import { connect } from 'react-redux';

import { downloadUserProfilesPartnershipsCSV } from '../../../../../state/modules/userProfilesPartnerships/actions';
import UserProfilesPartnershipsScreen from '../components/UserProfilesPartnershipsScreen';

const mapStateToProps = (state) => {
  return {
    total: state.userProfilesPartnerships.get('total'),
    isLoading: state.userProfilesPartnerships.get('isLoading'),
    isDownloading: state.userProfilesPartnerships.get('isCSVDownloading'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    download: (order, filter, cb) => {
      dispatch(downloadUserProfilesPartnershipsCSV(order, filter, cb));
    },
  };
};

const UserProfilesPartnershipsScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProfilesPartnershipsScreen);

export default UserProfilesPartnershipsScreenContainer;
