import {connect} from 'react-redux';
import {denormalize} from 'normalizr';

import {schema} from '../../../../../state/schema';
import {loadRelationships, deleteRelationship} from '../../../../../state/modules/relationships/actions';
import {sendMessage} from '../../../../../state/actions';
import RelationshipsList from '../components/RelationshipsList';

const mapStateToProps = (state, ownProps) => {

  const page = ownProps.page;
  const limit = ownProps.limit;
  const order = ownProps.order;
  const result = state.relationships.getIn(['pages', order, limit, page]);

  return {
    list: result 
      ? result.map(x => {
          return {
            key: x,
            ...denormalize(
              state.entities.getIn(['relationships', x]),
              schema.relationship,
              state.entities.toJS()
            )
          };
        }).toArray()
      : [],
    isLoading: state.relationships.get('isLoading'),
    total: state.relationships.get('total'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (page, limit, order, filter, cb) => {
      dispatch(loadRelationships(page, limit, order, filter, cb));
    },
    remove: (id, cb) => {
      dispatch(deleteRelationship(id, cb));
    },
    sendMessage: (level, title, body, expires) => {
      dispatch(sendMessage(level, title, body, expires));
    },
  }
}

const RelationshipsListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RelationshipsList);

export default RelationshipsListContainer;
