import { connect } from 'react-redux';
import { denormalize } from 'normalizr';

import { schema } from '../../../../../state/schema';
import { loadRegistrations } from '../../../../../state/modules/registration/actions';
import RegistrationsList from '../components/RegistrationsList';

const mapStateToProps = (state, ownProps) => {
  const page = ownProps.page;
  const limit = ownProps.limit;
  const order = ownProps.order;
  const result = state.registration.getIn(['pages', order, limit, page]);

  return {
    list: result
      ? result
          .map((x) => {
            return {
              key: x,
              ...denormalize(
                state.entities.getIn(['registrations', x]),
                schema.registration,
                state.entities.toJS()
              ),
            };
          })
          .toArray()
      : [],
    isLoading: state.registration.get('isLoading'),
    total: state.registration.get('total'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (page, limit, order, filter, cb) => {
      dispatch(loadRegistrations(page, limit, order, filter, cb));
    },
  };
};

const RegistrationsListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RegistrationsList);

export default RegistrationsListContainer;
