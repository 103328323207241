import {getI18n} from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import CareGuidesScreen from './containers/CareGuidesScreenContainer';
import CareGuideAddScreen from './containers/CareGuideAddScreenContainer';
import CareGuideEditScreen from './containers/CareGuideEditScreenContainer';

const register = () => {
  Logger.log('debug', `careGuides.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'careGuides', 'routes'])) {
    Events.dispatch('ADD_MAIN_ROUTES', 
      {
        'CareGuideAddScreen': ['PrivateRoute', '/care-guides/add', true, CareGuideAddScreen, getI18n().t('care_guide_route_add')],
        'CareGuideEditScreen': ['PrivateRoute', '/care-guides/edit/:id([\\w\\-]+)', true, CareGuideEditScreen, getI18n().t('care_guide_route_edit')],
        'CareGuidesScreen': ['PrivateRoute', '/care-guides/:page(\\d+)?', true, CareGuidesScreen, getI18n().t('care_guides_route_list')],
      }
    );
  }
}

export default register;

Logger.log('silly', `careGuides.register() loaded.`);
