import React, {useEffect} from 'react';
import {Translation} from 'react-i18next';
import {Form, Select} from 'antd';

const PostalCodeInput = ({name, label, options, load, rules=[], multiple=false, onChange=null, city_id=null, city_ids=null, ...props}) => {

  // load postal codes on mount
  useEffect(() => {
    load(1, 250, null, city_id ? {city_ids: city_id} : (city_ids ? {city_ids: city_ids} : null));
  }, [load, city_id, city_ids]);

  return (
    <Translation>{(t) => 
      <div className="form-group">
        <Form.Item
          name={name}
          label={label}
          rules={rules}
        >
          <Select
            showSearch
            mode={multiple ? "multiple" : null}
            disabled={props.isLoading || props.isSubmitting}
            loading={props.isLoading}
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            onChange={onChange}
          >
            {options.map(x => <Select.Option key={x.value} value={x.value}>{x.label}</Select.Option>)}
          </Select>
        </Form.Item>
      </div>
    }</Translation>
  );
}

export default PostalCodeInput;
