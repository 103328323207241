import { connect } from 'react-redux';

import { loadUsersPartnership } from '../../../../../state/modules/users/actions';
import UserPartnershipInput from '../components/UserPartnershipInput';

const mapStateToProps = (state) => {
  return {
    total: state.users.get('total'),
    isLoading: state.users.get('isLoading'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (partnershipId, page, limit, order, filter, cb) => {
      dispatch(loadUsersPartnership(partnershipId, page, limit, order, filter, cb));
    },
  };
};

const UserPartnershipInputContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserPartnershipInput);

export default UserPartnershipInputContainer;
