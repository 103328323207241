import {connect} from 'react-redux';

import CareGuideEditScreen from '../components/CareGuideEditScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.careGuides.get('isLoading'),
    success: state.careGuides.getIn(['form', 'success']),
  }
}

const CareGuideEditScreenContainer = connect(
  mapStateToProps
)(CareGuideEditScreen);

export default CareGuideEditScreenContainer;
