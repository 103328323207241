import {connect} from 'react-redux';

import MediumEditScreen from '../components/MediumEditScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.media.get('isLoading'),
    success: state.media.getIn(['form', 'success']),
  }
}

const MediumEditScreenContainer = connect(
  mapStateToProps
)(MediumEditScreen);

export default MediumEditScreenContainer;
