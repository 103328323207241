import React from 'react';
import {Translation} from 'react-i18next';
import {Checkbox, Form, Space} from 'antd';
import {MinusCircleOutlined} from '@ant-design/icons';

import MembersInput from '../../careGuideHelpers/containers/MembersInputContainer';
import PublicStatusInput from './PublicStatusInput';

const RecipientInputRow = ({recipient, index, remove, careGuideId, disabled=false, ...props}) => {

  return (
    <Translation>{(t) => 
      <div key={recipient.key} className="recipient-input-row">
        <Space style={{display: 'flex'}} align="start">

          <MembersInput
            name={[recipient.name, 'profile', 'id']}
            fieldKey={[recipient.fieldKey, 'profile', 'id']}
            label={t('message_recipient_user')}
            careGuideIds={[careGuideId]}
            rules={[
              {required: true, message: t('feedback_validation_required')},
            ]}
          />

          <div className="form-group">
            <Form.Item
              name={[recipient.name, 'is_read']}
              fieldKey={[recipient.fieldKey, 'is_read']}
              label={t('message_recipient_is_read')}
              valuePropName="checked"
            >
              <Checkbox disabled={disabled} />
            </Form.Item>
          </div>

          <PublicStatusInput
            name={[recipient.name, 'public_status']}
            fieldKey={[recipient.fieldKey, 'public_status']}
            label={t('message_recipient_public_status')}
            rules={[
              {required: true, message: t('feedback_validation_required')},
            ]}
            disabled={disabled}
          />

          <MinusCircleOutlined
            onClick={() => {
              remove(recipient.name);
            }}
          />

        </Space>
      </div>
    }</Translation>
  );
}

export default RecipientInputRow;
