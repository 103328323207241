import CareGuideModalExport from '../components/CareGuideModalExport';
import { downloadCareGuidesCSV } from '../../../../../state/modules/careGuides/actions';
import { connect } from 'react-redux';

const mapStateToProps = (state, ownProps) => {

  return {
    isDownloading: state.careGuides.get('isCSVDownloading'),
    total: state.careGuides.get('total') ?? 0,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    download: (partnershipId, order, filter, cb) => {
      dispatch(downloadCareGuidesCSV(partnershipId, order, filter, cb));
    }
  };
};

const CareGuideModalExportContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CareGuideModalExport);

export default CareGuideModalExportContainer;
