import { connect } from 'react-redux';

import CareGuideNotesCSVButton from '../components/CareGuideNotesCSVButton';

const mapStateToProps = state => {
  return {
  }
}

const CareGuideNotesCSVButtonContainer = connect(
  mapStateToProps
)(CareGuideNotesCSVButton);

export default CareGuideNotesCSVButtonContainer;