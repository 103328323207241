import { normalize } from 'normalizr';

import api from '../../api';
import { schema } from '../../schema';
import { addEntities, removeEntity } from '../../actions';
import Logger from '../../../lib/Logger';
import Format from '../../../lib/Format';

// ACTION TYPES

export const PARTNERSHIP_FORM_ANSWER_LIST_REQUEST =
  'PARTNERSHIP_FORM_ANSWER_LIST_REQUEST';
export const PARTNERSHIP_FORM_ANSWER_LIST_SUCCESS =
  'PARTNERSHIP_FORM_ANSWER_LIST_SUCCESS';
export const PARTNERSHIP_FORM_ANSWER_LIST_FAILURE =
  'PARTNERSHIP_FORM_ANSWER_LIST_FAILURE';
export const PARTNERSHIP_FORM_ANSWER_READ_REQUEST =
  'PARTNERSHIP_FORM_ANSWER_READ_REQUEST';
export const PARTNERSHIP_FORM_ANSWER_READ_SUCCESS =
  'PARTNERSHIP_FORM_ANSWER_READ_SUCCESS';
export const PARTNERSHIP_FORM_ANSWER_READ_FAILURE =
  'PARTNERSHIP_FORM_ANSWER_READ_FAILURE';
export const PARTNERSHIP_FORM_ANSWER_CSV_DOWNLOAD_REQUEST =
  'PARTNERSHIP_FORM_ANSWER_CSV_DOWNLOAD_REQUEST';
export const PARTNERSHIP_FORM_ANSWER_CSV_DOWNLOAD_SUCCESS =
  'PARTNERSHIP_FORM_ANSWER_CSV_DOWNLOAD_SUCCESS';
export const PARTNERSHIP_FORM_ANSWER_CSV_DOWNLOAD_FAILURE =
  'PARTNERSHIP_FORM_ANSWER_CSV_DOWNLOAD_FAILURE';
export const PARTNERSHIP_FORM_ANSWER_DELETE_REQUEST =
  'PARTNERSHIP_FORM_ANSWER_DELETE_REQUEST';
export const PARTNERSHIP_FORM_ANSWER_DELETE_SUCCESS =
  'PARTNERSHIP_FORM_ANSWER_DELETE_SUCCESS';
export const PARTNERSHIP_FORM_ANSWER_DELETE_FAILURE =
  'PARTNERSHIP_FORM_ANSWER_DELETE_FAILURE';

// ACTION CREATORS

export function partnershipFormAnswerListRequest(page, limit, order, filter) {
  Logger.log(
    'debug',
    `[state.partnershipFormAnswers.actions] partnershipFormAnswerListRequest(${page}, ${limit}, ${order}, %j)`,
    filter
  );
  return {
    type: PARTNERSHIP_FORM_ANSWER_LIST_REQUEST,
    page: page,
    limit: limit,
    order: order,
    filter: filter,
  };
}

export function partnershipFormAnswerListSuccess(data) {
  Logger.log(
    'debug',
    `[state.partnershipFormAnswers.actions] partnershipFormAnswerListSuccess(%j)`,
    data
  );
  return {
    type: PARTNERSHIP_FORM_ANSWER_LIST_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now(),
  };
}

export function partnershipFormAnswerListFailure(error) {
  Logger.log(
    'debug',
    `[state.partnershipFormAnswers.actions] partnershipFormAnswerListFailure(%j)`,
    error
  );
  return {
    type: PARTNERSHIP_FORM_ANSWER_LIST_FAILURE,
    error: error,
  };
}

export function partnershipFormAnswerReadRequest(id) {
  Logger.log(
    'debug',
    `[state.partnershipFormAnswers.actions] partnershipFormAnswerReadRequest(${id})`
  );
  return {
    type: PARTNERSHIP_FORM_ANSWER_READ_REQUEST,
    id: id,
  };
}

export function partnershipFormAnswerReadSuccess(data) {
  Logger.log(
    'debug',
    `[state.partnershipFormAnswers.actions] partnershipFormAnswerReadSuccess(%j)`,
    data
  );
  return {
    type: PARTNERSHIP_FORM_ANSWER_READ_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function partnershipFormAnswerReadFailure(error) {
  Logger.log(
    'debug',
    `[state.partnershipFormAnswers.actions] partnershipFormAnswerReadFailure(%j)`,
    error
  );
  return {
    type: PARTNERSHIP_FORM_ANSWER_READ_FAILURE,
    error: error,
  };
}

export function partnershipFormAnswerDeleteRequest(id) {
  Logger.log(
    'debug',
    `[state.partnershipFormAnswers.actions] partnershipFormAnswerDeleteRequest(${id})`
  );
  return {
    type: PARTNERSHIP_FORM_ANSWER_DELETE_REQUEST,
    id: id,
  };
}

export function partnershipFormAnswerDeleteSuccess(id) {
  Logger.log(
    'debug',
    `[state.partnershipFormAnswers.actions] partnershipFormAnswerDeleteSuccess(${id})`
  );
  return {
    type: PARTNERSHIP_FORM_ANSWER_DELETE_SUCCESS,
    id: id,
  };
}

export function partnershipFormAnswerDeleteFailure(error) {
  Logger.log(
    'debug',
    `[state.partnershipFormAnswers.actions] partnershipFormAnswerDeleteFailure(%j)`,
    error
  );
  return {
    type: PARTNERSHIP_FORM_ANSWER_DELETE_FAILURE,
    error: error,
  };
}

export function partnershipFormAnswerCSVDownloadRequest(order, filter) {
  Logger.log(
    'debug',
    `[state.partnershipFormAnswers.actions] partnershipFormAnswerCSVDownloadRequest()`
  );
  return {
    type: PARTNERSHIP_FORM_ANSWER_CSV_DOWNLOAD_REQUEST,
    order: order,
    filter: filter,
  };
}

export function partnershipFormAnswerCSVDownloadSuccess() {
  Logger.log(
    'debug',
    `[state.partnershipFormAnswers.actions] partnershipFormAnswerCSVDownloadSuccess()`
  );
  return {
    type: PARTNERSHIP_FORM_ANSWER_CSV_DOWNLOAD_SUCCESS,
  };
}

export function partnershipFormAnswerCSVDownloadFailure() {
  Logger.log(
    'debug',
    `[state.partnershipFormAnswers.actions] partnershipFormAnswerCSVDownloadFailure()`
  );
  return {
    type: PARTNERSHIP_FORM_ANSWER_CSV_DOWNLOAD_FAILURE,
  };
}

// API THUNK ACTION CREATORS

export function loadPartnershipFormAnswers(
  page = 1,
  limit = 10,
  order = null,
  filter = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.partnershipFormAnswers.actions] loadPartnershipFormAnswers(${page}, ${limit}, ${order}, ###)`
  );

  return async function (dispatch) {
    dispatch(partnershipFormAnswerListRequest(page, limit, order, filter));

    // call API
    const response = await api.getPartnershipFormAnswers(
      page,
      limit,
      order,
      filter
    );
    let success = false;

    // get partnership form answers list success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API partnership form answers list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );

      const normalizedEntities = normalize(
        response.getIn(['data', 'partnership_form_answers']),
        [schema.partnershipFormAnswer]
      );
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result,
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(partnershipFormAnswerListSuccess(data));
      success = true;
    } else if (1 === page && 204 === response.get('status')) {
      Logger.log(
        'info',
        `Get API partnership form answers list success [empty]. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: [],
      };
      dispatch(partnershipFormAnswerListSuccess(data));

      // get partnership form answers list failure
    } else {
      Logger.log(
        'info',
        `Get API partnership form answers list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      dispatch(
        partnershipFormAnswerListFailure(response.getIn(['data', 'error']))
      );
    }

    // callback function
    cb(success);
  };
}

export function loadPartnershipFormAnswer(id, cb = function () {}) {
  Logger.log(
    'debug',
    `[state.partnershipFormAnswers.actions] loadPartnershipFormAnswer(${id}, ###)`
  );

  return async function (dispatch) {
    dispatch(partnershipFormAnswerReadRequest(id));

    // call API
    const response = await api.getPartnershipFormAnswer(id);
    let success = false;

    // get partnership form answer success
    if (200 === response.get('status')) {
      Logger.log('info', `Get API partnershipFormAnswer success. ID: ${id}.`);

      const normalizedEntities = normalize(
        [response.getIn(['data', 'partnership_form_answer'])],
        [schema.partnershipFormAnswer]
      );
      const data = {
        id: response.getIn(['data', 'partnership_form_answer', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(partnershipFormAnswerReadSuccess(data));
      success = true;

      // get partnership form answer failure
    } else {
      Logger.log('info', `Get API partnershipFormAnswer failure. ID: ${id}.`);
      dispatch(
        partnershipFormAnswerReadFailure(response.getIn(['data', 'error']))
      );
    }

    // callback function
    cb(success);
  };
}

export function deletePartnershipFormAnswer(id, cb = function () {}) {
  Logger.log(
    'debug',
    `[state.partnershipFormAnswers.actions] deletePartnershipFormAnswer(${id}, ###)`
  );

  return async function (dispatch) {
    dispatch(partnershipFormAnswerDeleteRequest(id));

    // call API
    const response = await api.deletePartnershipFormAnswer(id);
    let success = false;

    // delete partnership form answer success
    if (204 === response.get('status')) {
      Logger.log(
        'info',
        `DELETE API partnership form answer success. ID: ${id}.`
      );

      dispatch(removeEntity({ entityType: 'partnershipFormAnswers', id: id }));
      dispatch(partnershipFormAnswerDeleteSuccess(id));
      success = true;

      // get partnership form answer failure
    } else {
      Logger.log(
        'info',
        `DELETE API partnership form answer failure. ID: ${id}.`
      );
      dispatch(
        partnershipFormAnswerDeleteFailure(response.getIn(['data', 'error']))
      );
    }

    // callback function
    cb(success);
  };
}

export function downloadPartnershipFormAnswersCSV(
  order = null,
  filter = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.partnershipFormAnswers.actions] downloadPartnershipFormAnswersCSV(${order}, ${filter}, ###)`
  );

  return async function (dispatch) {
    dispatch(partnershipFormAnswerCSVDownloadRequest(order, filter));

    // call API
    const response = await api.getPartnershipFormAnswersCSV(order, filter);

    // get partnership form answers CSV success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API partnership form answers CSV success. Order: ${order}`
      );
      const now = new Date();

      // trigger browser download
      const url = window.URL.createObjectURL(new Blob([response.get('data')]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `cv-partnership-form-answers-${Format.date(now, 'YYYY-MM-DDTHHmm')}.csv`
      );
      document.body.appendChild(link);
      link.click();

      dispatch(partnershipFormAnswerCSVDownloadSuccess());

      // get partnership form answers CSV failure
    } else {
      Logger.log('info', `Get API partnership form answers CSV failure.`);
      dispatch(partnershipFormAnswerCSVDownloadFailure());
    }

    // callback function
    cb();
  };
}

Logger.log('silly', `partnershipFormAnswers.actions loaded.`);
