import {normalize} from 'normalizr';

import api from '../../api';
import {schema} from '../../schema';
import {addEntities, removeEntity} from '../../actions';
import Format from '../../../lib/Format';
import Logger from '../../../lib/Logger';

// ACTION TYPES

export const RESOURCE_SERVICE_TAG_LIST_REQUEST = 'RESOURCE_SERVICE_TAG_LIST_REQUEST';
export const RESOURCE_SERVICE_TAG_LIST_SUCCESS = 'RESOURCE_SERVICE_TAG_LIST_SUCCESS';
export const RESOURCE_SERVICE_TAG_LIST_FAILURE = 'RESOURCE_SERVICE_TAG_LIST_FAILURE';
export const RESOURCE_SERVICE_TAG_READ_REQUEST = 'RESOURCE_SERVICE_TAG_READ_REQUEST';
export const RESOURCE_SERVICE_TAG_READ_SUCCESS = 'RESOURCE_SERVICE_TAG_READ_SUCCESS';
export const RESOURCE_SERVICE_TAG_READ_FAILURE = 'RESOURCE_SERVICE_TAG_READ_FAILURE';
export const RESOURCE_SERVICE_TAG_UPDATE_REQUEST = 'RESOURCE_SERVICE_TAG_UPDATE_REQUEST';
export const RESOURCE_SERVICE_TAG_UPDATE_SUCCESS = 'RESOURCE_SERVICE_TAG_UPDATE_SUCCESS';
export const RESOURCE_SERVICE_TAG_UPDATE_FAILURE = 'RESOURCE_SERVICE_TAG_UPDATE_FAILURE';
export const RESOURCE_SERVICE_TAG_CREATE_REQUEST = 'RESOURCE_SERVICE_TAG_CREATE_REQUEST';
export const RESOURCE_SERVICE_TAG_CREATE_SUCCESS = 'RESOURCE_SERVICE_TAG_CREATE_SUCCESS';
export const RESOURCE_SERVICE_TAG_CREATE_FAILURE = 'RESOURCE_SERVICE_TAG_CREATE_FAILURE';
export const RESOURCE_SERVICE_TAG_DELETE_REQUEST = 'RESOURCE_SERVICE_TAG_DELETE_REQUEST';
export const RESOURCE_SERVICE_TAG_DELETE_SUCCESS = 'RESOURCE_SERVICE_TAG_DELETE_SUCCESS';
export const RESOURCE_SERVICE_TAG_DELETE_FAILURE = 'RESOURCE_SERVICE_TAG_DELETE_FAILURE';
export const RESOURCE_SERVICE_TAG_FORM_DESTROY = 'RESOURCE_SERVICE_TAG_FORM_DESTROY';
export const RESOURCE_SERVICE_CSV_DOWNLOAD_REQUEST = 'RESOURCE_SERVICE_CSV_DOWNLOAD_REQUEST';
export const RESOURCE_SERVICE_CSV_DOWNLOAD_SUCCESS = 'RESOURCE_SERVICE_CSV_DOWNLOAD_SUCCESS';
export const RESOURCE_SERVICE_CSV_DOWNLOAD_FAILURE = 'RESOURCE_SERVICE_CSV_DOWNLOAD_FAILURE';

// ACTION CREATORS

export function resourceServiceTagListRequest(page, limit, order, filter) {
  Logger.log('debug', `[resourceServiceTags.actions] resourceServiceTagListRequest(${page}, ${limit}, ${order}, %j)`, filter);
  return {
    type: RESOURCE_SERVICE_TAG_LIST_REQUEST,
    page: page,
    limit: limit,
    order: order,
    filter: filter
  }
}

export function resourceServiceTagListSuccess(data) {
  Logger.log('debug', `[resourceServiceTags.actions] resourceServiceTagListSuccess(%j)`, data);
  return {
    type: RESOURCE_SERVICE_TAG_LIST_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now()
  }
}

export function resourceServiceTagListFailure(error) {
  Logger.log('debug', `[resourceServiceTags.actions] resourceServiceTagListFailure(%j)`, error);
  return {
    type: RESOURCE_SERVICE_TAG_LIST_FAILURE,
    error: error
  }
}

export function resourceServiceTagReadRequest(id) {
  Logger.log('debug', `[resourceServiceTags.actions] resourceServiceTagReadRequest(${id})`);
  return {
    type: RESOURCE_SERVICE_TAG_READ_REQUEST,
    id: id
  }
}

export function resourceServiceTagReadSuccess(data) {
  Logger.log('debug', `[resourceServiceTags.actions] resourceServiceTagReadSuccess(%j)`, data);
  return {
    type: RESOURCE_SERVICE_TAG_READ_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function resourceServiceTagReadFailure(error) {
  Logger.log('debug', `[resourceServiceTags.actions] resourceServiceTagReadFailure(%j)`, error);
  return {
    type: RESOURCE_SERVICE_TAG_READ_FAILURE,
    error: error
  }
}

export function resourceServiceTagUpdateRequest(id, data) {
  Logger.log('debug', `[resourceServiceTags.actions] resourceServiceTagUpdateRequest(${id}, %j)`, data);
  return {
    type: RESOURCE_SERVICE_TAG_UPDATE_REQUEST,
  }
}

export function resourceServiceTagUpdateSuccess(data) {
  Logger.log('debug', `[resourceServiceTags.actions] resourceServiceTagUpdateSuccess(%j)`, data);
  return {
    type: RESOURCE_SERVICE_TAG_UPDATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function resourceServiceTagUpdateFailure(error) {
  Logger.log('debug', `[resourceServiceTags.actions] resourceServiceTagUpdateFailure(%j)`, error);
  return {
    type: RESOURCE_SERVICE_TAG_UPDATE_FAILURE,
    error: error
  }
}

export function resourceServiceTagCreateRequest(data) {
  Logger.log('debug', `[resourceServiceTags.actions] resourceServiceTagCreateRequest(%j)`, data);
  return {
    type: RESOURCE_SERVICE_TAG_CREATE_REQUEST,
  }
}

export function resourceServiceTagCreateSuccess(data) {
  Logger.log('debug', `[resourceServiceTags.actions] resourceServiceTagCreateSuccess(%j)`, data);
  return {
    type: RESOURCE_SERVICE_TAG_CREATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function resourceServiceTagCreateFailure(error) {
  Logger.log('debug', `[resourceServiceTags.actions] resourceServiceTagCreateFailure(%j)`, error);
  return {
    type: RESOURCE_SERVICE_TAG_CREATE_FAILURE,
    error: error
  }
}

export function resourceServiceTagDeleteRequest(id) {
  Logger.log('debug', `[resourceServiceTags.actions] resourceServiceTagDeleteRequest(${id})`);
  return {
    type: RESOURCE_SERVICE_TAG_DELETE_REQUEST,
    id: id
  }
}

export function resourceServiceTagDeleteSuccess(id) {
  Logger.log('debug', `[resourceServiceTags.actions] resourceServiceTagDeleteSuccess(${id})`);
  return {
    type: RESOURCE_SERVICE_TAG_DELETE_SUCCESS,
    id: id,
  }
}

export function resourceServiceTagDeleteFailure(error) {
  Logger.log('debug', `[resourceServiceTags.actions] resourceServiceTagDeleteFailure(%j)`, error);
  return {
    type: RESOURCE_SERVICE_TAG_DELETE_FAILURE,
    error: error
  }
}

export function resourceServiceTagFormDestroy(formState=null) {
  Logger.log('debug', `[resourceServiceTags.actions] resourceServiceTagFormDestroy(%j)`, formState);
  return {
    type: RESOURCE_SERVICE_TAG_FORM_DESTROY,
    form: formState
  }
}

export function resourceServiceTagCSVDownloadRequest(order, filter) {
  Logger.log('debug', `[resourceServiceTags.actions] resourceServiceTagCSVDownloadRequest()`);
  return {
    type: RESOURCE_SERVICE_CSV_DOWNLOAD_REQUEST,
    order: order,
    filter: filter,
  }
}

export function resourceServiceTagCSVDownloadSuccess() {
  Logger.log('debug', `[resourceServiceTags.actions] resourceServiceTagCSVDownloadSuccess()`);
  return {
    type: RESOURCE_SERVICE_CSV_DOWNLOAD_SUCCESS,
  }
}

export function resourceServiceTagCSVDownloadFailure() {
  Logger.log('debug', `[resourceServiceTags.actions] resourceServiceTagCSVDownloadFailure()`);
  return {
    type: RESOURCE_SERVICE_CSV_DOWNLOAD_FAILURE,
  }
}


// API THUNK ACTION CREATORS

export function loadResourceServiceTags(page=1, limit=10, order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[resourceServiceTags.actions] loadResourceServiceTags(${page}, ${limit}, ${order}, %j, ###)`, filter);

  return async function(dispatch) {
    dispatch(resourceServiceTagListRequest(page, limit, order, filter));

    // call API
    const response = await api.getResourceServiceTags(page, limit, order, filter);
    let success = false;

    // get resource service tags list success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API resource service tags list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`);

      const normalizedEntities = normalize(response.getIn(['data', 'resource_service_tags']), [schema.resourceServiceTag]);
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(resourceServiceTagListSuccess(data));
      success = true;

    } else if (1 === page && 204 === response.get('status')) {

      Logger.log('info', `Get API resource service tags success [empty]. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: []
      };
      dispatch(resourceServiceTagListSuccess(data));
      success = true;
      
    // get resource service tags list failure
    } else {
      Logger.log('info', `Get API resource service tags list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      dispatch(resourceServiceTagListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function loadResourceServiceTag(id, cb=function(){}) {
  Logger.log('debug', `[resourceServiceTags.actions] loadResourceServiceTag(${id}, ###)`);

  return async function(dispatch) {
    dispatch(resourceServiceTagReadRequest(id));

    // call API
    const response = await api.getResourceServiceTag(id);
    let success = false;

    // get resource service tag success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API resource service tag success. ID: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'resource_service_tag'])], [schema.resourceServiceTag]);
      const data = {
        id: response.getIn(['data', 'resource_service_tag', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(resourceServiceTagReadSuccess(data));
      success = true;
      
    // get resource service tag failure
    } else {
      Logger.log('info', `Get API resource service tag failure. ID: ${id}.`);
      dispatch(resourceServiceTagReadFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function updateResourceServiceTag(id, data, cb=function(){}) {
  Logger.log('debug', `[resourceServiceTags.actions] updateResourceServiceTag(${id}, %j, ###)`, data);

  return async function(dispatch) {
    dispatch(resourceServiceTagUpdateRequest(id, data));

    // call API
    const response = await api.putResourceServiceTag(id, data);
    let success = false;

    // put resource service tag success
    if (200 === response.get('status')) {

      Logger.log('info', `PUT API resource service tag success. User: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'resource_service_tag'])], [schema.resourceServiceTag]);
      const data = {
        id: response.getIn(['data', 'resource_service_tag', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(resourceServiceTagUpdateSuccess(data));
      success = true;
      
    // get resource service tag failure
    } else {
      Logger.log('info', `PUT API resource service tag failure. ID: ${id}.`);
      dispatch(resourceServiceTagUpdateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function createResourceServiceTag(data, cb=function(){}) {
  Logger.log('debug', `[resourceServiceTags.actions] createResourceServiceTag(%j, ###)`, data);

  return async function(dispatch) {
    dispatch(resourceServiceTagCreateRequest(data));

    // call API
    const response = await api.postResourceServiceTags(data);
    let success = false;

    // post resource service tags success
    if (201 === response.get('status')) {

      Logger.log('info', `POST API resource service tags success. Post: ${response.getIn(['data', 'resource_service_tag', 'id'])}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'resource_service_tag'])], [schema.resourceServiceTag]);
      const data = {
        id: response.getIn(['data', 'resource_service_tag', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(resourceServiceTagCreateSuccess(data));
      success = true;
      
    // get resource service tags failure
    } else {
      Logger.log('info', `POST API resource service tags failure.`);
      dispatch(resourceServiceTagCreateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function deleteResourceServiceTag(id, cb=function(){}) {
  Logger.log('debug', `[resourceServiceTags.actions] deleteResourceServiceTag(${id}, ###)`);

  return async function(dispatch) {
    dispatch(resourceServiceTagDeleteRequest(id));

    // call API
    const response = await api.deleteResourceServiceTag(id);
    let success = false;

    // delete resource service tag success
    if (204 === response.get('status')) {

      Logger.log('info', `DELETE API resource service tag success. ID: ${id}.`);

      dispatch(removeEntity({entityType: 'resourceServiceTags', id: id}));
      dispatch(resourceServiceTagDeleteSuccess(id));
      success = true;
      
    // get resource service tag failure
    } else {
      Logger.log('info', `DELETE API resource service tag failure. ID: ${id}.`);
      dispatch(resourceServiceTagDeleteFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function downloadResourceServiceTagsCSV(order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[state.resourceServiceTags.actions] downloadResourceServiceTagsCSV(${order}, ${filter}, ###)`);

  return async function(dispatch) {
    dispatch(resourceServiceTagCSVDownloadRequest(order, filter));

    // call API
    const response = await api.getResourceServiceTagssCSV(order, filter);

    // get resource service tags CSV success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API resource service tags CSV success. Order: ${order}`);
      const now = new Date();

      // trigger browser download
      const url = window.URL.createObjectURL(new Blob([response.get('data')]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `cv-resource-service-tags-${Format.date(now, 'YYYY-MM-DDTHHmm')}.csv`);
      document.body.appendChild(link);
      link.click();

      dispatch(resourceServiceTagCSVDownloadSuccess());
      
    // get resource service tags CSV failure
    } else {
      Logger.log('info', `Get API resource service tags CSV failure.`);
      dispatch(resourceServiceTagCSVDownloadFailure());
    }

    // callback function
    cb();
  }
}

Logger.log('silly', `resourceServiceTags.actions loaded.`);
