import { normalize } from 'normalizr';

import api from '../../api';
import { schema } from '../../schema';
import { addEntities } from '../../actions';
import Logger from '../../../lib/Logger';

// ACTION TYPES

export const PASSWORD_RESET_LIST_REQUEST = 'PASSWORD_RESET_LIST_REQUEST';
export const PASSWORD_RESET_LIST_SUCCESS = 'PASSWORD_RESET_LIST_SUCCESS';
export const PASSWORD_RESET_LIST_FAILURE = 'PASSWORD_RESET_LIST_FAILURE';

// ACTION CREATORS

export function passwordResetListRequest(page, limit, order, filter) {
  Logger.log(
    'debug',
    `[passwordResets.actions] passwordResetListRequest(${page}, ${limit}, ${order}, %j)`,
    filter
  );
  return {
    type: PASSWORD_RESET_LIST_REQUEST,
    page: page,
    limit: limit,
    order: order,
    filter: filter,
  };
}

export function passwordResetListSuccess(data) {
  Logger.log(
    'debug',
    `[passwordResets.actions] passwordResetListSuccess(%j)`,
    data
  );
  return {
    type: PASSWORD_RESET_LIST_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now(),
  };
}

export function passwordResetListFailure(error) {
  Logger.log(
    'debug',
    `[passwordResets.actions] passwordResetListFailure(%j)`,
    error
  );
  return {
    type: PASSWORD_RESET_LIST_FAILURE,
    error: error,
  };
}

// API THUNK ACTION CREATORS

export function loadPasswordResets(
  page = 1,
  limit = 10,
  order = null,
  filter = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[passwordResets.actions] loadPasswordResets(${page}, ${limit}, ${order}, ###)`
  );

  return async function (dispatch) {
    dispatch(passwordResetListRequest(page, limit, order, filter));

    // call API
    const response = await api.getPasswordResets(page, limit, order, filter);
    let success = false;

    // get password resets list success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API password resets list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );

      const normalizedEntities = normalize(
        response.getIn(['data', 'password_resets']),
        [schema.passwordReset]
      );
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result,
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(passwordResetListSuccess(data));
      success = true;
    } else if (1 === page && 204 === response.get('status')) {
      Logger.log(
        'info',
        `Get API passwordr esets list success [empty]. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: [],
      };
      dispatch(passwordResetListSuccess(data));

      // get passwordr esets list failure
    } else {
      Logger.log(
        'info',
        `Get API password resets list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      dispatch(passwordResetListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

Logger.log('silly', `passwordResets.actions loaded.`);
