import {connect} from 'react-redux';
import {denormalize} from 'normalizr';

import {schema} from '../../../../../../state/schema';
import {loadRegions, deleteRegion} from '../../../../../../state/modules/locationRegions/actions';
import {sendMessage} from '../../../../../../state/actions';
import RegionsList from '../components/RegionsList';

const mapStateToProps = (state, ownProps) => {

  const page = ownProps.page;
  const limit = ownProps.limit;
  const order = ownProps.order;
  const result = state.locationRegions.getIn(['pages', order, limit, page]);
  return {
    list: result 
      ? result.map(x => {
          return {
            key: x,
            ...denormalize(
              state.entities.getIn(['regions', x]),
              schema.region,
              state.entities.toJS()
            )
          };
        }).toArray().map(x => {

          // flatten some nested objects to make table easier to work with
          return {
            country_name: x.country ? x.country.name: '',
            ...x
          };
        })
      : [],
    isLoading: state.locationRegions.get('areRegionsLoading'),
    total: state.locationRegions.get('total'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (page, limit, order, filter, cb) => {
      dispatch(loadRegions(page,limit, order, filter, cb));
    },
    remove: (id, cb) => {
      dispatch(deleteRegion(id, cb));
    },
    sendMessage: (level, title, body, expires) => {
      dispatch(sendMessage(level, title, body, expires));
    },
  }
}

const RegionListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RegionsList);

export default RegionListContainer;
