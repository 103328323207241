import {connect} from 'react-redux';

import {loadJournalTags} from '../../../../../state/modules/journalTags/actions';
import TagInput from '../components/TagInput';

const mapStateToProps = (state, ownProps) => {

  const tags = state.entities.get('journalTags', {});

  return {
    options: Object.keys(tags)
      .map(id => {return {label: tags[id].label, value: tags[id].label}}),
    isLoading: state.journalTags.get('isLoading'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (page, limit, order, filter, cb) => {
      dispatch(loadJournalTags(page, limit, order, filter, cb));
    },
  }
}

const TagInputContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TagInput);

export default TagInputContainer;
