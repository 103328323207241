import {connect} from 'react-redux';

import SubRegionScreen from '../components/SubRegionScreen';

const mapStateToProps = state => {
  return {
    total: state.locationSubRegions.get('total'),
    isLoading: state.locationSubRegions.get('areSubRegionsLoading')
  }
}

const SubRegionScreenContainer = connect(
  mapStateToProps
)(SubRegionScreen);

export default SubRegionScreenContainer;
