import { connect } from 'react-redux';
import { loadAdminPartnerClasses } from '../../../../../state/modules/adminPartnerClasses/actions';

import AdminPartnerClassInput from '../components/AdminPartnerClassInput';

const mapStateToProps = (state) => {
  return {
    total: state.adminPartnerClasses.get('total'),
    isLoading: state.adminPartnerClasses.get('isLoading'),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    load: (page, limit, order, filter, cb) => {
      dispatch(loadAdminPartnerClasses(page, limit, order, filter, cb));
    },
  }
}

const AdminPartnerClassInputContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminPartnerClassInput);

export default AdminPartnerClassInputContainer;
