import React from 'react';
import { Translation } from 'react-i18next';
import { Button } from 'antd';
import { CloudDownloadOutlined } from '@ant-design/icons';

import Logger from '../../../../../lib/Logger';

const RecommendedUtilitiesCSVButton = ({
  download,
  isDownloading = false,
  order = null,
  filter = null,
  ...props
}) => {
  const onClick = () => {
    download(order, filter);
  };

  return (
    <Translation>
      {(t) => (
        <Button
          size="small"
          icon={<CloudDownloadOutlined />}
          loading={isDownloading}
          onClick={onClick}
        >
          {t('action_export')}
        </Button>
      )}
    </Translation>
  );
};

export default RecommendedUtilitiesCSVButton;

Logger.log('silly', `RecommendedUtilitiesCSVButton loaded.`);
