import { connect } from 'react-redux';
import { denormalize } from 'normalizr';

import { schema } from '../../../../../state/schema';
import { loadAlerts } from '../../../../../state/modules/alerts/actions';
import AlertsList from '../components/AlertsList';

const mapStateToProps = (state, ownProps) => {
  const page = ownProps.page;
  const limit = ownProps.limit;
  const order = ownProps.order;
  const result = state.alerts.getIn(['pages', order, limit, page]);

  return {
    list: result
      ? result
          .map((x) => {
            return {
              key: x,
              ...denormalize(
                state.entities.getIn(['alerts', x]),
                schema.alert,
                state.entities.toJS()
              ),
            };
        }).toArray().map(x => {
          // flatten some nested objects to make table easier to work with
          return {
            partnership_name: x?.care_guide?.partnership?.name ?? '',
            partnership_plan: x?.care_guide?.partnership?.plan?.name ?? '',
            care_guide_name: (x?.care_guide?.first_name && x?.care_guide?.last_name) ? `${x?.care_guide?.first_name} ${x?.care_guide?.last_name}`: '',
            profile_username: x.profile?.user?.username || '',
            ...x
          };
        })
      : [],
    isLoading: state.alerts.get('isLoading'),
    total: state.alerts.get('total'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (page, limit, order, filter, cb) => {
      dispatch(loadAlerts(page, limit, order, filter, cb));
    },
  };
};

const AlertsListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AlertsList);

export default AlertsListContainer;
