import { normalize } from 'normalizr';

import api from '../../api';
import { schema } from '../../schema';
import { addEntities, removeEntity } from '../../actions';
import Format from '../../../lib/Format';
import Logger from '../../../lib/Logger';

// ACTION TYPES

export const ADMIN_PARTNER_CLASS_LIST_REQUEST =
  'ADMIN_PARTNER_CLASS_LIST_REQUEST';
export const ADMIN_PARTNER_CLASS_LIST_SUCCESS =
  'ADMIN_PARTNER_CLASS_LIST_SUCCESS';
export const ADMIN_PARTNER_CLASS_LIST_FAILURE =
  'ADMIN_PARTNER_CLASS_LIST_FAILURE';
export const ADMIN_PARTNER_CLASS_READ_REQUEST =
  'ADMIN_PARTNER_CLASS_READ_REQUEST';
export const ADMIN_PARTNER_CLASS_READ_SUCCESS =
  'ADMIN_PARTNER_CLASS_READ_SUCCESS';
export const ADMIN_PARTNER_CLASS_READ_FAILURE =
  'ADMIN_PARTNER_CLASS_READ_FAILURE';
export const ADMIN_PARTNER_CLASS_UPDATE_REQUEST =
  'ADMIN_PARTNER_CLASS_UPDATE_REQUEST';
export const ADMIN_PARTNER_CLASS_UPDATE_SUCCESS =
  'ADMIN_PARTNER_CLASS_UPDATE_SUCCESS';
export const ADMIN_PARTNER_CLASS_UPDATE_FAILURE =
  'ADMIN_PARTNER_CLASS_UPDATE_FAILURE';
export const ADMIN_PARTNER_CLASS_CREATE_REQUEST =
  'ADMIN_PARTNER_CLASS_CREATE_REQUEST';
export const ADMIN_PARTNER_CLASS_CREATE_SUCCESS =
  'ADMIN_PARTNER_CLASS_CREATE_SUCCESS';
export const ADMIN_PARTNER_CLASS_CREATE_FAILURE =
  'ADMIN_PARTNER_CLASS_CREATE_FAILURE';
export const ADMIN_PARTNER_CLASS_DELETE_REQUEST =
  'ADMIN_PARTNER_CLASS_DELETE_REQUEST';
export const ADMIN_PARTNER_CLASS_DELETE_SUCCESS =
  'ADMIN_PARTNER_CLASS_DELETE_SUCCESS';
export const ADMIN_PARTNER_CLASS_DELETE_FAILURE =
  'ADMIN_PARTNER_CLASS_DELETE_FAILURE';
export const ADMIN_PARTNER_CLASS_FORM_DESTROY =
  'ADMIN_PARTNER_CLASS_FORM_DESTROY';
export const ADMIN_PARTNER_CLASS_CSV_DOWNLOAD_REQUEST =
  'ADMIN_PARTNER_CLASS_CSV_DOWNLOAD_REQUEST';
export const ADMIN_PARTNER_CLASS_CSV_DOWNLOAD_SUCCESS =
  'ADMIN_PARTNER_CLASS_CSV_DOWNLOAD_SUCCESS';
export const ADMIN_PARTNER_CLASS_CSV_DOWNLOAD_FAILURE =
  'ADMIN_PARTNER_CLASS_CSV_DOWNLOAD_FAILURE';
export const ADMIN_PARTNER_CLASS_PARTNERSHIP_LIST_REQUEST =
  'ADMIN_PARTNER_CLASS_PARTNERSHIP_LIST_REQUEST';
export const ADMIN_PARTNER_CLASS_PARTNERSHIP_LIST_SUCCESS =
  'ADMIN_PARTNER_CLASS_PARTNERSHIP_LIST_SUCCESS';
export const ADMIN_PARTNER_CLASS_PARTNERSHIP_LIST_FAILURE =
  'ADMIN_PARTNER_CLASS_PARTNERSHIP_LIST_FAILURE';

// ACTION CREATORS

export function adminPartnerClassListRequest(page, limit, order, filter) {
  Logger.log(
    'debug',
    `[adminPartnerClasses.actions] adminPartnerClassListRequest(${page}, ${limit}, ${order}, %j)`,
    filter
  );
  return {
    type: ADMIN_PARTNER_CLASS_LIST_REQUEST,
    page: page,
    limit: limit,
    order: order,
    filter: filter,
  };
}

export function adminPartnerClassListSuccess(data) {
  Logger.log(
    'debug',
    `[adminPartnerClasses.actions] adminPartnerClassListSuccess(%j)`,
    data
  );
  return {
    type: ADMIN_PARTNER_CLASS_LIST_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now(),
  };
}

export function adminPartnerClassListFailure(error) {
  Logger.log(
    'debug',
    `[adminPartnerClasses.actions] adminPartnerClassListFailure(%j)`,
    error
  );
  return {
    type: ADMIN_PARTNER_CLASS_LIST_FAILURE,
    error: error,
  };
}

export function adminPartnerClassReadRequest(id) {
  Logger.log(
    'debug',
    `[adminPartnerClasses.actions] adminPartnerClassReadRequest(${id})`
  );
  return {
    type: ADMIN_PARTNER_CLASS_READ_REQUEST,
    id: id,
  };
}

export function adminPartnerClassReadSuccess(data) {
  Logger.log(
    'debug',
    `[adminPartnerClasses.actions] adminPartnerClassReadSuccess(%j)`,
    data
  );
  return {
    type: ADMIN_PARTNER_CLASS_READ_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function adminPartnerClassReadFailure(error) {
  Logger.log(
    'debug',
    `[adminPartnerClasses.actions] adminPartnerClassReadFailure(%j)`,
    error
  );
  return {
    type: ADMIN_PARTNER_CLASS_READ_FAILURE,
    error: error,
  };
}

export function adminPartnerClassUpdateRequest(id, data) {
  Logger.log(
    'debug',
    `[adminPartnerClasses.actions] adminPartnerClassUpdateRequest(${id}, %j)`,
    data
  );
  return {
    type: ADMIN_PARTNER_CLASS_UPDATE_REQUEST,
  };
}

export function adminPartnerClassUpdateSuccess(data) {
  Logger.log(
    'debug',
    `[adminPartnerClasses.actions] adminPartnerClassUpdateSuccess(%j)`,
    data
  );
  return {
    type: ADMIN_PARTNER_CLASS_UPDATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function adminPartnerClassUpdateFailure(error) {
  Logger.log(
    'debug',
    `[adminPartnerClasses.actions] adminPartnerClassUpdateFailure(%j)`,
    error
  );
  return {
    type: ADMIN_PARTNER_CLASS_UPDATE_FAILURE,
    error: error,
  };
}

export function adminPartnerClassCreateRequest(data) {
  Logger.log(
    'debug',
    `[adminPartnerClasses.actions] adminPartnerClassCreateRequest(%j)`,
    data
  );
  return {
    type: ADMIN_PARTNER_CLASS_CREATE_REQUEST,
  };
}

export function adminPartnerClassCreateSuccess(data) {
  Logger.log(
    'debug',
    `[adminPartnerClasses.actions] adminPartnerClassCreateSuccess(%j)`,
    data
  );
  return {
    type: ADMIN_PARTNER_CLASS_CREATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function adminPartnerClassCreateFailure(error) {
  Logger.log(
    'debug',
    `[adminPartnerClasses.actions] adminPartnerClassCreateFailure(%j)`,
    error
  );
  return {
    type: ADMIN_PARTNER_CLASS_CREATE_FAILURE,
    error: error,
  };
}

export function adminPartnerClassDeleteRequest(id) {
  Logger.log(
    'debug',
    `[adminPartnerClasses.actions] adminPartnerClassDeleteRequest(${id})`
  );
  return {
    type: ADMIN_PARTNER_CLASS_DELETE_REQUEST,
    id: id,
  };
}

export function adminPartnerClassDeleteSuccess(id) {
  Logger.log(
    'debug',
    `[adminPartnerClasses.actions] adminPartnerClassDeleteSuccess(${id})`
  );
  return {
    type: ADMIN_PARTNER_CLASS_DELETE_SUCCESS,
    id: id,
  };
}

export function adminPartnerClassDeleteFailure(error) {
  Logger.log(
    'debug',
    `[adminPartnerClasses.actions] adminPartnerClassDeleteFailure(%j)`,
    error
  );
  return {
    type: ADMIN_PARTNER_CLASS_DELETE_FAILURE,
    error: error,
  };
}

export function adminPartnerClassFormDestroy(formState = null) {
  Logger.log(
    'debug',
    `[adminPartnerClasses.actions] adminPartnerClassFormDestroy(%j)`,
    formState
  );
  return {
    type: ADMIN_PARTNER_CLASS_FORM_DESTROY,
    form: formState,
  };
}

export function adminPartnerClassCSVDownloadRequest(order, filter) {
  Logger.log(
    'debug',
    `[state.adminPartnerClasses.actions] adminPartnerClassCSVDownloadRequest()`
  );
  return {
    type: ADMIN_PARTNER_CLASS_CSV_DOWNLOAD_REQUEST,
    order: order,
    filter: filter,
  };
}

export function adminPartnerClassCSVDownloadSuccess() {
  Logger.log(
    'debug',
    `[state.adminPartnerClasses.actions] adminPartnerClassCSVDownloadSuccess()`
  );
  return {
    type: ADMIN_PARTNER_CLASS_CSV_DOWNLOAD_SUCCESS,
  };
}

export function adminPartnerClassCSVDownloadFailure() {
  Logger.log(
    'debug',
    `[state.adminPartnerClasses.actions] adminPartnerClassCSVDownloadFailure()`
  );
  return {
    type: ADMIN_PARTNER_CLASS_CSV_DOWNLOAD_FAILURE,
  };
}

export function adminPartnerClassPartnershipListRequest(partnershipId) {
  Logger.log(
    'debug',
    `[adminPartnerClassesAdmin.actions] adminPartnerClassPartnershipListRequest(${partnershipId})`
  );
  return {
    type: ADMIN_PARTNER_CLASS_PARTNERSHIP_LIST_REQUEST,
    partnershipId: partnershipId,
  };
}

export function adminPartnerClassPartnershipListSuccess(partnershipId, data) {
  Logger.log(
    'debug',
    `[adminPartnerClassesAdmin.actions] adminPartnerClassPartnershipListSuccess(${partnershipId}, %j)`,
    data
  );
  return {
    type: ADMIN_PARTNER_CLASS_PARTNERSHIP_LIST_SUCCESS,
    partnershipId: partnershipId,
    result: data.result,
    total: data.total,
    receivedAt: Date.now(),
  };
}

export function adminPartnerClassPartnershipListFailure(partnershipId, error) {
  Logger.log(
    'debug',
    `[adminPartnerClassesAdmin.actions] adminPartnerClassPartnershipListFailure(${partnershipId}, %j)`,
    error
  );
  return {
    type: ADMIN_PARTNER_CLASS_PARTNERSHIP_LIST_FAILURE,
    partnershipId: partnershipId,
    error: error,
  };
}

// API THUNK ACTION CREATORS

export function loadAdminPartnerClasses(
  page = 1,
  limit = 10,
  order = null,
  filter = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[adminPartnerClasses.actions] loadAdminPartnerClasses(${page}, ${limit}, ${order}, %j, ###)`,
    filter
  );

  return async function (dispatch) {
    dispatch(adminPartnerClassListRequest(page, limit, order, filter));

    // call API
    const response = await api.getAdminPartnerClasses(
      page,
      limit,
      order,
      filter
    );
    let success = false;
    let result = [];
    // get admin partner classes list success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API admin partner classes list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );

      const normalizedEntities = normalize(
        response.getIn(['data', 'admin_partner_classes']),
        [schema.adminPartnerClass]
      );
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result,
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(adminPartnerClassListSuccess(data));
      success = true;
      result = normalizedEntities.result;
    } else if (1 === page && 204 === response.get('status')) {
      Logger.log(
        'info',
        `Get API admin partner classes success [empty]. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: [],
      };
      dispatch(adminPartnerClassListSuccess(data));
      success = true;

      // get admin partner classes list failure
    } else {
      Logger.log(
        'info',
        `Get API admin partner classes list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      dispatch(adminPartnerClassListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success, result);
  };
}

export function loadAdminPartnerClass(id, cb = function () {}) {
  Logger.log(
    'debug',
    `[adminPartnerClasses.actions] loadAdminPartnerClass(${id}, ###)`
  );

  return async function (dispatch) {
    dispatch(adminPartnerClassReadRequest(id));

    // call API
    const response = await api.getAdminPartnerClass(id);
    let success = false;

    // get admin partner class success
    if (200 === response.get('status')) {
      Logger.log('info', `Get API admin partner class success. ID: ${id}.`);

      const normalizedEntities = normalize(
        [response.getIn(['data', 'admin_partner_class'])],
        [schema.adminPartnerClass]
      );
      const data = {
        id: response.getIn(['data', 'admin_partner_class', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(adminPartnerClassReadSuccess(data));
      success = true;

      // get admin partner class failure
    } else {
      Logger.log('info', `Get API admin partner class failure. ID: ${id}.`);
      dispatch(adminPartnerClassReadFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function updateAdminPartnerClass(id, data, cb = function () {}) {
  Logger.log(
    'debug',
    `[adminPartnerClasses.actions] updateAdminPartnerClass(${id}, %j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(adminPartnerClassUpdateRequest(id, data));

    // call API
    const response = await api.putAdminPartnerClass(id, data);
    let success = false;

    // put admin partner class success
    if (200 === response.get('status')) {
      Logger.log('info', `PUT API admin partner class success. User: ${id}.`);

      const normalizedEntities = normalize(
        [response.getIn(['data', 'admin_partner_class'])],
        [schema.adminPartnerClass]
      );
      const data = {
        id: response.getIn(['data', 'admin_partner_class', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(adminPartnerClassUpdateSuccess(data));
      success = true;

      // get admin partner class failure
    } else {
      Logger.log('info', `PUT API admin partner class failure. ID: ${id}.`);
      dispatch(
        adminPartnerClassUpdateFailure(response.getIn(['data', 'error']))
      );
    }

    // callback function
    cb(success);
  };
}

export function createAdminPartnerClass(data, cb = function () {}) {
  Logger.log(
    'debug',
    `[adminPartnerClasses.actions] createAdminPartnerClass(%j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(adminPartnerClassCreateRequest(data));

    // call API
    const response = await api.postAdminPartnerClasses(data);
    let success = false;

    // post admin partner classes success
    if (201 === response.get('status')) {
      Logger.log(
        'info',
        `POST API admin partner classes success. Post: ${response.getIn([
          'data',
          'admin_partner_class',
          'id',
        ])}.`
      );

      const normalizedEntities = normalize(
        [response.getIn(['data', 'admin_partner_class'])],
        [schema.adminPartnerClass]
      );
      const data = {
        id: response.getIn(['data', 'admin_partner_class', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(adminPartnerClassCreateSuccess(data));
      success = true;

      // get admin partner classes failure
    } else {
      Logger.log('info', `POST API admin partner classes failure.`);
      dispatch(
        adminPartnerClassCreateFailure(response.getIn(['data', 'error']))
      );
    }

    // callback function
    cb(success);
  };
}

export function deleteAdminPartnerClass(id, cb = function () {}) {
  Logger.log(
    'debug',
    `[adminPartnerClasses.actions] deleteAdminPartnerClass(${id}, ###)`
  );

  return async function (dispatch) {
    dispatch(adminPartnerClassDeleteRequest(id));

    // call API
    const response = await api.deleteAdminPartnerClass(id);
    let success = false;

    // delete admin partner class success
    if (204 === response.get('status')) {
      Logger.log('info', `DELETE API admin partner class success. ID: ${id}.`);

      dispatch(removeEntity({ entityType: 'adminPartnerClasses', id: id }));
      dispatch(adminPartnerClassDeleteSuccess(id));
      success = true;

      // get admin partner class failure
    } else {
      Logger.log('info', `DELETE API admin partner class failure. ID: ${id}.`);
      dispatch(
        adminPartnerClassDeleteFailure(response.getIn(['data', 'error']))
      );
    }

    // callback function
    cb(success);
  };
}

export function downloadAdminPartnerClassesCSV(
  order = null,
  filter = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.adminPartnerClasses.actions] downloadAdminPartnerClassesCSV(${order}, ${filter}, ###)`
  );

  return async function (dispatch) {
    dispatch(adminPartnerClassCSVDownloadRequest(order, filter));

    // call API
    const response = await api.getAdminPartnerClassesCSV(order, filter);
    let success = false;

    // get admin partner classes CSV success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API admin partner classes CSV success. Order: ${order}`
      );
      const now = new Date();

      // trigger browser download
      const url = window.URL.createObjectURL(new Blob([response.get('data')]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `cv-admin-partner-classes-${Format.date(now, 'YYYY-MM-DDTHHmm')}.csv`
      );
      document.body.appendChild(link);
      link.click();

      dispatch(adminPartnerClassCSVDownloadSuccess());
      success = true;

      // get admin partner classes CSV failure
    } else {
      Logger.log('info', `Get API admin partner classes CSV failure.`);
      dispatch(adminPartnerClassCSVDownloadFailure());
    }

    // callback function
    cb(success);
  };
}

export function loadAdminPartnerClassPartnership(
  partnershipId = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[adminPartnerClasses.actions] loadAdminPartnerClassPartnership(${partnershipId}`
  );

  return async function (dispatch) {
    dispatch(adminPartnerClassPartnershipListRequest(partnershipId));

    // call API
    const response = await api.getAdminPartnerClassesPartnership(partnershipId);
    let success = false;

    // get admin partner class partnership list success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API admin partner class partnership list success. ID: ${partnershipId}`
      );

      const normalizedEntities = normalize(
        response.getIn(['data', 'admin_partner_classes']),
        [schema.adminPartnerClass]
      );

      const data = {
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result,
      };
      dispatch(addEntities(normalizedEntities));
      dispatch(adminPartnerClassPartnershipListSuccess(partnershipId, data));
      success = true;
    } else if (204 === response.get('status')) {
      Logger.log(
        'info',
        `Get API admin partner class partnership success [empty]. ID: ${partnershipId}.`
      );
      const data = {
        total: 0,
        result: [],
      };
      dispatch(adminPartnerClassPartnershipListSuccess(partnershipId, data));
      success = true;

      // get admin partner class partnership list failure
    } else {
      Logger.log(
        'info',
        `Get API admin partner class partnership list failure. ID: ${partnershipId}.`
      );
      dispatch(
        adminPartnerClassPartnershipListFailure(
          partnershipId,
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success);
  };
}

Logger.log('silly', `adminPartnerClasses.actions loaded.`);
