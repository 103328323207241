import {connect} from 'react-redux';
import {List} from 'immutable';
import moment from 'moment';
import {denormalize} from 'normalizr';

import {schema} from '../../../../../../state/schema';
import {
  loadPostalCode,
  updatePostalCode,
  createPostalCode,
  deletePostalCode,
  postalCodeFormDestroy
} from '../../../../../../state/modules/locationPostalCodes/actions';
import {sendMessage} from '../../../../../../state/actions';
import PostalCodeForm from '../components/PostalCodeForm';
import Config from '../../../../../../Config';

const inputs = List([
  'postal_code',
  'region',
  'subregion',
  'city',
  'status'
]);

const mapStateToProps = (state, ownProps) => {

  const postalCode = denormalize(
    state.entities.getIn(['postalCodes', ownProps.id]),
    schema.postalCode,
    state.entities.toJS()
  );

  const data = {}, errors = {};
  for (const val of inputs.values()) {
    data[val] = state.entities.getIn(['postalCodes', ownProps.id, val], '');
    errors[val] = state.locationPostalCodes.getIn(['form', 'errors', val]);
  }
  data['country'] = postalCode && postalCode.region.country ? postalCode.region.country.id.toString() : null;
  data['region'] = postalCode && postalCode.region ? postalCode.region.id.toString() : null;
  data['subregion'] = postalCode && postalCode.subregion ? postalCode.subregion.id.toString() : null;
  Object.keys(data).forEach(x => {
    if (['status', 'rating'].includes(x)) {
      data[x] = data[x].toString();
    }
  });

  data['published_at'] = data['published_at']
    ? moment(data['published_at'], Config.get('API_DATETIME_FORMAT'))
    : moment();

  data['edited_at'] = data['edited_at']
    ? moment(data['edited_at'], Config.get('API_DATETIME_FORMAT'))
    : null;

  if (data['tags'] === '') {
    data['tags'] = [];
  }

  if (data['media'] === '') {
    data['media'] = [];
  }

  return {
    isLoading: state.locationPostalCodes.get('arePostalCodesLoading'),
    isSubmitting: state.locationPostalCodes.getIn(['form', 'isSubmitting']),
    success: state.locationPostalCodes.getIn(['form', 'success']),
    created_id: state.locationPostalCodes.getIn(['form', 'created_id']),
    data: data,
    errors: errors,
    createdAt: state.entities.getIn(['postalCodes', ownProps.id, 'created_at'], null),
    updatedAt: state.entities.getIn(['postalCodes', ownProps.id, 'updated_at'], null),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (id, cb) => {
      dispatch(loadPostalCode(id, cb));
    },
    update: (id, data, cb) => {
      dispatch(updatePostalCode(id, data, cb));
    },
    create: (data, cb) => {
      dispatch(createPostalCode(data, cb));
    },
    delete: (id, cb) => {
      dispatch(deletePostalCode(id, cb))
    },
    destroyForm: (formState) => {
      dispatch(postalCodeFormDestroy(formState));
    },
    sendMessage: (level, title, body, expires) => {
      dispatch(sendMessage(level, title, body, expires));
    },
  }
}

const PostalCodeFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PostalCodeForm);

export default PostalCodeFormContainer;
