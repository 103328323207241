import { connect } from 'react-redux';

import AlertsScreen from '../components/AlertsScreen';

const mapStateToProps = (state) => {
  return {
    total: state.alerts.get('total'),
    isLoading: state.alerts.get('isLoading'),
  };
};

const AlertsScreenContainer = connect(mapStateToProps)(AlertsScreen);

export default AlertsScreenContainer;
