import {connect} from 'react-redux';
import {denormalize} from 'normalizr';

import {schema} from '../../../../../state/schema';
import {loadAccounts, deleteAccount} from '../../../../../state/modules/accounts/actions';
import {sendMessage} from '../../../../../state/actions';
import AccountsList from '../components/AccountsList';

const mapStateToProps = (state, ownProps) => {

  const page = ownProps.page;
  const limit = ownProps.limit;
  const order = ownProps.order;
  const result = state.accounts.getIn(['pages', order, limit, page]);

  return {
    list: result 
    ? result.map(x => {
        return {
          key: x,
          ...denormalize(
            state.entities.getIn(['accounts', x]),
            schema.account,
            state.entities.toJS()
          )
        };
      }).toArray().map(x => {

        // flatten some nested objects to make table easier to work with
        return {
          plan_id: x.plan ? x.plan.id : '',
          plan_name: x.plan ? x.plan.name : '',
          owner_id: x.owner ? x.owner.id : '',
          owner_username: x.owner && x.owner.user ? x.owner.user.username : '',
          ...x,
          default_care_guide_limit: x.default_care_guide_limit ? x.default_care_guide_limit : (x.plan ? `(${x.plan.care_guide_limit})` : null),
          helper_limit: x.helper_limit ? x.helper_limit : (x.plan ? `(${x.plan.helper_limit})` : null),
          upload_gb_limit: x.upload_gb_limit ? x.upload_gb_limit : (x.plan ? `(${x.plan.upload_gb_limit})` : null),
        };
      })
    : [],
    isLoading: state.accounts.get('isLoading'),
    total: state.accounts.get('total'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (page, limit, order, filter, cb) => {
      dispatch(loadAccounts(page, limit, order, filter, cb));
    },
    remove: (id, cb) => {
      dispatch(deleteAccount(id, cb));
    },
    sendMessage: (level, title, body, expires) => {
      dispatch(sendMessage(level, title, body, expires));
    },
  }
}

const AccountsListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountsList);

export default AccountsListContainer;
