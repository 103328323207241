import {Map, List} from 'immutable';

import {
  IMPORTS_CSV_UPLOAD_URL_REQUEST,
  IMPORTS_CSV_UPLOAD_URL_SUCCESS,
  IMPORTS_CSV_UPLOAD_URL_FAILURE,
  IMPORTS_CSV_UPLOAD_REQUEST,
  IMPORTS_CSV_UPLOAD_SUCCESS,
  IMPORTS_CSV_UPLOAD_FAILURE,
  IMPORTS_CSV_MESSAGE_REQUEST,
  IMPORTS_CSV_MESSAGE_SUCCESS,
  IMPORTS_CSV_MESSAGE_FAILURE,
  IMPORTS_JOB_LIST_REQUEST,
  IMPORTS_JOB_LIST_SUCCESS,
  IMPORTS_JOB_LIST_FAILURE,
  IMPORTS_JOB_READ_REQUEST,
  IMPORTS_JOB_READ_SUCCESS,
  IMPORTS_JOB_READ_FAILURE,
} from './actions'
import Logger from '../../../lib/Logger';

export default function imports(
  state=Map({
    isLoading: false,
    isLoadingCSVUploadURL: false,
    isCSVUploading: false,
    isMessageSending: false,
  }),
  action
) {
  Logger.log('debug', `[imports.reducers] imports(%j, %j)`, state, action);

  switch(action.type) {

    case IMPORTS_CSV_UPLOAD_URL_REQUEST:
      return state.mergeDeep({
        isLoadingCSVUploadURL: true
      });

    case IMPORTS_CSV_UPLOAD_URL_SUCCESS:
      return state.mergeDeep({
        isLoadingCSVUploadURL: false,
        lastUpdated: action.receivedAt
      });

    case IMPORTS_CSV_UPLOAD_URL_FAILURE:
      return state.mergeDeep({
        isLoadingCSVUploadURL: false
      });

    case IMPORTS_CSV_UPLOAD_REQUEST:
      return state.mergeDeep({
        isCSVUploading: true,
      });

    case IMPORTS_CSV_UPLOAD_SUCCESS:
      return state.mergeDeep({
        isCSVUploading: false,
      });

    case IMPORTS_CSV_UPLOAD_FAILURE:
      return state.mergeDeep({
        isCSVUploading: false,
      });

    case IMPORTS_CSV_MESSAGE_REQUEST:
      return state.mergeDeep({
        isMessageSending: true,
      });

    case IMPORTS_CSV_MESSAGE_SUCCESS:
      return state.mergeDeep({
        isMessageSending: false,
      });

    case IMPORTS_CSV_MESSAGE_FAILURE:
      return state.mergeDeep({
        isMessageSending: false,
      });

    case IMPORTS_JOB_LIST_REQUEST:
      return state.mergeDeep({
        isLoading: true
      });

    case IMPORTS_JOB_LIST_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        pages: {
          [action.order]: {
            [action.limit]: {
              [action.page]: null
            }
          }
        },
        total: action.total,
        lastUpdated: action.receivedAt
      }).setIn(['pages', action.order, action.limit, action.page], List(action.result));

    case IMPORTS_JOB_LIST_FAILURE:
      return state.mergeDeep({
        isLoading: false
      });

    case IMPORTS_JOB_READ_REQUEST:
      return state.mergeDeep({
        isLoading: true
      });

    case IMPORTS_JOB_READ_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        lastUpdated: action.receivedAt
      });

    case IMPORTS_JOB_READ_FAILURE:
      return state.mergeDeep({
        isLoading: false
      });

    default:
      return state;
  }
}

Logger.log('silly', `imports.reducers loaded.`);
