import { normalize } from 'normalizr';

import api from '../../api';
import { schema } from '../../schema';
import { addEntities } from '../../actions';
import Logger from '../../../lib/Logger';

// ACTION TYPES

export const REGISTRATION_LIST_REQUEST = 'REGISTRATION_LIST_REQUEST';
export const REGISTRATION_LIST_SUCCESS = 'REGISTRATION_LIST_SUCCESS';
export const REGISTRATION_LIST_FAILURE = 'REGISTRATION_LIST_FAILURE';
export const REGISTRATION_READ_REQUEST = 'REGISTRATION_READ_REQUEST';
export const REGISTRATION_READ_SUCCESS = 'REGISTRATION_READ_SUCCESS';
export const REGISTRATION_READ_FAILURE = 'REGISTRATION_READ_FAILURE';

// ACTION CREATORS

export function registrationListRequest(page, limit, order, filter) {
  Logger.log(
    'debug',
    `[registration.actions] registrationListRequest(${page}, ${limit}, ${order}, %j)`,
    filter
  );
  return {
    type: REGISTRATION_LIST_REQUEST,
    page: page,
    limit: limit,
    order: order,
    filter: filter,
  };
}

export function registrationListSuccess(data) {
  Logger.log(
    'debug',
    `[registration.actions] registrationListSuccess(%j)`,
    data
  );
  return {
    type: REGISTRATION_LIST_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now(),
  };
}

export function registrationListFailure(error) {
  Logger.log(
    'debug',
    `[registration.actions] registrationListFailure(%j)`,
    error
  );
  return {
    type: REGISTRATION_LIST_FAILURE,
    error: error,
  };
}

export function registrationReadRequest(id) {
  Logger.log('debug', `[registration.actions] registrationReadRequest(${id})`);
  return {
    type: REGISTRATION_READ_REQUEST,
    id: id,
  };
}

export function registrationReadSuccess(data) {
  Logger.log(
    'debug',
    `[registration.actions] registrationReadSuccess(%j)`,
    data
  );
  return {
    type: REGISTRATION_READ_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function registrationReadFailure(error) {
  Logger.log(
    'debug',
    `[registration.actions] registrationReadFailure(%j)`,
    error
  );
  return {
    type: REGISTRATION_READ_FAILURE,
    error: error,
  };
}

// API THUNK ACTION CREATORS

export function loadRegistrations(
  page = 1,
  limit = 10,
  order = null,
  filter = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[registration.actions] loadRegistrations(${page}, ${limit}, ${order}, ###)`
  );

  return async function (dispatch) {
    dispatch(registrationListRequest(page, limit, order, filter));

    // call API
    const response = await api.getRegistrations(page, limit, order, filter);
    let success = false;

    // get registrations list success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API registrations list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );

      const normalizedEntities = normalize(
        response.getIn(['data', 'registrations']),
        [schema.registration]
      );
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result,
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(registrationListSuccess(data));
      success = true;
    } else if (1 === page && 204 === response.get('status')) {
      Logger.log(
        'info',
        `Get API registrations list success [empty]. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: [],
      };
      dispatch(registrationListSuccess(data));

      // get registrations list failure
    } else {
      Logger.log(
        'info',
        `Get API registrations list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      dispatch(registrationListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function loadRegistration(id, cb = function () {}) {
  Logger.log(
    'debug',
    `[registration.actions] loadRegistrationCode(${id}, ###)`
  );

  return async function (dispatch) {
    dispatch(registrationReadRequest(id));

    // call API
    const response = await api.getRegistration(id);
    let success = false;

    // get registration success
    if (200 === response.get('status')) {
      Logger.log('info', `Get API registration success. ID: ${id}.`);

      const normalizedEntities = normalize(
        [response.getIn(['data', 'registration'])],
        [schema.registration]
      );
      const data = {
        id: response.getIn(['data', 'registration', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(registrationReadSuccess(data));
      success = true;

      // get registration failure
    } else {
      Logger.log('info', `Get API registration failure. ID: ${id}.`);
      dispatch(registrationReadFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

Logger.log('silly', `registrations.actions loaded.`);
