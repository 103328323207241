import { normalize } from 'normalizr';

import api from '../../api';
import { schema } from '../../schema';
import { addEntities, removeEntity } from '../../actions';
import Format from '../../../lib/Format';
import Logger from '../../../lib/Logger';

// ACTION TYPES

export const JOURNAL_PARTNER_TAG_LIST_REQUEST =
  'JOURNAL_PARTNER_TAG_LIST_REQUEST';
export const JOURNAL_PARTNER_TAG_LIST_SUCCESS =
  'JOURNAL_PARTNER_TAG_LIST_SUCCESS';
export const JOURNAL_PARTNER_TAG_LIST_FAILURE =
  'JOURNAL_PARTNER_TAG_LIST_FAILURE';
export const JOURNAL_PARTNER_TAG_READ_REQUEST =
  'JOURNAL_PARTNER_TAG_READ_REQUEST';
export const JOURNAL_PARTNER_TAG_READ_SUCCESS =
  'JOURNAL_PARTNER_TAG_READ_SUCCESS';
export const JOURNAL_PARTNER_TAG_READ_FAILURE =
  'JOURNAL_PARTNER_TAG_READ_FAILURE';
export const JOURNAL_PARTNER_TAG_UPDATE_REQUEST =
  'JOURNAL_PARTNER_TAG_UPDATE_REQUEST';
export const JOURNAL_PARTNER_TAG_UPDATE_SUCCESS =
  'JOURNAL_PARTNER_TAG_UPDATE_SUCCESS';
export const JOURNAL_PARTNER_TAG_UPDATE_FAILURE =
  'JOURNAL_PARTNER_TAG_UPDATE_FAILURE';
export const JOURNAL_PARTNER_TAG_CREATE_REQUEST =
  'JOURNAL_PARTNER_TAG_CREATE_REQUEST';
export const JOURNAL_PARTNER_TAG_CREATE_SUCCESS =
  'JOURNAL_PARTNER_TAG_CREATE_SUCCESS';
export const JOURNAL_PARTNER_TAG_CREATE_FAILURE =
  'JOURNAL_PARTNER_TAG_CREATE_FAILURE';
export const JOURNAL_PARTNER_TAG_DELETE_REQUEST =
  'JOURNAL_PARTNER_TAG_DELETE_REQUEST';
export const JOURNAL_PARTNER_TAG_DELETE_SUCCESS =
  'JOURNAL_PARTNER_TAG_DELETE_SUCCESS';
export const JOURNAL_PARTNER_TAG_DELETE_FAILURE =
  'JOURNAL_PARTNER_TAG_DELETE_FAILURE';
export const JOURNAL_PARTNER_TAG_FORM_DESTROY =
  'JOURNAL_PARTNER_TAG_FORM_DESTROY';
export const JOURNAL_PARTNER_TAG_CSV_DOWNLOAD_REQUEST =
  'JOURNAL_PARTNER_TAG_CSV_DOWNLOAD_REQUEST';
export const JOURNAL_PARTNER_TAG_CSV_DOWNLOAD_SUCCESS =
  'JOURNAL_PARTNER_TAG_CSV_DOWNLOAD_SUCCESS';
export const JOURNAL_PARTNER_TAG_CSV_DOWNLOAD_FAILURE =
  'JOURNAL_PARTNER_TAG_CSV_DOWNLOAD_FAILURE';
export const JOURNAL_PARTNER_TAG_CARE_GUIDE_LIST_REQUEST =
  'JOURNAL_PARTNER_TAG_CARE_GUIDE_LIST_REQUEST';
export const JOURNAL_PARTNER_TAG_CARE_GUIDE_LIST_SUCCESS =
  'JOURNAL_PARTNER_TAG_CARE_GUIDE_LIST_SUCCESS';
export const JOURNAL_PARTNER_TAG_CARE_GUIDE_LIST_FAILURE =
  'JOURNAL_PARTNER_TAG_CARE_GUIDE_LIST_FAILURE';
// ACTION CREATORS

export function journalPartnerTagListRequest(page, limit, order, filter) {
  Logger.log(
    'debug',
    `[journalPartnerTags.actions] journalPartnerTagListRequest(${page}, ${limit}, ${order}, %j)`,
    filter
  );
  return {
    type: JOURNAL_PARTNER_TAG_LIST_REQUEST,
    page: page,
    limit: limit,
    order: order,
    filter: filter,
  };
}

export function journalPartnerTagListSuccess(data) {
  Logger.log(
    'debug',
    `[journalPartnerTags.actions] journalPartnerTagListSuccess(%j)`,
    data
  );
  return {
    type: JOURNAL_PARTNER_TAG_LIST_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now(),
  };
}

export function journalPartnerTagListFailure(error) {
  Logger.log(
    'debug',
    `[journalPartnerTags.actions] journalPartnerTagListFailure(%j)`,
    error
  );
  return {
    type: JOURNAL_PARTNER_TAG_LIST_FAILURE,
    error: error,
  };
}

export function journalPartnerTagCareGuideListRequest(
  careGuideId,
  page,
  limit,
  order,
  filter
) {
  Logger.log(
    'debug',
    `[journalPartnerTags.actions] journalPartnerTagCareGuideListRequest(${careGuideId}, ${page}, ${limit}, ${order}, %j)`,
    filter
  );
  return {
    type: JOURNAL_PARTNER_TAG_CARE_GUIDE_LIST_REQUEST,
    careGuideId: careGuideId,
    page: page,
    limit: limit,
    order: order,
    filter: filter,
  };
}

export function journalPartnerTagCareGuideListSuccess(careGuideId, data) {
  Logger.log(
    'debug',
    `[journalPartnerTags.actions] journalPartnerTagCareGuideListSuccess(${careGuideId}, %j)`,
    data
  );
  return {
    type: JOURNAL_PARTNER_TAG_CARE_GUIDE_LIST_SUCCESS,
    careGuideId: careGuideId,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now(),
  };
}

export function journalPartnerTagCareGuideListFailure(careGuideId, error) {
  Logger.log(
    'debug',
    `[journalPartnerTags.actions] journalPartnerTagCareGuideListFailure(${careGuideId}, %j)`,
    error
  );
  return {
    type: JOURNAL_PARTNER_TAG_CARE_GUIDE_LIST_FAILURE,
    careGuideId: careGuideId,
    error: error,
  };
}

export function journalPartnerTagReadRequest(id) {
  Logger.log(
    'debug',
    `[journalPartnerTags.actions] journalPartnerTagReadRequest(${id})`
  );
  return {
    type: JOURNAL_PARTNER_TAG_READ_REQUEST,
    id: id,
  };
}

export function journalPartnerTagReadSuccess(data) {
  Logger.log(
    'debug',
    `[journalPartnerTags.actions] journalPartnerTagReadSuccess(%j)`,
    data
  );
  return {
    type: JOURNAL_PARTNER_TAG_READ_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function journalPartnerTagReadFailure(error) {
  Logger.log(
    'debug',
    `[journalPartnerTags.actions] journalPartnerTagReadFailure(%j)`,
    error
  );
  return {
    type: JOURNAL_PARTNER_TAG_READ_FAILURE,
    error: error,
  };
}

export function journalPartnerTagUpdateRequest(id, data) {
  Logger.log(
    'debug',
    `[journalPartnerTags.actions] journalPartnerTagUpdateRequest(${id}, %j)`,
    data
  );
  return {
    type: JOURNAL_PARTNER_TAG_UPDATE_REQUEST,
  };
}

export function journalPartnerTagUpdateSuccess(data) {
  Logger.log(
    'debug',
    `[journalPartnerTags.actions] journalPartnerTagUpdateSuccess(%j)`,
    data
  );
  return {
    type: JOURNAL_PARTNER_TAG_UPDATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function journalPartnerTagUpdateFailure(error) {
  Logger.log(
    'debug',
    `[journalPartnerTags.actions] journalPartnerTagUpdateFailure(%j)`,
    error
  );
  return {
    type: JOURNAL_PARTNER_TAG_UPDATE_FAILURE,
    error: error,
  };
}

export function journalPartnerTagCreateRequest(data) {
  Logger.log(
    'debug',
    `[journalPartnerTags.actions] journalPartnerTagCreateRequest(%j)`,
    data
  );
  return {
    type: JOURNAL_PARTNER_TAG_CREATE_REQUEST,
  };
}

export function journalPartnerTagCreateSuccess(data) {
  Logger.log(
    'debug',
    `[journalPartnerTags.actions] journalPartnerTagCreateSuccess(%j)`,
    data
  );
  return {
    type: JOURNAL_PARTNER_TAG_CREATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function journalPartnerTagCreateFailure(error) {
  Logger.log(
    'debug',
    `[journalPartnerTags.actions] journalPartnerTagCreateFailure(%j)`,
    error
  );
  return {
    type: JOURNAL_PARTNER_TAG_CREATE_FAILURE,
    error: error,
  };
}

export function journalPartnerTagDeleteRequest(id) {
  Logger.log(
    'debug',
    `[journalPartnerTags.actions] journalPartnerTagDeleteRequest(${id})`
  );
  return {
    type: JOURNAL_PARTNER_TAG_DELETE_REQUEST,
    id: id,
  };
}

export function journalPartnerTagDeleteSuccess(id) {
  Logger.log(
    'debug',
    `[journalPartnerTags.actions] journalPartnerTagDeleteSuccess(${id})`
  );
  return {
    type: JOURNAL_PARTNER_TAG_DELETE_SUCCESS,
    id: id,
  };
}

export function journalPartnerTagDeleteFailure(error) {
  Logger.log(
    'debug',
    `[journalPartnerTags.actions] journalPartnerTagDeleteFailure(%j)`,
    error
  );
  return {
    type: JOURNAL_PARTNER_TAG_DELETE_FAILURE,
    error: error,
  };
}

export function journalPartnerTagFormDestroy(formState = null) {
  Logger.log(
    'debug',
    `[journalPartnerTags.actions] journalPartnerTagFormDestroy(%j)`,
    formState
  );
  return {
    type: JOURNAL_PARTNER_TAG_FORM_DESTROY,
    form: formState,
  };
}

export function journalPartnerTagCSVDownloadRequest(order, filter) {
  Logger.log(
    'debug',
    `[state.journalPartnerTags.actions] journalPartnerTagCSVDownloadRequest()`
  );
  return {
    type: JOURNAL_PARTNER_TAG_CSV_DOWNLOAD_REQUEST,
    order: order,
    filter: filter,
  };
}

export function journalPartnerTagCSVDownloadSuccess() {
  Logger.log(
    'debug',
    `[state.journalPartnerTags.actions] journalPartnerTagCSVDownloadSuccess()`
  );
  return {
    type: JOURNAL_PARTNER_TAG_CSV_DOWNLOAD_SUCCESS,
  };
}

export function journalPartnerTagCSVDownloadFailure() {
  Logger.log(
    'debug',
    `[state.journalPartnerTags.actions] journalPartnerTagCSVDownloadFailure()`
  );
  return {
    type: JOURNAL_PARTNER_TAG_CSV_DOWNLOAD_FAILURE,
  };
}

// API THUNK ACTION CREATORS

export function loadJournalPartnerTags(
  page = 1,
  limit = 10,
  order = null,
  filter = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[journalPartnerTags.actions] loadJournalPartnerTags(${page}, ${limit}, ${order}, %j, ###)`,
    filter
  );

  return async function (dispatch) {
    dispatch(journalPartnerTagListRequest(page, limit, order, filter));

    // call API
    const response = await api.getJournalPartnerTags(
      page,
      limit,
      order,
      filter
    );
    let success = false;

    // get journal partner tags list success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API journal partner tags list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );

      const normalizedEntities = normalize(
        response.getIn(['data', 'journal_partner_tags']),
        [schema.journalPartnerTag]
      );
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result,
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(journalPartnerTagListSuccess(data));
      success = true;
    } else if (1 === page && 204 === response.get('status')) {
      Logger.log(
        'info',
        `Get API journal partner tags success [empty]. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: [],
      };
      dispatch(journalPartnerTagListSuccess(data));
      success = true;

      // get journal partner tags list failure
    } else {
      Logger.log(
        'info',
        `Get API journal partner tags list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      dispatch(journalPartnerTagListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function loadJournalPartnerTag(id, cb = function () {}) {
  Logger.log(
    'debug',
    `[journalPartnerTags.actions] loadJournalPartnerTag(${id}, ###)`
  );

  return async function (dispatch) {
    dispatch(journalPartnerTagReadRequest(id));

    // call API
    const response = await api.getJournalPartnerTag(id);
    let success = false;

    // get journal partner tag success
    if (200 === response.get('status')) {
      Logger.log('info', `Get API journal partner tag success. ID: ${id}.`);

      const normalizedEntities = normalize(
        [response.getIn(['data', 'journal_partner_tag'])],
        [schema.journalPartnerTag]
      );
      const data = {
        id: response.getIn(['data', 'journal_partner_tag', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(journalPartnerTagReadSuccess(data));
      success = true;

      // get journal partner tag failure
    } else {
      Logger.log('info', `Get API journal partner tag failure. ID: ${id}.`);
      dispatch(journalPartnerTagReadFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function updateJournalPartnerTag(id, data, cb = function () {}) {
  Logger.log(
    'debug',
    `[journalPartnerTags.actions] updateJournalPartnerTag(${id}, %j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(journalPartnerTagUpdateRequest(id, data));

    // call API
    const response = await api.putJournalPartnerTag(id, data);
    let success = false;

    // put journal partner tag success
    if (200 === response.get('status')) {
      Logger.log('info', `PUT API journal partner tag success. User: ${id}.`);

      const normalizedEntities = normalize(
        [response.getIn(['data', 'journal_partner_tag'])],
        [schema.journalPartnerTag]
      );
      const data = {
        id: response.getIn(['data', 'journal_partner_tag', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(journalPartnerTagUpdateSuccess(data));
      success = true;

      // get journal partner tag failure
    } else {
      Logger.log('info', `PUT API journal partner tag failure. ID: ${id}.`);
      dispatch(
        journalPartnerTagUpdateFailure(response.getIn(['data', 'error']))
      );
    }

    // callback function
    cb(success);
  };
}

export function createJournalPartnerTag(data, cb = function () {}) {
  Logger.log(
    'debug',
    `[journalPartnerTags.actions] createJournalPartnerTag(%j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(journalPartnerTagCreateRequest(data));

    // call API
    const response = await api.postJournalPartnerTags(data);
    let success = false;

    // post journal partner tags success
    if (201 === response.get('status')) {
      Logger.log(
        'info',
        `POST API journal partner tags success. Post: ${response.getIn([
          'data',
          'journal_partner_tag',
          'id',
        ])}.`
      );

      const normalizedEntities = normalize(
        [response.getIn(['data', 'journal_partner_tag'])],
        [schema.journalPartnerTag]
      );
      const data = {
        id: response.getIn(['data', 'journal_partner_tag', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(journalPartnerTagCreateSuccess(data));
      success = true;

      // get journal partner tags failure
    } else {
      Logger.log('info', `POST API journal partner tags failure.`);
      dispatch(
        journalPartnerTagCreateFailure(response.getIn(['data', 'error']))
      );
    }

    // callback function
    cb(success);
  };
}

export function deleteJournalPartnerTag(id, cb = function () {}) {
  Logger.log(
    'debug',
    `[journalPartnerTags.actions] deleteJournalPartnerTag(${id}, ###)`
  );

  return async function (dispatch) {
    dispatch(journalPartnerTagDeleteRequest(id));

    // call API
    const response = await api.deleteJournalPartnerTag(id);
    let success = false;

    // delete journal partner tag success
    if (204 === response.get('status')) {
      Logger.log('info', `DELETE API journal partner tag success. ID: ${id}.`);

      dispatch(removeEntity({ entityType: 'journalPartnerTags', id: id }));
      dispatch(journalPartnerTagDeleteSuccess(id));
      success = true;

      // get journal partner tag failure
    } else {
      Logger.log('info', `DELETE API journal partner tag failure. ID: ${id}.`);
      dispatch(
        journalPartnerTagDeleteFailure(response.getIn(['data', 'error']))
      );
    }

    // callback function
    cb(success);
  };
}

export function downloadJournalPartnerTagsCSV(
  order = null,
  filter = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.journalPartnerTags.actions] downloadJournalPartnerTagsCSV(${order}, ${filter}, ###)`
  );

  return async function (dispatch) {
    dispatch(journalPartnerTagCSVDownloadRequest(order, filter));

    // call API
    const response = await api.getJournalPartnerTagsCSV(order, filter);
    let success = false;

    // get journal partner tags CSV success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API journal partner tags CSV success. Order: ${order}`
      );
      const now = new Date();

      // trigger browser download
      const url = window.URL.createObjectURL(new Blob([response.get('data')]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `cv-journal-partner-tags-${Format.date(now, 'YYYY-MM-DDTHHmm')}.csv`
      );
      document.body.appendChild(link);
      link.click();

      dispatch(journalPartnerTagCSVDownloadSuccess());
      success = true;

      // get journal partner tags CSV failure
    } else {
      Logger.log('info', `Get API journal partner tags CSV failure.`);
      dispatch(journalPartnerTagCSVDownloadFailure());
    }

    // callback function
    cb(success);
  };
}

export function loadJournalPartnerTagsCareGuide(
  careGuideId,
  page = 1,
  limit = 10,
  order = null,
  filter = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[journalPartnerTags.actions] loadJournalPartnerTagsCareGuide(${careGuideId}, ${page}, ${limit}, ${order}, %j, ###)`,
    filter
  );

  return async function (dispatch) {
    dispatch(
      journalPartnerTagCareGuideListRequest(
        careGuideId,
        page,
        limit,
        order,
        filter
      )
    );

    // call API
    const response = await api.getJournalPartnerTagCareGuide(
      careGuideId,
      page,
      limit,
      order,
      filter
    );
    let success = false;

    // get journal partner tags care guide list success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API journal partner tags care guide list success. ID: ${careGuideId}, Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );

      const normalizedEntities = normalize(
        response.getIn(['data', 'journal_partner_tags_care_guide']),
        [schema.journalPartnerTagsCareGuide]
      );
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result,
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(journalPartnerTagCareGuideListSuccess(careGuideId, data));
      success = true;
    } else if (1 === page && 204 === response.get('status')) {
      Logger.log(
        'info',
        `Get API journal partner tags care guide success [empty]. ID: ${careGuideId}, Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: [],
      };
      dispatch(journalPartnerTagCareGuideListSuccess(careGuideId, data));
      success = true;

      // get journal partner tags care guide list failure
    } else {
      Logger.log(
        'info',
        `Get API journal partner tags care guide  list failure. ID: ${careGuideId}, Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      dispatch(
        journalPartnerTagCareGuideListFailure(
          careGuideId,
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success);
  };
}

Logger.log('silly', `journalPartnerTags.actions loaded.`);
