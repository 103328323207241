import { normalize } from 'normalizr';

import api from '../../api';
import { schema } from '../../schema';
import { addEntities, removeEntity } from '../../actions';
import Format from '../../../lib/Format';
import Logger from '../../../lib/Logger';

// ACTION TYPES

export const RESOURCE_LIST_REQUEST = 'RESOURCE_LIST_REQUEST';
export const RESOURCE_LIST_SUCCESS = 'RESOURCE_LIST_SUCCESS';
export const RESOURCE_LIST_FAILURE = 'RESOURCE_LIST_FAILURE';
export const RESOURCE_READ_REQUEST = 'RESOURCE_READ_REQUEST';
export const RESOURCE_READ_SUCCESS = 'RESOURCE_READ_SUCCESS';
export const RESOURCE_READ_FAILURE = 'RESOURCE_READ_FAILURE';
export const RESOURCE_UPDATE_REQUEST = 'RESOURCE_UPDATE_REQUEST';
export const RESOURCE_UPDATE_SUCCESS = 'RESOURCE_UPDATE_SUCCESS';
export const RESOURCE_UPDATE_FAILURE = 'RESOURCE_UPDATE_FAILURE';
export const RESOURCE_CREATE_REQUEST = 'RESOURCE_CREATE_REQUEST';
export const RESOURCE_CREATE_SUCCESS = 'RESOURCE_CREATE_SUCCESS';
export const RESOURCE_CREATE_FAILURE = 'RESOURCE_CREATE_FAILURE';
export const RESOURCE_DELETE_REQUEST = 'RESOURCE_DELETE_REQUEST';
export const RESOURCE_DELETE_SUCCESS = 'RESOURCE_DELETE_SUCCESS';
export const RESOURCE_DELETE_FAILURE = 'RESOURCE_DELETE_FAILURE';
export const RESOURCE_FORM_DESTROY = 'RESOURCE_FORM_DESTROY';
export const RESOURCE_GEOCODE_REQUEST = 'RESOURCE_GEOCODE_REQUEST';
export const RESOURCE_GEOCODE_SUCCESS = 'RESOURCE_GEOCODE_SUCCESS';
export const RESOURCE_GEOCODE_FAILURE = 'RESOURCE_GEOCODE_FAILURE';
export const RESOURCE_CSV_DOWNLOAD_REQUEST = 'RESOURCE_CSV_DOWNLOAD_REQUEST';
export const RESOURCE_CSV_DOWNLOAD_SUCCESS = 'RESOURCE_CSV_DOWNLOAD_SUCCESS';
export const RESOURCE_CSV_DOWNLOAD_FAILURE = 'RESOURCE_CSV_DOWNLOAD_FAILURE';

// ACTION CREATORS

export function resourceListRequest(page, limit, order, filter) {
  Logger.log(
    'debug',
    `[resources.actions] resourceListRequest(${page}, ${limit}, ${order}, %j)`,
    filter
  );
  return {
    type: RESOURCE_LIST_REQUEST,
    page: page,
    limit: limit,
    order: order,
    filter: filter,
  };
}

export function resourceListSuccess(data) {
  Logger.log('debug', `[resources.actions] resourceListSuccess(%j)`, data);
  return {
    type: RESOURCE_LIST_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now(),
  };
}

export function resourceListFailure(error) {
  Logger.log('debug', `[resources.actions] resourceListFailure(%j)`, error);
  return {
    type: RESOURCE_LIST_FAILURE,
    error: error,
  };
}

export function resourceReadRequest(id) {
  Logger.log('debug', `[resources.actions] resourceReadRequest(${id})`);
  return {
    type: RESOURCE_READ_REQUEST,
    id: id,
  };
}

export function resourceReadSuccess(data) {
  Logger.log('debug', `[resources.actions] resourceReadSuccess(%j)`, data);
  return {
    type: RESOURCE_READ_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function resourceReadFailure(error) {
  Logger.log('debug', `[resources.actions] resourceReadFailure(%j)`, error);
  return {
    type: RESOURCE_READ_FAILURE,
    error: error,
  };
}

export function resourceUpdateRequest(id, data) {
  Logger.log(
    'debug',
    `[resources.actions] resourceUpdateRequest(${id}, %j)`,
    data
  );
  return {
    type: RESOURCE_UPDATE_REQUEST,
  };
}

export function resourceUpdateSuccess(data) {
  Logger.log('debug', `[resources.actions] resourceUpdateSuccess(%j)`, data);
  return {
    type: RESOURCE_UPDATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function resourceUpdateFailure(error) {
  Logger.log('debug', `[resources.actions] resourceUpdateFailure(%j)`, error);
  return {
    type: RESOURCE_UPDATE_FAILURE,
    error: error,
  };
}

export function resourceCreateRequest(data) {
  Logger.log('debug', `[resources.actions] resourceCreateRequest(%j)`, data);
  return {
    type: RESOURCE_CREATE_REQUEST,
  };
}

export function resourceCreateSuccess(data) {
  Logger.log('debug', `[resources.actions] resourceCreateSuccess(%j)`, data);
  return {
    type: RESOURCE_CREATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function resourceCreateFailure(error) {
  Logger.log('debug', `[resources.actions] resourceCreateFailure(%j)`, error);
  return {
    type: RESOURCE_CREATE_FAILURE,
    error: error,
  };
}

export function resourceDeleteRequest(id) {
  Logger.log('debug', `[resources.actions] resourceDeleteRequest(${id})`);
  return {
    type: RESOURCE_DELETE_REQUEST,
    id: id,
  };
}

export function resourceDeleteSuccess(id) {
  Logger.log('debug', `[resources.actions] resourceDeleteSuccess(${id})`);
  return {
    type: RESOURCE_DELETE_SUCCESS,
    id: id,
  };
}

export function resourceDeleteFailure(error) {
  Logger.log('debug', `[resources.actions] resourceDeleteFailure(%j)`, error);
  return {
    type: RESOURCE_DELETE_FAILURE,
    error: error,
  };
}

export function resourceFormDestroy(formState = null) {
  Logger.log('debug', `[resources.actions] resourceFormDestroy(%j)`, formState);
  return {
    type: RESOURCE_FORM_DESTROY,
    form: formState,
  };
}

export function resourceGeocodeRequest(location) {
  Logger.log(
    'debug',
    `[resources.actions] resourceGeocodeRequest(${location})`
  );
  return {
    type: RESOURCE_GEOCODE_REQUEST,
    location: location,
  };
}

export function resourceGeocodeSuccess(data) {
  Logger.log('debug', `[resources.actions] resourceGeocodeSuccess(%j)`, data);
  return {
    type: RESOURCE_GEOCODE_SUCCESS,
    location: data.location,
    latitude: data.latitude,
    longitude: data.longitude,
    receivedAt: Date.now(),
  };
}

export function resourceGeocodeFailure(error) {
  Logger.log('debug', `[resources.actions] resourceGeocodeFailure(%j)`, error);
  return {
    type: RESOURCE_GEOCODE_FAILURE,
    error: error,
  };
}

export function resourceCSVDownloadRequest(order, filter) {
  Logger.log('debug', `[state.resources.actions] resourceCSVDownloadRequest()`);
  return {
    type: RESOURCE_CSV_DOWNLOAD_REQUEST,
    order: order,
    filter: filter,
  };
}

export function resourceCSVDownloadSuccess() {
  Logger.log('debug', `[state.resources.actions] resourceCSVDownloadSuccess()`);
  return {
    type: RESOURCE_CSV_DOWNLOAD_SUCCESS,
  };
}

export function resourceCSVDownloadFailure() {
  Logger.log('debug', `[state.resources.actions] resourceCSVDownloadFailure()`);
  return {
    type: RESOURCE_CSV_DOWNLOAD_FAILURE,
  };
}

// API THUNK ACTION CREATORS

export function loadResources(
  page = 1,
  limit = 10,
  order = null,
  filter = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[resources.actions] loadResources(${page}, ${limit}, ${order}, %j, ###)`,
    filter
  );

  return async function (dispatch) {
    dispatch(resourceListRequest(page, limit, order, filter));

    // call API
    const response = await api.getResources(page, limit, order, filter);
    let success = false;
    let result = [];

    // get resources list success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API resources list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );

      const normalizedEntities = normalize(
        response.getIn(['data', 'resources']),
        [schema.resource]
      );
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result,
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(resourceListSuccess(data));
      success = true;
      result = normalizedEntities.result;
    } else if (1 === page && 204 === response.get('status')) {
      Logger.log(
        'info',
        `Get API resources success [empty]. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: [],
      };
      dispatch(resourceListSuccess(data));
      success = true;

      // get resources list failure
    } else {
      Logger.log(
        'info',
        `Get API resources list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      dispatch(resourceListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success, result);
  };
}

export function loadResource(id, cb = function () {}) {
  Logger.log('debug', `[resources.actions] loadResource(${id}, ###)`);

  return async function (dispatch) {
    dispatch(resourceReadRequest(id));

    // call API
    const response = await api.getResource(id);
    let success = false;

    // get resource success
    if (200 === response.get('status')) {
      Logger.log('info', `Get API resource success. ID: ${id}.`);

      const normalizedEntities = normalize(
        [response.getIn(['data', 'resource'])],
        [schema.resource]
      );
      const data = {
        id: response.getIn(['data', 'resource', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(resourceReadSuccess(data));
      success = true;

      // get resource failure
    } else {
      Logger.log('info', `Get API resource failure. ID: ${id}.`);
      dispatch(resourceReadFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function updateResource(id, data, cb = function () {}) {
  Logger.log(
    'debug',
    `[resources.actions] updateResource(${id}, %j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(resourceUpdateRequest(id, data));

    // call API
    const response = await api.putResource(id, data);
    let success = false;

    // put resource success
    if (200 === response.get('status')) {
      Logger.log('info', `PUT API resource success. User: ${id}.`);

      const normalizedEntities = normalize(
        [response.getIn(['data', 'resource'])],
        [schema.resource]
      );
      const data = {
        id: response.getIn(['data', 'resource', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(resourceUpdateSuccess(data));
      success = true;

      // get resource failure
    } else {
      Logger.log('info', `PUT API resource failure. ID: ${id}.`);
      dispatch(resourceUpdateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function createResource(data, cb = function () {}) {
  Logger.log('debug', `[resources.actions] createResource(%j, ###)`, data);

  return async function (dispatch) {
    dispatch(resourceCreateRequest(data));

    // call API
    const response = await api.postResources(data);
    let success = false;

    // post resources success
    if (201 === response.get('status')) {
      Logger.log(
        'info',
        `POST API resources success. Post: ${response.getIn([
          'data',
          'resource',
          'id',
        ])}.`
      );

      const normalizedEntities = normalize(
        [response.getIn(['data', 'resource'])],
        [schema.resource]
      );
      const data = {
        id: response.getIn(['data', 'resource', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(resourceCreateSuccess(data));
      success = true;

      // get resources failure
    } else {
      Logger.log('info', `POST API resources failure.`);
      dispatch(resourceCreateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function deleteResource(id, cb = function () {}) {
  Logger.log('debug', `[resources.actions] deleteResource(${id}, ###)`);

  return async function (dispatch) {
    dispatch(resourceDeleteRequest(id));

    // call API
    const response = await api.deleteResource(id);
    let success = false;

    // delete resource success
    if (204 === response.get('status')) {
      Logger.log('info', `DELETE API resource success. ID: ${id}.`);

      dispatch(removeEntity({ entityType: 'resources', id: id }));
      dispatch(resourceDeleteSuccess(id));
      success = true;

      // get resource failure
    } else {
      Logger.log('info', `DELETE API resource failure. ID: ${id}.`);
      dispatch(resourceDeleteFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function getGeocode(location, cb = function () {}) {
  Logger.log('debug', `[resources.actions] getGeocode(${location}, ###)`);

  return async function (dispatch) {
    dispatch(resourceGeocodeRequest(location));

    // call API
    const response = await api.getGoogleMapsAPIGeocode(location);
    let success = false;
    let data = {};

    // get geocode success
    if (200 === response.get('status')) {
      Logger.log('info', `Get API geocode success.`);

      data = {
        location: location,
        latitude: response.getIn([
          'data',
          'results',
          0,
          'geometry',
          'location',
          'lat',
        ]),
        longitude: response.getIn([
          'data',
          'results',
          0,
          'geometry',
          'location',
          'lng',
        ]),
      };

      dispatch(resourceGeocodeSuccess(data));
      success = true;

      // get geocode failure
    } else {
      Logger.log('info', `Get API geocode failure.`);
      dispatch(resourceGeocodeFailure(response.getIn(['error_message'])));
    }

    // callback function
    cb(success, data);
  };
}

export function downloadResourcesCSV(
  order = null,
  filter = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.resources.actions] downloadResourcesCSV(${order}, ${filter}, ###)`
  );

  return async function (dispatch) {
    dispatch(resourceCSVDownloadRequest(order, filter));

    // call API
    const response = await api.getResourcesCSV(order, filter);
    let success = false;

    // get resources CSV success
    if (200 === response.get('status')) {
      Logger.log('info', `Get API resources CSV success. Order: ${order}`);
      const now = new Date();

      // trigger browser download
      const url = window.URL.createObjectURL(new Blob([response.get('data')]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `cv-resources-${Format.date(now, 'YYYY-MM-DDTHHmm')}.csv`
      );
      document.body.appendChild(link);
      link.click();

      dispatch(resourceCSVDownloadSuccess());
      success = true;

      // get resources CSV failure
    } else {
      Logger.log('info', `Get API resources CSV failure.`);
      dispatch(resourceCSVDownloadFailure());
    }

    // callback function
    cb(success);
  };
}

Logger.log('silly', `resources.actions loaded.`);
