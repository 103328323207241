import { connect } from 'react-redux';

import { loadAccounts } from '../../../../../state/modules/accounts/actions';
import AccountInput from '../components/AccountInput';

const mapStateToProps = (state) => {
  return {
    total: state.accounts.get('total'),
    isLoading: state.accounts.get('isLoading'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (page, limit, order, filter, cb) => {
      dispatch(loadAccounts(page, limit, order, filter, cb));
    },
  };
};

const AccountInputContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountInput);

export default AccountInputContainer;
