import {getI18n} from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import RegistrationsScreen from './containers/RegistrationsScreenContainer';
import RegistrationViewScreen from './containers/RegistrationViewScreenContainer';

const register = () => {
  Logger.log('debug', `registration.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'registration', 'routes'])) {
    Events.dispatch('ADD_MAIN_ROUTES', 
      {
        'RegistrationViewScreen': ['PrivateRoute', '/registrations/view/:id([\\w\\-]+)', true, RegistrationViewScreen, getI18n().t('registrations_route_view')],
        'RegistrationsScreen': ['PrivateRoute', '/registrations/:page(\\d+)?', true, RegistrationsScreen, getI18n().t('registrations_route_list')],
      }
    );
  }
}

export default register;

Logger.log('silly', `registration.register() loaded.`);
