import {connect} from 'react-redux';

import MessageEditScreen from '../components/MessageEditScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.messages.get('isLoading'),
    success: state.messages.getIn(['form', 'success']),
  }
}

const MessageEditScreenContainer = connect(
  mapStateToProps
)(MessageEditScreen);

export default MessageEditScreenContainer;
