import {connect} from 'react-redux';

import PostalCodeEditScreen from '../components/PostalCodeEditScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.locationPostalCodes.get('arePostalCodesLoading'),
    success: state.locationPostalCodes.getIn(['form', 'success']),
  }
}

const PostalCodeEditScreenContainer = connect(
  mapStateToProps
)(PostalCodeEditScreen);

export default PostalCodeEditScreenContainer;
