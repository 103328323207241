import {connect} from 'react-redux';

import MediaScreen from '../components/MediaScreen';

const mapStateToProps = state => {
  return {
    total: state.media.get('total'),
    isLoading: state.media.get('isLoading')
  }
}

const MediaScreenContainer = connect(
  mapStateToProps
)(MediaScreen);

export default MediaScreenContainer;
