import {connect} from 'react-redux';

import CareGuidePartnerTagEditScreen from '../components/CareGuidePartnerTagEditScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.careGuidePartnerTags.get('isLoading'),
    success: state.careGuidePartnerTags.getIn(['form', 'success']),
  }
}

const CareGuidePartnerTagEditScreenContainer = connect(
  mapStateToProps
)(CareGuidePartnerTagEditScreen);

export default CareGuidePartnerTagEditScreenContainer;