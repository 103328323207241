import {getI18n} from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import ResourceServiceTagsScreen from './containers/ResourceServiceTagsScreenContainer';
import ResourceServiceTagAddScreen from './containers/ResourceServiceTagAddScreenContainer';
import ResourceServiceTagEditScreen from './containers/ResourceServiceTagEditScreenContainer';

const register = () => {
  Logger.log('debug', `resourceServiceTags.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'resourceServiceTags', 'routes'])) {
    Events.dispatch('ADD_MAIN_ROUTES', 
      {
        'ResourceServiceTagAddScreen': ['PrivateRoute', '/resource-service-tags/add', true, ResourceServiceTagAddScreen, getI18n().t('resource_service_tag_route_add')],
        'ResourceServiceTagEditScreen': ['PrivateRoute', '/resource-service-tags/edit/:id([\\w\\-]+)', true, ResourceServiceTagEditScreen, getI18n().t('resource_service_tag_route_edit')],
        'ResourceServiceTagsScreen': ['PrivateRoute', '/resource-service-tags/:page(\\d+)?', true, ResourceServiceTagsScreen, getI18n().t('resource_service_tags_route_list')],
      }
    );
  }
}

export default register;

Logger.log('silly', `resourceServiceTags.register() loaded.`);
