import {getI18n} from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import AdminPartnerClassesScreen from './containers/AdminPartnerClassesScreenContainer';
import AdminPartnerClassAddScreen from './containers/AdminPartnerClassAddScreenContainer';
import AdminPartnerClassEditScreen from './containers/AdminPartnerClassEditScreenContainer';

const register = () => {
  Logger.log('debug', `adminPartnerClasses.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'adminPartnerClasses', 'routes'])) {
    Events.dispatch('ADD_MAIN_ROUTES', 
      {
        'AdminPartnerClassAddScreen': ['PrivateRoute', '/care-guide-partner-classes/add', true, AdminPartnerClassAddScreen, getI18n().t('admin_partner_class_route_add')],
        'AdminPartnerClassEditScreen': ['PrivateRoute', '/care-guide-partner-classes/edit/:id([\\w\\-]+)', true, AdminPartnerClassEditScreen, getI18n().t('admin_partner_class_route_edit')],
        'AdminPartnerClassesScreen': ['PrivateRoute', '/care-guide-partner-classes/:page(\\d+)?', true, AdminPartnerClassesScreen, getI18n().t('admin_partner_classes_route_list')],
      }
    );
  }
}

export default register;

Logger.log('silly', `adminPartnerClasses.register() loaded.`);
