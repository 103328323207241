import {getI18n} from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import PartnershipsScreen from './containers/PartnershipsScreenContainer';
import PartnershipEditScreen from './containers/PartnershipEditScreenContainer';
import PartnershipAddScreen from './containers/PartnershipAddScreenContainer';

const register = () => {
  Logger.log('debug', `partnerships.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'partnerships', 'routes'])) {
    Events.dispatch('ADD_MAIN_ROUTES', 
      {
        'PartnershipAddScreen': ['PrivateRoute', '/partnership/add', true, PartnershipAddScreen, getI18n().t('partnership_route_add')],
        'PartnershipEditScreen': ['PrivateRoute', '/partnership/edit/:id([\\w\\-]+)', true, PartnershipEditScreen, getI18n().t('partnership_route_edit')],
        'PartnershipsScreen': ['PrivateRoute', '/partnership/:page(\\d+)?', true, PartnershipsScreen, getI18n().t('partnerships_route_list')],
      }
    );
  }
}

export default register;

Logger.log('silly', `partnerships.register() loaded.`);
