import React, { useEffect } from 'react';
import { Translation } from 'react-i18next';
import { Card, Col, Row, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import ImportTasksList from '../containers/ImportTasksListContainer';
import DocumentHead from '../../../elements/components/DocumentHead';
import QueryString from '../../../../../lib/QueryString';
import Logger from '../../../../../lib/Logger';

const ImportTasksScreen = (props) => {
  const scrollToTop = () => {
    Logger.log('debug', `ImportTasksScreen.scrollToTop()`);
    if (typeof window !== 'undefined') {
      setTimeout(() => window.scrollTo(0, 0), 100);
    }
  };

  const page = parseInt(props.match.params.page ? props.match.params.page : 1);
  const { order, ...filter } = QueryString.parse(props.location.search);

  const loadingIcon = (
    <Spin indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} />
  );

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <Translation>
      {(t) => (
        <div>
          <DocumentHead title={t('import_tasks_route_list')} />

          <Row>
            <Col span={24}>
              <Card extra={props.isLoading ? loadingIcon : null}>
                <ImportTasksList
                  key={page}
                  component="ImportTasksScreen"
                  page={page}
                  limit={props.limit}
                  order={order || 'updated_at.desc'}
                  filter={filter}
                />
              </Card>
            </Col>
          </Row>
        </div>
      )}
    </Translation>
  );
};

export default ImportTasksScreen;

Logger.log('silly', `ImportTasksScreen loaded.`);
