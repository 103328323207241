import {connect} from 'react-redux';

import ResourceTagAddScreen from '../components/ResourceTagAddScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.resourceTags.get('isLoading'),
    success: state.resourceTags.getIn(['form', 'success']),
  }
}

const ResourceTagAddScreenContainer = connect(
  mapStateToProps
)(ResourceTagAddScreen);

export default ResourceTagAddScreenContainer;
