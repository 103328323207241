import { getI18n } from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import DashboardSectionsScreen from './containers/DashboardSectionsScreenContainer';
import DashboardSectionAddScreen from './containers/DashboardSectionAddScreenContainer';
import DashboardSectionEditScreen from './containers/DashboardSectionEditScreenContainer';

const register = () => {
  Logger.log('debug', `dashboardSections.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'dashboardSections', 'routes'])) {
    Events.dispatch('ADD_MAIN_ROUTES', {
      DashboardSectionAddScreen: [
        'PrivateRoute',
        '/dashboard-sections/add',
        true,
        DashboardSectionAddScreen,
        getI18n().t('dashboard_sections_route_add'),
      ],
      DashboardSectionEditScreen: [
        'PrivateRoute',
        '/dashboard-sections/edit/:id([\\w\\-]+)',
        true,
        DashboardSectionEditScreen,
        getI18n().t('dashboard_sections_route_edit'),
      ],
      DashboardSectionsScreen: [
        'PrivateRoute',
        '/dashboard-sections/:page(\\d+)?',
        true,
        DashboardSectionsScreen,
        getI18n().t('dashboard_sections_route_list'),
      ],
    });
  }
};

export default register;

Logger.log('silly', `dashboardSections.register() loaded.`);
