import { connect } from 'react-redux';

import JournalRepliesCSVButton from '../components/JournalRepliesCSVButton';

const mapStateToProps = (state) => {
  return {};
};

const JournalRepliesCSVButtonContainer = connect(mapStateToProps)(
  JournalRepliesCSVButton
);

export default JournalRepliesCSVButtonContainer;
