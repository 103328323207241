import {connect} from 'react-redux';

import {downloadEmailsCSV} from '../../../../../state/modules/emails/actions';
import EmailsCSVButton from '../components/EmailsCSVButton';

const mapStateToProps = state => {
  return {
    isDownloading: state.emails.get('isCSVDownloading'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    download: (order, filter, cb) => {
      dispatch(downloadEmailsCSV(order, filter, cb));
    }
  }
}

const EmailsCSVButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailsCSVButton);

export default EmailsCSVButtonContainer;
