import {connect} from 'react-redux';

import CareGuideSectionEditScreen from '../components/CareGuideSectionEditScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.careGuideSections.get('isLoading'),
    success: state.careGuideSections.getIn(['form', 'success']),
  }
}

const CareGuideSectionEditScreenContainer = connect(
  mapStateToProps
)(CareGuideSectionEditScreen);

export default CareGuideSectionEditScreenContainer;
