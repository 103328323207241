import { getI18n } from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import RecommendedUtilitiesScreen from './containers/RecommendedUtilitiesScreenContainer';
import RecommendedUtilityAddScreen from './containers/RecommendedUtilityAddScreenContainer';
import RecommendedUtilityEditScreen from './containers/RecommendedUtilityEditScreenContainer';

const register = () => {
  Logger.log('debug', `recommendedUtilities.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'recommendedUtilities', 'routes'])) {
    Events.dispatch('ADD_MAIN_ROUTES', {
      RecommendedUtilityAddScreen: [
        'PrivateRoute',
        '/recommended-utilities/add',
        true,
        RecommendedUtilityAddScreen,
        getI18n().t('recommended_utilities_route_add'),
      ],
      RecommendedUtilityEditScreen: [
        'PrivateRoute',
        '/recommended-utilities/edit/:id([\\w\\-]+)',
        true,
        RecommendedUtilityEditScreen,
        getI18n().t('recommended_utilities_route_edit'),
      ],
      RecommendedUtilitiesScreen: [
        'PrivateRoute',
        '/recommended-utilities/:page(\\d+)?',
        true,
        RecommendedUtilitiesScreen,
        getI18n().t('recommended_utilities_route_list'),
      ],
    });
  }
};

export default register;

Logger.log('silly', `recommendedUtilities.register() loaded.`);
