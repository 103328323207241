import { connect } from 'react-redux';

import { loadResources } from '../../../../../state/modules/resources/actions';
import ResourceInput from '../components/ResourceInput';

const mapStateToProps = (state) => {
  return {
    total: state.resources.get('total'),
    isLoading: state.resources.get('isLoading'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (page, limit, order, filter, cb) => {
      dispatch(loadResources(page, limit, order, filter, cb));
    },
  };
};

const ResourceInputContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResourceInput);

export default ResourceInputContainer;
