import { connect } from 'react-redux';
import { List } from 'immutable';

import {
  loadCareGuideOwner,
  updateCareGuideOwner,
  createCareGuideOwner,
  deleteCareGuideOwner,
  careGuideOwnerFormDestroy,
} from '../../../../../state/modules/careGuideOwners/actions';
import { sendMessage } from '../../../../../state/actions';
import CareGuideOwnerForm from '../components/CareGuideOwnerForm';

const inputs = List(['account_id', 'care_guide_limit', 'profile', 'status']);

const mapStateToProps = (state, ownProps) => {
  const data = {},
    errors = {};
  for (const val of inputs.values()) {
    data[val] = state.entities.getIn(['careGuideOwners', ownProps.id, val], '');
    if (['profile'].includes(val)) {
      errors[val] = state.careGuideOwners.getIn([
        'form',
        'errors',
        'user_profile_id',
      ]);
    } else {
      errors[val] = state.careGuideOwners.getIn(['form', 'errors', val]);
    }
  }

  // change select number values to strings to work with ant design select inputs
  Object.keys(data).forEach((x) => {
    if (['status'].includes(x)) {
      data[x] = data[x].toString();
    }
  });

  return {
    isLoading: state.careGuideOwners.get('isLoading'),
    isSubmitting: state.careGuideOwners.getIn(['form', 'isSubmitting']),
    success: state.careGuideOwners.getIn(['form', 'success']),
    created_id: state.careGuideOwners.getIn(['form', 'created_id']),
    data: data,
    errors: errors,
    createdAt: state.entities.getIn(
      ['careGuideOwners', ownProps.id, 'created_at'],
      null
    ),
    updatedAt: state.entities.getIn(
      ['careGuideOwners', ownProps.id, 'updated_at'],
      null
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (id, cb) => {
      dispatch(loadCareGuideOwner(id, cb));
    },
    update: (id, data, cb) => {
      dispatch(updateCareGuideOwner(id, data, cb));
    },
    create: (data, cb) => {
      dispatch(createCareGuideOwner(data, cb));
    },
    delete: (id, cb) => {
      dispatch(deleteCareGuideOwner(id, cb));
    },
    destroyForm: (formState) => {
      dispatch(careGuideOwnerFormDestroy(formState));
    },
    sendMessage: (level, title, body, expires) => {
      dispatch(sendMessage(level, title, body, expires));
    },
  };
};

const CareGuideOwnerFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CareGuideOwnerForm);

export default CareGuideOwnerFormContainer;
