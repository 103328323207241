import {connect} from 'react-redux';

import CityEditScreen from '../components/CityEditScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.locationCities.get('areCitiesLoading'),
    success: state.locationCities.getIn(['form', 'success']),
  }
}

const CityEditScreenContainer = connect(
  mapStateToProps
)(CityEditScreen);

export default CityEditScreenContainer;
