import {normalize} from 'normalizr';

import api from '../../api';
import {schema} from '../../schema';
import {addEntities, removeEntity} from '../../actions';
import Format from '../../../lib/Format';
import Logger from '../../../lib/Logger';

// ACTION TYPES

export const CARE_GUIDE_HELPER_TAG_LIST_REQUEST = 'CARE_GUIDE_HELPER_TAG_LIST_REQUEST';
export const CARE_GUIDE_HELPER_TAG_LIST_SUCCESS = 'CARE_GUIDE_HELPER_TAG_LIST_SUCCESS';
export const CARE_GUIDE_HELPER_TAG_LIST_FAILURE = 'CARE_GUIDE_HELPER_TAG_LIST_FAILURE';
export const CARE_GUIDE_HELPER_TAG_READ_REQUEST = 'CARE_GUIDE_HELPER_TAG_READ_REQUEST';
export const CARE_GUIDE_HELPER_TAG_READ_SUCCESS = 'CARE_GUIDE_HELPER_TAG_READ_SUCCESS';
export const CARE_GUIDE_HELPER_TAG_READ_FAILURE = 'CARE_GUIDE_HELPER_TAG_READ_FAILURE';
export const CARE_GUIDE_HELPER_TAG_UPDATE_REQUEST = 'CARE_GUIDE_HELPER_TAG_UPDATE_REQUEST';
export const CARE_GUIDE_HELPER_TAG_UPDATE_SUCCESS = 'CARE_GUIDE_HELPER_TAG_UPDATE_SUCCESS';
export const CARE_GUIDE_HELPER_TAG_UPDATE_FAILURE = 'CARE_GUIDE_HELPER_TAG_UPDATE_FAILURE';
export const CARE_GUIDE_HELPER_TAG_CREATE_REQUEST = 'CARE_GUIDE_HELPER_TAG_CREATE_REQUEST';
export const CARE_GUIDE_HELPER_TAG_CREATE_SUCCESS = 'CARE_GUIDE_HELPER_TAG_CREATE_SUCCESS';
export const CARE_GUIDE_HELPER_TAG_CREATE_FAILURE = 'CARE_GUIDE_HELPER_TAG_CREATE_FAILURE';
export const CARE_GUIDE_HELPER_TAG_DELETE_REQUEST = 'CARE_GUIDE_HELPER_TAG_DELETE_REQUEST';
export const CARE_GUIDE_HELPER_TAG_DELETE_SUCCESS = 'CARE_GUIDE_HELPER_TAG_DELETE_SUCCESS';
export const CARE_GUIDE_HELPER_TAG_DELETE_FAILURE = 'CARE_GUIDE_HELPER_TAG_DELETE_FAILURE';
export const CARE_GUIDE_HELPER_TAG_FORM_DESTROY = 'CARE_GUIDE_HELPER_TAG_FORM_DESTROY';
export const CARE_GUIDE_HELPER_TAG_CSV_DOWNLOAD_REQUEST = 'CARE_GUIDE_HELPER_TAG_CSV_DOWNLOAD_REQUEST';
export const CARE_GUIDE_HELPER_TAG_CSV_DOWNLOAD_SUCCESS = 'CARE_GUIDE_HELPER_TAG_CSV_DOWNLOAD_SUCCESS';
export const CARE_GUIDE_HELPER_TAG_CSV_DOWNLOAD_FAILURE = 'CARE_GUIDE_HELPER_TAG_CSV_DOWNLOAD_FAILURE';

// ACTION CREATORS

export function careGuideHelperTagListRequest(page, limit, order, filter) {
  Logger.log('debug', `[careGuideHelperTags.actions] careGuideHelperTagListRequest(${page}, ${limit}, ${order}, %j)`, filter);
  return {
    type: CARE_GUIDE_HELPER_TAG_LIST_REQUEST,
    page: page,
    limit: limit,
    order: order,
    filter: filter
  }
}

export function careGuideHelperTagListSuccess(data) {
  Logger.log('debug', `[careGuideHelperTags.actions] careGuideHelperTagListSuccess(%j)`, data);
  return {
    type: CARE_GUIDE_HELPER_TAG_LIST_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now()
  }
}

export function careGuideHelperTagListFailure(error) {
  Logger.log('debug', `[careGuideHelperTags.actions] careGuideHelperTagListFailure(%j)`, error);
  return {
    type: CARE_GUIDE_HELPER_TAG_LIST_FAILURE,
    error: error
  }
}

export function careGuideHelperTagReadRequest(id) {
  Logger.log('debug', `[careGuideHelperTags.actions] careGuideHelperTagReadRequest(${id})`);
  return {
    type: CARE_GUIDE_HELPER_TAG_READ_REQUEST,
    id: id
  }
}

export function careGuideHelperTagReadSuccess(data) {
  Logger.log('debug', `[careGuideHelperTags.actions] careGuideHelperTagReadSuccess(%j)`, data);
  return {
    type: CARE_GUIDE_HELPER_TAG_READ_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function careGuideHelperTagReadFailure(error) {
  Logger.log('debug', `[careGuideHelperTags.actions] careGuideHelperTagReadFailure(%j)`, error);
  return {
    type: CARE_GUIDE_HELPER_TAG_READ_FAILURE,
    error: error
  }
}

export function careGuideHelperTagUpdateRequest(id, data) {
  Logger.log('debug', `[careGuideHelperTags.actions] careGuideHelperTagUpdateRequest(${id}, %j)`, data);
  return {
    type: CARE_GUIDE_HELPER_TAG_UPDATE_REQUEST,
  }
}

export function careGuideHelperTagUpdateSuccess(data) {
  Logger.log('debug', `[careGuideHelperTags.actions] careGuideHelperTagUpdateSuccess(%j)`, data);
  return {
    type: CARE_GUIDE_HELPER_TAG_UPDATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function careGuideHelperTagUpdateFailure(error) {
  Logger.log('debug', `[careGuideHelperTags.actions] careGuideHelperTagUpdateFailure(%j)`, error);
  return {
    type: CARE_GUIDE_HELPER_TAG_UPDATE_FAILURE,
    error: error
  }
}

export function careGuideHelperTagCreateRequest(data) {
  Logger.log('debug', `[careGuideHelperTags.actions] careGuideHelperTagCreateRequest(%j)`, data);
  return {
    type: CARE_GUIDE_HELPER_TAG_CREATE_REQUEST,
  }
}

export function careGuideHelperTagCreateSuccess(data) {
  Logger.log('debug', `[careGuideHelperTags.actions] careGuideHelperTagCreateSuccess(%j)`, data);
  return {
    type: CARE_GUIDE_HELPER_TAG_CREATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function careGuideHelperTagCreateFailure(error) {
  Logger.log('debug', `[careGuideHelperTags.actions] careGuideHelperTagCreateFailure(%j)`, error);
  return {
    type: CARE_GUIDE_HELPER_TAG_CREATE_FAILURE,
    error: error
  }
}

export function careGuideHelperTagDeleteRequest(id) {
  Logger.log('debug', `[careGuideHelperTags.actions] careGuideHelperTagDeleteRequest(${id})`);
  return {
    type: CARE_GUIDE_HELPER_TAG_DELETE_REQUEST,
    id: id
  }
}

export function careGuideHelperTagDeleteSuccess(id) {
  Logger.log('debug', `[careGuideHelperTags.actions] careGuideHelperTagDeleteSuccess(${id})`);
  return {
    type: CARE_GUIDE_HELPER_TAG_DELETE_SUCCESS,
    id: id,
  }
}

export function careGuideHelperTagDeleteFailure(error) {
  Logger.log('debug', `[careGuideHelperTags.actions] careGuideHelperTagDeleteFailure(%j)`, error);
  return {
    type: CARE_GUIDE_HELPER_TAG_DELETE_FAILURE,
    error: error
  }
}

export function careGuideHelperTagFormDestroy(formState=null) {
  Logger.log('debug', `[careGuideHelperTags.actions] careGuideHelperTagFormDestroy(%j)`, formState);
  return {
    type: CARE_GUIDE_HELPER_TAG_FORM_DESTROY,
    form: formState
  }
}

export function careGuideHelperTagCSVDownloadRequest(order, filter) {
  Logger.log('debug', `[state.careGuideHelperTags.actions] careGuideHelperTagCSVDownloadRequest()`);
  return {
    type: CARE_GUIDE_HELPER_TAG_CSV_DOWNLOAD_REQUEST,
    order: order,
    filter: filter,
  }
}

export function careGuideHelperTagCSVDownloadSuccess() {
  Logger.log('debug', `[state.careGuideHelperTags.actions] careGuideHelperTagCSVDownloadSuccess()`);
  return {
    type: CARE_GUIDE_HELPER_TAG_CSV_DOWNLOAD_SUCCESS,
  }
}

export function careGuideHelperTagCSVDownloadFailure() {
  Logger.log('debug', `[state.careGuideHelperTags.actions] careGuideHelperTagCSVDownloadFailure()`);
  return {
    type: CARE_GUIDE_HELPER_TAG_CSV_DOWNLOAD_FAILURE,
  }
}


// API THUNK ACTION CREATORS

export function loadCareGuideHelperTags(page=1, limit=10, order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[careGuideHelperTags.actions] loadCareGuideHelperTags(${page}, ${limit}, ${order}, %j, ###)`, filter);

  return async function(dispatch) {
    dispatch(careGuideHelperTagListRequest(page, limit, order, filter));

    // call API
    const response = await api.getCareGuideHelperTags(page, limit, order, filter);
    let success = false;

    // get care guide helper tags list success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API care guide helper tags list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`);

      const normalizedEntities = normalize(response.getIn(['data', 'helper_tags']), [schema.careGuideHelperTag]);
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideHelperTagListSuccess(data));
      success = true;

    } else if (1 === page && 204 === response.get('status')) {

      Logger.log('info', `Get API care guide helper tags success [empty]. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: []
      };
      dispatch(careGuideHelperTagListSuccess(data));
      success = true;
      
    // get care guide helper tags list failure
    } else {
      Logger.log('info', `Get API care guide helper tags list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      dispatch(careGuideHelperTagListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function loadCareGuideHelperTag(id, cb=function(){}) {
  Logger.log('debug', `[careGuideHelperTags.actions] loadCareGuideHelperTag(${id}, ###)`);

  return async function(dispatch) {
    dispatch(careGuideHelperTagReadRequest(id));

    // call API
    const response = await api.getCareGuideHelperTag(id);
    let success = false;

    // get care guide helper tag success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API care guide helper tag success. ID: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'helper_tag'])], [schema.careGuideHelperTag]);
      const data = {
        id: response.getIn(['data', 'helper_tag', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideHelperTagReadSuccess(data));
      success = true;
      
    // get care guide helper tag failure
    } else {
      Logger.log('info', `Get API care guide helper tag failure. ID: ${id}.`);
      dispatch(careGuideHelperTagReadFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function updateCareGuideHelperTag(id, data, cb=function(){}) {
  Logger.log('debug', `[careGuideHelperTags.actions] updateCareGuideHelperTag(${id}, %j, ###)`, data);

  return async function(dispatch) {
    dispatch(careGuideHelperTagUpdateRequest(id, data));

    // call API
    const response = await api.putCareGuideHelperTag(id, data);
    let success = false;

    // put care guide helper tag success
    if (200 === response.get('status')) {

      Logger.log('info', `PUT API care guide helper tag success. User: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'helper_tag'])], [schema.careGuideHelperTag]);
      const data = {
        id: response.getIn(['data', 'helper_tag', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideHelperTagUpdateSuccess(data));
      success = true;
      
    // get care guide helper tag failure
    } else {
      Logger.log('info', `PUT API care guide helper tag failure. ID: ${id}.`);
      dispatch(careGuideHelperTagUpdateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function createCareGuideHelperTag(data, cb=function(){}) {
  Logger.log('debug', `[careGuideHelperTags.actions] createCareGuideHelperTag(%j, ###)`, data);

  return async function(dispatch) {
    dispatch(careGuideHelperTagCreateRequest(data));

    // call API
    const response = await api.postCareGuideHelperTags(data);
    let success = false;

    // post care guide helper tags success
    if (201 === response.get('status')) {

      Logger.log('info', `POST API care guide helper tags success. Post: ${response.getIn(['data', 'care_guide_helper_tag', 'id'])}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'helper_tag'])], [schema.careGuideHelperTag]);
      const data = {
        id: response.getIn(['data', 'helper_tag', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideHelperTagCreateSuccess(data));
      success = true;
      
    // get care guide helper tags failure
    } else {
      Logger.log('info', `POST API care guide helper tags failure.`);
      dispatch(careGuideHelperTagCreateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function deleteCareGuideHelperTag(id, cb=function(){}) {
  Logger.log('debug', `[careGuideHelperTags.actions] deleteCareGuideHelperTag(${id}, ###)`);

  return async function(dispatch) {
    dispatch(careGuideHelperTagDeleteRequest(id));

    // call API
    const response = await api.deleteCareGuideHelperTag(id);
    let success = false;

    // delete care guide helper tag success
    if (204 === response.get('status')) {

      Logger.log('info', `DELETE API care guide helper tag success. ID: ${id}.`);

      dispatch(removeEntity({entityType: 'careGuideHelperTags', id: id}));
      dispatch(careGuideHelperTagDeleteSuccess(id));
      success = true;
      
    // get care guide helper tag failure
    } else {
      Logger.log('info', `DELETE API care guide helper tag failure. ID: ${id}.`);
      dispatch(careGuideHelperTagDeleteFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function downloadCareGuideHelperTagsCSV(order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[state.careGuideHelperTags.actions] downloadCareGuideHelperTagsCSV(${order}, ${filter}, ###)`);

  return async function(dispatch) {
    dispatch(careGuideHelperTagCSVDownloadRequest(order, filter));

    // call API
    const response = await api.getCareGuideHelperTagsCSV(order, filter);

    // get care guide helper tags CSV success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API care guide helper tags CSV success. Order: ${order}`);
      const now = new Date();

      // trigger browser download
      const url = window.URL.createObjectURL(new Blob([response.get('data')]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `cv-care-guide-helper-tags-${Format.date(now, 'YYYY-MM-DDTHHmm')}.csv`);
      document.body.appendChild(link);
      link.click();

      dispatch(careGuideHelperTagCSVDownloadSuccess());
      
    // get care guide helper tags CSV failure
    } else {
      Logger.log('info', `Get API care guide helper tags CSV failure.`);
      dispatch(careGuideHelperTagCSVDownloadFailure());
    }

    // callback function
    cb();
  }
}

Logger.log('silly', `careGuideHelperTags.actions loaded.`);
