import {connect} from 'react-redux';
import {List} from 'immutable';
import moment from 'moment';
import {denormalize} from 'normalizr';

import {
  loadMessage,
  updateMessage,
  createMessage,
  deleteMessage,
  messageFormDestroy
} from '../../../../../state/modules/messages/actions';
import {schema} from '../../../../../state/schema';
import {sendMessage} from '../../../../../state/actions';
import MessageForm from '../components/MessageForm';
import Config from '../../../../../Config';

const inputs = List([
  'care_guide',
  'profile',
  'thread_id',
  'recipients',
  'subject',
  'body',
  'sent_at',
  'is_draft',
  'public_status',
  'status'
]);

const mapStateToProps = (state, ownProps) => {

  const data = {}, errors = {};
  for (const val of inputs.values()) {
    data[val] = state.entities.getIn(['messages', ownProps.id, val], '');
    if (['profile'].includes(val)) {
      errors[val] = state.messages.getIn(['form', 'errors', 'user_profile_id']);
    }
    else if (['care_guide'].includes(val)) {
      errors[val] = state.messages.getIn(['form', 'errors', val + '_id']);
    }
    else {
      errors[val] = state.messages.getIn(['form', 'errors', val]);
    }
  }

  // hydrate recipients for dynamic inputs
  if ('recipients' in data && Array.isArray(data['recipients'])) {
    data['recipients'] = data['recipients'].map(x => {
      return {
        key: x,
        ...denormalize(
          state.entities.getIn(['messageRecipients', x]),
          schema.messageRecipient,
          state.entities.toJS()
        )
      };
    });
  }

  // change select number values to strings to work with ant design select inputs
  Object.keys(data).forEach(x => {
    if (['status'].includes(x)) {
      data[x] = data[x].toString();
    }
  });

  data['sent_at'] = data['sent_at']
    ? moment(data['sent_at'], Config.get('API_DATETIME_FORMAT'))
    : null;

  return {
    isLoading: state.messages.get('isLoading'),
    isSubmitting: state.messages.getIn(['form', 'isSubmitting']),
    success: state.messages.getIn(['form', 'success']),
    created_id: state.messages.getIn(['form', 'created_id']),
    data: data,
    errors: errors,
    createdAt: state.entities.getIn(['messages', ownProps.id, 'created_at'], null),
    updatedAt: state.entities.getIn(['messages', ownProps.id, 'updated_at'], null),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (id, cb) => {
      dispatch(loadMessage(id, cb));
    },
    update: (id, data, cb) => {
      dispatch(updateMessage(id, data, cb));
    },
    create: (data, cb) => {
      dispatch(createMessage(data, cb));
    },
    delete: (id, cb) => {
      dispatch(deleteMessage(id, cb))
    },
    destroyForm: (formState) => {
      dispatch(messageFormDestroy(formState));
    },
    sendMessage: (level, title, body, expires) => {
      dispatch(sendMessage(level, title, body, expires));
    },
  }
}

const MessageFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MessageForm);

export default MessageFormContainer;
