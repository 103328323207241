import {connect} from 'react-redux';

import JournalTagsScreen from '../components/JournalTagsScreen';

const mapStateToProps = state => {
  return {
    total: state.journalTags.get('total'),
    isLoading: state.journalTags.get('isLoading')
  }
}

const JournalTagsScreenContainer = connect(
  mapStateToProps
)(JournalTagsScreen);

export default JournalTagsScreenContainer;
