import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Translation, getI18n } from 'react-i18next';
import {
  Affix,
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Spin,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';

import PlanInput from '../../plans/containers/PlanInputContainer';
import PartnershipInput from '../../partnerships/containers/PartnershipInputContainer';
import message from '../../../elements/lib/MessageWrapper';
import FormMetadata from '../../../elements/components/FormMetadata';
import { pathTo } from '../../../Routes';
import Format from '../../../../../lib/Format';
import Logger from '../../../../../lib/Logger';
import Config from '../../../../../Config';
import RoleInput from './RoleInput';

const RegistrationCodeForm = ({
  id,
  data,
  errors,
  load,
  destroyForm,
  isLoading,
  isSubmitting,
  created_id,
  ...props
}) => {
  const [redirectTo, setRedirectTo] = useState(null);
  const [form] = Form.useForm();

  // form column settings
  const layout = {
    main: {
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
    },
  };
  // load record data from API
  useEffect(() => {
    if (id) {
      load(id);
    }
  }, [id, load]);

  // update input values when new data is available
  const dataString = JSON.stringify(data);
  useEffect(() => {
    if (id && !isSubmitting) {
      const dataObj = JSON.parse(dataString);
      dataObj['expires_at'] = dataObj['expires_at']
        ? moment(dataObj['expires_at'], Config.get('API_DATETIME_FORMAT'))
        : // : moment();
          null;
      form.setFieldsValue(dataObj);
    }
  }, [form, dataString, isSubmitting, id]);

  // handle errors reported by API
  useEffect(() => {
    let firstFieldName = '';
    for (const field in errors) {
      form.setFields([{ name: field, errors: errors[field] }]);
      if (firstFieldName === '') {
        firstFieldName = field;
      }
    }
    form.scrollToField(firstFieldName);
  }, [form, errors]);

  // redirect add form to edit form on successful create action
  useEffect(() => {
    if (created_id) {
      setRedirectTo(pathTo('RegistrationCodeEditScreen', { id: created_id }));
    }
    return () => {
      destroyForm();
    };
  }, [created_id, setRedirectTo, destroyForm]);

  // submit data handler
  const submitData = async (values) => {
    Logger.log('debug', `RegistrationCodeForm.submitData(###)`);

    // API POST/PUT payload
    let payload = {};
    for (const input of Object.keys(data)) {
      if (input in values) {
        // transform entity refs to integer IDs
        if (['plan'].includes(input)) {
          payload[input + '_id'] = parseInt(values[input]);
        } else if (['partnership'].includes(input)) {
          if (values[input] === '') {
            payload[input + '_id'] = null;
          } else {
            payload[input + '_id'] = values[input];
          }
        } else if (['expires_at'].includes(input)) {
          payload[input] = values[input]
            ? Format.date(values[input], Config.get('API_DATETIME_FORMAT'))
            : null;
        } else if (['is_used', 'is_multi_use'].includes(input)) {
          payload[input] = values[input] ? true : false;
        } else if (['code_alias'].includes(input)) {
          payload[input] = values[input]
            ? values[input].length > 0
              ? values[input]
              : null
            : null;
        } else {
          payload[input] = values[input];
        }
      }
    }
    if (id) {
      // update
      props.update(id, payload, (success) => {
        if (success) {
          message.success(getI18n().t('feedback_form_success'));
        } else {
          message.error(getI18n().t('feedback_form_error'));
        }
      });
    } else {
      // create
      props.create(payload, (success) => {
        if (success) {
          message.success(getI18n().t('feedback_form_success'));
        } else {
          message.error(getI18n().t('feedback_form_error'));
        }
      });
    }
  };

  // form submit handler
  const handleFinish = async (values) => {
    Logger.log('debug', `RegistrationCodeForm.handleFinish(###)`);
    if (!props.isSubmitting) {
      await submitData(values);
    }
  };

  // form error handler
  const handleFinishFailed = ({ values, errorFields, outOfDate }) => {
    Logger.log('debug', `RegistrationCodeForm.handleFinishFailed(###)`);
    message.error(getI18n().t('feedback_form_error'));
    if (errorFields && errorFields.length > 0) {
      form.scrollToField(errorFields[0].name);
    }
  };

  const onClickGenerateHandler = (length) => {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    form.setFieldsValue({ code: result });
  };

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <Translation>
      {(t) => (
        <>
          <div className="registration-code-form">
            <Form
              name="registration_code_form"
              form={form}
              initialValues={data}
              onFinish={handleFinish}
              onFinishFailed={handleFinishFailed}
              validateTrigger="onSubmit"
              {...layout.main}
            >
              <Row gutter={16}>
                <Col xs={24} lg={18}>
                  <Card
                    title={
                      id
                        ? t('registration_code_edit_title')
                        : t('registration_code_add_title')
                    }
                    extra={
                      isLoading ? (
                        <Spin
                          indicator={
                            <LoadingOutlined style={{ fontSize: 20 }} spin />
                          }
                        />
                      ) : null
                    }
                  >
                    <div className="form-group">
                      <Form.Item
                        label={t('registration_code_code')}
                        style={{ marginBottom: 0 }}
                      >
                        <Row gutter={8}>
                          <Col span={18}>
                            <Form.Item
                              name="code"
                              rules={[
                                {
                                  required: true,
                                  message: t('feedback_validation_required'),
                                },
                                {
                                  type: 'string',
                                  min: 5,
                                  max: 20,
                                  message: t('feedback_validation_char_range', {
                                    min: 5,
                                    max: 20,
                                  }),
                                },
                              ]}
                            >
                              <Input
                                autoFocus
                                disabled={isLoading || isSubmitting}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Button onClick={() => onClickGenerateHandler(8)}>
                              {t('registration_code_btn_generate_code')}
                            </Button>
                          </Col>
                        </Row>
                      </Form.Item>
                    </div>
                    <div className="form-group">
                      <Form.Item
                        name="code_alias"
                        label={t('registration_code_code_alias')}
                        rules={[
                          {
                            type: 'string',
                            min: 5,
                            max: 20,
                            message: t('feedback_validation_char_range', {
                              min: 5,
                              max: 20,
                            }),
                          },
                          {
                            pattern: /^\w+$/,
                            message: t('feedback_validation_alphanumeric'),
                          },
                        ]}
                      >
                        <Input disabled={isLoading || isSubmitting} />
                      </Form.Item>
                    </div>

                    <div className="form-group">
                      <Form.Item
                        name="is_multi_use"
                        label={t('registration_code_is_multi_use')}
                        valuePropName="checked"
                      >
                        <Checkbox disabled={isLoading || isSubmitting} />
                      </Form.Item>
                    </div>

                    <div className="form-group">
                      <Form.Item
                        name="expires_at"
                        label={t('registration_code_expires_at')}
                      >
                        <DatePicker
                          showTime
                          disabled={isLoading || isSubmitting}
                          format={Config.get('DEFAULT_DATETIME_FORMAT')}
                        />
                      </Form.Item>
                    </div>

                    <div className="form-group">
                      <Form.Item
                        name="is_used"
                        label={t('registration_code_is_used')}
                        valuePropName="checked"
                      >
                        <Checkbox disabled={isLoading || isSubmitting} />
                      </Form.Item>
                    </div>

                    <PlanInput
                      name="plan"
                      label={t('registration_code_plan')}
                      disabled={isLoading || isSubmitting}
                      form={form}
                      allowNone={true}
                    />
                  </Card>

                  <Card
                    title={t('partnership_title')}
                    extra={
                      isLoading ? (
                        <Spin
                          indicator={
                            <LoadingOutlined style={{ fontSize: 20 }} spin />
                          }
                        />
                      ) : null
                    }
                  >
                    <RoleInput
                      name="type_user"
                      label={t('registration_code_type_user')}
                      disabled={isLoading || isSubmitting || id}
                    />
                    <div className="form-group">
                      <Form.Item
                        name="limit_to_use"
                        label={t('registration_code_limit_to_use')}
                        rules={[
                          {
                            required: true,
                            message: t('feedback_validation_required'),
                          },
                          {
                            type: 'number',
                            message: t('feedback_validation_number'),
                          },
                          {
                            type: 'number',
                            min: 1,
                            max: 10000,
                            message: t('feedback_validation_range', {
                              min: 1,
                              max: 10000,
                            }),
                          },
                        ]}
                      >
                        <InputNumber
                          min={1}
                          max={10000}
                          disabled={isLoading || isSubmitting}
                        />
                      </Form.Item>
                    </div>
                    <div className="form-group">
                      <Form.Item
                        name="available_to_use"
                        label={t('registration_code_available_to_use')}
                        rules={[
                          {
                            required: true,
                            message: t('feedback_validation_required'),
                          },
                          {
                            type: 'number',
                            message: t('feedback_validation_number'),
                          },
                          {
                            type: 'number',
                            min: 0,
                            max: 1000,
                            message: t('feedback_validation_range', {
                              min: 0,
                              max: 10000,
                            }),
                          },
                        ]}
                      >
                        <InputNumber min={0} max={10000} disabled />
                      </Form.Item>
                    </div>

                    <PartnershipInput
                      form={form}
                      name="partnership"
                      label={t('registration_code_partnership')}
                      disabled={isLoading || isSubmitting}
                      allowNone={true}
                    />
                  </Card>
                </Col>

                <Col xs={24} lg={6}>
                  <Affix offsetTop={10}>
                    <Card title={t('form_metadata_header')}>
                      <FormMetadata
                        id={id}
                        isSubmitting={isSubmitting}
                        delete={props.delete.bind(this)}
                        deleteRedirectTo="RegistrationCodesScreen"
                        createdAt={props.createdAt}
                        updatedAt={props.updatedAt}
                      />
                    </Card>
                  </Affix>
                </Col>
              </Row>
            </Form>
          </div>
        </>
      )}
    </Translation>
  );
};

export default RegistrationCodeForm;

Logger.log('silly', `RegistrationCodeForm loaded.`);
