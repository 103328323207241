import { Map, List } from 'immutable';

import {
  PASSWORD_RESET_LIST_REQUEST,
  PASSWORD_RESET_LIST_SUCCESS,
  PASSWORD_RESET_LIST_FAILURE,
} from './actions';
import Logger from '../../../lib/Logger';

export default function passwordResets(
  state = Map({
    isLoading: false,
    pages: {},
  }),
  action
) {
  Logger.log(
    'debug',
    `[passwordResets.reducers] passwordResets(%j, %j)`,
    state,
    action
  );

  switch (action.type) {
    case PASSWORD_RESET_LIST_REQUEST:
      return state.mergeDeep({
        isLoading: true,
      });

    case PASSWORD_RESET_LIST_SUCCESS:
      return state
        .mergeDeep({
          isLoading: false,
          pages: {
            [action.order]: {
              [action.limit]: {
                [action.page]: null,
              },
            },
          },
          total: action.total,
          lastUpdated: action.receivedAt,
        })
        .setIn(
          ['pages', action.order, action.limit, action.page],
          List(action.result)
        );

    case PASSWORD_RESET_LIST_FAILURE:
      return state.mergeDeep({
        isLoading: false,
      });

    default:
      return state;
  }
}

Logger.log('silly', `passwordResets.reducers loaded.`);
