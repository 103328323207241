import {connect} from 'react-redux';

import PlanEditScreen from '../components/PlanEditScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.plans.get('isLoading'),
    success: state.plans.getIn(['form', 'success']),
  }
}

const PlanEditScreenContainer = connect(
  mapStateToProps
)(PlanEditScreen);

export default PlanEditScreenContainer;
