import {getI18n} from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import CareGuideSectionsScreen from './containers/CareGuideSectionsScreenContainer';
import CareGuideSectionAddScreen from './containers/CareGuideSectionAddScreenContainer';
import CareGuideSectionEditScreen from './containers/CareGuideSectionEditScreenContainer';

const register = () => {
  Logger.log('debug', `careGuideSections.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'careGuideSections', 'routes'])) {
    Events.dispatch('ADD_MAIN_ROUTES', 
      {
        'CareGuideSectionAddScreen': ['PrivateRoute', '/care-guide-sections/add', true, CareGuideSectionAddScreen, getI18n().t('care_guide_section_route_add')],
        'CareGuideSectionEditScreen': ['PrivateRoute', '/care-guide-sections/edit/:id([\\w\\-]+)', true, CareGuideSectionEditScreen, getI18n().t('care_guide_section_route_edit')],
        'CareGuideSectionsScreen': ['PrivateRoute', '/care-guide-sections/:page(\\d+)?', true, CareGuideSectionsScreen, getI18n().t('care_guide_sections_route_list')],
      }
    );
  }
}

export default register;

Logger.log('silly', `careGuideSections.register() loaded.`);
