import React from 'react';
import {Translation} from 'react-i18next';
import {Tag} from 'antd';


const ResourceTag = ({resource, ...props}) => {

  const labels = {
    1: "imports_jobs_resource_resource",
  };

  return (
    <Translation>{(t) =>
        <>
          {resource in labels ? <Tag>{t(labels[resource])}</Tag> : resource}
        </>
    }</Translation>
  )
}

export default ResourceTag;
