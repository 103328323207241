import {connect} from 'react-redux';

import {destroySession} from '../../../../../state/actions';
import {updateUserAccount2FA} from '../../../../../state/modules/userAccount/actions';
import TwoFactorSettingsForm from '../components/TwoFactorSettingsForm';

const mapStateToProps = (state, ownProps) => {
  return {
    isSubmitting: state.userAccount.get('isSubmitting2FA'),
    success: state.userAccount.getIn(['form2FA', 'success']),
    isEnrolledOTP: state.userAccount.getIn(['data', 'is_enrolled_otp']),
    OTPSecret: state.userAccount.getIn(['data', 'otp_secret']),
    provisioningURI: state.userAccount.getIn(['data', 'provisioning_uri']),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    update: (data, cb) => {
      dispatch(updateUserAccount2FA(data, cb));
    },
    destroySession: (cb) => {
      dispatch(destroySession(cb));
    }
  }
}

const TwoFactorSettingsFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TwoFactorSettingsForm);

export default TwoFactorSettingsFormContainer;
