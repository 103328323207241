import { connect } from 'react-redux';

import { sendCSV } from '../../../../../state/modules/careGuides/actions';
import CareGuideUploadCSVButton from '../components/CareGuideUploadCSVButton';

const mapStateToProps = (state, ownProps) => {
  return {
    isSendCsv: state.careGuides.get('isSendCsv'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendCSV: (data, cb) => {
      dispatch(sendCSV(data, cb));
    },
  };
};

const CareGuideUploadCSVButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CareGuideUploadCSVButton);

export default CareGuideUploadCSVButtonContainer;
