import {connect} from 'react-redux';

import EmailsScreen from '../components/EmailsScreen';

const mapStateToProps = state => {
  return {
    total: state.emails.get('total'),
    isLoading: state.emails.get('isLoading')
  }
}

const EmailsScreenContainer = connect(
  mapStateToProps
)(EmailsScreen);

export default EmailsScreenContainer;
