import {connect} from 'react-redux';

import CareGuideSectionsScreen from '../components/CareGuideSectionsScreen';

const mapStateToProps = state => {
  return {
    total: state.careGuideSections.get('total'),
    isLoading: state.careGuideSections.get('isLoading')
  }
}

const CareGuideSectionsScreenContainer = connect(
  mapStateToProps
)(CareGuideSectionsScreen);

export default CareGuideSectionsScreenContainer;
