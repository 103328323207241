import React from 'react';
import {Input, Button, DatePicker, Space} from 'antd';
import {SearchOutlined, FilterFilled} from '@ant-design/icons';
import moment from 'moment';


export function getColumnSearchProps(dataIndex, handleSearch, handleReset, title, filter=null) {
  let searchInput;
  return {
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInput = node;
          }}
          placeholder={`Search ${title}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters, dataIndex)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: text => text,
    filtered: filter && dataIndex in filter && filter[dataIndex] ? true : false,
    filteredValue: filter && dataIndex in filter && filter[dataIndex] ? [filter[dataIndex]] : null,
  }
};

export function getColumnDateRangeProps(dataIndex, handleSearch, handleReset, filter=null) {
  let firstInput;
  return {
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (<div style={{ padding: 8 }}>
        <DatePicker
          ref={node => {
            firstInput = node;
          }}
          placeholder={`Start Date`}
          value={selectedKeys[0] ? moment(selectedKeys[0]) : null}
          onChange={e => setSelectedKeys(e ? [e.format('YYYY-MM-DD'), selectedKeys[1]] : [null, selectedKeys[1]])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <DatePicker
          placeholder={`End Date`}
          value={selectedKeys[1] ? moment(selectedKeys[1]) : null}
          onChange={e => setSelectedKeys(e ? [selectedKeys[0], e.format('YYYY-MM-DD')] : [selectedKeys[0], null])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<FilterFilled />}
            size="small"
            style={{ width: 90 }}
          >
            Filter
          </Button>
          <Button onClick={() => handleReset(clearFilters, dataIndex)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>)
    },
    filterIcon: filtered => <FilterFilled style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => firstInput.focus(), 100);
      }
    },
    filtered: filter && dataIndex in filter && filter[dataIndex] ? true : false,
    filteredValue: (() => {
      if (filter && dataIndex in filter && filter[dataIndex]) {
        const dates = filter[dataIndex].split(',');
        return [dates.length > 0 ?dates[0] : null, dates.length > 1 ? dates[1] : null];
      }
      return null;
    })(),
  }
};
