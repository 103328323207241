import {connect} from 'react-redux';
import {denormalize} from 'normalizr';

import {schema} from '../../../../../state/schema';
import {loadJournalPartnerTagsCareGuide} from '../../../../../state/modules/journalPartnerTags/actions';
import TagInput from '../components/TagInput';

const mapStateToProps = (state, ownProps) => {

  const careGuideId = ownProps.careGuideId;
  const page = ownProps.page || 1;
  const limit = ownProps.limit || 150;
  const order = ownProps.order || 'created_at.desc';

  const result = state.journalPartnerTags.getIn(['partnerTags', careGuideId, 'pages', order, limit, page]);
  const partnerTags = result
    ? result.map(x => {
        return {
          key: x,
          ...denormalize(
            state.entities.getIn(['journalPartnerTagsCareGuides', x]),
            schema.journalPartnerTagsCareGuide,
            state.entities.toJS()
          )
        };
      }).toArray()
    : [];

  return {
    options: partnerTags.map(x => {
      return {label: x.label, value: x.label};
    }),
    isLoading: state.journalPartnerTags.get('arePartnerTagsLoading'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (careGuideId, page, limit, order, filter, cb) => {
      dispatch(loadJournalPartnerTagsCareGuide(careGuideId, page, limit, order, filter, cb));
    },
  }
}

const TagInputContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TagInput);

export default TagInputContainer;
