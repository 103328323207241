import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { Affix, Card, Col, Input, Row, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import DocumentHead from '../../../elements/components/DocumentHead';
import Logger from '../../../../../lib/Logger';
import FormMetadata from '../../../elements/components/FormMetadata';

const DisplayInput = ({ label, value, ...props }) => (
  <div className="form-group">
    <div className="ant-row ant-form-item" style={{ marginBottom: 0 }}>
      <div className="ant-col ant-col-5 ant-form-item-label">
        <label
          htmlFor="partnership_form_answer_form_application"
          className=""
          title="Application"
        >
          {label}
        </label>
      </div>
      <div className="ant-col ant-col-19 ant-form-item-control">
        <Input value={value} bordered={false} />
      </div>
    </div>
  </div>
);

class PartnershipFormAnswerViewScreen extends Component {
  scrollToTop = () => {
    Logger.log('debug', `PartnershipFormAnswerViewScreen.scrollToTop()`);
    if (typeof window !== 'undefined') {
      setTimeout(() => window.scrollTo(0, 0), 100);
    }
  };

  render() {
    const { partnershipFormAnswer, isLoading, remove } = this.props;

    return (
      <Translation>
        {(t) => (
          <div>
            <DocumentHead title={t('partnership_form_answers_route_view')} />

            <div className="partnership_form_answer-view">
              <Row gutter={16}>
                <Col xs={24} lg={18}>
                  <Card
                    title={t('partnership_form_answers_view_title')}
                    extra={
                      isLoading ? (
                        <Spin
                          indicator={
                            <LoadingOutlined style={{ fontSize: 20 }} spin />
                          }
                        />
                      ) : null
                    }
                  >
                    <DisplayInput
                      label={t('partnership_form_answers_id')}
                      value={
                        partnershipFormAnswer ? partnershipFormAnswer.id : null
                      }
                    />
                    <DisplayInput
                      label={t('partnership_form_answers_form_id')}
                      value={
                        partnershipFormAnswer
                          ? partnershipFormAnswer.form_id
                          : null
                      }
                    />
                    <DisplayInput
                      label={t('partnership_form_answers_partnership_name')}
                      value={
                        partnershipFormAnswer
                          ? partnershipFormAnswer.partnership.name
                          : null
                      }
                    />
                    <DisplayInput
                      label={t('partnership_form_answers_care_guide_name')}
                      value={
                        partnershipFormAnswer
                          ? partnershipFormAnswer.care_guide.first_name +
                            ' ' +
                            partnershipFormAnswer.care_guide.last_name
                          : null
                      }
                    />
                    <DisplayInput
                      label={t('partnership_form_answers_care_guide_owner')}
                      value={
                        partnershipFormAnswer
                          ? partnershipFormAnswer.care_guide.owner.profile.user
                              .username
                          : null
                      }
                    />
                    <DisplayInput
                      label={t(
                        'partnership_form_answers_user_profile_username'
                      )}
                      value={
                        partnershipFormAnswer
                          ? partnershipFormAnswer.profile.user.username
                          : null
                      }
                    />
                    {partnershipFormAnswer?.answers ? (
                      <>
                        {Object.entries(partnershipFormAnswer?.answers).map(
                          ([key, val]) => (
                            <DisplayInput
                              label={
                                Array.isArray(key.split(/(?=[A-Z])/))
                                  ? key.split(/(?=[A-Z])/).join(' ')
                                  : key.split(/(?=[A-Z])/)[0]
                              }
                              value={Array.isArray(val) ? val.join(', ') : val}
                            />
                          )
                        )}
                      </>
                    ) : null}
                  </Card>
                </Col>
                <Col xs={24} lg={6}>
                  <Affix offsetTop={10}>
                    <Card title={t('form_metadata_header')}>
                      <FormMetadata
                        id={partnershipFormAnswer?.id}
                        showStatus={false}
                        showSubmit={false}
                        isSubmitting={false}
                        delete={remove.bind(this)}
                        deleteRedirectTo="PartnershipFormAnswersScreen"
                        createdAt={partnershipFormAnswer?.created_at}
                        updatedAt={partnershipFormAnswer?.updated_at}
                      />
                    </Card>
                  </Affix>
                </Col>
              </Row>
            </div>
          </div>
        )}
      </Translation>
    );
  }

  /* LIFECYCLE EVENTS */

  componentDidMount() {
    this.scrollToTop();
    this.props.load(this.props.id);
  }
}

export default PartnershipFormAnswerViewScreen;

Logger.log('silly', `PartnershipFormAnswerViewScreen loaded.`);
