import {connect} from 'react-redux';

import CareGuideHelperTagsScreen from '../components/CareGuideHelperTagsScreen';

const mapStateToProps = state => {
  return {
    total: state.careGuideHelperTags.get('total'),
    isLoading: state.careGuideHelperTags.get('isLoading')
  }
}

const CareGuideHelperTagsScreenContainer = connect(
  mapStateToProps
)(CareGuideHelperTagsScreen);

export default CareGuideHelperTagsScreenContainer;
