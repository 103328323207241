import {normalize} from 'normalizr';

import api from '../../api';
import {schema} from '../../schema';
import {addEntities, removeEntity} from '../../actions';
import Logger from '../../../lib/Logger';

export const POSTAL_CODE_LIST_REQUEST = 'POSTAL_CODE_LIST_REQUEST';
export const POSTAL_CODE_LIST_SUCCESS = 'POSTAL_CODE_LIST_SUCCESS';
export const POSTAL_CODE_LIST_FAILURE = 'POSTAL_CODE_LIST_FAILURE';
export const POSTAL_CODE_READ_REQUEST = 'POSTAL_CODE_READ_REQUEST';
export const POSTAL_CODE_READ_SUCCESS = 'POSTAL_CODE_READ_SUCCESS';
export const POSTAL_CODE_READ_FAILURE = 'POSTAL_CODE_READ_FAILURE';
export const POSTAL_CODE_CREATE_FAILURE = 'POSTAL_CODE_CREATE_FAILURE';
export const POSTAL_CODE_CREATE_REQUEST = 'POSTAL_CODE_CREATE_REQUEST';
export const POSTAL_CODE_CREATE_SUCCESS = 'POSTAL_CODE_CREATE_SUCCESS';
export const POSTAL_CODE_DELETE_REQUEST = 'POSTAL_CODE_DELETE_REQUEST';
export const POSTAL_CODE_DELETE_SUCCESS = 'POSTAL_CODE_DELETE_SUCCESS';
export const POSTAL_CODE_DELETE_FAILURE = 'POSTAL_CODE_DELETE_FAILURE';
export const POSTAL_CODE_UPDATE_REQUEST = 'POSTAL_CODE_UPDATE_REQUEST';
export const POSTAL_CODE_UPDATE_SUCCESS = 'POSTAL_CODE_UPDATE_SUCCESS';
export const POSTAL_CODE_UPDATE_FAILURE = 'POSTAL_CODE_UPDATE_FAILURE';
export const POSTAL_CODE_FORM_DESTROY = 'POSTAL_CODE_FORM_DESTROY';

export function postalCodeListRequest(page, limit, order, filter) {
    Logger.log('debug', `[location.actions] postalCodeListRequest(${page}, ${limit})`);
    return {
      type: POSTAL_CODE_LIST_REQUEST,
      page: page,
      limit: limit,
      order: order,
      filter: filter
    }
  }
  
  export function postalCodeListSuccess(data) {
    Logger.log('debug', `[location.actions] postalCodeListSuccess(%j)`, data);
    return {
      type: POSTAL_CODE_LIST_SUCCESS,
      page: data.page,
      limit: data.limit,
      order: data.order,
      result: data.result,
      total: data.total,
      receivedAt: Date.now()
    }
  }
  
  export function postalCodeListFailure(error) {
    Logger.log('debug', `[location.actions] postalCodeListFailure(%j)`, error);
    return {
      type: POSTAL_CODE_LIST_FAILURE,
      error: error
    }
  }
  
  export function postalCodeCreateRequest(data) {
    Logger.log('debug', `[postalCode.actions] postalCodeCreateRequest(%j)`, data);
    return {
      type: POSTAL_CODE_CREATE_REQUEST,
    }
  }
  
  export function postalCodeCreateSuccess(data) {
    Logger.log('debug', `[postalCode.actions] postalCodeCreateSuccess(%j)`, data);
    return {
      type: POSTAL_CODE_CREATE_SUCCESS,
      id: data.id,
      receivedAt: Date.now()
    }
  }
  
  export function postalCodeCreateFailure(error) {
    Logger.log('debug', `[postalCode.actions] postalCodeCreateFailure(%j)`, error);
    return {
      type: POSTAL_CODE_CREATE_FAILURE,
      error: error
    }
  }
  
  export function postalCodeReadRequest(id) {
    Logger.log('debug', `[postalCode.actions] postalCodeReadRequest(${id})`);
    return {
      type: POSTAL_CODE_READ_REQUEST,
      id: id
    }
  }
  
  export function postalCodeReadSuccess(data) {
    Logger.log('debug', `[postalCode.actions] postalCodeReadSuccess(%j)`, data);
    return {
      type: POSTAL_CODE_READ_SUCCESS,
      id: data.id,
      receivedAt: Date.now()
    }
  }
  
  export function postalCodeReadFailure(error) {
    Logger.log('debug', `[postalCode.actions] postalCodeReadFailure(%j)`, error);
    return {
      type: POSTAL_CODE_READ_FAILURE,
      error: error
    }
  }
  
  export function postalCodeUpdateRequest(id, data) {
    Logger.log('debug', `[postalCode.actions] postalCodeUpdateRequest(${id}, %j)`, data);
    return {
      type: POSTAL_CODE_UPDATE_REQUEST,
    }
  }
  
  export function postalCodeUpdateSuccess(data) {
    Logger.log('debug', `[postalCode.actions] postalCodeUpdateSuccess(%j)`, data);
    return {
      type: POSTAL_CODE_UPDATE_SUCCESS,
      id: data.id,
      receivedAt: Date.now()
    }
  }
  
  export function postalCodeUpdateFailure(error) {
    Logger.log('debug', `[postalCode.actions] postalCodeUpdateFailure(%j)`, error);
    return {
      type: POSTAL_CODE_UPDATE_FAILURE,
      error: error
    }
  }
  
  export function  postalCodeDeleteRequest(id) {
    Logger.log('debug', `[ postalCode.actions] postalCodeDeleteRequest(${id})`);
    return {
      type: POSTAL_CODE_DELETE_REQUEST,
      id: id
    }
  }
  
  export function postalCodeDeleteSuccess(id) {
    Logger.log('debug', `[postalCode.actions] postalCodeDeleteSuccess(${id})`);
    return {
      type: POSTAL_CODE_DELETE_SUCCESS,
      id: id,
    }
  }
  
  export function postalCodeDeleteFailure(error) {
    Logger.log('debug', `[postalCode.actions] postalCodeDeleteFailure(%j)`, error);
    return {
      type: POSTAL_CODE_DELETE_FAILURE,
      error: error
    }
  }
  
  export function postalCodeFormDestroy(formState=null) {
    Logger.log('debug', `[postalCode.actions] postalCodeFormDestroy(%j)`, formState);
    return {
      type: POSTAL_CODE_FORM_DESTROY,
      form: formState
    }
  }

  // API THUNK ACTION CREATORS

  export function loadPostalCodes(page=1, limit=250, order=null, filter=null, cb=function(){}) {
    Logger.log('debug', `[location.actions] loadPostalCodes(${page}, ${limit}, ${order}, %j, ###)`, filter);
  
    return async function(dispatch) {
      dispatch(postalCodeListRequest(page, limit, order, filter));
  
      // call API
      const response = await api.getPostalCodes(page, limit, order, filter);
  
      // get postal codes list success
      if (200 === response.get('status')) {
  
        Logger.log('info', `Get API postal codes list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
  
        const normalizedPostalCodes = normalize(response.getIn(['data', 'postal_codes']), [schema.postalCode]);
  
        const postalCodeSuccessData = {
          page: response.getIn(['data', 'page']),
          limit: response.getIn(['data', 'limit']),
          order: order,
          total: response.getIn(['data', 'total']),
          result: normalizedPostalCodes.result
        };
  
        dispatch(addEntities(normalizedPostalCodes));
        dispatch(postalCodeListSuccess(postalCodeSuccessData));
        
      // get postal codes list failure
      } else {
        Logger.log('info', `Get API postal codes list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
        dispatch(postalCodeListFailure(response.getIn(['data', 'error'])));
      }
  
      // callback function
      cb();
    }
  }
  
  export function loadPostalCode(id, cb=function(){}) {
    Logger.log('debug', `[postalCode.actions] loadPostalCode(${id}, ###)`);
  
    return async function(dispatch) {
      dispatch(postalCodeReadRequest(id));
  
      // call API
      const response = await api.getPostalCode(id);
      let success = false;
  
      // get postalCode success
      if (200 === response.get('status')) {
  
        Logger.log('info', `Get API postalCode success. ID: ${id}.`);
  
        const normalizedEntities = normalize([response.getIn(['data', 'postal_code'])], [schema.postalCode]);
        const data = {
          id: response.getIn(['data', 'postal_code', 'id']),
        };
  
        dispatch(addEntities(normalizedEntities));
        dispatch(postalCodeReadSuccess(data));
        success = true;
        
      // get postalCode failure
      } else {
        Logger.log('info', `Get API postalCode failure. ID: ${id}.`);
        dispatch(postalCodeReadFailure(response.getIn(['data', 'error'])));
      }
  
      // callback function
      cb(success);
    }
  }
  
  export function createPostalCode(data, cb=function(){}) {
    Logger.log('debug', `[postalCode.actions] createPostalCode(%j, ###)`, data);
  
    return async function(dispatch) {
      dispatch(postalCodeCreateRequest(data));
  
      // call API
      const response = await api.postPostalCode(data);
      let success = false;
  
      // post postalCode success
      if (201 === response.get('status')) {
  
        Logger.log('info', `POST API postalCode success. Post: ${response.getIn(['data', 'postal_code', 'id'])}.`);
  
        const normalizedEntities = normalize([response.getIn(['data', 'postal_code'])], [schema.postalCode]);
        const data = {
          id: response.getIn(['data', 'postal_code', 'id']),
        };
        dispatch(addEntities(normalizedEntities));
        dispatch(postalCodeCreateSuccess(data));
        success = true;
        
      // get postal codes failure
      } else {
        Logger.log('info', `POST API postalCode failure.`);
        dispatch(postalCodeCreateFailure(response.getIn(['data', 'error'])));
      }
  
      // callback function
      cb(success);
    }
  }
  
  export function updatePostalCode(id, data, cb=function(){}) {
    Logger.log('debug', `[postalCode.actions] updatePostalCode(${id}, %j, ###)`, data);
  
    return async function(dispatch) {
      dispatch(postalCodeUpdateRequest(id, data));
  
      // call API
      const response = await api.putPostalCode(id, data);
      let success = false;
  
      // put postalCode success
      if (200 === response.get('status')) {
  
        Logger.log('info', `PUT API postalCode success. User: ${id}.`);
  
        const normalizedEntities = normalize([response.getIn(['data', 'postal_code'])], [schema.postalCode]);
        const data = {
          id: response.getIn(['data', 'postal_code', 'id']),
        };
  
        dispatch(addEntities(normalizedEntities));
        dispatch(postalCodeUpdateSuccess(data));
        success = true;
        
      // get postalCode failure
      } else {
        Logger.log('info', `PUT API postalCode failure. ID: ${id}.`);
        dispatch(postalCodeUpdateFailure(response.getIn(['data', 'error'])));
      }
  
      // callback function
      cb(success);
    }
  }
  
  export function deletePostalCode(id, cb=function(){}) {
    Logger.log('debug', `[locations.actions] deletePostalCode(${id}, ###)`);
  
    return async function(dispatch) {
      dispatch(postalCodeDeleteRequest(id));
  
      // call API
      const response = await api.deletePostalCode(id);
      let success = false;
  
      // delete postalCode post success
      if (204 === response.get('status')) {
  
        Logger.log('info', `DELETE API postalCode success. ID: ${id}.`);
  
        dispatch(removeEntity({entityType: 'postal_code', id: id}));
        dispatch(postalCodeDeleteSuccess(id));
        success = true;
        
      // get postalCode failure
      } else {
        Logger.log('info', `DELETE API postalCode post failure. ID: ${id}.`);
        dispatch(postalCodeDeleteFailure(response.getIn(['data', 'error'])));
      }
  
      // callback function
      cb(success);
    }
  }