import {connect} from 'react-redux';

import CareGuideOwnerAddScreen from '../components/CareGuideOwnerAddScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.careGuideOwners.get('isLoading'),
    success: state.careGuideOwners.getIn(['form', 'success']),
  }
}

const CareGuideOwnerAddScreenContainer = connect(
  mapStateToProps
)(CareGuideOwnerAddScreen);

export default CareGuideOwnerAddScreenContainer;
