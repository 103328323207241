import {connect} from 'react-redux';

import JournalPostEditScreen from '../components/JournalPostEditScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.journalPosts.get('isLoading'),
    success: state.journalPosts.getIn(['form', 'success']),
  }
}

const JournalPostEditScreenContainer = connect(
  mapStateToProps
)(JournalPostEditScreen);

export default JournalPostEditScreenContainer;
