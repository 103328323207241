import {connect} from 'react-redux';

import CountryAddScreen from '../components/CountryAddScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.locationCountries.get('areCountriesLoading'),
    success: state.locationCountries.getIn(['form', 'success']),
  }
}

const CountryAddScreenContainer = connect(
  mapStateToProps
)(CountryAddScreen);

export default CountryAddScreenContainer;
