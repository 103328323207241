import {connect} from 'react-redux';

import AccountEditScreen from '../components/AccountEditScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.accounts.get('isLoading'),
    success: state.accounts.getIn(['form', 'success']),
  }
}

const AccountEditScreenContainer = connect(
  mapStateToProps
)(AccountEditScreen);

export default AccountEditScreenContainer;
