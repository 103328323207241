import { connect } from 'react-redux';

import { loadUsersCareGuides } from '../../../../../state/modules/users/actions';
import UserCareGuidesInput from '../components/UserCareGuidesInput';

const mapStateToProps = (state) => {
  return {
    total: state.users.get('total'),
    isLoading: state.users.get('isLoading'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (page, limit, order, filter, cb) => {
      dispatch(loadUsersCareGuides(page, limit, order, filter, cb));
    },
  };
};

const UserCareGuidesInputContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserCareGuidesInput);

export default UserCareGuidesInputContainer;
