import React, {useState, useEffect} from "react";
import {Translation} from 'react-i18next';
import {Form, Input} from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default function BodyInput({name, label, form, defaultValue='', ...props}) {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue, setValue]);

  return (
    <Translation>{(t) => 
      <div className="form-group">

        <Form.Item name={name} style={{display: 'none'}} {...props}>
          <Input type="hidden" />
        </Form.Item>

        <Form.Item label={label}>
          <ReactQuill
            theme="snow"
            value={value}
            onChange={(content, delta, source, editor) => {
              setValue(content);
              form.setFields([{
                name: [name],
                value: editor.getHTML()
              }]);
            }}
          />
        </Form.Item>

      </div>
    }</Translation>
  );
}
