import {connect} from 'react-redux';

import {downloadResourceTagsCSV} from '../../../../../state/modules/resourceTags/actions';
import ResourceTagsCSVButton from '../components/ResourceTagsCSVButton';

const mapStateToProps = state => {
  return {
    isDownloading: state.resourceTags.get('isCSVDownloading'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    download: (order, filter, cb) => {
      dispatch(downloadResourceTagsCSV(order, filter, cb));
    }
  }
}

const ResourceTagsCSVButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResourceTagsCSVButton);

export default ResourceTagsCSVButtonContainer;
