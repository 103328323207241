import {getI18n} from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import AccountsScreen from './containers/AccountsScreenContainer';
import AccountAddScreen from './containers/AccountAddScreenContainer';
import AccountEditScreen from './containers/AccountEditScreenContainer';

const register = () => {
  Logger.log('debug', `accounts.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'accounts', 'routes'])) {
    Events.dispatch('ADD_MAIN_ROUTES', 
      {
        'AccountAddScreen': ['PrivateRoute', '/accounts/add', true, AccountAddScreen, getI18n().t('account_route_add')],
        'AccountEditScreen': ['PrivateRoute', '/accounts/edit/:id([\\w\\-]+)', true, AccountEditScreen, getI18n().t('account_route_edit')],
        'AccountsScreen': ['PrivateRoute', '/accounts/:page(\\d+)?', true, AccountsScreen, getI18n().t('accounts_route_list')],
      }
    );
  }
}

export default register;

Logger.log('silly', `accounts.register() loaded.`);
