import { Map, List } from 'immutable';

import {
  ALERT_LIST_REQUEST,
  ALERT_LIST_SUCCESS,
  ALERT_LIST_FAILURE,
  ALERT_READ_REQUEST,
  ALERT_READ_SUCCESS,
  ALERT_READ_FAILURE,
  ALERT_CSV_DOWNLOAD_REQUEST,
  ALERT_CSV_DOWNLOAD_SUCCESS,
  ALERT_CSV_DOWNLOAD_FAILURE,
} from './actions';
import Logger from '../../../lib/Logger';

export default function alerts(
  state = Map({
    isLoading: false,
    isCSVDownloading: false,
    pages: {},
  }),
  action
) {
  Logger.log('debug', `[alerts.reducers] alerts(%j, %j)`, state, action);

  switch (action.type) {
    case ALERT_LIST_REQUEST:
      return state.mergeDeep({
        isLoading: true,
      });

    case ALERT_LIST_SUCCESS:
      return state
        .mergeDeep({
          isLoading: false,
          pages: {
            [action.order]: {
              [action.limit]: {
                [action.page]: null,
              },
            },
          },
          total: action.total,
          lastUpdated: action.receivedAt,
        })
        .setIn(
          ['pages', action.order, action.limit, action.page],
          List(action.result)
        );

    case ALERT_LIST_FAILURE:
      return state.mergeDeep({
        isLoading: false,
      });

    case ALERT_READ_REQUEST:
      return state.mergeDeep({
        isLoading: true,
      });

    case ALERT_READ_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        lastUpdated: action.receivedAt,
      });

    case ALERT_READ_FAILURE:
      return state.mergeDeep({
        isLoading: false,
      });

    case ALERT_CSV_DOWNLOAD_REQUEST:
      return state.mergeDeep({
        isCSVDownloading: true,
      });

    case ALERT_CSV_DOWNLOAD_SUCCESS:
      return state.mergeDeep({
        isCSVDownloading: false,
      });

    case ALERT_CSV_DOWNLOAD_FAILURE:
      return state.mergeDeep({
        isCSVDownloading: false,
      });

    default:
      return state;
  }
}

Logger.log('silly', `alerts.reducers loaded.`);
