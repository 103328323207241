import { connect } from 'react-redux';

import CalendarEventsScreen from '../components/CalendarEventsScreen';
import { downloadCalendarEventsCSV } from '../../../../../state/modules/calendarEvents/actions';

const mapStateToProps = (state) => {
  return {
    total: state.calendarEvents.get('total'),
    isLoading: state.calendarEvents.get('isLoading'),
    isDownloading: state.calendarEvents.get('isCSVDownloading'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    download: (order, filter, cb) => {
      dispatch(downloadCalendarEventsCSV(order, filter, cb));
    },
  };
};

const CalendarEventsScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CalendarEventsScreen);

export default CalendarEventsScreenContainer;
