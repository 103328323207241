import {connect} from 'react-redux';

import RegionScreen from '../components/RegionScreen';

const mapStateToProps = state => {
  return {
    total: state.locationRegions.get('total'),
    isLoading: state.locationRegions.get('areRegionsLoading')
  }
}

const RegionScreenContainer = connect(
  mapStateToProps
)(RegionScreen);

export default RegionScreenContainer;
