import React from 'react';
import { Translation } from 'react-i18next';
import { Form, Select } from 'antd';

const TypeInput = ({ name, label, onChange, disabled = false, ...props }) => {
  const options = [
    {
      value: 1,
      label: 'dashboard_section_type_resources',
    },
    {
      value: 2,
      label: 'dashboard_section_type_daily_logs',
    },
    {
      value: 3,
      label: 'dashboard_section_type_recommended',
    },
  ];

  return (
    <Translation>
      {(t) => (
        <div className="form-group">
          <Form.Item
            name={name}
            label={label}
            rules={[
              { required: true, message: t('feedback_validation_required') },
            ]}
            {...props}
          >
            <Select disabled={disabled} onChange={onChange}>
              {options.map((x) => (
                <Select.Option key={x.value} value={x.value}>
                  {t(x.label)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      )}
    </Translation>
  );
};

export default TypeInput;
