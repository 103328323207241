import {getI18n} from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import CareGuideHelpersScreen from './containers/CareGuideHelpersScreenContainer';
import CareGuideHelperAddScreen from './containers/CareGuideHelperAddScreenContainer';
import CareGuideHelperEditScreen from './containers/CareGuideHelperEditScreenContainer';

const register = () => {
  Logger.log('debug', `careGuideHelpers.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'careGuideHelpers', 'routes'])) {
    Events.dispatch('ADD_MAIN_ROUTES', 
      {
        'CareGuideHelperAddScreen': ['PrivateRoute', '/care-guide-helpers/add', true, CareGuideHelperAddScreen, getI18n().t('care_guide_helper_route_add')],
        'CareGuideHelperEditScreen': ['PrivateRoute', '/care-guide-helpers/edit/:id([\\w\\-]+)', true, CareGuideHelperEditScreen, getI18n().t('care_guide_helper_route_edit')],
        'CareGuideHelpersScreen': ['PrivateRoute', '/care-guide-helpers/:page(\\d+)?', true, CareGuideHelpersScreen, getI18n().t('care_guide_helpers_route_list')],
      }
    );
  }
}

export default register;

Logger.log('silly', `careGuideHelpers.register() loaded.`);
