import { connect } from 'react-redux';
import { denormalize } from 'normalizr';

import { schema } from '../../../../../state/schema';
import { loadAdminPartnerClasses, deleteAdminPartnerClass } from '../../../../../state/modules/adminPartnerClasses/actions';
import { sendMessage } from '../../../../../state/actions';
import AdminPartnerClassesList from '../components/AdminPartnerClassesList';

const mapStateToProps = (state, ownProps) => {

  const page = ownProps.page;
  const limit = ownProps.limit;
  const order = ownProps.order;
  const result = state.adminPartnerClasses.getIn(['pages', order, limit, page]);

  return {
    list: result
      ? result.map(x => {
        return {
          key: x,
          ...denormalize(
            state.entities.getIn(['adminPartnerClasses', x]),
            schema.adminPartnerClass,
            state.entities.toJS()
          )
        };
      }).toArray().map(x => {
        return{
          partnership_name: x?.partnership?.name ?? '',
          partnership_plan: x?.partnership?.plan?.name ?? '',
          ...x,
        }
      })
      : [],
    isLoading: state.adminPartnerClasses.get('isLoading'),
    total: state.adminPartnerClasses.get('total'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (page, limit, order, filter, cb) => {
      dispatch(loadAdminPartnerClasses(page, limit, order, filter, cb));
    },
    remove: (id, cb) => {
      dispatch(deleteAdminPartnerClass(id, cb));
    },
    sendMessage: (level, title, body, expires) => {
      dispatch(sendMessage(level, title, body, expires));
    },
  }
}

const AdminPartnerClassesListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminPartnerClassesList);

export default AdminPartnerClassesListContainer;