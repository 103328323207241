import React, { useEffect, useState } from 'react';
import { Translation, getI18n } from 'react-i18next';
import { Button, Card, Col, Row, Space, Spin } from 'antd';
import { LoadingOutlined, PlusCircleOutlined } from '@ant-design/icons';
import message from '../../../elements/lib/MessageWrapper';
import CareGuideDocumentsList from '../containers/CareGuideDocumentsListContainer';
import CareGuideDocumentsCSVButton from '../containers/CareGuideDocumentsCSVButtonContainer';
import DocumentHead from '../../../elements/components/DocumentHead';
import { pathTo } from '../../../Routes';
import Config from '../../../../../Config';
import QueryString from '../../../../../lib/QueryString';
import Logger from '../../../../../lib/Logger';
import PartnershipModalExport from '../../partnerships/components/PartnershipModalExport';

const labels = {
  title: 'care_guide_documents_export_title',
  title_btn_cancel: 'care_guide_documents_button_cancel',
  title_btn_export: 'care_guide_documents_button_export',
  title_list_export: 'care_guide_documents_list_export',
  title_partnership_input: 'care_guide_documents_partnership_input',
};

const CareGuideDocumentsScreen = ({
  isDownloading,
  total,
  download,
  ...props
}) => {
  const [openModalExport, setOpenModalExport] = useState(false);
  const [optionExport, setOptionExport] = useState(1);
  const limit = Config.get('DEFAULT_LIST_LENGTH', 10);

  const scrollToTop = () => {
    Logger.log('debug', `CareGuideDocumentsScreen.scrollToTop()`);
    if (typeof window !== 'undefined') {
      setTimeout(() => window.scrollTo(0, 0), 100);
    }
  };

  const loadingIcon = (
    <Spin indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} />
  );

  const page = parseInt(props.match.params.page ? props.match.params.page : 1);
  const { order, ...filter } = QueryString.parse(props.location.search);

  useEffect(() => {
    scrollToTop();
  }, []);

  const submitData = async (values, partnershipId, clearForm) => {
    Logger.log('debug', `PartnershipModalExport.submitData(###)`);

    let filterByOptions = filter;

    if (values['option_export'] === 2) {
      filterByOptions = { partnership_id: partnershipId, ...filter };
    }

    download(order || 'updated_at.desc', filterByOptions, (success) => {
      if (success) {
        message.success(
          getI18n().t('care_guide_documents_message_export_success')
        );
        clearForm();
      } else {
        message.error(
          getI18n().t('care_guide_documents_message_export_failure')
        );
      }
    });
  };

  return (
    <Translation>
      {(t) => (
        <div>
          <DocumentHead title={t('care_guide_documents_route_list')} />

          <Row>
            <Col span={24}>
              <Card
                title={
                  <Space size={10}>
                    {t('care_guide_documents_list_title')}
                    <Button
                      size="small"
                      icon={<PlusCircleOutlined />}
                      onClick={(e) =>
                        props.history.push(pathTo('CareGuideDocumentAddScreen'))
                      }
                    >
                      {t('action_create')}
                    </Button>
                    <CareGuideDocumentsCSVButton
                      onClick={() => {
                        setOpenModalExport(true);
                      }}
                    />
                  </Space>
                }
                extra={props.isLoading ? loadingIcon : null}
              >
                <CareGuideDocumentsList
                  key={page}
                  component="CareGuideDocumentsScreen"
                  page={page}
                  limit={limit}
                  order={order || 'updated_at.desc'}
                  filter={filter}
                />
              </Card>
            </Col>
          </Row>
          <PartnershipModalExport
            labels={labels}
            openModalExport={openModalExport}
            setOpenModalExport={setOpenModalExport}
            order={order}
            filter={filter}
            isDownloading={isDownloading}
            total={total}
            download={download}
            optionExport={optionExport}
            setOptionExport={setOptionExport}
            submitData={submitData}
            options={[
              {
                value: 1,
                disabled: total < 1,
                label: 'care_guide_documents_export_option_all',
              },
              {
                value: 2,
                disabled: total < 1,
                label: 'care_guide_documents_filtered_by_partnership',
              },
            ]}
          />
        </div>
      )}
    </Translation>
  );
};

export default CareGuideDocumentsScreen;

Logger.log('silly', `CareGuideDocumentsScreen loaded.`);
