import {connect} from 'react-redux';
import {List} from 'immutable';
import moment from 'moment';

import {
  loadMedium,
  updateMedium,
  createMedia,
  deleteMedium,
  mediaFormDestroy
} from '../../../../../state/modules/media/actions';
import {sendMessage} from '../../../../../state/actions';
import MediumForm from '../components/MediumForm';
import Config from '../../../../../Config';

const inputs = List([
  'care_guide',
  'owner',
  'file_name',
  'file_extension',
  'file_directory',
  'file_bucket',
  'media_type',
  'size_bytes',
  'uploaded_at',
  'status',
  'partnership'
]);

const mapStateToProps = (state, ownProps) => {

  const data = {}, errors = {};
  for (const val of inputs.values()) {
    data[val] = state.entities.getIn(['media', ownProps.id, val], '');
    if (['care_guide', 'owner'].includes(val)) {
      errors[val] = state.media.getIn(['form', 'errors', val + '_id']);
    }
    else {
      errors[val] = state.media.getIn(['form', 'errors', val]);
    }
  }

  // change select number values to strings to work with ant design select inputs
  Object.keys(data).forEach(x => {
    if (['status'].includes(x)) {
      data[x] = data[x].toString();
    }
  });

  data['uploaded_at'] = data['uploaded_at']
    ? moment(data['uploaded_at'], Config.get('API_DATETIME_FORMAT'))
    : moment();

  return {
    isLoading: state.media.get('isLoading'),
    isSubmitting: state.media.getIn(['form', 'isSubmitting']),
    success: state.media.getIn(['form', 'success']),
    created_id: state.media.getIn(['form', 'created_id']),
    data: data,
    errors: errors,
    createdAt: state.entities.getIn(['media', ownProps.id, 'created_at'], null),
    updatedAt: state.entities.getIn(['media', ownProps.id, 'updated_at'], null),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (id, cb) => {
      dispatch(loadMedium(id, cb));
    },
    update: (id, data, cb) => {
      dispatch(updateMedium(id, data, cb));
    },
    create: (data, cb) => {
      dispatch(createMedia(data, cb));
    },
    delete: (id, cb) => {
      dispatch(deleteMedium(id, cb))
    },
    destroyForm: (formState) => {
      dispatch(mediaFormDestroy(formState));
    },
    sendMessage: (level, title, body, expires) => {
      dispatch(sendMessage(level, title, body, expires));
    },
  }
}

const MediumFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MediumForm);

export default MediumFormContainer;
