import React, {useState, useEffect} from 'react';
import {Redirect} from 'react-router-dom'
import {Translation, getI18n} from 'react-i18next';
import {Affix, Card, Col, Form, Input, InputNumber, Row, Spin} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';

import SubscriptionCycleInput from './SubscriptionCycleInput';
import SubscriptionStatusInput from './SubscriptionStatusInput';
import UserInput from '../../users/containers/UserInputContainer';
import PlanInput from '../../plans/containers/PlanInputContainer';
import TemplateInput from '../../careGuideTemplates/containers/TemplateInputContainer';
import message from '../../../elements/lib/MessageWrapper';
import FormMetadata from '../../../elements/components/FormMetadata';
import {pathTo} from '../../../Routes';
import Logger from '../../../../../lib/Logger';


const AccountForm = ({id, data, account, errors, load, destroyForm, isLoading, isSubmitting, created_id, ...props}) => {

  const [redirectTo, setRedirectTo] = useState(null);
  const [form] = Form.useForm();

  // form column settings
  const layout = {
    main: {
      labelCol: {span: 5},
      wrapperCol: {span: 19},
    },
  }

  // load record data from API
  useEffect(() => {
    if (id) {
      load(id);
    }
  }, [id, load]);

  // update input values when new data is available
  const dataString = JSON.stringify(data);
  useEffect(() => {
    if (id && !isSubmitting) {
      const dataObj = JSON.parse(dataString);
      form.setFieldsValue(dataObj);
    }
  }, [form, dataString, isSubmitting, id]);

  // handle errors reported by API
  useEffect(() => {
    let firstFieldName = '';
    for (const field in errors) {
      form.setFields([{name: field, errors: errors[field]}]);
      if (firstFieldName === '') {
        firstFieldName = field;
      }
    }
    form.scrollToField(firstFieldName);
  }, [form, errors]);

  // redirect add form to edit form on successful create action
  useEffect(() => {
    if (created_id) {
      setRedirectTo(pathTo('AccountEditScreen', {id: created_id}));
    }
    return () => {
      destroyForm();
    }
  }, [created_id, setRedirectTo, destroyForm]);

  // submit data handler
  const submitData = async (values) => {
    Logger.log('debug', `AccountForm.submitData(###)`);

    // API POST/PUT payload
    let payload = {};
    for (const input of Object.keys(data)) {
      if (input in values) {

        // transform entity refs to integer IDs
        if (['plan'].includes(input)) {
          payload[input + '_id'] = parseInt(values[input]);
        }
        
        // transform entity refs to UUIDs
        else if (['template', 'owner'].includes(input)) {
          payload[input + '_id'] = values[input];
        }

        else if (['subscription_id'].includes(input)) {
          payload[input] = values[input] ? values[input] : null;
        }
        
        else {
          payload[input] = values[input];
        }
      }
    }

    if (id) { // update
      props.update(id, payload, (success) => {
        if (success) {
          message.success(getI18n().t('feedback_form_success'));
        } else {
          message.error(getI18n().t('feedback_form_error'));
        }
      });      
    } else { // create
      props.create(payload, (success) => {
        if (success) {
          message.success(getI18n().t('feedback_form_success'));
        } else {
          message.error(getI18n().t('feedback_form_error'));
        }
      });      
    }
  }

  // form submit handler
  const handleFinish = async (values) => {
    Logger.log('debug', `AccountForm.handleFinish(###)`);
    if (!props.isSubmitting) {
      await submitData(values);
    }
  }

  // form error handler
  const handleFinishFailed = ({values, errorFields, outOfDate}) => {
    Logger.log('debug', `AccountForm.handleFinishFailed(###)`);
    message.error(getI18n().t('feedback_form_error'));
    if (errorFields && errorFields.length > 0) {
      form.scrollToField(errorFields[0].name);
    }
  }

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <Translation>{(t) => 
      <>
        <div className="account-form">
          <Form
            name="account_form"
            form={form}
            initialValues={data}
            onFinish={handleFinish}
            onFinishFailed={handleFinishFailed}
            validateTrigger="onSubmit"
            {...layout.main}
          >
            <Row gutter={16}>

              <Col xs={24} lg={18}>
                <Card
                  title={id ? t('account_edit_title') : t('account_add_title')}
                  extra={isLoading
                    ? <Spin indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} />
                    : null}
                >

                  {id
                    ? <div className="form-group">
                        <Form.Item label={t('account_account_id')}>
                          <span className="ant-form-text">{id}</span>
                        </Form.Item>
                      </div>
                    : null}

                  <div className="form-group">
                    <Form.Item label={t('account_default_care_guide_limit')}>
                      <Form.Item
                        name="default_care_guide_limit"
                        extra={t('account_hint_default_override')}
                        rules={[
                          {type: 'number', message: t('feedback_validation_number')},
                          {type: 'number', min: -1, max: 32767, message: t('feedback_validation_range', {min: 1, max: 32767})},
                        ]}
                        noStyle
                      >
                        <InputNumber
                          min={1}
                          max={32767}
                          disabled={isLoading || isSubmitting}
                        />
                      </Form.Item>
                      {account && account.plan
                        ? <span className="ant-form-text">{t('account_extra_default_value', { value: account.plan.care_guide_limit })}</span>
                        : null}
                    </Form.Item>
                  </div>

                  <div className="form-group">
                    <Form.Item label={t('account_helper_limit')}>
                      <Form.Item
                        name="helper_limit"
                        extra={t('account_hint_default_override')}
                        rules={[
                          {type: 'number', message: t('feedback_validation_number')},
                          {type: 'number', min: -1, max: 32767, message: t('feedback_validation_range', {min: 1, max: 32767})},
                        ]}
                        noStyle
                      >
                        <InputNumber
                          min={1}
                          max={32767}
                          disabled={isLoading || isSubmitting}
                        />
                      </Form.Item>
                      {account && account.plan
                        ? <span className="ant-form-text">{t('account_extra_default_value', { value: account.plan.helper_limit })}</span>
                        : null}
                      </Form.Item>
                  </div>

                  <div className="form-group">
                    <Form.Item label={t('account_upload_gb_limit')}>
                      <Form.Item
                        name="upload_gb_limit"
                        extra={t('account_hint_default_override')}
                        rules={[
                          {type: 'number', message: t('feedback_validation_number')},
                          {type: 'number', min: -1, max: 32767, message: t('feedback_validation_range', {min: 1, max: 32767})},
                        ]}
                        noStyle
                      >
                        <InputNumber
                          min={1}
                          max={32767}
                          disabled={isLoading || isSubmitting}
                        />
                      </Form.Item>
                      {account && account.plan
                        ? <span className="ant-form-text">{t('account_extra_default_value', { value: account.plan.upload_gb_limit })}</span>
                        : null}
                    </Form.Item>
                  </div>

                  <UserInput
                    name="owner"
                    label={t('account_owner')}
                    rules={[
                      {required: true, message: t('feedback_validation_required')},
                    ]}
                    form={form}
                    disabled={isLoading || isSubmitting}
                  />

                  <PlanInput
                    name="plan"
                    label={t('account_plan')}
                    rules={[
                      {required: true, message: t('feedback_validation_required')},
                    ]}
                    form={form}
                    disabled={isLoading || isSubmitting}
                  />

                  <SubscriptionCycleInput
                    name="subscription_cycle"
                    label={t('accounts_input_subscription_cycle')}
                    rules={null}
                    disabled={isLoading || isSubmitting}
                  />

                  <TemplateInput
                    name="template"
                    label={t('account_template')}
                    disabled={isLoading || isSubmitting}
                  />

                  <div className="form-group">
                    <Form.Item
                      name="subscription_id"
                      label={t('users_subscription_id')}
                      rules={[
                        {type: 'string', min: 0, max: 40, message: t('feedback_validation_char_range', {min: 0, max: 40})},
                      ]}
                    >
                      <Input disabled={isLoading || isSubmitting} />
                    </Form.Item>
                  </div>

                  <SubscriptionStatusInput
                    name="subscription_status"
                    label={t('accounts_input_subscription_status')}
                    rules={[
                      {required: true, message: t('feedback_validation_required')},
                    ]}
                    disabled={isLoading || isSubmitting}
                  />

                </Card>
              </Col>

              <Col xs={24} lg={6}>
                <Affix offsetTop={10}>
                  <Card title={t('form_metadata_header')}>
                    <FormMetadata
                      id={id}
                      isSubmitting={isSubmitting}
                      delete={props.delete.bind(this)}
                      deleteRedirectTo="AccountsScreen"
                      createdAt={props.createdAt}
                      updatedAt={props.updatedAt}
                    />
                  </Card>
                </Affix>
              </Col>

            </Row>
          </Form>
        </div>
      </>
    }</Translation>
  );
}

export default AccountForm;

Logger.log('silly', `AccountForm loaded.`);
