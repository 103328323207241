import React from 'react';
import {Translation} from 'react-i18next';
import {Button, Checkbox, Form, Input, Radio, Space} from 'antd';
import {DragOutlined, MinusOutlined, PlusOutlined} from '@ant-design/icons';
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";

const FieldTypeADLTableEditor = ({form, content, contentsPath, ...props}) => {

  // const groups = form.getFieldValue([...contentsPath, `groups`]);

  // input column settings
  const layout = {
    main: {
      wrapperCol: {span: 24},
    },
  }

  // move draggable row into new position after drag and drop reorder
  const onDragEnd = (result, move) => {
    if (result.destination) {
      move(result.source.index, result.destination.index);
    }
  }

  return (
    <Translation>{(t) => 
      <>

        <div className="field-type-adl-table-group-list nested-list">
          <Form.List name={[content.name, 'groups']}>

            {(groups, {add, remove, move}) => {
              return(
                <>
                  {groups.map((group, i) => (
                    <div key={i}>
                      <Space direction="horizontal" align="start">
                        <Space size={2} direction="vertical">

                          <Form.Item
                            {...group}
                            name={[group.name, 'name']}
                            label={t('care_guide_adl_table_input_name')}
                            fieldKey={[group.fieldKey, 'name']}
                            rules={[
                              {required: true, message: t('feedback_validation_required')},
                            ]}
                            {...layout.main}
                          >
                            <Input placeholder="Header" />
                          </Form.Item>

                          <Form.Item
                            {...group}
                            name={[group.name, 'is_adjustable']}
                            label={t('care_guide_adl_table_input_is_adjustable')}
                            fieldKey={[group.fieldKey, 'is_adjustable']}
                            valuePropName="checked"
                            initialValue={false}
                            {...layout.main}
                          >
                            <Checkbox />
                          </Form.Item>

                          
                          <div className="ant-form-item-label">
                            {t('care_guide_adl_table_input_fields')}
                          </div>

                          <div className="nested-list">
                            <Form.List name={[group.name, 'fields']}>

                              {(fields, {add, remove, move}) => {
                                return(
                                  <>
                                    <DragDropContext onDragEnd={(result) => onDragEnd(result, move)}>
                                      <Droppable droppableId="droppable2">
                                        {(provided, snapshot) => (
                                          <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                          >
                                            {fields.map((field, i) => (
                                              <Draggable key={field.key} draggableId={field.key.toString()} index={i}>
                                                {(provided, snapshot) => (
                                                  <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                  >
                                                    <div key={i}>

                                                      <Space direction="horizontal" align="start">

                                                        <div className="drag-handle" {...provided.dragHandleProps}>
                                                          <DragOutlined />
                                                        </div>

                                                        <Space size={2} direction="vertical">

                                                          <Form.Item
                                                            {...field}
                                                            name={[field.name, 'name']}
                                                            label={t('care_guide_adl_table_field_input_name')}
                                                            fieldKey={[field.fieldKey, 'name']}
                                                            rules={[
                                                              {required: true, message: t('feedback_validation_required')},
                                                            ]}
                                                            {...layout.main}
                                                          >
                                                            <Input />
                                                          </Form.Item>

                                                          <Form.Item
                                                            {...field}
                                                            name={[field.name, 'hint']}
                                                            label={t('care_guide_adl_table_field_input_hint')}
                                                            fieldKey={[field.fieldKey, 'hint']}
                                                            rules={[]}
                                                            {...layout.main}
                                                          >
                                                            <Input />
                                                          </Form.Item>

                                                          <Form.Item
                                                            {...field}
                                                            name={[field.name, 'value']}
                                                            label={t('care_guide_adl_table_field_input_value')}
                                                            fieldKey={[field.fieldKey, 'value']}
                                                            rules={[]}
                                                            {...layout.main}
                                                          >
                                                            <Radio.Group>
                                                              <Radio value={1}>{t('care_guide_adl_table_field_input_value_independent')}</Radio>
                                                              <Radio value={2}>{t('care_guide_adl_table_field_input_value_needs_help')}</Radio>
                                                              <Radio value={4}>{t('care_guide_adl_table_field_input_value_cannot_do')}</Radio>
                                                            </Radio.Group> 
                                                          </Form.Item>

                                                          <Form.Item
                                                            {...field}
                                                            name={[field.name, 'notes']}
                                                            label={t('care_guide_adl_table_field_input_notes')}
                                                            fieldKey={[field.fieldKey, 'notes']}
                                                            rules={[]}
                                                            {...layout.main}
                                                          >
                                                            <Input.TextArea />
                                                          </Form.Item>

                                                        </Space>

                                                        <Button
                                                          danger
                                                          shape="circle"
                                                          size="small"
                                                          icon={<MinusOutlined />}
                                                          onClick={() => {
                                                            remove(field.name);
                                                          }}
                                                        />
                                                      </Space>
                                                    </div>
                                                  </div>
                                                )}
                                              </Draggable>
                                            ))}
                                            {provided.placeholder}
                                          </div>
                                        )}
                                      </Droppable>
                                    </DragDropContext>

                                    <Form.Item>
                                      <Button
                                        type="dashed"
                                        onClick={() => {
                                          add();
                                        }}
                                        block
                                      >
                                        <PlusOutlined /> Add field
                                      </Button>
                                    </Form.Item>
                                  </>
                                );
                              }}

                            </Form.List>
                          </div>

                        </Space>

                        <Button
                          danger
                          shape="circle"
                          size="small"
                          icon={<MinusOutlined />}
                          onClick={() => {
                            remove(group.name);
                          }}
                        />
                      </Space>
                    </div>
                  ))}

                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => {
                        add();
                      }}
                      block
                    >
                      <PlusOutlined /> Add group
                    </Button>
                  </Form.Item>
                </>
              );
            }}

            </Form.List>
        </div>

      </>
    }</Translation>
  )
}

export default FieldTypeADLTableEditor;
