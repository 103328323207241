import {connect} from 'react-redux';

import CountryEditScreen from '../components/CountryEditScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.locationCountries.get('areCountriesLoading'),
    success: state.locationCountries.getIn(['form', 'success']),
  }
}

const CountryEditScreenContainer = connect(
  mapStateToProps
)(CountryEditScreen);

export default CountryEditScreenContainer;
