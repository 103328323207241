import {connect} from 'react-redux';

import MediumAddScreen from '../components/MediumAddScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.media.get('isLoading'),
    success: state.media.getIn(['form', 'success']),
  }
}

const MediumAddScreenContainer = connect(
  mapStateToProps
)(MediumAddScreen);

export default MediumAddScreenContainer;
