import React from 'react';
import {Translation} from 'react-i18next';
import {Form, Select} from 'antd';

const TimeInput = ({name, label, rules, placeholder, disabled=false, disabledOptions=[], onChange=null, onDropdownVisibleChange=null, ...props}) => {

  const options = [];
  const interval = 15;
  const ap = ['AM', 'PM'];
  let tt = 0;
  
  for (let i = 0; tt < 24 * 60; i++) {
    let hh = Math.floor(tt / 60);
    let mm = (tt % 60);
    let label = ("" + ((hh === 12 || hh === 0) ? 12 : hh % 12)).slice(-2) + ':' + ("0" + mm).slice(-2) + ' ' + ap[Math.floor(hh / 12)];
    options[i] = {
      value: label,
      label: label
    }
    tt = tt + interval;
  }

  return (
    <Translation>{(t) => 
      <div className="form-group">
        <Form.Item
          name={name}
          label={label}
          rules={rules}
        >
          <Select
            placeholder={placeholder ? placeholder : ''}
            disabled={disabled}
            onChange={onChange}
            onDropdownVisibleChange={onDropdownVisibleChange}
          >
            {options.map(x =>
              <Select.Option
                key={x.value}
                value={x.value}
                disabled={disabledOptions.includes(x.value)}
              >
                {x.label}
              </Select.Option>)}
          </Select>
        </Form.Item>
      </div>
    }</Translation>
  );
}

export default TimeInput;
