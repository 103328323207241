import { normalize } from 'normalizr';

import api from '../../api';
import { schema } from '../../schema';
import { addEntities, removeEntity } from '../../actions';
import Format from '../../../lib/Format';
import Logger from '../../../lib/Logger';

// ACTION TYPES

export const RECOMMENDED_UTILITY_LIST_REQUEST =
  'RECOMMENDED_UTILITY_LIST_REQUEST';
export const RECOMMENDED_UTILITY_LIST_SUCCESS =
  'RECOMMENDED_UTILITY_LIST_SUCCESS';
export const RECOMMENDED_UTILITY_LIST_FAILURE =
  'RECOMMENDED_UTILITY_LIST_FAILURE';
export const RECOMMENDED_UTILITY_READ_REQUEST =
  'RECOMMENDED_UTILITY_READ_REQUEST';
export const RECOMMENDED_UTILITY_READ_SUCCESS =
  'RECOMMENDED_UTILITY_READ_SUCCESS';
export const RECOMMENDED_UTILITY_READ_FAILURE =
  'RECOMMENDED_UTILITY_READ_FAILURE';
export const RECOMMENDED_UTILITY_UPDATE_REQUEST =
  'RECOMMENDED_UTILITY_UPDATE_REQUEST';
export const RECOMMENDED_UTILITY_UPDATE_SUCCESS =
  'RECOMMENDED_UTILITY_UPDATE_SUCCESS';
export const RECOMMENDED_UTILITY_UPDATE_FAILURE =
  'RECOMMENDED_UTILITY_UPDATE_FAILURE';
export const RECOMMENDED_UTILITY_CREATE_REQUEST =
  'RECOMMENDED_UTILITY_CREATE_REQUEST';
export const RECOMMENDED_UTILITY_CREATE_SUCCESS =
  'RECOMMENDED_UTILITY_CREATE_SUCCESS';
export const RECOMMENDED_UTILITY_CREATE_FAILURE =
  'RECOMMENDED_UTILITY_CREATE_FAILURE';
export const RECOMMENDED_UTILITY_DELETE_REQUEST =
  'RECOMMENDED_UTILITY_DELETE_REQUEST';
export const RECOMMENDED_UTILITY_DELETE_SUCCESS =
  'RECOMMENDED_UTILITY_DELETE_SUCCESS';
export const RECOMMENDED_UTILITY_DELETE_FAILURE =
  'RECOMMENDED_UTILITY_DELETE_FAILURE';
export const RECOMMENDED_UTILITY_FORM_DESTROY =
  'RECOMMENDED_UTILITY_FORM_DESTROY';
export const RECOMMENDED_UTILITY_CSV_DOWNLOAD_REQUEST =
  'RECOMMENDED_UTILITY_CSV_DOWNLOAD_REQUEST';
export const RECOMMENDED_UTILITY_CSV_DOWNLOAD_SUCCESS =
  'RECOMMENDED_UTILITY_CSV_DOWNLOAD_SUCCESS';
export const RECOMMENDED_UTILITY_CSV_DOWNLOAD_FAILURE =
  'RECOMMENDED_UTILITY_CSV_DOWNLOAD_FAILURE';

// ACTION CREATORS

export function recommendedUtilityListRequest(page, limit, order, filter) {
  Logger.log(
    'debug',
    `[recommendedUtilities.actions] recommendedUtilityListRequest(${page}, ${limit}, ${order}, %j)`,
    filter
  );
  return {
    type: RECOMMENDED_UTILITY_LIST_REQUEST,
    page: page,
    limit: limit,
    order: order,
    filter: filter,
  };
}

export function recommendedUtilityListSuccess(data) {
  Logger.log(
    'debug',
    `[recommendedUtilities.actions] recommendedUtilityListSuccess(%j)`,
    data
  );
  return {
    type: RECOMMENDED_UTILITY_LIST_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now(),
  };
}

export function recommendedUtilityListFailure(error) {
  Logger.log(
    'debug',
    `[recommendedUtilities.actions] recommendedUtilityListFailure(%j)`,
    error
  );
  return {
    type: RECOMMENDED_UTILITY_LIST_FAILURE,
    error: error,
  };
}

export function recommendedUtilityReadRequest(id) {
  Logger.log(
    'debug',
    `[recommendedUtilities.actions] recommendedUtilityReadRequest(${id})`
  );
  return {
    type: RECOMMENDED_UTILITY_READ_REQUEST,
    id: id,
  };
}

export function recommendedUtilityReadSuccess(data) {
  Logger.log(
    'debug',
    `[recommendedUtilities.actions] recommendedUtilityReadSuccess(%j)`,
    data
  );
  return {
    type: RECOMMENDED_UTILITY_READ_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function recommendedUtilityReadFailure(error) {
  Logger.log(
    'debug',
    `[recommendedUtilities.actions] recommendedUtilityReadFailure(%j)`,
    error
  );
  return {
    type: RECOMMENDED_UTILITY_READ_FAILURE,
    error: error,
  };
}

export function recommendedUtilityUpdateRequest(id, data) {
  Logger.log(
    'debug',
    `[recommendedUtilities.actions] recommendedUtilityUpdateRequest(${id}, %j)`,
    data
  );
  return {
    type: RECOMMENDED_UTILITY_UPDATE_REQUEST,
  };
}

export function recommendedUtilityUpdateSuccess(data) {
  Logger.log(
    'debug',
    `[recommendedUtilities.actions] recommendedUtilityUpdateSuccess(%j)`,
    data
  );
  return {
    type: RECOMMENDED_UTILITY_UPDATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function recommendedUtilityUpdateFailure(error) {
  Logger.log(
    'debug',
    `[recommendedUtilities.actions] recommendedUtilityUpdateFailure(%j)`,
    error
  );
  return {
    type: RECOMMENDED_UTILITY_UPDATE_FAILURE,
    error: error,
  };
}

export function recommendedUtilityCreateRequest(data) {
  Logger.log(
    'debug',
    `[recommendedUtilities.actions] recommendedUtilityCreateRequest(%j)`,
    data
  );
  return {
    type: RECOMMENDED_UTILITY_CREATE_REQUEST,
  };
}

export function recommendedUtilityCreateSuccess(data) {
  Logger.log(
    'debug',
    `[recommendedUtilities.actions] recommendedUtilityCreateSuccess(%j)`,
    data
  );
  return {
    type: RECOMMENDED_UTILITY_CREATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function recommendedUtilityCreateFailure(error) {
  Logger.log(
    'debug',
    `[recommendedUtilities.actions] recommendedUtilityCreateFailure(%j)`,
    error
  );
  return {
    type: RECOMMENDED_UTILITY_CREATE_FAILURE,
    error: error,
  };
}

export function recommendedUtilityDeleteRequest(id) {
  Logger.log(
    'debug',
    `[recommendedUtilities.actions] recommendedUtilityDeleteRequest(${id})`
  );
  return {
    type: RECOMMENDED_UTILITY_DELETE_REQUEST,
    id: id,
  };
}

export function recommendedUtilityDeleteSuccess(id) {
  Logger.log(
    'debug',
    `[recommendedUtilities.actions] recommendedUtilityDeleteSuccess(${id})`
  );
  return {
    type: RECOMMENDED_UTILITY_DELETE_SUCCESS,
    id: id,
  };
}

export function recommendedUtilityDeleteFailure(error) {
  Logger.log(
    'debug',
    `[recommendedUtilities.actions] recommendedUtilityDeleteFailure(%j)`,
    error
  );
  return {
    type: RECOMMENDED_UTILITY_DELETE_FAILURE,
    error: error,
  };
}

export function recommendedUtilitiesFormDestroy(formState = null) {
  Logger.log(
    'debug',
    `[recommendedUtilities.actions] recommendedUtilitiesFormDestroy(%j)`,
    formState
  );
  return {
    type: RECOMMENDED_UTILITY_FORM_DESTROY,
    form: formState,
  };
}

export function recommendedUtilityCSVDownloadRequest(order, filter) {
  Logger.log(
    'debug',
    `[state.recommendedUtilities.actions] recommendedUtilityCSVDownloadRequest()`
  );
  return {
    type: RECOMMENDED_UTILITY_CSV_DOWNLOAD_REQUEST,
    order: order,
    filter: filter,
  };
}

export function recommendedUtilityCSVDownloadSuccess() {
  Logger.log(
    'debug',
    `[state.recommendedUtilities.actions] recommendedUtilityCSVDownloadSuccess()`
  );
  return {
    type: RECOMMENDED_UTILITY_CSV_DOWNLOAD_SUCCESS,
  };
}

export function recommendedUtilityCSVDownloadFailure() {
  Logger.log(
    'debug',
    `[state.recommendedUtilities.actions] recommendedUtilityCSVDownloadFailure()`
  );
  return {
    type: RECOMMENDED_UTILITY_CSV_DOWNLOAD_FAILURE,
  };
}

// API THUNK ACTION CREATORS

export function loadRecommendedUtilities(
  page = 1,
  limit = 10,
  order = null,
  filter = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[recommendedUtilities.actions] loadRecommendedUtilities(${page}, ${limit}, ${order}, %j, ###)`,
    filter
  );

  return async function (dispatch) {
    dispatch(recommendedUtilityListRequest(page, limit, order, filter));

    // call API
    const response = await api.getRecommendedUtilities(
      page,
      limit,
      order,
      filter
    );
    let success = false;

    // get recommended utilities list success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API recommended utilities list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );

      const normalizedEntities = normalize(
        response.getIn(['data', 'recommended_utilities']),
        [schema.recommendedUtility]
      );
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result,
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(recommendedUtilityListSuccess(data));
      success = true;
    } else if (1 === page && 204 === response.get('status')) {
      Logger.log(
        'info',
        `Get API recommended utilities success [empty]. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: [],
      };
      dispatch(recommendedUtilityListSuccess(data));
      success = true;

      // get recommended utilities list failure
    } else {
      Logger.log(
        'info',
        `Get API recommended utilities list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      dispatch(
        recommendedUtilityListFailure(response.getIn(['data', 'error']))
      );
    }

    // callback function
    cb(success);
  };
}

export function loadRecommendedUtility(id, cb = function () {}) {
  Logger.log(
    'debug',
    `[recommendedUtilities.actions] loadRecommendedUtility(${id}, ###)`
  );

  return async function (dispatch) {
    dispatch(recommendedUtilityReadRequest(id));

    // call API
    const response = await api.getRecommendedUtility(id);
    let success = false;

    // get recommended utility success
    if (200 === response.get('status')) {
      Logger.log('info', `Get API recommended utility success. ID: ${id}.`);

      const normalizedEntities = normalize(
        [response.getIn(['data', 'recommended_utility'])],
        [schema.recommendedUtility]
      );
      const data = {
        id: response.getIn(['data', 'recommended_utility', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(recommendedUtilityReadSuccess(data));
      success = true;

      // get recommended utility failure
    } else {
      Logger.log('info', `Get API recommended utility failure. ID: ${id}.`);
      dispatch(
        recommendedUtilityReadFailure(response.getIn(['data', 'error']))
      );
    }

    // callback function
    cb(success);
  };
}

export function updateRecommendedUtility(id, data, cb = function () {}) {
  Logger.log(
    'debug',
    `[recommendedUtilities.actions] updateRecommendedUtility(${id}, %j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(recommendedUtilityUpdateRequest(id, data));

    // call API
    const response = await api.putRecommendedUtility(id, data);
    let success = false;

    // put recommended utility success
    if (200 === response.get('status')) {
      Logger.log('info', `PUT API recommended utility success. User: ${id}.`);

      const normalizedEntities = normalize(
        [response.getIn(['data', 'recommended_utility'])],
        [schema.recommendedUtility]
      );
      const data = {
        id: response.getIn(['data', 'recommended_utility', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(recommendedUtilityUpdateSuccess(data));
      success = true;

      // get recommended utility failure
    } else {
      Logger.log('info', `PUT API recommended utility failure. ID: ${id}.`);
      dispatch(
        recommendedUtilityUpdateFailure(response.getIn(['data', 'error']))
      );
    }

    // callback function
    cb(success);
  };
}

export function createRecommendedUtility(data, cb = function () {}) {
  Logger.log(
    'debug',
    `[recommendedUtilities.actions] createRecommendedUtility(%j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(recommendedUtilityCreateRequest(data));

    // call API
    const response = await api.postRecommendedUtilities(data);
    let success = false;

    // post recommended utilities success
    if (201 === response.get('status')) {
      Logger.log(
        'info',
        `POST API recommended utilities success. Post: ${response.getIn([
          'data',
          'recommended_utility',
          'id',
        ])}.`
      );

      const normalizedEntities = normalize(
        [response.getIn(['data', 'recommended_utility'])],
        [schema.recommendedUtility]
      );
      const data = {
        id: response.getIn(['data', 'recommended_utility', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(recommendedUtilityCreateSuccess(data));
      success = true;

      // get recommended utilities failure
    } else {
      Logger.log('info', `POST API recommended utilities failure.`);
      dispatch(
        recommendedUtilityCreateFailure(response.getIn(['data', 'error']))
      );
    }

    // callback function
    cb(success);
  };
}

export function deleteRecommendedUtility(id, cb = function () {}) {
  Logger.log(
    'debug',
    `[recommendedUtilities.actions] deleteRecommendedUtility(${id}, ###)`
  );

  return async function (dispatch) {
    dispatch(recommendedUtilityDeleteRequest(id));

    // call API
    const response = await api.deleteRecommendedUtility(id);
    let success = false;

    // delete recommended utility success
    if (204 === response.get('status')) {
      Logger.log('info', `DELETE API recommended utility success. ID: ${id}.`);

      dispatch(removeEntity({ entityType: 'recommendedUtilities', id: id }));
      dispatch(recommendedUtilityDeleteSuccess(id));
      success = true;

      // get recommended utility failure
    } else {
      Logger.log('info', `DELETE API recommended utility failure. ID: ${id}.`);
      dispatch(
        recommendedUtilityDeleteFailure(response.getIn(['data', 'error']))
      );
    }

    // callback function
    cb(success);
  };
}

export function downloadRecommendedUtilitiesCSV(
  order = null,
  filter = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.recommendedUtilities.actions] downloadRecommendedUtilitiesCSV(${order}, ${filter}, ###)`
  );

  return async function (dispatch) {
    dispatch(recommendedUtilityCSVDownloadRequest(order, filter));

    // call API
    const response = await api.getRecommendedUtilitiesCSV(order, filter);
    let success = false;
    // get recommended utilities CSV success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API recommended utilities CSV success. Order: ${order}`
      );
      const now = new Date();

      // trigger browser download
      const url = window.URL.createObjectURL(new Blob([response.get('data')]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `cv-care-guide-documents-${Format.date(now, 'YYYY-MM-DDTHHmm')}.csv`
      );
      document.body.appendChild(link);
      link.click();

      dispatch(recommendedUtilityCSVDownloadSuccess());
      success = true;
      // get recommended utilities CSV failure
    } else {
      Logger.log('info', `Get API recommended utilities CSV failure.`);
      dispatch(recommendedUtilityCSVDownloadFailure());
    }

    // callback function
    cb(success);
  };
}

Logger.log('silly', `recommendedUtilities.actions loaded.`);
