import {getI18n} from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import CountryScreen from './country/containers/CountryScreenContainer';
import CountryAddScreen from './country/containers/CountryAddScreenContainer';
import CountryEditScreen from './country/containers/CountryEditScreenContainer';

import RegionScreen from './region/containers/RegionScreenContainer';
import RegionAddScreen from './region/containers/RegionAddScreenContainer';
import RegionEditScreen from './region/containers/RegionEditScreenContainer';

import SubRegionScreen from './subregion/containers/SubRegionScreenContainer';
import SubRegionAddScreen from './subregion/containers/SubRegionAddScreenContainer';
import SubRegionEditScreen from './subregion/containers/SubRegionEditScreenContainer';

import CityScreen from './city/containers/CityScreenContainer';
import CityAddScreen from './city/containers/CityAddScreenContainer';
import CityEditScreen from './city/containers/CityEditScreenContainer';

import PostalCodeScreen from './postalCode/containers/PostalCodeScreenContainer';
import PostalCodeAddScreen from './postalCode/containers/PostalCodeAddScreenContainer';
import PostalCodeEditScreen from './postalCode/containers/PostalCodeEditScreenContainer';

const register = () => {
  Logger.log('debug', `locations.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'locations', 'routes'])) {
    Events.dispatch('ADD_MAIN_ROUTES', 
      {
        'CountryAddScreen': ['PrivateRoute', '/countries/add', true, CountryAddScreen, getI18n().t('country_route_add')],
        'CountryEditScreen': ['PrivateRoute', '/countries/edit/:id([\\w\\-]+)', true, CountryEditScreen, getI18n().t('country_route_edit')],
        'CountryScreen': ['PrivateRoute', '/countries/:page(\\d+)?', true, CountryScreen, getI18n().t('locations_country_route_list')],
        'RegionAddScreen': ['PrivateRoute', '/regions/add', true, RegionAddScreen, getI18n().t('region_route_add')],
        'RegionEditScreen': ['PrivateRoute', '/regions/edit/:id([\\w\\-]+)', true, RegionEditScreen, getI18n().t('region_route_edit')],
        'RegionScreen': ['PrivateRoute', '/regions/:page(\\d+)?', true, RegionScreen, getI18n().t('locations_region_route_list')],
        'SubRegionAddScreen': ['PrivateRoute', '/subregions/add', true, SubRegionAddScreen, getI18n().t('subregion_route_add')],
        'SubRegionEditScreen': ['PrivateRoute', '/subregions/edit/:id([\\w\\-]+)', true, SubRegionEditScreen, getI18n().t('subregion_route_edit')],
        'SubRegionScreen': ['PrivateRoute', '/subregions/:page(\\d+)?', true, SubRegionScreen, getI18n().t('locations_subregion_route_list')],
        'CityAddScreen': ['PrivateRoute', '/cities/add', true, CityAddScreen, getI18n().t('city_route_add')],
        'CityEditScreen': ['PrivateRoute', '/cities/edit/:id([\\w\\-]+)', true, CityEditScreen, getI18n().t('city_route_edit')],
        'CityScreen': ['PrivateRoute', '/cities/:page(\\d+)?', true, CityScreen, getI18n().t('locations_city_route_list')],
        'PostalCodeAddScreen': ['PrivateRoute', '/postal_codes/add', true, PostalCodeAddScreen, getI18n().t('postal_code_route_add')],
        'PostalCodeEditScreen': ['PrivateRoute', '/postal_codes/edit/:id([\\w\\-]+)', true, PostalCodeEditScreen, getI18n().t('postal_code_route_edit')],
        'PostalCodeScreen': ['PrivateRoute', '/postal_codes/:page(\\d+)?', true, PostalCodeScreen, getI18n().t('locations_postal_code_route_list')],
      }
    );
  }
}

export default register;

Logger.log('silly', `locations.register() loaded.`);
