import { connect } from 'react-redux';
import { downloadCareGuideInvitationssCSV } from '../../../../../state/modules/careGuideInvitations/actions';

import CareGuideInvitationsScreen from '../components/CareGuideInvitationsScreen';

const mapStateToProps = (state) => {
  return {
    total: state.careGuideInvitations.get('total'),
    isLoading: state.careGuideInvitations.get('isLoading'),
    isDownloading: state.careGuideInvitations.get('isCSVDownloading'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    download: (order, filter, cb) => {
      dispatch(downloadCareGuideInvitationssCSV(order, filter, cb));
    },
  };
};

const CareGuideInvitationsScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CareGuideInvitationsScreen);

export default CareGuideInvitationsScreenContainer;
