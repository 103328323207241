import {connect} from 'react-redux';

import EmailEditScreen from '../components/EmailEditScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.emails.get('isLoading'),
    success: state.emails.getIn(['form', 'success']),
  }
}

const EmailEditScreenContainer = connect(
  mapStateToProps
)(EmailEditScreen);

export default EmailEditScreenContainer;
