import {connect} from 'react-redux';
import {denormalize} from 'normalizr';

import {schema} from '../../../../../state/schema';
import {loadCalendarEventExceptions, deleteCalendarEventException} from '../../../../../state/modules/calendarEvents/actions';
import {sendMessage} from '../../../../../state/actions';
import CalendarEventExceptionsList from '../components/CalendarEventExceptionsList';

const mapStateToProps = (state, ownProps) => {

  const page = ownProps.page;
  const limit = ownProps.limit;
  const order = ownProps.order;
  const result = state.calendarEvents.getIn(['exceptionPages', order, limit, page]);

  return {
    list: result 
      ? result.map(x => {
          return {
            key: x,
            ...denormalize(
              state.entities.getIn(['calendarEventExceptions', x]),
              schema.calendarEventException,
              state.entities.toJS()
            )
          };
        }).toArray().map(x => {
          // flatten some nested objects to make table easier to work with
          return {
            partnership_name: x?.event?.partnership?.name ?? '',
            partnership_plan: x?.event?.partnership?.plan?.name ?? '',
            event_id: x.event ? x.event.id : '',
            ...x
          };
        })
      : [],
    isLoading: state.calendarEvents.get('areExceptionsLoading'),
    total: state.calendarEvents.get('exceptionsTotal'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (page, limit, order, filter, cb) => {
      dispatch(loadCalendarEventExceptions(page, limit, order, filter, cb));
    },
    remove: (id, cb) => {
      dispatch(deleteCalendarEventException(id, cb));
    },
    sendMessage: (level, title, body, expires) => {
      dispatch(sendMessage(level, title, body, expires));
    },
  }
}

const CalendarEventExceptionsListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CalendarEventExceptionsList);

export default CalendarEventExceptionsListContainer;
