import {getI18n} from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import MediaScreen from './containers/MediaScreenContainer';
import MediumAddScreen from './containers/MediumAddScreenContainer';
import MediumEditScreen from './containers/MediumEditScreenContainer';

const register = () => {
  Logger.log('debug', `media.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'media', 'routes'])) {
    Events.dispatch('ADD_MAIN_ROUTES', 
      {
        'MediumAddScreen': ['PrivateRoute', '/media/add', true, MediumAddScreen, getI18n().t('media_route_add')],
        'MediumEditScreen': ['PrivateRoute', '/media/edit/:id([\\w\\-]+)', true, MediumEditScreen, getI18n().t('media_route_edit')],
        'MediaScreen': ['PrivateRoute', '/media/:page(\\d+)?', true, MediaScreen, getI18n().t('media_route_list')],
      }
    );
  }
}

export default register;

Logger.log('silly', `media.register() loaded.`);
