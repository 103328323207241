import React from 'react';
import {Translation, getI18n} from 'react-i18next';
import {Button, Form, Input} from 'antd';

import Logger from '../../../../../lib/Logger';
import message from '../../../elements/lib/MessageWrapper';

const TwoFactorAuthForm = props => {

  const [form] = Form.useForm();

  // submit handler
  const submit = async (values) => {
    Logger.log('debug', `TwoFactorAuthForm.submit()`);

    const payload = {
      token: props.otpToken,
      otp: values.otp,
    };

    // create session
    props.createSession(payload, (success, requires2FA) => {
      if (success) {
          // this.setState({redirectToReferrer: true});  // disabled: LoginScreen handes any authenticated redirect
      } else {
        message.error(getI18n().t('session_login_form_message_failure'));
      }
    });
  }

  // form submit handler
  const handleFinish = async (values) => {
    Logger.log('debug', `TwoFactorAuthForm.handleFinish(###)`);
    if (!props.isSubmitting) {
      await submit(values);
    }
  }

  // form error handler
  const handleFinishFailed = ({values, errorFields, outOfDate}) => {
    Logger.log('debug', `TwoFactorAuthForm.handleFinishFailed(###)`);
    message.error(getI18n().t('session_login_form_message_failure'));
  }

  return(
      <Translation>{(t) => 
        <div>
          <Form
            hideRequiredMark
            name="login_2fa_form"
            onFinish={handleFinish}
            onFinishFailed={handleFinishFailed}
            form={form}
            layout="vertical"
          >

            <Form.Item
              name="otp"
              label={null}
              rules={[
                { required: true, message: t('feedback_validation_required') }
              ]}
            >
              <Input autoFocus />
            </Form.Item>

            <div className="form-actions">
              <Form.Item>
                <Button
                  type="primary"
                  className="login-button"
                  htmlType="submit"
                  loading={props.isSubmitting}
                >
                  {t('session_login_2fa_form_button_submit')}
                </Button>
              </Form.Item>
            </div>

          </Form>
        </div>
      }</Translation>
  );
}

export default TwoFactorAuthForm;

Logger.log('silly', `TwoFactorAuthForm loaded.`);
