import {getI18n} from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import RegistrationCodesScreen from './containers/RegistrationCodesScreenContainer';
import RegistrationCodeAddScreen from './containers/RegistrationCodeAddScreenContainer';
import RegistrationCodeEditScreen from './containers/RegistrationCodeEditScreenContainer';

const register = () => {
  Logger.log('debug', `registrationCodes.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'registrationCodes', 'routes'])) {
    Events.dispatch('ADD_MAIN_ROUTES', 
      {
        'RegistrationCodeAddScreen': ['PrivateRoute', '/registration-codes/add', true, RegistrationCodeAddScreen, getI18n().t('registration_code_route_add')],
        'RegistrationCodeEditScreen': ['PrivateRoute', '/registration-codes/edit/:id([\\w\\-]+)', true, RegistrationCodeEditScreen, getI18n().t('registration_code_route_edit')],
        'RegistrationCodesScreen': ['PrivateRoute', '/registration-codes/:page(\\d+)?', true, RegistrationCodesScreen, getI18n().t('registration_codes_route_list')],
      }
    );
  }
}

export default register;

Logger.log('silly', `registrationCodes.register() loaded.`);
