import {connect} from 'react-redux';
import {List} from 'immutable';

import {
  loadCareGuideTemplate,
  updateCareGuideTemplate,
  createCareGuideTemplate,
  deleteCareGuideTemplate,
  careGuideTemplateFormDestroy
} from '../../../../../state/modules/careGuideTemplates/actions';
import {loadCareGuideSections} from '../../../../../state/modules/careGuideSections/actions';
import {sendMessage} from '../../../../../state/actions';
import CareGuideTemplateForm from '../components/CareGuideTemplateForm';

const inputs = List([
  'name',
  'summary',
  'sections',
  'status'
]);

const mapStateToProps = (state, ownProps) => {

  const data = {}, errors = {};
  for (const val of inputs.values()) {
    data[val] = state.entities.getIn(['careGuideTemplates', ownProps.id, val], '');
    if (state.careGuideTemplates.getIn(['form', 'errors', val])) {
      errors[val] = state.careGuideTemplates.getIn(['form', 'errors', val]);
    }
  }

  // change select number values to strings to work with ant design select inputs
  Object.keys(data).forEach(x => {
    if (['status'].includes(x)) {
      data[x] = data[x].toString();
    }
  });

  // hydrate sections for dynamic inputs
  if ('sections' in data && Array.isArray(data['sections'])) {
    data['sections'] = data['sections'].map(x => state.entities.getIn(['careGuideTemplateSections', x]));

    // side load section name
    data['sections'] = data['sections'].map(x => {
      return {
        section_id: x.section,
        name: state.entities.getIn(['careGuideSections', x.section, 'name']),
        ...x,
      }  
    });
  }

  return {
    isLoading: state.careGuideTemplates.get('isLoading'),
    isSubmitting: state.careGuideTemplates.getIn(['form', 'isSubmitting']),
    success: state.careGuideTemplates.getIn(['form', 'success']),
    created_id: state.careGuideTemplates.getIn(['form', 'created_id']),
    data: data,
    errors: errors,
    createdAt: state.entities.getIn(['careGuideTemplates', ownProps.id, 'created_at'], null),
    updatedAt: state.entities.getIn(['careGuideTemplates', ownProps.id, 'updated_at'], null),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (id, cb) => {
      dispatch(loadCareGuideTemplate(id, cb));
    },
    update: (id, data, cb) => {
      dispatch(updateCareGuideTemplate(id, data, cb));
    },
    create: (data, cb) => {
      dispatch(createCareGuideTemplate(data, cb));
    },
    delete: (id, cb) => {
      dispatch(deleteCareGuideTemplate(id, cb))
    },
    destroyForm: (formState) => {
      dispatch(careGuideTemplateFormDestroy(formState));
    },
    loadSections: (page, limit, order, filter, cb) => {
      dispatch(loadCareGuideSections(page, limit, order, filter, cb));
    },
    sendMessage: (level, title, body, expires) => {
      dispatch(sendMessage(level, title, body, expires));
    },
  }
}

const CareGuideTemplateFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CareGuideTemplateForm);

export default CareGuideTemplateFormContainer;
