import { connect } from 'react-redux';

import { downloadAlertsCSV } from '../../../../../state/modules/alerts/actions';
import AlertsCSVButton from '../components/AlertsCSVButton';

const mapStateToProps = (state) => {
  return {
    isDownloading: state.alerts.get('isCSVDownloading'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    download: (order, filter, cb) => {
      dispatch(downloadAlertsCSV(order, filter, cb));
    },
  };
};

const AlertsCSVButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AlertsCSVButton);

export default AlertsCSVButtonContainer;
