import {normalize} from 'normalizr';

import api from '../../api';
import {schema} from '../../schema';
import {addEntities, removeEntity} from '../../actions';
import Logger from '../../../lib/Logger';

export const SUBREGION_LIST_REQUEST = 'SUBREGION_LIST_REQUEST';
export const SUBREGION_LIST_SUCCESS = 'SUBREGION_LIST_SUCCESS';
export const SUBREGION_LIST_FAILURE = 'SUBREGION_LIST_FAILURE';
export const SUBREGION_READ_REQUEST = 'SUBREGION_READ_REQUEST';
export const SUBREGION_READ_SUCCESS = 'SUBREGION_READ_SUCCESS';
export const SUBREGION_READ_FAILURE = 'SUBREGION_READ_FAILURE';
export const SUBREGION_CREATE_FAILURE = 'SUBREGION_CREATE_FAILURE';
export const SUBREGION_CREATE_REQUEST = 'SUBREGION_CREATE_REQUEST';
export const SUBREGION_CREATE_SUCCESS = 'SUBREGION_CREATE_SUCCESS';
export const SUBREGION_DELETE_REQUEST = 'SUBREGION_DELETE_REQUEST';
export const SUBREGION_DELETE_SUCCESS = 'SUBREGION_DELETE_SUCCESS';
export const SUBREGION_DELETE_FAILURE = 'SUBREGION_DELETE_FAILURE';
export const SUBREGION_UPDATE_REQUEST = 'SUBREGION_UPDATE_REQUEST';
export const SUBREGION_UPDATE_SUCCESS = 'SUBREGION_UPDATE_SUCCESS';
export const SUBREGION_UPDATE_FAILURE = 'SUBREGION_UPDATE_FAILURE';
export const SUBREGION_FORM_DESTROY = 'SUBREGION_FORM_DESTROY';

export function subregionListRequest(page, limit, order, filter) {
    Logger.log('debug', `[location.actions] subregionListRequest(${page}, ${limit})`);
    return {
      type: SUBREGION_LIST_REQUEST,
      page: page,
      limit: limit,
      order: order,
      filter: filter
    }
  }
  
  export function subregionListSuccess(data) {
    Logger.log('debug', `[location.actions] subregionListSuccess(%j)`, data);
    return {
      type: SUBREGION_LIST_SUCCESS,
      page: data.page,
      limit: data.limit,
      order: data.order,
      result: data.result,
      total: data.total,
      receivedAt: Date.now()
    }
  }
  
  export function subregionListFailure(error) {
    Logger.log('debug', `[location.actions] subregionListFailure(%j)`, error);
    return {
      type: SUBREGION_LIST_FAILURE,
      error: error
    }
  }
  
  export function subregionCreateRequest(data) {
    Logger.log('debug', `[subregion.actions] subregionCreateRequest(%j)`, data);
    return {
      type: SUBREGION_CREATE_REQUEST,
    }
  }
  
  export function subregionCreateSuccess(data) {
    Logger.log('debug', `[subregion.actions] subregionCreateSuccess(%j)`, data);
    return {
      type: SUBREGION_CREATE_SUCCESS,
      id: data.id,
      receivedAt: Date.now()
    }
  }
  
  export function subregionCreateFailure(error) {
    Logger.log('debug', `[subregion.actions] subregionCreateFailure(%j)`, error);
    return {
      type: SUBREGION_CREATE_FAILURE,
      error: error
    }
  }
  
  export function subregionReadRequest(id) {
    Logger.log('debug', `[subregion.actions] subregionReadRequest(${id})`);
    return {
      type: SUBREGION_READ_REQUEST,
      id: id
    }
  }
  
  export function subregionReadSuccess(data) {
    Logger.log('debug', `[subregion.actions] subregionReadSuccess(%j)`, data);
    return {
      type: SUBREGION_READ_SUCCESS,
      id: data.id,
      receivedAt: Date.now()
    }
  }
  
  export function subregionReadFailure(error) {
    Logger.log('debug', `[subregion.actions] subregionReadFailure(%j)`, error);
    return {
      type: SUBREGION_READ_FAILURE,
      error: error
    }
  }
  
  export function subregionUpdateRequest(id, data) {
    Logger.log('debug', `[subregion.actions] subregionUpdateRequest(${id}, %j)`, data);
    return {
      type: SUBREGION_UPDATE_REQUEST,
    }
  }
  
  export function subregionUpdateSuccess(data) {
    Logger.log('debug', `[subregion.actions] subregionUpdateSuccess(%j)`, data);
    return {
      type: SUBREGION_UPDATE_SUCCESS,
      id: data.id,
      receivedAt: Date.now()
    }
  }
  
  export function subregionUpdateFailure(error) {
    Logger.log('debug', `[subregion.actions] subregionUpdateFailure(%j)`, error);
    return {
      type: SUBREGION_UPDATE_FAILURE,
      error: error
    }
  }
  
  export function  subregionDeleteRequest(id) {
    Logger.log('debug', `[ subregion.actions] subregionDeleteRequest(${id})`);
    return {
      type: SUBREGION_DELETE_REQUEST,
      id: id
    }
  }
  
  export function subregionDeleteSuccess(id) {
    Logger.log('debug', `[subregion.actions] subregionDeleteSuccess(${id})`);
    return {
      type: SUBREGION_DELETE_SUCCESS,
      id: id,
    }
  }
  
  export function subregionDeleteFailure(error) {
    Logger.log('debug', `[subregion.actions] subregionDeleteFailure(%j)`, error);
    return {
      type: SUBREGION_DELETE_FAILURE,
      error: error
    }
  }
  
  export function subregionFormDestroy(formState=null) {
    Logger.log('debug', `[subregion.actions] subregionFormDestroy(%j)`, formState);
    return {
      type: SUBREGION_FORM_DESTROY,
      form: formState
    }
  }

  // API THUNK ACTION CREATORS

export function loadSubRegions(page=1, limit=250,order=null, filter=null, cb=function(){}) {
    Logger.log('debug', `[location.actions] loadSubRegions(${page}, ${limit}, ###)`);
  
    return async function(dispatch) {
      dispatch(subregionListRequest(page, limit, order, filter));
  
      // call API
      const response = await api.getSubRegions(page, limit, order, filter);
  
      // get subregions list success
      if (200 === response.get('status')) {
  
        Logger.log('info', `Get API subregions list success. Page: ${page}, Limit: ${limit}.  Order: ${order}.`);
  
        const normalizedSubRegions = normalize(response.getIn(['data', 'subregions']), [schema.subregion]);
        const subregionSuccessData = {
          page: response.getIn(['data', 'page']),
          limit: response.getIn(['data', 'limit']),
          order: order,
          total: response.getIn(['data', 'total']),
          result: normalizedSubRegions.result
        };

        dispatch(addEntities(normalizedSubRegions));
        dispatch(subregionListSuccess(subregionSuccessData));
        
      // get subregions list failure
      } else {
        Logger.log('info', `Get API subregions list failure. Page: ${page}, Limit: ${limit}.`);
        dispatch(subregionListFailure(response.getIn(['data', 'error'])));
      }
  
      // callback function
      cb();
    }
  }
  
  export function loadSubRegion(id, cb=function(){}) {
    Logger.log('debug', `[subregion.actions] loadSubRegion(${id}, ###)`);
  
    return async function(dispatch) {
      dispatch(subregionReadRequest(id));
  
      // call API
      const response = await api.getSubRegion(id);
      let success = false;
  
      // get subregion success
      if (200 === response.get('status')) {
  
        Logger.log('info', `Get API subregion success. ID: ${id}.`);
  
        const normalizedEntities = normalize([response.getIn(['data', 'subregion'])], [schema.subregion]);
        const data = {
          id: response.getIn(['data', 'subregion', 'id']),
        };
  
        dispatch(addEntities(normalizedEntities));
        dispatch(subregionReadSuccess(data));
        success = true;
        
      // get subregion failure
      } else {
        Logger.log('info', `Get API subregion failure. ID: ${id}.`);
        dispatch(subregionReadFailure(response.getIn(['data', 'error'])));
      }
  
      // callback function
      cb(success);
    }
  }
  
  export function createSubRegion(data, cb=function(){}) {
    Logger.log('debug', `[subregion.actions] createSubRegion(%j, ###)`, data);
  
    return async function(dispatch) {
      dispatch(subregionCreateRequest(data));
  
      // call API
      const response = await api.postSubRegion(data);
      let success = false;
  
      // post subregion success
      if (201 === response.get('status')) {
  
        Logger.log('info', `POST API subregion success. Post: ${response.getIn(['data', 'subregion', 'id'])}.`);
  
        const normalizedEntities = normalize([response.getIn(['data', 'subregion'])], [schema.subregion]);
        const data = {
          id: response.getIn(['data', 'subregion', 'id']),
        };
        dispatch(addEntities(normalizedEntities));
        dispatch(subregionCreateSuccess(data));
        success = true;
        
      // get subregions failure
      } else {
        Logger.log('info', `POST API subregion failure.`);
        dispatch(subregionCreateFailure(response.getIn(['data', 'error'])));
      }
  
      // callback function
      cb(success);
    }
  }
  
  export function updateSubRegion(id, data, cb=function(){}) {
    Logger.log('debug', `[subregion.actions] updateSubRegion(${id}, %j, ###)`, data);
  
    return async function(dispatch) {
      dispatch(subregionUpdateRequest(id, data));
  
      // call API
      const response = await api.putSubRegion(id, data);
      let success = false;
  
      // put subregion success
      if (200 === response.get('status')) {
  
        Logger.log('info', `PUT API subregion success. User: ${id}.`);
  
        const normalizedEntities = normalize([response.getIn(['data', 'subregion'])], [schema.subregion]);
        const data = {
          id: response.getIn(['data', 'subregion', 'id']),
        };
  
        dispatch(addEntities(normalizedEntities));
        dispatch(subregionUpdateSuccess(data));
        success = true;
        
      // get subregion failure
      } else {
        Logger.log('info', `PUT API subregion failure. ID: ${id}.`);
        dispatch(subregionUpdateFailure(response.getIn(['data', 'error'])));
      }
  
      // callback function
      cb(success);
    }
  }
  
  export function deleteSubRegion(id, cb=function(){}) {
    Logger.log('debug', `[locations.actions] deleteSubRegion(${id}, ###)`);
  
    return async function(dispatch) {
      dispatch(subregionDeleteRequest(id));
  
      // call API
      const response = await api.deletesubregion(id);
      let success = false;
  
      // delete subregion post success
      if (204 === response.get('status')) {
  
        Logger.log('info', `DELETE API subregion success. ID: ${id}.`);
  
        dispatch(removeEntity({entityType: 'subregion', id: id}));
        dispatch(subregionDeleteSuccess(id));
        success = true;
        
      // get subregion failure
      } else {
        Logger.log('info', `DELETE API subregion post failure. ID: ${id}.`);
        dispatch(subregionDeleteFailure(response.getIn(['data', 'error'])));
      }
  
      // callback function
      cb(success);
    }
  }