import { normalize } from 'normalizr';

import api from '../../api';
import { schema } from '../../schema';
import { addEntities, removeEntity } from '../../actions';
import Format from '../../../lib/Format';
import Logger from '../../../lib/Logger';

// ACTION TYPES

export const CARE_GUIDE_PARTNER_TAG_LIST_REQUEST =
  'CARE_GUIDE_PARTNER_TAG_LIST_REQUEST';
export const CARE_GUIDE_PARTNER_TAG_LIST_SUCCESS =
  'CARE_GUIDE_PARTNER_TAG_LIST_SUCCESS';
export const CARE_GUIDE_PARTNER_TAG_LIST_FAILURE =
  'CARE_GUIDE_PARTNER_TAG_LIST_FAILURE';
export const CARE_GUIDE_PARTNER_TAG_READ_REQUEST =
  'CARE_GUIDE_PARTNER_TAG_READ_REQUEST';
export const CARE_GUIDE_PARTNER_TAG_READ_SUCCESS =
  'CARE_GUIDE_PARTNER_TAG_READ_SUCCESS';
export const CARE_GUIDE_PARTNER_TAG_READ_FAILURE =
  'CARE_GUIDE_PARTNER_TAG_READ_FAILURE';
export const CARE_GUIDE_PARTNER_TAG_UPDATE_REQUEST =
  'CARE_GUIDE_PARTNER_TAG_UPDATE_REQUEST';
export const CARE_GUIDE_PARTNER_TAG_UPDATE_SUCCESS =
  'CARE_GUIDE_PARTNER_TAG_UPDATE_SUCCESS';
export const CARE_GUIDE_PARTNER_TAG_UPDATE_FAILURE =
  'CARE_GUIDE_PARTNER_TAG_UPDATE_FAILURE';
export const CARE_GUIDE_PARTNER_TAG_CREATE_REQUEST =
  'CARE_GUIDE_PARTNER_TAG_CREATE_REQUEST';
export const CARE_GUIDE_PARTNER_TAG_CREATE_SUCCESS =
  'CARE_GUIDE_PARTNER_TAG_CREATE_SUCCESS';
export const CARE_GUIDE_PARTNER_TAG_CREATE_FAILURE =
  'CARE_GUIDE_PARTNER_TAG_CREATE_FAILURE';
export const CARE_GUIDE_PARTNER_TAG_DELETE_REQUEST =
  'CARE_GUIDE_PARTNER_TAG_DELETE_REQUEST';
export const CARE_GUIDE_PARTNER_TAG_DELETE_SUCCESS =
  'CARE_GUIDE_PARTNER_TAG_DELETE_SUCCESS';
export const CARE_GUIDE_PARTNER_TAG_DELETE_FAILURE =
  'CARE_GUIDE_PARTNER_TAG_DELETE_FAILURE';
export const CARE_GUIDE_PARTNER_TAG_FORM_DESTROY =
  'CARE_GUIDE_PARTNER_TAG_FORM_DESTROY';
export const CARE_GUIDE_PARTNER_TAG_CSV_DOWNLOAD_REQUEST =
  'CARE_GUIDE_PARTNER_TAG_CSV_DOWNLOAD_REQUEST';
export const CARE_GUIDE_PARTNER_TAG_CSV_DOWNLOAD_SUCCESS =
  'CARE_GUIDE_PARTNER_TAG_CSV_DOWNLOAD_SUCCESS';
export const CARE_GUIDE_PARTNER_TAG_CSV_DOWNLOAD_FAILURE =
  'CARE_GUIDE_PARTNER_TAG_CSV_DOWNLOAD_FAILURE';

// ACTION CREATORS

export function careGuidePartnerTagListRequest(page, limit, order, filter) {
  Logger.log(
    'debug',
    `[careGuidePartnerTags.actions] careGuidePartnerTagListRequest(${page}, ${limit}, ${order}, %j)`,
    filter
  );
  return {
    type: CARE_GUIDE_PARTNER_TAG_LIST_REQUEST,
    page: page,
    limit: limit,
    order: order,
    filter: filter,
  };
}

export function careGuidePartnerTagListSuccess(data) {
  Logger.log(
    'debug',
    `[careGuidePartnerTags.actions] careGuidePartnerTagListSuccess(%j)`,
    data
  );
  return {
    type: CARE_GUIDE_PARTNER_TAG_LIST_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now(),
  };
}

export function careGuidePartnerTagListFailure(error) {
  Logger.log(
    'debug',
    `[careGuidePartnerTags.actions] careGuidePartnerTagListFailure(%j)`,
    error
  );
  return {
    type: CARE_GUIDE_PARTNER_TAG_LIST_FAILURE,
    error: error,
  };
}

export function careGuidePartnerTagReadRequest(id) {
  Logger.log(
    'debug',
    `[careGuidePartnerTags.actions] careGuidePartnerTagReadRequest(${id})`
  );
  return {
    type: CARE_GUIDE_PARTNER_TAG_READ_REQUEST,
    id: id,
  };
}

export function careGuidePartnerTagReadSuccess(data) {
  Logger.log(
    'debug',
    `[careGuidePartnerTags.actions] careGuidePartnerTagReadSuccess(%j)`,
    data
  );
  return {
    type: CARE_GUIDE_PARTNER_TAG_READ_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function careGuidePartnerTagReadFailure(error) {
  Logger.log(
    'debug',
    `[careGuidePartnerTags.actions] careGuidePartnerTagReadFailure(%j)`,
    error
  );
  return {
    type: CARE_GUIDE_PARTNER_TAG_READ_FAILURE,
    error: error,
  };
}

export function careGuidePartnerTagUpdateRequest(id, data) {
  Logger.log(
    'debug',
    `[careGuidePartnerTags.actions] careGuidePartnerTagUpdateRequest(${id}, %j)`,
    data
  );
  return {
    type: CARE_GUIDE_PARTNER_TAG_UPDATE_REQUEST,
  };
}

export function careGuidePartnerTagUpdateSuccess(data) {
  Logger.log(
    'debug',
    `[careGuidePartnerTags.actions] careGuidePartnerTagUpdateSuccess(%j)`,
    data
  );
  return {
    type: CARE_GUIDE_PARTNER_TAG_UPDATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function careGuidePartnerTagUpdateFailure(error) {
  Logger.log(
    'debug',
    `[careGuidePartnerTags.actions] careGuidePartnerTagUpdateFailure(%j)`,
    error
  );
  return {
    type: CARE_GUIDE_PARTNER_TAG_UPDATE_FAILURE,
    error: error,
  };
}

export function careGuidePartnerTagCreateRequest(data) {
  Logger.log(
    'debug',
    `[careGuidePartnerTags.actions] careGuidePartnerTagCreateRequest(%j)`,
    data
  );
  return {
    type: CARE_GUIDE_PARTNER_TAG_CREATE_REQUEST,
  };
}

export function careGuidePartnerTagCreateSuccess(data) {
  Logger.log(
    'debug',
    `[careGuidePartnerTags.actions] careGuidePartnerTagCreateSuccess(%j)`,
    data
  );
  return {
    type: CARE_GUIDE_PARTNER_TAG_CREATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function careGuidePartnerTagCreateFailure(error) {
  Logger.log(
    'debug',
    `[careGuidePartnerTags.actions] careGuidePartnerTagCreateFailure(%j)`,
    error
  );
  return {
    type: CARE_GUIDE_PARTNER_TAG_CREATE_FAILURE,
    error: error,
  };
}

export function careGuidePartnerTagDeleteRequest(id) {
  Logger.log(
    'debug',
    `[careGuidePartnerTags.actions] careGuidePartnerTagDeleteRequest(${id})`
  );
  return {
    type: CARE_GUIDE_PARTNER_TAG_DELETE_REQUEST,
    id: id,
  };
}

export function careGuidePartnerTagDeleteSuccess(id) {
  Logger.log(
    'debug',
    `[careGuidePartnerTags.actions] careGuidePartnerTagDeleteSuccess(${id})`
  );
  return {
    type: CARE_GUIDE_PARTNER_TAG_DELETE_SUCCESS,
    id: id,
  };
}

export function careGuidePartnerTagDeleteFailure(error) {
  Logger.log(
    'debug',
    `[careGuidePartnerTags.actions] careGuidePartnerTagDeleteFailure(%j)`,
    error
  );
  return {
    type: CARE_GUIDE_PARTNER_TAG_DELETE_FAILURE,
    error: error,
  };
}

export function careGuidePartnerTagFormDestroy(formState = null) {
  Logger.log(
    'debug',
    `[careGuidePartnerTags.actions] careGuidePartnerTagFormDestroy(%j)`,
    formState
  );
  return {
    type: CARE_GUIDE_PARTNER_TAG_FORM_DESTROY,
    form: formState,
  };
}

export function careGuidePartnerTagCSVDownloadRequest(order, filter) {
  Logger.log(
    'debug',
    `[state.careGuidePartnerTags.actions] careGuidePartnerTagCSVDownloadRequest()`
  );
  return {
    type: CARE_GUIDE_PARTNER_TAG_CSV_DOWNLOAD_REQUEST,
    order: order,
    filter: filter,
  };
}

export function careGuidePartnerTagCSVDownloadSuccess() {
  Logger.log(
    'debug',
    `[state.careGuidePartnerTags.actions] careGuidePartnerTagCSVDownloadSuccess()`
  );
  return {
    type: CARE_GUIDE_PARTNER_TAG_CSV_DOWNLOAD_SUCCESS,
  };
}

export function careGuidePartnerTagCSVDownloadFailure() {
  Logger.log(
    'debug',
    `[state.careGuidePartnerTags.actions] careGuidePartnerTagCSVDownloadFailure()`
  );
  return {
    type: CARE_GUIDE_PARTNER_TAG_CSV_DOWNLOAD_FAILURE,
  };
}

// API THUNK ACTION CREATORS

export function loadCareGuidePartnerTags(
  page = 1,
  limit = 10,
  order = null,
  filter = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[careGuidePartnerTags.actions] loadCareGuidePartnerTags(${page}, ${limit}, ${order}, %j, ###)`,
    filter
  );

  return async function (dispatch) {
    dispatch(careGuidePartnerTagListRequest(page, limit, order, filter));

    // call API
    const response = await api.getCareGuidePartnerTags(
      page,
      limit,
      order,
      filter
    );
    let success = false;

    // get care guide partner tags list success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API care guide partner tags list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );

      const normalizedEntities = normalize(
        response.getIn(['data', 'care_guide_partner_tags']),
        [schema.careGuidePartnerTag]
      );
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result,
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuidePartnerTagListSuccess(data));
      success = true;
    } else if (1 === page && 204 === response.get('status')) {
      Logger.log(
        'info',
        `Get API care guide partner tags success [empty]. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: [],
      };
      dispatch(careGuidePartnerTagListSuccess(data));
      success = true;

      // get care guide partner tags list failure
    } else {
      Logger.log(
        'info',
        `Get API care guide partner tags list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      dispatch(
        careGuidePartnerTagListFailure(response.getIn(['data', 'error']))
      );
    }

    // callback function
    cb(success);
  };
}

export function loadCareGuidePartnerTag(id, cb = function () {}) {
  Logger.log(
    'debug',
    `[careGuidePartnerTags.actions] loadCareGuidePartnerTag(${id}, ###)`
  );

  return async function (dispatch) {
    dispatch(careGuidePartnerTagReadRequest(id));

    // call API
    const response = await api.getCareGuidePartnerTag(id);
    let success = false;

    // get care guide partner tag success
    if (200 === response.get('status')) {
      Logger.log('info', `Get API care guide partner tag success. ID: ${id}.`);

      const normalizedEntities = normalize(
        [response.getIn(['data', 'care_guide_partner_tag'])],
        [schema.careGuidePartnerTag]
      );
      const data = {
        id: response.getIn(['data', 'care_guide_partner_tag', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuidePartnerTagReadSuccess(data));
      success = true;

      // get care guide partner tag failure
    } else {
      Logger.log('info', `Get API care guide partner tag failure. ID: ${id}.`);
      dispatch(
        careGuidePartnerTagReadFailure(response.getIn(['data', 'error']))
      );
    }

    // callback function
    cb(success);
  };
}

export function updateCareGuidePartnerTag(id, data, cb = function () {}) {
  Logger.log(
    'debug',
    `[careGuidePartnerTags.actions] updateCareGuidePartnerTag(${id}, %j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(careGuidePartnerTagUpdateRequest(id, data));

    // call API
    const response = await api.putCareGuidePartnerTag(id, data);
    let success = false;

    // put care guide partner tag success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `PUT API care guide partner tag success. User: ${id}.`
      );

      const normalizedEntities = normalize(
        [response.getIn(['data', 'care_guide_partner_tag'])],
        [schema.careGuidePartnerTag]
      );
      const data = {
        id: response.getIn(['data', 'care_guide_partner_tag', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuidePartnerTagUpdateSuccess(data));
      success = true;

      // get care guide partner tag failure
    } else {
      Logger.log('info', `PUT API care guide partner tag failure. ID: ${id}.`);
      dispatch(
        careGuidePartnerTagUpdateFailure(response.getIn(['data', 'error']))
      );
    }

    // callback function
    cb(success);
  };
}

export function createCareGuidePartnerTag(data, cb = function () {}) {
  Logger.log(
    'debug',
    `[careGuidePartnerTags.actions] createCareGuidePartnerTag(%j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(careGuidePartnerTagCreateRequest(data));

    // call API
    const response = await api.postCareGuidePartnerTags(data);
    let success = false;

    // post care guide partner tags success
    if (201 === response.get('status')) {
      Logger.log(
        'info',
        `POST API care guide partner tags success. Post: ${response.getIn([
          'data',
          'care_guide_partner_tag',
          'id',
        ])}.`
      );

      const normalizedEntities = normalize(
        [response.getIn(['data', 'care_guide_partner_tag'])],
        [schema.careGuidePartnerTag]
      );
      const data = {
        id: response.getIn(['data', 'care_guide_partner_tag', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuidePartnerTagCreateSuccess(data));
      success = true;

      // get care guide partner tags failure
    } else {
      Logger.log('info', `POST API care guide partner tags failure.`);
      dispatch(
        careGuidePartnerTagCreateFailure(response.getIn(['data', 'error']))
      );
    }

    // callback function
    cb(success);
  };
}

export function deleteCareGuidePartnerTag(id, cb = function () {}) {
  Logger.log(
    'debug',
    `[careGuidePartnerTags.actions] deleteCareGuidePartnerTag(${id}, ###)`
  );

  return async function (dispatch) {
    dispatch(careGuidePartnerTagDeleteRequest(id));

    // call API
    const response = await api.deleteCareGuidePartnerTag(id);
    let success = false;

    // delete care guide partner tag success
    if (204 === response.get('status')) {
      Logger.log(
        'info',
        `DELETE API care guide partner tag success. ID: ${id}.`
      );

      dispatch(removeEntity({ entityType: 'careGuidePartnerTags', id: id }));
      dispatch(careGuidePartnerTagDeleteSuccess(id));
      success = true;

      // get care guide partner tag failure
    } else {
      Logger.log(
        'info',
        `DELETE API care guide partner tag failure. ID: ${id}.`
      );
      dispatch(
        careGuidePartnerTagDeleteFailure(response.getIn(['data', 'error']))
      );
    }

    // callback function
    cb(success);
  };
}

export function downloadCareGuidePartnerTagsCSV(
  order = null,
  filter = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.careGuidePartnerTags.actions] downloadCareGuidePartnerTagsCSV(${order}, ${filter}, ###)`
  );

  return async function (dispatch) {
    dispatch(careGuidePartnerTagCSVDownloadRequest(order, filter));

    // call API
    const response = await api.getCareGuidePartnerTagsCSV(order, filter);
    let success = false;

    // get care guide partner tags CSV success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API care guide partner tags CSV success. Order: ${order}`
      );
      const now = new Date();

      // trigger browser download
      const url = window.URL.createObjectURL(new Blob([response.get('data')]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `cv-care-guide-partner-tags-${Format.date(now, 'YYYY-MM-DDTHHmm')}.csv`
      );
      document.body.appendChild(link);
      link.click();

      dispatch(careGuidePartnerTagCSVDownloadSuccess());
      success = true;

      // get care guide partner tags CSV failure
    } else {
      Logger.log('info', `Get API care guide partner tags CSV failure.`);
      dispatch(careGuidePartnerTagCSVDownloadFailure());
    }

    // callback function
    cb(success);
  };
}

Logger.log('silly', `careGuidePartnerTags.actions loaded.`);
