import { normalize } from 'normalizr';

import api from '../../api';
import { schema } from '../../schema';
import { addEntities, removeEntity } from '../../actions';
import Format from '../../../lib/Format';
import Logger from '../../../lib/Logger';

// ACTION TYPES

export const CARE_GUIDE_HELPER_LIST_REQUEST = 'CARE_GUIDE_HELPER_LIST_REQUEST';
export const CARE_GUIDE_HELPER_LIST_SUCCESS = 'CARE_GUIDE_HELPER_LIST_SUCCESS';
export const CARE_GUIDE_HELPER_LIST_FAILURE = 'CARE_GUIDE_HELPER_LIST_FAILURE';
export const CARE_GUIDE_HELPER_READ_REQUEST = 'CARE_GUIDE_HELPER_READ_REQUEST';
export const CARE_GUIDE_HELPER_READ_SUCCESS = 'CARE_GUIDE_HELPER_READ_SUCCESS';
export const CARE_GUIDE_HELPER_READ_FAILURE = 'CARE_GUIDE_HELPER_READ_FAILURE';
export const CARE_GUIDE_HELPER_UPDATE_REQUEST =
  'CARE_GUIDE_HELPER_UPDATE_REQUEST';
export const CARE_GUIDE_HELPER_UPDATE_SUCCESS =
  'CARE_GUIDE_HELPER_UPDATE_SUCCESS';
export const CARE_GUIDE_HELPER_UPDATE_FAILURE =
  'CARE_GUIDE_HELPER_UPDATE_FAILURE';
export const CARE_GUIDE_HELPER_CREATE_REQUEST =
  'CARE_GUIDE_HELPER_CREATE_REQUEST';
export const CARE_GUIDE_HELPER_CREATE_SUCCESS =
  'CARE_GUIDE_HELPER_CREATE_SUCCESS';
export const CARE_GUIDE_HELPER_CREATE_FAILURE =
  'CARE_GUIDE_HELPER_CREATE_FAILURE';
export const CARE_GUIDE_HELPER_DELETE_REQUEST =
  'CARE_GUIDE_HELPER_DELETE_REQUEST';
export const CARE_GUIDE_HELPER_DELETE_SUCCESS =
  'CARE_GUIDE_HELPER_DELETE_SUCCESS';
export const CARE_GUIDE_HELPER_DELETE_FAILURE =
  'CARE_GUIDE_HELPER_DELETE_FAILURE';
export const CARE_GUIDE_HELPER_FORM_DESTROY = 'CARE_GUIDE_HELPER_FORM_DESTROY';
export const CARE_GUIDE_MEMBER_LIST_REQUEST = 'CARE_GUIDE_MEMBER_LIST_REQUEST';
export const CARE_GUIDE_MEMBER_LIST_SUCCESS = 'CARE_GUIDE_MEMBER_LIST_SUCCESS';
export const CARE_GUIDE_MEMBER_LIST_FAILURE = 'CARE_GUIDE_MEMBER_LIST_FAILURE';
export const CARE_GUIDE_HELPER_CSV_DOWNLOAD_REQUEST =
  'CARE_GUIDE_HELPER_CSV_DOWNLOAD_REQUEST';
export const CARE_GUIDE_HELPER_CSV_DOWNLOAD_SUCCESS =
  'CARE_GUIDE_HELPER_CSV_DOWNLOAD_SUCCESS';
export const CARE_GUIDE_HELPER_CSV_DOWNLOAD_FAILURE =
  'CARE_GUIDE_HELPER_CSV_DOWNLOAD_FAILURE';

// ACTION CREATORS

export function careGuideHelperListRequest(page, limit, order, filter) {
  Logger.log(
    'debug',
    `[careGuideHelpers.actions] careGuideHelperListRequest(${page}, ${limit}, ${order}, %j)`,
    filter
  );
  return {
    type: CARE_GUIDE_HELPER_LIST_REQUEST,
    page: page,
    limit: limit,
    order: order,
    filter: filter,
  };
}

export function careGuideHelperListSuccess(data) {
  Logger.log(
    'debug',
    `[careGuideHelpers.actions] careGuideHelperListSuccess(%j)`,
    data
  );
  return {
    type: CARE_GUIDE_HELPER_LIST_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now(),
  };
}

export function careGuideHelperListFailure(error) {
  Logger.log(
    'debug',
    `[careGuideHelpers.actions] careGuideHelperListFailure(%j)`,
    error
  );
  return {
    type: CARE_GUIDE_HELPER_LIST_FAILURE,
    error: error,
  };
}

export function careGuideHelperReadRequest(id) {
  Logger.log(
    'debug',
    `[careGuideHelpers.actions] careGuideHelperReadRequest(${id})`
  );
  return {
    type: CARE_GUIDE_HELPER_READ_REQUEST,
    id: id,
  };
}

export function careGuideHelperReadSuccess(data) {
  Logger.log(
    'debug',
    `[careGuideHelpers.actions] careGuideHelperReadSuccess(%j)`,
    data
  );
  return {
    type: CARE_GUIDE_HELPER_READ_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function careGuideHelperReadFailure(error) {
  Logger.log(
    'debug',
    `[careGuideHelpers.actions] careGuideHelperReadFailure(%j)`,
    error
  );
  return {
    type: CARE_GUIDE_HELPER_READ_FAILURE,
    error: error,
  };
}

export function careGuideHelperUpdateRequest(id, data) {
  Logger.log(
    'debug',
    `[careGuideHelpers.actions] careGuideHelperUpdateRequest(${id}, %j)`,
    data
  );
  return {
    type: CARE_GUIDE_HELPER_UPDATE_REQUEST,
  };
}

export function careGuideHelperUpdateSuccess(data) {
  Logger.log(
    'debug',
    `[careGuideHelpers.actions] careGuideHelperUpdateSuccess(%j)`,
    data
  );
  return {
    type: CARE_GUIDE_HELPER_UPDATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function careGuideHelperUpdateFailure(error) {
  Logger.log(
    'debug',
    `[careGuideHelpers.actions] careGuideHelperUpdateFailure(%j)`,
    error
  );
  return {
    type: CARE_GUIDE_HELPER_UPDATE_FAILURE,
    error: error,
  };
}

export function careGuideHelperCreateRequest(data) {
  Logger.log(
    'debug',
    `[careGuideHelpers.actions] careGuideHelperCreateRequest(%j)`,
    data
  );
  return {
    type: CARE_GUIDE_HELPER_CREATE_REQUEST,
  };
}

export function careGuideHelperCreateSuccess(data) {
  Logger.log(
    'debug',
    `[careGuideHelpers.actions] careGuideHelperCreateSuccess(%j)`,
    data
  );
  return {
    type: CARE_GUIDE_HELPER_CREATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function careGuideHelperCreateFailure(error) {
  Logger.log(
    'debug',
    `[careGuideHelpers.actions] careGuideHelperCreateFailure(%j)`,
    error
  );
  return {
    type: CARE_GUIDE_HELPER_CREATE_FAILURE,
    error: error,
  };
}

export function careGuideHelperDeleteRequest(id) {
  Logger.log(
    'debug',
    `[careGuideHelpers.actions] careGuideHelperDeleteRequest(${id})`
  );
  return {
    type: CARE_GUIDE_HELPER_DELETE_REQUEST,
    id: id,
  };
}

export function careGuideHelperDeleteSuccess(id) {
  Logger.log(
    'debug',
    `[careGuideHelpers.actions] careGuideHelperDeleteSuccess(${id})`
  );
  return {
    type: CARE_GUIDE_HELPER_DELETE_SUCCESS,
    id: id,
  };
}

export function careGuideHelperDeleteFailure(error) {
  Logger.log(
    'debug',
    `[careGuideHelpers.actions] careGuideHelperDeleteFailure(%j)`,
    error
  );
  return {
    type: CARE_GUIDE_HELPER_DELETE_FAILURE,
    error: error,
  };
}

export function careGuideHelperFormDestroy(formState = null) {
  Logger.log(
    'debug',
    `[careGuideHelpers.actions] careGuideHelperFormDestroy(%j)`,
    formState
  );
  return {
    type: CARE_GUIDE_HELPER_FORM_DESTROY,
    form: formState,
  };
}

export function careGuideMemberListRequest(filter) {
  Logger.log(
    'debug',
    `[careGuideHelpers.actions] careGuideMemberListRequest(%j)`,
    filter
  );
  return {
    type: CARE_GUIDE_MEMBER_LIST_REQUEST,
    filter: filter,
  };
}

export function careGuideMemberListSuccess(data) {
  Logger.log(
    'debug',
    `[careGuideHelpers.actions] careGuideMemberListSuccess(%j)`,
    data
  );
  return {
    type: CARE_GUIDE_MEMBER_LIST_SUCCESS,
    result: data.result,
    total: data.total,
    helperCount: data.helper_count,
    receivedAt: Date.now(),
  };
}

export function careGuideMemberListFailure(error) {
  Logger.log(
    'debug',
    `[careGuideHelpers.actions] careGuideMemberListFailure(%j)`,
    error
  );
  return {
    type: CARE_GUIDE_MEMBER_LIST_FAILURE,
    error: error,
  };
}

export function careGuideHelperCSVDownloadRequest(order, filter) {
  Logger.log(
    'debug',
    `[state.careGuideHelpers.actions] careGuideHelperCSVDownloadRequest()`
  );
  return {
    type: CARE_GUIDE_HELPER_CSV_DOWNLOAD_REQUEST,
    order: order,
    filter: filter,
  };
}

export function careGuideHelperCSVDownloadSuccess() {
  Logger.log(
    'debug',
    `[state.careGuideHelpers.actions] careGuideHelperCSVDownloadSuccess()`
  );
  return {
    type: CARE_GUIDE_HELPER_CSV_DOWNLOAD_SUCCESS,
  };
}

export function careGuideHelperCSVDownloadFailure() {
  Logger.log(
    'debug',
    `[state.careGuideHelpers.actions] careGuideHelperCSVDownloadFailure()`
  );
  return {
    type: CARE_GUIDE_HELPER_CSV_DOWNLOAD_FAILURE,
  };
}

// API THUNK ACTION CREATORS

export function loadCareGuideHelpers(
  page = 1,
  limit = 10,
  order = null,
  filter = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[careGuideHelpers.actions] loadCareGuideHelpers(${page}, ${limit}, ${order}, %j, ###)`,
    filter
  );

  return async function (dispatch) {
    dispatch(careGuideHelperListRequest(page, limit, order, filter));

    // call API
    const response = await api.getCareGuideHelpers(page, limit, order, filter);
    let success = false;

    // get care guide helpers list success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API care guide helpers list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );

      const normalizedEntities = normalize(
        response.getIn(['data', 'care_guide_helpers']),
        [schema.careGuideHelper]
      );
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result,
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideHelperListSuccess(data));
      success = true;
    } else if (1 === page && 204 === response.get('status')) {
      Logger.log(
        'info',
        `Get API care guide helpers success [empty]. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: [],
      };
      dispatch(careGuideHelperListSuccess(data));
      success = true;

      // get care guide helpers list failure
    } else {
      Logger.log(
        'info',
        `Get API care guide helpers list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      dispatch(careGuideHelperListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function loadCareGuideHelper(id, cb = function () {}) {
  Logger.log(
    'debug',
    `[careGuideHelpers.actions] loadCareGuideHelper(${id}, ###)`
  );

  return async function (dispatch) {
    dispatch(careGuideHelperReadRequest(id));

    // call API
    const response = await api.getCareGuideHelper(id);
    let success = false;

    // get care guide helper success
    if (200 === response.get('status')) {
      Logger.log('info', `Get API care guide helper success. ID: ${id}.`);

      const normalizedEntities = normalize(
        [response.getIn(['data', 'care_guide_helper'])],
        [schema.careGuideHelper]
      );
      const data = {
        id: response.getIn(['data', 'care_guide_helper', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideHelperReadSuccess(data));
      success = true;

      // get care guide helper failure
    } else {
      Logger.log('info', `Get API care guide helper failure. ID: ${id}.`);
      dispatch(careGuideHelperReadFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function updateCareGuideHelper(id, data, cb = function () {}) {
  Logger.log(
    'debug',
    `[careGuideHelpers.actions] updateCareGuideHelper(${id}, %j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(careGuideHelperUpdateRequest(id, data));

    // call API
    const response = await api.putCareGuideHelper(id, data);
    let success = false;

    // put care guide helper success
    if (200 === response.get('status')) {
      Logger.log('info', `PUT API care guide helper success. User: ${id}.`);

      const normalizedEntities = normalize(
        [response.getIn(['data', 'care_guide_helper'])],
        [schema.careGuideHelper]
      );
      const data = {
        id: response.getIn(['data', 'care_guide_helper', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideHelperUpdateSuccess(data));
      success = true;

      // get care guide helper failure
    } else {
      Logger.log('info', `PUT API care guide helper failure. ID: ${id}.`);
      dispatch(careGuideHelperUpdateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function createCareGuideHelper(data, cb = function () {}) {
  Logger.log(
    'debug',
    `[careGuideHelpers.actions] createCareGuideHelper(%j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(careGuideHelperCreateRequest(data));

    // call API
    const response = await api.postCareGuideHelpers(data);
    let success = false;

    // post care guide helpers success
    if (201 === response.get('status')) {
      Logger.log(
        'info',
        `POST API care guide helpers success. Post: ${response.getIn([
          'data',
          'care_guide_helper',
          'id',
        ])}.`
      );

      const normalizedEntities = normalize(
        [response.getIn(['data', 'care_guide_helper'])],
        [schema.careGuideHelper]
      );
      const data = {
        id: response.getIn(['data', 'care_guide_helper', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideHelperCreateSuccess(data));
      success = true;

      // get care guide helpers failure
    } else {
      Logger.log('info', `POST API care guide helpers failure.`);
      dispatch(careGuideHelperCreateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function deleteCareGuideHelper(id, cb = function () {}) {
  Logger.log(
    'debug',
    `[careGuideHelpers.actions] deleteCareGuideHelper(${id}, ###)`
  );

  return async function (dispatch) {
    dispatch(careGuideHelperDeleteRequest(id));

    // call API
    const response = await api.deleteCareGuideHelper(id);
    let success = false;

    // delete care guide helper success
    if (204 === response.get('status')) {
      Logger.log('info', `DELETE API care guide helper success. ID: ${id}.`);

      dispatch(removeEntity({ entityType: 'care_guide_helpers', id: id }));
      dispatch(careGuideHelperDeleteSuccess(id));
      success = true;

      // get care guide helper failure
    } else {
      Logger.log('info', `DELETE API care guide helper failure. ID: ${id}.`);
      dispatch(careGuideHelperDeleteFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function loadCareGuideMembers(filter = null, cb = function () {}) {
  Logger.log(
    'debug',
    `[careGuideHelpers.actions] loadCareGuideMembers(%j, ###)`,
    filter
  );

  return async function (dispatch) {
    dispatch(careGuideMemberListRequest(filter));

    // call API
    const response = await api.getCareGuideMembers(filter);
    let success = false;

    // get care guide members list success
    if (200 === response.get('status')) {
      Logger.log('info', `Get API care guide members list success.`);

      const normalizedEntities = normalize(
        response.getIn(['data', 'care_guide_members']),
        [schema.careGuideMember]
      );
      const data = {
        total: response.getIn(['data', 'total']),
        helper_count: response.getIn(['data', 'helper_count']),
        result: normalizedEntities.result,
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideMemberListSuccess(data));
      success = true;
    } else if (204 === response.get('status')) {
      Logger.log('info', `Get API care guide members success [empty].`);
      const data = {
        total: 0,
        result: [],
      };
      dispatch(careGuideMemberListSuccess(data));
      success = true;

      // get care guide helpers list failure
    } else {
      Logger.log('info', `Get API care guide members list failure`);
      dispatch(careGuideMemberListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function downloadCareGuideHelpersCSV(
  order = null,
  filter = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.careGuideHelpers.actions] downloadCareGuideHelpersCSV(${order}, ${filter}, ###)`
  );

  return async function (dispatch) {
    dispatch(careGuideHelperCSVDownloadRequest(order, filter));

    // call API
    const response = await api.getCareGuideHelpersCSV(order, filter);
    let success = false;

    // get care guide helpers CSV success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API care guide helpers CSV success. Order: ${order}`
      );
      const now = new Date();

      // trigger browser download
      const url = window.URL.createObjectURL(new Blob([response.get('data')]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `cv-care-guide-helpers-${Format.date(now, 'YYYY-MM-DDTHHmm')}.csv`
      );
      document.body.appendChild(link);
      link.click();

      dispatch(careGuideHelperCSVDownloadSuccess());
      success = true;
      // get care guide helpers CSV failure
    } else {
      Logger.log('info', `Get API care guide helpers CSV failure.`);
      dispatch(careGuideHelperCSVDownloadFailure());
    }

    // callback function
    cb(success);
  };
}

Logger.log('silly', `careGuideHelpers.actions loaded.`);
