import {getI18n} from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import UserProfilesPartnershipsScreen from './containers/UserProfilesPartnershipsScreenContainer';
import UserProfilesPartnershipAddScreen from './containers/UserProfilesPartnershipAddScreenContainer';
import UserProfilesPartnershipEditScreen from './containers/UserProfilesPartnershipEditScreenContainer';

const register = () => {
  Logger.log('debug', `userProfilesPartnerships.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'userProfilesPartnerships', 'routes'])) {
    Events.dispatch('ADD_MAIN_ROUTES', 
      {
        'UserProfilesPartnershipAddScreen': ['PrivateRoute', '/userProfilespartnership/add', true, UserProfilesPartnershipAddScreen, getI18n().t('user_profiles_partnership_route_add')],
        'UserProfilesPartnershipEditScreen': ['PrivateRoute', '/userProfilespartnership/edit/:id([\\w\\-]+)', true, UserProfilesPartnershipEditScreen, getI18n().t('user_profiles_partnership_route_edit')],
        'UserProfilesPartnershipsScreen': ['PrivateRoute', '/userProfilespartnership/:page(\\d+)?', true, UserProfilesPartnershipsScreen, getI18n().t('user_profiles_partnerships_route_list')],
      }
    );
  }
}

export default register;

Logger.log('silly', `partnerships.register() loaded.`);
