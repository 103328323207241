import {connect} from 'react-redux';

import RegistrationCodeEditScreen from '../components/RegistrationCodeEditScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.registrationCodes.get('isLoading'),
    success: state.registrationCodes.getIn(['form', 'success']),
  }
}

const RegistrationCodeEditScreenContainer = connect(
  mapStateToProps
)(RegistrationCodeEditScreen);

export default RegistrationCodeEditScreenContainer;
