import {connect} from 'react-redux';

import {downloadCareGuideTemplatesCSV} from '../../../../../state/modules/careGuideTemplates/actions';
import CareGuideTemplatesCSVButton from '../components/CareGuideTemplatesCSVButton';

const mapStateToProps = state => {
  return {
    isDownloading: state.careGuideTemplates.get('isCSVDownloading'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    download: (order, filter, cb) => {
      dispatch(downloadCareGuideTemplatesCSV(order, filter, cb));
    }
  }
}

const CareGuideTemplatesCSVButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CareGuideTemplatesCSVButton);

export default CareGuideTemplatesCSVButtonContainer;
