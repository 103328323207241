import {connect} from 'react-redux';

import {loadPostalCodes} from '../../../../../../state/modules/locationPostalCodes/actions';
import PostalCodeInput from '../components/PostalCodeInput';

const mapStateToProps = (state, ownProps) => {
  // const regionIds = ownProps['region_ids'] ? ownProps['region_ids'].split(',').map(x => parseInt(x)) : [];
  // const subregionIds = ownProps['subregion_ids'] ? ownProps['subregion_ids'].split(',').map(x => parseInt(x)) : [];
  const cityIds = ownProps['city_id']
    ? [parseInt(ownProps['city_id'])]
    : (ownProps['city_ids'] ? ownProps['city_ids'].split(',').map(x => parseInt(x)) : []);
  const postalCodes = state.entities.get('postalCodes', {});
  return {
    options: Object.keys(postalCodes)
      // .filter(id => postalCodes[id].region && regionIds.includes(postalCodes[id].region))
      // .filter(id => postalCodes[id].subregion && subregionIds.includes(postalCodes[id].subregion))
      .filter(id => postalCodes[id].city && cityIds.includes(postalCodes[id].city))
      .map(id => {return {label: postalCodes[id].postal_code, value: id}})
      .sort((a, b) => a.label > b.label ? 1 : -1),
    isLoading: state.locationPostalCodes.get('arePostalCodesLoading'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (page, limit, order, filter, cb) => {
      dispatch(loadPostalCodes(page, limit, order, filter, cb));
    },
  }
}

const PostalCodeInputContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PostalCodeInput);

export default PostalCodeInputContainer;
