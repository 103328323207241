import {connect} from 'react-redux';

import {loadCountries} from '../../../../../../state/modules/locationCountries/actions';
import CountryInput from '../components/CountryInput';

const mapStateToProps = (state) => {
  const countries = state.entities.get('countries', {});
  return {
    options: Object.keys(countries).map(id => {return {label: countries[id].name, value: id}}),
    isLoading: state.locationCountries.get('areCountriesLoading'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (page, limit, cb) => {
      dispatch(loadCountries(page, limit, cb));
    },
  }
}

const CountryInputContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CountryInput);

export default CountryInputContainer;
