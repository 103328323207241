import { connect } from 'react-redux';
import { denormalize } from 'normalizr';

import { schema } from '../../../../../state/schema';
import {
  loadCareGuideDocuments,
  deleteCareGuideDocument,
} from '../../../../../state/modules/careGuideDocuments/actions';
import CareGuideDocumentsList from '../components/CareGuideDocumentsList';

const mapStateToProps = (state, ownProps) => {
  const page = ownProps.page;
  const limit = ownProps.limit;
  const order = ownProps.order;
  const result = state.careGuideDocuments.getIn(['pages', order, limit, page]);

  return {
    list: result
      ? result
          .map((x) => {
            return {
              key: x,
              ...denormalize(
                state.entities.getIn(['careGuideDocuments', x]),
                schema.careGuideDocument,
                state.entities.toJS()
              ),
            };
          })
          .toArray()
          .map((x) => {
            // flatten some nested objects to make table easier to work with
            return {
              partnership_name: x?.care_guide?.partnership?.name ?? '',
              partnership_plan: x?.care_guide?.partnership?.plan?.name ?? '',
              care_guide_name:
                x?.care_guide?.first_name && x?.care_guide?.last_name
                  ? `${x?.care_guide?.first_name} ${x?.care_guide?.last_name}`
                  : '',
              profile_username: x.profile?.user?.username || '',
              ...x,
            };
          })
      : [],
    isLoading: state.careGuideDocuments.get('isLoading'),
    total: state.careGuideDocuments.get('total'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (page, limit, order, filter, cb) => {
      dispatch(loadCareGuideDocuments(page, limit, order, filter, cb));
    },
    remove: (id, cb) => {
      dispatch(deleteCareGuideDocument(id, cb));
    },
  };
};

const CareGuideDocumentsListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CareGuideDocumentsList);

export default CareGuideDocumentsListContainer;
