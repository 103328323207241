import { connect } from 'react-redux';
import { List } from 'immutable';
import moment from 'moment';
import {
  loadRecommendedUtility,
  updateRecommendedUtility,
  createRecommendedUtility,
  deleteRecommendedUtility,
  recommendedUtilitiesFormDestroy,
} from '../../../../../state/modules/recommendedUtilities/actions';
import RecommendedUtilityForm from '../components/RecommendedUtilityForm';
import Config from '../../../../../Config';

const inputs = List([
  'partnership',
  'profile',
  'website',
  'file_name',
  'file_name_bucket',
  'file_extension',
  'file_directory',
  'file_bucket',
  'size_bytes',
  'title',
  'uploaded_at',
  'status',
]);

const mapStateToProps = (state, ownProps) => {
  const data = {},
    errors = {};
  for (const val of inputs.values()) {
    data[val] = state.entities.getIn(
      ['recommendedUtilities', ownProps.id, val],
      ''
    );

    if (['profile'].includes(val)) {
      errors[val] = state.recommendedUtilities.getIn([
        'form',
        'errors',
        'user_profile_id',
      ]);
    } else if (['partnership'].includes(val)) {
      errors[val] = state.recommendedUtilities.getIn([
        'form',
        'errors',
        val + '_id',
      ]);
    } else {
      errors[val] = state.recommendedUtilities.getIn(['form', 'errors', val]);
    }
  }

  // change select number values to strings to work with ant design select inputs
  Object.keys(data).forEach((x) => {
    if (['status'].includes(x)) {
      data[x] = data[x].toString();
    }
  });

  data['uploaded_at'] = data['uploaded_at']
    ? moment(data['uploaded_at'], Config.get('API_DATETIME_FORMAT'))
    : moment();

  return {
    isLoading: state.recommendedUtilities.get('isLoading'),
    isSubmitting: state.recommendedUtilities.getIn(['form', 'isSubmitting']),
    success: state.recommendedUtilities.getIn(['form', 'success']),
    created_id: state.recommendedUtilities.getIn(['form', 'created_id']),
    data: data,
    errors: errors,
    createdAt: state.entities.getIn(
      ['recommendedUtilities', ownProps.id, 'created_at'],
      null
    ),
    updatedAt: state.entities.getIn(
      ['recommendedUtilities', ownProps.id, 'updated_at'],
      null
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (id, cb) => {
      dispatch(loadRecommendedUtility(id, cb));
    },
    update: (id, data, cb) => {
      dispatch(updateRecommendedUtility(id, data, cb));
    },
    create: (data, cb) => {
      dispatch(createRecommendedUtility(data, cb));
    },
    delete: (id, cb) => {
      dispatch(deleteRecommendedUtility(id, cb));
    },
    destroyForm: (formState) => {
      dispatch(recommendedUtilitiesFormDestroy(formState));
    },
  };
};

const RecommendedUtilityFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RecommendedUtilityForm);

export default RecommendedUtilityFormContainer;
