import { connect } from 'react-redux';

import ResourcesScreen from '../components/ResourcesScreen';
import { downloadResourcesCSV } from '../../../../../state/modules/resources/actions';

const mapStateToProps = (state) => {
  return {
    total: state.resources.get('total'),
    isLoading: state.resources.get('isLoading'),
    isDownloading: state.resources.get('isCSVDownloading'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    download: (order, filter, cb) => {
      dispatch(downloadResourcesCSV(order, filter, cb));
    },
  };
};

const ResourcesScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResourcesScreen);

export default ResourcesScreenContainer;
