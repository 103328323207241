import { connect } from 'react-redux';
import { denormalize } from 'normalizr';

import { schema } from '../../../../../state/schema';
import {
  loadCareGuidePartnerTags,
  deleteCareGuidePartnerTag,
} from '../../../../../state/modules/careGuidePartnerTags/actions';
import { sendMessage } from '../../../../../state/actions';
import CareGuidePartnerTagsList from '../components/CareGuidePartnerTagsList';

const mapStateToProps = (state, ownProps) => {
  const page = ownProps.page;
  const limit = ownProps.limit;
  const order = ownProps.order;
  const result = state.careGuidePartnerTags.getIn([
    'pages',
    order,
    limit,
    page,
  ]);

  return {
    limit,
    page,
    order,
    list: result
      ? result
          .map((x) => {
            return {
              key: x,
              ...denormalize(
                state.entities.getIn(['careGuidePartnerTags', x]),
                schema.careGuidePartnerTag,
                state.entities.toJS()
              ),
            };
          })
          .toArray()
          .map((x) => {
            return {
              partnership_name: x?.admin_partner_class?.partnership?.name ?? '',
              partnership_plan:
                x?.admin_partner_class?.partnership?.plan?.name ?? '',
              class_name: x?.admin_partner_class?.name ?? '',
              ...x,
            };
          })
      : [],
    isLoading: state.careGuidePartnerTags.get('isLoading'),
    total: state.careGuidePartnerTags.get('total'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (page, limit, order, filter, cb) => {
      dispatch(loadCareGuidePartnerTags(page, limit, order, filter, cb));
    },
    remove: (id, cb) => {
      dispatch(deleteCareGuidePartnerTag(id, cb));
    },
    sendMessage: (level, title, body, expires) => {
      dispatch(sendMessage(level, title, body, expires));
    },
  };
};

const CareGuidePartnerTagsListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CareGuidePartnerTagsList);

export default CareGuidePartnerTagsListContainer;
