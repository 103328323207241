import React from 'react';
import { Translation, getI18n } from 'react-i18next';
import { Button, Upload } from 'antd';
import { CloudUploadOutlined, LoadingOutlined } from '@ant-design/icons';

import message from '../../../elements/lib/MessageWrapper';

const CareGuideUploadCSVButton = ({
  isSendCsv,
  sendCSV,
  ...props
}) => {
  // upload criteria
  const fileTypes = {
    'text/csv': 'csv',
    'application/vnd.ms-excel': 'csv',
    'application/csv': 'csv',
    'text/x-csv': 'csv',
    'application/x-csv': 'csv',
    'text/comma-separated-values': 'csv',
    'text/x-comma-separated-values': 'csv',
    'text/plain': 'csv',
  };
  const fileSizeLimit = 1024 * 1024 * 10;

  const beforeUpload = (file) => {
    const isSupportedFileType = Object.keys(fileTypes).includes(file.type);
    if (!isSupportedFileType) {
      message.error(getI18n().t('imports_csv_form_feedback_file_types'));
    }

    const isUnderFileSizeLimit = file.size < fileSizeLimit;
    if (!isUnderFileSizeLimit) {
      message.error(getI18n().t('imports_csv_form_feedback_file_size_limit'));
    }

    return isSupportedFileType && isUnderFileSizeLimit;
  };

  const submit = async (data) => {

    const formData = new FormData();
    formData.append('care_guides_csv', data.file);

    sendCSV(formData, async (uploadSuccess, messageResponse) => {
      if (uploadSuccess) {
        message.success(getI18n().t(messageResponse));
      } else {
        message.error(getI18n().t(messageResponse));
      }
    });
  };

  return (
    <Translation>
      {(t) => (
        <>
          <Upload
            name="import"
            showUploadList={false}
            customRequest={submit}
            beforeUpload={beforeUpload}
          >
            <Button
              size="small"
              icon={isSendCsv ? <LoadingOutlined /> : <CloudUploadOutlined />}
            >
              {t('action_import')}
            </Button>
          </Upload>
        </>
      )}
    </Translation>
  );
};

export default CareGuideUploadCSVButton;
