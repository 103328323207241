import React, { useState } from 'react';
import message from '../../../elements/lib/MessageWrapper';
import Logger from '../../../../../lib/Logger';
import { withRouter } from 'react-router';
import { Translation, getI18n } from 'react-i18next';
import { Space, Button, Form, Radio } from 'antd';
import { Modal, Typography } from 'antd';
import PartnershipInput from '../../partnerships/containers/PartnershipInputContainer';

const { Text } = Typography;

const layout = {
  main: {
    labelCol: { span: 5 },
    wrapperCol: { span: 19 },
  },
};

const CareGuideModalExport = ({
  openModalExport,
  setOpenModalExport,
  isDownloading,
  total,
  download,
  order,
  filter,
  isLoading,
  ...props
}) => {
  const [partnershipId, setPartnershipId] = useState(null);
  const [optionExport, setOptionExport] = useState(1);

  const [form] = Form.useForm();

  const onClickOK = () => {
    form.submit();
  };

  // form submit handler
  const handleFinish = async (values) => {
    Logger.log('debug', `careGuideModalExportForm.handleFinish(###)`);
    if (!isDownloading) {
      await submitData(values);
    }
  };

  // form error handler
  const handleFinishFailed = () => {
    Logger.log('debug', `careGuideModalExportForm.handleFinishFailed(###)`);
    message.error(getI18n().t('care_guide_message_export_failure'));
  };

  const submitData = async (values) => {
    Logger.log('debug', `careGuideModalExportForm.submitData(###)`);

    let filterByOptions = filter;
    let orderByOptions = order;

    if (values['option_export'] === 3) {
      filterByOptions = { export_public: true };
      orderByOptions = 'started_at.asc';
    }

    download(partnershipId, orderByOptions, filterByOptions, (success) => {
      if (success) {
        message.success(getI18n().t('care_guide_message_export_success'));
        clearForm();
      } else {
        message.error(getI18n().t('care_guide_message_export_failure'));
      }
    });
  };

  const clearForm = () => {
    setOpenModalExport(false);
    form.setFieldsValue({
      option_export: 1,
      partnership: null,
    });
    setOptionExport(1);
    setPartnershipId(null);
  };

  return (
    <Translation>
      {(t) => (
        <>
          <Modal
            title={t('care_guide_export_title')}
            className="modal-export"
            centered
            visible={openModalExport}
            onCancel={clearForm}
            footer={[
              <Button
                key="back"
                onClick={clearForm}
                disabled={
                  total < 1 ||
                  (!partnershipId && (optionExport === 2 || optionExport === 3)) || isDownloading
                }
              >
                {t('care_guide_export_cancel')}
              </Button>,
              <Button
                key="submit"
                type="primary"
                loading={isDownloading}
                disabled={
                  total < 1 ||
                  (!partnershipId && (optionExport === 2 || optionExport === 3))
                }
                onClick={onClickOK}
              >
                {t('care_guide_export_care_guides')}
              </Button>,
            ]}
          >
            <Form
              name="validate_other"
              {...layout.main}
              initialValues={{
                option_export: 1,
              }}
              form={form}
              onFinish={handleFinish}
              onFinishFailed={handleFinishFailed}
              validateTrigger="onSubmit"
              requiredMark={false}
              layout="vertical"
            >
              <div className="ant-col ant-col-24 ant-form-item-label">
                <label
                  className="ant-form-item-required export-care-guides-options-title"
                  title="options-title"
                >
                  {t('care_guide_list_export')}
                </label>
                <Text disabled>({t('required')})</Text>
              </div>

              <Form.Item name="option_export">
                <Radio.Group
                  size={'large'}
                  onChange={(e) => setOptionExport(e.target.value)}
                >
                  <Space direction="vertical">
                    <Radio value={1} disabled={total < 1}>
                      {t('care_guide_export_option_all')}
                    </Radio>
                    <Radio value={2} disabled={total < 1}>
                      {t('care_guide_option_filtered_by_general')}
                    </Radio>
                    <Radio value={3} disabled={total < 1}>
                      {t('care_guide_option_filtered_by_partner')}
                    </Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
              {(optionExport === 2 || optionExport === 3) && (
                <PartnershipInput
                  form={form}
                  name="partnership"
                  label={t('care_guide_partnership')}
                  disabled={isDownloading}
                  allowNone={false}
                  onChange={(e) => setPartnershipId(e)}
                />
              )}
              <div style={{ display: 'none' }}>
                <Button htmlType="submit"></Button>
              </div>
            </Form>
          </Modal>
        </>
      )}
    </Translation>
  );
};

export default withRouter(CareGuideModalExport);
