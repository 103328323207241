import {getI18n} from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import CareGuideHelperTagsScreen from './containers/CareGuideHelperTagsScreenContainer';
import CareGuideHelperTagAddScreen from './containers/CareGuideHelperTagAddScreenContainer';
import CareGuideHelperTagEditScreen from './containers/CareGuideHelperTagEditScreenContainer';

const register = () => {
  Logger.log('debug', `careGuideHelperTags.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'careGuideHelperTags', 'routes'])) {
    Events.dispatch('ADD_MAIN_ROUTES', 
      {
        'CareGuideHelperTagAddScreen': ['PrivateRoute', '/care-guide-helper-tags/add', true, CareGuideHelperTagAddScreen, getI18n().t('care_guide_helper_tag_route_add')],
        'CareGuideHelperTagEditScreen': ['PrivateRoute', '/care-guide-helper-tags/edit/:id([\\w\\-]+)', true, CareGuideHelperTagEditScreen, getI18n().t('care_guide_helper_tag_route_edit')],
        'CareGuideHelperTagsScreen': ['PrivateRoute', '/care-guide-helper-tags/:page(\\d+)?', true, CareGuideHelperTagsScreen, getI18n().t('care_guide_helper_tags_route_list')],
      }
    );
  }
}

export default register;

Logger.log('silly', `careGuideHelperTags.register() loaded.`);
