import { connect } from 'react-redux';

import DashboardSectionEditScreen from '../components/DashboardSectionEditScreen';

const mapStateToProps = (state) => {
  return {
    isLoading: state.dashboardSections.get('isLoading'),
    success: state.dashboardSections.getIn(['form', 'success']),
  };
};

const DashboardSectionEditScreenContainer = connect(mapStateToProps)(
  DashboardSectionEditScreen
);

export default DashboardSectionEditScreenContainer;
