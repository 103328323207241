import {connect} from 'react-redux';

import PartnershipsScreen from '../components/PartnershipsScreen';

const mapStateToProps = state => {
  return {
    total: state.partnerships.get('total'),
    isLoading: state.partnerships.get('isLoading')
  }
}

const PartnershipsScreenContainer = connect(
  mapStateToProps
)(PartnershipsScreen);

export default PartnershipsScreenContainer;
