import { connect } from 'react-redux';

import RecommendedUtilityEditScreen from '../components/RecommendedUtilityEditScreen';

const mapStateToProps = (state) => {
  return {
    isLoading: state.recommendedUtilities.get('isLoading'),
    success: state.recommendedUtilities.getIn(['form', 'success']),
  };
};

const RecommendedUtilityEditScreenContainer = connect(mapStateToProps)(
  RecommendedUtilityEditScreen
);

export default RecommendedUtilityEditScreenContainer;
