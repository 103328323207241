import { connect } from 'react-redux';

import CalendarEventsCSVButton from '../components/CalendarEventsCSVButton';

const mapStateToProps = (state) => {
  return {};
};

const CalendarEventsCSVButtonContainer = connect(mapStateToProps)(
  CalendarEventsCSVButton
);

export default CalendarEventsCSVButtonContainer;
