import {Map, List} from 'immutable';

import {
    REGION_LIST_REQUEST,
    REGION_LIST_SUCCESS,
    REGION_LIST_FAILURE,
    REGION_READ_REQUEST,
    REGION_READ_SUCCESS,
    REGION_READ_FAILURE,
    REGION_UPDATE_REQUEST,
    REGION_UPDATE_SUCCESS,
    REGION_UPDATE_FAILURE,
    REGION_CREATE_REQUEST,
    REGION_CREATE_SUCCESS,
    REGION_CREATE_FAILURE,
    REGION_DELETE_REQUEST,
    REGION_DELETE_SUCCESS,
    REGION_DELETE_FAILURE,
    REGION_FORM_DESTROY
  } from './actions';
  import Logger from '../../../lib/Logger';
  
export default function regions(
    state=Map({
      areRegionsLoading: false
    }),
    action
  ) {
    Logger.log('debug', `[region.reducers] regions(%j, %j)`, state, action);
  
    switch(action.type) {
  
      case REGION_LIST_REQUEST:
        return state.mergeDeep({
          areRegionsLoading: true
        });
  
      case REGION_LIST_SUCCESS:
        return state.mergeDeep({
          areRegionsLoading: false,
          pages: {
            [action.order]: {
              [action.limit]: {
                [action.page]: null
              }
            }
          },
          total: action.total,
          lastUpdated: action.receivedAt
        }).setIn(['pages', action.order, action.limit, action.page], List(action.result));
  
      case REGION_LIST_FAILURE:
        return state.mergeDeep({
          areRegionsLoading: false
        });
      case REGION_READ_REQUEST:
        return state.mergeDeep({
          areRegionsLoading: true
        });
  
      case REGION_READ_SUCCESS:
        return state.mergeDeep({
          areRegionsLoading: false,
          lastUpdated: action.receivedAt
        });
  
      case REGION_READ_FAILURE:
        return state.mergeDeep({
          areRegionsLoading: false
        });
        case REGION_UPDATE_REQUEST:
          return state.mergeDeep({
            areRegionsLoading: true,
            form: {
              isSubmitting: true,
              success: null,
              errors: null
            }
          });
    
        case REGION_UPDATE_SUCCESS:
          return state.mergeDeep({
            areRegionsLoading: false,
            form: {
              isSubmitting: false,
              success: true,
              errors: null
            },
            lastUpdated: action.receivedAt
          });
    
        case REGION_UPDATE_FAILURE:
          return state.mergeDeep({
            areRegionsLoading: false,
            form: {
              isSubmitting: false,
              success: false,
              errors: null
            }
          }).setIn(['form', 'errors'], action.error);
    
        case REGION_CREATE_REQUEST:
          return state.mergeDeep({
            areRegionsLoading: true,
            form: {
              isSubmitting: true,
              success: null,
              errors: null
            }
          });
    
        case REGION_CREATE_SUCCESS:
          return state.mergeDeep({
            areRegionsLoading: false,
            form: {
              isSubmitting: false,
              success: true,
              created_id: action.id,
              errors: null
            },
            lastUpdated: action.receivedAt
          });
    
        case REGION_CREATE_FAILURE:
          return state.mergeDeep({
            areRegionsLoading: false,
            form: {
              isSubmitting: false,
              success: false,
              errors: null
            }
          }).setIn(['form', 'errors'], action.error);
    
        case REGION_DELETE_REQUEST:
          return state.mergeDeep({
            areRegionsLoading: true
          });
    
        case REGION_DELETE_SUCCESS:
          return state.mergeDeep({
            areRegionsLoading: false,
            lastUpdated: action.receivedAt
          });
    
        case REGION_DELETE_FAILURE:
          return state.mergeDeep({
            areRegionsLoading: false
          });
    
        case REGION_FORM_DESTROY:
          return state.mergeDeep({
            form: null
          }).set('form', action.form);
          
      default:
        return state;
    }
  }
  
  Logger.log('silly', `region.reducers loaded.`);