import {connect} from 'react-redux';

import CityScreen from '../components/CityScreen';

const mapStateToProps = state => {
  return {
    total: state.locationCities.get('total'),
    isLoading: state.locationCities.get('areCitiesLoading')
  }
}

const CityScreenContainer = connect(
  mapStateToProps
)(CityScreen);

export default CityScreenContainer;
