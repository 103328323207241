import React from 'react';
import {Translation} from 'react-i18next';
import {Button, Checkbox, Form, Input, Select, Space} from 'antd';
import {DragOutlined, MinusOutlined, PlusOutlined} from '@ant-design/icons';
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";

const TimeInput = ({name=null, interval=60, emptyOption=null, value=null, onChange=null, className=null, ...props}) => {

  const options = [];
  const ap = ['AM', 'PM'];
  let tt = 0;

  if (emptyOption) {
    options.push({
      value: null,
      label: emptyOption
    });
  }
  
  for (let i = emptyOption ? 1 : 0; tt < 24 * 60; i++) {
    let hh = Math.floor(tt / 60);
    let mm = (tt % 60);
    let label = ("" + ((hh === 12 || hh === 0) ? 12 : hh % 12)).slice(-2) + ':' + ("0" + mm).slice(-2) + ' ' + ap[Math.floor(hh / 12)];
    options[i] = {
      value: label,
      label: label
    }
    tt = tt + interval;
  }

  return (
    <Translation>{(t) => 
      <Select
        name={name}
        value={value}
        onChange={onChange}
        className={className}
      >
        {options.map(x =>
          <Select.Option
            key={x.value}
            value={x.value}
          >
            {x.label}
          </Select.Option>)}
      </Select>
    }</Translation>
  );
}

const FieldTypeMedTableEditor = ({form, content, contentsPath, ...props}) => {

  // input column settings
  const layout = {
    main: {
      wrapperCol: {span: 24},
    },
  }

  // move draggable row into new position after drag and drop reorder
  const onDragEnd = (result, move) => {
    if (result.destination) {
      move(result.source.index, result.destination.index);
    }
  }

  return (
    <Translation>{(t) => 
      <>

        <Form.Item
          {...content}
          name={[content.name, 'options', 'is_adjustable']}
          label={t('care_guide_med_table_input_is_adjustable')}
          valuePropName="checked"
          {...layout.main}
        >
          <Checkbox />
        </Form.Item>

        <Form.Item
          {...content}
          name={[content.name, 'options', 'group_name_placeholder']}
          label={t('care_guide_med_table_input_group_name_placeholder')}
          {...layout.main}
        >
          <Input />
        </Form.Item>

        <Form.Item
          {...content}
          name={[content.name, 'options', 'medication_placeholder']}
          label={t('care_guide_med_table_input_medication_placeholder')}
          {...layout.main}
        >
          <Input />
        </Form.Item>

        <Form.Item
          {...content}
          name={[content.name, 'options', 'dose_placeholder']}
          label={t('care_guide_med_table_input_dose_placeholder')}
          {...layout.main}
        >
          <Input />
        </Form.Item>

        <Form.Item
          {...content}
          name={[content.name, 'options', 'notes_placeholder']}
          label={t('care_guide_med_table_input_notes_placeholder')}
          {...layout.main}
        >
          <Input />
        </Form.Item>

        <div className="field-type-med-table-group-list nested-list">
          <Form.List name={[content.name, 'groups']}>

            {(groups, {add, remove, move}) => {
              return(
                <>
                  <DragDropContext onDragEnd={(result) => onDragEnd(result, move)}>
                    <Droppable droppableId="droppableMedTableGroup">
                      {(provided, snapshot) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {groups.map((group, i) => (
                            <Draggable key={group.key} draggableId={group.key.toString()} index={i}>
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                >

                                  <div key={i}>
                                    <Space direction="horizontal" align="start">

                                      <div className="drag-handle" {...provided.dragHandleProps}>
                                        <DragOutlined />
                                      </div>

                                      <Space size={2} direction="vertical">

                                        <Form.Item
                                          {...group}
                                          name={[group.name, 'name']}
                                          label={t('care_guide_med_table_input_name')}
                                          fieldKey={[group.fieldKey, 'name']}
                                          rules={[
                                            {required: true, message: t('feedback_validation_required')},
                                          ]}
                                          {...layout.main}
                                        >
                                          <Input placeholder="Header" />
                                        </Form.Item>

                                        <Form.Item
                                          {...group}
                                          name={[group.name, 'is_deletable']}
                                          label={t('care_guide_med_table_input_is_deletable')}
                                          fieldKey={[group.fieldKey, 'is_deletable']}
                                          valuePropName="checked"
                                          {...layout.main}
                                        >
                                          <Checkbox />
                                        </Form.Item>

                                        <Form.Item
                                          {...group}
                                          name={[group.name, 'is_adjustable']}
                                          label={t('care_guide_med_table_input_is_adjustable')}
                                          fieldKey={[group.fieldKey, 'is_adjustable']}
                                          valuePropName="checked"
                                          {...layout.main}
                                        >
                                          <Checkbox />
                                        </Form.Item>

                                        <div className="ant-form-item-label">
                                          {t('care_guide_med_table_input_rows')}
                                        </div>

                                        <div className="nested-list">
                                          <Form.List name={[group.name, 'fields']}>

                                            {(fields, {add, remove, move}) => {
                                              return(
                                                <>
                                                  {fields.map((field, i) => (
                                                    <div key={i}>

                                                      <Space direction="horizontal" align="start">

                                                        <Space size={2} direction="vertical">

                                                          <Form.Item
                                                            {...field}
                                                            name={[field.name, 'medication']}
                                                            label={t('care_guide_med_table_field_input_medication')}
                                                            fieldKey={[field.fieldKey, 'medication']}
                                                            rules={[
                                                              {required: true, message: t('feedback_validation_required')},
                                                            ]}
                                                            {...layout.main}
                                                          >
                                                            <Input />
                                                          </Form.Item>

                                                          <Form.Item
                                                            {...field}
                                                            name={[field.name, 'dose']}
                                                            label={t('care_guide_med_table_field_input_dose')}
                                                            fieldKey={[field.fieldKey, 'dose']}
                                                            rules={[]}
                                                            {...layout.main}
                                                          >
                                                            <Input />
                                                          </Form.Item>

                                                          <Form.Item
                                                            {...field}
                                                            name={[field.name, 'time']}
                                                            label={t('care_guide_med_table_field_input_time')}
                                                            fieldKey={[field.fieldKey, 'time']}
                                                            rules={[]}
                                                            {...layout.main}
                                                          >
                                                            <TimeInput
                                                              emptyOption={t('As needed')}
                                                            />
                                                          </Form.Item>

                                                          <Form.Item
                                                            {...field}
                                                            name={[field.name, 'notes']}
                                                            label={t('care_guide_med_table_field_input_notes')}
                                                            fieldKey={[field.fieldKey, 'notes']}
                                                            rules={[]}
                                                            {...layout.main}
                                                          >
                                                            <Input.TextArea />
                                                          </Form.Item>

                                                        </Space>

                                                        <Button
                                                          danger
                                                          shape="circle"
                                                          size="small"
                                                          icon={<MinusOutlined />}
                                                          onClick={() => {
                                                            remove(field.name);
                                                          }}
                                                        />
                                                      </Space>
                                                    </div>
                                                  ))}

                                                  <Form.Item>
                                                    <Button
                                                      type="dashed"
                                                      onClick={() => {
                                                        add();
                                                      }}
                                                      block
                                                    >
                                                      <PlusOutlined /> Add row
                                                    </Button>
                                                  </Form.Item>
                                                </>
                                              );
                                            }}

                                          </Form.List>
                                        </div>

                                      </Space>

                                      <Button
                                        danger
                                        shape="circle"
                                        size="small"
                                        icon={<MinusOutlined />}
                                        onClick={() => {
                                          remove(group.name);
                                        }}
                                      />
                                    </Space>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>

                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => {
                        add();
                      }}
                      block
                    >
                      <PlusOutlined /> Add group
                    </Button>
                  </Form.Item>
                </>
              );
            }}

            </Form.List>
        </div>

      </>
    }</Translation>
  )
}

export default FieldTypeMedTableEditor;
