import {connect} from 'react-redux';

import CareGuideInvitationEditScreen from '../components/CareGuideInvitationEditScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.careGuideInvitations.get('isLoading'),
    success: state.careGuideInvitations.getIn(['form', 'success']),
  }
}

const CareGuideInvitationEditScreenContainer = connect(
  mapStateToProps
)(CareGuideInvitationEditScreen);

export default CareGuideInvitationEditScreenContainer;
