import { connect } from 'react-redux';
import { List } from 'immutable';
import {
  loadDashboardSection,
  updateDashboardSection,
  createDashboardSection,
  deleteDashboardSection,
  dashboardSectionsFormDestroy,
} from '../../../../../state/modules/dashboardSections/actions';
import DashboardSectionForm from '../components/DashboardSectionForm';

const inputs = List(['partnership', 'type', 'order']);

const mapStateToProps = (state, ownProps) => {
  const data = {},
    errors = {};
  for (const val of inputs.values()) {
    data[val] = state.entities.getIn(
      ['dashboardSections', ownProps.id, val],
      ''
    );
    if (['partnership'].includes(val)) {
      errors[val] = state.dashboardSections.getIn([
        'form',
        'errors',
        val + '_id',
      ]);
    } else {
      errors[val] = state.dashboardSections.getIn(['form', 'errors', val]);
    }
  }

  return {
    isLoading: state.dashboardSections.get('isLoading'),
    isSubmitting: state.dashboardSections.getIn(['form', 'isSubmitting']),
    success: state.dashboardSections.getIn(['form', 'success']),
    created_id: state.dashboardSections.getIn(['form', 'created_id']),
    data: data,
    errors: errors,
    createdAt: state.entities.getIn(
      ['dashboardSections', ownProps.id, 'created_at'],
      null
    ),
    updatedAt: state.entities.getIn(
      ['dashboardSections', ownProps.id, 'updated_at'],
      null
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (id, cb) => {
      dispatch(loadDashboardSection(id, cb));
    },
    update: (id, data, cb) => {
      dispatch(updateDashboardSection(id, data, cb));
    },
    create: (data, cb) => {
      dispatch(createDashboardSection(data, cb));
    },
    delete: (id, cb) => {
      dispatch(deleteDashboardSection(id, cb));
    },
    destroyForm: (formState) => {
      dispatch(dashboardSectionsFormDestroy(formState));
    },
  };
};

const DashboardSectionFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardSectionForm);

export default DashboardSectionFormContainer;
