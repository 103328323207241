import {connect} from 'react-redux';

import JobsScreen from '../components/JobsScreen';

const mapStateToProps = state => {
  return {
    total: state.imports.get('total'),
    isLoading: state.imports.get('isLoading')
  }
}

const JobsScreenContainer = connect(
  mapStateToProps
)(JobsScreen);

export default JobsScreenContainer;
