import {connect} from 'react-redux';

import ResourceEditScreen from '../components/ResourceEditScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.resources.get('isLoading'),
    success: state.resources.getIn(['form', 'success']),
  }
}

const ResourceEditScreenContainer = connect(
  mapStateToProps
)(ResourceEditScreen);

export default ResourceEditScreenContainer;
