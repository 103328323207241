import {connect} from 'react-redux';

import {loadRelationships} from '../../../../../state/modules/relationships/actions';
import RelationshipInput from '../components/RelationshipInput';

const mapStateToProps = (state) => {
  const relationships = state.entities.get('relationships', {});
  return {
    options: Object.keys(relationships).map(id => {return {label: relationships[id].name, value: id}}),
    isLoading: state.relationships.get('isLoading'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (page, limit, order, filter, cb) => {
      dispatch(loadRelationships(page, limit, order, filter, cb));
    },
  }
}

const RelationshipInputContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RelationshipInput);

export default RelationshipInputContainer;
