import { connect } from 'react-redux';
import { List } from 'immutable';
import moment from 'moment';
import {
  loadCareGuideNote,
  updateCareGuideNote,
  createCareGuideNote,
  deleteCareGuideNote,
  careGuideNoteFormDestroy,
} from '../../../../../state/modules/careGuideNotes/actions';
import CareGuideNoteForm from '../components/CareGuideNoteForm';
import Config from '../../../../../Config';

const inputs = List([
  'body',
  'is_pinned',
  'edited_at',
  'pinned_at',
  'care_guide',
  'profile',
  'status',
]);

const mapStateToProps = (state, ownProps) => {
  const data = {},
    errors = {};
  for (const val of inputs.values()) {
    data[val] = state.entities.getIn(['careGuideNotes', ownProps.id, val], '');

    if (['profile'].includes(val)) {
      errors[val] = state.careGuideNotes.getIn([
        'form',
        'errors',
        'user_profile_id',
      ]);
    } else if (['care_guide'].includes(val)) {
      errors[val] = state.careGuideNotes.getIn(['form', 'errors', val + '_id']);
    } else {
      errors[val] = state.careGuideNotes.getIn(['form', 'errors', val]);
    }
  }

  // change select number values to strings to work with ant design select inputs
  Object.keys(data).forEach((x) => {
    if (['status'].includes(x)) {
      data[x] = data[x].toString();
    }
  });

  data['edited_at'] = data['edited_at']
    ? moment(data['edited_at'], Config.get('API_DATETIME_FORMAT'))
    : moment();

  data['pinned_at'] = data['pinned_at']
    ? moment(data['pinned_at'], Config.get('API_DATETIME_FORMAT'))
    : null;

  return {
    isLoading: state.careGuideNotes.get('isLoading'),
    isSubmitting: state.careGuideNotes.getIn(['form', 'isSubmitting']),
    success: state.careGuideNotes.getIn(['form', 'success']),
    created_id: state.careGuideNotes.getIn(['form', 'created_id']),
    data: data,
    errors: errors,
    createdAt: state.entities.getIn(
      ['careGuideNotes', ownProps.id, 'created_at'],
      null
    ),
    updatedAt: state.entities.getIn(
      ['careGuideNotes', ownProps.id, 'updated_at'],
      null
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (id, cb) => {
      dispatch(loadCareGuideNote(id, cb));
    },
    update: (id, data, cb) => {
      dispatch(updateCareGuideNote(id, data, cb));
    },
    create: (data, cb) => {
      dispatch(createCareGuideNote(data, cb));
    },
    delete: (id, cb) => {
      dispatch(deleteCareGuideNote(id, cb));
    },
    destroyForm: (formState) => {
      dispatch(careGuideNoteFormDestroy(formState));
    },
  };
};

const CareGuideNoteFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CareGuideNoteForm);

export default CareGuideNoteFormContainer;
