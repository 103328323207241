import { connect } from 'react-redux';
import { downloadCareGuideHelpersCSV } from '../../../../../state/modules/careGuideHelpers/actions';

import CareGuideHelpersScreen from '../components/CareGuideHelpersScreen';

const mapStateToProps = (state) => {
  return {
    total: state.careGuideHelpers.get('total'),
    isLoading: state.careGuideHelpers.get('isLoading'),
    isDownloading: state.careGuideHelpers.get('isCSVDownloading'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    download: (order, filter, cb) => {
      dispatch(downloadCareGuideHelpersCSV(order, filter, cb));
    },
  };
};

const CareGuideHelpersScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CareGuideHelpersScreen);

export default CareGuideHelpersScreenContainer;
