import {connect} from 'react-redux';

import CareGuideHelperEditScreen from '../components/CareGuideHelperEditScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.careGuideHelpers.get('isLoading'),
    success: state.careGuideHelpers.getIn(['form', 'success']),
  }
}

const CareGuideHelperEditScreenContainer = connect(
  mapStateToProps
)(CareGuideHelperEditScreen);

export default CareGuideHelperEditScreenContainer;
