import {connect} from 'react-redux';

import RegistrationCodeAddScreen from '../components/RegistrationCodeAddScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.registrationCodes.get('isLoading'),
    success: state.registrationCodes.getIn(['form', 'success']),
  }
}

const RegistrationCodeAddScreenContainer = connect(
  mapStateToProps
)(RegistrationCodeAddScreen);

export default RegistrationCodeAddScreenContainer;
