import React from 'react';
import {Translation} from 'react-i18next';
import {Form, Select} from 'antd';

const PriorityInput =({name, label, fieldKey=null, rules=null, disabled=false, ...props}) => {

  const options = [
    {
      value: 0,
      label: "email_priority_now",
    },
    {
      value: 1,
      label: "email_priority_high",
    },
    {
      value: 2,
      label: "email_priority_medium",
    },
    {
      value: 3,
      label: "email_priority_low",
    },

  ];

  return (
    <Translation>{(t) => 
      <div className="form-group">
        <Form.Item
          name={name || 'priority'}
          fieldKey={fieldKey}
          label={label || t('email_priority')}
          rules={rules}
        >
          <Select disabled={disabled}>
            {options.map(x => <Select.Option key={x.value} value={x.value}>{t(x.label)}</Select.Option>)}
          </Select>
        </Form.Item>
      </div>
    }</Translation>
  );
}

export default PriorityInput;
