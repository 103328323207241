import {connect} from 'react-redux';
import {List} from 'immutable';
import moment from 'moment';
import {denormalize} from 'normalizr';

import {schema} from '../../../../../../state/schema';
import {
  loadRegion,
  updateRegion,
  createRegion,
  deleteRegion,
  regionFormDestroy
} from '../../../../../../state/modules/locationRegions/actions';
import {sendMessage} from '../../../../../../state/actions';
import RegionForm from '../components/RegionForm';
import Config from '../../../../../../Config';

const inputs = List([
  'name',
  'code_2',
  'country',
  'status'
]);

const mapStateToProps = (state, ownProps) => {

  const region = denormalize(
    state.entities.getIn(['regions', ownProps.id]),
    schema.region,
    state.entities.toJS()
  );

  const data = {}, errors = {};
  for (const val of inputs.values()) {
    data[val] = state.entities.getIn(['regions', ownProps.id, val], '');
    errors[val] = state.locationRegions.getIn(['form', 'errors', val]);
  }
  data['country'] = region && region.country ? region.country.id.toString() : null;
  Object.keys(data).forEach(x => {
    if (['status', 'rating'].includes(x)) {
      data[x] = data[x].toString();
    }
  });

  data['published_at'] = data['published_at']
    ? moment(data['published_at'], Config.get('API_DATETIME_FORMAT'))
    : moment();

  data['edited_at'] = data['edited_at']
    ? moment(data['edited_at'], Config.get('API_DATETIME_FORMAT'))
    : null;

  if (data['tags'] === '') {
    data['tags'] = [];
  }

  if (data['media'] === '') {
    data['media'] = [];
  }

  return {
    isLoading: state.locationRegions.get('areRegionsLoading'),
    isSubmitting: state.locationRegions.getIn(['form', 'isSubmitting']),
    success: state.locationRegions.getIn(['form', 'success']),
    created_id: state.locationRegions.getIn(['form', 'created_id']),
    data: data,
    errors: errors,
    createdAt: state.entities.getIn(['regions', ownProps.id, 'created_at'], null),
    updatedAt: state.entities.getIn(['regions', ownProps.id, 'updated_at'], null),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (id, cb) => {
      dispatch(loadRegion(id, cb));
    },
    update: (id, data, cb) => {
      dispatch(updateRegion(id, data, cb));
    },
    create: (data, cb) => {
      dispatch(createRegion(data, cb));
    },
    delete: (id, cb) => {
      dispatch(deleteRegion(id, cb))
    },
    destroyForm: (formState) => {
      dispatch(regionFormDestroy(formState));
    },
    sendMessage: (level, title, body, expires) => {
      dispatch(sendMessage(level, title, body, expires));
    },
  }
}

const RegionFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RegionForm);

export default RegionFormContainer;
