import { getI18n } from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import PartnershipFormAnswersScreen from './containers/PartnershipFormAnswersScreenContainer';
import PartnershipFormAnswerViewScreen from './containers/PartnershipFormAnswerViewScreenContainer';

const register = () => {
  Logger.log('debug', `partnershipFormAnswers.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'partnershipFormAnswers', 'routes'])) {
    Events.dispatch('ADD_MAIN_ROUTES', {
      PartnershipFormAnswerViewScreen: [
        'PrivateRoute',
        '/partnership-form-answers/view/:id([\\w\\-]+)',
        true,
        PartnershipFormAnswerViewScreen,
        getI18n().t('partnership_form_answers_route_view'),
      ],
      PartnershipFormAnswersScreen: [
        'PrivateRoute',
        '/partnership-form-answers/:page(\\d+)?',
        true,
        PartnershipFormAnswersScreen,
        getI18n().t('partnership_form_answers_route_list'),
      ],
    });
  }
};

export default register;

Logger.log('silly', `partnershipFormAnswers.register() loaded.`);
