import {connect} from 'react-redux';

import CareGuidesScreen from '../components/CareGuidesScreen';

const mapStateToProps = state => {
  return {
    total: state.careGuides.get('total'),
    isLoading: state.careGuides.get('isLoading')
  }
}

const CareGuidesScreenContainer = connect(
  mapStateToProps
)(CareGuidesScreen);

export default CareGuidesScreenContainer;
