import { Map, List } from "immutable";

import {
  PLAN_LIST_REQUEST,
  PLAN_LIST_SUCCESS,
  PLAN_LIST_FAILURE,
  PLAN_READ_REQUEST,
  PLAN_READ_SUCCESS,
  PLAN_READ_FAILURE,
  PLAN_UPDATE_REQUEST,
  PLAN_UPDATE_SUCCESS,
  PLAN_UPDATE_FAILURE,
  PLAN_CREATE_REQUEST,
  PLAN_CREATE_SUCCESS,
  PLAN_CREATE_FAILURE,
  PLAN_DELETE_REQUEST,
  PLAN_DELETE_SUCCESS,
  PLAN_DELETE_FAILURE,
  PLAN_FORM_DESTROY,
  PLAN_CSV_DOWNLOAD_REQUEST,
  PLAN_CSV_DOWNLOAD_SUCCESS,
  PLAN_CSV_DOWNLOAD_FAILURE,
} from "./actions";
import Logger from "../../../lib/Logger";

export default function plans(
  state = Map({
    isLoading: false,
    isCSVDownloading: false,
    pages: {},
  }),
  action
) {
  Logger.log("debug", `[plans.reducers] plans(%j, %j)`, state, action);

  switch (action.type) {
    case PLAN_LIST_REQUEST:
      return state.mergeDeep({
        isLoading: true,
      });

    case PLAN_LIST_SUCCESS:
      return state
        .mergeDeep({
          isLoading: false,
          pages: {
            [action.order]: {
              [action.limit]: {
                [action.page]: null,
              },
            },
          },
          total: action.total,
          lastUpdated: action.receivedAt,
        })
        .setIn(
          ["pages", action.order, action.limit, action.page],
          List(action.result)
        );

    case PLAN_LIST_FAILURE:
      return state.mergeDeep({
        isLoading: false,
      });

    case PLAN_READ_REQUEST:
      return state.mergeDeep({
        isLoading: true,
      });

    case PLAN_READ_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        lastUpdated: action.receivedAt,
      });

    case PLAN_READ_FAILURE:
      return state.mergeDeep({
        isLoading: false,
      });

    case PLAN_UPDATE_REQUEST:
      return state.mergeDeep({
        isLoading: true,
        form: {
          isSubmitting: true,
          success: null,
          errors: null,
        },
      });

    case PLAN_UPDATE_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        form: {
          isSubmitting: false,
          success: true,
          errors: null,
        },
        lastUpdated: action.receivedAt,
      });

    case PLAN_UPDATE_FAILURE:
      return state
        .mergeDeep({
          isLoading: false,
          form: {
            isSubmitting: false,
            success: false,
            errors: null,
          },
        })
        .setIn(["form", "errors"], action.error);

    case PLAN_CREATE_REQUEST:
      return state.mergeDeep({
        isLoading: true,
        form: {
          isSubmitting: true,
          success: null,
          errors: null,
        },
      });

    case PLAN_CREATE_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        form: {
          isSubmitting: false,
          success: true,
          created_id: parseInt(action.id),
          errors: null,
        },
        lastUpdated: action.receivedAt,
      });

    case PLAN_CREATE_FAILURE:
      return state
        .mergeDeep({
          isLoading: false,
          form: {
            isSubmitting: false,
            success: false,
            errors: null,
          },
        })
        .setIn(["form", "errors"], action.error);

    case PLAN_DELETE_REQUEST:
      return state.mergeDeep({
        isLoading: true,
      });

    case PLAN_DELETE_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        lastUpdated: action.receivedAt,
      });

    case PLAN_DELETE_FAILURE:
      return state.mergeDeep({
        isLoading: false,
      });

    case PLAN_FORM_DESTROY:
      return state
        .mergeDeep({
          form: null,
        })
        .set("form", action.form);

    case PLAN_CSV_DOWNLOAD_REQUEST:
      return state.mergeDeep({
        isCSVDownloading: true,
      });

    case PLAN_CSV_DOWNLOAD_SUCCESS:
      return state.mergeDeep({
        isCSVDownloading: false,
      });

    case PLAN_CSV_DOWNLOAD_FAILURE:
      return state.mergeDeep({
        isCSVDownloading: false,
      });

    default:
      return state;
  }
}

Logger.log("silly", `plans.reducers loaded.`);
