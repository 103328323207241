import {connect} from 'react-redux';
import {List} from 'immutable';

import {
  loadCareGuidePartnerTag,
  updateCareGuidePartnerTag,
  createCareGuidePartnerTag,
  deleteCareGuidePartnerTag,
  careGuidePartnerTagFormDestroy
} from '../../../../../state/modules/careGuidePartnerTags/actions';
import {sendMessage} from '../../../../../state/actions';
import CareGuidePartnerTagForm from '../components/CareGuidePartnerTagForm';

const inputs = List([
  'label',
  'status',
  'color_hex',
  'admin_partner_class'
]);

const mapStateToProps = (state, ownProps) => {

  const data = {}, errors = {};
  for (const val of inputs.values()) {
    data[val] = state.entities.getIn(['careGuidePartnerTags', ownProps.id, val], '');
    errors[val] = state.careGuidePartnerTags.getIn(['form', 'errors', val]);
  }

  // change select number values to strings to work with ant design select inputs
  Object.keys(data).forEach(x => {
    if (['status'].includes(x)) {
      data[x] = data[x].toString();
    }
  });

  return {
    isLoading: state.careGuidePartnerTags.get('isLoading'),
    isSubmitting: state.careGuidePartnerTags.getIn(['form', 'isSubmitting']),
    success: state.careGuidePartnerTags.getIn(['form', 'success']),
    created_id: state.careGuidePartnerTags.getIn(['form', 'created_id']),
    data: data,
    errors: errors,
    createdAt: state.entities.getIn(['careGuidePartnerTags', ownProps.id, 'created_at'], null),
    updatedAt: state.entities.getIn(['careGuidePartnerTags', ownProps.id, 'updated_at'], null),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (id, cb) => {
      dispatch(loadCareGuidePartnerTag(id, cb));
    },
    update: (id, data, cb) => {
      dispatch(updateCareGuidePartnerTag(id, data, cb));
    },
    create: (data, cb) => {
      dispatch(createCareGuidePartnerTag(data, cb));
    },
    delete: (id, cb) => {
      dispatch(deleteCareGuidePartnerTag(id, cb))
    },
    destroyForm: (formState) => {
      dispatch(careGuidePartnerTagFormDestroy(formState));
    },
    sendMessage: (level, title, body, expires) => {
      dispatch(sendMessage(level, title, body, expires));
    },
  }
}

const CareGuidePartnerTagFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CareGuidePartnerTagForm);

export default CareGuidePartnerTagFormContainer;