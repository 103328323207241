import {getI18n} from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import ResourcesScreen from './containers/ResourcesScreenContainer';
import ResourceAddScreen from './containers/ResourceAddScreenContainer';
import ResourceEditScreen from './containers/ResourceEditScreenContainer';

const register = () => {
  Logger.log('debug', `resources.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'resources', 'routes'])) {
    Events.dispatch('ADD_MAIN_ROUTES', 
      {
        'ResourceAddScreen': ['PrivateRoute', '/resources/add', true, ResourceAddScreen, getI18n().t('resources_route_add')],
        'ResourceEditScreen': ['PrivateRoute', '/resources/edit/:id([\\w\\-]+)', true, ResourceEditScreen, getI18n().t('resources_route_edit')],
        'ResourcesScreen': ['PrivateRoute', '/resources/:page(\\d+)?', true, ResourcesScreen, getI18n().t('resources_route_list')],
      }
    );
  }
}

export default register;

Logger.log('silly', `resources.register() loaded.`);
