import {connect} from 'react-redux';

import PartnershipEditScreen from '../components/PartnershipEditScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.partnerships.get('isLoading'),
    success: state.partnerships.getIn(['form', 'success']),
  }
}

const PartnershipEditScreenContainer = connect(
  mapStateToProps
)(PartnershipEditScreen);

export default PartnershipEditScreenContainer;
