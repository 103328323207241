import {connect} from 'react-redux';

import JournalPartnerTagEditScreen from '../components/JournalPartnerTagEditScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.journalPartnerTags.get('isLoading'),
    success: state.journalPartnerTags.getIn(['form', 'success']),
  }
}

const JournalPartnerTagEditScreenContainer = connect(
  mapStateToProps
)(JournalPartnerTagEditScreen);

export default JournalPartnerTagEditScreenContainer;