import React, {useState, useEffect} from 'react';
import {Redirect} from 'react-router-dom'
import {Translation, getI18n} from 'react-i18next';
import {Affix, Card, Checkbox, Col, Form, Input, InputNumber, Row, Spin} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';

import TemplateInput from '../../careGuideTemplates/containers/TemplateInputContainer';
import message from '../../../elements/lib/MessageWrapper';
import FormMetadata from '../../../elements/components/FormMetadata';
import {pathTo} from '../../../Routes';
import Logger from '../../../../../lib/Logger';


const PlanForm = ({id, data, errors, load, destroyForm, isLoading, isSubmitting, created_id, ...props}) => {

  const [redirectTo, setRedirectTo] = useState(null);
  const [form] = Form.useForm();

  // form column settings
  const layout = {
    main: {
      labelCol: {span: 5},
      wrapperCol: {span: 19},
    },
  }

  const accessFlagOptions = ['none', 'active', 'pending', 'canceled', 'failed'];

  // load record data from API
  useEffect(() => {
    if (id) {
      load(id);
    }
  }, [id, load]);

  // update input values when new data is available
  const dataString = JSON.stringify(data);
  useEffect(() => {
    if (id && !isSubmitting) {
      const dataObj = JSON.parse(dataString);
      form.setFieldsValue(dataObj);
    }
  }, [form, dataString, isSubmitting, id]);

  // handle errors reported by API
  useEffect(() => {
    let firstFieldName = '';
    for (const field in errors) {
      form.setFields([{name: field, errors: errors[field]}]);
      if (firstFieldName === '') {
        firstFieldName = field;
      }
    }
    form.scrollToField(firstFieldName);
  }, [form, errors]);

  // redirect add form to edit form on successful create action
  useEffect(() => {
    if (created_id) {
      setRedirectTo(pathTo('PlanEditScreen', {id: created_id}));
    }
    return () => {
      destroyForm();
    }
  }, [created_id, setRedirectTo, destroyForm]);

  // submit data handler
  const submitData = async (values) => {
    Logger.log('debug', `PlanForm.submitData(###)`);

    // API POST/PUT payload
    let payload = {};
    for (const input of Object.keys(data)) {

      // transform boolean values
      if (['is_public', 'is_default', 'is_partner_plan'].includes(input)) {
        payload[input] = values[input] ? true : false;
      }
      
      // transform entity refs to IDs
      else if (['template'].includes(input)) {
        payload[input + '_id'] = values[input];
      }

      // flags
      else if (['access_flags'].includes(input)) {
        if (values[input]) {
          const flags = {};
          for (const flag of accessFlagOptions) {
            flags[flag] = values[input].includes(flag);
          }
          payload[input] = flags;
        }
      }

      // handle values that can be null
      else if (['price_month', 'price_year', 'month_trial_period', 'year_trial_period'].includes(input)) {
        payload[input] = values[input] ? values[input] : null;
      }
      
      else if (input in values) {
        payload[input] = values[input];
      }
    }

    if (id) { // update
      props.update(id, payload, (success) => {
        if (success) {
          message.success(getI18n().t('feedback_form_success'));
        } else {
          message.error(getI18n().t('feedback_form_error'));
        }
      });      
    } else { // create
      props.create(payload, (success) => {
        if (success) {
          message.success(getI18n().t('feedback_form_success'));
        } else {
          message.error(getI18n().t('feedback_form_error'));
        }
      });      
    }
  }

  // form submit handler
  const handleFinish = async (values) => {
    Logger.log('debug', `PlanForm.handleFinish(###)`);
    if (!props.isSubmitting) {
      await submitData(values);
    }
  }

  // form error handler
  const handleFinishFailed = ({values, errorFields, outOfDate}) => {
    Logger.log('debug', `PlanForm.handleFinishFailed(###)`);
    message.error(getI18n().t('feedback_form_error'));
    if (errorFields && errorFields.length > 0) {
      form.scrollToField(errorFields[0].name);
    }
  }

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <Translation>{(t) => 
      <>
        <div className="plan-form">
          <Form
            name="plan_form"
            form={form}
            initialValues={data}
            onFinish={handleFinish}
            onFinishFailed={handleFinishFailed}
            validateTrigger="onSubmit"
            {...layout.main}
          >
            <Row gutter={16}>

              <Col xs={24} lg={18}>
                <Card
                  title={id ? t('plans_edit_title') : t('plans_add_title')}
                  extra={isLoading
                    ? <Spin indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} />
                    : null}
                >

                  <div className="form-group">
                    <Form.Item
                      name="name"
                      label={t('plans_name')}
                      rules={[
                        {required: true, message: t('feedback_validation_required')},
                        {type: 'string', min: 2, max: 60, message: t('feedback_validation_char_range', {min: 2, max: 60})},
                      ]}
                    >
                      <Input autoFocus disabled={isLoading || isSubmitting} />
                    </Form.Item>
                  </div>

                  <div className="form-group">
                    <Form.Item
                      name="description"
                      label={t('plans_description')}
                      rules={null}
                    >
                      <Input.TextArea rows={5} disabled={isLoading || isSubmitting} />
                    </Form.Item>
                  </div>
                  <div className="form-group">
                    <Form.Item
                      name="order"
                      label={t('plans_order')}
                      rules={[
                        {required: true, message: t('feedback_validation_required')},
                        {type: 'number', message: t('feedback_validation_number')},
                        {type: 'number', min: 1, max: 32767, message: t('feedback_validation_range', {min: 1, max: 32767})},
                      ]}
                    >
                      <InputNumber min={1} max={32767} disabled={isLoading || isSubmitting} />
                    </Form.Item>
                  </div>
                  <div className="form-group">
                    <Form.Item
                      name="is_public"
                      label={t('plans_is_public')}
                      valuePropName="checked"
                    >
                      <Checkbox disabled={isLoading || isSubmitting}>{t('plans_is_public_hint')}</Checkbox> 
                    </Form.Item>
                  </div>

                  <div className="form-group">
                    <Form.Item
                      name="is_default"
                      label={t('plans_is_default')}
                      valuePropName="checked"
                    >
                      <Checkbox disabled={isLoading || isSubmitting}>{t('plans_is_default_hint')}</Checkbox>
                    </Form.Item>
                  </div>

                  <div className="form-group">
                    <Form.Item
                      name="is_partner_plan"
                      label={t('plans_is_partner_plan')}
                      valuePropName="checked"
                    >
                      <Checkbox disabled={isLoading || isSubmitting}>{t('plans_is_partner_plan_hint')}</Checkbox>
                    </Form.Item>
                  </div>

                  <div className="form-group checkbox-list">
                    <Form.Item
                      name="access_flags"
                      label={t('plans_access_flags')}
                      extra={t('plans_access_flags_hint')}
                    >
                      <Checkbox.Group options={accessFlagOptions.map(x => {return {value: x, label: t('plans_access_flag_' + x)}})} />
                    </Form.Item>
                  </div>

                  <div className="form-group">
                    <Form.Item label={t('plans_care_guide_limit')}>
                      <Form.Item
                        name="care_guide_limit"
                        rules={[
                          {required: true, message: t('feedback_validation_required')},
                          {type: 'number', message: t('feedback_validation_number')},
                          {type: 'number', min: -1, max: 2147483647, message: t('feedback_validation_range', {min: -1, max: 2147483647})},
                        ]}
                        noStyle
                      >
                        <InputNumber
                          min={-1}
                          max={2147483647}
                          step={1}
                          formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={value => value.replace(/\$\s?|(,*)/g, '')}
                          disabled={isLoading || isSubmitting}
                        />
                      </Form.Item>
                      <span className="ant-form-text">{t('unit_care_guides')}</span>
                    </Form.Item>
                  </div>

                  <div className="form-group">
                    <Form.Item label={t('plans_helper_limit')}>
                      <Form.Item
                        name="helper_limit"
                        rules={[
                          {required: true, message: t('feedback_validation_required')},
                          {type: 'number', message: t('feedback_validation_number')},
                          {type: 'number', min: -1, max: 2147483647, message: t('feedback_validation_range', {min: -1, max: 2147483647})},
                        ]}
                        noStyle
                      >
                        <InputNumber
                          min={-1}
                          max={2147483647}
                          step={1}
                          formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={value => value.replace(/\$\s?|(,*)/g, '')}
                          disabled={isLoading || isSubmitting}
                        />
                      </Form.Item>
                      <span className="ant-form-text">{t('unit_users')}</span>
                    </Form.Item>
                  </div>

                  <div className="form-group">
                    <Form.Item label={t('plans_upload_gb_limit')}>
                      <Form.Item
                        name="upload_gb_limit"
                        rules={[
                          {required: true, message: t('feedback_validation_required')},
                          {type: 'number', message: t('feedback_validation_number')},
                          {type: 'number', min: -1, max: 2147483647, message: t('feedback_validation_range', {min: -1, max: 2147483647})},
                        ]}
                        noStyle
                      >
                        <InputNumber
                          min={-1}
                          max={2147483647}
                          step={1}
                          formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={value => value.replace(/\$\s?|(,*)/g, '')}
                          disabled={isLoading || isSubmitting}
                        />
                      </Form.Item>
                      <span className="ant-form-text">{t('unit_gb')}</span>
                    </Form.Item>
                  </div>

                  <div className="form-group">
                    <Form.Item label={t('plans_limit_to_admin')}>
                      <Form.Item
                        name="limit_to_admin"
                        rules={[
                          {required: true, message: t('feedback_validation_required')},
                          {type: 'number', message: t('feedback_validation_number')},
                          {type: 'number', min: -1, max: 2147483647, message: t('feedback_validation_range', {min: -1, max: 2147483647})},
                        ]}
                        noStyle
                      >
                        <InputNumber
                          min={-1}
                          max={2147483647}
                          step={1}
                          formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={value => value.replace(/\$\s?|(,*)/g, '')}
                          disabled={isLoading || isSubmitting}
                        />
                      </Form.Item>
                      <span className="ant-form-text">{t('plans_limit_to_admin_users')}</span>
                    </Form.Item>
                  </div>

                  <div className="form-group">
                    <Form.Item label={t('plans_limit_to_client')}>
                      <Form.Item
                        name="limit_to_client"
                        rules={[
                          {required: true, message: t('feedback_validation_required')},
                          {type: 'number', message: t('feedback_validation_number')},
                          {type: 'number', min: -1, max: 2147483647, message: t('feedback_validation_range', {min: -1, max: 2147483647})},
                        ]}
                        noStyle
                      >
                        <InputNumber
                          min={-1}
                          max={2147483647}
                          step={1}
                          formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={value => value.replace(/\$\s?|(,*)/g, '')}
                          disabled={isLoading || isSubmitting}
                        />
                      </Form.Item>
                      <span className="ant-form-text">{t('plans_limit_to_client_users')}</span>
                    </Form.Item>
                  </div>

                  <TemplateInput
                    name="template"
                    label={t('plans_template')}
                    disabled={isLoading || isSubmitting}
                  />

                  <div className="form-group">
                    <Form.Item
                      name="consent_url"
                      label={t('plans_consent_url')}
                      extra={t('plans_consent_url_hint')}
                      rules={[
                        {type: 'string', min: 0, max: 255, message: t('feedback_validation_char_range', {min: 0, max: 255})},
                      ]}
                    >
                      <Input disabled={isLoading || isSubmitting} />
                    </Form.Item>
                  </div>

                  <div className="form-group">
                    <Form.Item
                      name="product_id"
                      label={t('plans_product_id')}
                      extra={t('stripe_data_source')}
                      rules={[
                        {type: 'string', min: 0, max: 30, message: t('feedback_validation_char_range', {min: 2, max: 30})},
                      ]}
                    >
                      <Input autoFocus disabled={isLoading || isSubmitting} />
                    </Form.Item>
                  </div>

                </Card>

                <Row gutter={16}>

                  <Col xs={24} xxl={12}>
                    <Card
                      title={t('plans_month_title')}
                      extra={isLoading
                        ? <Spin indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} />
                        : null}
                    >

                      <div className="form-group">
                        <Form.Item
                          name="price_month"
                          label={t('plans_price_month')}
                          extra={t('plans_price_month_hint')}
                          rules={[
                            {type: 'number', message: t('feedback_validation_number')},
                            {type: 'number', min: 0, max: 2097151, message: t('feedback_validation_range', {min: 0, max: 2097151})},
                          ]}
                        >
                          <InputNumber
                            min={0}
                            max={2097151}
                            step={0.01}
                            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                            disabled={isLoading || isSubmitting}
                          />
                        </Form.Item>
                      </div>

                      <div className="form-group">
                        <Form.Item label={t('plans_month_trial_period')} extra={t('plans_month_trial_period_hint')}>
                          <Form.Item
                            name="month_trial_period"
                            rules={[
                              {type: 'number', min: 0, max: 365, message: t('feedback_validation_range', {min: 1, max: 365})},
                            ]}
                            noStyle
                          >
                            <InputNumber min={0} max={365} disabled={isLoading || isSubmitting} />
                          </Form.Item>
                          <span className="ant-form-text">{t('unit_days')}</span>
                        </Form.Item>
                      </div>

                      <div className="form-group">
                        <Form.Item
                          name="month_price_id"
                          label={t('plans_month_price_id')}
                          extra={t('stripe_data_source')}
                          rules={[
                            {type: 'string', min: 0, max: 40, message: t('feedback_validation_char_range', {min: 2, max: 40})},
                          ]}
                        >
                          <Input autoFocus disabled={isLoading || isSubmitting} />
                        </Form.Item>
                      </div>

                    </Card>
                  </Col>

                  <Col xs={24} xxl={12}>
                    <Card
                      title={t('plans_year_title')}
                      extra={isLoading
                        ? <Spin indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} />
                        : null}
                    >

                      <div className="form-group">
                        <Form.Item
                          name="price_year"
                          label={t('plans_price_year')}
                          extra={t('plans_price_year_hint')}
                          rules={[
                            // {required: true, message: t('feedback_validation_required')},
                            {type: 'number', message: t('feedback_validation_number')},
                            {type: 'number', min: 0, max: 2097151, message: t('feedback_validation_range', {min: 0, max: 2097151})},
                          ]}
                        >
                          <InputNumber
                            min={0}
                            max={2097151}
                            step={0.01}
                            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                            disabled={isLoading || isSubmitting}
                          />
                        </Form.Item>
                      </div>

                      <div className="form-group">
                        <Form.Item label={t('plans_year_trial_period')} extra={t('plans_year_trial_period_hint')}>
                          <Form.Item
                            name="year_trial_period"
                            rules={[
                              {type: 'number', min: 0, max: 365, message: t('feedback_validation_range', {min: 1, max: 365})},
                            ]}
                            noStyle
                          >
                            <InputNumber min={0} max={365} disabled={isLoading || isSubmitting} />
                          </Form.Item>
                          <span className="ant-form-text">{t('unit_days')}</span>
                        </Form.Item>
                      </div>

                      <div className="form-group">
                        <Form.Item
                          name="year_price_id"
                          label={t('plans_year_price_id')}
                          extra={t('stripe_data_source')}
                          rules={[
                            {type: 'string', min: 0, max: 40, message: t('feedback_validation_char_range', {min: 2, max: 40})},
                          ]}
                        >
                          <Input autoFocus disabled={isLoading || isSubmitting} />
                        </Form.Item>
                      </div>

                    </Card>
                  </Col>

                </Row>

              </Col>

              <Col xs={24} lg={6}>
                <Affix offsetTop={10}>
                  <Card title={t('form_metadata_header')}>
                    <FormMetadata
                      id={id}
                      isSubmitting={isSubmitting}
                      delete={props.delete.bind(this)}
                      deleteRedirectTo="PlansScreen"
                      createdAt={props.createdAt}
                      updatedAt={props.updatedAt}
                    />
                  </Card>
                </Affix>
              </Col>

            </Row>

          </Form>
        </div>
      </>
    }</Translation>
  );
}

export default PlanForm;

Logger.log('silly', `PlanForm loaded.`);
