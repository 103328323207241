import {connect} from 'react-redux';

import UserProfilesPartnershipEditScreen from '../components/UserProfilesPartnershipEditScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.userProfilesPartnerships.get('isLoading'),
    success: state.userProfilesPartnerships.getIn(['form', 'success']),
  }
}

const UserProfilesPartnershipEditScreenContainer = connect(
  mapStateToProps
)(UserProfilesPartnershipEditScreen);

export default UserProfilesPartnershipEditScreenContainer;
