import React from 'react';
import {Translation} from 'react-i18next';
import {Form, Select} from 'antd';


const PublicStatusInput = ({name, label, fieldKey=null, rules=null, disabled=false, ...props}) => {

  const options = [
    {
      value: 1,
      label: "calendar_event_participant_public_status_invited",
    },
    {
      value: 2,
      label: "calendar_event_participant_public_status_accepted",
    },
    {
      value: 3,
      label: "calendar_event_participant_public_status_declined",
    },
    {
      value: 4,
      label: "calendar_event_participant_public_status_maybe",
    },
  ];

  return (
    <Translation>{(t) => 
      <div className="form-group">
        <Form.Item
          name={name || 'public_status'}
          fieldKey={fieldKey}
          label={label || t('calendar_event_participant_public_status')}
          rules={rules}
        >
          <Select disabled={disabled}>
            {options.map(x => <Select.Option key={x.value} value={x.value}>{t(x.label)}</Select.Option>)}
          </Select>
        </Form.Item>
      </div>
    }</Translation>
  );
}

export default PublicStatusInput;
