import {connect} from 'react-redux';

import PartnershipAddScreen from '../components/PartnershipAddScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.partnerships.get('isLoading'),
    success: state.partnerships.getIn(['form', 'success']),
  }
}

const PartnershipAddScreenContainer = connect(
  mapStateToProps
)(PartnershipAddScreen);

export default PartnershipAddScreenContainer;
