import {connect} from 'react-redux';
import {List} from 'immutable';

import {
  loadPlan,
  updatePlan,
  createPlan,
  deletePlan,
  planFormDestroy
} from '../../../../../state/modules/plans/actions';
import {sendMessage} from '../../../../../state/actions';
import PlanForm from '../components/PlanForm';

const inputs = List([
  'name',
  'description',
  'order',
  'price_month',
  'price_year',
  'is_public',
  'is_default',
  'is_partner_plan',
  'care_guide_limit',
  'helper_limit',
  'upload_gb_limit',
  'limit_to_admin',
  'limit_to_client',
  'template',
  'product_id',
  'month_price_id',
  'year_price_id',
  'month_trial_period',
  'year_trial_period',
  'access_flags',
  'consent_url',
  'status'
]);

const mapStateToProps = (state, ownProps) => {

  const data = {}, errors = {};
  for (const val of inputs.values()) {
    data[val] = state.entities.getIn(['plans', ownProps.id, val], '');
    if (state.plans.getIn(['form', 'errors', val])) {
      errors[val] = state.plans.getIn(['form', 'errors', val]);
    }
  }

  // change select number values to strings to work with ant design select inputs
  Object.keys(data).forEach(x => {
    if (['status'].includes(x)) {
      data[x] = data[x].toString();
    }
  });

  data['access_flags'] = data['access_flags']
    ? Object.keys(data['access_flags']).filter(x => data['access_flags'][x])
    : null;

  return {
    isLoading: state.plans.get('isLoading'),
    isSubmitting: state.plans.getIn(['form', 'isSubmitting']),
    success: state.plans.getIn(['form', 'success']),
    created_id: state.plans.getIn(['form', 'created_id']),
    data: data,
    errors: errors,
    createdAt: state.entities.getIn(['plans', ownProps.id, 'created_at'], null),
    updatedAt: state.entities.getIn(['plans', ownProps.id, 'updated_at'], null),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (id, cb) => {
      dispatch(loadPlan(id, cb));
    },
    update: (id, data, cb) => {
      dispatch(updatePlan(id, data, cb));
    },
    create: (data, cb) => {
      dispatch(createPlan(data, cb));
    },
    delete: (id, cb) => {
      dispatch(deletePlan(id, cb))
    },
    destroyForm: (formState) => {
      dispatch(planFormDestroy(formState));
    },
    sendMessage: (level, title, body, expires) => {
      dispatch(sendMessage(level, title, body, expires));
    },
  }
}

const PlanFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PlanForm);

export default PlanFormContainer;
