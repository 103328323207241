import React, { Component } from 'react';
import { Translation } from 'react-i18next';

import AdminPartnerClassForm from '../containers/AdminPartnerClassFormContainer';
import DocumentHead from '../../../elements/components/DocumentHead';
import Logger from '../../../../../lib/Logger';

class AdminPartnerClassAddScreen extends Component {

  scrollToTop = () => {
    Logger.log('debug', `AdminPartnerClassAddScreen.scrollToTop()`);
    if (typeof window !== 'undefined') {
      setTimeout(() => window.scrollTo(0, 0), 100);
    }
  }

  render() {
    return (
      <Translation>{(t) =>
        <div>
          <DocumentHead title={t('admin_partner_class_route_add')} />
          <AdminPartnerClassForm />
        </div>
      }</Translation>
    )
  }

  /* LIFECYCLE EVENTS */

  componentDidMount() {
    this.scrollToTop();
  }
}

export default AdminPartnerClassAddScreen;

Logger.log('silly', `AdminPartnerClassAddScreen loaded.`);