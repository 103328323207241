import React, {Component} from 'react';
import {Translation, getI18n} from 'react-i18next';
import {Affix, Card, Col, Input, Row, Spin} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';

import DocumentHead from '../../../elements/components/DocumentHead';
import Config from '../../../../../Config';
import Format from '../../../../../lib/Format';
import Logger from '../../../../../lib/Logger';


const progressLabels = {
  1: getI18n().t("imports_jobs_progress_queued"),
  2: getI18n().t("imports_jobs_progress_processing"),
  3: getI18n().t("imports_jobs_progress_complete"),
  4: getI18n().t("imports_jobs_progress_failed"),
};

const resourceLabels = {
  1: getI18n().t("imports_jobs_resource_resource"),
};

const DisplayInput = ({label, value, textarea=false, ...props}) => (
  <div className="form-group">
    <div className="ant-row ant-form-item" style={{marginBottom: 0}}>
      <div className="ant-col ant-col-5 ant-form-item-label">
        <label htmlFor="registration_form_application" className="" title="Application">{label}</label>
      </div>
      <div className="ant-col ant-col-19 ant-form-item-control">
          {textarea
            ? <Input.TextArea value={value} bordered={false} rows={15} />
            : <Input value={value} bordered={false} />}
      </div>
    </div>
  </div>
);

class JobViewScreen extends Component {

  scrollToTop = () => {
    Logger.log('debug', `JobViewScreen.scrollToTop()`);
    if (typeof window !== 'undefined') {
      setTimeout(() => window.scrollTo(0, 0), 100);
    }
  }

  render() {
    const {job, isLoading} = this.props;
    return (
      <Translation>{(t) => 
        <div>
          <DocumentHead title={t('imports_jobs_route_view')} />
          
          <div className="imports-jobs-view">

            <Row gutter={16}>

              <Col xs={24} lg={18}>
                <Card
                  title={t('imports_jobs_view_title')}
                  extra={isLoading
                    ? <Spin indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} />
                    : null}
                >

                  <DisplayInput label={t('imports_jobs_id')} value={job ? job.id : null} />
                  <DisplayInput label={t('imports_jobs_resource')} value={job ? (job.resource in resourceLabels ? resourceLabels[job.resource] : job.resource) : null} />
                  <DisplayInput label={t('imports_jobs_progress')} value={job ? (job.progress in progressLabels ? progressLabels[job.progress] : job.progress) : null} />
                  <DisplayInput label={t('imports_jobs_upload_date')} value={job ? Format.date(job.upload_date, Config.get('DEFAULT_DATETIME_FORMAT')) : null} />
                  <DisplayInput label={t('imports_jobs_complete_date')} value={job ? Format.date(job.complete_date, Config.get('DEFAULT_DATETIME_FORMAT')) : null} />

                  <DisplayInput label={t('imports_jobs_records_processed')} value={job ? job.records_processed : null} />
                  <DisplayInput label={t('imports_jobs_records_failed')} value={job ? job.records_failed : null} />
                  
                  <DisplayInput label={t('imports_jobs_import_bucket_name')} value={job ? job.import_bucket_name : null} />
                  <DisplayInput label={t('imports_jobs_import_directory_name')} value={job ? job.import_directory_name : null} />
                  <DisplayInput label={t('imports_jobs_import_file_id')} value={job ? job.import_file_id : null} />
                  <DisplayInput label={t('imports_jobs_import_file_ext')} value={job ? job.import_file_ext : null} />

                  <DisplayInput textarea={true} label={t('imports_jobs_errors')} value={job ? job.errors : null} />

                </Card>
              </Col>

              <Col xs={24} lg={6}>
                <Affix offsetTop={10}>
                  <Card title={t('form_metadata_header')}>

                      {job && job.created_at
                        ? <p>{t('form_metadata_created_at')} <small>{Format.date(job.created_at, Config.get('DEFAULT_DATETIME_FORMAT'))}</small></p>
                        : ''}
                      {job && job.updated_at && job.updated_at !== job.created_at
                        ? <p>{t('form_metadata_updated_ad')} <small>{Format.date(job.updated_at, Config.get('DEFAULT_DATETIME_FORMAT'))}</small></p>
                        : ''}

                  </Card>
                </Affix>
              </Col>

            </Row>

          </div>

        </div>
      }</Translation>
    )
  }

  /* LIFECYCLE EVENTS */

  componentDidMount() {
    this.scrollToTop();
    this.props.load(this.props.id);
  }
}

export default JobViewScreen;

Logger.log('silly', `JobViewScreen loaded.`);
