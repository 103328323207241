import { connect } from 'react-redux';
import { downloadJournalPostsCSV } from '../../../../../state/modules/journalPosts/actions';

import JournalPostsScreen from '../components/JournalPostsScreen';

const mapStateToProps = (state) => {
  return {
    total: state.journalPosts.get('total'),
    isLoading: state.journalPosts.get('isLoading'),
    isDownloading: state.journalPosts.get('isCSVDownloading'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    download: (order, filter, cb) => {
      dispatch(downloadJournalPostsCSV(order, filter, cb));
    },
  };
};
const JournalPostsScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(JournalPostsScreen);

export default JournalPostsScreenContainer;
