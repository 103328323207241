import { connect } from 'react-redux';
import { List } from 'immutable';
import { denormalize } from 'normalizr';
import { schema } from '../../../../../state/schema';
import {
  loadCalendarEventParticipants,
  deleteCalendarEventParticipant,
  calendarEventShowEventParticipantFormDestroy,
  calendarEventHideEventParticipantForm,
  updateCalendarEventParticipant,
  createCalendarEventParticipant,
} from '../../../../../state/modules/calendarEvents/actions';
import EventParticipantFormModal from '../components/EventParticipantFormModal';

const inputs = List(['public_status', 'user_profile_id']);

const mapStateToProps = (state, ownProps) => {
  const errors = {};
  for (const val of inputs.values()) {
    if (state.calendarEvents.getIn(['eventForm', 'errors', val])) {
      errors[val] = state.calendarEvents.getIn(['eventForm', 'errors', val]);
    }
  }

  const participantId = state.calendarEvents.get(
    'eventParticipantFormParticipantId'
  );

  const participant = participantId
    ? denormalize(
        state.entities.getIn(['calendarEventParticipants', participantId]),
        schema.calendarEventParticipant,
        state.entities.toJS()
      )
    : null;

  const data = {
    public_status: participant ? participant.public_status : '',
    user_profile_id: participant
      ? participant.profile
        ? participant.profile.id
        : null
      : null,
  };

  return {
    pageParticipants: state.calendarEvents.get('pageParticipants'),
    visible: state.calendarEvents.get('eventParticipantFormIsVisible'),
    isSubmitting: state.calendarEvents.getIn(['eventForm', 'isSubmitting']),
    success: state.calendarEvents.getIn(['eventForm', 'success']),
    participantId: participantId,
    participant: participant,
    data: data,
    errors: errors,
    inputs,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    create: (eventId, data, cb) => {
      dispatch(createCalendarEventParticipant(eventId, data, cb));
    },
    update: (id, data, cb) => {
      dispatch(updateCalendarEventParticipant(id, data, cb));
    },
    delete: (calendarEventParticipantId, cb) => {
      dispatch(deleteCalendarEventParticipant(calendarEventParticipantId, cb));
    },
    loadParticipants: (calendarEventId, page, limit, cb) => {
      dispatch(loadCalendarEventParticipants(calendarEventId, page, limit, cb));
    },
    formDestroy: (formState) => {
      dispatch(calendarEventShowEventParticipantFormDestroy(formState));
    },
    hideForm: () => {
      dispatch(calendarEventHideEventParticipantForm());
    },
  };
};

const EventParticipantFormModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EventParticipantFormModal);

export default EventParticipantFormModalContainer;
