import {normalize} from 'normalizr';

import api from '../../api';
import {schema} from '../../schema';
import {addEntities} from '../../actions';
import Logger from '../../../lib/Logger';


// ACTION TYPES

export const IMPORTS_CSV_UPLOAD_URL_REQUEST = 'IMPORTS_CSV_UPLOAD_URL_REQUEST';
export const IMPORTS_CSV_UPLOAD_URL_SUCCESS = 'IMPORTS_CSV_UPLOAD_URL_SUCCESS';
export const IMPORTS_CSV_UPLOAD_URL_FAILURE = 'IMPORTS_CSV_UPLOAD_URL_FAILURE';
export const IMPORTS_CSV_UPLOAD_REQUEST = 'IMPORTS_CSV_UPLOAD_REQUEST';
export const IMPORTS_CSV_UPLOAD_SUCCESS = 'IMPORTS_CSV_UPLOAD_SUCCESS';
export const IMPORTS_CSV_UPLOAD_FAILURE = 'IMPORTS_CSV_UPLOAD_FAILURE';
export const IMPORTS_CSV_MESSAGE_REQUEST = 'IMPORTS_CSV_MESSAGE_REQUEST';
export const IMPORTS_CSV_MESSAGE_SUCCESS = 'IMPORTS_CSV_MESSAGE_SUCCESS';
export const IMPORTS_CSV_MESSAGE_FAILURE = 'IMPORTS_CSV_MESSAGE_FAILURE';
export const IMPORTS_JOB_LIST_REQUEST = 'IMPORTS_JOB_LIST_REQUEST';
export const IMPORTS_JOB_LIST_SUCCESS = 'IMPORTS_JOB_LIST_SUCCESS';
export const IMPORTS_JOB_LIST_FAILURE = 'IMPORTS_JOB_LIST_FAILURE';
export const IMPORTS_JOB_READ_REQUEST = 'IMPORTS_JOB_READ_REQUEST';
export const IMPORTS_JOB_READ_SUCCESS = 'IMPORTS_JOB_READ_SUCCESS';
export const IMPORTS_JOB_READ_FAILURE = 'IMPORTS_JOB_READ_FAILURE';

// ACTION CREATORS

export function csvUloadUrlRequest() {
  Logger.log('debug', `[imports.actions] csvUloadUrlRequest()`);
  return {
    type: IMPORTS_CSV_UPLOAD_URL_REQUEST,
  }
}

export function csvUloadUrlSuccess(data) {
  Logger.log('debug', `[imports.actions] csvUloadUrlSuccess(%j)`, data);
  return {
    type: IMPORTS_CSV_UPLOAD_URL_SUCCESS,
    result: data.result,
    receivedAt: Date.now()
  }
}

export function csvUloadUrlFailure(error) {
  Logger.log('debug', `[imports.actions] csvUloadUrlFailure(%j)`, error);
  return {
    type: IMPORTS_CSV_UPLOAD_URL_FAILURE,
    error: error
  }
}

export function csvUploadRequest() {
  Logger.log('debug', `[imports.actions] csvUploadRequest()`);
  return {
    type: IMPORTS_CSV_UPLOAD_REQUEST,
  }
}

export function csvUploadSuccess() {
  Logger.log('debug', `[imports.actions] csvUploadSuccess()`);
  return {
    type: IMPORTS_CSV_UPLOAD_SUCCESS,
  }
}

export function csvUploadFailure(error) {
  Logger.log('debug', `[imports.actions] csvUploadFailure(%j)`, error);
  return {
    type: IMPORTS_CSV_UPLOAD_FAILURE,
    error: error
  }
}

export function csvMessageRequest(data) {
  Logger.log('debug', `[imports.actions] csvMessageRequest(%j)`, data);
  return {
    type: IMPORTS_CSV_MESSAGE_REQUEST,
  }
}

export function csvMessageSuccess(data) {
  Logger.log('debug', `[imports.actions] csvMessageSuccess(%j)`, data);
  return {
    type: IMPORTS_CSV_MESSAGE_SUCCESS,
  }
}

export function csvMessageFailure(error) {
  Logger.log('debug', `[imports.actions] csvMessageFailure(%j)`, error);
  return {
    type: IMPORTS_CSV_MESSAGE_FAILURE,
    error: error
  }
}

export function importJobListRequest(page, limit, order, filter) {
  Logger.log('debug', `[imports.actions] importJobListRequest(${page}, ${limit}, ${order}, %j)`, filter);
  return {
    type: IMPORTS_JOB_LIST_REQUEST,
    page: page,
    limit: limit,
    order: order,
    filter: filter
  }
}

export function importJobListSuccess(data) {
  Logger.log('debug', `[imports.actions] importJobListSuccess(%j)`, data);
  return {
    type: IMPORTS_JOB_LIST_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now()
  }
}

export function importJobListFailure(error) {
  Logger.log('debug', `[imports.actions] importJobListFailure(%j)`, error);
  return {
    type: IMPORTS_JOB_LIST_FAILURE,
    error: error
  }
}

export function importJobReadRequest(id) {
  Logger.log('debug', `[imports.actions] importJobReadRequest(${id})`);
  return {
    type: IMPORTS_JOB_READ_REQUEST,
    id: id
  }
}

export function importJobReadSuccess(data) {
  Logger.log('debug', `[imports.actions] importJobReadSuccess(%j)`, data);
  return {
    type: IMPORTS_JOB_READ_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function importJobReadFailure(error) {
  Logger.log('debug', `[resources.actions] importJobReadFailure(%j)`, error);
  return {
    type: IMPORTS_JOB_READ_FAILURE,
    error: error
  }
}


// API THUNK ACTION CREATORS

export function loadCSVUploadURL(cb=function(){}) {
  Logger.log('debug', `[imports.actions] loadCSVUploadURL()`);

  return async function(dispatch) {
    dispatch(csvUloadUrlRequest());

    // call API
    const response = await api.getImportsCSVUploadURL();
    let success = false;
    let data = {};

    // get CSV upload URL success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API CSV upload URL success.`);

      data = {
        bucket: response.getIn(['data', 'bucket']),
        directory: response.getIn(['data', 'directory']),
        file_ext: response.getIn(['data', 'file_ext']),
        file_id: response.getIn(['data', 'file_id']),
        upload_url: response.getIn(['data', 'upload_url']),
      };

      dispatch(csvUloadUrlSuccess(data));
      success = true;
      
    // get CSV upload URL failure
    } else {
      Logger.log('info', `Get API CSV upload URLfailure.`);
      dispatch(csvUloadUrlFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success, data);
  }
}

export function uploadCSV(path, file, cb=function(){}) {
  Logger.log('debug', `[imports.actions] uploadCSV(${path}, ###, ###)`);

  return async function(dispatch) {
    dispatch(csvUploadRequest());

    // call API
    const response = await api.putS3Upload(path, file);
    let success = false;

    // put image success
    if (200 === response.get('status')) {
      Logger.log('info', `PUT upload CSV success.`);
      success = true;
      dispatch(csvUploadSuccess());
      
    // put image failure
    } else {
      Logger.log('info', `PUT upload CSV failure.`);
      dispatch(csvUploadFailure("Upload error."));
    }

    // callback function
    cb(success);
  }
}

export function sendCSVMessage(payload, cb=function(){}) {
  Logger.log('debug', `[imports.actions] sendCSVMessage(%j, ###)`, payload);

  return async function(dispatch) {
    dispatch(csvMessageRequest(payload));

    // call API
    const response = await api.postImportsCSVMessage(payload);
    let success = false;
    let data = {};

    // post message success
    if (201 === response.get('status')) {

      Logger.log('info', `POST API imports message success.`);

      data = {
        message_id: response.getIn(['data', 'message_id']),
        import_job_id: response.getIn(['data', 'import_job_id']),
      };

      dispatch(csvMessageSuccess(data));
      success = true;
      
    // get message failure
    } else {
      Logger.log('info', `POST API imports message failure.`);
      dispatch(csvMessageFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function loadImportJobs(page=1, limit=10, order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[imports.actions] loadImportJobs(${page}, ${limit}, ${order}, %j, ###)`, filter);

  return async function(dispatch) {
    dispatch(importJobListRequest(page, limit, order, filter));

    // call API
    const response = await api.getImportJobs(page, limit, order, filter);
    let success = false;

    // get import jobs list success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API import jobs list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`);

      const normalizedEntities = normalize(response.getIn(['data', 'import_jobs']), [schema.importJob]);
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(importJobListSuccess(data));
      success = true;

    } else if (1 === page && 204 === response.get('status')) {

      Logger.log('info', `Get API import jobs success [empty]. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: []
      };
      dispatch(importJobListSuccess(data));
      success = true;
      
    // get import jobs list failure
    } else {
      Logger.log('info', `Get API import jobs list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      dispatch(importJobListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function loadImportJob(id, cb=function(){}) {
  Logger.log('debug', `[imports.actions] loadImportJob(${id}, ###)`);

  return async function(dispatch) {
    dispatch(importJobReadRequest(id));

    // call API
    const response = await api.getImportJob(id);
    let success = false;

    // get import job success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API import job success. ID: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'import_job'])], [schema.importJob]);
      const data = {
        id: response.getIn(['data', 'import_job', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(importJobReadSuccess(data));
      success = true;
      
    // get import job failure
    } else {
      Logger.log('info', `Get API import job failure. ID: ${id}.`);
      dispatch(importJobReadFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

Logger.log('silly', `imports.actions loaded.`);
