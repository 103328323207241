import { getI18n } from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import AlertsScreen from './containers/AlertsScreenContainer';
import AlertViewScreen from './containers/AlertViewScreenContainer';

const register = () => {
  Logger.log('debug', `alerts.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'alerts', 'routes'])) {
    Events.dispatch('ADD_MAIN_ROUTES', {
      AlertsScreen: [
        'PrivateRoute',
        '/alerts/:page(\\d+)?',
        true,
        AlertsScreen,
        getI18n().t('alerts_route_list'),
      ],
      AlertViewScreen: [
        'PrivateRoute',
        '/alerts/view/:id([\\w\\-]+)',
        true,
        AlertViewScreen,
        getI18n().t('alerts_route_view'),
      ],
    });
  }
};

export default register;

Logger.log('silly', `alert.register() loaded.`);
