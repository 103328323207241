import React, {useEffect} from 'react';
import {Translation} from 'react-i18next';
import {Form, Select} from 'antd';

const TemplateInput = ({name, label, options, isLoading, disabled, load, onChange, style=null, loadOnMount=true, ...props}) => {

  // load sections on mount
  useEffect(() => {
    if (loadOnMount) {
      load(1, 100);
    }
  }, [load, loadOnMount]);

  return (
    <Translation>{(t) => 
      <div className="form-group">
        <Form.Item
          name={name}
          label={label}
          style={style}
          rules={[
            {required: true, message: t('feedback_validation_required')},
          ]}
        >
          <Select
            disabled={disabled || isLoading}
            loading={isLoading}
            onChange={onChange}
          >
            {options.map(x => <Select.Option key={x.value} value={x.value}>{x.label}</Select.Option>)}
          </Select>
        </Form.Item>
      </div>
    }</Translation>
  );
}

export default TemplateInput;
