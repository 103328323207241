import {connect} from 'react-redux';

import JournalTagEditScreen from '../components/JournalTagEditScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.journalTags.get('isLoading'),
    success: state.journalTags.getIn(['form', 'success']),
  }
}

const JournalTagEditScreenContainer = connect(
  mapStateToProps
)(JournalTagEditScreen);

export default JournalTagEditScreenContainer;
