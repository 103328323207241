import React, { useEffect } from 'react';
import { Translation, getI18n } from 'react-i18next';
import { Affix, Card, Col, Input, Row, Spin, Tag } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import DocumentHead from '../../../elements/components/DocumentHead';
import Config from '../../../../../Config';
import Format from '../../../../../lib/Format';
import Logger from '../../../../../lib/Logger';
import { BooleanTag, AlertTypeTag } from '../../../elements/components/Tags';

const DisplayInput = ({ label, value, ...props }) => (
  <div className="form-group">
    <div className="ant-row ant-form-item" style={{ marginBottom: 0 }}>
      <div className="ant-col ant-col-5 ant-form-item-label">
        <label
          htmlFor="alert_form_application"
          className=""
          title="Application"
        >
          {label}
        </label>
      </div>
      <div className="ant-col ant-col-19 ant-form-item-control">
        <Input value={value} bordered={false} />
      </div>
    </div>
  </div>
);

const DisplayComponent = ({ label, value, ...props }) => (
  <div className="form-group">
    <div className="ant-row ant-form-item" style={{ marginBottom: 0 }}>
      <div className="ant-col ant-col-5 ant-form-item-label">
        <label
          htmlFor="alert_form_application"
          className=""
          title="Application"
        >
          {label}
        </label>
      </div>
      <div className="ant-col ant-col-19 ant-form-item-control">
        <div className="ant-form-item-control-input">
          <div className="ant-form-item-control-input-content">{value}</div>
        </div>
      </div>
    </div>
  </div>
);

const AlertViewScreen = ({ alert, isLoading, load, id, ...props }) => {
  const scrollToTop = () => {
    Logger.log('debug', `AlertViewScreen.scrollToTop()`);
    if (typeof window !== 'undefined') {
      setTimeout(() => window.scrollTo(0, 0), 100);
    }
  };

  useEffect(() => {
    scrollToTop();
    load(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Translation>
      {(t) => (
        <div>
          <DocumentHead title={t('alerts_route_view')} />

          <div className="alert-view">
            <Row gutter={16}>
              <Col xs={24} lg={18}>
                <Card
                  title={t('alerts_view_title')}
                  extra={
                    isLoading ? (
                      <Spin
                        indicator={
                          <LoadingOutlined style={{ fontSize: 20 }} spin />
                        }
                      />
                    ) : null
                  }
                >
                  <DisplayInput
                    label={t('alerts_profile_username')}
                    value={alert?.profile?.user?.username ?? ''}
                  />
                  <DisplayInput
                    label={t('alerts_subject_profile_username')}
                    value={alert?.subject_profile?.user?.username ?? ''}
                  />
                  <DisplayInput
                    label={t('alerts_care_guide_name')}
                    value={
                      alert?.care_guide?.first_name &&
                      alert?.care_guide?.last_name
                        ? `${alert?.care_guide?.first_name} ${alert?.care_guide?.last_name}`
                        : ''
                    }
                  />
                  <DisplayInput
                    label={t('alerts_owner_username')}
                    value={
                      alert?.care_guide?.owner?.profile?.user?.username ?? null
                    }
                  />

                  <DisplayInput
                    label={t('alerts_alert_at')}
                    value={
                      alert
                        ? Format.date(
                            alert?.alert_at,
                            Config.get('DEFAULT_DATETIME_FORMAT')
                          )
                        : null
                    }
                  />
                  <DisplayComponent
                    label={t('alerts_alert_type')}
                    value={
                      alert?.alert_type ? (
                        <AlertTypeTag alert_type={alert?.alert_type} />
                      ) : null
                    }
                  />
                  <DisplayComponent
                    label={t('alerts_alert_route')}
                    value={
                      alert?.alert_route ? (
                        <Tag>
                          {getI18n().t(
                            'alerts_alert_route_' + alert?.alert_route
                          )}
                        </Tag>
                      ) : null
                    }
                  />
                  <DisplayComponent
                    label={t('alerts_is_read')}
                    value={<BooleanTag value={alert?.is_read} />}
                  />
                </Card>
              </Col>
              <Col xs={24} lg={6}>
                <Affix offsetTop={10}>
                  <Card title={t('form_metadata_header')}>
                    {alert && alert.created_at ? (
                      <p>
                        {t('form_metadata_created_at')}{' '}
                        <small>
                          {Format.date(
                            alert.created_at,
                            Config.get('DEFAULT_DATETIME_FORMAT')
                          )}
                        </small>
                      </p>
                    ) : (
                      ''
                    )}
                    {alert &&
                    alert.updated_at &&
                    alert.updated_at !== alert.created_at ? (
                      <p>
                        {t('form_metadata_updated_ad')}{' '}
                        <small>
                          {Format.date(
                            alert.updated_at,
                            Config.get('DEFAULT_DATETIME_FORMAT')
                          )}
                        </small>
                      </p>
                    ) : (
                      ''
                    )}
                  </Card>
                </Affix>
              </Col>
            </Row>
          </div>
        </div>
      )}
    </Translation>
  );
};

export default AlertViewScreen;

Logger.log('silly', `AlertViewScreen loaded.`);
