import { connect } from 'react-redux';
import { downloadRecommendedUtilitiesCSV } from '../../../../../state/modules/recommendedUtilities/actions';

import RecommendedUtilitiesScreen from '../components/RecommendedUtilitiesScreen';

const mapStateToProps = (state) => {
  return {
    total: state.recommendedUtilities.get('total'),
    isLoading: state.recommendedUtilities.get('isLoading'),
    isDownloading: state.recommendedUtilities.get('isCSVDownloading'),
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    download: (order, filter, cb) => {
      dispatch(downloadRecommendedUtilitiesCSV(order, filter, cb));
    },
  };
};
const RecommendedUtilitiesScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RecommendedUtilitiesScreen);

export default RecommendedUtilitiesScreenContainer;
