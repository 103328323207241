import {connect} from 'react-redux';

import CareGuideTemplateAddScreen from '../components/CareGuideTemplateAddScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.careGuideTemplates.get('isLoading'),
    success: state.careGuideTemplates.getIn(['form', 'success']),
  }
}

const CareGuideTemplateAddScreenContainer = connect(
  mapStateToProps
)(CareGuideTemplateAddScreen);

export default CareGuideTemplateAddScreenContainer;
