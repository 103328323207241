import { connect } from 'react-redux';
import { denormalize } from 'normalizr';
import { getI18n } from 'react-i18next';
import { schema } from '../../../../../state/schema';
import {
  loadResources,
  deleteResource,
} from '../../../../../state/modules/resources/actions';
import { sendMessage } from '../../../../../state/actions';
import ResourcesList from '../components/ResourcesList';

const get_assigned_to = (x) => {
  if (x?.is_multiple_care_guides) {
    return x?.partnership?.name;
  } else {
    if (
      x?.amount_assigned_care_guides > 1 ||
      (x?.amount_assigned_care_guides === 1 && x?.care_guide == null)
    ) {
      return `${x.amount_assigned_care_guides} members`;
    } else if (x?.care_guide && x?.amount_assigned_care_guides === 1) {
      if (x?.care_guide?.first_name && x?.care_guide?.last_name) {
        return `${x?.care_guide?.first_name} ${x?.care_guide?.last_name}`;
      }
    } else {
      if (x?.partnership) {
        return getI18n().t('resource_unassigned');
      } else {
        return getI18n().t('resources_universal');
      }
    }
  }
};

const get_owner_name = (x) => {
  if (x?.is_multiple_care_guides) {
    return x?.partnership?.name;
  } else {
    if (
      x?.amount_assigned_care_guides > 1 ||
      (x?.amount_assigned_care_guides === 1 && x?.care_guide == null)
    ) {
      return `${x.amount_assigned_care_guides} members`;
    } else if (x?.care_guide && x?.amount_assigned_care_guides === 1) {
      if (
        x?.care_guide?.owner?.profile?.first_name &&
        x?.care_guide?.owner?.profile?.last_name
      ) {
        return `${x?.care_guide?.owner?.profile?.first_name} ${x?.care_guide?.owner?.profile?.last_name}`;
      }
    } else {
      if (x?.partnership) {
        return getI18n().t('resource_unassigned');
      } else {
        return getI18n().t('resources_universal');
      }
    }
  }
};

const get_profile_name = (x) => {
  if (x?.profile) {
    return `${x?.profile?.first_name} ${x?.profile?.last_name} `;
  }
  return getI18n().t('resource_profile_empty');
};

const mapStateToProps = (state, ownProps) => {
  const page = ownProps.page;
  const limit = ownProps.limit;
  const order = ownProps.order;
  const result = state.resources.getIn(['pages', order, limit, page]);

  return {
    list: result
      ? result
          .map((x) => {
            return {
              key: x,
              ...denormalize(
                state.entities.getIn(['resources', x]),
                schema.resource,
                state.entities.toJS()
              ),
            };
          })
          .toArray()
          .map((x) => {
            // flatten some nested objects to make table easier to work with
            return {
              care_guide_name: get_assigned_to(x),
              owner_name: get_owner_name(x),
              partnership_name:
                x?.partnership?.name ??
                (x?.care_guide
                  ? getI18n().t('resources_empty')
                  : getI18n().t('resources_universal')),
              partnership_plan:
                x?.partnership?.plan?.name ??
                (x?.care_guide
                  ? getI18n().t('resources_empty')
                  : getI18n().t('resources_universal')),
              postal_code_name: x.postal_code ? x.postal_code.postal_code : '',
              profile_name: get_profile_name(x),
              ...x,
            };
          })
      : [],
    isLoading: state.resources.get('isLoading'),
    total: state.resources.get('total'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (page, limit, order, filter, cb) => {
      dispatch(loadResources(page, limit, order, filter, cb));
    },
    remove: (id, cb) => {
      dispatch(deleteResource(id, cb));
    },
    sendMessage: (level, title, body, expires) => {
      dispatch(sendMessage(level, title, body, expires));
    },
  };
};

const ResourcesListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResourcesList);

export default ResourcesListContainer;
