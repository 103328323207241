import {connect} from 'react-redux';
import {List} from 'immutable';
import moment from 'moment';

import {
  loadJournalPost,
  updateJournalPost,
  createJournalPost,
  deleteJournalPost,
  journalPostFormDestroy
} from '../../../../../state/modules/journalPosts/actions';
import {sendMessage} from '../../../../../state/actions';
import JournalPostForm from '../components/JournalPostForm';
import Config from '../../../../../Config';

const inputs = List([
  'care_guide',
  'profile',
  'rating',
  'body',
  'published_at',
  'edited_at',
  'public_status',
  'tags',
  'partner_tags',
  'media',
  'status'
]);

const mapStateToProps = (state, ownProps) => {

  const data = {}, errors = {};
  for (const val of inputs.values()) {
    data[val] = state.entities.getIn(['journalPosts', ownProps.id, val], '');
    if (['profile'].includes(val)) {
      errors[val] = state.journalPosts.getIn(['form', 'errors', 'user_profile_id']);
    }
    else if (['care_guide'].includes(val)) {
      errors[val] = state.journalPosts.getIn(['form', 'errors', val + '_id']);
    }
    else {
      errors[val] = state.journalPosts.getIn(['form', 'errors', val]);
    }
  }

  // change select number values to strings to work with ant design select inputs
  Object.keys(data).forEach(x => {
    if (['status', 'rating'].includes(x)) {
      data[x] = data[x].toString();
    }
  });

  data['published_at'] = data['published_at']
    ? moment(data['published_at'], Config.get('API_DATETIME_FORMAT'))
    : moment();

  data['edited_at'] = data['edited_at']
    ? moment(data['edited_at'], Config.get('API_DATETIME_FORMAT'))
    : null;

  if (data['tags'] === '') {
    data['tags'] = [];
  }
  
  if (data['partner_tags'] === '') {
    data['partner_tags'] = [];
  }

  if (data['media'] === '') {
    data['media'] = [];
  }

  return {
    isLoading: state.journalPosts.get('isLoading'),
    isSubmitting: state.journalPosts.getIn(['form', 'isSubmitting']),
    success: state.journalPosts.getIn(['form', 'success']),
    created_id: state.journalPosts.getIn(['form', 'created_id']),
    data: data,
    errors: errors,
    createdAt: state.entities.getIn(['journalPosts', ownProps.id, 'created_at'], null),
    updatedAt: state.entities.getIn(['journalPosts', ownProps.id, 'updated_at'], null),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (id, cb) => {
      dispatch(loadJournalPost(id, cb));
    },
    update: (id, data, cb) => {
      dispatch(updateJournalPost(id, data, cb));
    },
    create: (data, cb) => {
      dispatch(createJournalPost(data, cb));
    },
    delete: (id, cb) => {
      dispatch(deleteJournalPost(id, cb))
    },
    destroyForm: (formState) => {
      dispatch(journalPostFormDestroy(formState));
    },
    sendMessage: (level, title, body, expires) => {
      dispatch(sendMessage(level, title, body, expires));
    },
  }
}

const JournalPostFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(JournalPostForm);

export default JournalPostFormContainer;
