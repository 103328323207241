import { normalize } from 'normalizr';

import api from '../../api';
import { schema } from '../../schema';
import { addEntities, removeEntity } from '../../actions';
import Format from '../../../lib/Format';
import Logger from '../../../lib/Logger';

// ACTION TYPES

export const CARE_GUIDE_DOCUMENT_LIST_REQUEST =
  'CARE_GUIDE_DOCUMENT_LIST_REQUEST';
export const CARE_GUIDE_DOCUMENT_LIST_SUCCESS =
  'CARE_GUIDE_DOCUMENT_LIST_SUCCESS';
export const CARE_GUIDE_DOCUMENT_LIST_FAILURE =
  'CARE_GUIDE_DOCUMENT_LIST_FAILURE';
export const CARE_GUIDE_DOCUMENT_READ_REQUEST =
  'CARE_GUIDE_DOCUMENT_READ_REQUEST';
export const CARE_GUIDE_DOCUMENT_READ_SUCCESS =
  'CARE_GUIDE_DOCUMENT_READ_SUCCESS';
export const CARE_GUIDE_DOCUMENT_READ_FAILURE =
  'CARE_GUIDE_DOCUMENT_READ_FAILURE';
export const CARE_GUIDE_DOCUMENT_UPDATE_REQUEST =
  'CARE_GUIDE_DOCUMENT_UPDATE_REQUEST';
export const CARE_GUIDE_DOCUMENT_UPDATE_SUCCESS =
  'CARE_GUIDE_DOCUMENT_UPDATE_SUCCESS';
export const CARE_GUIDE_DOCUMENT_UPDATE_FAILURE =
  'CARE_GUIDE_DOCUMENT_UPDATE_FAILURE';
export const CARE_GUIDE_DOCUMENT_CREATE_REQUEST =
  'CARE_GUIDE_DOCUMENT_CREATE_REQUEST';
export const CARE_GUIDE_DOCUMENT_CREATE_SUCCESS =
  'CARE_GUIDE_DOCUMENT_CREATE_SUCCESS';
export const CARE_GUIDE_DOCUMENT_CREATE_FAILURE =
  'CARE_GUIDE_DOCUMENT_CREATE_FAILURE';
export const CARE_GUIDE_DOCUMENT_DELETE_REQUEST =
  'CARE_GUIDE_DOCUMENT_DELETE_REQUEST';
export const CARE_GUIDE_DOCUMENT_DELETE_SUCCESS =
  'CARE_GUIDE_DOCUMENT_DELETE_SUCCESS';
export const CARE_GUIDE_DOCUMENT_DELETE_FAILURE =
  'CARE_GUIDE_DOCUMENT_DELETE_FAILURE';
export const CARE_GUIDE_DOCUMENT_FORM_DESTROY =
  'CARE_GUIDE_DOCUMENT_FORM_DESTROY';
export const CARE_GUIDE_DOCUMENT_CSV_DOWNLOAD_REQUEST =
  'CARE_GUIDE_DOCUMENT_CSV_DOWNLOAD_REQUEST';
export const CARE_GUIDE_DOCUMENT_CSV_DOWNLOAD_SUCCESS =
  'CARE_GUIDE_DOCUMENT_CSV_DOWNLOAD_SUCCESS';
export const CARE_GUIDE_DOCUMENT_CSV_DOWNLOAD_FAILURE =
  'CARE_GUIDE_DOCUMENT_CSV_DOWNLOAD_FAILURE';

// ACTION CREATORS

export function careGuideDocumentListRequest(page, limit, order, filter) {
  Logger.log(
    'debug',
    `[careGuideDocuments.actions] careGuideDocumentListRequest(${page}, ${limit}, ${order}, %j)`,
    filter
  );
  return {
    type: CARE_GUIDE_DOCUMENT_LIST_REQUEST,
    page: page,
    limit: limit,
    order: order,
    filter: filter,
  };
}

export function careGuideDocumentListSuccess(data) {
  Logger.log(
    'debug',
    `[careGuideDocuments.actions] careGuideDocumentListSuccess(%j)`,
    data
  );
  return {
    type: CARE_GUIDE_DOCUMENT_LIST_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now(),
  };
}

export function careGuideDocumentListFailure(error) {
  Logger.log(
    'debug',
    `[careGuideDocuments.actions] careGuideDocumentListFailure(%j)`,
    error
  );
  return {
    type: CARE_GUIDE_DOCUMENT_LIST_FAILURE,
    error: error,
  };
}

export function careGuideDocumentReadRequest(id) {
  Logger.log(
    'debug',
    `[careGuideDocuments.actions] careGuideDocumentReadRequest(${id})`
  );
  return {
    type: CARE_GUIDE_DOCUMENT_READ_REQUEST,
    id: id,
  };
}

export function careGuideDocumentReadSuccess(data) {
  Logger.log(
    'debug',
    `[careGuideDocuments.actions] careGuideDocumentReadSuccess(%j)`,
    data
  );
  return {
    type: CARE_GUIDE_DOCUMENT_READ_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function careGuideDocumentReadFailure(error) {
  Logger.log(
    'debug',
    `[careGuideDocuments.actions] careGuideDocumentReadFailure(%j)`,
    error
  );
  return {
    type: CARE_GUIDE_DOCUMENT_READ_FAILURE,
    error: error,
  };
}

export function careGuideDocumentUpdateRequest(id, data) {
  Logger.log(
    'debug',
    `[careGuideDocuments.actions] careGuideDocumentUpdateRequest(${id}, %j)`,
    data
  );
  return {
    type: CARE_GUIDE_DOCUMENT_UPDATE_REQUEST,
  };
}

export function careGuideDocumentUpdateSuccess(data) {
  Logger.log(
    'debug',
    `[careGuideDocuments.actions] careGuideDocumentUpdateSuccess(%j)`,
    data
  );
  return {
    type: CARE_GUIDE_DOCUMENT_UPDATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function careGuideDocumentUpdateFailure(error) {
  Logger.log(
    'debug',
    `[careGuideDocuments.actions] careGuideDocumentUpdateFailure(%j)`,
    error
  );
  return {
    type: CARE_GUIDE_DOCUMENT_UPDATE_FAILURE,
    error: error,
  };
}

export function careGuideDocumentCreateRequest(data) {
  Logger.log(
    'debug',
    `[careGuideDocuments.actions] careGuideDocumentCreateRequest(%j)`,
    data
  );
  return {
    type: CARE_GUIDE_DOCUMENT_CREATE_REQUEST,
  };
}

export function careGuideDocumentCreateSuccess(data) {
  Logger.log(
    'debug',
    `[careGuideDocuments.actions] careGuideDocumentCreateSuccess(%j)`,
    data
  );
  return {
    type: CARE_GUIDE_DOCUMENT_CREATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function careGuideDocumentCreateFailure(error) {
  Logger.log(
    'debug',
    `[careGuideDocuments.actions] careGuideDocumentCreateFailure(%j)`,
    error
  );
  return {
    type: CARE_GUIDE_DOCUMENT_CREATE_FAILURE,
    error: error,
  };
}

export function careGuideDocumentDeleteRequest(id) {
  Logger.log(
    'debug',
    `[careGuideDocuments.actions] careGuideDocumentDeleteRequest(${id})`
  );
  return {
    type: CARE_GUIDE_DOCUMENT_DELETE_REQUEST,
    id: id,
  };
}

export function careGuideDocumentDeleteSuccess(id) {
  Logger.log(
    'debug',
    `[careGuideDocuments.actions] careGuideDocumentDeleteSuccess(${id})`
  );
  return {
    type: CARE_GUIDE_DOCUMENT_DELETE_SUCCESS,
    id: id,
  };
}

export function careGuideDocumentDeleteFailure(error) {
  Logger.log(
    'debug',
    `[careGuideDocuments.actions] careGuideDocumentDeleteFailure(%j)`,
    error
  );
  return {
    type: CARE_GUIDE_DOCUMENT_DELETE_FAILURE,
    error: error,
  };
}

export function careGuideDocumentsFormDestroy(formState = null) {
  Logger.log(
    'debug',
    `[careGuideDocuments.actions] careGuideDocumentsFormDestroy(%j)`,
    formState
  );
  return {
    type: CARE_GUIDE_DOCUMENT_FORM_DESTROY,
    form: formState,
  };
}

export function careGuideDocumentCSVDownloadRequest(order, filter) {
  Logger.log(
    'debug',
    `[state.careGuideDocuments.actions] careGuideDocumentCSVDownloadRequest()`
  );
  return {
    type: CARE_GUIDE_DOCUMENT_CSV_DOWNLOAD_REQUEST,
    order: order,
    filter: filter,
  };
}

export function careGuideDocumentCSVDownloadSuccess() {
  Logger.log(
    'debug',
    `[state.careGuideDocuments.actions] careGuideDocumentCSVDownloadSuccess()`
  );
  return {
    type: CARE_GUIDE_DOCUMENT_CSV_DOWNLOAD_SUCCESS,
  };
}

export function careGuideDocumentCSVDownloadFailure() {
  Logger.log(
    'debug',
    `[state.careGuideDocuments.actions] careGuideDocumentCSVDownloadFailure()`
  );
  return {
    type: CARE_GUIDE_DOCUMENT_CSV_DOWNLOAD_FAILURE,
  };
}

// API THUNK ACTION CREATORS

export function loadCareGuideDocuments(
  page = 1,
  limit = 10,
  order = null,
  filter = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[careGuideDocuments.actions] loadCareGuideDocuments(${page}, ${limit}, ${order}, %j, ###)`,
    filter
  );

  return async function (dispatch) {
    dispatch(careGuideDocumentListRequest(page, limit, order, filter));

    // call API
    const response = await api.getCareGuideDocuments(
      page,
      limit,
      order,
      filter
    );
    let success = false;

    // get care guide documents list success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API care guide documents list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );

      const normalizedEntities = normalize(
        response.getIn(['data', 'care_guide_documents']),
        [schema.careGuideDocument]
      );
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result,
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideDocumentListSuccess(data));
      success = true;
    } else if (1 === page && 204 === response.get('status')) {
      Logger.log(
        'info',
        `Get API care guide documents success [empty]. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: [],
      };
      dispatch(careGuideDocumentListSuccess(data));
      success = true;

      // get care guide documents list failure
    } else {
      Logger.log(
        'info',
        `Get API care guide documents list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      dispatch(careGuideDocumentListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function loadCareGuideDocument(id, cb = function () {}) {
  Logger.log(
    'debug',
    `[careGuideDocuments.actions] loadCareGuideDocument(${id}, ###)`
  );

  return async function (dispatch) {
    dispatch(careGuideDocumentReadRequest(id));

    // call API
    const response = await api.getCareGuideDocument(id);
    let success = false;

    // get care guide document success
    if (200 === response.get('status')) {
      Logger.log('info', `Get API care guide document success. ID: ${id}.`);

      const normalizedEntities = normalize(
        [response.getIn(['data', 'care_guide_document'])],
        [schema.careGuideDocument]
      );
      const data = {
        id: response.getIn(['data', 'care_guide_document', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideDocumentReadSuccess(data));
      success = true;

      // get care guide document failure
    } else {
      Logger.log('info', `Get API care guide document failure. ID: ${id}.`);
      dispatch(careGuideDocumentReadFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function updateCareGuideDocument(id, data, cb = function () {}) {
  Logger.log(
    'debug',
    `[careGuideDocuments.actions] updateCareGuideDocument(${id}, %j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(careGuideDocumentUpdateRequest(id, data));

    // call API
    const response = await api.putCareGuideDocument(id, data);
    let success = false;

    // put care guide document success
    if (200 === response.get('status')) {
      Logger.log('info', `PUT API care guide document success. User: ${id}.`);

      const normalizedEntities = normalize(
        [response.getIn(['data', 'care_guide_document'])],
        [schema.careGuideDocument]
      );
      const data = {
        id: response.getIn(['data', 'care_guide_document', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideDocumentUpdateSuccess(data));
      success = true;

      // get care guide document failure
    } else {
      Logger.log('info', `PUT API care guide document failure. ID: ${id}.`);
      dispatch(
        careGuideDocumentUpdateFailure(response.getIn(['data', 'error']))
      );
    }

    // callback function
    cb(success);
  };
}

export function createCareGuideDocument(data, cb = function () {}) {
  Logger.log(
    'debug',
    `[careGuideDocuments.actions] createCareGuideDocument(%j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(careGuideDocumentCreateRequest(data));

    // call API
    const response = await api.postCareGuideDocuments(data);
    let success = false;

    // post care guide documents success
    if (201 === response.get('status')) {
      Logger.log(
        'info',
        `POST API care guide documents success. Post: ${response.getIn([
          'data',
          'care_guide_document',
          'id',
        ])}.`
      );

      const normalizedEntities = normalize(
        [response.getIn(['data', 'care_guide_document'])],
        [schema.careGuideDocument]
      );
      const data = {
        id: response.getIn(['data', 'care_guide_document', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideDocumentCreateSuccess(data));
      success = true;

      // get care guide documents failure
    } else {
      Logger.log('info', `POST API care guide documents failure.`);
      dispatch(
        careGuideDocumentCreateFailure(response.getIn(['data', 'error']))
      );
    }

    // callback function
    cb(success);
  };
}

export function deleteCareGuideDocument(id, cb = function () {}) {
  Logger.log(
    'debug',
    `[careGuideDocuments.actions] deleteCareGuideDocument(${id}, ###)`
  );

  return async function (dispatch) {
    dispatch(careGuideDocumentDeleteRequest(id));

    // call API
    const response = await api.deleteCareGuideDocument(id);
    let success = false;

    // delete care guide document success
    if (204 === response.get('status')) {
      Logger.log('info', `DELETE API care guide document success. ID: ${id}.`);

      dispatch(removeEntity({ entityType: 'careGuideDocuments', id: id }));
      dispatch(careGuideDocumentDeleteSuccess(id));
      success = true;

      // get care guide document failure
    } else {
      Logger.log('info', `DELETE API care guide document failure. ID: ${id}.`);
      dispatch(
        careGuideDocumentDeleteFailure(response.getIn(['data', 'error']))
      );
    }

    // callback function
    cb(success);
  };
}

export function downloadCareGuideDocumentsCSV(
  order = null,
  filter = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.careGuideDocuments.actions] downloadCareGuideDocumentsCSV(${order}, ${filter}, ###)`
  );

  return async function (dispatch) {
    dispatch(careGuideDocumentCSVDownloadRequest(order, filter));

    // call API
    const response = await api.getCareGuideDocumentsCSV(order, filter);
    let success = false;
    // get care guide documents CSV success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API care guide documents CSV success. Order: ${order}`
      );
      const now = new Date();

      // trigger browser download
      const url = window.URL.createObjectURL(new Blob([response.get('data')]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `cv-care-guide-documents-${Format.date(now, 'YYYY-MM-DDTHHmm')}.csv`
      );
      document.body.appendChild(link);
      link.click();

      dispatch(careGuideDocumentCSVDownloadSuccess());
      success = true;
      // get care guide documents CSV failure
    } else {
      Logger.log('info', `Get API care guide documents CSV failure.`);
      dispatch(careGuideDocumentCSVDownloadFailure());
    }

    // callback function
    cb(success);
  };
}

Logger.log('silly', `careGuideDocuments.actions loaded.`);
