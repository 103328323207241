import {connect} from 'react-redux';

import {loadCSVUploadURL, uploadCSV, sendCSVMessage} from '../../../../../state/modules/imports/actions';
import UploadCSVButton from '../components/UploadCSVButton';

const mapStateToProps = (state, ownProps) => {
  return {
    isUploading: state.imports.get('isLoadingCSVUploadURL') || state.imports.get('isCSVUploading') || state.imports.get('isMessageSending'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getUploadURL: (cb) => {
      dispatch(loadCSVUploadURL(cb));
    },
    upload: (path, file, cb) => {
      dispatch(uploadCSV(path, file, cb));
    },
    sendCSVMessage: (data, cb) => {
      dispatch(sendCSVMessage(data, cb));
    },
  }
}

const UploadCSVButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadCSVButton);

export default UploadCSVButtonContainer;
