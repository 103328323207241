import { connect } from 'react-redux';

import CareGuidePartnerTagsCSVButton from '../components/CareGuidePartnerTagsCSVButton';

const mapStateToProps = (state) => {
  return {};
};

const CareGuidePartnerTagsCSVButtonContainer = connect(mapStateToProps)(
  CareGuidePartnerTagsCSVButton
);

export default CareGuidePartnerTagsCSVButtonContainer;
