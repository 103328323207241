import React, {useState} from 'react';
import {Translation} from 'react-i18next';
import {Form, Input, Space} from 'antd';
import {DragOutlined, MinusCircleOutlined} from '@ant-design/icons';
import {Draggable} from "react-beautiful-dnd";

import FieldInput from '../../careGuideSectionFields/components/FieldTypeInput';
import CareGuideContentEditor from '../../careGuides/components/CareGuideContentEditor';
import FieldTypeTableEditor from '../../careGuides/components/FieldTypeTableEditor';
import FieldTypeADLTableEditor from '../../careGuides/components/FieldTypeADLTableEditor';
import FieldTypeMedTableEditor from '../../careGuides/components/FieldTypeMedTableEditor';
import Logger from '../../../../../lib/Logger';

const FieldInputRow = ({form, field, index, remove, ...props}) => {

  const [contentType, setContentType] = useState(form.getFieldValue([`fields`, field.name, `type`]));
  
  // prep content input based on type
  var contentInput = null;
  var contentEditor = null;
  var contentStyle = {display: 'none'};
  switch(contentType) {
    case 1:
      contentStyle = null;
      contentInput = <Input placeholder="Content" />;
      break;
    case 2:
      contentStyle = null;
      contentInput = <Input.TextArea placeholder="Content" rows={4} />;
      break;
    case 3:
      contentInput = <Input type="hidden" />;
      contentEditor = 
        <CareGuideContentEditor
          onChange={(contents, delta, source, editor) => {
            form.setFields([{
              name: [`fields`, field.name, `default_content`],
              value: editor.getHTML()
            }]);
          }}
          defaultValue={form.getFieldValue([`fields`, field.name, `default_content`])}
        />;
      break;
    case 4:
      contentStyle = null;
      contentInput = 
        <FieldTypeTableEditor
          form={form}
          content={field}
          contentsPath={[`fields`, field.name]}
        />;
      break;
    case 5:
      contentStyle = null;
      contentInput = 
        <FieldTypeADLTableEditor
          form={form}
          content={field}
          contentsPath={[`fields`, field.name]}
        />;
      break;
    case 6:
      contentStyle = null;
      contentInput = 
        <FieldTypeMedTableEditor
          form={form}
          content={field}
          contentsPath={[`fields`, field.name]}
        />;
      break;
    default:
  }

  return (
    <Draggable key={field.key} draggableId={field.key.toString()} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <Translation>{(t) => 
            <div key={field.key}>

              <Space style={{display: 'flex'}} align="start">

                <div className="drag-handle" {...provided.dragHandleProps}>
                  <DragOutlined />
                </div>

                <FieldInput
                  {...field}
                  name={[field.name, 'type']}
                  fieldKey={[field.fieldKey, 'type']}
                  onChange={e => setContentType(e)}
                />

                <Form.Item
                  {...field}
                  name={[field.name, 'label']}
                  fieldKey={[field.fieldKey, 'label']}
                  rules={[
                    {required: true, message: t('feedback_validation_required')},
                  ]}
                >
                  <Input placeholder="Label" />
                </Form.Item>

                <MinusCircleOutlined
                  onClick={() => {
                    remove(field.name);
                  }}
                />
              </Space>

              <Form.Item
                {...field}
                name={[field.name, 'default_content']}
                fieldKey={[field.fieldKey, 'default_content']}
                style={contentStyle}
              >
                {contentInput}
              </Form.Item>
              {contentEditor}

            </div>
          }</Translation>
        </div>
      )}
    </Draggable>
  )
}


export default FieldInputRow;

Logger.log('silly', `FieldInputRow loaded.`);
