import React, { useEffect } from 'react';
import { Translation } from 'react-i18next';
import { Button, Card, Col, Row, Space, Spin } from 'antd';
import { LoadingOutlined, PlusCircleOutlined } from '@ant-design/icons';
import RecommendedUtilitiesList from '../containers/RecommendedUtilitiesListContainer';
import RecommendedUtilitiesCSVButton from '../containers/RecommendedUtilitiesCSVButtonContainer';
import DocumentHead from '../../../elements/components/DocumentHead';
import { pathTo } from '../../../Routes';
import Config from '../../../../../Config';
import QueryString from '../../../../../lib/QueryString';
import Logger from '../../../../../lib/Logger';

const RecommendedUtilitiesScreen = ({
  isDownloading,
  total,
  download,
  ...props
}) => {
  const limit = Config.get('DEFAULT_LIST_LENGTH', 10);

  const scrollToTop = () => {
    Logger.log('debug', `RecommendedUtilitiesScreen.scrollToTop()`);
    if (typeof window !== 'undefined') {
      setTimeout(() => window.scrollTo(0, 0), 100);
    }
  };

  const loadingIcon = (
    <Spin indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} />
  );

  const page = parseInt(props.match.params.page ? props.match.params.page : 1);
  const { order, ...filter } = QueryString.parse(props.location.search);

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <Translation>
      {(t) => (
        <div>
          <DocumentHead title={t('recommended_utilities_route_list')} />

          <Row>
            <Col span={24}>
              <Card
                title={
                  <Space size={10}>
                    {t('recommended_utilities_list_title')}
                    <Button
                      size="small"
                      icon={<PlusCircleOutlined />}
                      onClick={(e) =>
                        props.history.push(
                          pathTo('RecommendedUtilityAddScreen')
                        )
                      }
                    >
                      {t('action_create')}
                    </Button>
                    <RecommendedUtilitiesCSVButton
                      order={order}
                      filter={filter}
                    />
                  </Space>
                }
                extra={props.isLoading ? loadingIcon : null}
              >
                <RecommendedUtilitiesList
                  key={page}
                  component="RecommendedUtilitiesScreen"
                  page={page}
                  limit={limit}
                  order={order || 'updated_at.desc'}
                  filter={filter}
                />
              </Card>
            </Col>
          </Row>
        </div>
      )}
    </Translation>
  );
};

export default RecommendedUtilitiesScreen;

Logger.log('silly', `RecommendedUtilitiesScreen loaded.`);
