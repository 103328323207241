import { Map, List } from "immutable";

import {
  JOURNAL_PARTNER_TAG_LIST_REQUEST,
  JOURNAL_PARTNER_TAG_LIST_SUCCESS,
  JOURNAL_PARTNER_TAG_LIST_FAILURE,
  JOURNAL_PARTNER_TAG_READ_REQUEST,
  JOURNAL_PARTNER_TAG_READ_SUCCESS,
  JOURNAL_PARTNER_TAG_READ_FAILURE,
  JOURNAL_PARTNER_TAG_UPDATE_REQUEST,
  JOURNAL_PARTNER_TAG_UPDATE_SUCCESS,
  JOURNAL_PARTNER_TAG_UPDATE_FAILURE,
  JOURNAL_PARTNER_TAG_CREATE_REQUEST,
  JOURNAL_PARTNER_TAG_CREATE_SUCCESS,
  JOURNAL_PARTNER_TAG_CREATE_FAILURE,
  JOURNAL_PARTNER_TAG_DELETE_REQUEST,
  JOURNAL_PARTNER_TAG_DELETE_SUCCESS,
  JOURNAL_PARTNER_TAG_DELETE_FAILURE,
  JOURNAL_PARTNER_TAG_FORM_DESTROY,
  JOURNAL_PARTNER_TAG_CSV_DOWNLOAD_REQUEST,
  JOURNAL_PARTNER_TAG_CSV_DOWNLOAD_SUCCESS,
  JOURNAL_PARTNER_TAG_CSV_DOWNLOAD_FAILURE,
  JOURNAL_PARTNER_TAG_CARE_GUIDE_LIST_REQUEST,
  JOURNAL_PARTNER_TAG_CARE_GUIDE_LIST_SUCCESS,
  JOURNAL_PARTNER_TAG_CARE_GUIDE_LIST_FAILURE,
} from "./actions";
import Logger from "../../../lib/Logger";

export default function journalPartnerTags(
  state = Map({
    isLoading: false,
    arePartnerTagsLoading: false,
    isCSVDownloading: false,
    pages: {},
  }),
  action
) {
  Logger.log(
    "debug",
    `[journalPartnerTags.reducers] journalPartnerTags(%j, %j)`,
    state,
    action
  );

  switch (action.type) {
    case JOURNAL_PARTNER_TAG_LIST_REQUEST:
      return state.mergeDeep({
        isLoading: true,
      });

    case JOURNAL_PARTNER_TAG_LIST_SUCCESS:
      return state
        .mergeDeep({
          isLoading: false,
          pages: {
            [action.order]: {
              [action.limit]: {
                [action.page]: null,
              },
            },
          },
          total: action.total,
          lastUpdated: action.receivedAt,
        })
        .setIn(
          ["pages", action.order, action.limit, action.page],
          List(action.result)
        );

    case JOURNAL_PARTNER_TAG_LIST_FAILURE:
      return state.mergeDeep({
        isLoading: false,
      });

    case JOURNAL_PARTNER_TAG_READ_REQUEST:
      return state.mergeDeep({
        isLoading: true,
      });

    case JOURNAL_PARTNER_TAG_READ_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        lastUpdated: action.receivedAt,
      });

    case JOURNAL_PARTNER_TAG_READ_FAILURE:
      return state.mergeDeep({
        isLoading: false,
      });

    case JOURNAL_PARTNER_TAG_UPDATE_REQUEST:
      return state.mergeDeep({
        isLoading: true,
        form: {
          isSubmitting: true,
          success: null,
          errors: null,
        },
      });

    case JOURNAL_PARTNER_TAG_UPDATE_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        form: {
          isSubmitting: false,
          success: true,
          errors: null,
        },
        lastUpdated: action.receivedAt,
      });

    case JOURNAL_PARTNER_TAG_UPDATE_FAILURE:
      return state
        .mergeDeep({
          isLoading: false,
          form: {
            isSubmitting: false,
            success: false,
            errors: null,
          },
        })
        .setIn(["form", "errors"], action.error);

    case JOURNAL_PARTNER_TAG_CREATE_REQUEST:
      return state.mergeDeep({
        isLoading: true,
        form: {
          isSubmitting: true,
          success: null,
          errors: null,
        },
      });

    case JOURNAL_PARTNER_TAG_CREATE_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        form: {
          isSubmitting: false,
          success: true,
          created_id: action.id,
          errors: null,
        },
        lastUpdated: action.receivedAt,
      });

    case JOURNAL_PARTNER_TAG_CREATE_FAILURE:
      return state
        .mergeDeep({
          isLoading: false,
          form: {
            isSubmitting: false,
            success: false,
            errors: null,
          },
        })
        .setIn(["form", "errors"], action.error);

    case JOURNAL_PARTNER_TAG_DELETE_REQUEST:
      return state.mergeDeep({
        isLoading: true,
      });

    case JOURNAL_PARTNER_TAG_DELETE_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        lastUpdated: action.receivedAt,
      });

    case JOURNAL_PARTNER_TAG_DELETE_FAILURE:
      return state.mergeDeep({
        isLoading: false,
      });

    case JOURNAL_PARTNER_TAG_FORM_DESTROY:
      return state
        .mergeDeep({
          form: null,
        })
        .set("form", action.form);

    case JOURNAL_PARTNER_TAG_CSV_DOWNLOAD_REQUEST:
      return state.mergeDeep({
        isCSVDownloading: true,
      });

    case JOURNAL_PARTNER_TAG_CSV_DOWNLOAD_SUCCESS:
      return state.mergeDeep({
        isCSVDownloading: false,
      });

    case JOURNAL_PARTNER_TAG_CSV_DOWNLOAD_FAILURE:
      return state.mergeDeep({
        isCSVDownloading: false,
      });

    case JOURNAL_PARTNER_TAG_CARE_GUIDE_LIST_REQUEST:
      return state.mergeDeep({
        arePartnerTagsLoading: true,
      });

    case JOURNAL_PARTNER_TAG_CARE_GUIDE_LIST_SUCCESS:
      return state
        .mergeDeep({
          arePartnerTagsLoading: false,
          partnerTags: {
            [action.careGuideId]: {
              total: action.total,
              pages: {
                [action.order]: {
                  [action.limit]: {
                    [action.page]: null,
                  },
                },
              },
            },
          },
          lastUpdated: action.receivedAt,
        })
        .setIn(
          [
            "partnerTags",
            action.careGuideId,
            "pages",
            action.order,
            action.limit,
            action.page,
          ],
          List(action.result)
        );

    case JOURNAL_PARTNER_TAG_CARE_GUIDE_LIST_FAILURE:
      return state.mergeDeep({
        arePartnerTagsLoading: false,
      });

    default:
      return state;
  }
}

Logger.log("silly", `journalPartnerTags.reducers loaded.`);
