import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Translation, getI18n } from 'react-i18next';
import {
  Affix,
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Space,
  Spin,
} from 'antd';
import {
  DragOutlined,
  GlobalOutlined,
  LoadingOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import moment from 'moment';

import SegmentInputRow from './SegmentInputRow';
import RelationshipInput from '../../relationships/containers/RelationshipInputContainer';
import message from '../../../elements/lib/MessageWrapper';
import FormMetadata from '../../../elements/components/FormMetadata';
import { pathTo } from '../../../Routes';
import Format from '../../../../../lib/Format';
import Logger from '../../../../../lib/Logger';
import Config from '../../../../../Config';
import TagInput from '../../adminPartnerClasses/containers/TagInputContainer';
import AccountInput from '../../accounts/containers/AccountInputContainer';
import CareGuideOwnerInput from '../../careGuideOwners/containers/CareGuideOwnerInputContainer';



const SummaryInputRow = ({ field, index, remove, ...props }) => {
  return (
    <Draggable key={field.key} draggableId={field.key.toString()} index={index}>
      {(provided, snapshot) => (
        <div ref={provided.innerRef} {...provided.draggableProps}>
          <Translation>
            {(t) => (
              <Space key={field.key} style={{ display: 'flex' }} align="start">
                <div className="drag-handle" {...provided.dragHandleProps}>
                  <DragOutlined />
                </div>

                <div className="form-group">
                  <Form.Item
                    {...field}
                    name={[field.name, 'field']}
                    label={t('care_guide_template_summary_field')}
                    fieldKey={[field.fieldKey, 'field']}
                    rules={[
                      {
                        required: true,
                        message: t('feedback_validation_required'),
                      },
                      {
                        type: 'string',
                        min: 2,
                        max: 60,
                        message: t('feedback_validation_char_range', {
                          min: 2,
                          max: 60,
                        }),
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </div>

                <div className="form-group">
                  <Form.Item
                    {...field}
                    name={[field.name, 'value']}
                    label={t('care_guide_template_summary_value')}
                    fieldKey={[field.fieldKey, 'value']}
                  >
                    <Input.TextArea autoSize />
                  </Form.Item>
                </div>

                <MinusCircleOutlined
                  onClick={() => {
                    remove(field.name);
                  }}
                />
              </Space>
            )}
          </Translation>
        </div>
      )}
    </Draggable>
  );
};

const CareGuideForm = ({
  id,
  data,
  errors,
  load,
  destroyForm,
  isLoading,
  isSubmitting,
  created_id,
  copyId = null,
  ...props
}) => {
  const [redirectTo, setRedirectTo] = useState(null);
  const [form] = Form.useForm();
  const [partnershipId, setPartnershipId] = useState('N/A');
  const [classes, setClasses] = useState([]);

  // form column settings
  const layout = {
    main: {
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
    },
  };

  // load record data from API
  useEffect(() => {
    if (id) {
      load(id);
    }
  }, [id, load]);

  // load record to copy from API
  useEffect(() => {
    if (copyId) {
      load(copyId);
    }
  }, [copyId, load]);

  // update input values when new data is available
  const dataString = JSON.stringify(data);
  useEffect(() => {
    if ((id || copyId) && !isSubmitting) {
      const dataObj = JSON.parse(dataString);
      dataObj['started_at'] = dataObj['started_at']
        ? moment(dataObj['started_at'], Config.get('API_DATETIME_FORMAT'))
        : moment();
      form.setFieldsValue({
        ...dataObj,
        ...data.care_partner_tags.reduce((acc, item) => {
          const key = item.admin_partner_class.name;
          const value = item.id;
          acc[key] = value;
          return acc;
        }, {}),
      });
      setPartnershipId(dataObj['partnership']);
    }
    // eslint-disable-next-line
  }, [form, dataString, isSubmitting, id, copyId]);

  // handle errors reported by API
  useEffect(() => {
    let firstFieldName = '';
    for (const field in errors) {
      form.setFields([{ name: field, errors: errors[field] }]);
      if (firstFieldName === '') {
        firstFieldName = field;
      }
    }
    form.scrollToField(firstFieldName);
  }, [form, errors]);

  // redirect add form to edit form on successful create action
  useEffect(() => {
    if (created_id) {
      setRedirectTo(pathTo('CareGuideEditScreen', { id: created_id }));
    }
    return () => {
      destroyForm();
    };
  }, [created_id, setRedirectTo, destroyForm]);

  // move segment row into new position after drag and drop reorder
  const onDragEnd = (result, move) => {
    if (result.destination) {
      move(result.source.index, result.destination.index);
    }
  };

  // submit data handler
  const submitData = async (values) => {
    Logger.log('debug', `CareGuideForm.submitData(###)`);

    // API POST/PUT payload
    let payload = {};
    let care_partner_tags = [];

    for (const input of Object.keys(data)) {
      if (input in values) {
        // transform entity refs to integer IDs
        if (['relationship'].includes(input)) {
          payload[input + '_id'] = parseInt(values[input]);
        }

        // transform entity refs to IDs
        else if (['owner'].includes(input)) {
          payload[input + '_id'] = values[input];
        }

        // transform dates into ISO
        else if (['started_at'].includes(input)) {
          payload[input] = Format.date(
            values[input],
            Config.get('API_DATETIME_FORMAT')
          );
        }

        // add ordering to segments
        else if (input === 'segments' && Array.isArray(values[input])) {
          payload[input] = values[input].map((x, i) => {
            // handle contents
            if ('contents' in x && Array.isArray(x['contents'])) {
              x['contents'] = x['contents'].map((c, index) => {
                if ('groups' in c && Array.isArray(c['groups'])) {
                  c['groups'] = c['groups'].map((g, i2) => {
                    const { order, ...group } = g; // remove old order
                    return { order: i2 + 1, ...group };
                  });
                }
                const { order, ...content } = c; // remove old order
                return { order: index + 1, ...content };
              });
            }
            const { order, key, ...segment } = x; // remove old order and key
            return { order: i + 1, ...segment };
          });
        }

        // transform lat/lng into geo point
        else if (['latitude', 'longitude'].includes(input)) {
          if (!('geo' in payload)) {
            payload['geo'] = {};
          }
          if (values[input]) payload['geo'][input] = values[input];
        } else {
          payload[input] = values[input];
        }
      }
    }

    for (const input of classes) {
      if (values[input] && values[input] !== '') {
        care_partner_tags.push(values[input]);
      }
    }

    delete payload['care_partner_tags'];
    payload['care_partner_tags_list'] = care_partner_tags;

    if (id) {
      // update
      props.update(id, payload, (success) => {
        if (success) {
          message.success(getI18n().t('feedback_form_success'));
        } else {
          message.error(getI18n().t('feedback_form_error'));
        }
      });
    } else {
      // create
      props.create(payload, (success) => {
        if (success) {
          message.success(getI18n().t('feedback_form_success'));
        } else {
          message.error(getI18n().t('feedback_form_error'));
        }
      });
    }
  };

  const onClickGeocodeHandler = (e) => {
    const postalCode = form.getFieldValue('postal_code');
    if (postalCode) {
      props.getGeocode(postalCode, (success, data) => {
        if (success) {
          form.setFieldsValue({
            latitude: data.latitude,
            longitude: data.longitude,
          });
        }
      });
    }
  };

  // form submit handler
  const handleFinish = async (values) => {
    Logger.log('debug', `CareGuideForm.handleFinish(###)`);
    if (!props.isSubmitting) {
      await submitData(values);
    }
  };

  // form error handler
  const handleFinishFailed = ({ values, errorFields, outOfDate }) => {
    Logger.log('debug', `CareGuideForm.handleFinishFailed(###)`);
    message.error(getI18n().t('feedback_form_error'));
    if (errorFields && errorFields.length > 0) {
      form.scrollToField(errorFields[0].name);
    }
  };

  // remove error message when input value changes
  const handleValuesChange = (changedValues, allValues) => {
    for (const key of Object.keys(changedValues)) {
      form.setFields([{ name: key, errors: [] }]);
    }
  };

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <Translation>
      {(t) => (
        <>
          <div className="care-guide-form">
            <Form
              name="care_guide_form"
              form={form}
              initialValues={data}
              onFinish={handleFinish}
              onFinishFailed={handleFinishFailed}
              onValuesChange={handleValuesChange}
              validateTrigger="onSubmit"
              {...layout.main}
            >
              <Row gutter={16}>
                <Col xs={24} lg={18}>
                  <Card
                    title={
                      id
                        ? t('care_guide_edit_title')
                        : t('care_guide_add_title')
                    }
                    extra={
                      isLoading ? (
                        <Spin
                          indicator={
                            <LoadingOutlined style={{ fontSize: 20 }} spin />
                          }
                        />
                      ) : null
                    }
                  >
                    {id ? (
                      <div className="form-group">
                        <Form.Item label={t('care_guide_care_guide_id')}>
                          <span className="ant-form-text">{id}</span>
                        </Form.Item>
                      </div>
                    ) : null}

                    {partnershipId !== 'N/A' && (
                      <div className="form-group">
                        <Form.Item label={t('care_guide_partnership_id')}>
                          <span className="ant-form-text">{partnershipId}</span>
                        </Form.Item>
                      </div>
                    )}

                    <div className="form-group">
                      <Form.Item
                        name="first_name"
                        label={t('care_guide_first_name')}
                        rules={[
                          {
                            required: true,
                            message: t('feedback_validation_required'),
                          },
                          {
                            type: 'string',
                            min: 1,
                            max: 40,
                            message: t('feedback_validation_char_range', {
                              min: 1,
                              max: 40,
                            }),
                          },
                        ]}
                      >
                        <Input autoFocus disabled={isLoading || isSubmitting} />
                      </Form.Item>
                    </div>

                    <div className="form-group">
                      <Form.Item
                        name="last_name"
                        label={t('care_guide_last_name')}
                        rules={[
                          {
                            required: true,
                            message: t('feedback_validation_required'),
                          },
                          {
                            type: 'string',
                            min: 2,
                            max: 40,
                            message: t('feedback_validation_char_range', {
                              min: 2,
                              max: 40,
                            }),
                          },
                        ]}
                      >
                        <Input disabled={isLoading || isSubmitting} />
                      </Form.Item>
                    </div>

                    <AccountInput
                      name="account_id"
                      label={t('care_guide_account')}
                      rules={[
                        {
                          required: true,
                          message: t('feedback_validation_required'),
                        },
                      ]}
                      form={form}
                      disabled={isLoading || isSubmitting}
                    />

                    <CareGuideOwnerInput
                      name="owner"
                      label={t('care_guide_owner')}
                      rules={[
                        {
                          required: true,
                          message: t('feedback_validation_required'),
                        },
                      ]}
                      form={form}
                      disabled={isLoading || isSubmitting}
                    />

                    <RelationshipInput
                      name="relationship"
                      label={t('care_guide_relationship')}
                      disabled={isLoading || isSubmitting}
                    />

                    <div className="form-group">
                      <Form.Item
                        name="started_at"
                        label={t('care_guide_started_at')}
                        rules={[
                          {
                            required: true,
                            message: t('feedback_validation_required'),
                          },
                        ]}
                      >
                        <DatePicker
                          showTime
                          disabled={isLoading || isSubmitting}
                          format={Config.get('DEFAULT_DATETIME_FORMAT')}
                        />
                      </Form.Item>
                    </div>

                    <div className="form-group">
                      <Form.Item
                        name="postal_code_input"
                        label={t('care_guide_postal_code')}
                        rules={[
                          {
                            required: true,
                            message: t('feedback_validation_required'),
                          },
                          {
                            type: 'string',
                            min: 5,
                            max: 20,
                            message: t('feedback_validation_char_range', {
                              min: 5,
                              max: 20,
                            }),
                          },
                        ]}
                      >
                        <Input disabled={isLoading || isSubmitting} />
                      </Form.Item>
                    </div>

                    {partnershipId && partnershipId !== 'N/A' && (
                      <>
                        <div className="form-group">
                          <TagInput
                            partnershipId={partnershipId}
                            disabled={isLoading || isSubmitting}
                            setClasses={setClasses}
                          />
                        </div>
                        <Form.Item
                          style={{ display: 'none' }}
                          name="care_partner_tags"
                        >
                          <Input type="hidden" />
                        </Form.Item>
                        <Row>
                          <Col offset={layout.main.labelCol.span}>
                            {form.getFieldError('care_partner_tags') && (
                              <h2 className="error-label">
                                {form.getFieldError('care_partner_tags')}
                              </h2>
                            )}
                          </Col>
                        </Row>
                      </>
                    )}

                    <div className="form-group">
                      <Form.Item
                        label={t('care_guide_latitude')}
                        style={{ marginBottom: 0 }}
                      >
                        <Row gutter={8}>
                          <Col span={12}>
                            <Form.Item
                              name="latitude"
                              rules={[
                                // {required: false, message: t('feedback_validation_required')},
                                {
                                  type: 'number',
                                  message: t('feedback_validation_number'),
                                },
                                {
                                  type: 'number',
                                  min: -90,
                                  max: 90,
                                  message: t('feedback_validation_range', {
                                    min: -90,
                                    max: 90,
                                  }),
                                },
                              ]}
                            >
                              <InputNumber
                                min={-90}
                                max={90}
                                disabled={isLoading || isSubmitting}
                                style={{ width: '100%' }}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Button
                              icon={<GlobalOutlined />}
                              onClick={onClickGeocodeHandler}
                              loading={props.isGeocodeLoading}
                            >
                              {t('care_guide_update_geocode')}
                            </Button>
                          </Col>
                        </Row>
                      </Form.Item>
                    </div>

                    <div className="form-group">
                      <Form.Item
                        label={t('care_guide_longitude')}
                        style={{ marginBottom: 0 }}
                      >
                        <Row gutter={8}>
                          <Col span={12}>
                            <Form.Item
                              name="longitude"
                              rules={[
                                // {required: false, message: t('feedback_validation_required')},
                                {
                                  type: 'number',
                                  message: t('feedback_validation_number'),
                                },
                                {
                                  type: 'number',
                                  min: -180,
                                  max: 180,
                                  message: t('feedback_validation_range', {
                                    min: -180,
                                    max: 180,
                                  }),
                                },
                              ]}
                            >
                              <InputNumber
                                min={-180}
                                max={180}
                                disabled={isLoading || isSubmitting}
                                style={{ width: '100%' }}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form.Item>
                    </div>
                  </Card>

                  {id || copyId ? (
                    <Card
                      title={t('care_guide_summary_title')}
                      extra={
                        isLoading ? (
                          <Spin
                            indicator={
                              <LoadingOutlined style={{ fontSize: 20 }} spin />
                            }
                          />
                        ) : null
                      }
                    >
                      <Form.List name="summary">
                        {(fields, { add, remove, move }) => {
                          return (
                            <>
                              <DragDropContext
                                onDragEnd={(result) => onDragEnd(result, move)}
                              >
                                <Droppable droppableId="droppable">
                                  {(provided, snapshot) => (
                                    <div
                                      {...provided.droppableProps}
                                      ref={provided.innerRef}
                                    >
                                      {fields.map((field, i) => (
                                        <SummaryInputRow
                                          field={field}
                                          remove={remove}
                                          index={i}
                                          key={i}
                                        />
                                      ))}
                                      {provided.placeholder}
                                    </div>
                                  )}
                                </Droppable>
                              </DragDropContext>

                              <Form.Item>
                                <Button
                                  type="dashed"
                                  onClick={() => {
                                    add();
                                  }}
                                  block
                                >
                                  <PlusOutlined /> Add row
                                </Button>
                              </Form.Item>
                            </>
                          );
                        }}
                      </Form.List>
                    </Card>
                  ) : null}

                  {id || copyId ? (
                    <Card
                      title={t('care_guide_segments_title')}
                      extra={
                        isLoading ? (
                          <Spin
                            indicator={
                              <LoadingOutlined style={{ fontSize: 20 }} spin />
                            }
                          />
                        ) : null
                      }
                    >
                      <Form.List name="segments">
                        {(segments, { add, remove, move }) => {
                          return (
                            <>
                              <DragDropContext
                                onDragEnd={(result) => onDragEnd(result, move)}
                              >
                                <Droppable droppableId="droppable">
                                  {(provided, snapshot) => (
                                    <div
                                      {...provided.droppableProps}
                                      ref={provided.innerRef}
                                    >
                                      {segments.map((segment, i) => (
                                        <SegmentInputRow
                                          segment={segment}
                                          remove={remove}
                                          form={form}
                                          index={i}
                                          key={i}
                                        />
                                      ))}
                                      {provided.placeholder}
                                    </div>
                                  )}
                                </Droppable>
                              </DragDropContext>

                              <Form.Item>
                                <Button
                                  type="dashed"
                                  onClick={() => {
                                    add();
                                  }}
                                  block
                                >
                                  <PlusOutlined /> Add section
                                </Button>
                              </Form.Item>
                            </>
                          );
                        }}
                      </Form.List>
                    </Card>
                  ) : null}
                </Col>

                <Col xs={24} lg={6}>
                  <Affix offsetTop={10}>
                    <Card title={t('form_metadata_header')}>
                      <FormMetadata
                        id={id}
                        isSubmitting={isSubmitting}
                        delete={props.delete.bind(this)}
                        deleteRedirectTo="CareGuidesScreen"
                        copyScreen="CareGuideAddScreen"
                        createdAt={props.createdAt}
                        updatedAt={props.updatedAt}
                      />
                    </Card>
                  </Affix>
                </Col>
              </Row>
            </Form>
          </div>
        </>
      )}
    </Translation>
  );
};

export default CareGuideForm;

Logger.log('silly', `CareGuideForm loaded.`);
