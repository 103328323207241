import {connect} from 'react-redux';

import JournalPartnerTagAddScreen from '../components/JournalPartnerTagAddScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.journalPartnerTags.get('isLoading'),
    success: state.journalPartnerTags.getIn(['form', 'success']),
  }
}

const JournalPartnerTagAddScreenContainer = connect(
  mapStateToProps
)(JournalPartnerTagAddScreen);

export default JournalPartnerTagAddScreenContainer;