import {connect} from 'react-redux';

import CareGuideSectionAddScreen from '../components/CareGuideSectionAddScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.careGuideSections.get('isLoading'),
    success: state.careGuideSections.getIn(['form', 'success']),
  }
}

const CareGuideSectionAddScreenContainer = connect(
  mapStateToProps
)(CareGuideSectionAddScreen);

export default CareGuideSectionAddScreenContainer;
