import {connect} from 'react-redux';

import {downloadMediaCSV} from '../../../../../state/modules/media/actions';
import MediaCSVButton from '../components/MediaCSVButton';

const mapStateToProps = state => {
  return {
    isDownloading: state.media.get('isCSVDownloading'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    download: (order, filter, cb) => {
      dispatch(downloadMediaCSV(order, filter, cb));
    }
  }
}

const MediaCSVButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MediaCSVButton);

export default MediaCSVButtonContainer;
