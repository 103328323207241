import {connect} from 'react-redux';

import CityAddScreen from '../components/CityAddScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.locationCities.get('areCitiesLoading'),
    success: state.locationCities.getIn(['form', 'success']),
  }
}

const CityAddScreenContainer = connect(
  mapStateToProps
)(CityAddScreen);

export default CityAddScreenContainer;
