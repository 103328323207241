import { connect } from 'react-redux';
import { denormalize } from 'normalizr';
import { getI18n } from 'react-i18next';

import { schema } from '../../../../../state/schema';
import {
  loadCalendarEvents,
  deleteCalendarEvent,
} from '../../../../../state/modules/calendarEvents/actions';
import { sendMessage } from '../../../../../state/actions';
import CalendarEventsList from '../components/CalendarEventsList';

const mapStateToProps = (state, ownProps) => {
  const page = ownProps.page;
  const limit = ownProps.limit;
  const order = ownProps.order;
  const result = state.calendarEvents.getIn(['pages', order, limit, page]);

  return {
    list: result
      ? result
          .map((x) => {
            return {
              key: x,
              ...denormalize(
                state.entities.getIn(['calendarEvents', x]),
                schema.calendarEvent,
                state.entities.toJS()
              ),
            };
          })
          .toArray()
          .map((x) => {
            // flatten some nested objects to make table easier to work with
            return {
              care_guide_name:
                x?.care_guide?.first_name && x?.care_guide?.last_name
                  ? `${x?.care_guide?.first_name} ${x?.care_guide?.last_name}`
                  : x?.amount_assigned_care_guides > 1
                  ? getI18n().t('calendar_events_is_multiple_care_guide')
                  : x.partnership?.name ??
                    getI18n().t('calendar_events_universal'),
              owner_name:
                x?.care_guide?.owner?.profile?.first_name &&
                x?.care_guide?.owner?.profile?.last_name
                  ? `${x?.care_guide?.owner?.profile?.first_name} ${x?.care_guide?.owner?.profile?.last_name}`
                  : x?.amount_assigned_care_guides > 1
                  ? getI18n().t('calendar_events_is_multiple_owner')
                  : x?.partnership?.name ??
                    getI18n().t('calendar_events_universal'),
              partnership_name:
                x?.partnership?.name ??
                (x?.care_guide
                  ? getI18n().t('calendar_events_empty')
                  : getI18n().t('calendar_events_universal')),
              partnership_plan:
                x?.partnership?.plan?.name ??
                (x?.care_guide
                  ? getI18n().t('calendar_events_empty')
                  : getI18n().t('calendar_events_universal')),
              profile_id: x.profile ? x.profile.id : '',
              parent_event_id: x.parent_event ? x.parent_event.id : '',
              profile_username:
                x.profile && x.profile.user ? x.profile.user.username : '',
              ...x,
            };
          })
      : [],
    isLoading: state.calendarEvents.get('isLoading'),
    total: state.calendarEvents.get('total'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (page, limit, order, filter, cb) => {
      dispatch(loadCalendarEvents(page, limit, order, filter, cb));
    },
    remove: (id, cb) => {
      dispatch(deleteCalendarEvent(id, cb));
    },
    sendMessage: (level, title, body, expires) => {
      dispatch(sendMessage(level, title, body, expires));
    },
  };
};

const CalendarEventsListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CalendarEventsList);

export default CalendarEventsListContainer;
