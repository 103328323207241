import {connect} from 'react-redux';

import UserProfilesPartnershipAddScreen from '../components/UserProfilesPartnershipAddScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.userProfilesPartnerships.get('isLoading'),
    success: state.userProfilesPartnerships.getIn(['form', 'success']),
  }
}

const UserProfilesPartnershipAddScreenContainer = connect(
  mapStateToProps
)(UserProfilesPartnershipAddScreen);

export default UserProfilesPartnershipAddScreenContainer;
