import {connect} from 'react-redux';
import {List} from 'immutable';

import {
  loadJournalTag,
  updateJournalTag,
  createJournalTag,
  deleteJournalTag,
  journalTagFormDestroy
} from '../../../../../state/modules/journalTags/actions';
import {sendMessage} from '../../../../../state/actions';
import JournalTagForm from '../components/JournalTagForm';

const inputs = List([
  'label',
  'status'
]);

const mapStateToProps = (state, ownProps) => {

  const data = {}, errors = {};
  for (const val of inputs.values()) {
    data[val] = state.entities.getIn(['journalTags', ownProps.id, val], '');
    errors[val] = state.journalTags.getIn(['form', 'errors', val]);
  }

  // change select number values to strings to work with ant design select inputs
  Object.keys(data).forEach(x => {
    if (['status', 'rating'].includes(x)) {
      data[x] = data[x].toString();
    }
  });

  return {
    isLoading: state.journalTags.get('isLoading'),
    isSubmitting: state.journalTags.getIn(['form', 'isSubmitting']),
    success: state.journalTags.getIn(['form', 'success']),
    created_id: state.journalTags.getIn(['form', 'created_id']),
    data: data,
    errors: errors,
    createdAt: state.entities.getIn(['journalTags', ownProps.id, 'created_at'], null),
    updatedAt: state.entities.getIn(['journalTags', ownProps.id, 'updated_at'], null),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (id, cb) => {
      dispatch(loadJournalTag(id, cb));
    },
    update: (id, data, cb) => {
      dispatch(updateJournalTag(id, data, cb));
    },
    create: (data, cb) => {
      dispatch(createJournalTag(data, cb));
    },
    delete: (id, cb) => {
      dispatch(deleteJournalTag(id, cb))
    },
    destroyForm: (formState) => {
      dispatch(journalTagFormDestroy(formState));
    },
    sendMessage: (level, title, body, expires) => {
      dispatch(sendMessage(level, title, body, expires));
    },
  }
}

const JournalTagFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(JournalTagForm);

export default JournalTagFormContainer;
