import {connect} from 'react-redux';
import {denormalize} from 'normalizr';

import {schema} from '../../../../../state/schema';
import {loadCareGuideTemplates, deleteCareGuideTemplate} from '../../../../../state/modules/careGuideTemplates/actions';
import {sendMessage} from '../../../../../state/actions';
import CareGuideTemplatesList from '../components/CareGuideTemplatesList';

const mapStateToProps = (state, ownProps) => {

  const page = ownProps.page;
  const limit = ownProps.limit;
  const order = ownProps.order;
  const result = state.careGuideTemplates.getIn(['pages', order, limit, page]);

  return {
    list: result 
      ? result.map(x => {
          return {
            key: x,
            ...denormalize(
              state.entities.getIn(['careGuideTemplates', x]),
              schema.careGuideTemplate,
              state.entities.toJS()
            )
          };
        }).toArray()
      : [],
    isLoading: state.careGuideTemplates.get('isLoading'),
    total: state.careGuideTemplates.get('total'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (page, limit, order, filter, cb) => {
      dispatch(loadCareGuideTemplates(page, limit, order, filter, cb));
    },
    remove: (id, cb) => {
      dispatch(deleteCareGuideTemplate(id, cb));
    },
    sendMessage: (level, title, body, expires) => {
      dispatch(sendMessage(level, title, body, expires));
    },
  }
}

const CareGuideTemplatesListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CareGuideTemplatesList);

export default CareGuideTemplatesListContainer;
