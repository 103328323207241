import {connect} from 'react-redux';

import {loadCareGuideTemplates} from '../../../../../state/modules/careGuideTemplates/actions';
import TemplateInput from '../components/TemplateInput';

const mapStateToProps = (state) => {
  const templates = state.entities.get('careGuideTemplates', {});
  return {
    options: Object.keys(templates).map(id => {return {label: templates[id].name, value: id}}),
    isLoading: state.careGuideTemplates.get('isLoading'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (page, limit, order, filter, cb) => {
      dispatch(loadCareGuideTemplates(page, limit, order, filter, cb));
    },
  }
}

const TemplateInputContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TemplateInput);

export default TemplateInputContainer;
