import { connect } from 'react-redux';

import UserProfilesPartnershipsCSVButton from '../components/UserProfilesPartnershipsCSVButton';

const mapStateToProps = (state) => {
  return {};
};

const UserProfilesPartnershipsCSVButtonContainer = connect(mapStateToProps)(
  UserProfilesPartnershipsCSVButton
);

export default UserProfilesPartnershipsCSVButtonContainer;
