import React from 'react';
import {Translation} from 'react-i18next';
import {Button} from 'antd';
import {CloudDownloadOutlined} from '@ant-design/icons';

import Logger from '../../../../../lib/Logger';

const CalendarEventsCSVButton =  ({ onClick, ...props }) => {

  return (
    <Translation>{(t) => 
      <Button
        size="small"
        icon={<CloudDownloadOutlined />}
        onClick={onClick}
      >
        {t('action_export')}
      </Button>
    }</Translation>
  );
}

export default CalendarEventsCSVButton;

Logger.log('silly', `CalendarEventsCSVButton loaded.`);
