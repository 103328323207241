import {getI18n} from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import JournalPostsScreen from './containers/JournalPostsScreenContainer';
import JournalPostAddScreen from './containers/JournalPostAddScreenContainer';
import JournalPostEditScreen from './containers/JournalPostEditScreenContainer';

const register = () => {
  Logger.log('debug', `journalPosts.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'journalPosts', 'routes'])) {
    Events.dispatch('ADD_MAIN_ROUTES', 
      {
        'JournalPostAddScreen': ['PrivateRoute', '/journal-posts/add', true, JournalPostAddScreen, getI18n().t('journal_post_route_add')],
        'JournalPostEditScreen': ['PrivateRoute', '/journal-posts/edit/:id([\\w\\-]+)', true, JournalPostEditScreen, getI18n().t('journal_post_route_edit')],
        'JournalPostsScreen': ['PrivateRoute', '/journal-posts/:page(\\d+)?', true, JournalPostsScreen, getI18n().t('journal_posts_route_list')],
      }
    );
  }
}

export default register;

Logger.log('silly', `journalPosts.register() loaded.`);
