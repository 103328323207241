import { connect } from 'react-redux';

import AdminPartnerClassesCSVButton from '../components/AdminPartnerClassesCSVButton';

const mapStateToProps = (state) => {
  return {};
};

const AdminPartnerClassesCSVButtonContainer = connect(mapStateToProps)(
  AdminPartnerClassesCSVButton
);

export default AdminPartnerClassesCSVButtonContainer;
