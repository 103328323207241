import {getI18n} from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import CareGuideNotesScreen from './containers/CareGuideNotesScreenContainer';
import CareGuideNoteAddScreen from './containers/CareGuideNoteAddScreenContainer';
import CareGuideNoteEditScreen from './containers/CareGuideNoteEditScreenContainer';

const register = () => {
  Logger.log('debug', `careGuideNotes.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'careGuideNotes', 'routes'])) {
    Events.dispatch('ADD_MAIN_ROUTES', 
      {
        'CareGuideNoteAddScreen': ['PrivateRoute', '/care-guide-notes/add', true, CareGuideNoteAddScreen, getI18n().t('care_guide_note_route_add')],
        'CareGuideNoteEditScreen': ['PrivateRoute', '/care-guide-notes/edit/:id([\\w\\-]+)', true, CareGuideNoteEditScreen, getI18n().t('care_guide_note_route_edit')],
        'CareGuideNotesScreen': ['PrivateRoute', '/care-guide-notes/:page(\\d+)?', true, CareGuideNotesScreen, getI18n().t('care_guide_notes_route_list')],
      }
    );
  }
}

export default register;

Logger.log('silly', `careGuideNotes.register() loaded.`);
