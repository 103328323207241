import { connect } from 'react-redux';

import CalendarEventExceptionsScreen from '../components/CalendarEventExceptionsScreen';
import { downloadCalendarEventExceptionsCSV } from '../../../../../state/modules/calendarEvents/actions';

const mapStateToProps = (state) => {
  return {
    total: state.calendarEvents.get('exceptionsTotal'),
    isLoading: state.calendarEvents.get('areExceptionsLoading'),
    isDownloading: state.calendarEvents.get('isCSVDownloading'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    download: (order, filter, cb) => {
      dispatch(downloadCalendarEventExceptionsCSV(order, filter, cb));
    },
  };
};

const CalendarEventExceptionsScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CalendarEventExceptionsScreen);

export default CalendarEventExceptionsScreenContainer;
