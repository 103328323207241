import { connect } from 'react-redux';
import { denormalize } from 'normalizr';

import { schema } from '../../../../../state/schema';
import {
  loadJournalPosts,
  deleteJournalPost,
} from '../../../../../state/modules/journalPosts/actions';
import { sendMessage } from '../../../../../state/actions';
import JournalPostsList from '../components/JournalPostsList';

const mapStateToProps = (state, ownProps) => {
  const page = ownProps.page;
  const limit = ownProps.limit;
  const order = ownProps.order;
  const result = state.journalPosts.getIn(['pages', order, limit, page]);

  return {
    list: result
      ? result
          .map((x) => {
            return {
              key: x,
              ...denormalize(
                state.entities.getIn(['journalPosts', x]),
                schema.journalPost,
                state.entities.toJS()
              ),
            };
          })
          .toArray()
          .map((x) => {
            // flatten some nested objects to make table easier to work with
            return {
              care_guide_name:
                x?.care_guide?.first_name && x?.care_guide?.last_name
                  ? `${x?.care_guide?.first_name} ${x?.care_guide?.last_name}`
                  : '',
              partnership_name: x?.care_guide?.partnership?.name ?? '',
              partnership_plan: x?.care_guide?.partnership?.plan?.name ?? '',
              care_guide_id: x.care_guide ? x.care_guide.id : '',
              profile_id: x.profile ? x.profile.id : '',
              profile_username:
                x.profile && x.profile.user ? x.profile.user.username : '',
              ...x,
            };
          })
      : [],
    isLoading: state.journalPosts.get('isLoading'),
    total: state.journalPosts.get('total'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (page, limit, order, filter, cb) => {
      dispatch(loadJournalPosts(page, limit, order, filter, cb));
    },
    remove: (id, cb) => {
      dispatch(deleteJournalPost(id, cb));
    },
    sendMessage: (level, title, body, expires) => {
      dispatch(sendMessage(level, title, body, expires));
    },
  };
};

const JournalPostsListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(JournalPostsList);

export default JournalPostsListContainer;
