import { connect } from 'react-redux';

import AdminPartnerClassAddScreen from '../components/AdminPartnerClassAddScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.adminPartnerClasses.get('isLoading'),
    success: state.adminPartnerClasses.getIn(['form', 'success']),
  }
}

const AdminPartnerClassAddScreenContainer = connect(
  mapStateToProps
)(AdminPartnerClassAddScreen);

export default AdminPartnerClassAddScreenContainer;