import { connect } from 'react-redux';

import { loadUsers } from '../../../../../state/modules/users/actions';
import UserInput from '../components/UserInput';

const mapStateToProps = (state) => {
  return {
    total: state.users.get('total'),
    isLoading: state.users.get('isLoading'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (page, limit, order, filter, cb) => {
      dispatch(loadUsers(page, limit, order, filter, cb));
    },
  };
};

const UserInputContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserInput);

export default UserInputContainer;
