import {connect} from 'react-redux';

import ResourceNoteEditScreen from '../components/ResourceNoteEditScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.resourceNotes.get('isLoading'),
    success: state.resourceNotes.getIn(['form', 'success']),
  }
}

const ResourceNoteEditScreenContainer = connect(
  mapStateToProps
)(ResourceNoteEditScreen);

export default ResourceNoteEditScreenContainer;
