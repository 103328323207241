import {getI18n} from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import ResourceTagsScreen from './containers/ResourceTagsScreenContainer';
import ResourceTagAddScreen from './containers/ResourceTagAddScreenContainer';
import ResourceTagEditScreen from './containers/ResourceTagEditScreenContainer';

const register = () => {
  Logger.log('debug', `resourceTags.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'resourceTags', 'routes'])) {
    Events.dispatch('ADD_MAIN_ROUTES', 
      {
        'ResourceTagAddScreen': ['PrivateRoute', '/resource-tags/add', true, ResourceTagAddScreen, getI18n().t('resource_tag_route_add')],
        'ResourceTagEditScreen': ['PrivateRoute', '/resource-tags/edit/:id([\\w\\-]+)', true, ResourceTagEditScreen, getI18n().t('resource_tag_route_edit')],
        'ResourceTagsScreen': ['PrivateRoute', '/resource-tags/:page(\\d+)?', true, ResourceTagsScreen, getI18n().t('resource_tags_route_list')],
      }
    );
  }
}

export default register;

Logger.log('silly', `resourceTags.register() loaded.`);
