import {connect} from 'react-redux';

import CalendarEventExceptionAddScreen from '../components/CalendarEventExceptionAddScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.calendarEvents.get('areExceptionsLoading'),
    success: state.calendarEvents.getIn(['exceptionForm', 'success']),
  }
}

const CalendarEventExceptionAddScreenContainer = connect(
  mapStateToProps
)(CalendarEventExceptionAddScreen);

export default CalendarEventExceptionAddScreenContainer;
