import {connect} from 'react-redux';

import CareGuideAddScreen from '../components/CareGuideAddScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.careGuides.get('isLoading'),
    success: state.careGuides.getIn(['form', 'success']),
  }
}

const CareGuideAddScreenContainer = connect(
  mapStateToProps
)(CareGuideAddScreen);

export default CareGuideAddScreenContainer;
