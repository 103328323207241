import {normalize} from 'normalizr';

import api from '../../api';
import {schema} from '../../schema';
import {addEntities, removeEntity} from '../../actions';
import Format from '../../../lib/Format';
import Logger from '../../../lib/Logger';

// ACTION TYPES

export const JOURNAL_TAG_LIST_REQUEST = 'JOURNAL_TAG_LIST_REQUEST';
export const JOURNAL_TAG_LIST_SUCCESS = 'JOURNAL_TAG_LIST_SUCCESS';
export const JOURNAL_TAG_LIST_FAILURE = 'JOURNAL_TAG_LIST_FAILURE';
export const JOURNAL_TAG_READ_REQUEST = 'JOURNAL_TAG_READ_REQUEST';
export const JOURNAL_TAG_READ_SUCCESS = 'JOURNAL_TAG_READ_SUCCESS';
export const JOURNAL_TAG_READ_FAILURE = 'JOURNAL_TAG_READ_FAILURE';
export const JOURNAL_TAG_UPDATE_REQUEST = 'JOURNAL_TAG_UPDATE_REQUEST';
export const JOURNAL_TAG_UPDATE_SUCCESS = 'JOURNAL_TAG_UPDATE_SUCCESS';
export const JOURNAL_TAG_UPDATE_FAILURE = 'JOURNAL_TAG_UPDATE_FAILURE';
export const JOURNAL_TAG_CREATE_REQUEST = 'JOURNAL_TAG_CREATE_REQUEST';
export const JOURNAL_TAG_CREATE_SUCCESS = 'JOURNAL_TAG_CREATE_SUCCESS';
export const JOURNAL_TAG_CREATE_FAILURE = 'JOURNAL_TAG_CREATE_FAILURE';
export const JOURNAL_TAG_DELETE_REQUEST = 'JOURNAL_TAG_DELETE_REQUEST';
export const JOURNAL_TAG_DELETE_SUCCESS = 'JOURNAL_TAG_DELETE_SUCCESS';
export const JOURNAL_TAG_DELETE_FAILURE = 'JOURNAL_TAG_DELETE_FAILURE';
export const JOURNAL_TAG_FORM_DESTROY = 'JOURNAL_TAG_FORM_DESTROY';
export const JOURNAL_TAG_CSV_DOWNLOAD_REQUEST = 'JOURNAL_TAG_CSV_DOWNLOAD_REQUEST';
export const JOURNAL_TAG_CSV_DOWNLOAD_SUCCESS = 'JOURNAL_TAG_CSV_DOWNLOAD_SUCCESS';
export const JOURNAL_TAG_CSV_DOWNLOAD_FAILURE = 'JOURNAL_TAG_CSV_DOWNLOAD_FAILURE';

// ACTION CREATORS

export function journalTagListRequest(page, limit, order, filter) {
  Logger.log('debug', `[journalTags.actions] journalTagListRequest(${page}, ${limit}, ${order}, %j)`, filter);
  return {
    type: JOURNAL_TAG_LIST_REQUEST,
    page: page,
    limit: limit,
    order: order,
    filter: filter
  }
}

export function journalTagListSuccess(data) {
  Logger.log('debug', `[journalTags.actions] journalTagListSuccess(%j)`, data);
  return {
    type: JOURNAL_TAG_LIST_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now()
  }
}

export function journalTagListFailure(error) {
  Logger.log('debug', `[journalTags.actions] journalTagListFailure(%j)`, error);
  return {
    type: JOURNAL_TAG_LIST_FAILURE,
    error: error
  }
}

export function journalTagReadRequest(id) {
  Logger.log('debug', `[journalTags.actions] journalTagReadRequest(${id})`);
  return {
    type: JOURNAL_TAG_READ_REQUEST,
    id: id
  }
}

export function journalTagReadSuccess(data) {
  Logger.log('debug', `[journalTags.actions] journalTagReadSuccess(%j)`, data);
  return {
    type: JOURNAL_TAG_READ_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function journalTagReadFailure(error) {
  Logger.log('debug', `[journalTags.actions] journalTagReadFailure(%j)`, error);
  return {
    type: JOURNAL_TAG_READ_FAILURE,
    error: error
  }
}

export function journalTagUpdateRequest(id, data) {
  Logger.log('debug', `[journalTags.actions] journalTagUpdateRequest(${id}, %j)`, data);
  return {
    type: JOURNAL_TAG_UPDATE_REQUEST,
  }
}

export function journalTagUpdateSuccess(data) {
  Logger.log('debug', `[journalTags.actions] journalTagUpdateSuccess(%j)`, data);
  return {
    type: JOURNAL_TAG_UPDATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function journalTagUpdateFailure(error) {
  Logger.log('debug', `[journalTags.actions] journalTagUpdateFailure(%j)`, error);
  return {
    type: JOURNAL_TAG_UPDATE_FAILURE,
    error: error
  }
}

export function journalTagCreateRequest(data) {
  Logger.log('debug', `[journalTags.actions] journalTagCreateRequest(%j)`, data);
  return {
    type: JOURNAL_TAG_CREATE_REQUEST,
  }
}

export function journalTagCreateSuccess(data) {
  Logger.log('debug', `[journalTags.actions] journalTagCreateSuccess(%j)`, data);
  return {
    type: JOURNAL_TAG_CREATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function journalTagCreateFailure(error) {
  Logger.log('debug', `[journalTags.actions] journalTagCreateFailure(%j)`, error);
  return {
    type: JOURNAL_TAG_CREATE_FAILURE,
    error: error
  }
}

export function journalTagDeleteRequest(id) {
  Logger.log('debug', `[journalTags.actions] journalTagDeleteRequest(${id})`);
  return {
    type: JOURNAL_TAG_DELETE_REQUEST,
    id: id
  }
}

export function journalTagDeleteSuccess(id) {
  Logger.log('debug', `[journalTags.actions] journalTagDeleteSuccess(${id})`);
  return {
    type: JOURNAL_TAG_DELETE_SUCCESS,
    id: id,
  }
}

export function journalTagDeleteFailure(error) {
  Logger.log('debug', `[journalTags.actions] journalTagDeleteFailure(%j)`, error);
  return {
    type: JOURNAL_TAG_DELETE_FAILURE,
    error: error
  }
}

export function journalTagFormDestroy(formState=null) {
  Logger.log('debug', `[journalTags.actions] journalTagFormDestroy(%j)`, formState);
  return {
    type: JOURNAL_TAG_FORM_DESTROY,
    form: formState
  }
}

export function journalTagCSVDownloadRequest(order, filter) {
  Logger.log('debug', `[state.journalTags.actions] journalTagCSVDownloadRequest()`);
  return {
    type: JOURNAL_TAG_CSV_DOWNLOAD_REQUEST,
    order: order,
    filter: filter,
  }
}

export function journalTagCSVDownloadSuccess() {
  Logger.log('debug', `[state.journalTags.actions] journalTagCSVDownloadSuccess()`);
  return {
    type: JOURNAL_TAG_CSV_DOWNLOAD_SUCCESS,
  }
}

export function journalTagCSVDownloadFailure() {
  Logger.log('debug', `[state.journalTags.actions] journalTagCSVDownloadFailure()`);
  return {
    type: JOURNAL_TAG_CSV_DOWNLOAD_FAILURE,
  }
}


// API THUNK ACTION CREATORS

export function loadJournalTags(page=1, limit=10, order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[journalTags.actions] loadJournalTags(${page}, ${limit}, ${order}, %j, ###)`, filter);

  return async function(dispatch) {
    dispatch(journalTagListRequest(page, limit, order, filter));

    // call API
    const response = await api.getJournalTags(page, limit, order, filter);
    let success = false;

    // get journal tags list success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API journal tags list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`);

      const normalizedEntities = normalize(response.getIn(['data', 'journal_tags']), [schema.journalTag]);
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(journalTagListSuccess(data));
      success = true;

    } else if (1 === page && 204 === response.get('status')) {

      Logger.log('info', `Get API journal tags success [empty]. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: []
      };
      dispatch(journalTagListSuccess(data));
      success = true;
      
    // get journal tags list failure
    } else {
      Logger.log('info', `Get API journal tags list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      dispatch(journalTagListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function loadJournalTag(id, cb=function(){}) {
  Logger.log('debug', `[journalTags.actions] loadJournalTag(${id}, ###)`);

  return async function(dispatch) {
    dispatch(journalTagReadRequest(id));

    // call API
    const response = await api.getJournalTag(id);
    let success = false;

    // get journal tag success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API journal tag success. ID: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'journal_tag'])], [schema.journalTag]);
      const data = {
        id: response.getIn(['data', 'journal_tag', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(journalTagReadSuccess(data));
      success = true;
      
    // get journal tag failure
    } else {
      Logger.log('info', `Get API journal tag failure. ID: ${id}.`);
      dispatch(journalTagReadFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function updateJournalTag(id, data, cb=function(){}) {
  Logger.log('debug', `[journalTags.actions] updateJournalTag(${id}, %j, ###)`, data);

  return async function(dispatch) {
    dispatch(journalTagUpdateRequest(id, data));

    // call API
    const response = await api.putJournalTag(id, data);
    let success = false;

    // put journal tag success
    if (200 === response.get('status')) {

      Logger.log('info', `PUT API journal tag success. User: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'journal_tag'])], [schema.journalTag]);
      const data = {
        id: response.getIn(['data', 'journal_tag', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(journalTagUpdateSuccess(data));
      success = true;
      
    // get journal tag failure
    } else {
      Logger.log('info', `PUT API journal tag failure. ID: ${id}.`);
      dispatch(journalTagUpdateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function createJournalTag(data, cb=function(){}) {
  Logger.log('debug', `[journalTags.actions] createJournalTag(%j, ###)`, data);

  return async function(dispatch) {
    dispatch(journalTagCreateRequest(data));

    // call API
    const response = await api.postJournalTags(data);
    let success = false;

    // post journal tags success
    if (201 === response.get('status')) {

      Logger.log('info', `POST API journal tags success. Post: ${response.getIn(['data', 'journal_tag', 'id'])}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'journal_tag'])], [schema.journalTag]);
      const data = {
        id: response.getIn(['data', 'journal_tag', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(journalTagCreateSuccess(data));
      success = true;
      
    // get journal tags failure
    } else {
      Logger.log('info', `POST API journal tags failure.`);
      dispatch(journalTagCreateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function deleteJournalTag(id, cb=function(){}) {
  Logger.log('debug', `[journalTags.actions] deleteJournalTag(${id}, ###)`);

  return async function(dispatch) {
    dispatch(journalTagDeleteRequest(id));

    // call API
    const response = await api.deleteJournalTag(id);
    let success = false;

    // delete journal tag success
    if (204 === response.get('status')) {

      Logger.log('info', `DELETE API journal tag success. ID: ${id}.`);

      dispatch(removeEntity({entityType: 'journal_tags', id: id}));
      dispatch(journalTagDeleteSuccess(id));
      success = true;
      
    // get journal tag failure
    } else {
      Logger.log('info', `DELETE API journal tag failure. ID: ${id}.`);
      dispatch(journalTagDeleteFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function downloadJournalTagsCSV(order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[state.journalTags.actions] downloadJournalTagsCSV(${order}, ${filter}, ###)`);

  return async function(dispatch) {
    dispatch(journalTagCSVDownloadRequest(order, filter));

    // call API
    const response = await api.getJournalTagsCSV(order, filter);

    // get journal tags CSV success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API journal tags CSV success. Order: ${order}`);
      const now = new Date();

      // trigger browser download
      const url = window.URL.createObjectURL(new Blob([response.get('data')]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `cv-journal-tags-${Format.date(now, 'YYYY-MM-DDTHHmm')}.csv`);
      document.body.appendChild(link);
      link.click();

      dispatch(journalTagCSVDownloadSuccess());
      
    // get journal tags CSV failure
    } else {
      Logger.log('info', `Get API journal tags CSV failure.`);
      dispatch(journalTagCSVDownloadFailure());
    }

    // callback function
    cb();
  }
}

Logger.log('silly', `journalTags.actions loaded.`);
