import {connect} from 'react-redux';
import {List} from 'immutable';
import moment from 'moment';

import {
  loadCareGuideHelper,
  updateCareGuideHelper,
  createCareGuideHelper,
  deleteCareGuideHelper,
  careGuideHelperFormDestroy
} from '../../../../../state/modules/careGuideHelpers/actions';
import {sendMessage} from '../../../../../state/actions';
import CareGuideHelperForm from '../components/CareGuideHelperForm';
import Config from '../../../../../Config';

const inputs = List([
  'care_guide',
  'profile',
  'role',
  'public_status',
  'note',
  'joined_at',
  'tags',
  'status'
]);

const mapStateToProps = (state, ownProps) => {

  const data = {}, errors = {};
  for (const val of inputs.values()) {
    data[val] = state.entities.getIn(['careGuideHelpers', ownProps.id, val], '');
    if (['profile'].includes(val)) {
      errors[val] = state.careGuideHelpers.getIn(['form', 'errors', 'user_profile_id']);
    }
    else if (['care_guide'].includes(val)) {
      errors[val] = state.careGuideHelpers.getIn(['form', 'errors', val + '_id']);
    }
    else {
      errors[val] = state.careGuideHelpers.getIn(['form', 'errors', val]);
    }
  }

  // change select number values to strings to work with ant design select inputs
  Object.keys(data).forEach(x => {
    if (['status'].includes(x)) {
      data[x] = data[x].toString();
    }
  });

  data['joined_at'] = data['joined_at']
    ? moment(data['joined_at'], Config.get('API_DATETIME_FORMAT'))
    : moment();

  if (data['tags'] === '') {
    data['tags'] = [];
  }

  return {
    isLoading: state.careGuideHelpers.get('isLoading'),
    isSubmitting: state.careGuideHelpers.getIn(['form', 'isSubmitting']),
    success: state.careGuideHelpers.getIn(['form', 'success']),
    created_id: state.careGuideHelpers.getIn(['form', 'created_id']),
    data: data,
    errors: errors,
    createdAt: state.entities.getIn(['careGuideHelpers', ownProps.id, 'created_at'], null),
    updatedAt: state.entities.getIn(['careGuideHelpers', ownProps.id, 'updated_at'], null),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (id, cb) => {
      dispatch(loadCareGuideHelper(id, cb));
    },
    update: (id, data, cb) => {
      dispatch(updateCareGuideHelper(id, data, cb));
    },
    create: (data, cb) => {
      dispatch(createCareGuideHelper(data, cb));
    },
    delete: (id, cb) => {
      dispatch(deleteCareGuideHelper(id, cb))
    },
    destroyForm: (formState) => {
      dispatch(careGuideHelperFormDestroy(formState));
    },
    sendMessage: (level, title, body, expires) => {
      dispatch(sendMessage(level, title, body, expires));
    },
  }
}

const CareGuideHelperFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CareGuideHelperForm);

export default CareGuideHelperFormContainer;
