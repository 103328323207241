import { Map } from 'immutable';

const Config = Map({
  ENVIRONMENT: process.env.NODE_ENV, // production, development, test
  API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
  API_APP_KEY: process.env.REACT_APP_API_APP_KEY,
  LOG_LEVEL: process.env.REACT_APP_LOG_LEVEL, // error, warn, info, verbose, debug, silly

  LANGUAGES: ['en', 'es'], // list of supported languages in two-letter format, default is first
  SESSION_TIMEOUT_COUNTDOWN: 60, // number of seconds to warn user before logging user out automatically
  AUTO_LOGOUT_COUNTDOWN: 15, // number of seconds to automatically log a user out if they requested log out but didn't confirm

  DEFAULT_LOGIN_SCREEN: 'LoginScreen', // routing key for default login screen
  DEFAULT_LOGIN_REDIRECT: 'DashboardScreen', // routing key for screen to redirect user to after successful authentication

  DEFAULT_AUTH_STORAGE: 'session', // session, local
  DEFAULT_LIST_LENGTH: 10,
  DEFAULT_MESSAGE_TIMEOUT: 5, // number of seconds to show status messages - such as success/failure to update a record
  DEFAULT_DATE_FORMAT: 'YYYY-MM-DD',
  DEFAULT_DATETIME_FORMAT: 'YYYY-MM-DD HH:mm:ss',

  API_DATETIME_FORMAT: 'YYYY-MM-DDTHH:mm:ssZZ',
  UI_COLLAPSE_SIDEBAR_WIDTH: 1024, // screen width at which sidebar collapses automatically

  GOOGLE_MAPS_API_KEY: process.env.REACT_APP_GOOGLE_MAPS_API_KEY, // API key for interacting with Google Maps API

  MODULE_TOGGLES: Map({
    session: { enabled: true, routes: true },
    dashboard: { enabled: true, routes: true },
    userAccount: { enabled: true, routes: true },
    users: { enabled: true, routes: true },
    administrators: { enabled: true, routes: true },
    roles: { enabled: true, routes: true },
    appKeys: { enabled: true, routes: true },
    termsOfServices: { enabled: true, routes: true },
    logins: { enabled: true, routes: true },
    plans: { enabled: true, routes: true },
    relationships: { enabled: true, routes: true },
    accounts: { enabled: true, routes: true },
    careGuides: { enabled: true, routes: true },
    careGuideOwners: { enabled: true, routes: true },
    careGuideHelpers: { enabled: true, routes: true },
    careGuideHelperTags: { enabled: true, routes: true },
    careGuideInvitations: { enabled: true, routes: true },
    careGuideTemplates: { enabled: true, routes: true },
    careGuideSections: { enabled: true, routes: true },
    journalPosts: { enabled: true, routes: true },
    journalReplies: { enabled: true, routes: true },
    journalTags: { enabled: true, routes: true },
    messages: { enabled: true, routes: true },
    calendarEvents: { enabled: true, routes: true },
    resources: { enabled: true, routes: true },
    resourceTags: { enabled: true, routes: true },
    resourceServiceTags: { enabled: true, routes: true },
    resourceNotes: { enabled: true, routes: true },
    registrationCodes: { enabled: true, routes: true },
    media: { enabled: true, routes: true },
    timezones: { enabled: true, routes: false },
    registration: { enabled: true, routes: true },
    passwordResets: { enabled: true, routes: true },
    locations: { enabled: true, routes: true },
    locationCountries: { enabled: true, routes: true },
    locationRegions: { enabled: true, routes: true },
    locationSubRegions: { enabled: true, routes: true },
    locationCities: { enabled: true, routes: true },
    locationPostalCodes: { enabled: true, routes: true },
    imports: { enabled: true, routes: true },
    partnerships: { enabled: true, routes: true },
    userProfilesPartnerships: { enabled: true, routes: true },
    userProfiles: { enabled: true, routes: false },
    journalPartnerTags: { enabled: true, routes: true },
    emails: { enabled: true, routes: true },
    careGuidePartnerTags: { enabled: true, routes: true },
    adminPartnerClasses: { enabled: true, routes: true },
    careGuideNotes: { enabled: true, routes: true },
    careGuideDocuments: { enabled: true, routes: true },
    importTasks: { enabled: true, routes: true },
    alerts: { enabled: true, routes: true },
    partnershipFormAnswers: { enabled: true, routes: true },
    dashboardSections: { enabled: true, routes: true },
    recommendedUtilities: { enabled: true, routes: true },
  }),
});

export default Config;
