import {connect} from 'react-redux';
import {denormalize} from 'normalizr';

import {schema} from '../../../../../state/schema';
import {loadImportJob} from '../../../../../state/modules/imports/actions';
import JobViewScreen from '../components/JobViewScreen';

const mapStateToProps = (state, ownProps) => {

  const job = ownProps.match.params.id
    ? denormalize(
        state.entities.getIn(['importJobs', ownProps.match.params.id]),
        schema.importJob,
        state.entities.toJS()
      )
    : null;;

  return {
    isLoading: state.imports.get('isLoading'),
    job: job,
    id: ownProps.match.params.id,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (id, cb) => {
      dispatch(loadImportJob(id, cb));
    },
  }
}

const JobViewScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(JobViewScreen);

export default JobViewScreenContainer;
