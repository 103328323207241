import {connect} from 'react-redux';

import {downloadTermsOfServicesCSV} from '../../../../../state/modules/termsOfServices/actions';
import TermsOfServicesCSVButton from '../components/TermsOfServicesCSVButton';

const mapStateToProps = state => {
  return {
    isDownloading: state.termsOfServices.get('isCSVDownloading'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    download: (order, filter, cb) => {
      dispatch(downloadTermsOfServicesCSV(order, filter, cb));
    }
  }
}

const TermsOfServicesCSVButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TermsOfServicesCSVButton);

export default TermsOfServicesCSVButtonContainer;
