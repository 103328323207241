import { connect } from 'react-redux';

import CareGuideNoteAddScreen from '../components/CareGuideNoteAddScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.careGuideNotes.get('isLoading'),
    success: state.careGuideNotes.getIn(['form', 'success']),
  }
}

const CareGuideNoteAddScreenContainer = connect(
  mapStateToProps
)(CareGuideNoteAddScreen);

export default CareGuideNoteAddScreenContainer;