import React from 'react';
import { Translation } from 'react-i18next';
import { Space } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';
import UserCareGuidesInput from '../../users/containers/UserCareGuidesInputContainer';
import PublicStatusInput from './PublicStatusInput';
import UserPartnershipInput from '../../users/containers/UserPartnershipInputContainer';

const ParticipantInputRow = ({
  isMultipleCareGuide,
  isEventPartner,
  partnershipId,
  participant,
  index,
  remove,
  careGuideIds,
  disabled = false,
  firstLoad,
  setFirstLoad,
  form,
  ...props
}) => {
  return (
    <Translation>
      {(t) => (
        <div key={participant.key} className="participant-input-row">
          <Space style={{ display: 'flex' }} align="start">
            {isEventPartner && isMultipleCareGuide === false ? (
              <>
                <UserPartnershipInput
                  firstLoad={firstLoad}
                  setFirstLoad={setFirstLoad}
                  partnershipId={partnershipId}
                  name={[participant.name, 'profile', 'id']}
                  fieldKey={[participant.fieldKey, 'profile', 'id']}
                  label={t('calendar_event_participant_user')}
                  rules={[
                    {
                      required: true,
                      message: t('feedback_validation_required'),
                    },
                  ]}
                  form={form}
                  disabled={disabled}
                />
              </>
            ) : (
              <>
                <UserCareGuidesInput
                  firstLoad={firstLoad}
                  setFirstLoad={setFirstLoad}
                  careGuideIds={careGuideIds}
                  name={[participant.name, 'profile', 'id']}
                  fieldKey={[participant.fieldKey, 'profile', 'id']}
                  label={t('calendar_event_participant_user')}
                  rules={[
                    {
                      required: true,
                      message: t('feedback_validation_required'),
                    },
                  ]}
                  form={form}
                  disabled={disabled}
                />
              </>
            )}

            <PublicStatusInput
              name={[participant.name, 'public_status']}
              fieldKey={[participant.fieldKey, 'public_status']}
              label={t('calendar_event_participant_public_status')}
              rules={[
                { required: true, message: t('feedback_validation_required') },
              ]}
              disabled={disabled}
            />

            <MinusCircleOutlined
              onClick={() => {
                remove(participant.name);
              }}
            />
          </Space>
        </div>
      )}
    </Translation>
  );
};

export default ParticipantInputRow;
