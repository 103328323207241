import { connect } from 'react-redux';

import { downloadPartnershipFormAnswersCSV } from '../../../../../state/modules/partnershipFormAnswers/actions';
import PartnershipFormAnswersCSVButton from '../components/PartnershipFormAnswersCSVButton';

const mapStateToProps = (state) => {
  return {
    isDownloading: state.partnershipFormAnswers.get('isCSVDownloading'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    download: (order, filter, cb) => {
      dispatch(downloadPartnershipFormAnswersCSV(order, filter, cb));
    },
  };
};

const PartnershipFormAnswersCSVButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnershipFormAnswersCSVButton);

export default PartnershipFormAnswersCSVButtonContainer;
