import React, {useEffect} from 'react';
import {Translation} from 'react-i18next';
import {Form, Select} from 'antd';

const TagInput = ({name, label, options, load, rules=[], ...props}) => {

  // load tags on mount
  useEffect(() => {
    load();
  }, [load]);

  return (
    <Translation>{(t) => 
      <div className="form-group">
        <Form.Item
          name={name}
          label={label}
          rules={rules}
        >
          <Select
            showSearch
            mode="multiple"
            disabled={props.isLoading || props.isSubmitting}
            loading={props.isLoading}
          >
            {options.map(x => <Select.Option key={x.value} value={x.value}>{x.label}</Select.Option>)}
          </Select>
        </Form.Item>
      </div>
    }</Translation>
  );
}

export default TagInput;
