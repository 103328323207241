import {connect} from 'react-redux';
import {List} from 'immutable';
import moment from 'moment';

import {
  loadResourceNote,
  updateResourceNote,
  createResourceNote,
  deleteResourceNote,
  resourceNoteFormDestroy
} from '../../../../../state/modules/resourceNotes/actions';
import {sendMessage} from '../../../../../state/actions';
import ResourceNoteForm from '../components/ResourceNoteForm';
import Config from '../../../../../Config';

const inputs = List([
  'profile',
  'resource_id',
  'body',
  'published_at',
  'edited_at',
  'public_status',
  'status'
]);

const mapStateToProps = (state, ownProps) => {

  const data = {}, errors = {};
  for (const val of inputs.values()) {
    data[val] = state.entities.getIn(['resourceNotes', ownProps.id, val], '');
    if (['profile'].includes(val)) {
      errors[val] = state.resourceNotes.getIn(['form', 'errors', 'user_profile_id']);
    }
    else {
      errors[val] = state.resourceNotes.getIn(['form', 'errors', val]);
    }
  }

  // change select number values to strings to work with ant design select inputs
  Object.keys(data).forEach(x => {
    if (['status'].includes(x)) {
      data[x] = data[x].toString();
    }
  });

  data['published_at'] = data['published_at']
    ? moment(data['published_at'], Config.get('API_DATETIME_FORMAT'))
    : moment();

  data['edited_at'] = data['edited_at']
    ? moment(data['edited_at'], Config.get('API_DATETIME_FORMAT'))
    : null;

  return {
    isLoading: state.resourceNotes.get('isLoading'),
    isSubmitting: state.resourceNotes.getIn(['form', 'isSubmitting']),
    success: state.resourceNotes.getIn(['form', 'success']),
    created_id: state.resourceNotes.getIn(['form', 'created_id']),
    data: data,
    errors: errors,
    createdAt: state.entities.getIn(['resourceNotes', ownProps.id, 'created_at'], null),
    updatedAt: state.entities.getIn(['resourceNotes', ownProps.id, 'updated_at'], null),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (id, cb) => {
      dispatch(loadResourceNote(id, cb));
    },
    update: (id, data, cb) => {
      dispatch(updateResourceNote(id, data, cb));
    },
    create: (data, cb) => {
      dispatch(createResourceNote(data, cb));
    },
    delete: (id, cb) => {
      dispatch(deleteResourceNote(id, cb))
    },
    destroyForm: (formState) => {
      dispatch(resourceNoteFormDestroy(formState));
    },
    sendMessage: (level, title, body, expires) => {
      dispatch(sendMessage(level, title, body, expires));
    },
  }
}

const ResourceNoteFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResourceNoteForm);

export default ResourceNoteFormContainer;
