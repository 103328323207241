import {normalize} from 'normalizr';

import api from '../../api';
import {schema} from '../../schema';
import {addEntities, removeEntity} from '../../actions';
import Format from '../../../lib/Format';
import Logger from '../../../lib/Logger';

// ACTION TYPES

export const RESOURCE_TAG_LIST_REQUEST = 'RESOURCE_TAG_LIST_REQUEST';
export const RESOURCE_TAG_LIST_SUCCESS = 'RESOURCE_TAG_LIST_SUCCESS';
export const RESOURCE_TAG_LIST_FAILURE = 'RESOURCE_TAG_LIST_FAILURE';
export const RESOURCE_TAG_READ_REQUEST = 'RESOURCE_TAG_READ_REQUEST';
export const RESOURCE_TAG_READ_SUCCESS = 'RESOURCE_TAG_READ_SUCCESS';
export const RESOURCE_TAG_READ_FAILURE = 'RESOURCE_TAG_READ_FAILURE';
export const RESOURCE_TAG_UPDATE_REQUEST = 'RESOURCE_TAG_UPDATE_REQUEST';
export const RESOURCE_TAG_UPDATE_SUCCESS = 'RESOURCE_TAG_UPDATE_SUCCESS';
export const RESOURCE_TAG_UPDATE_FAILURE = 'RESOURCE_TAG_UPDATE_FAILURE';
export const RESOURCE_TAG_CREATE_REQUEST = 'RESOURCE_TAG_CREATE_REQUEST';
export const RESOURCE_TAG_CREATE_SUCCESS = 'RESOURCE_TAG_CREATE_SUCCESS';
export const RESOURCE_TAG_CREATE_FAILURE = 'RESOURCE_TAG_CREATE_FAILURE';
export const RESOURCE_TAG_DELETE_REQUEST = 'RESOURCE_TAG_DELETE_REQUEST';
export const RESOURCE_TAG_DELETE_SUCCESS = 'RESOURCE_TAG_DELETE_SUCCESS';
export const RESOURCE_TAG_DELETE_FAILURE = 'RESOURCE_TAG_DELETE_FAILURE';
export const RESOURCE_TAG_FORM_DESTROY = 'RESOURCE_TAG_FORM_DESTROY';
export const RESOURCE_CSV_DOWNLOAD_REQUEST = 'RESOURCE_CSV_DOWNLOAD_REQUEST';
export const RESOURCE_CSV_DOWNLOAD_SUCCESS = 'RESOURCE_CSV_DOWNLOAD_SUCCESS';
export const RESOURCE_CSV_DOWNLOAD_FAILURE = 'RESOURCE_CSV_DOWNLOAD_FAILURE';

// ACTION CREATORS

export function resourceTagListRequest(page, limit, order, filter) {
  Logger.log('debug', `[resourceTags.actions] resourceTagListRequest(${page}, ${limit}, ${order}, %j)`, filter);
  return {
    type: RESOURCE_TAG_LIST_REQUEST,
    page: page,
    limit: limit,
    order: order,
    filter: filter
  }
}

export function resourceTagListSuccess(data) {
  Logger.log('debug', `[resourceTags.actions] resourceTagListSuccess(%j)`, data);
  return {
    type: RESOURCE_TAG_LIST_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now()
  }
}

export function resourceTagListFailure(error) {
  Logger.log('debug', `[resourceTags.actions] resourceTagListFailure(%j)`, error);
  return {
    type: RESOURCE_TAG_LIST_FAILURE,
    error: error
  }
}

export function resourceTagReadRequest(id) {
  Logger.log('debug', `[resourceTags.actions] resourceTagReadRequest(${id})`);
  return {
    type: RESOURCE_TAG_READ_REQUEST,
    id: id
  }
}

export function resourceTagReadSuccess(data) {
  Logger.log('debug', `[resourceTags.actions] resourceTagReadSuccess(%j)`, data);
  return {
    type: RESOURCE_TAG_READ_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function resourceTagReadFailure(error) {
  Logger.log('debug', `[resourceTags.actions] resourceTagReadFailure(%j)`, error);
  return {
    type: RESOURCE_TAG_READ_FAILURE,
    error: error
  }
}

export function resourceTagUpdateRequest(id, data) {
  Logger.log('debug', `[resourceTags.actions] resourceTagUpdateRequest(${id}, %j)`, data);
  return {
    type: RESOURCE_TAG_UPDATE_REQUEST,
  }
}

export function resourceTagUpdateSuccess(data) {
  Logger.log('debug', `[resourceTags.actions] resourceTagUpdateSuccess(%j)`, data);
  return {
    type: RESOURCE_TAG_UPDATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function resourceTagUpdateFailure(error) {
  Logger.log('debug', `[resourceTags.actions] resourceTagUpdateFailure(%j)`, error);
  return {
    type: RESOURCE_TAG_UPDATE_FAILURE,
    error: error
  }
}

export function resourceTagCreateRequest(data) {
  Logger.log('debug', `[resourceTags.actions] resourceTagCreateRequest(%j)`, data);
  return {
    type: RESOURCE_TAG_CREATE_REQUEST,
  }
}

export function resourceTagCreateSuccess(data) {
  Logger.log('debug', `[resourceTags.actions] resourceTagCreateSuccess(%j)`, data);
  return {
    type: RESOURCE_TAG_CREATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function resourceTagCreateFailure(error) {
  Logger.log('debug', `[resourceTags.actions] resourceTagCreateFailure(%j)`, error);
  return {
    type: RESOURCE_TAG_CREATE_FAILURE,
    error: error
  }
}

export function resourceTagDeleteRequest(id) {
  Logger.log('debug', `[resourceTags.actions] resourceTagDeleteRequest(${id})`);
  return {
    type: RESOURCE_TAG_DELETE_REQUEST,
    id: id
  }
}

export function resourceTagDeleteSuccess(id) {
  Logger.log('debug', `[resourceTags.actions] resourceTagDeleteSuccess(${id})`);
  return {
    type: RESOURCE_TAG_DELETE_SUCCESS,
    id: id,
  }
}

export function resourceTagDeleteFailure(error) {
  Logger.log('debug', `[resourceTags.actions] resourceTagDeleteFailure(%j)`, error);
  return {
    type: RESOURCE_TAG_DELETE_FAILURE,
    error: error
  }
}

export function resourceTagFormDestroy(formState=null) {
  Logger.log('debug', `[resourceTags.actions] resourceTagFormDestroy(%j)`, formState);
  return {
    type: RESOURCE_TAG_FORM_DESTROY,
    form: formState
  }
}

export function resourceTagCSVDownloadRequest(order, filter) {
  Logger.log('debug', `[state.careGuideHelperTags.actions] resourceTagCSVDownloadRequest()`);
  return {
    type: RESOURCE_CSV_DOWNLOAD_REQUEST,
    order: order,
    filter: filter,
  }
}

export function resourceTagCSVDownloadSuccess() {
  Logger.log('debug', `[state.careGuideHelperTags.actions] resourceTagCSVDownloadSuccess()`);
  return {
    type: RESOURCE_CSV_DOWNLOAD_SUCCESS,
  }
}

export function resourceTagCSVDownloadFailure() {
  Logger.log('debug', `[state.careGuideHelperTags.actions] resourceTagCSVDownloadFailure()`);
  return {
    type: RESOURCE_CSV_DOWNLOAD_FAILURE,
  }
}


// API THUNK ACTION CREATORS

export function loadResourceTags(page=1, limit=10, order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[resourceTags.actions] loadResourceTags(${page}, ${limit}, ${order}, %j, ###)`, filter);

  return async function(dispatch) {
    dispatch(resourceTagListRequest(page, limit, order, filter));

    // call API
    const response = await api.getResourceTags(page, limit, order, filter);
    let success = false;

    // get resource tags list success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API resource tags list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`);

      const normalizedEntities = normalize(response.getIn(['data', 'resource_tags']), [schema.resourceTag]);
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(resourceTagListSuccess(data));
      success = true;

    } else if (1 === page && 204 === response.get('status')) {

      Logger.log('info', `Get API resource tags success [empty]. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: []
      };
      dispatch(resourceTagListSuccess(data));
      success = true;
      
    // get resource tags list failure
    } else {
      Logger.log('info', `Get API resource tags list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      dispatch(resourceTagListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function loadResourceTag(id, cb=function(){}) {
  Logger.log('debug', `[resourceTags.actions] loadResourceTag(${id}, ###)`);

  return async function(dispatch) {
    dispatch(resourceTagReadRequest(id));

    // call API
    const response = await api.getResourceTag(id);
    let success = false;

    // get resource tag success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API resource tag success. ID: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'resource_tag'])], [schema.resourceTag]);
      const data = {
        id: response.getIn(['data', 'resource_tag', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(resourceTagReadSuccess(data));
      success = true;
      
    // get resource tag failure
    } else {
      Logger.log('info', `Get API resource tag failure. ID: ${id}.`);
      dispatch(resourceTagReadFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function updateResourceTag(id, data, cb=function(){}) {
  Logger.log('debug', `[resourceTags.actions] updateResourceTag(${id}, %j, ###)`, data);

  return async function(dispatch) {
    dispatch(resourceTagUpdateRequest(id, data));

    // call API
    const response = await api.putResourceTag(id, data);
    let success = false;

    // put resource tag success
    if (200 === response.get('status')) {

      Logger.log('info', `PUT API resource tag success. User: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'resource_tag'])], [schema.resourceTag]);
      const data = {
        id: response.getIn(['data', 'resource_tag', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(resourceTagUpdateSuccess(data));
      success = true;
      
    // get resource tag failure
    } else {
      Logger.log('info', `PUT API resource tag failure. ID: ${id}.`);
      dispatch(resourceTagUpdateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function createResourceTag(data, cb=function(){}) {
  Logger.log('debug', `[resourceTags.actions] createResourceTag(%j, ###)`, data);

  return async function(dispatch) {
    dispatch(resourceTagCreateRequest(data));

    // call API
    const response = await api.postResourceTags(data);
    let success = false;

    // post resource tags success
    if (201 === response.get('status')) {

      Logger.log('info', `POST API resource tags success. Post: ${response.getIn(['data', 'resource_tag', 'id'])}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'resource_tag'])], [schema.resourceTag]);
      const data = {
        id: response.getIn(['data', 'resource_tag', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(resourceTagCreateSuccess(data));
      success = true;
      
    // get resource tags failure
    } else {
      Logger.log('info', `POST API resource tags failure.`);
      dispatch(resourceTagCreateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function deleteResourceTag(id, cb=function(){}) {
  Logger.log('debug', `[resourceTags.actions] deleteResourceTag(${id}, ###)`);

  return async function(dispatch) {
    dispatch(resourceTagDeleteRequest(id));

    // call API
    const response = await api.deleteResourceTag(id);
    let success = false;

    // delete resource tag success
    if (204 === response.get('status')) {

      Logger.log('info', `DELETE API resource tag success. ID: ${id}.`);

      dispatch(removeEntity({entityType: 'resourceTags', id: id}));
      dispatch(resourceTagDeleteSuccess(id));
      success = true;
      
    // get resource tag failure
    } else {
      Logger.log('info', `DELETE API resource tag failure. ID: ${id}.`);
      dispatch(resourceTagDeleteFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function downloadResourceTagsCSV(order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[state.resourceTags.actions] downloadResourceTagsCSV(${order}, ${filter}, ###)`);

  return async function(dispatch) {
    dispatch(resourceTagCSVDownloadRequest(order, filter));

    // call API
    const response = await api.getResourceTagssCSV(order, filter);

    // get resource tags CSV success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API resource tags CSV success. Order: ${order}`);
      const now = new Date();

      // trigger browser download
      const url = window.URL.createObjectURL(new Blob([response.get('data')]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `cv-resource-tags-${Format.date(now, 'YYYY-MM-DDTHHmm')}.csv`);
      document.body.appendChild(link);
      link.click();

      dispatch(resourceTagCSVDownloadSuccess());
      
    // get resource tags CSV failure
    } else {
      Logger.log('info', `Get API resource tags CSV failure.`);
      dispatch(resourceTagCSVDownloadFailure());
    }

    // callback function
    cb();
  }
}

Logger.log('silly', `resourceTags.actions loaded.`);
