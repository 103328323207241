import {connect} from 'react-redux';
import {denormalize} from 'normalizr';

import {schema} from '../../../../../state/schema';
import {loadEmails, deleteEmail} from '../../../../../state/modules/emails/actions';
import {sendMessage} from '../../../../../state/actions';
import EmailsList from '../components/EmailsList';

const mapStateToProps = (state, ownProps) => {

  const page = ownProps.page;
  const limit = ownProps.limit;
  const order = ownProps.order;
  const result = state.emails.getIn(['pages', order, limit, page]);

  return {
    list: result 
      ? result.map(x => {
          return {
            key: x,
            ...denormalize(
              state.entities.getIn(['emails', x]),
              schema.email,
              state.entities.toJS()
            )
          };
        }).toArray()
      : [],
    isLoading: state.emails.get('isLoading'),
    total: state.emails.get('total'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (page, limit, order, filter, cb) => {
      dispatch(loadEmails(page, limit, order, filter, cb));
    },
    remove: (id, cb) => {
      dispatch(deleteEmail(id, cb));
    },
    sendMessage: (level, title, body, expires) => {
      dispatch(sendMessage(level, title, body, expires));
    },
  }
}

const EmailsListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailsList);

export default EmailsListContainer;
