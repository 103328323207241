import { connect } from 'react-redux';

import { downloadJournalPartnerTagsCSV } from '../../../../../state/modules/journalPartnerTags/actions';
import JournalPartnerTagsScreen from '../components/JournalPartnerTagsScreen';

const mapStateToProps = (state) => {
  return {
    total: state.journalPartnerTags.get('total'),
    isLoading: state.journalPartnerTags.get('isLoading'),
    isDownloading: state.journalPartnerTags.get('isCSVDownloading'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    download: (order, filter, cb) => {
      dispatch(downloadJournalPartnerTagsCSV(order, filter, cb));
    },
  };
};

const JournalPartnerTagsScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(JournalPartnerTagsScreen);

export default JournalPartnerTagsScreenContainer;
