import { connect } from 'react-redux';

import { loadJournalPosts } from '../../../../../state/modules/journalPosts/actions';
import JournalPostInput from '../components/JournalPostInput';

const mapStateToProps = (state) => {
  return {
    total: state.journalPosts.get('total'),
    isLoading: state.journalPosts.get('isLoading'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (page, limit, order, filter, cb) => {
      dispatch(loadJournalPosts(page, limit, order, filter, cb));
    },
  };
};

const JournalPostInputContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(JournalPostInput);

export default JournalPostInputContainer;
