import React from 'react';
import {Translation} from 'react-i18next';
import {Button, Form, Input, Space} from 'antd';
import {DragOutlined, MinusOutlined, PlusOutlined} from '@ant-design/icons';
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";

import SegmentContentInputRow from './SegmentContentInputRow';


const SegmentInputRow = ({segment, index, remove, form, ...props}) => {

  // move segment row into new position after drag and drop reorder
  const onDragEnd = (result, move) => {
    if (result.destination) {
      move(result.source.index, result.destination.index);
    }
  }

  // input column settings
  const layout = {
    main: {
      wrapperCol: {span: 24},
    },
  }

  return (
    <Draggable key={segment.key} draggableId={segment.key.toString()} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <Translation>{(t) => 
            <>
              <Space key={segment.key} style={{display: 'flex'}} align="start" className="care-guide-segment-spacer">

                <div className="drag-handle" {...provided.dragHandleProps}>
                  <DragOutlined />
                </div>

                <Form.Item
                  {...segment}
                  name={[segment.name, 'name']}
                  fieldKey={[segment.fieldKey, 'name']}
                  rules={[
                    {required: true, message: t('feedback_validation_required')},
                  ]}
                  {...layout.main}
                >
                  <Input placeholder="Name" />
                </Form.Item>

                <Button
                  danger
                  shape="circle"
                  size="small"
                  icon={<MinusOutlined />}
                  onClick={() => remove(segment.name)}
                />
              </Space>

              <div className="nested-list">
                <Form.List name={[segment.name, 'contents']}>
                    
                  {(contents, {add, remove, move}) => {
                    return(
                      <>
                        <DragDropContext onDragEnd={(result) => onDragEnd(result, move)}>
                          <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                              >
                                {contents.map((content, i) => (
                                  <SegmentContentInputRow
                                    segment={segment}
                                    content={content}
                                    defaultContentType={form.getFieldValue([`segments`, segment.name, `contents`, content.name, `type`])}
                                    remove={remove}
                                    form={form}
                                    index={i}
                                    key={i}
                                  />
                                ))}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>

                        <Form.Item>
                          <Button
                            type="dashed"
                            onClick={() => {
                              add();
                            }}
                            block
                          >
                            <PlusOutlined /> Add content
                          </Button>
                        </Form.Item>
                      </>
                    );
                  }}
                </Form.List>
              </div>

            </>
          }</Translation>
        </div>
      )}
    </Draggable>
  )
}

export default SegmentInputRow;
