import { connect } from 'react-redux';
import { List } from 'immutable';
import moment from 'moment';
import {
  loadCareGuideDocument,
  updateCareGuideDocument,
  createCareGuideDocument,
  deleteCareGuideDocument,
  careGuideDocumentsFormDestroy,
} from '../../../../../state/modules/careGuideDocuments/actions';
import CareGuideDocumentForm from '../components/CareGuideDocumentForm';
import Config from '../../../../../Config';

const inputs = List([
  'care_guide',
  'profile',
  'file_name',
  'user_file_name',
  'file_extension',
  'file_directory',
  'file_bucket',
  'size_bytes',
  'is_pinned',
  'description',
  'uploaded_at',
  'pinned_at',
  'status',
]);

const mapStateToProps = (state, ownProps) => {
  const data = {},
    errors = {};
  for (const val of inputs.values()) {
    data[val] = state.entities.getIn(
      ['careGuideDocuments', ownProps.id, val],
      ''
    );

    if (['profile'].includes(val)) {
      errors[val] = state.careGuideDocuments.getIn([
        'form',
        'errors',
        'user_profile_id',
      ]);
    } else if (['care_guide'].includes(val)) {
      errors[val] = state.careGuideDocuments.getIn([
        'form',
        'errors',
        val + '_id',
      ]);
    } else {
      errors[val] = state.careGuideDocuments.getIn(['form', 'errors', val]);
    }
  }

  // change select number values to strings to work with ant design select inputs
  Object.keys(data).forEach((x) => {
    if (['status'].includes(x)) {
      data[x] = data[x].toString();
    }
  });

  data['uploaded_at'] = data['uploaded_at']
    ? moment(data['uploaded_at'], Config.get('API_DATETIME_FORMAT'))
    : moment();

  data['pinned_at'] = data['pinned_at']
    ? moment(data['pinned_at'], Config.get('API_DATETIME_FORMAT'))
    : null;

  return {
    isLoading: state.careGuideDocuments.get('isLoading'),
    isSubmitting: state.careGuideDocuments.getIn(['form', 'isSubmitting']),
    success: state.careGuideDocuments.getIn(['form', 'success']),
    created_id: state.careGuideDocuments.getIn(['form', 'created_id']),
    data: data,
    errors: errors,
    createdAt: state.entities.getIn(
      ['careGuideDocuments', ownProps.id, 'created_at'],
      null
    ),
    updatedAt: state.entities.getIn(
      ['careGuideDocuments', ownProps.id, 'updated_at'],
      null
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (id, cb) => {
      dispatch(loadCareGuideDocument(id, cb));
    },
    update: (id, data, cb) => {
      dispatch(updateCareGuideDocument(id, data, cb));
    },
    create: (data, cb) => {
      dispatch(createCareGuideDocument(data, cb));
    },
    delete: (id, cb) => {
      dispatch(deleteCareGuideDocument(id, cb));
    },
    destroyForm: (formState) => {
      dispatch(careGuideDocumentsFormDestroy(formState));
    },
  };
};

const CareGuideDocumentFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CareGuideDocumentForm);

export default CareGuideDocumentFormContainer;
