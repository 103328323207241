import { connect } from 'react-redux';

import JournalPostsCSVButton from '../components/JournalPostsCSVButton';

const mapStateToProps = (state) => {
  return {};
};

const JournalPostsCSVButtonContainer = connect(mapStateToProps)(
  JournalPostsCSVButton
);

export default JournalPostsCSVButtonContainer;
