import {Map, List} from 'immutable';

import {
    POSTAL_CODE_LIST_REQUEST,
    POSTAL_CODE_LIST_SUCCESS,
    POSTAL_CODE_LIST_FAILURE,
    POSTAL_CODE_READ_REQUEST,
    POSTAL_CODE_READ_SUCCESS,
    POSTAL_CODE_READ_FAILURE,
    POSTAL_CODE_UPDATE_REQUEST,
    POSTAL_CODE_UPDATE_SUCCESS,
    POSTAL_CODE_UPDATE_FAILURE,
    POSTAL_CODE_CREATE_REQUEST,
    POSTAL_CODE_CREATE_SUCCESS,
    POSTAL_CODE_CREATE_FAILURE,
    POSTAL_CODE_DELETE_REQUEST,
    POSTAL_CODE_DELETE_SUCCESS,
    POSTAL_CODE_DELETE_FAILURE,
    POSTAL_CODE_FORM_DESTROY
  } from './actions';
  import Logger from '../../../lib/Logger';
  
export default function postal_codes(
    state=Map({
      areCitiesLoading: false
    }),
    action
  ) {
    Logger.log('debug', `[postal_code.reducers] postal codes(%j, %j)`, state, action);
  
    switch(action.type) {
  
      case POSTAL_CODE_LIST_REQUEST:
        return state.mergeDeep({
          areCitiesLoading: true
        });
  
      case POSTAL_CODE_LIST_SUCCESS:
        return state.mergeDeep({
          areCitiesLoading: false,
          pages: {
            [action.order]: {
              [action.limit]: {
                [action.page]: null
              }
            }
          },
          total: action.total,
          lastUpdated: action.receivedAt
        }).setIn(['pages', action.order, action.limit, action.page], List(action.result));
  
      case POSTAL_CODE_LIST_FAILURE:
        return state.mergeDeep({
          areCitiesLoading: false
        });
      case POSTAL_CODE_READ_REQUEST:
        return state.mergeDeep({
          areCitiesLoading: true
        });
  
      case POSTAL_CODE_READ_SUCCESS:
        return state.mergeDeep({
          areCitiesLoading: false,
          lastUpdated: action.receivedAt
        });
  
      case POSTAL_CODE_READ_FAILURE:
        return state.mergeDeep({
          areCitiesLoading: false
        });
        case POSTAL_CODE_UPDATE_REQUEST:
          return state.mergeDeep({
            areCitiesLoading: true,
            form: {
              isSubmitting: true,
              success: null,
              errors: null
            }
          });
    
        case POSTAL_CODE_UPDATE_SUCCESS:
          return state.mergeDeep({
            areCitiesLoading: false,
            form: {
              isSubmitting: false,
              success: true,
              errors: null
            },
            lastUpdated: action.receivedAt
          });
    
        case POSTAL_CODE_UPDATE_FAILURE:
          return state.mergeDeep({
            areCitiesLoading: false,
            form: {
              isSubmitting: false,
              success: false,
              errors: null
            }
          }).setIn(['form', 'errors'], action.error);
    
        case POSTAL_CODE_CREATE_REQUEST:
          return state.mergeDeep({
            areCitiesLoading: true,
            form: {
              isSubmitting: true,
              success: null,
              errors: null
            }
          });
    
        case POSTAL_CODE_CREATE_SUCCESS:
          return state.mergeDeep({
            areCitiesLoading: false,
            form: {
              isSubmitting: false,
              success: true,
              created_id: action.id,
              errors: null
            },
            lastUpdated: action.receivedAt
          });
    
        case POSTAL_CODE_CREATE_FAILURE:
          return state.mergeDeep({
            areCitiesLoading: false,
            form: {
              isSubmitting: false,
              success: false,
              errors: null
            }
          }).setIn(['form', 'errors'], action.error);
    
        case POSTAL_CODE_DELETE_REQUEST:
          return state.mergeDeep({
            areCitiesLoading: true
          });
    
        case POSTAL_CODE_DELETE_SUCCESS:
          return state.mergeDeep({
            areCitiesLoading: false,
            lastUpdated: action.receivedAt
          });
    
        case POSTAL_CODE_DELETE_FAILURE:
          return state.mergeDeep({
            areCitiesLoading: false
          });
    
        case POSTAL_CODE_FORM_DESTROY:
          return state.mergeDeep({
            form: null
          }).set('form', action.form);
          
      default:
        return state;
    }
  }
  
  Logger.log('silly', `postal_code.reducers loaded.`);