import { normalize } from 'normalizr';

import api from '../../api';
import { schema } from '../../schema';
import { addEntities, removeEntity } from '../../actions';
import Format from '../../../lib/Format';
import Logger from '../../../lib/Logger';

// ACTION TYPES

export const JOURNAL_REPLY_LIST_REQUEST = 'JOURNAL_REPLY_LIST_REQUEST';
export const JOURNAL_REPLY_LIST_SUCCESS = 'JOURNAL_REPLY_LIST_SUCCESS';
export const JOURNAL_REPLY_LIST_FAILURE = 'JOURNAL_REPLY_LIST_FAILURE';
export const JOURNAL_REPLY_READ_REQUEST = 'JOURNAL_REPLY_READ_REQUEST';
export const JOURNAL_REPLY_READ_SUCCESS = 'JOURNAL_REPLY_READ_SUCCESS';
export const JOURNAL_REPLY_READ_FAILURE = 'JOURNAL_REPLY_READ_FAILURE';
export const JOURNAL_REPLY_UPDATE_REQUEST = 'JOURNAL_REPLY_UPDATE_REQUEST';
export const JOURNAL_REPLY_UPDATE_SUCCESS = 'JOURNAL_REPLY_UPDATE_SUCCESS';
export const JOURNAL_REPLY_UPDATE_FAILURE = 'JOURNAL_REPLY_UPDATE_FAILURE';
export const JOURNAL_REPLY_CREATE_REQUEST = 'JOURNAL_REPLY_CREATE_REQUEST';
export const JOURNAL_REPLY_CREATE_SUCCESS = 'JOURNAL_REPLY_CREATE_SUCCESS';
export const JOURNAL_REPLY_CREATE_FAILURE = 'JOURNAL_REPLY_CREATE_FAILURE';
export const JOURNAL_REPLY_DELETE_REQUEST = 'JOURNAL_REPLY_DELETE_REQUEST';
export const JOURNAL_REPLY_DELETE_SUCCESS = 'JOURNAL_REPLY_DELETE_SUCCESS';
export const JOURNAL_REPLY_DELETE_FAILURE = 'JOURNAL_REPLY_DELETE_FAILURE';
export const JOURNAL_REPLY_FORM_DESTROY = 'JOURNAL_REPLY_FORM_DESTROY';
export const JOURNAL_REPLY_CSV_DOWNLOAD_REQUEST =
  'JOURNAL_REPLY_CSV_DOWNLOAD_REQUEST';
export const JOURNAL_REPLY_CSV_DOWNLOAD_SUCCESS =
  'JOURNAL_REPLY_CSV_DOWNLOAD_SUCCESS';
export const JOURNAL_REPLY_CSV_DOWNLOAD_FAILURE =
  'JOURNAL_REPLY_CSV_DOWNLOAD_FAILURE';

// ACTION CREATORS

export function journalReplyListRequest(page, limit, order, filter) {
  Logger.log(
    'debug',
    `[journalReplies.actions] journalReplyListRequest(${page}, ${limit}, ${order}, %j)`,
    filter
  );
  return {
    type: JOURNAL_REPLY_LIST_REQUEST,
    page: page,
    limit: limit,
    order: order,
    filter: filter,
  };
}

export function journalReplyListSuccess(data) {
  Logger.log(
    'debug',
    `[journalReplies.actions] journalReplyListSuccess(%j)`,
    data
  );
  return {
    type: JOURNAL_REPLY_LIST_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now(),
  };
}

export function journalReplyListFailure(error) {
  Logger.log(
    'debug',
    `[journalReplies.actions] journalReplyListFailure(%j)`,
    error
  );
  return {
    type: JOURNAL_REPLY_LIST_FAILURE,
    error: error,
  };
}

export function journalReplyReadRequest(id) {
  Logger.log(
    'debug',
    `[journalReplies.actions] journalReplyReadRequest(${id})`
  );
  return {
    type: JOURNAL_REPLY_READ_REQUEST,
    id: id,
  };
}

export function journalReplyReadSuccess(data) {
  Logger.log(
    'debug',
    `[journalReplies.actions] journalReplyReadSuccess(%j)`,
    data
  );
  return {
    type: JOURNAL_REPLY_READ_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function journalReplyReadFailure(error) {
  Logger.log(
    'debug',
    `[journalReplies.actions] journalReplyReadFailure(%j)`,
    error
  );
  return {
    type: JOURNAL_REPLY_READ_FAILURE,
    error: error,
  };
}

export function journalReplyUpdateRequest(id, data) {
  Logger.log(
    'debug',
    `[journalReplies.actions] journalReplyUpdateRequest(${id}, %j)`,
    data
  );
  return {
    type: JOURNAL_REPLY_UPDATE_REQUEST,
  };
}

export function journalReplyUpdateSuccess(data) {
  Logger.log(
    'debug',
    `[journalReplies.actions] journalReplyUpdateSuccess(%j)`,
    data
  );
  return {
    type: JOURNAL_REPLY_UPDATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function journalReplyUpdateFailure(error) {
  Logger.log(
    'debug',
    `[journalReplies.actions] journalReplyUpdateFailure(%j)`,
    error
  );
  return {
    type: JOURNAL_REPLY_UPDATE_FAILURE,
    error: error,
  };
}

export function journalReplyCreateRequest(data) {
  Logger.log(
    'debug',
    `[journalReplies.actions] journalReplyCreateRequest(%j)`,
    data
  );
  return {
    type: JOURNAL_REPLY_CREATE_REQUEST,
  };
}

export function journalReplyCreateSuccess(data) {
  Logger.log(
    'debug',
    `[journalReplies.actions] journalReplyCreateSuccess(%j)`,
    data
  );
  return {
    type: JOURNAL_REPLY_CREATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function journalReplyCreateFailure(error) {
  Logger.log(
    'debug',
    `[journalReplies.actions] journalReplyCreateFailure(%j)`,
    error
  );
  return {
    type: JOURNAL_REPLY_CREATE_FAILURE,
    error: error,
  };
}

export function journalReplyDeleteRequest(id) {
  Logger.log(
    'debug',
    `[journalReplies.actions] journalReplyDeleteRequest(${id})`
  );
  return {
    type: JOURNAL_REPLY_DELETE_REQUEST,
    id: id,
  };
}

export function journalReplyDeleteSuccess(id) {
  Logger.log(
    'debug',
    `[journalReplies.actions] journalReplyDeleteSuccess(${id})`
  );
  return {
    type: JOURNAL_REPLY_DELETE_SUCCESS,
    id: id,
  };
}

export function journalReplyDeleteFailure(error) {
  Logger.log(
    'debug',
    `[journalReplies.actions] journalReplyDeleteFailure(%j)`,
    error
  );
  return {
    type: JOURNAL_REPLY_DELETE_FAILURE,
    error: error,
  };
}

export function journalReplyFormDestroy(formState = null) {
  Logger.log(
    'debug',
    `[journalReplies.actions] journalReplyFormDestroy(%j)`,
    formState
  );
  return {
    type: JOURNAL_REPLY_FORM_DESTROY,
    form: formState,
  };
}

export function journalReplyCSVDownloadRequest(order, filter) {
  Logger.log(
    'debug',
    `[state.journalReplies.actions] journalReplyCSVDownloadRequest()`
  );
  return {
    type: JOURNAL_REPLY_CSV_DOWNLOAD_REQUEST,
    order: order,
    filter: filter,
  };
}

export function journalReplyCSVDownloadSuccess() {
  Logger.log(
    'debug',
    `[state.journalReplies.actions] journalReplyCSVDownloadSuccess()`
  );
  return {
    type: JOURNAL_REPLY_CSV_DOWNLOAD_SUCCESS,
  };
}

export function journalReplyCSVDownloadFailure() {
  Logger.log(
    'debug',
    `[state.journalReplies.actions] journalReplyCSVDownloadFailure()`
  );
  return {
    type: JOURNAL_REPLY_CSV_DOWNLOAD_FAILURE,
  };
}

// API THUNK ACTION CREATORS

export function loadJournalReplies(
  page = 1,
  limit = 10,
  order = null,
  filter = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[journalReplies.actions] loadJournalReplies(${page}, ${limit}, ${order}, %j, ###)`,
    filter
  );

  return async function (dispatch) {
    dispatch(journalReplyListRequest(page, limit, order, filter));

    // call API
    const response = await api.getJournalReplies(page, limit, order, filter);
    let success = false;

    // get journal replies list success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API journal replies list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );

      const normalizedEntities = normalize(
        response.getIn(['data', 'journal_replies']),
        [schema.journalReply]
      );
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result,
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(journalReplyListSuccess(data));
      success = true;
    } else if (1 === page && 204 === response.get('status')) {
      Logger.log(
        'info',
        `Get API journal replies success [empty]. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: [],
      };
      dispatch(journalReplyListSuccess(data));
      success = true;

      // get journal replies list failure
    } else {
      Logger.log(
        'info',
        `Get API journal replies list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      dispatch(journalReplyListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function loadJournalReply(id, cb = function () {}) {
  Logger.log('debug', `[journalReplies.actions] loadJournalReply(${id}, ###)`);

  return async function (dispatch) {
    dispatch(journalReplyReadRequest(id));

    // call API
    const response = await api.getJournalReply(id);
    let success = false;

    // get journal reply success
    if (200 === response.get('status')) {
      Logger.log('info', `Get API journal reply success. ID: ${id}.`);

      const normalizedEntities = normalize(
        [response.getIn(['data', 'journal_reply'])],
        [schema.journalReply]
      );
      const data = {
        id: response.getIn(['data', 'journal_reply', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(journalReplyReadSuccess(data));
      success = true;

      // get journal reply failure
    } else {
      Logger.log('info', `Get API journal reply failure. ID: ${id}.`);
      dispatch(journalReplyReadFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function updateJournalReply(id, data, cb = function () {}) {
  Logger.log(
    'debug',
    `[journalReplies.actions] updateJournalReply(${id}, %j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(journalReplyUpdateRequest(id, data));

    // call API
    const response = await api.putJournalReply(id, data);
    let success = false;

    // put journal reply success
    if (200 === response.get('status')) {
      Logger.log('info', `PUT API journal reply success. User: ${id}.`);

      const normalizedEntities = normalize(
        [response.getIn(['data', 'journal_reply'])],
        [schema.journalReply]
      );
      const data = {
        id: response.getIn(['data', 'journal_reply', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(journalReplyUpdateSuccess(data));
      success = true;

      // get journal reply failure
    } else {
      Logger.log('info', `PUT API journal reply failure. ID: ${id}.`);
      dispatch(journalReplyUpdateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function createJournalReply(data, cb = function () {}) {
  Logger.log(
    'debug',
    `[journalReplies.actions] createJournalReply(%j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(journalReplyCreateRequest(data));

    // call API
    const response = await api.postJournalReplies(data);
    let success = false;

    // post journal replies success
    if (201 === response.get('status')) {
      Logger.log(
        'info',
        `POST API journal replies success. Post: ${response.getIn([
          'data',
          'journal_reply',
          'id',
        ])}.`
      );

      const normalizedEntities = normalize(
        [response.getIn(['data', 'journal_reply'])],
        [schema.journalReply]
      );
      const data = {
        id: response.getIn(['data', 'journal_reply', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(journalReplyCreateSuccess(data));
      success = true;

      // get journal replies failure
    } else {
      Logger.log('info', `POST API journal replies failure.`);
      dispatch(journalReplyCreateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function deleteJournalReply(id, cb = function () {}) {
  Logger.log(
    'debug',
    `[journalReplies.actions] deleteJournalReply(${id}, ###)`
  );

  return async function (dispatch) {
    dispatch(journalReplyDeleteRequest(id));

    // call API
    const response = await api.deleteJournalReply(id);
    let success = false;

    // delete journal reply success
    if (204 === response.get('status')) {
      Logger.log('info', `DELETE API journal reply success. ID: ${id}.`);

      dispatch(removeEntity({ entityType: 'journal_replies', id: id }));
      dispatch(journalReplyDeleteSuccess(id));
      success = true;

      // get journal reply failure
    } else {
      Logger.log('info', `DELETE API journal reply failure. ID: ${id}.`);
      dispatch(journalReplyDeleteFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function journalRepliesCSV(
  order = null,
  filter = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.journalReplies.actions] journalRepliesCSV(${order}, ${filter}, ###)`
  );

  return async function (dispatch) {
    dispatch(journalReplyCSVDownloadRequest(order, filter));

    // call API
    const response = await api.getJournalRepliesCSV(order, filter);
    let success = false;

    // get journal replies CSV success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API journal replies CSV success. Order: ${order}`
      );
      const now = new Date();

      // trigger browser download
      const url = window.URL.createObjectURL(new Blob([response.get('data')]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `cv-journal-replies-${Format.date(now, 'YYYY-MM-DDTHHmm')}.csv`
      );
      document.body.appendChild(link);
      link.click();

      dispatch(journalReplyCSVDownloadSuccess());
      success = true;
      // get journal replies CSV failure
    } else {
      Logger.log('info', `Get API journal replies CSV failure.`);
      dispatch(journalReplyCSVDownloadFailure());
    }

    // callback function
    cb(success);
  };
}

Logger.log('silly', `journalReplies.actions loaded.`);
