import { normalize } from 'normalizr';

import api from '../../api';
import { schema } from '../../schema';
import { addEntities, removeEntity } from '../../actions';
import Format from '../../../lib/Format';
import Logger from '../../../lib/Logger';

// ACTION TYPES

export const CALENDAR_EVENT_LIST_REQUEST = 'CALENDAR_EVENT_LIST_REQUEST';
export const CALENDAR_EVENT_LIST_SUCCESS = 'CALENDAR_EVENT_LIST_SUCCESS';
export const CALENDAR_EVENT_LIST_FAILURE = 'CALENDAR_EVENT_LIST_FAILURE';
export const CALENDAR_EVENT_READ_REQUEST = 'CALENDAR_EVENT_READ_REQUEST';
export const CALENDAR_EVENT_READ_SUCCESS = 'CALENDAR_EVENT_READ_SUCCESS';
export const CALENDAR_EVENT_READ_FAILURE = 'CALENDAR_EVENT_READ_FAILURE';
export const CALENDAR_EVENT_UPDATE_REQUEST = 'CALENDAR_EVENT_UPDATE_REQUEST';
export const CALENDAR_EVENT_UPDATE_SUCCESS = 'CALENDAR_EVENT_UPDATE_SUCCESS';
export const CALENDAR_EVENT_UPDATE_FAILURE = 'CALENDAR_EVENT_UPDATE_FAILURE';
export const CALENDAR_EVENT_CREATE_REQUEST = 'CALENDAR_EVENT_CREATE_REQUEST';
export const CALENDAR_EVENT_CREATE_SUCCESS = 'CALENDAR_EVENT_CREATE_SUCCESS';
export const CALENDAR_EVENT_CREATE_FAILURE = 'CALENDAR_EVENT_CREATE_FAILURE';
export const CALENDAR_EVENT_DELETE_REQUEST = 'CALENDAR_EVENT_DELETE_REQUEST';
export const CALENDAR_EVENT_DELETE_SUCCESS = 'CALENDAR_EVENT_DELETE_SUCCESS';
export const CALENDAR_EVENT_DELETE_FAILURE = 'CALENDAR_EVENT_DELETE_FAILURE';
export const CALENDAR_EVENT_FORM_DESTROY = 'CALENDAR_EVENT_FORM_DESTROY';
export const CALENDAR_EVENT_CSV_DOWNLOAD_REQUEST =
  'CALENDAR_EVENT_CSV_DOWNLOAD_REQUEST';
export const CALENDAR_EVENT_CSV_DOWNLOAD_SUCCESS =
  'CALENDAR_EVENT_CSV_DOWNLOAD_SUCCESS';
export const CALENDAR_EVENT_CSV_DOWNLOAD_FAILURE =
  'CALENDAR_EVENT_CSV_DOWNLOAD_FAILURE';
export const CALENDAR_EVENT_EXCEPTION_LIST_REQUEST =
  'CALENDAR_EVENT_EXCEPTION_LIST_REQUEST';
export const CALENDAR_EVENT_EXCEPTION_LIST_SUCCESS =
  'CALENDAR_EVENT_EXCEPTION_LIST_SUCCESS';
export const CALENDAR_EVENT_EXCEPTION_LIST_FAILURE =
  'CALENDAR_EVENT_EXCEPTION_LIST_FAILURE';
export const CALENDAR_EVENT_EXCEPTION_READ_REQUEST =
  'CALENDAR_EVENT_EXCEPTION_READ_REQUEST';
export const CALENDAR_EVENT_EXCEPTION_READ_SUCCESS =
  'CALENDAR_EVENT_EXCEPTION_READ_SUCCESS';
export const CALENDAR_EVENT_EXCEPTION_READ_FAILURE =
  'CALENDAR_EVENT_EXCEPTION_READ_FAILURE';
export const CALENDAR_EVENT_EXCEPTION_UPDATE_REQUEST =
  'CALENDAR_EVENT_EXCEPTION_UPDATE_REQUEST';
export const CALENDAR_EVENT_EXCEPTION_UPDATE_SUCCESS =
  'CALENDAR_EVENT_EXCEPTION_UPDATE_SUCCESS';
export const CALENDAR_EVENT_EXCEPTION_UPDATE_FAILURE =
  'CALENDAR_EVENT_EXCEPTION_UPDATE_FAILURE';
export const CALENDAR_EVENT_EXCEPTION_CREATE_REQUEST =
  'CALENDAR_EVENT_EXCEPTION_CREATE_REQUEST';
export const CALENDAR_EVENT_EXCEPTION_CREATE_SUCCESS =
  'CALENDAR_EVENT_EXCEPTION_CREATE_SUCCESS';
export const CALENDAR_EVENT_EXCEPTION_CREATE_FAILURE =
  'CALENDAR_EVENT_EXCEPTION_CREATE_FAILURE';
export const CALENDAR_EVENT_EXCEPTION_DELETE_REQUEST =
  'CALENDAR_EVENT_EXCEPTION_DELETE_REQUEST';
export const CALENDAR_EVENT_EXCEPTION_DELETE_SUCCESS =
  'CALENDAR_EVENT_EXCEPTION_DELETE_SUCCESS';
export const CALENDAR_EVENT_EXCEPTION_DELETE_FAILURE =
  'CALENDAR_EVENT_EXCEPTION_DELETE_FAILURE';
export const CALENDAR_EVENT_EXCEPTION_FORM_DESTROY =
  'CALENDAR_EVENT_EXCEPTION_FORM_DESTROY';
export const CALENDAR_EVENT_EXCEPTION_CSV_DOWNLOAD_REQUEST =
  'CALENDAR_EVENT_EXCEPTION_CSV_DOWNLOAD_REQUEST';
export const CALENDAR_EVENT_EXCEPTION_CSV_DOWNLOAD_SUCCESS =
  'CALENDAR_EVENT_EXCEPTION_CSV_DOWNLOAD_SUCCESS';
export const CALENDAR_EVENT_EXCEPTION_CSV_DOWNLOAD_FAILURE =
  'CALENDAR_EVENT_EXCEPTION_CSV_DOWNLOAD_FAILURE';

export const CALENDAR_EVENT_LIST_PARTICIPANTS_REQUEST =
  'CALENDAR_EVENT_LIST_PARTICIPANTS_REQUEST';
export const CALENDAR_EVENT_LIST_PARTICIPANTS_SUCCESS =
  'CALENDAR_EVENT_LIST_PARTICIPANTS_SUCCESS';
export const CALENDAR_EVENT_LIST_PARTICIPANTS_FAILURE =
  'CALENDAR_EVENT_LIST_PARTICIPANTS_FAILURE';
export const CALENDAR_EVENT_DELETE_PARTICIPANT_REQUEST =
  'CALENDAR_EVENT_DELETE_PARTICIPANT_REQUEST';
export const CALENDAR_EVENT_DELETE_PARTICIPANT_SUCCESS =
  'CALENDAR_EVENT_DELETE_PARTICIPANT_SUCCESS';
export const CALENDAR_EVENT_DELETE_PARTICIPANT_FAILURE =
  'CALENDAR_EVENT_DELETE_PARTICIPANT_FAILURE';
export const CALENDAR_EVENT_SHOW_EVENT_PARTICIPANT_FORM =
  'CALENDAR_EVENT_SHOW_EVENT_PARTICIPANT_FORM';
export const CALENDAR_EVENT_HIDE_EVENT_PARTICIPANT_FORM =
  'CALENDAR_EVENT_HIDE_EVENT_PARTICIPANT_FORM';
export const CALENDAR_EVENT_EVENT_PARTICIPANT_FORM_DESTROY =
  'CALENDAR_EVENT_EVENT_PARTICIPANT_FORM_DESTROY';
export const CALENDAR_EVENT_UPDATE_PARTICIPANT_REQUEST =
  'CALENDAR_EVENT_UPDATE_PARTICIPANT_REQUEST';
export const CALENDAR_EVENT_UPDATE_PARTICIPANT_SUCCESS =
  'CALENDAR_EVENT_UPDATE_PARTICIPANT_SUCCESS';
export const CALENDAR_EVENT_UPDATE_PARTICIPANT_FAILURE =
  'CALENDAR_EVENT_UPDATE_PARTICIPANT_FAILURE';
export const CALENDAR_EVENT_CREATE_PARTICIPANT_REQUEST =
  'CALENDAR_EVENT_CREATE_PARTICIPANT_REQUEST';
export const CALENDAR_EVENT_CREATE_PARTICIPANT_SUCCESS =
  'CALENDAR_EVENT_CREATE_PARTICIPANT_SUCCESS';
export const CALENDAR_EVENT_CREATE_PARTICIPANT_FAILURE =
  'CALENDAR_EVENT_CREATE_PARTICIPANT_FAILURE';

export const CALENDAR_EVENT_PARTICIPANT_SET_PAGE =
  'CALENDAR_EVENT_PARTICIPANT_SET_PAGE';

// ACTION CREATORS

export function calendarEventListRequest(page, limit, order, filter) {
  Logger.log(
    'debug',
    `[calendarEvents.actions] calendarEventListRequest(${page}, ${limit}, ${order}, %j)`,
    filter
  );
  return {
    type: CALENDAR_EVENT_LIST_REQUEST,
    page: page,
    limit: limit,
    order: order,
    filter: filter,
  };
}

export function calendarEventListSuccess(data) {
  Logger.log(
    'debug',
    `[calendarEvents.actions] calendarEventListSuccess(%j)`,
    data
  );
  return {
    type: CALENDAR_EVENT_LIST_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now(),
  };
}

export function calendarEventListFailure(error) {
  Logger.log(
    'debug',
    `[calendarEvents.actions] calendarEventListFailure(%j)`,
    error
  );
  return {
    type: CALENDAR_EVENT_LIST_FAILURE,
    error: error,
  };
}

export function calendarEventReadRequest(id) {
  Logger.log(
    'debug',
    `[calendarEvents.actions] calendarEventReadRequest(${id})`
  );
  return {
    type: CALENDAR_EVENT_READ_REQUEST,
    id: id,
  };
}

export function calendarEventReadSuccess(data) {
  Logger.log(
    'debug',
    `[calendarEvents.actions] calendarEventReadSuccess(%j)`,
    data
  );
  return {
    type: CALENDAR_EVENT_READ_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function calendarEventReadFailure(error) {
  Logger.log(
    'debug',
    `[calendarEvents.actions] calendarEventReadFailure(%j)`,
    error
  );
  return {
    type: CALENDAR_EVENT_READ_FAILURE,
    error: error,
  };
}

export function calendarEventUpdateRequest(id, data) {
  Logger.log(
    'debug',
    `[calendarEvents.actions] calendarEventUpdateRequest(${id}, %j)`,
    data
  );
  return {
    type: CALENDAR_EVENT_UPDATE_REQUEST,
  };
}

export function calendarEventUpdateSuccess(data) {
  Logger.log(
    'debug',
    `[calendarEvents.actions] calendarEventUpdateSuccess(%j)`,
    data
  );
  return {
    type: CALENDAR_EVENT_UPDATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function calendarEventUpdateFailure(error) {
  Logger.log(
    'debug',
    `[calendarEvents.actions] calendarEventUpdateFailure(%j)`,
    error
  );
  return {
    type: CALENDAR_EVENT_UPDATE_FAILURE,
    error: error,
  };
}

export function calendarEventCreateRequest(data) {
  Logger.log(
    'debug',
    `[calendarEvents.actions] calendarEventCreateRequest(%j)`,
    data
  );
  return {
    type: CALENDAR_EVENT_CREATE_REQUEST,
  };
}

export function calendarEventCreateSuccess(data) {
  Logger.log(
    'debug',
    `[calendarEvents.actions] calendarEventCreateSuccess(%j)`,
    data
  );
  return {
    type: CALENDAR_EVENT_CREATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function calendarEventCreateFailure(error) {
  Logger.log(
    'debug',
    `[calendarEvents.actions] calendarEventCreateFailure(%j)`,
    error
  );
  return {
    type: CALENDAR_EVENT_CREATE_FAILURE,
    error: error,
  };
}

export function calendarEventDeleteRequest(id) {
  Logger.log(
    'debug',
    `[calendarEvents.actions] calendarEventDeleteRequest(${id})`
  );
  return {
    type: CALENDAR_EVENT_DELETE_REQUEST,
    id: id,
  };
}

export function calendarEventDeleteSuccess(id) {
  Logger.log(
    'debug',
    `[calendarEvents.actions] calendarEventDeleteSuccess(${id})`
  );
  return {
    type: CALENDAR_EVENT_DELETE_SUCCESS,
    id: id,
  };
}

export function calendarEventDeleteFailure(error) {
  Logger.log(
    'debug',
    `[calendarEvents.actions] calendarEventDeleteFailure(%j)`,
    error
  );
  return {
    type: CALENDAR_EVENT_DELETE_FAILURE,
    error: error,
  };
}

export function calendarEventFormDestroy(formState = null) {
  Logger.log(
    'debug',
    `[calendarEvents.actions] calendarEventFormDestroy(%j)`,
    formState
  );
  return {
    type: CALENDAR_EVENT_FORM_DESTROY,
    form: formState,
  };
}

export function calendarEventCSVDownloadRequest(order, filter) {
  Logger.log(
    'debug',
    `[state.calendarEvents.actions] calendarEventCSVDownloadRequest()`
  );
  return {
    type: CALENDAR_EVENT_CSV_DOWNLOAD_REQUEST,
    order: order,
    filter: filter,
  };
}

export function calendarEventCSVDownloadSuccess() {
  Logger.log(
    'debug',
    `[state.calendarEvents.actions] calendarEventCSVDownloadSuccess()`
  );
  return {
    type: CALENDAR_EVENT_CSV_DOWNLOAD_SUCCESS,
  };
}

export function calendarEventCSVDownloadFailure() {
  Logger.log(
    'debug',
    `[state.calendarEvents.actions] calendarEventCSVDownloadFailure()`
  );
  return {
    type: CALENDAR_EVENT_CSV_DOWNLOAD_FAILURE,
  };
}

export function calendarEventExceptionListRequest(page, limit, order, filter) {
  Logger.log(
    'debug',
    `[calendarEvents.actions] calendarEventExceptionListRequest(${page}, ${limit}, ${order}, %j)`,
    filter
  );
  return {
    type: CALENDAR_EVENT_EXCEPTION_LIST_REQUEST,
    page: page,
    limit: limit,
    order: order,
    filter: filter,
  };
}

export function calendarEventExceptionListSuccess(data) {
  Logger.log(
    'debug',
    `[calendarEvents.actions] calendarEventExceptionListSuccess(%j)`,
    data
  );
  return {
    type: CALENDAR_EVENT_EXCEPTION_LIST_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now(),
  };
}

export function calendarEventExceptionListFailure(error) {
  Logger.log(
    'debug',
    `[calendarEvents.actions] calendarEventExceptionListFailure(%j)`,
    error
  );
  return {
    type: CALENDAR_EVENT_EXCEPTION_LIST_FAILURE,
    error: error,
  };
}

export function calendarEventExceptionReadRequest(id) {
  Logger.log(
    'debug',
    `[calendarEvents.actions] calendarEventExceptionReadRequest(${id})`
  );
  return {
    type: CALENDAR_EVENT_EXCEPTION_READ_REQUEST,
    id: id,
  };
}

export function calendarEventExceptionReadSuccess(data) {
  Logger.log(
    'debug',
    `[calendarEvents.actions] calendarEventExceptionReadSuccess(%j)`,
    data
  );
  return {
    type: CALENDAR_EVENT_EXCEPTION_READ_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function calendarEventExceptionReadFailure(error) {
  Logger.log(
    'debug',
    `[calendarEvents.actions] calendarEventExceptionReadFailure(%j)`,
    error
  );
  return {
    type: CALENDAR_EVENT_EXCEPTION_READ_FAILURE,
    error: error,
  };
}

export function calendarEventExceptionUpdateRequest(id, data) {
  Logger.log(
    'debug',
    `[calendarEvents.actions] calendarEventExceptionUpdateRequest(${id}, %j)`,
    data
  );
  return {
    type: CALENDAR_EVENT_EXCEPTION_UPDATE_REQUEST,
  };
}

export function calendarEventExceptionUpdateSuccess(data) {
  Logger.log(
    'debug',
    `[calendarEvents.actions] calendarEventExceptionUpdateSuccess(%j)`,
    data
  );
  return {
    type: CALENDAR_EVENT_EXCEPTION_UPDATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function calendarEventExceptionUpdateFailure(error) {
  Logger.log(
    'debug',
    `[calendarEvents.actions] calendarEventExceptionUpdateFailure(%j)`,
    error
  );
  return {
    type: CALENDAR_EVENT_EXCEPTION_UPDATE_FAILURE,
    error: error,
  };
}

export function calendarEventExceptionCreateRequest(data) {
  Logger.log(
    'debug',
    `[calendarEvents.actions] calendarEventExceptionCreateRequest(%j)`,
    data
  );
  return {
    type: CALENDAR_EVENT_EXCEPTION_CREATE_REQUEST,
  };
}

export function calendarEventExceptionCreateSuccess(data) {
  Logger.log(
    'debug',
    `[calendarEvents.actions] calendarEventExceptionCreateSuccess(%j)`,
    data
  );
  return {
    type: CALENDAR_EVENT_EXCEPTION_CREATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function calendarEventExceptionCreateFailure(error) {
  Logger.log(
    'debug',
    `[calendarEvents.actions] calendarEventExceptionCreateFailure(%j)`,
    error
  );
  return {
    type: CALENDAR_EVENT_EXCEPTION_CREATE_FAILURE,
    error: error,
  };
}

export function calendarEventExceptionDeleteRequest(id) {
  Logger.log(
    'debug',
    `[calendarEvents.actions] calendarEventExceptionDeleteRequest(${id})`
  );
  return {
    type: CALENDAR_EVENT_EXCEPTION_DELETE_REQUEST,
    id: id,
  };
}

export function calendarEventExceptionDeleteSuccess(id) {
  Logger.log(
    'debug',
    `[calendarEvents.actions] calendarEventExceptionDeleteSuccess(${id})`
  );
  return {
    type: CALENDAR_EVENT_EXCEPTION_DELETE_SUCCESS,
    id: id,
  };
}

export function calendarEventExceptionDeleteFailure(error) {
  Logger.log(
    'debug',
    `[calendarEvents.actions] calendarEventExceptionDeleteFailure(%j)`,
    error
  );
  return {
    type: CALENDAR_EVENT_EXCEPTION_DELETE_FAILURE,
    error: error,
  };
}

export function calendarEventExceptionFormDestroy(formState = null) {
  Logger.log(
    'debug',
    `[calendarEvents.actions] calendarEventExceptionFormDestroy(%j)`,
    formState
  );
  return {
    type: CALENDAR_EVENT_EXCEPTION_FORM_DESTROY,
    form: formState,
  };
}

export function calendarEventExceptionCSVDownloadRequest(order, filter) {
  Logger.log(
    'debug',
    `[state.calendarEvents.actions] calendarEventExceptionCSVDownloadRequest()`
  );
  return {
    type: CALENDAR_EVENT_EXCEPTION_CSV_DOWNLOAD_REQUEST,
    order: order,
    filter: filter,
  };
}

export function calendarEventExceptionCSVDownloadSuccess() {
  Logger.log(
    'debug',
    `[state.calendarEvents.actions] calendarEventExceptionCSVDownloadSuccess()`
  );
  return {
    type: CALENDAR_EVENT_EXCEPTION_CSV_DOWNLOAD_SUCCESS,
  };
}

export function calendarEventExceptionCSVDownloadFailure() {
  Logger.log(
    'debug',
    `[state.calendarEvents.actions] calendarEventExceptionCSVDownloadFailure()`
  );
  return {
    type: CALENDAR_EVENT_EXCEPTION_CSV_DOWNLOAD_FAILURE,
  };
}

export function calendarEventListParticipantsRequest(
  calendarEventId,
  page,
  limit
) {
  Logger.log(
    'debug',
    `[state.calendarEvents.actions] calendarEventListParticipantsRequest(${calendarEventId}, ${page}, ${limit}`
  );
  return {
    type: CALENDAR_EVENT_LIST_PARTICIPANTS_REQUEST,
    calendarEventId: calendarEventId,
    page: page,
    limit: limit,
  };
}

export function calendarEventListParticipantsSuccess(calendarEventId, data) {
  Logger.log(
    'debug',
    `[state.calendarEvents.actions] calendarEventListParticipantsSuccess(${calendarEventId}, %j)`,
    data
  );
  return {
    type: CALENDAR_EVENT_LIST_PARTICIPANTS_SUCCESS,
    calendarEventId: calendarEventId,
    page: data.page,
    limit: data.limit,
    result: data.result,
    total: data.total,
    receivedAt: Date.now(),
  };
}

export function calendarEventListParticipantsFailure(calendarEventId, error) {
  Logger.log(
    'debug',
    `[state.calendarEvents.actions] calendarEventListParticipantsFailure(${calendarEventId}, %j)`,
    error
  );
  return {
    type: CALENDAR_EVENT_LIST_PARTICIPANTS_FAILURE,
    calendarEventId: calendarEventId,
    error: error,
  };
}

export function calendarEventParticipantCreateRequest(eventId, data) {
  Logger.log(
    'debug',
    `[state.calendarEvents.actions] calendarEventParticipantCreateRequest(${eventId}, %j)`,
    data
  );
  return {
    type: CALENDAR_EVENT_CREATE_PARTICIPANT_REQUEST,
    eventId: eventId,
  };
}

export function calendarEventParticipantCreateSuccess(eventId, data) {
  Logger.log(
    'debug',
    `[state.calendarEvents.actions] calendarEventParticipantCreateSuccess(${eventId}, %j)`,
    data
  );
  return {
    type: CALENDAR_EVENT_CREATE_PARTICIPANT_SUCCESS,
    eventId: eventId,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function calendarEventParticipantCreateFailure(eventId, error) {
  Logger.log(
    'debug',
    `[state.calendarEvents.actions] calendarEventParticipantCreateFailure(${eventId}, %j)`,
    error
  );
  return {
    type: CALENDAR_EVENT_CREATE_PARTICIPANT_FAILURE,
    eventId: eventId,
    error: error,
  };
}

export function calendarEventParticipantUpdateRequest(id, data) {
  Logger.log(
    'debug',
    `[state.calendarEvents.actions] calendarEventParticipantUpdateRequest(${id}, %j)`,
    data
  );
  return {
    type: CALENDAR_EVENT_UPDATE_PARTICIPANT_REQUEST,
    id: id,
  };
}

export function calendarEventParticipantUpdateSuccess(data) {
  Logger.log(
    'debug',
    `[state.calendarEvents.actions] calendarEventParticipantUpdateSuccess(%j)`,
    data
  );
  return {
    type: CALENDAR_EVENT_UPDATE_PARTICIPANT_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function calendarEventParticipantUpdateFailure(error) {
  Logger.log(
    'debug',
    `[state.calendarEvents.actions] calendarEventParticipantUpdateFailure(%j)`,
    error
  );
  return {
    type: CALENDAR_EVENT_UPDATE_PARTICIPANT_FAILURE,
    error: error,
  };
}

export function calendarEventDeleteParticipantRequest(id) {
  Logger.log(
    'debug',
    `[state.calendarEvents.actions] calendarEventDeleteParticipantRequest(${id})`
  );
  return {
    type: CALENDAR_EVENT_DELETE_PARTICIPANT_REQUEST,
    id: id,
  };
}

export function calendarEventDeleteParticipantSuccess(id) {
  Logger.log(
    'debug',
    `[state.calendarEvents.actions] calendarEventDeleteParticipantSuccess(${id})`
  );
  return {
    type: CALENDAR_EVENT_DELETE_PARTICIPANT_SUCCESS,
    id: id,
  };
}

export function calendarEventDeleteParticipantFailure(error) {
  Logger.log(
    'debug',
    `[state.calendarEvents.actions] calendarEventDeleteParticipantFailure(%j)`,
    error
  );
  return {
    type: CALENDAR_EVENT_DELETE_PARTICIPANT_FAILURE,
    error: error,
  };
}

export function calendarEventShowEventParticipantFormDestroy(formState = null) {
  Logger.log(
    'debug',
    `[state.calendarEvents.actions] calendarEventShowEventParticipantFormDestroy(%j)`,
    formState
  );
  return {
    type: CALENDAR_EVENT_EVENT_PARTICIPANT_FORM_DESTROY,
    form: formState,
  };
}

export function calendarEventShowEventParticipantForm(participantId = null) {
  Logger.log(
    'debug',
    `[state.calendarEvents.actions] calendarEventShowEventParticipantForm()`
  );
  return {
    type: CALENDAR_EVENT_SHOW_EVENT_PARTICIPANT_FORM,
    participantId: participantId,
  };
}

export function calendarEventHideEventParticipantForm() {
  Logger.log(
    'debug',
    `[state.calendarEvents.actions] calendarEventHideEventForm()`
  );
  return {
    type: CALENDAR_EVENT_HIDE_EVENT_PARTICIPANT_FORM,
  };
}

export function calendarEventParticipantSetPage(page) {
  Logger.log(
    'info',
    `[state.calendarEvents.actions] calendarEventParticipantSetPage(${page})`
  );

  return {
    type: CALENDAR_EVENT_PARTICIPANT_SET_PAGE,
    page: page,
  };
}

// API THUNK ACTION CREATORS

export function loadCalendarEvents(
  page = 1,
  limit = 10,
  order = null,
  filter = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[calendarEvents.actions] loadCalendarEvents(${page}, ${limit}, ${order}, %j, ###)`,
    filter
  );

  return async function (dispatch) {
    dispatch(calendarEventListRequest(page, limit, order, filter));

    // call API
    const response = await api.getCalendarEvents(page, limit, order, filter);
    let success = false;

    // get calendar events list success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API calendar events list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );

      const normalizedEntities = normalize(
        response.getIn(['data', 'calendar_events']),
        [schema.calendarEvent]
      );
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result,
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(calendarEventListSuccess(data));
      success = true;
    } else if (1 === page && 204 === response.get('status')) {
      Logger.log(
        'info',
        `Get API calendar events success [empty]. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: [],
      };
      dispatch(calendarEventListSuccess(data));
      success = true;

      // get calendarEvents list failure
    } else {
      Logger.log(
        'info',
        `Get API calendar events list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      dispatch(calendarEventListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function loadCalendarEvent(id, cb = function () {}) {
  Logger.log('debug', `[calendarEvents.actions] loadCalendarEvent(${id}, ###)`);

  return async function (dispatch) {
    dispatch(calendarEventReadRequest(id));

    // call API
    const response = await api.getCalendarEvent(id);
    let success = false;

    // get calendar event success
    if (200 === response.get('status')) {
      Logger.log('info', `Get API calendar event success. ID: ${id}.`);

      const normalizedEntities = normalize(
        [response.getIn(['data', 'calendar_event'])],
        [schema.calendarEvent]
      );
      const data = {
        id: response.getIn(['data', 'calendar_event', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(calendarEventReadSuccess(data));
      success = true;

      // get calendar event failure
    } else {
      Logger.log('info', `Get API calendar event failure. ID: ${id}.`);
      dispatch(calendarEventReadFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function updateCalendarEvent(id, data, cb = function () {}) {
  Logger.log(
    'debug',
    `[calendarEvents.actions] updateCalendarEvent(${id}, %j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(calendarEventUpdateRequest(id, data));

    // call API
    const response = await api.putCalendarEvent(id, data);
    let success = false;

    // put calendar event success
    if (200 === response.get('status')) {
      Logger.log('info', `PUT API calendar event success. User: ${id}.`);

      const normalizedEntities = normalize(
        [response.getIn(['data', 'calendar_event'])],
        [schema.calendarEvent]
      );
      const data = {
        id: response.getIn(['data', 'calendar_event', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(calendarEventUpdateSuccess(data));
      success = true;

      // get calendar event failure
    } else {
      Logger.log('info', `PUT API calendar event failure. ID: ${id}.`);
      dispatch(calendarEventUpdateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function createCalendarEvent(data, cb = function () {}) {
  Logger.log(
    'debug',
    `[calendarEvents.actions] createCalendarEvent(%j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(calendarEventCreateRequest(data));

    // call API
    const response = await api.postCalendarEvents(data);
    let success = false;

    // post calendar events success
    if (201 === response.get('status')) {
      Logger.log(
        'info',
        `POST API calendar events success. Post: ${response.getIn([
          'data',
          'calendar_event',
          'id',
        ])}.`
      );

      const normalizedEntities = normalize(
        [response.getIn(['data', 'calendar_event'])],
        [schema.calendarEvent]
      );
      const data = {
        id: response.getIn(['data', 'calendar_event', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(calendarEventCreateSuccess(data));
      success = true;

      // get calendar events failure
    } else {
      Logger.log('info', `POST API calendar events failure.`);
      dispatch(calendarEventCreateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function deleteCalendarEvent(id, cb = function () {}) {
  Logger.log(
    'debug',
    `[calendarEvents.actions] deleteCalendarEvent(${id}, ###)`
  );

  return async function (dispatch) {
    dispatch(calendarEventDeleteRequest(id));

    // call API
    const response = await api.deleteCalendarEvent(id);
    let success = false;

    // delete calendar event success
    if (204 === response.get('status')) {
      Logger.log('info', `DELETE API calendar event success. ID: ${id}.`);

      dispatch(removeEntity({ entityType: 'calendarEvents', id: id }));
      dispatch(calendarEventDeleteSuccess(id));
      success = true;

      // get calendar event failure
    } else {
      Logger.log('info', `DELETE API calendar event failure. ID: ${id}.`);
      dispatch(calendarEventDeleteFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function downloadCalendarEventsCSV(
  order = null,
  filter = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.calendarEvents.actions] downloadCalendarEventsCSV(${order}, ${filter}, ###)`
  );

  return async function (dispatch) {
    dispatch(calendarEventCSVDownloadRequest(order, filter));

    // call API
    const response = await api.getCalendarEventsCSV(order, filter);
    let success = false;

    // get calendar events CSV success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API calendar events CSV success. Order: ${order}`
      );
      const now = new Date();

      // trigger browser download
      const url = window.URL.createObjectURL(new Blob([response.get('data')]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `cv-calendar-events-${Format.date(now, 'YYYY-MM-DDTHHmm')}.csv`
      );
      document.body.appendChild(link);
      link.click();

      dispatch(calendarEventCSVDownloadSuccess());
      success = true;
      // get calendar events CSV failure
    } else {
      Logger.log('info', `Get API calendar events CSV failure.`);
      dispatch(calendarEventCSVDownloadFailure());
    }

    // callback function
    cb(success);
  };
}

export function loadCalendarEventExceptions(
  page = 1,
  limit = 10,
  order = null,
  filter = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[calendarEvents.actions] loadCalendarEventExceptions(${page}, ${limit}, ${order}, %j, ###)`,
    filter
  );

  return async function (dispatch) {
    dispatch(calendarEventExceptionListRequest(page, limit, order, filter));

    // call API
    const response = await api.getCalendarEventExceptions(
      page,
      limit,
      order,
      filter
    );
    let success = false;

    // get calendar event exceptions list success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API calendar event exceptions list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );

      const normalizedEntities = normalize(
        response.getIn(['data', 'calendar_event_exceptions']),
        [schema.calendarEventException]
      );
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result,
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(calendarEventExceptionListSuccess(data));
      success = true;
    } else if (1 === page && 204 === response.get('status')) {
      Logger.log(
        'info',
        `Get API calendar event exceptions success [empty]. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: [],
      };
      dispatch(calendarEventExceptionListSuccess(data));
      success = true;

      // get calendar event exceptions list failure
    } else {
      Logger.log(
        'info',
        `Get API calendar event exceptions list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      dispatch(
        calendarEventExceptionListFailure(response.getIn(['data', 'error']))
      );
    }

    // callback function
    cb(success);
  };
}

export function loadCalendarEventException(id, cb = function () {}) {
  Logger.log(
    'debug',
    `[calendarEvents.actions] loadCalendarEventException(${id}, ###)`
  );

  return async function (dispatch) {
    dispatch(calendarEventExceptionReadRequest(id));

    // call API
    const response = await api.getCalendarEventException(id);
    let success = false;

    // get calendar event exception success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API calendar event exception success. ID: ${id}.`
      );

      const normalizedEntities = normalize(
        [response.getIn(['data', 'calendar_event_exception'])],
        [schema.calendarEventException]
      );
      const data = {
        id: response.getIn(['data', 'calendar_event_exception', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(calendarEventExceptionReadSuccess(data));
      success = true;

      // get calendar event exception failure
    } else {
      Logger.log(
        'info',
        `Get API calendar event exception failure. ID: ${id}.`
      );
      dispatch(
        calendarEventExceptionReadFailure(response.getIn(['data', 'error']))
      );
    }

    // callback function
    cb(success);
  };
}

export function updateCalendarEventException(id, data, cb = function () {}) {
  Logger.log(
    'debug',
    `[calendarEvents.actions] updateCalendarEventException(${id}, %j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(calendarEventExceptionUpdateRequest(id, data));

    // call API
    const response = await api.putCalendarEventException(id, data);
    let success = false;

    // put calendar event exception success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `PUT API calendar event exception success. User: ${id}.`
      );

      const normalizedEntities = normalize(
        [response.getIn(['data', 'calendar_event_exception'])],
        [schema.calendarEventException]
      );
      const data = {
        id: response.getIn(['data', 'calendar_event_exception', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(calendarEventExceptionUpdateSuccess(data));
      success = true;

      // get calendar event exception failure
    } else {
      Logger.log(
        'info',
        `PUT API calendar event exception failure. ID: ${id}.`
      );
      dispatch(
        calendarEventExceptionUpdateFailure(response.getIn(['data', 'error']))
      );
    }

    // callback function
    cb(success);
  };
}

export function createCalendarEventException(data, cb = function () {}) {
  Logger.log(
    'debug',
    `[calendarEvents.actions] createCalendarEventException(%j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(calendarEventExceptionCreateRequest(data));

    // call API
    const response = await api.postCalendarEventExceptions(data);
    let success = false;

    // post calendar event exceptions success
    if (201 === response.get('status')) {
      Logger.log(
        'info',
        `POST API calendar event exceptions success. Post: ${response.getIn([
          'data',
          'calendar_event_exception',
          'id',
        ])}.`
      );

      const normalizedEntities = normalize(
        [response.getIn(['data', 'calendar_event_exception'])],
        [schema.calendarEventException]
      );
      const data = {
        id: response.getIn(['data', 'calendar_event_exception', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(calendarEventExceptionCreateSuccess(data));
      success = true;

      // get calendar event exceptions failure
    } else {
      Logger.log('info', `POST API calendar event exceptions failure.`);
      dispatch(
        calendarEventExceptionCreateFailure(response.getIn(['data', 'error']))
      );
    }

    // callback function
    cb(success);
  };
}

export function deleteCalendarEventException(id, cb = function () {}) {
  Logger.log(
    'debug',
    `[calendarEvents.actions] deleteCalendarEventException(${id}, ###)`
  );

  return async function (dispatch) {
    dispatch(calendarEventExceptionDeleteRequest(id));

    // call API
    const response = await api.deleteCalendarEventException(id);
    let success = false;

    // delete calendar event exception success
    if (204 === response.get('status')) {
      Logger.log(
        'info',
        `DELETE API calendar event exception success. ID: ${id}.`
      );

      dispatch(removeEntity({ entityType: 'calendarEventExceptions', id: id }));
      dispatch(calendarEventExceptionDeleteSuccess(id));
      success = true;

      // get calendar event exception failure
    } else {
      Logger.log(
        'info',
        `DELETE API calendar event exception failure. ID: ${id}.`
      );
      dispatch(
        calendarEventExceptionDeleteFailure(response.getIn(['data', 'error']))
      );
    }

    // callback function
    cb(success);
  };
}

export function downloadCalendarEventExceptionsCSV(
  order = null,
  filter = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.calendarEvents.actions] downloadCalendarEventExceptionsCSV(${order}, ${filter}, ###)`
  );

  return async function (dispatch) {
    dispatch(calendarEventExceptionCSVDownloadRequest(order, filter));

    // call API
    const response = await api.getCalendarEventExceptionsCSV(order, filter);
    let success = false;

    // get calendar event exceptions CSV success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API calendar event exceptions CSV success. Order: ${order}`
      );
      const now = new Date();

      // trigger browser download
      const url = window.URL.createObjectURL(new Blob([response.get('data')]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `cv-calendar-event-exceptions-${Format.date(
          now,
          'YYYY-MM-DDTHHmm'
        )}.csv`
      );
      document.body.appendChild(link);
      link.click();

      dispatch(calendarEventExceptionCSVDownloadSuccess());
      success = true;
      // get calendar event exceptions CSV failure
    } else {
      Logger.log('info', `Get API calendar event exceptions CSV failure.`);
      dispatch(calendarEventExceptionCSVDownloadFailure());
    }

    // callback function
    cb(success);
  };
}

export function loadCalendarEventParticipants(
  calendarEventId,
  page = 1,
  limit = 10,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.calendarEvents.actions] loadCalendarEventParticipants(${calendarEventId}, ${page}, ${limit}, ###)`
  );

  return async function (dispatch) {
    dispatch(
      calendarEventListParticipantsRequest(calendarEventId, page, limit)
    );

    // call API
    const response = await api.getCalendarEventParticipants(
      calendarEventId,
      page,
      limit
    );
    let success = false;
    let total = 0;

    // get calendar participant events list success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API calendar participant events list success. Calendar Event ID: ${calendarEventId}, Page: ${page}, Limit: ${limit}.`
      );

      const normalizedEntities = normalize(
        response.getIn(['data', 'calendar_event_participants']),
        [schema.calendarEventParticipant]
      );

      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result,
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(calendarEventListParticipantsSuccess(calendarEventId, data));

      success = true;
      total = response.getIn(['data', 'total']);
    } else if (1 === page && 204 === response.get('status')) {
      Logger.log(
        'info',
        `Get API calendar participant events list success [empty]. Calendar Event ID: ${calendarEventId}, Page: ${page}, Limit: ${limit}.`
      );
      const data = {
        page: page,
        limit: limit,
        total: 0,
        result: [],
      };
      dispatch(calendarEventListParticipantsSuccess(calendarEventId, data));
      success = true;

      // get calendar participant events list failure
    } else {
      Logger.log(
        'info',
        `Get API calendar participant events list failure. Calendar Event ID: ${calendarEventId}, Page: ${page}, Limit: ${limit}.`
      );
      dispatch(
        calendarEventListParticipantsFailure(
          calendarEventId,
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success, total);
  };
}

export function createCalendarEventParticipant(
  eventId,
  data,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.calendarEvents.actions] createCalendarEventParticipant(${eventId}, %j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(calendarEventParticipantCreateRequest(eventId, data));

    // call API
    const response = await api.postCalendarEventParticipant(eventId, data);
    let success = false;

    // post calendar event participant success
    if (201 === response.get('status')) {
      Logger.log(
        'info',
        `POST API calendar event participant success. Calendar Event ID: ${eventId}.`
      );

      const normalizedEntities = normalize(
        [response.getIn(['data', 'calendar_event_participants'])],
        [schema.calendarEventParticipant]
      );
      const data = {
        id: response.getIn(['data', 'calendar_event_participants', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(calendarEventParticipantCreateSuccess(eventId, data));
      success = true;

      // post calendar event participant failure
    } else {
      Logger.log(
        'info',
        `POST API calendar event participant failure. Calendar Event ID: ${eventId}.`
      );
      dispatch(
        calendarEventParticipantCreateFailure(
          eventId,
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success);
  };
}

export function updateCalendarEventParticipant(id, data, cb = function () {}) {
  Logger.log(
    'debug',
    `[state.calendarEvents.actions] updateCalendarEventParticipant(${id}, %j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(calendarEventParticipantUpdateRequest(id, data));

    // call API
    const response = await api.putCalendarEventParticipant(id, data);
    let success = false;

    // PUT calendar event participant success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `PUT API calendar event participant success. Participant: ${id}.`
      );

      const normalizedEntities = normalize(
        [response.getIn(['data', 'calendar_event_participant'])],
        [schema.calendarEventParticipant]
      );
      const data = {
        id: response.getIn(['data', 'calendar_event_participant', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(calendarEventParticipantUpdateSuccess(id, data));
      success = true;

      // PUT calendar event participant failure
    } else {
      Logger.log('info', `PUT API calendar event failure. ID: ${id}.`);
      dispatch(
        calendarEventParticipantUpdateFailure(response.getIn(['data', 'error']))
      );
    }

    // callback function
    cb(success);
  };
}

export function deleteCalendarEventParticipant(id, cb = function () {}) {
  Logger.log(
    'debug',
    `[calendarEvents.actions] deleteCalendarEventParticipant(${id})`
  );

  return async function (dispatch) {
    dispatch(calendarEventDeleteParticipantRequest(id));

    // call API
    const response = await api.deleteCalendarEventParticipant(id);
    let success = false;

    // delete calendar event participant success
    if (204 === response.get('status')) {
      Logger.log(
        'info',
        `DELETE API calendar event participant success. ID: ${id}.`
      );

      dispatch(calendarEventDeleteParticipantSuccess(id));
      success = true;

      // get calendar event failure
    } else {
      Logger.log(
        'info',
        `DELETE API calendar event participant failure. ID: ${id}.`
      );
      dispatch(
        calendarEventDeleteParticipantFailure(response.getIn(['data', 'error']))
      );
    }

    // callback function
    cb(success);
  };
}

Logger.log('silly', `calendarEvents.actions loaded.`);
