import {connect} from 'react-redux';
import {List} from 'immutable';
import moment from 'moment';

import {
  loadEmail,
  updateEmail,
  deleteEmail,
  emailFormDestroy
} from '../../../../../state/modules/emails/actions';
import {sendMessage} from '../../../../../state/actions';
import EmailForm from '../components/EmailForm';
import Config from '../../../../../Config';

const inputs = List([
  'service_id',
  'priority',
  'send_status',
  'template',
  'accepted',
  'rejected',
  'attempts',
  'queued_at',
  'last_attempt_at',
  'substitutions',
  'recipients'
]);


const mapStateToProps = (state, ownProps) => {

  const data = {}, errors = {};
  for (const val of inputs.values()) {
    data[val] = state.entities.getIn(['emails', ownProps.id, val], '');
      errors[val] = state.emails.getIn(['form', 'errors', val]);
  }
  data['last_attempt_at'] = data['last_attempt_at']
    ? moment(data['last_attempt_at'], Config.get('API_DATETIME_FORMAT'))
    : null;
  
  data['queued_at'] = data['queued_at']
    ? moment(data['queued_at'], Config.get('API_DATETIME_FORMAT'))
    : moment();

  return {
    isLoading: state.emails.get('isLoading'),
    isSubmitting: state.emails.getIn(['form', 'isSubmitting']),
    success: state.emails.getIn(['form', 'success']),
    data: data,
    errors: errors,
    createdAt: state.entities.getIn(['emails', ownProps.id, 'created_at'], null),
    updatedAt: state.entities.getIn(['emails', ownProps.id, 'updated_at'], null),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (id, cb) => {
      dispatch(loadEmail(id, cb));
    },
    update: (id, data, cb) => {
      dispatch(updateEmail(id, data, cb));
    },
    delete: (id, cb) => {
      dispatch(deleteEmail(id, cb))
    },
    destroyForm: (formState) => {
      dispatch(emailFormDestroy(formState));
    },
    sendMessage: (level, title, body, expires) => {
      dispatch(sendMessage(level, title, body, expires));
    },
  }
}

const EmailFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailForm);

export default EmailFormContainer;
