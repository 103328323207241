import {connect} from 'react-redux';

import CalendarEventExceptionEditScreen from '../components/CalendarEventExceptionEditScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.calendarEvents.get('areExceptionsLoading'),
    success: state.calendarEvents.getIn(['exceptionForm', 'success']),
  }
}

const CalendarEventExceptionEditScreenContainer = connect(
  mapStateToProps
)(CalendarEventExceptionEditScreen);

export default CalendarEventExceptionEditScreenContainer;
