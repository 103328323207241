import {connect} from 'react-redux';
import {List} from 'immutable';
import moment from 'moment';
import {denormalize} from 'normalizr';

import {schema} from '../../../../../../state/schema';
import {
  loadSubRegion,
  updateSubRegion,
  createSubRegion,
  deleteSubRegion,
  subregionFormDestroy
} from '../../../../../../state/modules/locationSubRegions/actions';
import {sendMessage} from '../../../../../../state/actions';
import SubRegionForm from '../components/SubRegionForm';
import Config from '../../../../../../Config';

const inputs = List([
  'name',
  'region',
  'status'
]);

const mapStateToProps = (state, ownProps) => {

  const subregion = denormalize(
    state.entities.getIn(['subregions', ownProps.id]),
    schema.subregion,
    state.entities.toJS()
  );

  const data = {}, errors = {};
  for (const val of inputs.values()) {
    data[val] = state.entities.getIn(['subregions', ownProps.id, val], '');
    errors[val] = state.locationSubRegions.getIn(['form', 'errors', val]);
  }
  data['country'] = subregion && subregion.region.country ? subregion.region.country.id.toString() : null;
  data['region'] = subregion && subregion.region ? subregion.region.id.toString() : null;
  Object.keys(data).forEach(x => {
    if (['status', 'rating'].includes(x)) {
      data[x] = data[x].toString();
    }
  });

  data['published_at'] = data['published_at']
    ? moment(data['published_at'], Config.get('API_DATETIME_FORMAT'))
    : moment();

  data['edited_at'] = data['edited_at']
    ? moment(data['edited_at'], Config.get('API_DATETIME_FORMAT'))
    : null;

  if (data['tags'] === '') {
    data['tags'] = [];
  }

  if (data['media'] === '') {
    data['media'] = [];
  }

  return {
    isLoading: state.locationSubRegions.get('areSubRegionsLoading'),
    isSubmitting: state.locationSubRegions.getIn(['form', 'isSubmitting']),
    success: state.locationSubRegions.getIn(['form', 'success']),
    created_id: state.locationSubRegions.getIn(['form', 'created_id']),
    data: data,
    errors: errors,
    createdAt: state.entities.getIn(['subregions', ownProps.id, 'created_at'], null),
    updatedAt: state.entities.getIn(['subregions', ownProps.id, 'updated_at'], null),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (id, cb) => {
      dispatch(loadSubRegion(id, cb));
    },
    update: (id, data, cb) => {
      dispatch(updateSubRegion(id, data, cb));
    },
    create: (data, cb) => {
      dispatch(createSubRegion(data, cb));
    },
    delete: (id, cb) => {
      dispatch(deleteSubRegion(id, cb))
    },
    destroyForm: (formState) => {
      dispatch(subregionFormDestroy(formState));
    },
    sendMessage: (level, title, body, expires) => {
      dispatch(sendMessage(level, title, body, expires));
    },
  }
}

const SubRegionFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SubRegionForm);

export default SubRegionFormContainer;
