import React, { Component } from 'react';
import { Translation } from 'react-i18next';

import AdminPartnerClassForm from '../containers/AdminPartnerClassFormContainer';
import DocumentHead from '../../../elements/components/DocumentHead';
import Logger from '../../../../../lib/Logger';

class AdminPartnerClassEditScreen extends Component {

  scrollToTop = () => {
    Logger.log('debug', `AdminPartnerClassEditScreen.scrollToTop()`);
    if (typeof window !== 'undefined') {
      setTimeout(() => window.scrollTo(0, 0), 100);
    }
  }

  render() {
    return (
      <Translation>{(t) =>
        <div>
          <DocumentHead title={t('admin_partner_class_route_edit')} />
          <AdminPartnerClassForm id={this.props.match.params.id} />
        </div>
      }</Translation>
    )
  }

  /* LIFECYCLE EVENTS */

  componentDidMount() {
    this.scrollToTop();
  }
}

export default AdminPartnerClassEditScreen;

Logger.log('silly', `AdminPartnerClassEditScreen loaded.`);