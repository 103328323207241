import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { Affix, Card, Col, Input, Row, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import DocumentHead from '../../../elements/components/DocumentHead';
import Config from '../../../../../Config';
import Format from '../../../../../lib/Format';
import Logger from '../../../../../lib/Logger';


const DisplayInput = ({ label, value, ...props }) => (
  <div className="form-group">
    <div className="ant-row ant-form-item" style={{ marginBottom: 0 }}>
      <div className="ant-col ant-col-5 ant-form-item-label">
        <label htmlFor="care_guide_document_form_application" className="" title="Application">{label}</label>
      </div>
      <div className="ant-col ant-col-19 ant-form-item-control">
        <Input value={value} bordered={false} />
      </div>
    </div>
  </div>
);

const DisplayInputText = ({ label, value, ...props }) => (
  <div className="form-group">
    <div className="ant-row ant-form-item" style={{ marginBottom: 0 }}>
      <div className="ant-col ant-col-5 ant-form-item-label">
        <label htmlFor="care_guide_document_form_application" className="" title="Application">{label}</label>
      </div>
      <div className="ant-col ant-col-19 ant-form-item-control">
        <Input.TextArea  rows={3} value={value} bordered={false} />
      </div>
    </div>
  </div>
);

const is_pinned = {
  true: "True",
  false: "False"
}

class CareGuideDocumentViewScreen extends Component {

  scrollToTop = () => {
    Logger.log('debug', `CareGuideDocumentViewScreen.scrollToTop()`);
    if (typeof window !== 'undefined') {
      setTimeout(() => window.scrollTo(0, 0), 100);
    }
  }

  render() {
    const { careGuideDocument, isLoading } = this.props;

    return (
      <Translation>{(t) =>
        <div>
          <DocumentHead title={t('care_guide_documents_route_view')} />

          <div className="careGuideDocument-view">

            <Row gutter={16}>

              <Col xs={24} lg={18}>
                <Card
                  title={t('care_guide_documents_view_title')}
                  extra={isLoading
                    ? <Spin indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} />
                    : null}
                >
                  <DisplayInput label={t('care_guide_documents_id')} value={careGuideDocument ? careGuideDocument.id : null} />
                  <DisplayInput label={t('care_guide_documents_care_guide_name')} value={careGuideDocument && careGuideDocument.care_guide ? `${careGuideDocument.care_guide.first_name} ${careGuideDocument.care_guide.last_name}` : null} />
                  <DisplayInput label={t('care_guide_documents_profile_username')} value={careGuideDocument && careGuideDocument.profile && careGuideDocument.profile.user ? careGuideDocument.profile.user.username : null} />
                  <DisplayInput label={t('care_guide_documents_file_name')} value={careGuideDocument ? careGuideDocument.file_name : null} />
                  <DisplayInput label={t('care_guide_documents_user_file_name')} value={careGuideDocument ? careGuideDocument.user_file_name : null} />
                  <DisplayInput label={t('care_guide_documents_file_extension')} value={careGuideDocument ? careGuideDocument.file_extension : null} />
                  <DisplayInput label={t('care_guide_documents_file_directory')} value={careGuideDocument ? careGuideDocument.file_directory : null} />
                  <DisplayInput label={t('care_guide_documents_file_bucket')} value={careGuideDocument ? careGuideDocument.file_bucket : null} />
                  <DisplayInput label={t('care_guide_documents_size_bytes')} value={careGuideDocument ? careGuideDocument.size_bytes : null} />
                  <DisplayInput label={t('care_guide_documents_uploaded_at')} value={careGuideDocument ? Format.date(careGuideDocument.uploaded_at, Config.get('DEFAULT_DATETIME_FORMAT')) : null} />
                  <DisplayInput label={t('care_guide_documents_is_pinned')} value={careGuideDocument ? is_pinned[careGuideDocument.is_pinned] : null} />
                  <DisplayInput label={t('care_guide_documents_pinned_at')} value={careGuideDocument ? Format.date(careGuideDocument.pinned_at, Config.get('DEFAULT_DATETIME_FORMAT')) : null} />
                  <DisplayInputText label={t('care_guide_documents_description')} value={careGuideDocument ? careGuideDocument.description : ""} />

                </Card>
              </Col>
              <Col xs={24} lg={6}>
                <Affix offsetTop={10}>
                  <Card title={t('form_metadata_header')}>
                    {careGuideDocument && careGuideDocument.created_at
                      ? <p>{t('form_metadata_created_at')} <small>{Format.date(careGuideDocument.created_at, Config.get('DEFAULT_DATETIME_FORMAT'))}</small></p>
                      : ''}
                    {careGuideDocument && careGuideDocument.updated_at && careGuideDocument.updated_at !== careGuideDocument.created_at
                      ? <p>{t('form_metadata_updated_ad')} <small>{Format.date(careGuideDocument.updated_at, Config.get('DEFAULT_DATETIME_FORMAT'))}</small></p>
                      : ''}
                  </Card>
                </Affix>
              </Col>
            </Row>

          </div>

        </div>
      }</Translation>
    )
  }

  /* LIFECYCLE EVENTS */

  componentDidMount() {
    this.scrollToTop();
    this.props.load(this.props.id);
  }
}

export default CareGuideDocumentViewScreen;

Logger.log('silly', `CareGuideDocumentViewScreen loaded.`);
