import {connect} from 'react-redux';

import {downloadPartnershipsCSV} from '../../../../../state/modules/partnerships/actions';
import PartnershipsCSVButton from '../components/PartnershipsCSVButton';

const mapStateToProps = state => {
  return {
    isDownloading: state.partnerships.get('isCSVDownloading'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    download: (order, filter, cb) => {
      dispatch(downloadPartnershipsCSV(order, filter, cb));
    }
  }
}

const PartnershipsCSVButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnershipsCSVButton);

export default PartnershipsCSVButtonContainer;
