import { connect } from 'react-redux';
import { loadCareGuides } from '../../../../../state/modules/careGuides/actions';

import CareGuideInput from '../components/CareGuideInput';

const mapStateToProps = (state, ownProps) => {
    
  return {
    total: state.careGuides.get('total'),
    isLoading: state.careGuides.get('isLoading'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (page, limit, order, filter, cb) => {
      dispatch(loadCareGuides(page, limit, order, filter, cb));
    },
  }
}

const CareGuideInputContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CareGuideInput);

export default CareGuideInputContainer;
