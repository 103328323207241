import { connect } from 'react-redux';

import RecommendedUtilityAddScreen from '../components/RecommendedUtilityAddScreen';

const mapStateToProps = (state) => {
  return {
    isLoading: state.recommendedUtilities.get('isLoading'),
    success: state.recommendedUtilities.getIn(['form', 'success']),
  };
};

const RecommendedUtilityAddScreenContainer = connect(mapStateToProps)(
  RecommendedUtilityAddScreen
);

export default RecommendedUtilityAddScreenContainer;
