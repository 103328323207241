import {getI18n} from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import ResourceNotesScreen from './containers/ResourceNotesScreenContainer';
import ResourceNoteAddScreen from './containers/ResourceNoteAddScreenContainer';
import ResourceNoteEditScreen from './containers/ResourceNoteEditScreenContainer';

const register = () => {
  Logger.log('debug', `resourceNotes.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'resourceNotes', 'routes'])) {
    Events.dispatch('ADD_MAIN_ROUTES', 
      {
        'ResourceNoteAddScreen': ['PrivateRoute', '/resource-notes/add', true, ResourceNoteAddScreen, getI18n().t('resource_note_route_add')],
        'ResourceNoteEditScreen': ['PrivateRoute', '/resource-notes/edit/:id([\\w\\-]+)', true, ResourceNoteEditScreen, getI18n().t('resource_note_route_edit')],
        'ResourceNotesScreen': ['PrivateRoute', '/resource-notes/:page(\\d+)?', true, ResourceNotesScreen, getI18n().t('resource_notes_route_list')],
      }
    );
  }
}

export default register;

Logger.log('silly', `resourceNotes.register() loaded.`);
