import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom'
import { Translation, getI18n } from 'react-i18next';
import { Affix, Card, Col, DatePicker, Form, Input, Row, Spin, Space, Button } from 'antd';
import moment from 'moment';
import PriorityInput from "./PriorityInput";
import SendStatusInput from "./SendStatusInput";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  DragOutlined,
  LoadingOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import message from '../../../elements/lib/MessageWrapper';
import FormMetadata from '../../../elements/components/FormMetadata';
import Format from '../../../../../lib/Format';
import Logger from '../../../../../lib/Logger';
import Config from '../../../../../Config';


const SubstitutionsInputRow = ({ field, index, remove, ...props }) => {
  return (
    <Draggable key={field.key} draggableId={field.key.toString()} index={index}>
      {(provided, snapshot) => (
        <div ref={provided.innerRef} {...provided.draggableProps}>
          <Translation>
            {(t) => (
              <Space key={field.key} style={{ display: "flex" }} align="start">
                <div className="drag-handle" {...provided.dragHandleProps}>
                  <DragOutlined />
                </div>

                <div className="form-group">
                  <Form.Item
                    {...field}
                    name={[field.name, "field"]}
                    label={t("email_substitutions_field")}
                    fieldKey={[field.fieldKey, "field"]}
                    rules={[
                      {
                        required: true,
                        message: t("feedback_validation_required"),
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </div>

                <div className="form-group">
                  <Form.Item
                    {...field}
                    name={[field.name, "value"]}
                    label={t("email_substitutions_value")}
                    fieldKey={[field.fieldKey, "value"]}
                  >
                    <Input.TextArea autoSize />
                  </Form.Item>
                </div>

                <MinusCircleOutlined
                  onClick={() => {
                    remove(field.name);
                  }}
                />
              </Space>
            )}
          </Translation>
        </div>
      )}
    </Draggable>
  );
};

const RecipientsInputRow = ({ recipient, remove, index, ...props }) => {
  return (
    <Translation>
      {(t) => (
        <div className="recipient-input-row">

          <Space style={{ display: "flex" }} align="start">
            <div className="form-group">
              <Form.Item
                {...recipient}
                label={t('emails_recipient')}
                rules={[
                  {type: 'email', message: t('feedback_validation_email')},
                  { required: true, message: t('feedback_validation_required') }
                  ,
                ]}
              >
                <Input
                  autoFocus
                />
              </Form.Item>
            </div>
            <MinusCircleOutlined
              onClick={() => {
                remove(index);
              }}
            />
          </Space>
        </div>

      )}
    </Translation>
  );
};

const EmailForm = ({ id, data, errors, load, destroyForm, isLoading, isSubmitting, ...props }) => {

  // eslint-disable-next-line no-unused-vars
  const [redirectTo, setRedirectTo] = useState(null);
  const [form] = Form.useForm();

  const apiDatetimeFormat = Config.get('API_DATETIME_FORMAT');

  // form column settings
  const layout = {
    main: {
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
    },
  }

  // load record data from API
  useEffect(() => {
    if (id) {
      load(id);
    }
  }, [id, load]);

  // update input values when new data is available
  const dataString = JSON.stringify(data);
  useEffect(() => {
    if (id && !isSubmitting) {
      const dataObj = JSON.parse(dataString);
      dataObj['last_attempt_at'] = dataObj['last_attempt_at']
        ? moment(dataObj['last_attempt_at'], apiDatetimeFormat)
        : null;

      dataObj['queued_at'] = dataObj['queued_at']
        ? moment(dataObj['queued_at'], apiDatetimeFormat)
        : moment();
      form.setFieldsValue(dataObj);
    }
  }, [form, dataString, isSubmitting, id, apiDatetimeFormat]);

  // handle errors reported by API
  useEffect(() => {
    let firstFieldName = '';
    for (const field in errors) {
      form.setFields([{ name: field, errors: errors[field] }]);
      if (firstFieldName === '') {
        firstFieldName = field;
      }
    }
    form.scrollToField(firstFieldName);
  }, [form, errors]);

  // submit data handler
  const submitData = async (values) => {
    Logger.log('debug', `EmailForm.submitData(###)`);

    // API POST/PUT payload
    let payload = {};
    for (const input of Object.keys(data)) {
      if (input in values) {

        // transform entity refs to IDs
        if (['event'].includes(input)) {
          payload[input + '_id'] = values[input];
        }

        // transform date values
        else if (['queued_at', 'last_attempt_at'].includes(input)) {
          payload[input] = Format.date(values[input], apiDatetimeFormat);
        }

        else {
          payload[input] = values[input];
        }
      }
    }

    if (id) { // update
      props.update(id, payload, (success) => {
        if (success) {
          message.success(getI18n().t('feedback_form_success'));
        } else {
          message.error(getI18n().t('feedback_form_error'));
        }
      });
    }
  }

  // form submit handler
  const handleFinish = async (values) => {
    Logger.log('debug', `EmailForm.handleFinish(###)`);
    if (!props.isSubmitting) {
      await submitData(values);
    }
  }

  // form error handler
  const handleFinishFailed = ({ values, errorFields, outOfDate }) => {
    Logger.log('debug', `EmailForm.handleFinishFailed(###)`);
    message.error(getI18n().t('feedback_form_error'));
    if (errorFields && errorFields.length > 0) {
      form.scrollToField(errorFields[0].name);
    }
  }

  // move segment row into new position after drag and drop reorder
  const onDragEnd = (result, move) => {
    if (result.destination) {
      move(result.source.index, result.destination.index);
    }
  };

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <Translation>{(t) =>
      <>
        <div className="email-form">
          <Form
            name="emails_form"
            form={form}
            initialValues={data}
            onFinish={handleFinish}
            onFinishFailed={handleFinishFailed}
            validateTrigger="onSubmit"
            {...layout.main}
          >
            <Row gutter={16}>

              <Col xs={24} lg={18}>
                <Card
                  title={id ? t('emails_edit_title') : t('emails_add_title')}
                  extra={isLoading
                    ? <Spin indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} />
                    : null}
                >

                  <div className="form-group">
                    <Form.Item
                      name="accepted"
                      label={t('emails_accepted')}
                      rules={[
                        { required: true, message: t('feedback_validation_required') },
                      ]}
                    >
                      <Input
                        autoFocus
                        disabled={true}
                      />
                    </Form.Item>
                  </div>

                  <div className="form-group">
                    <Form.Item
                      name="rejected"
                      label={t('emails_rejected')}
                      rules={[
                        { required: true, message: t('feedback_validation_required') },
                      ]}
                    >
                      <Input
                        autoFocus
                        disabled={true}
                      />
                    </Form.Item>
                  </div>

                  <div className="form-group">
                    <Form.Item
                      name="attempts"
                      label={t('emails_attempts')}
                      rules={[
                        { required: true, message: t('feedback_validation_required') },
                      ]}
                    >
                      <Input
                        autoFocus
                        disabled={true}
                      />
                    </Form.Item>
                  </div>
                  <div className="form-group">
                    <Form.Item
                      name="service_id"
                      label={t('emails_service_id')}
                      rules={[]}
                    >
                      <Input
                        autoFocus
                        disabled={isLoading || isSubmitting}
                      />
                    </Form.Item>
                  </div>

                  <div className="form-group">
                    <Form.Item
                      name="template"
                      label={t('emails_template')}
                      rules={[
                        { required: true, message: t('feedback_validation_required') },
                      ]}
                    >
                      <Input
                        autoFocus
                        disabled={isLoading || isSubmitting}
                      />
                    </Form.Item>
                  </div>

                  <PriorityInput
                    name="priority"
                    label={t('emails_priority')}
                    rules={null}
                    disabled={isLoading || isSubmitting}
                  />

                  <SendStatusInput
                    name="send_status"
                    label={t('emails_send_status')}
                    rules={null}
                    disabled={isLoading || isSubmitting}
                  />

                  <div className="form-group">
                    <Form.Item
                      name="last_attempt_at"
                      label={t('emails_last_attempt_at')}
                      rules={[]}
                    >
                      <DatePicker
                        autoFocus
                        showTime
                        disabled={isLoading || isSubmitting}
                      />
                    </Form.Item>
                  </div>

                  <div className="form-group">
                    <Form.Item
                      name="queued_at"
                      label={t('emails_queued_at')}
                      rules={[
                        { required: true, message: t('feedback_validation_required') },
                      ]}
                    >
                      <DatePicker
                        autoFocus
                        showTime
                        disabled={isLoading || isSubmitting}
                      />
                    </Form.Item>
                  </div>


                </Card>

                <Card
                  title={t("email_recipients_title")}
                  extra={
                    isLoading ? (
                      <Spin
                        indicator={
                          <LoadingOutlined style={{ fontSize: 20 }} spin />
                        }
                      />
                    ) : null
                  }
                >
                  <Form.List name="recipients">
                    {(recipients, { add, remove, move }) => {
                      return (
                        <>
                          <div className="scroll-recipients">
                            {recipients.map((recipient, i) => (
                              <RecipientsInputRow recipient={recipient} index={i} remove={remove} />
                            ))}
                          </div>

                          <Form.Item style={{ marginBottom: 0 }}>
                            <Button
                              type="dashed"
                              onClick={() => {
                                add();
                              }}
                              block
                            >
                              <PlusOutlined /> {t("emails_add_recipient")}
                            </Button>
                          </Form.Item>
                        </>
                      );
                    }}
                  </Form.List>



                </Card>


                <Card
                  title={t("email_substitutions_title")}
                  extra={
                    isLoading ? (
                      <Spin
                        indicator={
                          <LoadingOutlined style={{ fontSize: 20 }} spin />
                        }
                      />
                    ) : null
                  }
                >


                  <Form.List name="substitutions">
                    {(fields, { add, remove, move }) => {
                      return (
                        <>
                          <DragDropContext
                            onDragEnd={(result) => onDragEnd(result, move)}
                          >
                            <Droppable droppableId="droppable">
                              {(provided, snapshot) => (
                                <div
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                >
                                  {fields.map((field, i) => (
                                    <SubstitutionsInputRow
                                      field={field}
                                      remove={remove}
                                      index={i}
                                      key={i}
                                    />
                                  ))}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </DragDropContext>

                          <Form.Item>
                            <Button
                              type="dashed"
                              onClick={() => {
                                add();
                              }}
                              block
                            >
                              <PlusOutlined /> {t("emails_add_data")}
                            </Button>
                          </Form.Item>
                        </>
                      );
                    }}
                  </Form.List>


                </Card>
              </Col>

              <Col xs={24} lg={6}>
                <Affix offsetTop={10}>
                  <Card title={t('form_metadata_header')}>
                    <FormMetadata
                      id={id}
                      isSubmitting={isSubmitting}
                      delete={props.delete.bind(this)}
                      deleteRedirectTo="EmailsScreen"
                      createdAt={props.createdAt}
                      showStatus={false}
                      updatedAt={props.updatedAt}
                    />
                  </Card>
                </Affix>
              </Col>

            </Row>
          </Form>
        </div>
      </>
    }</Translation>
  );
}

export default EmailForm;

Logger.log('silly', `EmailForm loaded.`);
