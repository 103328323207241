import {connect} from 'react-redux';

import CareGuideTemplateEditScreen from '../components/CareGuideTemplateEditScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.careGuideTemplates.get('isLoading'),
    success: state.careGuideTemplates.getIn(['form', 'success']),
  }
}

const CareGuideTemplateEditScreenContainer = connect(
  mapStateToProps
)(CareGuideTemplateEditScreen);

export default CareGuideTemplateEditScreenContainer;
