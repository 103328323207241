import { connect } from 'react-redux';

import RegistrationCodesCSVButton from '../components/RegistrationCodesCSVButton';

const mapStateToProps = (state) => {
  return {};
};

const RegistrationCodesCSVButtonContainer = connect(mapStateToProps)(
  RegistrationCodesCSVButton
);

export default RegistrationCodesCSVButtonContainer;
