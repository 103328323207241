import { connect } from 'react-redux';

import CareGuidesCSVButton from '../components/CareGuidesCSVButton';

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

const CareGuidesCSVButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CareGuidesCSVButton);

export default CareGuidesCSVButtonContainer;
