import { connect } from 'react-redux';
import { denormalize } from 'normalizr';

import { schema } from '../../../../../state/schema';
import {
  loadUserProfilesPartnerships,
  deleteUserProfilesPartnership,
} from '../../../../../state/modules/userProfilesPartnerships/actions';
import { sendMessage } from '../../../../../state/actions';
import UserProfilesPartnershipsList from '../components/UserProfilesPartnershipsList';

const mapStateToProps = (state, ownProps) => {
  const page = ownProps.page;
  const limit = ownProps.limit;
  const order = ownProps.order;
  const result = state.userProfilesPartnerships.getIn([
    'pages',
    order,
    limit,
    page,
  ]);

  return {
    list: result
      ? result
          .map((x) => {
            return {
              key: x,
              ...denormalize(
                state.entities.getIn(['userProfilesPartnerships', x]),
                schema.userProfilesPartnership,
                state.entities.toJS()
              ),
            };
          })
          .toArray()
          .map((x) => {
            // flatten some nested objects to make table easier to work with
            return {
              partnership_name: x?.partnership?.name ?? '',
              partnership_plan: x?.partnership?.plan?.name ?? '',
              profile_id: x.profile ? x.profile.id : '',
              profile_username:
                x.profile && x.profile.user ? x.profile.user.username : '',
              ...x,
            };
          })
      : [],
    isLoading: state.userProfilesPartnerships.get('isLoading'),
    total: state.userProfilesPartnerships.get('total'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (page, limit, order, filter, cb) => {
      dispatch(loadUserProfilesPartnerships(page, limit, order, filter, cb));
    },
    remove: (id, cb) => {
      dispatch(deleteUserProfilesPartnership(id, cb));
    },
    sendMessage: (level, title, body, expires) => {
      dispatch(sendMessage(level, title, body, expires));
    },
  };
};

const UserProfilesPartnershipsListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProfilesPartnershipsList);

export default UserProfilesPartnershipsListContainer;
