import {connect} from 'react-redux';

import {loadCities} from '../../../../../../state/modules/locationCities/actions';
import CityInput from '../components/CityInput';

const mapStateToProps = (state, ownProps) => {
  const regionIds = ownProps['region_id']
    ? [parseInt(ownProps['region_id'])]
    : (ownProps['region_ids'] ? ownProps['region_ids'].split(',').map(x => parseInt(x)) : []);
  const subregionIds = ownProps['subregion_id']
    ? [parseInt(ownProps['subregion_id'])]
    : (ownProps['subregion_ids'] ? ownProps['subregion_ids'].split(',').map(x => parseInt(x)) : []);
  const cities = state.entities.get('cities', {});

  return {
    options: Object.keys(cities)
      .filter(id => regionIds.length === 0 || (cities[id].region && regionIds.includes(cities[id].region)))
      .filter(id => subregionIds.length === 0 || (cities[id].subregion && subregionIds.includes(cities[id].subregion)))
      .map(id => {return {label: cities[id].name, value: id}}),
    isLoading: state.locationCities.get('areCitiesLoading'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (page, limit, order, filter, cb) => {
      dispatch(loadCities(page, limit, order, filter, cb));
    },
  }
}

const CityInputContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CityInput);

export default CityInputContainer;
