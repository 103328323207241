import { connect } from 'react-redux';

import AdminPartnerClassEditScreen from '../components/AdminPartnerClassEditScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.adminPartnerClasses.get('isLoading'),
    success: state.adminPartnerClasses.getIn(['form', 'success']),
  }
}

const AdminPartnerClassEditScreenContainer = connect(
  mapStateToProps
)(AdminPartnerClassEditScreen);

export default AdminPartnerClassEditScreenContainer;