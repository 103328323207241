import { connect } from 'react-redux';

import DashboardSectionsScreen from '../components/DashboardSectionsScreen';

const mapStateToProps = (state) => {
  return {
    total: state.careGuideDocuments.get('total'),
    isLoading: state.careGuideDocuments.get('isLoading'),
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
const DashboardSectionsScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardSectionsScreen);

export default DashboardSectionsScreenContainer;
