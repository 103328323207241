import React, { useEffect, useState } from 'react';
import { Translation } from 'react-i18next';
import { Form, Select } from 'antd';
import { List } from 'immutable';
import { schema } from '../../../../../state/schema';
import store from '../../../../../state/store';
import { denormalize } from 'normalizr';

const { Option } = Select;

const PartnershipInput = ({
  form,
  name,
  label,
  isLoading,
  disabled,
  load,
  onChange,
  rules = null,
  style = null,
  loadOnMount = true,
  allowNone = false,
  multiple = false,
  filter = {},
  total,
  ...props
}) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [firstLoad, setFirstLoad] = useState(true);

  const [result, setResult] = useState(List([]));

  const limit = 10;
  const order = 'updated_at.asc';
  const state = store.getState();

  // load sections on mount
  useEffect(() => {
    if (loadOnMount) {
      load(page, limit, order, filter, (success, result) => {
        if (success) {
          setResult(List(result));
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [load, page, limit, loadOnMount]);

  const onScroll = async (event) => {
    const target = event.target;
    if (
      !isLoading &&
      target.scrollTop + target.offsetHeight === target.scrollHeight &&
      total > data.length
    ) {
      setPage(page + 1);
    }
  };

  useEffect(() => {
    const options = result
      ? result
          .map((x) => {
            return {
              key: x,
              ...denormalize(
                state.entities.getIn(['partnerships', x]),
                schema.partnership,
                state.entities.toJS()
              ),
            };
          })
          .toArray()
      : [];
    setData((prevState) => {
      const filteredOptions = options.filter(
        (newOption) => !prevState.some((x) => x.id === newOption.id)
      );
      return prevState.concat(filteredOptions);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  useEffect(() => {
    if (firstLoad) {
      const objectId = form.getFieldValue(name);
      if (objectId) {
        const object = objectId
          ? denormalize(
              state.entities.getIn(['partnerships', objectId]),
              schema.partnership,
              state.entities.toJS()
            )
          : null;

        setData((prevState) => {
          let existObject = prevState.find((x) => x.id === object.id);
          if (!existObject) {
            prevState.push(object);
          }

          return prevState;
        });
        setFirstLoad(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstLoad]);

  return (
    <Translation>
      {(t) => (
        <div className="form-group">
          <Form.Item name={name} label={label} style={style} rules={rules}>
            <Select
              onPopupScroll={onScroll}
              optionFilterProp="children"
              disabled={disabled}
              loading={isLoading}
              mode={multiple ? 'multiple' : null}
              maxTagCount={6}
              onChange={onChange}
            >
              {allowNone ? (
                <Select.Option key={0} value="">
                  {t('form_input_options_none')}
                </Select.Option>
              ) : null}
              {data.map((x) => (
                <Option key={x.id} value={x.id}>
                  {x.name}
                </Option>
              ))}
              {isLoading && (
                <Select.Option disabled key={-1}>
                  {t('form_input_loading')}
                </Select.Option>
              )}
            </Select>
          </Form.Item>
        </div>
      )}
    </Translation>
  );
};

export default PartnershipInput;
