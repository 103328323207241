import { connect } from 'react-redux';

import ResourceNotesScreen from '../components/ResourceNotesScreen';
import { resourceNotesCSV } from '../../../../../state/modules/resourceNotes/actions';

const mapStateToProps = (state) => {
  return {
    total: state.resourceNotes.get('total'),
    isLoading: state.resourceNotes.get('isLoading'),
    isDownloading: state.resourceNotes.get('isCSVDownloading'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    download: (order, filter, cb) => {
      dispatch(resourceNotesCSV(order, filter, cb));
    },
  };
};

const ResourceNotesScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResourceNotesScreen);

export default ResourceNotesScreenContainer;
