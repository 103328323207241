import {connect} from 'react-redux';

import PostalCodeScreen from '../components/PostalCodeScreen';

const mapStateToProps = state => {
  return {
    total: state.locationPostalCodes.get('total'),
    isLoading: state.locationPostalCodes.get('arePostalCodesLoading')
  }
}

const PostalCodeScreenContainer = connect(
  mapStateToProps
)(PostalCodeScreen);

export default PostalCodeScreenContainer;
