import React, {useState} from 'react';
import {Translation} from 'react-i18next';
import {Button, Form, Input, Space} from 'antd';
import {DragOutlined, MinusOutlined} from '@ant-design/icons';
import {Draggable} from "react-beautiful-dnd";

import FieldTypeInput from '../../careGuideSectionFields/components/FieldTypeInput';
import CareGuideContentEditor from './CareGuideContentEditor';
import FieldTypeTableEditor from './FieldTypeTableEditor';
import FieldTypeADLTableEditor from './FieldTypeADLTableEditor';
import FieldTypeMedTableEditor from './FieldTypeMedTableEditor';


const SegmentContentInputRow = ({segment, content, index, remove, form, defaultContentType, ...props}) => {

  // const [contentType, setContentType] = useState(form.getFieldValue([`segments`, segment.name, `contents`, content.name, `type`]));
  const [contentType, setContentType] = useState(defaultContentType);

  // input column settings
  const layout = {
    main: {
      wrapperCol: {span: 24},
    },
  }

  // prep content input based on type
  var contentInput = null;
  var contentEditor = null;
  var contentStyle = {display: 'none'};
  switch(contentType) {
    case 1:
      contentStyle = null;
      contentInput = <Input placeholder="Content" />;
      break;
    case 2:
      contentStyle = null;
      contentInput = <Input.TextArea placeholder="Content" rows={4} />;
      break;
    case 3:
      contentInput = <Input type="hidden" />;
      contentEditor = 
        <CareGuideContentEditor
          onChange={(contents, delta, source, editor) => {
            form.setFields([{
              name: [`segments`, segment.name, `contents`, content.name, `content`],
              value: editor.getHTML()
            }]);
          }}
          defaultValue={form.getFieldValue([`segments`, segment.name, `contents`, content.name, `content`])}
        />;
      break;
    case 4:
      contentStyle = null;
      contentInput =
        <FieldTypeTableEditor
          form={form}
          content={content}
          contentsPath={[`segments`, segment.name, `contents`, content.name]}
        />;
      break;
    case 5:
      contentStyle = null;
      contentInput =
        <FieldTypeADLTableEditor
          form={form}
          content={content}
          contentsPath={[`segments`, segment.name, `contents`, content.name]}
        />;
      break;
    case 6:
      contentStyle = null;
      contentInput =
        <FieldTypeMedTableEditor
          form={form}
          content={content}
          contentsPath={[`segments`, segment.name, `contents`, content.name]}
        />;
      break;
    default:
  }

  return (
    <Draggable key={content.key} draggableId={content.key.toString()} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <Translation>{(t) => 
            <>
              <Space style={{display: 'flex'}} align="start">

                <div className="drag-handle" {...provided.dragHandleProps}>
                  <DragOutlined />
                </div>

                <FieldTypeInput
                  {...content}
                  name={[content.name, 'type']}
                  fieldKey={[content.fieldKey, 'type']}
                  onChange={e => setContentType(e)}
                  {...layout.main}
                />

                <Button
                  danger
                  shape="circle"
                  size="small"
                  icon={<MinusOutlined />}
                  onClick={() => remove(content.name)}
                />
              </Space>

              <Form.Item
                {...content}
                name={[content.name, 'content']}
                fieldKey={[content.fieldKey, 'content']}
                style={contentStyle}
                {...layout.main}
              >
                {contentInput}
              </Form.Item>
              {contentEditor}

            </>
          }</Translation>
        </div>
      )}
    </Draggable>
  )
}

export default SegmentContentInputRow;
