import { connect } from 'react-redux';

import ImportTasksScreen from '../components/ImportTasksScreen';

const mapStateToProps = (state) => {
  return {
    total: state.importTasks.get('total'),
    isLoading: state.importTasks.get('isLoading'),
  };
};

const ImportTasksScreenContainer = connect(mapStateToProps)(ImportTasksScreen);

export default ImportTasksScreenContainer;
