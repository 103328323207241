import {connect} from 'react-redux';

import {downloadRolesCSV} from '../../../../../state/modules/roles/actions';
import RolesCSVButton from '../components/RolesCSVButton';

const mapStateToProps = state => {
  return {
    isDownloading: state.roles.get('isCSVDownloading'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    download: (order, filter, cb) => {
      dispatch(downloadRolesCSV(order, filter, cb));
    }
  }
}

const RolesCSVButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RolesCSVButton);

export default RolesCSVButtonContainer;
