import { connect } from 'react-redux';

import CareGuideHelpersCSVButton from '../components/CareGuideHelpersCSVButton';

const mapStateToProps = (state) => {
  return {};
};

const CareGuideHelpersCSVButtonContainer = connect(mapStateToProps)(
  CareGuideHelpersCSVButton
);

export default CareGuideHelpersCSVButtonContainer;
