import {connect} from 'react-redux';

import ResourceTagsScreen from '../components/ResourceTagsScreen';

const mapStateToProps = state => {
  return {
    total: state.resourceTags.get('total'),
    isLoading: state.resourceTags.get('isLoading')
  }
}

const ResourceTagsScreenContainer = connect(
  mapStateToProps
)(ResourceTagsScreen);

export default ResourceTagsScreenContainer;
