import {Map, List} from 'immutable';

import {
  MEDIA_LIST_REQUEST,
  MEDIA_LIST_SUCCESS,
  MEDIA_LIST_FAILURE,
  MEDIA_READ_REQUEST,
  MEDIA_READ_SUCCESS,
  MEDIA_READ_FAILURE,
  MEDIA_UPDATE_REQUEST,
  MEDIA_UPDATE_SUCCESS,
  MEDIA_UPDATE_FAILURE,
  MEDIA_CREATE_REQUEST,
  MEDIA_CREATE_SUCCESS,
  MEDIA_CREATE_FAILURE,
  MEDIA_DELETE_REQUEST,
  MEDIA_DELETE_SUCCESS,
  MEDIA_DELETE_FAILURE,
  MEDIA_CARE_GUIDE_LIST_REQUEST,
  MEDIA_CARE_GUIDE_LIST_SUCCESS,
  MEDIA_CARE_GUIDE_LIST_FAILURE,
  MEDIA_PARTNERSHIP_LIST_REQUEST,
  MEDIA_PARTNERSHIP_LIST_SUCCESS,
  MEDIA_PARTNERSHIP_LIST_FAILURE,
  MEDIA_FORM_DESTROY,
  MEDIA_CSV_DOWNLOAD_REQUEST,
  MEDIA_CSV_DOWNLOAD_SUCCESS,
  MEDIA_CSV_DOWNLOAD_FAILURE
} from './actions'
import Logger from '../../../lib/Logger';

export default function media(
  state=Map({
    isLoading: false,
    isCareGuideMediaLoading: false,
    isPartnershipMediaLoading: false,
    isCSVDownloading: false,
    pages: {},
  }),
  action
) {
  Logger.log('debug', `[media.reducers] media(%j, %j)`, state, action);

  switch(action.type) {

    case MEDIA_LIST_REQUEST:
      return state.mergeDeep({
        isLoading: true
      });

    case MEDIA_LIST_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        pages: {
          [action.order]: {
            [action.limit]: {
              [action.page]: null
            }
          }
        },
        total: action.total,
        lastUpdated: action.receivedAt
      }).setIn(['pages', action.order, action.limit, action.page], List(action.result));

    case MEDIA_LIST_FAILURE:
      return state.mergeDeep({
        isLoading: false
      });

    case MEDIA_READ_REQUEST:
      return state.mergeDeep({
        isLoading: true
      });

    case MEDIA_READ_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        lastUpdated: action.receivedAt
      });

    case MEDIA_READ_FAILURE:
      return state.mergeDeep({
        isLoading: false
      });

    case MEDIA_UPDATE_REQUEST:
      return state.mergeDeep({
        isLoading: true,
        form: {
          isSubmitting: true,
          success: null,
          errors: null
        }
      });

    case MEDIA_UPDATE_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        form: {
          isSubmitting: false,
          success: true,
          errors: null
        },
        lastUpdated: action.receivedAt
      });

    case MEDIA_UPDATE_FAILURE:
      return state.mergeDeep({
        isLoading: false,
        form: {
          isSubmitting: false,
          success: false,
          errors: null
        }
      }).setIn(['form', 'errors'], action.error);

    case MEDIA_CREATE_REQUEST:
      return state.mergeDeep({
        isLoading: true,
        form: {
          isSubmitting: true,
          success: null,
          errors: null
        }
      });

    case MEDIA_CREATE_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        form: {
          isSubmitting: false,
          success: true,
          created_id: action.id,
          errors: null
        },
        lastUpdated: action.receivedAt
      });

    case MEDIA_CREATE_FAILURE:
      return state.mergeDeep({
        isLoading: false,
        form: {
          isSubmitting: false,
          success: false,
          errors: null
        }
      }).setIn(['form', 'errors'], action.error);

    case MEDIA_DELETE_REQUEST:
      return state.mergeDeep({
        isLoading: true
      });

    case MEDIA_DELETE_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        lastUpdated: action.receivedAt
      });

    case MEDIA_DELETE_FAILURE:
      return state.mergeDeep({
        isLoading: false
      });

    case MEDIA_CARE_GUIDE_LIST_REQUEST:
      return state.mergeDeep({
        isCareGuideMediaLoading: true
      });

    case MEDIA_CARE_GUIDE_LIST_SUCCESS:
      return state.mergeDeep({
        isCareGuideMediaLoading: false,
        care_guides: {
          [action.careGuideId]: {
            [action.order]: {
              [action.limit]: {
                [action.page]: null
              }
            },
            total: action.total,
          }
        },
        lastUpdated: action.receivedAt
      }).setIn(['care_guides', action.careGuideId, action.order, action.limit, action.page], List(action.result));

    case MEDIA_CARE_GUIDE_LIST_FAILURE:
      return state.mergeDeep({
        isCareGuideMediaLoading: false
      });

    case MEDIA_PARTNERSHIP_LIST_REQUEST:
      return state.mergeDeep({
        isPartnershipMediaLoading: true
      });
  
    case MEDIA_PARTNERSHIP_LIST_SUCCESS:
      return state.mergeDeep({
        isPartnershipMediaLoading: false,
        partnerships: {
          [action.partnershipId]: {
            [action.order]: {
              [action.limit]: {
                [action.page]: null
              }
            },
            total: action.total,
          }
        },
        lastUpdated: action.receivedAt
      }).setIn(['partnerships', action.partnershipId, action.order, action.limit, action.page], List(action.result));
  
    case MEDIA_PARTNERSHIP_LIST_FAILURE:
      return state.mergeDeep({
        isPartnershipMediaLoading: false
      });

    case MEDIA_FORM_DESTROY:
      return state.mergeDeep({
        form: null
      }).set('form', action.form);

    case MEDIA_CSV_DOWNLOAD_REQUEST:
      return state.mergeDeep({
        isCSVDownloading: true
      });

    case MEDIA_CSV_DOWNLOAD_SUCCESS:
      return state.mergeDeep({
        isCSVDownloading: false,
      });

    case MEDIA_CSV_DOWNLOAD_FAILURE:
      return state.mergeDeep({
        isCSVDownloading: false,
      });

    default:
      return state;
  }
}

Logger.log('silly', `media.reducers loaded.`);
