import React from 'react';
import {Translation} from 'react-i18next';
import {Form, Select} from 'antd';


const SubscriptionCycleInput = ({name, label, fieldKey=null, rules=null, disabled=false, ...props}) => {

  const options = [
    {
      value: null,
      label: "accounts_input_subscription_cycle_none",
    },
    {
      value: 'M',
      label: "accounts_input_subscription_cycle_month",
    },
    {
      value: 'Y',
      label: "accounts_input_subscription_cycle_year",
    },
  ];

  return (
    <Translation>{(t) => 
      <div className="form-group">
        <Form.Item
          name={name || 'subscription_cycle'}
          fieldKey={fieldKey}
          label={label || t('accounts_input_subscription_cycle')}
          rules={rules}
        >
          <Select disabled={disabled}>
            {options.map(x => <Select.Option key={x.value} value={x.value}>{t(x.label)}</Select.Option>)}
          </Select>
        </Form.Item>
      </div>
    }</Translation>
  );
}

export default SubscriptionCycleInput;
