import { connect } from 'react-redux';
import { denormalize } from 'normalizr';

import { schema } from '../../../../../state/schema';
import {
  loadPartnershipFormAnswers,
  deletePartnershipFormAnswer,
} from '../../../../../state/modules/partnershipFormAnswers/actions';
import PartnershipFormAnswersList from '../components/PartnershipFormAnswersList';

const mapStateToProps = (state, ownProps) => {
  const page = ownProps.page;
  const limit = ownProps.limit;
  const order = ownProps.order;
  const result = state.partnershipFormAnswers.getIn([
    'pages',
    order,
    limit,
    page,
  ]);

  return {
    list: result
      ? result
          .map((x) => {
            return {
              key: x,
              ...denormalize(
                state.entities.getIn(['partnershipFormAnswers', x]),
                schema.partnershipFormAnswer,
                state.entities.toJS()
              ),
            };
          })
          .toArray()
          .map((x) => {
            // flatten some nested objects to make table easier to work with
            return {
              care_guide_name:
                x?.care_guide?.first_name && x?.care_guide?.last_name
                  ? `${x?.care_guide?.first_name} ${x?.care_guide?.last_name}`
                  : '',
              partnership_name: x?.care_guide?.partnership?.name ?? '',
              profile_username:
                x.profile && x.profile.user ? x.profile.user.username : '',
              ...x,
            };
          })
      : [],
    isLoading: state.partnershipFormAnswers.get('isLoading'),
    total: state.partnershipFormAnswers.get('total'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (page, limit, order, filter, cb) => {
      dispatch(loadPartnershipFormAnswers(page, limit, order, filter, cb));
    },
    remove: (id, cb) => {
      dispatch(deletePartnershipFormAnswer(id, cb));
    },
  };
};

const PartnershipFormAnswersListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnershipFormAnswersList);

export default PartnershipFormAnswersListContainer;
