import {connect} from 'react-redux';

import CareGuideHelperAddScreen from '../components/CareGuideHelperAddScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.careGuideHelpers.get('isLoading'),
    success: state.careGuideHelpers.getIn(['form', 'success']),
  }
}

const CareGuideHelperAddScreenContainer = connect(
  mapStateToProps
)(CareGuideHelperAddScreen);

export default CareGuideHelperAddScreenContainer;
