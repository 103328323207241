import { connect } from 'react-redux';

import PartnershipFormAnswersScreen from '../components/PartnershipFormAnswersScreen';

const mapStateToProps = (state) => {
  return {
    total: state.partnershipFormAnswers.get('total'),
    isLoading: state.partnershipFormAnswers.get('isLoading'),
  };
};

const PartnershipFormAnswersScreenContainer = connect(mapStateToProps)(
  PartnershipFormAnswersScreen
);

export default PartnershipFormAnswersScreenContainer;
