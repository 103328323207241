import { normalize } from 'normalizr';

import api from '../../api';
import { schema } from '../../schema';
import { addEntities, removeEntity } from '../../actions';
import Format from '../../../lib/Format';
import Logger from '../../../lib/Logger';

// ACTION TYPES

export const JOURNAL_POST_LIST_REQUEST = 'JOURNAL_POST_LIST_REQUEST';
export const JOURNAL_POST_LIST_SUCCESS = 'JOURNAL_POST_LIST_SUCCESS';
export const JOURNAL_POST_LIST_FAILURE = 'JOURNAL_POST_LIST_FAILURE';
export const JOURNAL_POST_READ_REQUEST = 'JOURNAL_POST_READ_REQUEST';
export const JOURNAL_POST_READ_SUCCESS = 'JOURNAL_POST_READ_SUCCESS';
export const JOURNAL_POST_READ_FAILURE = 'JOURNAL_POST_READ_FAILURE';
export const JOURNAL_POST_UPDATE_REQUEST = 'JOURNAL_POST_UPDATE_REQUEST';
export const JOURNAL_POST_UPDATE_SUCCESS = 'JOURNAL_POST_UPDATE_SUCCESS';
export const JOURNAL_POST_UPDATE_FAILURE = 'JOURNAL_POST_UPDATE_FAILURE';
export const JOURNAL_POST_CREATE_REQUEST = 'JOURNAL_POST_CREATE_REQUEST';
export const JOURNAL_POST_CREATE_SUCCESS = 'JOURNAL_POST_CREATE_SUCCESS';
export const JOURNAL_POST_CREATE_FAILURE = 'JOURNAL_POST_CREATE_FAILURE';
export const JOURNAL_POST_DELETE_REQUEST = 'JOURNAL_POST_DELETE_REQUEST';
export const JOURNAL_POST_DELETE_SUCCESS = 'JOURNAL_POST_DELETE_SUCCESS';
export const JOURNAL_POST_DELETE_FAILURE = 'JOURNAL_POST_DELETE_FAILURE';
export const JOURNAL_POST_FORM_DESTROY = 'JOURNAL_POST_FORM_DESTROY';
export const JOURNAL_POST_CSV_DOWNLOAD_REQUEST =
  'JOURNAL_POST_CSV_DOWNLOAD_REQUEST';
export const JOURNAL_POST_CSV_DOWNLOAD_SUCCESS =
  'JOURNAL_POST_CSV_DOWNLOAD_SUCCESS';
export const JOURNAL_POST_CSV_DOWNLOAD_FAILURE =
  'JOURNAL_POST_CSV_DOWNLOAD_FAILURE';

// ACTION CREATORS

export function journalPostListRequest(page, limit, order, filter) {
  Logger.log(
    'debug',
    `[journalPosts.actions] journalPostListRequest(${page}, ${limit}, ${order}, %j)`,
    filter
  );
  return {
    type: JOURNAL_POST_LIST_REQUEST,
    page: page,
    limit: limit,
    order: order,
    filter: filter,
  };
}

export function journalPostListSuccess(data) {
  Logger.log(
    'debug',
    `[journalPosts.actions] journalPostListSuccess(%j)`,
    data
  );
  return {
    type: JOURNAL_POST_LIST_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now(),
  };
}

export function journalPostListFailure(error) {
  Logger.log(
    'debug',
    `[journalPosts.actions] journalPostListFailure(%j)`,
    error
  );
  return {
    type: JOURNAL_POST_LIST_FAILURE,
    error: error,
  };
}

export function journalPostReadRequest(id) {
  Logger.log('debug', `[journalPosts.actions] journalPostReadRequest(${id})`);
  return {
    type: JOURNAL_POST_READ_REQUEST,
    id: id,
  };
}

export function journalPostReadSuccess(data) {
  Logger.log(
    'debug',
    `[journalPosts.actions] journalPostReadSuccess(%j)`,
    data
  );
  return {
    type: JOURNAL_POST_READ_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function journalPostReadFailure(error) {
  Logger.log(
    'debug',
    `[journalPosts.actions] journalPostReadFailure(%j)`,
    error
  );
  return {
    type: JOURNAL_POST_READ_FAILURE,
    error: error,
  };
}

export function journalPostUpdateRequest(id, data) {
  Logger.log(
    'debug',
    `[journalPosts.actions] journalPostUpdateRequest(${id}, %j)`,
    data
  );
  return {
    type: JOURNAL_POST_UPDATE_REQUEST,
  };
}

export function journalPostUpdateSuccess(data) {
  Logger.log(
    'debug',
    `[journalPosts.actions] journalPostUpdateSuccess(%j)`,
    data
  );
  return {
    type: JOURNAL_POST_UPDATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function journalPostUpdateFailure(error) {
  Logger.log(
    'debug',
    `[journalPosts.actions] journalPostUpdateFailure(%j)`,
    error
  );
  return {
    type: JOURNAL_POST_UPDATE_FAILURE,
    error: error,
  };
}

export function journalPostCreateRequest(data) {
  Logger.log(
    'debug',
    `[journalPosts.actions] journalPostCreateRequest(%j)`,
    data
  );
  return {
    type: JOURNAL_POST_CREATE_REQUEST,
  };
}

export function journalPostCreateSuccess(data) {
  Logger.log(
    'debug',
    `[journalPosts.actions] journalPostCreateSuccess(%j)`,
    data
  );
  return {
    type: JOURNAL_POST_CREATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function journalPostCreateFailure(error) {
  Logger.log(
    'debug',
    `[journalPosts.actions] journalPostCreateFailure(%j)`,
    error
  );
  return {
    type: JOURNAL_POST_CREATE_FAILURE,
    error: error,
  };
}

export function journalPostDeleteRequest(id) {
  Logger.log('debug', `[journalPosts.actions] journalPostDeleteRequest(${id})`);
  return {
    type: JOURNAL_POST_DELETE_REQUEST,
    id: id,
  };
}

export function journalPostDeleteSuccess(id) {
  Logger.log('debug', `[journalPosts.actions] journalPostDeleteSuccess(${id})`);
  return {
    type: JOURNAL_POST_DELETE_SUCCESS,
    id: id,
  };
}

export function journalPostDeleteFailure(error) {
  Logger.log(
    'debug',
    `[journalPosts.actions] journalPostDeleteFailure(%j)`,
    error
  );
  return {
    type: JOURNAL_POST_DELETE_FAILURE,
    error: error,
  };
}

export function journalPostFormDestroy(formState = null) {
  Logger.log(
    'debug',
    `[journalPosts.actions] journalPostFormDestroy(%j)`,
    formState
  );
  return {
    type: JOURNAL_POST_FORM_DESTROY,
    form: formState,
  };
}

export function journalPostCSVDownloadRequest(order, filter) {
  Logger.log(
    'debug',
    `[state.journalPosts.actions] journalPostCSVDownloadRequest()`
  );
  return {
    type: JOURNAL_POST_CSV_DOWNLOAD_REQUEST,
    order: order,
    filter: filter,
  };
}

export function journalPostCSVDownloadSuccess() {
  Logger.log(
    'debug',
    `[state.journalPosts.actions] journalPostCSVDownloadSuccess()`
  );
  return {
    type: JOURNAL_POST_CSV_DOWNLOAD_SUCCESS,
  };
}

export function journalPostCSVDownloadFailure() {
  Logger.log(
    'debug',
    `[state.journalPosts.actions] journalPostCSVDownloadFailure()`
  );
  return {
    type: JOURNAL_POST_CSV_DOWNLOAD_FAILURE,
  };
}

// API THUNK ACTION CREATORS

export function loadJournalPosts(
  page = 1,
  limit = 10,
  order = null,
  filter = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[journalPosts.actions] loadJournalPosts(${page}, ${limit}, ${order}, %j, ###)`,
    filter
  );

  return async function (dispatch) {
    dispatch(journalPostListRequest(page, limit, order, filter));

    // call API
    const response = await api.getJournalPosts(page, limit, order, filter);
    let success = false;
    let result = [];

    // get journal posts list success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API journal posts list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );

      const normalizedEntities = normalize(
        response.getIn(['data', 'journal_posts']),
        [schema.journalPost]
      );
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result,
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(journalPostListSuccess(data));
      success = true;
      result = normalizedEntities.result;
    } else if (1 === page && 204 === response.get('status')) {
      Logger.log(
        'info',
        `Get API journal posts success [empty]. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: [],
      };
      dispatch(journalPostListSuccess(data));
      success = true;

      // get journal posts list failure
    } else {
      Logger.log(
        'info',
        `Get API journal posts list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      dispatch(journalPostListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success, result);
  };
}

export function loadJournalPost(id, cb = function () {}) {
  Logger.log('debug', `[journalPosts.actions] loadJournalPost(${id}, ###)`);

  return async function (dispatch) {
    dispatch(journalPostReadRequest(id));

    // call API
    const response = await api.getJournalPost(id);
    let success = false;

    // get journal post success
    if (200 === response.get('status')) {
      Logger.log('info', `Get API journal post success. ID: ${id}.`);

      const normalizedEntities = normalize(
        [response.getIn(['data', 'journal_post'])],
        [schema.journalPost]
      );
      const data = {
        id: response.getIn(['data', 'journal_post', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(journalPostReadSuccess(data));
      success = true;

      // get journal post failure
    } else {
      Logger.log('info', `Get API journal post failure. ID: ${id}.`);
      dispatch(journalPostReadFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function updateJournalPost(id, data, cb = function () {}) {
  Logger.log(
    'debug',
    `[journalPosts.actions] updateJournalPost(${id}, %j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(journalPostUpdateRequest(id, data));

    // call API
    const response = await api.putJournalPost(id, data);
    let success = false;

    // put journal post success
    if (200 === response.get('status')) {
      Logger.log('info', `PUT API journal post success. User: ${id}.`);

      const normalizedEntities = normalize(
        [response.getIn(['data', 'journal_post'])],
        [schema.journalPost]
      );
      const data = {
        id: response.getIn(['data', 'journal_post', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(journalPostUpdateSuccess(data));
      success = true;

      // get journal post failure
    } else {
      Logger.log('info', `PUT API journal post failure. ID: ${id}.`);
      dispatch(journalPostUpdateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function createJournalPost(data, cb = function () {}) {
  Logger.log(
    'debug',
    `[journalPosts.actions] createJournalPost(%j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(journalPostCreateRequest(data));

    // call API
    const response = await api.postJournalPosts(data);
    let success = false;

    // post journal posts success
    if (201 === response.get('status')) {
      Logger.log(
        'info',
        `POST API journal posts success. Post: ${response.getIn([
          'data',
          'journal_post',
          'id',
        ])}.`
      );

      const normalizedEntities = normalize(
        [response.getIn(['data', 'journal_post'])],
        [schema.journalPost]
      );
      const data = {
        id: response.getIn(['data', 'journal_post', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(journalPostCreateSuccess(data));
      success = true;

      // get journal posts failure
    } else {
      Logger.log('info', `POST API journal posts failure.`);
      dispatch(journalPostCreateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function deleteJournalPost(id, cb = function () {}) {
  Logger.log('debug', `[journalPosts.actions] deleteJournalPost(${id}, ###)`);

  return async function (dispatch) {
    dispatch(journalPostDeleteRequest(id));

    // call API
    const response = await api.deleteJournalPost(id);
    let success = false;

    // delete journal post success
    if (204 === response.get('status')) {
      Logger.log('info', `DELETE API journal post success. ID: ${id}.`);

      dispatch(removeEntity({ entityType: 'journal_posts', id: id }));
      dispatch(journalPostDeleteSuccess(id));
      success = true;

      // get journal post failure
    } else {
      Logger.log('info', `DELETE API journal post failure. ID: ${id}.`);
      dispatch(journalPostDeleteFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function downloadJournalPostsCSV(
  order = null,
  filter = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.journalPosts.actions] downloadJournalPostsCSV(${order}, ${filter}, ###)`
  );

  return async function (dispatch) {
    dispatch(journalPostCSVDownloadRequest(order, filter));

    // call API
    const response = await api.getJournalPostsCSV(order, filter);
    let success = false;
    // get journal posts CSV success
    if (200 === response.get('status')) {
      Logger.log('info', `Get API journal posts CSV success. Order: ${order}`);
      const now = new Date();

      // trigger browser download
      const url = window.URL.createObjectURL(new Blob([response.get('data')]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `cv-journal-posts-${Format.date(now, 'YYYY-MM-DDTHHmm')}.csv`
      );
      document.body.appendChild(link);
      link.click();

      dispatch(journalPostCSVDownloadSuccess());
      success = true;

      // get journal posts CSV failure
    } else {
      Logger.log('info', `Get API journal posts CSV failure.`);
      dispatch(journalPostCSVDownloadFailure());
    }

    // callback function
    cb(success);
  };
}

Logger.log('silly', `journalPosts.actions loaded.`);
