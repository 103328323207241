import { connect } from 'react-redux';
import { denormalize } from 'normalizr';

import { schema } from '../../../../../state/schema';
import { loadImportTasks } from '../../../../../state/modules/importTasks/actions';
import ImportTasksList from '../components/ImportTasksList';
import Config from '../../../../../Config';

const mapStateToProps = (state, ownProps) => {
  const page = ownProps.page;
  const limit = ownProps.limit || Config.get('DEFAULT_LIST_LENGTH', 10);
  const order = ownProps.order;
  const result = state.importTasks.getIn(['pages', order, limit, page]);

  return {
    list: result
      ? result
          .map((x) => {
            return {
              key: x,
              ...denormalize(
                state.entities.getIn(['importTasks', x]),
                schema.importTask,
                state.entities.toJS()
              ),
            };
          })
          .toArray().map(x => {
            // flatten some nested objects to make table easier to work with
            return {
              administrator_username: x?.administrator?.username ?? '',
              care_guide_name: x?.care_guide?.first_name &&  x.care_guide?.last_name ? `${x.care_guide?.first_name} ${x.care_guide?.last_name}` : '',
              owner_username: x?.care_guide?.owner?.profile?.user?.username ?? '',
              ...x,
            };
          })
      : [],
    isLoading: state.importTasks.get('isLoading'),
    total: state.importTasks.get('total'),
    order,
    limit,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (page, limit, order, filter, cb) => {
      dispatch(loadImportTasks(page, limit, order, filter, cb));
    },
  };
};

const ImportTasksListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ImportTasksList);

export default ImportTasksListContainer;
