import {getI18n} from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import PasswordResetsScreen from './containers/PasswordResetsScreenContainer';

const register = () => {
  Logger.log('debug', `passwordResets.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'passwordResets', 'routes'])) {
    Events.dispatch('ADD_MAIN_ROUTES', 
      {
        'PasswordResetsScreen': ['PrivateRoute', '/password-resets/:page(\\d+)?', true, PasswordResetsScreen, getI18n().t('password_resets_route_list')],
      }
    );
  }
}

export default register;

Logger.log('silly', `passwordResets.register() loaded.`);
