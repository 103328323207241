import {connect} from 'react-redux';

import {loadRegions} from '../../../../../../state/modules/locationRegions/actions';
import RegionInput from '../components/RegionInput';

const mapStateToProps = (state, ownProps) => {
  const countryIds = ownProps['country_id']
    ? [parseInt(ownProps['country_id'])]
    : (ownProps['country_ids'] ? ownProps['country_ids'].split(',').map(x => parseInt(x)) : []);
  const regions = state.entities.get('regions', {});
  
  let options =  Object.keys(regions)
  .filter(id => regions[id].country && countryIds.includes(regions[id].country))
  .map(id => {return {label: regions[id].name, value: id}})
  return {
    options: options,
    isLoading: state.locationRegions.get('areRegionsLoading'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (page, limit, order, filter, cb) => {
      dispatch(loadRegions(page, limit, order, filter, cb));
    },
  }
}

const RegionInputContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RegionInput);

export default RegionInputContainer;
