import {connect} from 'react-redux';

import ResourceServiceTagEditScreen from '../components/ResourceServiceTagEditScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.resourceServiceTags.get('isLoading'),
    success: state.resourceServiceTags.getIn(['form', 'success']),
  }
}

const ResourceServiceTagEditScreenContainer = connect(
  mapStateToProps
)(ResourceServiceTagEditScreen);

export default ResourceServiceTagEditScreenContainer;
