import {connect} from 'react-redux';

import {downloadCareGuideOwndersCSV} from '../../../../../state/modules/careGuideOwners/actions';
import CareGuideOwnersCSVButton from '../components/CareGuideOwnersCSVButton';

const mapStateToProps = state => {
  return {
    isDownloading: state.careGuideOwners.get('isCSVDownloading'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    download: (order, filter, cb) => {
      dispatch(downloadCareGuideOwndersCSV(order, filter, cb));
    }
  }
}

const CareGuideOwnersCSVButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CareGuideOwnersCSVButton);

export default CareGuideOwnersCSVButtonContainer;
