import {getI18n} from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import JournalPartnerTagsScreen from './containers/JournalPartnerTagsScreenContainer';
import JournalPartnerTagAddScreen from './containers/JournalPartnerTagAddScreenContainer';
import JournalPartnerTagEditScreen from './containers/JournalPartnerTagEditScreenContainer';

const register = () => {
  Logger.log('debug', `journalPartnerTags.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'journalPartnerTags', 'routes'])) {
    Events.dispatch('ADD_MAIN_ROUTES', 
      {
        'JournalPartnerTagAddScreen': ['PrivateRoute', '/journal-partner-tags/add', true, JournalPartnerTagAddScreen, getI18n().t('journal_partner_tag_route_add')],
        'JournalPartnerTagEditScreen': ['PrivateRoute', '/journal-partner-tags/edit/:id([\\w\\-]+)', true, JournalPartnerTagEditScreen, getI18n().t('journal_partner_tag_route_edit')],
        'JournalPartnerTagsScreen': ['PrivateRoute', '/journal-partner-tags/:page(\\d+)?', true, JournalPartnerTagsScreen, getI18n().t('journal_partner_tags_route_list')],
      }
    );
  }
}

export default register;

Logger.log('silly', `journalPartnerTags.register() loaded.`);
