import {connect} from 'react-redux';

import SubRegionAddScreen from '../components/SubRegionAddScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.locationSubRegions.get('areSubRegionsLoading'),
    success: state.locationSubRegions.getIn(['form', 'success']),
  }
}

const SubRegionAddScreenContainer = connect(
  mapStateToProps
)(SubRegionAddScreen);

export default SubRegionAddScreenContainer;
