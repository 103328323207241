import {connect} from 'react-redux';

import {createSession} from '../../../../../state/actions';
import TwoFactorAuthForm from '../components/TwoFactorAuthForm';

const mapStateToProps = (state, ownProps) => {
  return {
    otpToken: state.session.get('otpToken'),
    isSubmitting: state.session.get('isSubmitting'),
    success: state.session.getIn(['form', 'success'])
  }
}

const mapDispatchToProps = dispatch => {
  return {
    createSession: (data, cb) => {
      dispatch(createSession(data, cb));
    }
  }
}

const TwoFactorAuthFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TwoFactorAuthForm);

export default TwoFactorAuthFormContainer
