import React, {useState, useEffect} from 'react';
import {Redirect} from 'react-router-dom'
import {Translation, getI18n} from 'react-i18next';
import {Affix, Card, Col, Form, Input, Row, Spin} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';



import message from '../../../../elements/lib/MessageWrapper';
import FormMetadata from '../../../../elements/components/FormMetadata';
import {pathTo} from '../../../../Routes';

import SubRegionInput from '../../subregion/containers/SubRegionInputContainer';
import RegionInput from '../../region/containers/RegionInputContainer';
import CountryInput from '../../country/containers/CountryInputContainer';
import Logger from '../../../../../../lib/Logger';


const CityForm = ({id, data, errors, load, destroyForm, isLoading, isSubmitting, created_id, ...props}) => {

  
  const [redirectTo, setRedirectTo] = useState(null);
  const [countryId, setCountryId] = useState(data['country']);
  const [regionId, setRegionId] = useState(data['region']);
  const [form] = Form.useForm();

  // form column settings
  const layout = {
    main: {
      labelCol: {span: 5},
      wrapperCol: {span: 19},
    },
  }


  // load record data from API
  useEffect(() => {
    if (id) {
      load(id);
    }
  }, [id, load]);

  // update input values when new data is available
  const dataString = JSON.stringify(data);
  useEffect(() => {
    if (id && !isSubmitting) {
      const dataObj = JSON.parse(dataString);
      setCountryId(dataObj['country']);
      form.setFieldsValue(dataObj);
    }
  }, [form, dataString, isSubmitting, id]);

  // handle errors reported by API
  useEffect(() => {
    let firstFieldName = '';
    for (const field in errors) {
      form.setFields([{name: field, errors: errors[field]}]);
      if (firstFieldName === '') {
        firstFieldName = field;
      }
    }
    form.scrollToField(firstFieldName);
  }, [form, errors]);

  // redirect add form to edit form on successful create action
  useEffect(() => {
    if (created_id) {
      setRedirectTo(pathTo('CityEditScreen', {id: created_id}));
    }
    return () => {
      destroyForm();
    }
  }, [created_id, setRedirectTo, destroyForm]);

  // submit data handler
  const submitData = async (values) => {
    Logger.log('debug', `CityForm.submitData(###)`);

    // API POST/PUT payload
    let payload = {};
    for (const input of Object.keys(data)) {
      if (['region'].includes(input)) {
        payload['region_id'] = parseInt(values[input]);
      }
      else if (['subregion'].includes(input)) {
        payload['subregion_id'] = parseInt(values[input]);
      }
      else if (input in values) {
        payload[input] = values[input];
      }
    }

    delete payload['country']

    if (id) { // update
      props.update(id, payload, (success) => {
        if (success) {
          message.success(getI18n().t('feedback_form_success'));
        } else {
          message.error(getI18n().t('feedback_form_error'));
        }
      });      
    } else { // create
      props.create(payload, (success) => {
        if (success) {
          message.success(getI18n().t('feedback_form_success'));
        } else {
          message.error(getI18n().t('feedback_form_error'));
        }
      });      
    }
  }

  // form submit handler
  const handleFinish = async (values) => {
    Logger.log('debug', `CityForm.handleFinish(###)`);
    if (!props.isSubmitting) {
      await submitData(values);
    }
  }

  // form error handler
  const handleFinishFailed = ({values, errorFields, outOfDate}) => {
    Logger.log('debug', `CityForm.handleFinishFailed(###)`);
    message.error(getI18n().t('feedback_form_error'));
    if (errorFields && errorFields.length > 0) {
      form.scrollToField(errorFields[0].name);
    }
  }

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <Translation>{(t) => 
      <>
        <div className="city-form">
          <Form
            name="city_form"
            form={form}
            initialValues={data}
            onFinish={handleFinish}
            onFinishFailed={handleFinishFailed}
            validateTrigger="onSubmit"
            {...layout.main}
          >
            <Row gutter={16}>

              <Col xs={24} lg={18}>
                <Card
                  title={id ? t('city_edit_title') : t('city_add_title')}
                  extra={isLoading
                    ? <Spin indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} />
                    : null}
                >
                    

                <div className="form-group">
                    <Form.Item
                      name="name"
                      label={t('city_name')}
                      rules={[
                        {required: true, message: t('feedback_validation_required')},
                        {type: 'string', min: 2, max: 40, message: t('feedback_validation_char_range', {min: 2, max: 40})},
                        {pattern: /^\w+$/, message: t('feedback_validation_alphanumeric')},
                        {pattern: /(?!^\d+$)^.+$/, message: t('feedback_validation_not_number')},
                      ]}
                    >
                      <Input autoFocus disabled={isLoading || isSubmitting} />
                    </Form.Item>
                </div>
                <CountryInput
                    name="country"
                    label={t('resources_country')}
                    disabled={isLoading || isSubmitting}
                    onChange={value => {
                      setCountryId(value)
                    }}
                  />

                  <RegionInput
                    name="region"
                    label={t('resources_region')}
                    disabled={isLoading || isSubmitting}
                    country_id={countryId}
                    onChange={value => {
                      setRegionId(value)
                    }}
                  />

                  <SubRegionInput
                    name="subregion"
                    label={t('resources_subregion')}
                    disabled={isLoading || isSubmitting}
                    region_id={regionId}
                  />
                </Card>
                
              </Col>

              <Col xs={24} lg={6}>
                <Affix offsetTop={10}>
                  <Card title={t('form_metadata_header')}>
                    <FormMetadata
                      id={id}
                      isSubmitting={isSubmitting}
                      delete={props.delete.bind(this)}
                      deleteRedirectTo="CityScreen"
                      createdAt={props.createdAt}
                      updatedAt={props.updatedAt}
                    />
                  </Card>
                </Affix>
              </Col>

            </Row>
          </Form>
        </div>
      </>
    }</Translation>
  );
}

export default CityForm;

Logger.log('silly', `CityForm loaded.`);
