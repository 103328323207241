import {connect} from 'react-redux';

import MessageAddScreen from '../components/MessageAddScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.messages.get('isLoading'),
    success: state.messages.getIn(['form', 'success']),
  }
}

const MessageAddScreenContainer = connect(
  mapStateToProps
)(MessageAddScreen);

export default MessageAddScreenContainer;
