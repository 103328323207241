import React, {useState} from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default function CareGuideContentEditor({onChange, defaultValue='', ...props}) {
  const [value, setValue] = useState(defaultValue);

  return (
    <ReactQuill
      theme="snow"
      value={value}
      onChange={(content, delta, source, editor) => {
        setValue(content);
        onChange(content, delta, source, editor);
      }}
    />
  );
}
