import { connect } from 'react-redux';

import CareGuideInvitationsCSVButton from '../components/CareGuideInvitationsCSVButton';

const mapStateToProps = (state) => {
  return {};
};

const CareGuideInvitationsCSVButtonContainer = connect(mapStateToProps)(
  CareGuideInvitationsCSVButton
);

export default CareGuideInvitationsCSVButtonContainer;
