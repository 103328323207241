import {getI18n} from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import CareGuideOwnersScreen from './containers/CareGuideOwnersScreenContainer';
import CareGuideOwnerAddScreen from './containers/CareGuideOwnerAddScreenContainer';
import CareGuideOwnerEditScreen from './containers/CareGuideOwnerEditScreenContainer';

const register = () => {
  Logger.log('debug', `careGuideOwners.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'careGuideOwners', 'routes'])) {
    Events.dispatch('ADD_MAIN_ROUTES', 
      {
        'CareGuideOwnerAddScreen': ['PrivateRoute', '/care-guide-owners/add', true, CareGuideOwnerAddScreen, getI18n().t('care_guide_owner_route_add')],
        'CareGuideOwnerEditScreen': ['PrivateRoute', '/care-guide-owners/edit/:id([\\w\\-]+)', true, CareGuideOwnerEditScreen, getI18n().t('care_guide_owner_route_edit')],
        'CareGuideOwnersScreen': ['PrivateRoute', '/care-guide-owners/:page(\\d+)?', true, CareGuideOwnersScreen, getI18n().t('care_guide_owners_route_list')],
      }
    );
  }
}

export default register;

Logger.log('silly', `careGuideOwners.register() loaded.`);
