import {connect} from 'react-redux';

import {downloadResourceServiceTagsCSV} from '../../../../../state/modules/resourceServiceTags/actions';
import ResourceServiceTagsCSVButton from '../components/ResourceServiceTagsCSVButton';

const mapStateToProps = state => {
  return {
    isDownloading: state.resourceServiceTags.get('isCSVDownloading'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    download: (order, filter, cb) => {
      dispatch(downloadResourceServiceTagsCSV(order, filter, cb));
    }
  }
}

const ResourceServiceTagsCSVButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResourceServiceTagsCSVButton);

export default ResourceServiceTagsCSVButtonContainer;
