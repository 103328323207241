import { connect } from 'react-redux';
import { List } from 'immutable';

import {
  loadAdminPartnerClass,
  updateAdminPartnerClass,
  createAdminPartnerClass,
  deleteAdminPartnerClass,
  adminPartnerClassFormDestroy,
} from '../../../../../state/modules/adminPartnerClasses/actions';
import { sendMessage } from '../../../../../state/actions';
import AdminPartnerClassForm from '../components/AdminPartnerClassForm';

const inputs = List([
  'name',
  'status',
  'order',
  'partnership',
  'use_tag_yes_or_no',
]);

const mapStateToProps = (state, ownProps) => {
  const data = {},
    errors = {};
  for (const val of inputs.values()) {
    data[val] = state.entities.getIn(
      ['adminPartnerClasses', ownProps.id, val],
      ''
    );
    errors[val] = state.adminPartnerClasses.getIn(['form', 'errors', val]);
  }

  // change select number values to strings to work with ant design select inputs
  Object.keys(data).forEach((x) => {
    if (['status'].includes(x)) {
      data[x] = data[x].toString();
    }
  });

  return {
    isLoading: state.adminPartnerClasses.get('isLoading'),
    isSubmitting: state.adminPartnerClasses.getIn(['form', 'isSubmitting']),
    success: state.adminPartnerClasses.getIn(['form', 'success']),
    created_id: state.adminPartnerClasses.getIn(['form', 'created_id']),
    data: data,
    errors: errors,
    createdAt: state.entities.getIn(
      ['adminPartnerClasses', ownProps.id, 'created_at'],
      null
    ),
    updatedAt: state.entities.getIn(
      ['adminPartnerClasses', ownProps.id, 'updated_at'],
      null
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (id, cb) => {
      dispatch(loadAdminPartnerClass(id, cb));
    },
    update: (id, data, cb) => {
      dispatch(updateAdminPartnerClass(id, data, cb));
    },
    create: (data, cb) => {
      dispatch(createAdminPartnerClass(data, cb));
    },
    delete: (id, cb) => {
      dispatch(deleteAdminPartnerClass(id, cb));
    },
    destroyForm: (formState) => {
      dispatch(adminPartnerClassFormDestroy(formState));
    },
    sendMessage: (level, title, body, expires) => {
      dispatch(sendMessage(level, title, body, expires));
    },
  };
};

const AdminPartnerClassFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminPartnerClassForm);

export default AdminPartnerClassFormContainer;
