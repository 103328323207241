import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Translation, getI18n } from 'react-i18next';
import { Affix, Card, Col, DatePicker, Form, Row, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';

import BodyInput from './BodyInput';
import PublicStatusInput from './PublicStatusInput';
import UserCareGuideInput from '../../users/containers/UserCareGuideInputContainer';
import JournalPostInput from '../../journalPosts/containers/JournalPostInputContainer';
import message from '../../../elements/lib/MessageWrapper';
import FormMetadata from '../../../elements/components/FormMetadata';
import { pathTo } from '../../../Routes';
import Format from '../../../../../lib/Format';
import Logger from '../../../../../lib/Logger';
import Config from '../../../../../Config';
import { schema } from '../../../../../state/schema';
import store from '../../../../../state/store';
import { denormalize } from 'normalizr';

const JournalReplyForm = ({
  id,
  data,
  errors,
  load,
  destroyForm,
  isLoading,
  isSubmitting,
  created_id,
  ...props
}) => {
  const [redirectTo, setRedirectTo] = useState(null);
  const [careGuideId, setCareGuideId] = useState(null);
  const [form] = Form.useForm();
  const state = store.getState();

  // form column settings
  const layout = {
    main: {
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
    },
  };

  // load record data from API
  useEffect(() => {
    if (id) {
      load(id);
    }
  }, [id, load]);

  // update input values when new data is available
  const dataString = JSON.stringify(data);
  useEffect(() => {
    if (id && !isSubmitting) {
      const dataObj = JSON.parse(dataString);
      dataObj['published_at'] = dataObj['published_at']
        ? moment(dataObj['published_at'], Config.get('API_DATETIME_FORMAT'))
        : moment();
      dataObj['edited_at'] = dataObj['edited_at']
        ? moment(dataObj['edited_at'], Config.get('API_DATETIME_FORMAT'))
        : null;
      /*       setCareGuideId(dataObj['care_guide']);
       */
      form.setFieldsValue(dataObj);
    }
  }, [form, dataString, isSubmitting, id]);

  // handle errors reported by API
  useEffect(() => {
    let firstFieldName = '';
    for (const field in errors) {
      form.setFields([{ name: field, errors: errors[field] }]);
      if (firstFieldName === '') {
        firstFieldName = field;
      }
    }
    form.scrollToField(firstFieldName);
  }, [form, errors]);

  // redirect add form to edit form on successful create action
  useEffect(() => {
    if (created_id) {
      setRedirectTo(pathTo('JournalReplyEditScreen', { id: created_id }));
    }
    return () => {
      destroyForm();
    };
  }, [created_id, setRedirectTo, destroyForm]);

  // submit data handler
  const submitData = async (values) => {
    Logger.log('debug', `JournalReplyForm.submitData(###)`);

    // API POST/PUT payload
    let payload = {};
    for (const input of Object.keys(data)) {
      if (input in values) {
        // transform profile refs to UUIDs
        if (['profile'].includes(input)) {
          payload['user_profile_id'] = values[input];
        } else if (['published_at', 'edited_at'].includes(input)) {
          payload[input] = Format.date(
            values[input],
            Config.get('API_DATETIME_FORMAT')
          );
        } else {
          payload[input] = values[input];
        }
      }
    }

    if (id) {
      // update
      props.update(id, payload, (success) => {
        if (success) {
          message.success(getI18n().t('feedback_form_success'));
        } else {
          message.error(getI18n().t('feedback_form_error'));
        }
      });
    } else {
      // create
      props.create(payload, (success) => {
        if (success) {
          message.success(getI18n().t('feedback_form_success'));
        } else {
          message.error(getI18n().t('feedback_form_error'));
        }
      });
    }
  };

  // form submit handler
  const handleFinish = async (values) => {
    Logger.log('debug', `JournalReplyForm.handleFinish(###)`);
    if (!props.isSubmitting) {
      await submitData(values);
    }
  };

  // form error handler
  const handleFinishFailed = ({ values, errorFields, outOfDate }) => {
    Logger.log('debug', `JournalReplyForm.handleFinishFailed(###)`);
    message.error(getI18n().t('feedback_form_error'));
    if (errorFields && errorFields.length > 0) {
      form.scrollToField(errorFields[0].name);
    }
  };

  const onChangeJournalPost = (e) => {
    const journalPost = denormalize(
      state.entities.getIn(['journalPosts', e]),
      schema.journalPost,
      state.entities.toJS()
    );
    setCareGuideId(journalPost?.care_guide?.id);
    form.setFieldsValue({ profile: null });
  };

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <Translation>
      {(t) => (
        <>
          <div className="journal-reply-form">
            <Form
              name="journal_reply_form"
              form={form}
              initialValues={data}
              onFinish={handleFinish}
              onFinishFailed={handleFinishFailed}
              validateTrigger="onSubmit"
              {...layout.main}
            >
              <Row gutter={16}>
                <Col xs={24} lg={18}>
                  <Card
                    title={
                      id
                        ? t('journal_reply_edit_title')
                        : t('journal_reply_add_title')
                    }
                    extra={
                      isLoading ? (
                        <Spin
                          indicator={
                            <LoadingOutlined style={{ fontSize: 20 }} spin />
                          }
                        />
                      ) : null
                    }
                  >
                    <JournalPostInput
                      name="journal_post_id"
                      label={t('journal_reply_journal_post_id')}
                      rules={[
                        {
                          required: true,
                          message: t('feedback_validation_required'),
                        },
                      ]}
                      onChange={onChangeJournalPost}
                      form={form}
                      disabled={isLoading || isSubmitting}
                    />

                    <UserCareGuideInput
                      careGuideId={careGuideId}
                      name="profile"
                      label={t('journal_post_profile')}
                      rules={[
                        {
                          required: true,
                          message: t('feedback_validation_required'),
                        },
                      ]}
                      form={form}
                      disabled={isLoading || isSubmitting}
                    />

                    <div className="form-group">
                      <Form.Item
                        name="published_at"
                        label={t('journal_reply_published_at')}
                        rules={[
                          {
                            required: true,
                            message: t('feedback_validation_required'),
                          },
                        ]}
                      >
                        <DatePicker
                          showTime
                          disabled={isLoading || isSubmitting}
                          format={Config.get('DEFAULT_DATETIME_FORMAT')}
                        />
                      </Form.Item>
                    </div>

                    <div className="form-group">
                      <Form.Item
                        name="edited_at"
                        label={t('journal_reply_edited_at')}
                        rules={null}
                      >
                        <DatePicker
                          showTime
                          disabled={isLoading || isSubmitting}
                          format={Config.get('DEFAULT_DATETIME_FORMAT')}
                        />
                      </Form.Item>
                    </div>

                    <BodyInput
                      name="body"
                      label={t('journal_reply_body')}
                      form={form}
                      defaultValue={form.getFieldValue(['body'])}
                      disabled={isLoading || isSubmitting}
                    />

                    <PublicStatusInput
                      name="public_status"
                      label={t('journal_reply_public_status')}
                      disabled={isLoading || isSubmitting}
                    />
                  </Card>
                </Col>

                <Col xs={24} lg={6}>
                  <Affix offsetTop={10}>
                    <Card title={t('form_metadata_header')}>
                      <FormMetadata
                        id={id}
                        isSubmitting={isSubmitting}
                        delete={props.delete.bind(this)}
                        deleteRedirectTo="JournalRepliesScreen"
                        createdAt={props.createdAt}
                        updatedAt={props.updatedAt}
                      />
                    </Card>
                  </Affix>
                </Col>
              </Row>
            </Form>
          </div>
        </>
      )}
    </Translation>
  );
};

export default JournalReplyForm;

Logger.log('silly', `JournalReplyForm loaded.`);
