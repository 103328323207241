import {getI18n} from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import RelationshipsScreen from './containers/RelationshipsScreenContainer';
import RelationshipAddScreen from './containers/RelationshipAddScreenContainer';
import RelationshipEditScreen from './containers/RelationshipEditScreenContainer';

const register = () => {
  Logger.log('debug', `relationships.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'relationships', 'routes'])) {
    Events.dispatch('ADD_MAIN_ROUTES', 
      {
        'RelationshipAddScreen': ['PrivateRoute', '/relationships/add', true, RelationshipAddScreen, getI18n().t('relationship_route_add')],
        'RelationshipEditScreen': ['PrivateRoute', '/relationships/edit/:id(\\d+)', true, RelationshipEditScreen, getI18n().t('relationship_route_edit')],
        'RelationshipsScreen': ['PrivateRoute', '/relationships/:page(\\d+)?', true, RelationshipsScreen, getI18n().t('relationships_route_list')],
      }
    );
  }
}

export default register;

Logger.log('silly', `relationships.register() loaded.`);
