import {connect} from 'react-redux';
import {denormalize} from 'normalizr';

import {schema} from '../../../../../../state/schema';
import {loadPostalCodes, deletePostalCode} from '../../../../../../state/modules/locationPostalCodes/actions';
import {sendMessage} from '../../../../../../state/actions';
import PostalCodesList from '../components/PostalCodesList';

const mapStateToProps = (state, ownProps) => {

  const page = ownProps.page;
  const limit = ownProps.limit;
  const order = ownProps.order;
  const result = state.locationPostalCodes.getIn(['pages', order, limit, page]);
  return {
    list: result 
      ? result.map(x => {
          return {
            key: x,
            ...denormalize(
              state.entities.getIn(['postalCodes', x]),
              schema.postalCode,
              state.entities.toJS()
            )
          };
        }).toArray().map(x => {
          // flatten some nested objects to make table easier to work with
          return {
            region_name: x.region ? x.region.name: '',
            subregion_name: x.subregion ? x.subregion.name: '',
            city_name: x.city ? x.city.name: '',
            ...x
          };
        })
      : [],
    isLoading: state.locationPostalCodes.get('arePostalCodesLoading'),
    total: state.locationPostalCodes.get('total'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (page, limit, order, filter, cb) => {
      dispatch(loadPostalCodes(page,limit, order, filter, cb));
    },
    remove: (id, cb) => {
      dispatch(deletePostalCode(id, cb));
    },
    sendMessage: (level, title, body, expires) => {
      dispatch(sendMessage(level, title, body, expires));
    },
  }
}

const PostalCodeListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PostalCodesList);

export default PostalCodeListContainer;
