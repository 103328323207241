import { Map, List } from 'immutable';

import {
  PARTNERSHIP_FORM_ANSWER_LIST_REQUEST,
  PARTNERSHIP_FORM_ANSWER_LIST_SUCCESS,
  PARTNERSHIP_FORM_ANSWER_LIST_FAILURE,
  PARTNERSHIP_FORM_ANSWER_READ_REQUEST,
  PARTNERSHIP_FORM_ANSWER_READ_SUCCESS,
  PARTNERSHIP_FORM_ANSWER_READ_FAILURE,
  PARTNERSHIP_FORM_ANSWER_CSV_DOWNLOAD_REQUEST,
  PARTNERSHIP_FORM_ANSWER_CSV_DOWNLOAD_SUCCESS,
  PARTNERSHIP_FORM_ANSWER_CSV_DOWNLOAD_FAILURE,
  PARTNERSHIP_FORM_ANSWER_DELETE_REQUEST,
  PARTNERSHIP_FORM_ANSWER_DELETE_SUCCESS,
  PARTNERSHIP_FORM_ANSWER_DELETE_FAILURE,
} from './actions';
import Logger from '../../../lib/Logger';

export default function partnershipFormAnswers(
  state = Map({
    isLoading: false,
    isCSVDownloading: false,
    pages: {},
  }),
  action
) {
  Logger.log(
    'debug',
    `[partnershipFormAnswers.reducers] partnershipFormAnswers(%j, %j)`,
    state,
    action
  );

  switch (action.type) {
    case PARTNERSHIP_FORM_ANSWER_LIST_REQUEST:
      return state.mergeDeep({
        isLoading: true,
      });

    case PARTNERSHIP_FORM_ANSWER_LIST_SUCCESS:
      return state
        .mergeDeep({
          isLoading: false,
          pages: {
            [action.order]: {
              [action.limit]: {
                [action.page]: null,
              },
            },
          },
          total: action.total,
          lastUpdated: action.receivedAt,
        })
        .setIn(
          ['pages', action.order, action.limit, action.page],
          List(action.result)
        );

    case PARTNERSHIP_FORM_ANSWER_LIST_FAILURE:
      return state.mergeDeep({
        isLoading: false,
      });

    case PARTNERSHIP_FORM_ANSWER_READ_REQUEST:
      return state.mergeDeep({
        isLoading: true,
      });

    case PARTNERSHIP_FORM_ANSWER_READ_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        lastUpdated: action.receivedAt,
      });

    case PARTNERSHIP_FORM_ANSWER_READ_FAILURE:
      return state.mergeDeep({
        isLoading: false,
      });

    case PARTNERSHIP_FORM_ANSWER_DELETE_REQUEST:
      return state.mergeDeep({
        isLoading: true,
      });

    case PARTNERSHIP_FORM_ANSWER_DELETE_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        lastUpdated: action.receivedAt,
      });

    case PARTNERSHIP_FORM_ANSWER_DELETE_FAILURE:
      return state.mergeDeep({
        isLoading: false,
      });

    case PARTNERSHIP_FORM_ANSWER_CSV_DOWNLOAD_REQUEST:
      return state.mergeDeep({
        isCSVDownloading: true,
      });

    case PARTNERSHIP_FORM_ANSWER_CSV_DOWNLOAD_SUCCESS:
      return state.mergeDeep({
        isCSVDownloading: false,
      });

    case PARTNERSHIP_FORM_ANSWER_CSV_DOWNLOAD_FAILURE:
      return state.mergeDeep({
        isCSVDownloading: false,
      });

    default:
      return state;
  }
}

Logger.log('silly', `partnershipFormAnswers.reducers loaded.`);
