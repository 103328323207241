import { connect } from 'react-redux';
import { denormalize } from 'normalizr';

import { schema } from '../../../../../state/schema';
import {
  loadCareGuideNotes,
  deleteCareGuideNote,
} from '../../../../../state/modules/careGuideNotes/actions';
import { sendMessage } from '../../../../../state/actions';
import CareGuideNotesList from '../components/CareGuideNotesList';

const mapStateToProps = (state, ownProps) => {
  const page = ownProps.page;
  const limit = ownProps.limit;
  const order = ownProps.order;
  const result = state.careGuideNotes.getIn(['pages', order, limit, page]);

  return {
    list: result
      ? result
          .map((x) => {
            return {
              key: x,
              ...denormalize(
                state.entities.getIn(['careGuideNotes', x]),
                schema.careGuideNote,
                state.entities.toJS()
              ),
            };
          })
          .toArray()
          .map((x) => {
            return {
              partnership_name: x?.care_guide?.partnership?.name ?? '',
              partnership_plan: x?.care_guide?.partnership?.plan?.name ?? '',
              care_guide_name:
                x?.care_guide?.first_name && x?.care_guide?.last_name
                  ? `${x?.care_guide?.first_name} ${x?.care_guide?.last_name}`
                  : '',
              profile_username: x.profile?.user?.username || '',
              ...x,
            };
          })
      : [],
    isLoading: state.careGuideNotes.get('isLoading'),
    total: state.careGuideNotes.get('total'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (page, limit, order, filter, cb) => {
      dispatch(loadCareGuideNotes(page, limit, order, filter, cb));
    },
    remove: (id, cb) => {
      dispatch(deleteCareGuideNote(id, cb));
    },
    sendMessage: (level, title, body, expires) => {
      dispatch(sendMessage(level, title, body, expires));
    },
  };
};

const CareGuideNotesListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CareGuideNotesList);

export default CareGuideNotesListContainer;
