import React from 'react';
import {Translation} from 'react-i18next';
import {Form, Select} from 'antd';

const FieldInput = ({name, onChange, disabled=false, ...props}) => {

  const options = [
    {
      value: 1,
      label: 'care_guide_section_fields_input_type_text',
    },
    {
      value: 2,
      label: 'care_guide_section_fields_input_type_text_area',
    },
    {
      value: 3,
      label: 'care_guide_section_fields_input_type_wysiwyg',
    },
    {
      value: 4,
      label: 'care_guide_section_fields_input_type_table',
    },
    {
      value: 5,
      label: 'care_guide_section_fields_input_type_adl_table',
    },
    {
      value: 6,
      label: 'care_guide_section_fields_input_type_med_table',
    },
  ];

  return (
    <Translation>{(t) => 
      <div className="form-group">
        <Form.Item
          name={name}
          style={{width: '200px'}}
          rules={[
            {required: true, message: t('feedback_validation_required')},
          ]}
          {...props}
        >
          <Select disabled={disabled} onChange={onChange}>
            {options.map(x => <Select.Option key={x.value} value={x.value}>{t(x.label)}</Select.Option>)}
          </Select>
        </Form.Item>
      </div>
    }</Translation>
  );
}

export default FieldInput;
