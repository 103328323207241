import { connect } from 'react-redux';
import { denormalize } from 'normalizr';

import { schema } from '../../../../../state/schema';
import { loadRegistration } from '../../../../../state/modules/registration/actions';
import RegistrationViewScreen from '../components/RegistrationViewScreen';

const mapStateToProps = (state, ownProps) => {
  const registration = ownProps.match.params.id
    ? denormalize(
        state.entities.getIn(['registrations', ownProps.match.params.id]),
        schema.registration,
        state.entities.toJS()
      )
    : null;

  return {
    isLoading: state.registration.get('isLoading'),
    registration: registration,
    id: ownProps.match.params.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (id, cb) => {
      dispatch(loadRegistration(id, cb));
    },
  };
};

const RegistrationViewScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RegistrationViewScreen);

export default RegistrationViewScreenContainer;
