import { connect } from 'react-redux';

import { loadUsersCareGuidePartnership } from '../../../../../state/modules/users/actions';
import UserCareGuidePartnershipInput from '../components/UserCareGuidePartnershipInput';

const mapStateToProps = (state) => {
  return {
    total: state.users.get('total'),
    isLoading: state.users.get('isLoading'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (careGuideId, page, limit, order, filter, cb) => {
      dispatch(loadUsersCareGuidePartnership(careGuideId, page, limit, order, filter, cb));
    },
  };
};

const UserCareGuidePartnershipInputContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserCareGuidePartnershipInput);

export default UserCareGuidePartnershipInputContainer;
