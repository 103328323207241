import { connect } from 'react-redux';

import ResourceNotesCSVButton from '../components/ResourceNotesCSVButton';

const mapStateToProps = (state) => {
  return {};
};

const ResourceNotesCSVButtonContainer = connect(mapStateToProps)(
  ResourceNotesCSVButton
);

export default ResourceNotesCSVButtonContainer;
