import { connect } from 'react-redux';
import { denormalize } from 'normalizr';

import { schema } from '../../../../../state/schema';
import {
  loadCareGuides,
  deleteCareGuide,
} from '../../../../../state/modules/careGuides/actions';
import { sendMessage } from '../../../../../state/actions';
import CareGuidesList from '../components/CareGuidesList';

const mapStateToProps = (state, ownProps) => {
  const page = ownProps.page;
  const limit = ownProps.limit;
  const order = ownProps.order;
  const result = state.careGuides.getIn(['pages', order, limit, page]);

  return {
    list: result
      ? result
          .map((x) => {
            return {
              key: x,
              ...denormalize(
                state.entities.getIn(['careGuides', x]),
                schema.careGuide,
                state.entities.toJS()
              ),
            };
          })
          .toArray()
          .map((x) => {
            return {
              partnership_name: x?.partnership?.name ?? '',
              partnership_plan: x?.partnership?.plan?.name ?? '',
              owner_username: x?.account.owner?.user?.username ?? '',
              ...x,
            };
          })
      : [],
    isLoading: state.careGuides.get('isLoading'),
    total: state.careGuides.get('total'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (page, limit, order, filter, cb) => {
      dispatch(loadCareGuides(page, limit, order, filter, cb));
    },
    remove: (id, cb) => {
      dispatch(deleteCareGuide(id, cb));
    },
    sendMessage: (level, title, body, expires) => {
      dispatch(sendMessage(level, title, body, expires));
    },
  };
};

const CareGuidesListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CareGuidesList);

export default CareGuidesListContainer;
