import { connect } from 'react-redux';
import { denormalize } from 'normalizr';

import { schema } from '../../../../../state/schema';
import {
  loadMessages,
  deleteMessage,
} from '../../../../../state/modules/messages/actions';
import { sendMessage } from '../../../../../state/actions';
import MessagesList from '../components/MessagesList';

const mapStateToProps = (state, ownProps) => {
  const page = ownProps.page;
  const limit = ownProps.limit;
  const order = ownProps.order;
  const result = state.messages.getIn(['pages', order, limit, page]);

  return {
    list: result
      ? result
          .map((x) => {
            return {
              key: x,
              ...denormalize(
                state.entities.getIn(['messages', x]),
                schema.message,
                state.entities.toJS()
              ),
            };
          })
          .toArray()
          .map((x) => {
            // flatten some nested objects to make table easier to work with
            return {
              care_guide_name:
              x?.care_guide?.first_name && x?.care_guide?.last_name
                ? `${x?.care_guide?.first_name} ${x?.care_guide?.last_name}` : '',
              partnership_name: x?.care_guide?.partnership?.name ?? '',
              partnership_plan: x?.care_guide?.partnership?.plan?.name ?? '',
              care_guide_id: x.care_guide ? x.care_guide.id : '',
              profile_id: x.profile ? x.profile.id : '',
              profile_username:
                x.profile && x.profile.user ? x.profile.user.username : '',
              ...x,
            };
          })
      : [],
    isLoading: state.messages.get('isLoading'),
    total: state.messages.get('total'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (page, limit, order, filter, cb) => {
      dispatch(loadMessages(page, limit, order, filter, cb));
    },
    remove: (id, cb) => {
      dispatch(deleteMessage(id, cb));
    },
    sendMessage: (level, title, body, expires) => {
      dispatch(sendMessage(level, title, body, expires));
    },
  };
};

const MessagesListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MessagesList);

export default MessagesListContainer;
