import {connect} from 'react-redux';

import RelationshipEditScreen from '../components/RelationshipEditScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.relationships.get('isLoading'),
    success: state.relationships.getIn(['form', 'success']),
  }
}

const RelationshipEditScreenContainer = connect(
  mapStateToProps
)(RelationshipEditScreen);

export default RelationshipEditScreenContainer;
