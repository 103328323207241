import {Map} from 'immutable';

import {
    TIMEZONE_LIST_REQUEST,
    TIMEZONE_LIST_SUCCESS,
    TIMEZONE_LIST_FAILURE
} from './actions'
import Logger from '../../../lib/Logger';

export default function timezones(
  state=Map({
    isLoading: false,
    timezones: {}
  }),
  action
) {
  Logger.log('debug', `[timezones.reducers] timezones(%j, %j)`, state, action);

  switch(action.type) {

    case TIMEZONE_LIST_REQUEST:
      return state.mergeDeep({
        isLoading: true
      });

    case TIMEZONE_LIST_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        timezones: null,
        lastUpdated: action.receivedAt
      }).setIn(['timezones'], action.result);

    case TIMEZONE_LIST_FAILURE:
      return state.mergeDeep({
        isLoading: false
      });

    default:
      return state;
  }
}

Logger.log('silly', `timezones.reducers loaded.`);
