import api from '../../api';
import Logger from '../../../lib/Logger';

// ACTION TYPES

export const TIMEZONE_LIST_REQUEST = 'TIMEZONE_LIST_REQUEST';
export const TIMEZONE_LIST_SUCCESS = 'TIMEZONE_LIST_SUCCESS';
export const TIMEZONE_LIST_FAILURE = 'TIMEZONE_LIST_FAILURE';

// ACTION CREATORS

export function timezoneListRequest() {
  Logger.log('debug', `[timezones.actions] timezoneListRequest()`);
  return {
    type: TIMEZONE_LIST_REQUEST,
  }
}

export function timezoneListSuccess(data) {
  Logger.log('debug', `[timezones.actions] timezoneListSuccess(%j)`, data);
  return {
    type: TIMEZONE_LIST_SUCCESS,
    result: data.result,
    receivedAt: Date.now()
  }
}

export function timezoneListFailure(error) {
  Logger.log('debug', `[timezones.actions] timezoneListFailure(%j)`, error);
  return {
    type: TIMEZONE_LIST_FAILURE,
    error: error
  }
}


// API THUNK ACTION CREATORS

export function loadTimezones(cb=function(){}) {
  Logger.log('debug', `[timezones.actions] loadTimezones()`);

  return async function(dispatch) {
    dispatch(timezoneListRequest());

    // call API
    const response = await api.getTimezones();
    let success = false;

    // get timezones list success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API timezones list success.`);

      const data = {
        result: response.getIn(['data'])
      };

      dispatch(timezoneListSuccess(data));
      success = true;
      
    // get timezones list failure
    } else {
      Logger.log('info', `Get API timezones list failure.`);
      dispatch(timezoneListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

Logger.log('silly', `timezones.actions loaded.`);
