import { connect } from 'react-redux';

import DashboardSectionAddScreen from '../components/DashboardSectionAddScreen';

const mapStateToProps = (state) => {
  return {
    isLoading: state.dashboardSections.get('isLoading'),
    success: state.dashboardSections.getIn(['form', 'success']),
  };
};

const DashboardSectionAddScreenContainer = connect(mapStateToProps)(
  DashboardSectionAddScreen
);

export default DashboardSectionAddScreenContainer;
