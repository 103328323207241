import React from 'react';
import {Translation} from 'react-i18next';
import {Tag} from 'antd';


const ProgressTag = ({progress, ...props}) => {

  const labels = {
    1: "imports_jobs_progress_queued",
    2: "imports_jobs_progress_processing",
    3: "imports_jobs_progress_complete",
    4: "imports_jobs_progress_failed",
  };

  const colors = {
    1: "default",
    2: "processing",
    3: "success",
    4: "error",
  };

  return (
    <Translation>{(t) =>
        <Tag color={colors[progress]}>{t(labels[progress])}</Tag>
    }</Translation>
  )
}

export default ProgressTag;
