import {connect} from 'react-redux';

import SubRegionEditScreen from '../components/SubRegionEditScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.locationRegions.get('areSubRegionsLoading'),
    success: state.locationRegions.getIn(['form', 'success']),
  }
}

const SubRegionEditScreenContainer = connect(
  mapStateToProps
)(SubRegionEditScreen);

export default SubRegionEditScreenContainer;
