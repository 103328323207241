import {connect} from 'react-redux';

import CareGuideHelperTagAddScreen from '../components/CareGuideHelperTagAddScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.careGuideHelperTags.get('isLoading'),
    success: state.careGuideHelperTags.getIn(['form', 'success']),
  }
}

const CareGuideHelperTagAddScreenContainer = connect(
  mapStateToProps
)(CareGuideHelperTagAddScreen);

export default CareGuideHelperTagAddScreenContainer;
