import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import { Translation, getI18n } from 'react-i18next';
import { Table } from 'antd';

import ListActions from '../../../elements/components/ListActions';
import {
  getColumnSearchProps,
  getColumnDateRangeProps,
} from '../../../elements/components/TableColumnFilters';
import { BooleanTag, StatusTag } from '../../../elements/components/Tags';
import { pathTo } from '../../../Routes';
import Format from '../../../../../lib/Format';
import QueryString from '../../../../../lib/QueryString';
import Logger from '../../../../../lib/Logger';

const ResourcesList = ({
  component,
  page,
  limit,
  order,
  filter,
  total,
  load,
  remove,
  history,
  ...props
}) => {
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters, dataIndex) => {
    clearFilters();
    delete filter[dataIndex];
    history.push(
      QueryString.append(props.location.pathname, { order, ...filter })
    );
  };

  const columns = [
    {
      title: getI18n().t('table_header_id'),
      dataIndex: 'id',
      key: 'id',
      defaultSortOrder:
        order === 'id.asc' ? 'ascend' : order === 'id.desc' ? 'descend' : null,
      sorter: true,
      ...getColumnSearchProps(
        'id',
        handleSearch,
        handleReset,
        getI18n().t('table_header_id'),
        filter
      ),
    },
    {
      title: getI18n().t('resources_owner_name'),
      dataIndex: 'owner_name',
      key: 'owner_name',
      ...getColumnSearchProps(
        'owner_name',
        handleSearch,
        handleReset,
        getI18n().t('resources_owner_name'),
        filter
      ),
    },
    {
      title: getI18n().t('resources_care_guide_name'),
      dataIndex: 'care_guide_name',
      key: 'care_guide_name',
      ...getColumnSearchProps(
        'care_guide_name',
        handleSearch,
        handleReset,
        getI18n().t('resources_care_guide_name'),
        filter
      ),
    },
    {
      title: getI18n().t('resources_name'),
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps(
        'name',
        handleSearch,
        handleReset,
        getI18n().t('resources_name'),
        filter
      ),
    },
    {
      title: getI18n().t('resources_partnership_name'),
      dataIndex: 'partnership_name',
      key: 'partnership',
      ...getColumnSearchProps(
        'partnership',
        handleSearch,
        handleReset,
        getI18n().t('resources_partnership_name'),
        filter
      ),
    },
    {
      title: getI18n().t('resources_partnership_plan'),
      dataIndex: 'partnership_plan',
      key: 'plan',
      ...getColumnSearchProps(
        'plan',
        handleSearch,
        handleReset,
        getI18n().t('resources_partnership_plan'),
        filter
      ),
    },
    {
      title: getI18n().t('resources_created_by'),
      dataIndex: 'profile_name',
      key: 'profile_name',
    },
    {
      title: getI18n().t('resources_is_universal'),
      dataIndex: 'is_universal',
      key: 'is_universal',
      filters: [
        { text: getI18n().t('boolean_true'), value: 1 },
        { text: getI18n().t('boolean_false'), value: 0 },
      ],
      filteredValue:
        'is_universal' in filter ? filter['is_universal'].split(',') : null,
      render: (val) => <BooleanTag value={val} />,
    },
    {
      title: getI18n().t('resources_is_pinned'),
      dataIndex: 'is_pinned',
      key: 'is_pinned',
      filters: [
        { text: getI18n().t('boolean_true'), value: 1 },
        { text: getI18n().t('boolean_false'), value: 0 },
      ],
      filteredValue:
        'is_pinned' in filter ? filter['is_pinned'].split(',') : null,
      render: (val) => <BooleanTag value={val} />,
    },
    {
      title: getI18n().t('resources_is_pinned_dashboard'),
      dataIndex: 'is_pinned_dashboard',
      key: 'is_pinned_dashboard',
      filters: [
        { text: getI18n().t('boolean_true'), value: 1 },
        { text: getI18n().t('boolean_false'), value: 0 },
      ],
      filteredValue:
        'is_pinned_dashboard' in filter
          ? filter['is_pinned_dashboard'].split(',')
          : null,
      render: (val) => <BooleanTag value={val} />,
    },
    {
      title: getI18n().t('table_header_status'),
      dataIndex: 'status',
      key: 'status',
      filters: [
        { text: getI18n().t('status_enabled'), value: 1 },
        { text: getI18n().t('status_disabled'), value: 2 },
        { text: getI18n().t('status_archived'), value: 3 },
        { text: getI18n().t('status_deleted'), value: 4 },
        { text: getI18n().t('status_pending'), value: 5 },
      ],
      filteredValue: 'status' in filter ? filter['status'].split(',') : null,
      render: (code) => <StatusTag status={code} />,
    },
    {
      title: getI18n().t('table_header_updated_at'),
      dataIndex: 'updated_at',
      key: 'updated_at',
      render: (date) => Format.date(date),
      defaultSortOrder:
        order === 'updated_at.asc'
          ? 'ascend'
          : order === 'updated_at.desc'
          ? 'descend'
          : null,
      sorter: true,
      ...getColumnDateRangeProps(
        'updated_at',
        handleSearch,
        handleReset,
        filter
      ),
    },
    {
      title: getI18n().t('table_header_created_at'),
      dataIndex: 'created_at',
      key: 'created_at',
      render: (date) => Format.date(date),
      defaultSortOrder:
        order === 'created_at.asc'
          ? 'ascend'
          : order === 'created_at.desc'
          ? 'descend'
          : null,
      sorter: true,
      ...getColumnDateRangeProps(
        'created_at',
        handleSearch,
        handleReset,
        filter
      ),
    },
    {
      title: getI18n().t('table_header_actions'),
      dataIndex: 'id',
      key: 'actions',
      render: (id) => (
        <ListActions
          id={id}
          page={page}
          limit={limit}
          order={order}
          filter={filter}
          load={load.bind(this)}
          remove={remove.bind(this)}
          editScreen="ResourceEditScreen"
        />
      ),
    },
  ];

  const pagination = {
    current: page,
    pageSize: limit,
    total: total,
    showSizeChanger: false,
    position: ['bottomCenter'],
  };

  const handleTableChange = (pagination, filters, sorter) => {
    let path = props.location.pathname;
    const params = {};

    // handle pagination
    if ('current' in pagination && pagination['current']) {
      path = pathTo(component, { page: pagination['current'] });
    }

    // handle sorting
    if ('field' in sorter && 'order' in sorter) {
      if (sorter['field'] && sorter['order']) {
        const order =
          sorter['field'] +
          '.' +
          (sorter['order'] === 'ascend' ? 'asc' : 'desc');
        params['order'] = order;
      }
    }

    // handle filters
    if (filters) {
      for (const key in filters) {
        if (filters[key]) {
          params[key] = filters[key].join(',');
        }
      }
    }

    history.push(QueryString.append(path, params));
  };

  const filterString = JSON.stringify(filter);
  useEffect(() => {
    load(page, limit, order, JSON.parse(filterString));
  }, [page, limit, order, filterString, load]);

  return (
    <Translation>
      {(t) => (
        <Table
          dataSource={props.list}
          columns={columns}
          pagination={page === 1 && total <= limit ? false : pagination}
          loading={props.isLoading}
          scroll={{ x: true }}
          onChange={handleTableChange}
        />
      )}
    </Translation>
  );
};

export default withRouter(ResourcesList);

Logger.log('silly', `ResourcesList loaded.`);
