import {getI18n} from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import CareGuideInvitationsScreen from './containers/CareGuideInvitationsScreenContainer';
import CareGuideInvitationAddScreen from './containers/CareGuideInvitationAddScreenContainer';
import CareGuideInvitationEditScreen from './containers/CareGuideInvitationEditScreenContainer';

const register = () => {
  Logger.log('debug', `careGuideInvitations.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'careGuideInvitations', 'routes'])) {
    Events.dispatch('ADD_MAIN_ROUTES', 
      {
        'CareGuideInvitationAddScreen': ['PrivateRoute', '/care-guide-invitations/add', true, CareGuideInvitationAddScreen, getI18n().t('care_guide_invitation_route_add')],
        'CareGuideInvitationEditScreen': ['PrivateRoute', '/care-guide-invitations/edit/:id([\\w\\-]+)', true, CareGuideInvitationEditScreen, getI18n().t('care_guide_invitation_route_edit')],
        'CareGuideInvitationsScreen': ['PrivateRoute', '/care-guide-invitations/:page(\\d+)?', true, CareGuideInvitationsScreen, getI18n().t('care_guide_invitations_route_list')],
      }
    );
  }
}

export default register;

Logger.log('silly', `careGuideInvitations.register() loaded.`);
