import {connect} from 'react-redux';

import ResourceServiceTagsScreen from '../components/ResourceServiceTagsScreen';

const mapStateToProps = state => {
  return {
    total: state.resourceServiceTags.get('total'),
    isLoading: state.resourceServiceTags.get('isLoading')
  }
}

const ResourceServiceTagsScreenContainer = connect(
  mapStateToProps
)(ResourceServiceTagsScreen);

export default ResourceServiceTagsScreenContainer;
