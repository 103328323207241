import {connect} from 'react-redux';
import {denormalize} from 'normalizr';

import {schema} from '../../../../../state/schema';
import {loadCareGuideMedia} from '../../../../../state/modules/media/actions';
import MediaInput from '../components/MediaInput';

const mapStateToProps = (state, ownProps) => {

  const careGuideId = ownProps.careGuideId;
  const page = ownProps.page || 1;
  const limit = ownProps.limit || 50;
  const order = ownProps.order || 'created_at.desc';

  const result = state.media.getIn(['care_guides', careGuideId, order, limit, page]);
  const media = result
    ? result.map(x => {
        return {
          key: x,
          ...denormalize(
            state.entities.getIn(['media', x]),
            schema.medium,
            state.entities.toJS()
          )
        };
      }).toArray()
    : [];

  return {
    options: media.map(x => {
      return {label: x.file_name + '.' + x.file_extension, value: x.id};
    }),
    isLoading: state.media.get('isCareGuideMediaLoading'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (careGuideId, page, limit, order, filter, cb) => {
      dispatch(loadCareGuideMedia(careGuideId, page, limit, order, filter, cb));
    },
  }
}

const MediaInputContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MediaInput);

export default MediaInputContainer;
