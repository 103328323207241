import { normalize } from 'normalizr';

import api from '../../api';
import { schema } from '../../schema';
import { addEntities, removeEntity } from '../../actions';
import Format from '../../../lib/Format';
import Logger from '../../../lib/Logger';

// ACTION TYPES

export const RESOURCE_NOTE_LIST_REQUEST = 'RESOURCE_NOTE_LIST_REQUEST';
export const RESOURCE_NOTE_LIST_SUCCESS = 'RESOURCE_NOTE_LIST_SUCCESS';
export const RESOURCE_NOTE_LIST_FAILURE = 'RESOURCE_NOTE_LIST_FAILURE';
export const RESOURCE_NOTE_READ_REQUEST = 'RESOURCE_NOTE_READ_REQUEST';
export const RESOURCE_NOTE_READ_SUCCESS = 'RESOURCE_NOTE_READ_SUCCESS';
export const RESOURCE_NOTE_READ_FAILURE = 'RESOURCE_NOTE_READ_FAILURE';
export const RESOURCE_NOTE_UPDATE_REQUEST = 'RESOURCE_NOTE_UPDATE_REQUEST';
export const RESOURCE_NOTE_UPDATE_SUCCESS = 'RESOURCE_NOTE_UPDATE_SUCCESS';
export const RESOURCE_NOTE_UPDATE_FAILURE = 'RESOURCE_NOTE_UPDATE_FAILURE';
export const RESOURCE_NOTE_CREATE_REQUEST = 'RESOURCE_NOTE_CREATE_REQUEST';
export const RESOURCE_NOTE_CREATE_SUCCESS = 'RESOURCE_NOTE_CREATE_SUCCESS';
export const RESOURCE_NOTE_CREATE_FAILURE = 'RESOURCE_NOTE_CREATE_FAILURE';
export const RESOURCE_NOTE_DELETE_REQUEST = 'RESOURCE_NOTE_DELETE_REQUEST';
export const RESOURCE_NOTE_DELETE_SUCCESS = 'RESOURCE_NOTE_DELETE_SUCCESS';
export const RESOURCE_NOTE_DELETE_FAILURE = 'RESOURCE_NOTE_DELETE_FAILURE';
export const RESOURCE_NOTE_FORM_DESTROY = 'RESOURCE_NOTE_FORM_DESTROY';
export const RESOURCE_NOTE_CSV_DOWNLOAD_REQUEST =
  'RESOURCE_NOTE_CSV_DOWNLOAD_REQUEST';
export const RESOURCE_NOTE_CSV_DOWNLOAD_SUCCESS =
  'RESOURCE_NOTE_CSV_DOWNLOAD_SUCCESS';
export const RESOURCE_NOTE_CSV_DOWNLOAD_FAILURE =
  'RESOURCE_NOTE_CSV_DOWNLOAD_FAILURE';

// ACTION CREATORS

export function resourceNoteListRequest(page, limit, order, filter) {
  Logger.log(
    'debug',
    `[resourceNotes.actions] resourceNoteListRequest(${page}, ${limit}, ${order}, %j)`,
    filter
  );
  return {
    type: RESOURCE_NOTE_LIST_REQUEST,
    page: page,
    limit: limit,
    order: order,
    filter: filter,
  };
}

export function resourceNoteListSuccess(data) {
  Logger.log(
    'debug',
    `[resourceNotes.actions] resourceNoteListSuccess(%j)`,
    data
  );
  return {
    type: RESOURCE_NOTE_LIST_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now(),
  };
}

export function resourceNoteListFailure(error) {
  Logger.log(
    'debug',
    `[resourceNotes.actions] resourceNoteListFailure(%j)`,
    error
  );
  return {
    type: RESOURCE_NOTE_LIST_FAILURE,
    error: error,
  };
}

export function resourceNoteReadRequest(id) {
  Logger.log('debug', `[resourceNotes.actions] resourceNoteReadRequest(${id})`);
  return {
    type: RESOURCE_NOTE_READ_REQUEST,
    id: id,
  };
}

export function resourceNoteReadSuccess(data) {
  Logger.log(
    'debug',
    `[resourceNotes.actions] resourceNoteReadSuccess(%j)`,
    data
  );
  return {
    type: RESOURCE_NOTE_READ_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function resourceNoteReadFailure(error) {
  Logger.log(
    'debug',
    `[resourceNotes.actions] resourceNoteReadFailure(%j)`,
    error
  );
  return {
    type: RESOURCE_NOTE_READ_FAILURE,
    error: error,
  };
}

export function resourceNoteUpdateRequest(id, data) {
  Logger.log(
    'debug',
    `[resourceNotes.actions] resourceNoteUpdateRequest(${id}, %j)`,
    data
  );
  return {
    type: RESOURCE_NOTE_UPDATE_REQUEST,
  };
}

export function resourceNoteUpdateSuccess(data) {
  Logger.log(
    'debug',
    `[resourceNotes.actions] resourceNoteUpdateSuccess(%j)`,
    data
  );
  return {
    type: RESOURCE_NOTE_UPDATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function resourceNoteUpdateFailure(error) {
  Logger.log(
    'debug',
    `[resourceNotes.actions] resourceNoteUpdateFailure(%j)`,
    error
  );
  return {
    type: RESOURCE_NOTE_UPDATE_FAILURE,
    error: error,
  };
}

export function resourceNoteCreateRequest(data) {
  Logger.log(
    'debug',
    `[resourceNotes.actions] resourceNoteCreateRequest(%j)`,
    data
  );
  return {
    type: RESOURCE_NOTE_CREATE_REQUEST,
  };
}

export function resourceNoteCreateSuccess(data) {
  Logger.log(
    'debug',
    `[resourceNotes.actions] resourceNoteCreateSuccess(%j)`,
    data
  );
  return {
    type: RESOURCE_NOTE_CREATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function resourceNoteCreateFailure(error) {
  Logger.log(
    'debug',
    `[resourceNotes.actions] resourceNoteCreateFailure(%j)`,
    error
  );
  return {
    type: RESOURCE_NOTE_CREATE_FAILURE,
    error: error,
  };
}

export function resourceNoteDeleteRequest(id) {
  Logger.log(
    'debug',
    `[resourceNotes.actions] resourceNoteDeleteRequest(${id})`
  );
  return {
    type: RESOURCE_NOTE_DELETE_REQUEST,
    id: id,
  };
}

export function resourceNoteDeleteSuccess(id) {
  Logger.log(
    'debug',
    `[resourceNotes.actions] resourceNoteDeleteSuccess(${id})`
  );
  return {
    type: RESOURCE_NOTE_DELETE_SUCCESS,
    id: id,
  };
}

export function resourceNoteDeleteFailure(error) {
  Logger.log(
    'debug',
    `[resourceNotes.actions] resourceNoteDeleteFailure(%j)`,
    error
  );
  return {
    type: RESOURCE_NOTE_DELETE_FAILURE,
    error: error,
  };
}

export function resourceNoteFormDestroy(formState = null) {
  Logger.log(
    'debug',
    `[resourceNotes.actions] resourceNoteFormDestroy(%j)`,
    formState
  );
  return {
    type: RESOURCE_NOTE_FORM_DESTROY,
    form: formState,
  };
}

export function resourceNoteCSVDownloadRequest(order, filter) {
  Logger.log(
    'debug',
    `[state.resourceNotes.actions] resourceNoteCSVDownloadRequest()`
  );
  return {
    type: RESOURCE_NOTE_CSV_DOWNLOAD_REQUEST,
    order: order,
    filter: filter,
  };
}

export function resourceNoteCSVDownloadSuccess() {
  Logger.log(
    'debug',
    `[state.resourceNotes.actions] resourceNoteCSVDownloadSuccess()`
  );
  return {
    type: RESOURCE_NOTE_CSV_DOWNLOAD_SUCCESS,
  };
}

export function resourceNoteCSVDownloadFailure() {
  Logger.log(
    'debug',
    `[state.resourceNotes.actions] resourceNoteCSVDownloadFailure()`
  );
  return {
    type: RESOURCE_NOTE_CSV_DOWNLOAD_FAILURE,
  };
}

// API THUNK ACTION CREATORS

export function loadResourceNotes(
  page = 1,
  limit = 10,
  order = null,
  filter = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[resourceNotes.actions] loadResourceNotes(${page}, ${limit}, ${order}, %j, ###)`,
    filter
  );

  return async function (dispatch) {
    dispatch(resourceNoteListRequest(page, limit, order, filter));

    // call API
    const response = await api.getResourceNotes(page, limit, order, filter);
    let success = false;

    // get resource notes list success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API resource notes list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );

      const normalizedEntities = normalize(
        response.getIn(['data', 'resource_notes']),
        [schema.resourceNote]
      );
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result,
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(resourceNoteListSuccess(data));
      success = true;
    } else if (1 === page && 204 === response.get('status')) {
      Logger.log(
        'info',
        `Get API resource notes success [empty]. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: [],
      };
      dispatch(resourceNoteListSuccess(data));
      success = true;

      // get resource notes list failure
    } else {
      Logger.log(
        'info',
        `Get API resource notes list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      dispatch(resourceNoteListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function loadResourceNote(id, cb = function () {}) {
  Logger.log('debug', `[resourceNotes.actions] loadResourceNote(${id}, ###)`);

  return async function (dispatch) {
    dispatch(resourceNoteReadRequest(id));

    // call API
    const response = await api.getResourceNote(id);
    let success = false;

    // get resource note success
    if (200 === response.get('status')) {
      Logger.log('info', `Get API resource note success. ID: ${id}.`);

      const normalizedEntities = normalize(
        [response.getIn(['data', 'resource_note'])],
        [schema.resourceNote]
      );
      const data = {
        id: response.getIn(['data', 'resource_note', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(resourceNoteReadSuccess(data));
      success = true;

      // get resource note failure
    } else {
      Logger.log('info', `Get API resource note failure. ID: ${id}.`);
      dispatch(resourceNoteReadFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function updateResourceNote(id, data, cb = function () {}) {
  Logger.log(
    'debug',
    `[resourceNotes.actions] updateResourceNote(${id}, %j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(resourceNoteUpdateRequest(id, data));

    // call API
    const response = await api.putResourceNote(id, data);
    let success = false;

    // put resource note success
    if (200 === response.get('status')) {
      Logger.log('info', `PUT API resource note success. User: ${id}.`);

      const normalizedEntities = normalize(
        [response.getIn(['data', 'resource_note'])],
        [schema.resourceNote]
      );
      const data = {
        id: response.getIn(['data', 'resource_note', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(resourceNoteUpdateSuccess(data));
      success = true;

      // get resource note failure
    } else {
      Logger.log('info', `PUT API resource note failure. ID: ${id}.`);
      dispatch(resourceNoteUpdateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function createResourceNote(data, cb = function () {}) {
  Logger.log(
    'debug',
    `[resourceNotes.actions] createResourceNote(%j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(resourceNoteCreateRequest(data));

    // call API
    const response = await api.postResourceNotes(data);
    let success = false;

    // post resource notes success
    if (201 === response.get('status')) {
      Logger.log(
        'info',
        `POST API resource notes success. Post: ${response.getIn([
          'data',
          'resource_note',
          'id',
        ])}.`
      );

      const normalizedEntities = normalize(
        [response.getIn(['data', 'resource_note'])],
        [schema.resourceNote]
      );
      const data = {
        id: response.getIn(['data', 'resource_note', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(resourceNoteCreateSuccess(data));
      success = true;

      // get resource notes failure
    } else {
      Logger.log('info', `POST API resource notes failure.`);
      dispatch(resourceNoteCreateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function deleteResourceNote(id, cb = function () {}) {
  Logger.log('debug', `[resourceNotes.actions] deleteResourceNote(${id}, ###)`);

  return async function (dispatch) {
    dispatch(resourceNoteDeleteRequest(id));

    // call API
    const response = await api.deleteResourceNote(id);
    let success = false;

    // delete resource note success
    if (204 === response.get('status')) {
      Logger.log('info', `DELETE API resource note success. ID: ${id}.`);

      dispatch(removeEntity({ entityType: 'resource_notes', id: id }));
      dispatch(resourceNoteDeleteSuccess(id));
      success = true;

      // get resource note failure
    } else {
      Logger.log('info', `DELETE API resource note failure. ID: ${id}.`);
      dispatch(resourceNoteDeleteFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function resourceNotesCSV(
  order = null,
  filter = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.resourceNotes.actions] resourceNotesCSV(${order}, ${filter}, ###)`
  );

  return async function (dispatch) {
    dispatch(resourceNoteCSVDownloadRequest(order, filter));

    // call API
    const response = await api.getResourceNotesCSV(order, filter);
    let success = false;

    // get resource notes CSV success
    if (200 === response.get('status')) {
      Logger.log('info', `Get API resource notes CSV success. Order: ${order}`);
      const now = new Date();

      // trigger browser download
      const url = window.URL.createObjectURL(new Blob([response.get('data')]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `cv-resource-notes-${Format.date(now, 'YYYY-MM-DDTHHmm')}.csv`
      );
      document.body.appendChild(link);
      link.click();

      dispatch(resourceNoteCSVDownloadSuccess());
      success = true;
      // get resource notes CSV failure
    } else {
      Logger.log('info', `Get API resource notes CSV failure.`);
      dispatch(resourceNoteCSVDownloadFailure());
    }

    // callback function
    cb(success);
  };
}

Logger.log('silly', `resourceNotes.actions loaded.`);
