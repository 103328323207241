import {connect} from 'react-redux';
import {List} from 'immutable';

import {
  loadCareGuideSection,
  updateCareGuideSection,
  createCareGuideSection,
  deleteCareGuideSection,
  careGuideSectionFormDestroy
} from '../../../../../state/modules/careGuideSections/actions';
import {sendMessage} from '../../../../../state/actions';
import CareGuideSectionForm from '../components/CareGuideSectionForm';

const inputs = List([
  'name',
  'fields',
  'status'
]);

const mapStateToProps = (state, ownProps) => {

  const data = {}, errors = {};
  for (const val of inputs.values()) {
    data[val] = state.entities.getIn(['careGuideSections', ownProps.id, val], '');
    if (state.careGuideSections.getIn(['form', 'errors', val])) {
      errors[val] = state.careGuideSections.getIn(['form', 'errors', val]);
    }
  }

  // change select number values to strings to work with ant design select inputs
  Object.keys(data).forEach(x => {
    if (['status'].includes(x)) {
      data[x] = data[x].toString();
    }
  });

  // hydrate fields for dynamic inputs
  if ('fields' in data && Array.isArray(data['fields'])) {
    data['fields'] = data['fields'].map(x => state.entities.getIn(['careGuideSectionFields', x]));
  }

  return {
    isLoading: state.careGuideSections.get('isLoading'),
    isSubmitting: state.careGuideSections.getIn(['form', 'isSubmitting']),
    success: state.careGuideSections.getIn(['form', 'success']),
    created_id: state.careGuideSections.getIn(['form', 'created_id']),
    data: data,
    errors: errors,
    createdAt: state.entities.getIn(['careGuideSections', ownProps.id, 'created_at'], null),
    updatedAt: state.entities.getIn(['careGuideSections', ownProps.id, 'updated_at'], null),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (id, cb) => {
      dispatch(loadCareGuideSection(id, cb));
    },
    update: (id, data, cb) => {
      dispatch(updateCareGuideSection(id, data, cb));
    },
    create: (data, cb) => {
      dispatch(createCareGuideSection(data, cb));
    },
    delete: (id, cb) => {
      dispatch(deleteCareGuideSection(id, cb))
    },
    destroyForm: (formState) => {
      dispatch(careGuideSectionFormDestroy(formState));
    },
    sendMessage: (level, title, body, expires) => {
      dispatch(sendMessage(level, title, body, expires));
    },
  }
}

const CareGuideSectionFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CareGuideSectionForm);

export default CareGuideSectionFormContainer;
