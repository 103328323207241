import { Map, List } from 'immutable';

import {
  IMPORT_TASK_LIST_REQUEST,
  IMPORT_TASK_LIST_SUCCESS,
  IMPORT_TASK_LIST_FAILURE,
  IMPORT_TASK_READ_REQUEST,
  IMPORT_TASK_READ_SUCCESS,
  IMPORT_TASK_READ_FAILURE,
} from './actions';
import Logger from '../../../lib/Logger';

export default function importTasks(
  state = Map({
    isLoading: false,
    pages: {},
  }),
  action
) {
  Logger.log(
    'debug',
    `[importTasks.reducers] importTasks(%j, %j)`,
    state,
    action
  );

  switch (action.type) {
    case IMPORT_TASK_LIST_REQUEST:
      return state.mergeDeep({
        isLoading: true,
      });

    case IMPORT_TASK_LIST_SUCCESS:
      return state
        .mergeDeep({
          isLoading: false,
          pages: {
            [action.order]: {
              [action.limit]: {
                [action.page]: null,
              },
            },
          },
          total: action.total,
          lastUpdated: action.receivedAt,
        })
        .setIn(
          ['pages', action.order, action.limit, action.page],
          List(action.result)
        );

    case IMPORT_TASK_LIST_FAILURE:
      return state.mergeDeep({
        isLoading: false,
      });

    case IMPORT_TASK_READ_REQUEST:
      return state.mergeDeep({
        isLoading: true,
      });

    case IMPORT_TASK_READ_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        lastUpdated: action.receivedAt,
      });

    case IMPORT_TASK_READ_FAILURE:
      return state.mergeDeep({
        isLoading: false,
      });

    default:
      return state;
  }
}

Logger.log('silly', `importTasks.reducers loaded.`);
