import {connect} from 'react-redux';
import {List} from 'immutable';

import {
  loadUserProfilesPartnership,
  updateUserProfilesPartnership,
  createUserProfilesPartnership,
  deleteUserProfilesPartnership,
  userProfilesPartnershipFormDestroy
} from '../../../../../state/modules/userProfilesPartnerships/actions';
import {sendMessage} from '../../../../../state/actions';
import UserProfilesPartnershipForm from '../components/UserProfilesPartnershipForm';

const inputs = List([
  'id',
  'profile',
  'partnership',
  'role',
  'status'
]);

const mapStateToProps = (state, ownProps) => {

  const data = {}, errors = {};
  for (const val of inputs.values()) {
    data[val] = state.entities.getIn(['userProfilesPartnerships', ownProps.id, val], '');
      errors[val] = state.userProfilesPartnerships.getIn(['form', 'errors', val]);
  }

  // change select number values to strings to work with ant design select inputs
  Object.keys(data).forEach(x => {
    if (['status'].includes(x)) {
      data[x] = data[x].toString();
    }
  });


  return {
    isLoading: state.userProfilesPartnerships.get('isLoading'),
    isSubmitting: state.userProfilesPartnerships.getIn(['form', 'isSubmitting']),
    success: state.userProfilesPartnerships.getIn(['form', 'success']),
    created_id: state.userProfilesPartnerships.getIn(['form', 'created_id']),
    data: data,
    errors: errors,
    createdAt: state.entities.getIn(['userProfilesPartnerships', ownProps.id, 'created_at'], null),
    updatedAt: state.entities.getIn(['userProfilesPartnerships', ownProps.id, 'updated_at'], null),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (id, cb) => {
      dispatch(loadUserProfilesPartnership(id, cb));
    },
    update: (id, data, cb) => {
      dispatch(updateUserProfilesPartnership(id, data, cb));
    },
    create: (data, cb) => {
      dispatch(createUserProfilesPartnership(data, cb));
    },
    delete: (id, cb) => {
      dispatch(deleteUserProfilesPartnership(id, cb))
    },
    destroyForm: (formState) => {
      dispatch(userProfilesPartnershipFormDestroy(formState));
    },
    sendMessage: (level, title, body, expires) => {
      dispatch(sendMessage(level, title, body, expires));
    },
  }
}

const UserProfilesPartnershipFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProfilesPartnershipForm);

export default UserProfilesPartnershipFormContainer;
