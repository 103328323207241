import {getI18n} from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import CareGuideDocumentsScreen from './containers/CareGuideDocumentsScreenContainer';
import CareGuideDocumentAddScreen from './containers/CareGuideDocumentAddScreenContainer';
import CareGuideDocumentEditScreen from './containers/CareGuideDocumentEditScreenContainer';
import CareGuideDocumentViewScreen from './containers/CareGuideDocumentViewScreenContainer';

const register = () => {
  Logger.log('debug', `careGuideDocuments.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'careGuideDocuments', 'routes'])) {
    Events.dispatch('ADD_MAIN_ROUTES', 
      {
        'CareGuideDocumentViewScreen': ['PrivateRoute', '/care-guide-documents/view/:id([\\w\\-]+)', true, CareGuideDocumentViewScreen, getI18n().t('care_guide_documents_route_view')],
        'CareGuideDocumentAddScreen': ['PrivateRoute', '/care-guide-documents/add', true, CareGuideDocumentAddScreen, getI18n().t('care_guide_documents_route_add')],
        'CareGuideDocumentEditScreen': ['PrivateRoute', '/care-guide-documents/edit/:id([\\w\\-]+)', true, CareGuideDocumentEditScreen, getI18n().t('care_guide_documents_route_edit')],
        'CareGuideDocumentsScreen': ['PrivateRoute', '/care-guide-documents/:page(\\d+)?', true, CareGuideDocumentsScreen, getI18n().t('care_guide_documents_route_list')],
      }
    );
  }
}

export default register;

Logger.log('silly', `careGuideDocuments.register() loaded.`);
