import {connect} from 'react-redux';

import CareGuideHelperTagEditScreen from '../components/CareGuideHelperTagEditScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.careGuideHelperTags.get('isLoading'),
    success: state.careGuideHelperTags.getIn(['form', 'success']),
  }
}

const CareGuideHelperTagEditScreenContainer = connect(
  mapStateToProps
)(CareGuideHelperTagEditScreen);

export default CareGuideHelperTagEditScreenContainer;
