import {normalize} from 'normalizr';

import api from '../../api';
import {schema} from '../../schema';
import {addEntities, removeEntity} from '../../actions';
import Logger from '../../../lib/Logger';

export const COUNTRY_LIST_REQUEST = 'COUNTRY_LIST_REQUEST';
export const COUNTRY_LIST_SUCCESS = 'COUNTRY_LIST_SUCCESS';
export const COUNTRY_LIST_FAILURE = 'COUNTRY_LIST_FAILURE';
export const COUNTRY_READ_REQUEST = 'COUNTRY_READ_REQUEST';
export const COUNTRY_READ_SUCCESS = 'COUNTRY_READ_SUCCESS';
export const COUNTRY_READ_FAILURE = 'COUNTRY_READ_FAILURE';
export const COUNTRY_CREATE_FAILURE = 'COUNTRY_CREATE_FAILURE';
export const COUNTRY_CREATE_REQUEST = 'COUNTRY_CREATE_REQUEST';
export const COUNTRY_CREATE_SUCCESS = 'COUNTRY_CREATE_SUCCESS';
export const COUNTRY_DELETE_REQUEST = 'COUNTRY_DELETE_REQUEST';
export const COUNTRY_DELETE_SUCCESS = 'COUNTRY_DELETE_SUCCESS';
export const COUNTRY_DELETE_FAILURE = 'COUNTRY_DELETE_FAILURE';
export const COUNTRY_UPDATE_REQUEST = 'COUNTRY_UPDATE_REQUEST';
export const COUNTRY_UPDATE_SUCCESS = 'COUNTRY_UPDATE_SUCCESS';
export const COUNTRY_UPDATE_FAILURE = 'COUNTRY_UPDATE_FAILURE';
export const COUNTRY_FORM_DESTROY = 'COUNTRY_FORM_DESTROY';

export function countryListRequest(page, limit, order, filter) {
    Logger.log('debug', `[location.actions] countryListRequest(${page}, ${limit})`);
    return {
      type: COUNTRY_LIST_REQUEST,
      page: page,
      limit: limit,
      order: order,
      filter: filter
    }
  }
  
  export function countryListSuccess(data) {
    Logger.log('debug', `[location.actions] countryListSuccess(%j)`, data);
    return {
      type: COUNTRY_LIST_SUCCESS,
      page: data.page,
      limit: data.limit,
      order: data.order,
      result: data.result,
      total: data.total,
      receivedAt: Date.now()
    }
  }
  
  export function countryListFailure(error) {
    Logger.log('debug', `[location.actions] countryListFailure(%j)`, error);
    return {
      type: COUNTRY_LIST_FAILURE,
      error: error
    }
  }
  
  export function countryCreateRequest(data) {
    Logger.log('debug', `[country.actions] countryCreateRequest(%j)`, data);
    return {
      type: COUNTRY_CREATE_REQUEST,
    }
  }
  
  export function countryCreateSuccess(data) {
    Logger.log('debug', `[country.actions] countryCreateSuccess(%j)`, data);
    return {
      type: COUNTRY_CREATE_SUCCESS,
      id: data.id,
      receivedAt: Date.now()
    }
  }
  
  export function countryCreateFailure(error) {
    Logger.log('debug', `[country.actions] countryCreateFailure(%j)`, error);
    return {
      type: COUNTRY_CREATE_FAILURE,
      error: error
    }
  }
  
  export function countryReadRequest(id) {
    Logger.log('debug', `[country.actions] countryReadRequest(${id})`);
    return {
      type: COUNTRY_READ_REQUEST,
      id: id
    }
  }
  
  export function countryReadSuccess(data) {
    Logger.log('debug', `[country.actions] countryReadSuccess(%j)`, data);
    return {
      type: COUNTRY_READ_SUCCESS,
      id: data.id,
      receivedAt: Date.now()
    }
  }
  
  export function countryReadFailure(error) {
    Logger.log('debug', `[country.actions] countryReadFailure(%j)`, error);
    return {
      type: COUNTRY_READ_FAILURE,
      error: error
    }
  }
  
  export function countryUpdateRequest(id, data) {
    Logger.log('debug', `[country.actions] countryUpdateRequest(${id}, %j)`, data);
    return {
      type: COUNTRY_UPDATE_REQUEST,
    }
  }
  
  export function countryUpdateSuccess(data) {
    Logger.log('debug', `[country.actions] countryUpdateSuccess(%j)`, data);
    return {
      type: COUNTRY_UPDATE_SUCCESS,
      id: data.id,
      receivedAt: Date.now()
    }
  }
  
  export function countryUpdateFailure(error) {
    Logger.log('debug', `[country.actions] countryUpdateFailure(%j)`, error);
    return {
      type: COUNTRY_UPDATE_FAILURE,
      error: error
    }
  }
  
  export function  countryDeleteRequest(id) {
    Logger.log('debug', `[ country.actions] countryDeleteRequest(${id})`);
    return {
      type: COUNTRY_DELETE_REQUEST,
      id: id
    }
  }
  
  export function countryDeleteSuccess(id) {
    Logger.log('debug', `[country.actions] countryDeleteSuccess(${id})`);
    return {
      type: COUNTRY_DELETE_SUCCESS,
      id: id,
    }
  }
  
  export function countryDeleteFailure(error) {
    Logger.log('debug', `[country.actions] countryDeleteFailure(%j)`, error);
    return {
      type: COUNTRY_DELETE_FAILURE,
      error: error
    }
  }
  
  export function countryFormDestroy(formState=null) {
    Logger.log('debug', `[country.actions] countryFormDestroy(%j)`, formState);
    return {
      type: COUNTRY_FORM_DESTROY,
      form: formState
    }
  }

  // API THUNK ACTION CREATORS

export function loadCountries(page=1, limit=250,order=null, filter=null, cb=function(){}) {
    Logger.log('debug', `[location.actions] loadCountries(${page}, ${limit}, ###)`);
  
    return async function(dispatch) {
      dispatch(countryListRequest(page, limit, order, filter));
  
      // call API
      const response = await api.getCountries(page, limit, order, filter);
  
      // get countries list success
      if (200 === response.get('status')) {
  
        Logger.log('info', `Get API countries list success. Page: ${page}, Limit: ${limit}.  Order: ${order}.`);
  
        const normalizedCountries = normalize(response.getIn(['data', 'countries']), [schema.country]);
        const countrySuccessData = {
          page: response.getIn(['data', 'page']),
          limit: response.getIn(['data', 'limit']),
          order: order,
          total: response.getIn(['data', 'total']),
          result: normalizedCountries.result
        };
        dispatch(addEntities(normalizedCountries));
        dispatch(countryListSuccess(countrySuccessData));
        
      // get countries list failure
      } else {
        Logger.log('info', `Get API countries list failure. Page: ${page}, Limit: ${limit}.`);
        dispatch(countryListFailure(response.getIn(['data', 'error'])));
      }
  
      // callback function
      cb();
    }
  }
  
  export function loadCountry(id, cb=function(){}) {
    Logger.log('debug', `[country.actions] loadCountry(${id}, ###)`);
  
    return async function(dispatch) {
      dispatch(countryReadRequest(id));
  
      // call API
      const response = await api.getCountry(id);
      let success = false;
  
      // get country success
      if (200 === response.get('status')) {
  
        Logger.log('info', `Get API country success. ID: ${id}.`);
  
        const normalizedEntities = normalize([response.getIn(['data', 'country'])], [schema.country]);
        const data = {
          id: response.getIn(['data', 'country', 'id']),
        };
  
        dispatch(addEntities(normalizedEntities));
        dispatch(countryReadSuccess(data));
        success = true;
        
      // get country failure
      } else {
        Logger.log('info', `Get API country failure. ID: ${id}.`);
        dispatch(countryReadFailure(response.getIn(['data', 'error'])));
      }
  
      // callback function
      cb(success);
    }
  }
  
  export function createCountry(data, cb=function(){}) {
    Logger.log('debug', `[country.actions] createCountry(%j, ###)`, data);
  
    return async function(dispatch) {
      dispatch(countryCreateRequest(data));
  
      // call API
      const response = await api.postCountry(data);
      let success = false;
  
      // post country success
      if (201 === response.get('status')) {
  
        Logger.log('info', `POST API country success. Post: ${response.getIn(['data', 'country', 'id'])}.`);
  
        const normalizedEntities = normalize([response.getIn(['data', 'country'])], [schema.country]);
        const data = {
          id: response.getIn(['data', 'country', 'id']),
        };
  
        dispatch(addEntities(normalizedEntities));
        dispatch(countryCreateSuccess(data));
        success = true;
        
      // get countrys failure
      } else {
        Logger.log('info', `POST API country failure.`);
        dispatch(countryCreateFailure(response.getIn(['data', 'error'])));
      }
  
      // callback function
      cb(success);
    }
  }
  
  export function updateCountry(id, data, cb=function(){}) {
    Logger.log('debug', `[country.actions] updateCountry(${id}, %j, ###)`, data);
  
    return async function(dispatch) {
      dispatch(countryUpdateRequest(id, data));
  
      // call API
      const response = await api.putCountry(id, data);
      let success = false;
  
      // put country success
      if (200 === response.get('status')) {
  
        Logger.log('info', `PUT API country success. User: ${id}.`);
  
        const normalizedEntities = normalize([response.getIn(['data', 'country'])], [schema.country]);
        const data = {
          id: response.getIn(['data', 'country', 'id']),
        };
  
        dispatch(addEntities(normalizedEntities));
        dispatch(countryUpdateSuccess(data));
        success = true;
        
      // get country failure
      } else {
        Logger.log('info', `PUT API country failure. ID: ${id}.`);
        dispatch(countryUpdateFailure(response.getIn(['data', 'error'])));
      }
  
      // callback function
      cb(success);
    }
  }
  
  export function deleteCountry(id, cb=function(){}) {
    Logger.log('debug', `[locations.actions] deleteCountry(${id}, ###)`);
  
    return async function(dispatch) {
      dispatch(countryDeleteRequest(id));
  
      // call API
      const response = await api.deleteCountry(id);
      let success = false;
  
      // delete country post success
      if (204 === response.get('status')) {
  
        Logger.log('info', `DELETE API country success. ID: ${id}.`);
  
        dispatch(removeEntity({entityType: 'country', id: id}));
        dispatch(countryDeleteSuccess(id));
        success = true;
        
      // get country failure
      } else {
        Logger.log('info', `DELETE API country post failure. ID: ${id}.`);
        dispatch(countryDeleteFailure(response.getIn(['data', 'error'])));
      }
  
      // callback function
      cb(success);
    }
  }