import { connect } from 'react-redux';
import { List } from 'immutable';

import {
  loadPartnership,
  updatePartnership,
  deletePartnership,
  createPartnership,
  partnershipFormDestroy,
} from '../../../../../state/modules/partnerships/actions';
import { sendMessage } from '../../../../../state/actions';
import PartnershipForm from '../components/PartnershipForm';
import { schema } from '../../../../../state/schema';
import { denormalize } from 'normalizr';

const inputs = List([
  'id',
  'status',
  'name',
  'cognito_form_id',
  'cognito_form_key',
  'color_hex',
  'access_role_admin_in_app',
  'access_role_admin_in_care_guide',
  'access_tabs_in_app',
  'user_profiles_partnerships',
  'profile',
  'plan',
  'hide_partner_tags',
]);

const mapStateToProps = (state, ownProps) => {
  const data = {},
    errors = {};
  for (const val of inputs.values()) {
    data[val] = state.entities.getIn(['partnerships', ownProps.id, val], '');
    if (['partnership'].includes(val)) {
      errors[val] = state.partnerships.getIn(['form', 'errors', val + '_id']);
    } else {
      errors[val] = state.partnerships.getIn(['form', 'errors', val]);
    }
  }

  data['access_role_admin_in_app'] = data['access_role_admin_in_app']
    ? Object.keys(data['access_role_admin_in_app']).filter(
        (x) => data['access_role_admin_in_app'][x]
      )
    : null;

  data['access_role_admin_in_care_guide'] = data[
    'access_role_admin_in_care_guide'
  ]
    ? Object.keys(data['access_role_admin_in_care_guide']).filter(
        (x) => data['access_role_admin_in_care_guide'][x]
      )
    : null;

  if (
    'user_profiles_partnerships' in data &&
    Array.isArray(data['user_profiles_partnerships'])
  ) {
    data['profile'] = data['user_profiles_partnerships']
      ? data['user_profiles_partnerships']
          .map((x) => {
            return {
              key: x,
              ...denormalize(
                state.entities.getIn(['userProfilesPartnerships', x]),
                schema.userProfilesPartnership,
                state.entities.toJS()
              ),
            };
          })
          .filter((user) => user.role === 1)
          .map((user) => {
            return user.profile.id;
          })[0]
      : [];
  }

  data['access_tabs_in_app'] = data['access_tabs_in_app']
    ? Object.keys(data['access_tabs_in_app']).filter(
        (x) => data['access_tabs_in_app'][x]
      )
    : null;

  // change select number values to strings to work with ant design select inputs
  Object.keys(data).forEach((x) => {
    if (['status'].includes(x)) {
      data[x] = data[x].toString();
    }
  });

  return {
    isLoading: state.partnerships.get('isLoading'),
    isSubmitting: state.partnerships.getIn(['form', 'isSubmitting']),
    success: state.partnerships.getIn(['form', 'success']),
    created_id: state.partnerships.getIn(['form', 'created_id']),
    data: data,
    errors: errors,
    createdAt: state.entities.getIn(
      ['partnerships', ownProps.id, 'created_at'],
      null
    ),
    updatedAt: state.entities.getIn(
      ['partnerships', ownProps.id, 'updated_at'],
      null
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (id, cb) => {
      dispatch(loadPartnership(id, cb));
    },
    update: (id, data, cb) => {
      dispatch(updatePartnership(id, data, cb));
    },
    delete: (id, cb) => {
      dispatch(deletePartnership(id, cb));
    },
    destroyForm: (formState) => {
      dispatch(partnershipFormDestroy(formState));
    },
    sendMessage: (level, title, body, expires) => {
      dispatch(sendMessage(level, title, body, expires));
    },
    create: (data, cb) => {
      dispatch(createPartnership(data, cb));
    },
  };
};

const PartnershipFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnershipForm);

export default PartnershipFormContainer;
