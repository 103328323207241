import {getI18n} from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import JournalRepliesScreen from './containers/JournalRepliesScreenContainer';
import JournalReplyAddScreen from './containers/JournalReplyAddScreenContainer';
import JournalReplyEditScreen from './containers/JournalReplyEditScreenContainer';

const register = () => {
  Logger.log('debug', `journalReplies.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'journalReplies', 'routes'])) {
    Events.dispatch('ADD_MAIN_ROUTES', 
      {
        'JournalReplyAddScreen': ['PrivateRoute', '/journal-replies/add', true, JournalReplyAddScreen, getI18n().t('journal_reply_route_add')],
        'JournalReplyEditScreen': ['PrivateRoute', '/journal-replies/edit/:id([\\w\\-]+)', true, JournalReplyEditScreen, getI18n().t('journal_reply_route_edit')],
        'JournalRepliesScreen': ['PrivateRoute', '/journal-replies/:page(\\d+)?', true, JournalRepliesScreen, getI18n().t('journal_replies_route_list')],
      }
    );
  }
}

export default register;

Logger.log('silly', `journalReplies.register() loaded.`);
