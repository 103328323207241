import {normalize} from 'normalizr';

import api from '../../api';
import {schema} from '../../schema';
import {addEntities, removeEntity} from '../../actions';
import Format from '../../../lib/Format';
import Logger from '../../../lib/Logger';

// ACTION TYPES

export const REGISTRATION_CODE_LIST_REQUEST = 'REGISTRATION_CODE_LIST_REQUEST';
export const REGISTRATION_CODE_LIST_SUCCESS = 'REGISTRATION_CODE_LIST_SUCCESS';
export const REGISTRATION_CODE_LIST_FAILURE = 'REGISTRATION_CODE_LIST_FAILURE';
export const REGISTRATION_CODE_READ_REQUEST = 'REGISTRATION_CODE_READ_REQUEST';
export const REGISTRATION_CODE_READ_SUCCESS = 'REGISTRATION_CODE_READ_SUCCESS';
export const REGISTRATION_CODE_READ_FAILURE = 'REGISTRATION_CODE_READ_FAILURE';
export const REGISTRATION_CODE_UPDATE_REQUEST = 'REGISTRATION_CODE_UPDATE_REQUEST';
export const REGISTRATION_CODE_UPDATE_SUCCESS = 'REGISTRATION_CODE_UPDATE_SUCCESS';
export const REGISTRATION_CODE_UPDATE_FAILURE = 'REGISTRATION_CODE_UPDATE_FAILURE';
export const REGISTRATION_CODE_CREATE_REQUEST = 'REGISTRATION_CODE_CREATE_REQUEST';
export const REGISTRATION_CODE_CREATE_SUCCESS = 'REGISTRATION_CODE_CREATE_SUCCESS';
export const REGISTRATION_CODE_CREATE_FAILURE = 'REGISTRATION_CODE_CREATE_FAILURE';
export const REGISTRATION_CODE_DELETE_REQUEST = 'REGISTRATION_CODE_DELETE_REQUEST';
export const REGISTRATION_CODE_DELETE_SUCCESS = 'REGISTRATION_CODE_DELETE_SUCCESS';
export const REGISTRATION_CODE_DELETE_FAILURE = 'REGISTRATION_CODE_DELETE_FAILURE';
export const REGISTRATION_CODE_FORM_DESTROY = 'REGISTRATION_CODE_FORM_DESTROY';
export const REGISTRATION_CODE_CSV_DOWNLOAD_REQUEST = 'REGISTRATION_CODE_CSV_DOWNLOAD_REQUEST';
export const REGISTRATION_CODE_CSV_DOWNLOAD_SUCCESS = 'REGISTRATION_CODE_CSV_DOWNLOAD_SUCCESS';
export const REGISTRATION_CODE_CSV_DOWNLOAD_FAILURE = 'REGISTRATION_CODE_CSV_DOWNLOAD_FAILURE';

// ACTION CREATORS

export function registrationCodeListRequest(page, limit, order, filter) {
  Logger.log('debug', `[registrationCodes.actions] registrationCodeListRequest(${page}, ${limit}, ${order}, %j)`, filter);
  return {
    type: REGISTRATION_CODE_LIST_REQUEST,
    page: page,
    limit: limit,
    order: order,
    filter: filter
  }
}

export function registrationCodeListSuccess(data) {
  Logger.log('debug', `[registrationCodes.actions] registrationCodeListSuccess(%j)`, data);
  return {
    type: REGISTRATION_CODE_LIST_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now()
  }
}

export function registrationCodeListFailure(error) {
  Logger.log('debug', `[registrationCodes.actions] registrationCodeListFailure(%j)`, error);
  return {
    type: REGISTRATION_CODE_LIST_FAILURE,
    error: error
  }
}

export function registrationCodeReadRequest(id) {
  Logger.log('debug', `[registrationCodes.actions] registrationCodeReadRequest(${id})`);
  return {
    type: REGISTRATION_CODE_READ_REQUEST,
    id: id
  }
}

export function registrationCodeReadSuccess(data) {
  Logger.log('debug', `[registrationCodes.actions] registrationCodeReadSuccess(%j)`, data);
  return {
    type: REGISTRATION_CODE_READ_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function registrationCodeReadFailure(error) {
  Logger.log('debug', `[registrationCodes.actions] registrationCodeReadFailure(%j)`, error);
  return {
    type: REGISTRATION_CODE_READ_FAILURE,
    error: error
  }
}

export function registrationCodeUpdateRequest(id, data) {
  Logger.log('debug', `[registrationCodes.actions] registrationCodeUpdateRequest(${id}, %j)`, data);
  return {
    type: REGISTRATION_CODE_UPDATE_REQUEST,
  }
}

export function registrationCodeUpdateSuccess(data) {
  Logger.log('debug', `[registrationCodes.actions] registrationCodeUpdateSuccess(%j)`, data);
  return {
    type: REGISTRATION_CODE_UPDATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function registrationCodeUpdateFailure(error) {
  Logger.log('debug', `[registrationCodes.actions] registrationCodeUpdateFailure(%j)`, error);
  return {
    type: REGISTRATION_CODE_UPDATE_FAILURE,
    error: error
  }
}

export function registrationCodeCreateRequest(data) {
  Logger.log('debug', `[registrationCodes.actions] registrationCodeCreateRequest(%j)`, data);
  return {
    type: REGISTRATION_CODE_CREATE_REQUEST,
  }
}

export function registrationCodeCreateSuccess(data) {
  Logger.log('debug', `[registrationCodes.actions] registrationCodeCreateSuccess(%j)`, data);
  return {
    type: REGISTRATION_CODE_CREATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function registrationCodeCreateFailure(error) {
  Logger.log('debug', `[registrationCodes.actions] registrationCodeCreateFailure(%j)`, error);
  return {
    type: REGISTRATION_CODE_CREATE_FAILURE,
    error: error
  }
}

export function registrationCodeDeleteRequest(id) {
  Logger.log('debug', `[registrationCodes.actions] registrationCodeDeleteRequest(${id})`);
  return {
    type: REGISTRATION_CODE_DELETE_REQUEST,
    id: id
  }
}

export function registrationCodeDeleteSuccess(id) {
  Logger.log('debug', `[registrationCodes.actions] registrationCodeDeleteSuccess(${id})`);
  return {
    type: REGISTRATION_CODE_DELETE_SUCCESS,
    id: id,
  }
}

export function registrationCodeDeleteFailure(error) {
  Logger.log('debug', `[registrationCodes.actions] registrationCodeDeleteFailure(%j)`, error);
  return {
    type: REGISTRATION_CODE_DELETE_FAILURE,
    error: error
  }
}

export function registrationCodeFormDestroy(formState=null) {
  Logger.log('debug', `[registrationCodes.actions] registrationCodeFormDestroy(%j)`, formState);
  return {
    type: REGISTRATION_CODE_FORM_DESTROY,
    form: formState
  }
}

export function registrationCodeCSVDownloadRequest(order, filter) {
  Logger.log('debug', `[state.registrationCodes.actions] registrationCodeCSVDownloadRequest()`);
  return {
    type: REGISTRATION_CODE_CSV_DOWNLOAD_REQUEST,
    order: order,
    filter: filter,
  }
}

export function registrationCodeCSVDownloadSuccess() {
  Logger.log('debug', `[state.registrationCodes.actions] registrationCodeCSVDownloadSuccess()`);
  return {
    type: REGISTRATION_CODE_CSV_DOWNLOAD_SUCCESS,
  }
}

export function registrationCodeCSVDownloadFailure() {
  Logger.log('debug', `[state.registrationCodes.actions] registrationCodeCSVDownloadFailure()`);
  return {
    type: REGISTRATION_CODE_CSV_DOWNLOAD_FAILURE,
  }
}


// API THUNK ACTION CREATORS

export function loadRegistrationCodes(page=1, limit=10, order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[registrationCodes.actions] loadRegistrationCodes(${page}, ${limit}, ${order}, %j, ###)`, filter);

  return async function(dispatch) {
    dispatch(registrationCodeListRequest(page, limit, order, filter));

    // call API
    const response = await api.getRegistrationCodes(page, limit, order, filter);
    let success = false;

    // get registration codes list success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API registration codes list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`);

      const normalizedEntities = normalize(response.getIn(['data', 'registration_codes']), [schema.registrationCode]);
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(registrationCodeListSuccess(data));
      success = true;

    } else if (1 === page && 204 === response.get('status')) {

      Logger.log('info', `Get API registration codes success [empty]. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: []
      };
      dispatch(registrationCodeListSuccess(data));
      success = true;
      
    // get registration codes list failure
    } else {
      Logger.log('info', `Get API registration codes list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      dispatch(registrationCodeListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function loadRegistrationCode(id, cb=function(){}) {
  Logger.log('debug', `[registrationCodes.actions] loadRegistrationCode(${id}, ###)`);

  return async function(dispatch) {
    dispatch(registrationCodeReadRequest(id));

    // call API
    const response = await api.getRegistrationCode(id);
    let success = false;

    // get registration code success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API registration code success. ID: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'registration_code'])], [schema.registrationCode]);
      const data = {
        id: response.getIn(['data', 'registration_code', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(registrationCodeReadSuccess(data));
      success = true;
      
    // get registration code failure
    } else {
      Logger.log('info', `Get API registration code failure. ID: ${id}.`);
      dispatch(registrationCodeReadFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function updateRegistrationCode(id, data, cb=function(){}) {
  Logger.log('debug', `[registrationCodes.actions] updateRegistrationCode(${id}, %j, ###)`, data);

  return async function(dispatch) {
    dispatch(registrationCodeUpdateRequest(id, data));

    // call API
    const response = await api.putRegistrationCode(id, data);
    let success = false;

    // put registration code success
    if (200 === response.get('status')) {

      Logger.log('info', `PUT API registration code success. User: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'registration_code'])], [schema.registrationCode]);
      const data = {
        id: response.getIn(['data', 'registration_code', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(registrationCodeUpdateSuccess(data));
      success = true;
      
    // get registration code failure
    } else {
      Logger.log('info', `PUT API registration code failure. ID: ${id}.`);
      dispatch(registrationCodeUpdateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function createRegistrationCode(data, cb=function(){}) {
  Logger.log('debug', `[registrationCodes.actions] createRegistrationCode(%j, ###)`, data);

  return async function(dispatch) {
    dispatch(registrationCodeCreateRequest(data));

    // call API
    const response = await api.postRegistrationCodes(data);
    let success = false;

    // post registration codes success
    if (201 === response.get('status')) {

      Logger.log('info', `POST API registration codes success. Post: ${response.getIn(['data', 'registration_code', 'id'])}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'registration_code'])], [schema.registrationCode]);
      const data = {
        id: response.getIn(['data', 'registration_code', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(registrationCodeCreateSuccess(data));
      success = true;
      
    // get registration codes failure
    } else {
      Logger.log('info', `POST API registration codes failure.`);
      dispatch(registrationCodeCreateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function deleteRegistrationCode(id, cb=function(){}) {
  Logger.log('debug', `[registrationCodes.actions] deleteRegistrationCode(${id}, ###)`);

  return async function(dispatch) {
    dispatch(registrationCodeDeleteRequest(id));

    // call API
    const response = await api.deleteRegistrationCode(id);
    let success = false;

    // delete registration code success
    if (204 === response.get('status')) {

      Logger.log('info', `DELETE API registration code success. ID: ${id}.`);

      dispatch(removeEntity({entityType: 'registration_codes', id: id}));
      dispatch(registrationCodeDeleteSuccess(id));
      success = true;
      
    // get registration code failure
    } else {
      Logger.log('info', `DELETE API registration code failure. ID: ${id}.`);
      dispatch(registrationCodeDeleteFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function downloadRegistrationCodesCSV(order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[state.registrationCodes.actions] downloadRegistrationCodesCSV(${order}, ${filter}, ###)`);

  return async function(dispatch) {
    dispatch(registrationCodeCSVDownloadRequest(order, filter));

    // call API
    const response = await api.getRegistrationCodesCSV(order, filter);
    let success = false;
  
    // get registration codes CSV success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API registration codes CSV success. Order: ${order}`);
      const now = new Date();

      // trigger browser download
      const url = window.URL.createObjectURL(new Blob([response.get('data')]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `cv-registration-codes-${Format.date(now, 'YYYY-MM-DDTHHmm')}.csv`);
      document.body.appendChild(link);
      link.click();

      dispatch(registrationCodeCSVDownloadSuccess());
      success = true;

    // get registration codes CSV failure
    } else {
      Logger.log('info', `Get API registration codes CSV failure.`);
      dispatch(registrationCodeCSVDownloadFailure());
    }

    // callback function
    cb(success);
  }
}

Logger.log('silly', `registrationCodes.actions loaded.`);
