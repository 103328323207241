import {normalize} from 'normalizr';

import api from '../../api';
import {schema} from '../../schema';
import {addEntities, removeEntity} from '../../actions';
import Format from '../../../lib/Format';
import Logger from '../../../lib/Logger';

// ACTION TYPES

export const RELATIONSHIP_LIST_REQUEST = 'RELATIONSHIP_LIST_REQUEST';
export const RELATIONSHIP_LIST_SUCCESS = 'RELATIONSHIP_LIST_SUCCESS';
export const RELATIONSHIP_LIST_FAILURE = 'RELATIONSHIP_LIST_FAILURE';
export const RELATIONSHIP_READ_REQUEST = 'RELATIONSHIP_READ_REQUEST';
export const RELATIONSHIP_READ_SUCCESS = 'RELATIONSHIP_READ_SUCCESS';
export const RELATIONSHIP_READ_FAILURE = 'RELATIONSHIP_READ_FAILURE';
export const RELATIONSHIP_UPDATE_REQUEST = 'RELATIONSHIP_UPDATE_REQUEST';
export const RELATIONSHIP_UPDATE_SUCCESS = 'RELATIONSHIP_UPDATE_SUCCESS';
export const RELATIONSHIP_UPDATE_FAILURE = 'RELATIONSHIP_UPDATE_FAILURE';
export const RELATIONSHIP_CREATE_REQUEST = 'RELATIONSHIP_CREATE_REQUEST';
export const RELATIONSHIP_CREATE_SUCCESS = 'RELATIONSHIP_CREATE_SUCCESS';
export const RELATIONSHIP_CREATE_FAILURE = 'RELATIONSHIP_CREATE_FAILURE';
export const RELATIONSHIP_DELETE_REQUEST = 'RELATIONSHIP_DELETE_REQUEST';
export const RELATIONSHIP_DELETE_SUCCESS = 'RELATIONSHIP_DELETE_SUCCESS';
export const RELATIONSHIP_DELETE_FAILURE = 'RELATIONSHIP_DELETE_FAILURE';
export const RELATIONSHIP_FORM_DESTROY = 'RELATIONSHIP_FORM_DESTROY';
export const RELATIONSHIP_CSV_DOWNLOAD_REQUEST = 'RELATIONSHIP_CSV_DOWNLOAD_REQUEST';
export const RELATIONSHIP_CSV_DOWNLOAD_SUCCESS = 'RELATIONSHIP_CSV_DOWNLOAD_SUCCESS';
export const RELATIONSHIP_CSV_DOWNLOAD_FAILURE = 'RELATIONSHIP_CSV_DOWNLOAD_FAILURE';

// ACTION CREATORS

export function relationshipListRequest(page, limit, order, filter) {
  Logger.log('debug', `[relationships.actions] relationshipListRequest(${page}, ${limit}, ${order}, %j)`, filter);
  return {
    type: RELATIONSHIP_LIST_REQUEST,
    page: page,
    limit: limit,
    order: order,
    filter: filter
  }
}

export function relationshipListSuccess(data) {
  Logger.log('debug', `[relationships.actions] relationshipListSuccess(%j)`, data);
  return {
    type: RELATIONSHIP_LIST_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now()
  }
}

export function relationshipListFailure(error) {
  Logger.log('debug', `[relationships.actions] relationshipListFailure(%j)`, error);
  return {
    type: RELATIONSHIP_LIST_FAILURE,
    error: error
  }
}

export function relationshipReadRequest(id) {
  Logger.log('debug', `[relationships.actions] relationshipReadRequest(${id})`);
  return {
    type: RELATIONSHIP_READ_REQUEST,
    id: id
  }
}

export function relationshipReadSuccess(data) {
  Logger.log('debug', `[relationships.actions] relationshipReadSuccess(%j)`, data);
  return {
    type: RELATIONSHIP_READ_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function relationshipReadFailure(error) {
  Logger.log('debug', `[relationships.actions] relationshipReadFailure(%j)`, error);
  return {
    type: RELATIONSHIP_READ_FAILURE,
    error: error
  }
}

export function relationshipUpdateRequest(id, data) {
  Logger.log('debug', `[relationships.actions] relationshipUpdateRequest(${id}, %j)`, data);
  return {
    type: RELATIONSHIP_UPDATE_REQUEST,
  }
}

export function relationshipUpdateSuccess(data) {
  Logger.log('debug', `[relationships.actions] relationshipUpdateSuccess(%j)`, data);
  return {
    type: RELATIONSHIP_UPDATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function relationshipUpdateFailure(error) {
  Logger.log('debug', `[relationships.actions] relationshipUpdateFailure(%j)`, error);
  return {
    type: RELATIONSHIP_UPDATE_FAILURE,
    error: error
  }
}

export function relationshipCreateRequest(data) {
  Logger.log('debug', `[relationships.actions] relationshipCreateRequest(%j)`, data);
  return {
    type: RELATIONSHIP_CREATE_REQUEST,
  }
}

export function relationshipCreateSuccess(data) {
  Logger.log('debug', `[relationships.actions] relationshipCreateSuccess(%j)`, data);
  return {
    type: RELATIONSHIP_CREATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function relationshipCreateFailure(error) {
  Logger.log('debug', `[relationships.actions] relationshipCreateFailure(%j)`, error);
  return {
    type: RELATIONSHIP_CREATE_FAILURE,
    error: error
  }
}

export function relationshipDeleteRequest(id) {
  Logger.log('debug', `[relationships.actions] relationshipDeleteRequest(${id})`);
  return {
    type: RELATIONSHIP_DELETE_REQUEST,
    id: id
  }
}

export function relationshipDeleteSuccess(id) {
  Logger.log('debug', `[relationships.actions] relationshipDeleteSuccess(${id})`);
  return {
    type: RELATIONSHIP_DELETE_SUCCESS,
    id: id,
  }
}

export function relationshipDeleteFailure(error) {
  Logger.log('debug', `[relationships.actions] relationshipDeleteFailure(%j)`, error);
  return {
    type: RELATIONSHIP_DELETE_FAILURE,
    error: error
  }
}

export function relationshipFormDestroy(formState=null) {
  Logger.log('debug', `[relationships.actions] relationshipFormDestroy(%j)`, formState);
  return {
    type: RELATIONSHIP_FORM_DESTROY,
    form: formState
  }
}

export function relationshipCSVDownloadRequest(order, filter) {
  Logger.log('debug', `[state.relationships.actions] relationshipCSVDownloadRequest()`);
  return {
    type: RELATIONSHIP_CSV_DOWNLOAD_REQUEST,
    order: order,
    filter: filter,
  }
}

export function relationshipCSVDownloadSuccess() {
  Logger.log('debug', `[state.relationships.actions] relationshipCSVDownloadSuccess()`);
  return {
    type: RELATIONSHIP_CSV_DOWNLOAD_SUCCESS,
  }
}

export function relationshipCSVDownloadFailure() {
  Logger.log('debug', `[state.relationships.actions] relationshipCSVDownloadFailure()`);
  return {
    type: RELATIONSHIP_CSV_DOWNLOAD_FAILURE,
  }
}


// API THUNK ACTION CREATORS

export function loadRelationships(page=1, limit=10, order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[relationships.actions] loadRelationships(${page}, ${limit}, ${order}, %j, ###)`, filter);

  return async function(dispatch) {
    dispatch(relationshipListRequest(page, limit, order, filter));

    // call API
    const response = await api.getRelationships(page, limit, order, filter);
    let success = false;

    // get relationship list success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API relationship list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`);

      const normalizedEntities = normalize(response.getIn(['data', 'relationships']), [schema.relationship]);
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(relationshipListSuccess(data));
      success = true;

    } else if (1 === page && 204 === response.get('status')) {

      Logger.log('info', `Get API relationship success [empty]. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: []
      };
      dispatch(relationshipListSuccess(data));
      success = true;
      
    // get relationship list failure
    } else {
      Logger.log('info', `Get API relationship list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      dispatch(relationshipListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function loadRelationship(id, cb=function(){}) {
  Logger.log('debug', `[relationships.actions] loadRelationship(${id}, ###)`);

  return async function(dispatch) {
    dispatch(relationshipReadRequest(id));

    // call API
    const response = await api.getRelationship(id);
    let success = false;

    // get relationship success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API relationship success. ID: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'relationship'])], [schema.relationship]);
      const data = {
        id: response.getIn(['data', 'relationship', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(relationshipReadSuccess(data));
      success = true;
      
    // get relationship failure
    } else {
      Logger.log('info', `Get API relationship failure. ID: ${id}.`);
      dispatch(relationshipReadFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function updateRelationship(id, data, cb=function(){}) {
  Logger.log('debug', `[relationships.actions] updateRelationship(${id}, %j, ###)`, data);

  return async function(dispatch) {
    dispatch(relationshipUpdateRequest(id, data));

    // call API
    const response = await api.putRelationship(id, data);
    let success = false;

    // put relationship success
    if (200 === response.get('status')) {

      Logger.log('info', `PUT API relationship success. User: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'relationship'])], [schema.relationship]);
      const data = {
        id: response.getIn(['data', 'relationship', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(relationshipUpdateSuccess(data));
      success = true;
      
    // get relationship failure
    } else {
      Logger.log('info', `PUT API relationship failure. ID: ${id}.`);
      dispatch(relationshipUpdateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function createRelationship(data, cb=function(){}) {
  Logger.log('debug', `[relationships.actions] createRelationship(%j, ###)`, data);

  return async function(dispatch) {
    dispatch(relationshipCreateRequest(data));

    // call API
    const response = await api.postRelationships(data);
    let success = false;

    // post relationship success
    if (201 === response.get('status')) {

      Logger.log('info', `POST API relationship success. Post: ${response.getIn(['data', 'relationship', 'id'])}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'relationship'])], [schema.relationship]);
      const data = {
        id: response.getIn(['data', 'relationship', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(relationshipCreateSuccess(data));
      success = true;
      
    // get relationship failure
    } else {
      Logger.log('info', `POST API relationship failure.`);
      dispatch(relationshipCreateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function deleteRelationship(id, cb=function(){}) {
  Logger.log('debug', `[relationships.actions] deleteRelationship(${id}, ###)`);

  return async function(dispatch) {
    dispatch(relationshipDeleteRequest(id));

    // call API
    const response = await api.deleteRelationship(id);
    let success = false;

    // delete relationship success
    if (204 === response.get('status')) {

      Logger.log('info', `DELETE API relationship success. ID: ${id}.`);

      dispatch(removeEntity({entityType: 'relationship', id: id}));
      dispatch(relationshipDeleteSuccess(id));
      success = true;
      
    // get relationship failure
    } else {
      Logger.log('info', `DELETE API relationship failure. ID: ${id}.`);
      dispatch(relationshipDeleteFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function downloadRelationshipsCSV(order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[state.relationships.actions] downloadRelationshipsCSV(${order}, ${filter}, ###)`);

  return async function(dispatch) {
    dispatch(relationshipCSVDownloadRequest(order, filter));

    // call API
    const response = await api.getRelationshipsCSV(order, filter);

    // get relationships CSV success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API relationships CSV success. Order: ${order}`);
      const now = new Date();

      // trigger browser download
      const url = window.URL.createObjectURL(new Blob([response.get('data')]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `cv-relationships-${Format.date(now, 'YYYY-MM-DDTHHmm')}.csv`);
      document.body.appendChild(link);
      link.click();

      dispatch(relationshipCSVDownloadSuccess());
      
    // get relationships CSV failure
    } else {
      Logger.log('info', `Get API relationships CSV failure.`);
      dispatch(relationshipCSVDownloadFailure());
    }

    // callback function
    cb();
  }
}

Logger.log('silly', `relationships.actions loaded.`);
