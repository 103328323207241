import {normalize} from 'normalizr';

import api from '../../api';
import {schema} from '../../schema';
import {addEntities, removeEntity} from '../../actions';
import Logger from '../../../lib/Logger';

export const CITY_LIST_REQUEST = 'CITY_LIST_REQUEST';
export const CITY_LIST_SUCCESS = 'CITY_LIST_SUCCESS';
export const CITY_LIST_FAILURE = 'CITY_LIST_FAILURE';
export const CITY_READ_REQUEST = 'CITY_READ_REQUEST';
export const CITY_READ_SUCCESS = 'CITY_READ_SUCCESS';
export const CITY_READ_FAILURE = 'CITY_READ_FAILURE';
export const CITY_CREATE_FAILURE = 'CITY_CREATE_FAILURE';
export const CITY_CREATE_REQUEST = 'CITY_CREATE_REQUEST';
export const CITY_CREATE_SUCCESS = 'CITY_CREATE_SUCCESS';
export const CITY_DELETE_REQUEST = 'CITY_DELETE_REQUEST';
export const CITY_DELETE_SUCCESS = 'CITY_DELETE_SUCCESS';
export const CITY_DELETE_FAILURE = 'CITY_DELETE_FAILURE';
export const CITY_UPDATE_REQUEST = 'CITY_UPDATE_REQUEST';
export const CITY_UPDATE_SUCCESS = 'CITY_UPDATE_SUCCESS';
export const CITY_UPDATE_FAILURE = 'CITY_UPDATE_FAILURE';
export const CITY_FORM_DESTROY = 'CITY_FORM_DESTROY';

export function cityListRequest(page, limit, order, filter) {
    Logger.log('debug', `[location.actions] cityListRequest(${page}, ${limit})`);
    return {
      type: CITY_LIST_REQUEST,
      page: page,
      limit: limit,
      order: order,
      filter: filter
    }
  }
  
  export function cityListSuccess(data) {
    Logger.log('debug', `[location.actions] cityListSuccess(%j)`, data);
    return {
      type: CITY_LIST_SUCCESS,
      page: data.page,
      limit: data.limit,
      order: data.order,
      result: data.result,
      total: data.total,
      receivedAt: Date.now()
    }
  }
  
  export function cityListFailure(error) {
    Logger.log('debug', `[location.actions] cityListFailure(%j)`, error);
    return {
      type: CITY_LIST_FAILURE,
      error: error
    }
  }
  
  export function cityCreateRequest(data) {
    Logger.log('debug', `[city.actions] cityCreateRequest(%j)`, data);
    return {
      type: CITY_CREATE_REQUEST,
    }
  }
  
  export function cityCreateSuccess(data) {
    Logger.log('debug', `[city.actions] cityCreateSuccess(%j)`, data);
    return {
      type: CITY_CREATE_SUCCESS,
      id: data.id,
      receivedAt: Date.now()
    }
  }
  
  export function cityCreateFailure(error) {
    Logger.log('debug', `[city.actions] cityCreateFailure(%j)`, error);
    return {
      type: CITY_CREATE_FAILURE,
      error: error
    }
  }
  
  export function cityReadRequest(id) {
    Logger.log('debug', `[city.actions] cityReadRequest(${id})`);
    return {
      type: CITY_READ_REQUEST,
      id: id
    }
  }
  
  export function cityReadSuccess(data) {
    Logger.log('debug', `[city.actions] cityReadSuccess(%j)`, data);
    return {
      type: CITY_READ_SUCCESS,
      id: data.id,
      receivedAt: Date.now()
    }
  }
  
  export function cityReadFailure(error) {
    Logger.log('debug', `[city.actions] cityReadFailure(%j)`, error);
    return {
      type: CITY_READ_FAILURE,
      error: error
    }
  }
  
  export function cityUpdateRequest(id, data) {
    Logger.log('debug', `[city.actions] cityUpdateRequest(${id}, %j)`, data);
    return {
      type: CITY_UPDATE_REQUEST,
    }
  }
  
  export function cityUpdateSuccess(data) {
    Logger.log('debug', `[city.actions] cityUpdateSuccess(%j)`, data);
    return {
      type: CITY_UPDATE_SUCCESS,
      id: data.id,
      receivedAt: Date.now()
    }
  }
  
  export function cityUpdateFailure(error) {
    Logger.log('debug', `[city.actions] cityUpdateFailure(%j)`, error);
    return {
      type: CITY_UPDATE_FAILURE,
      error: error
    }
  }
  
  export function  cityDeleteRequest(id) {
    Logger.log('debug', `[ city.actions] cityDeleteRequest(${id})`);
    return {
      type: CITY_DELETE_REQUEST,
      id: id
    }
  }
  
  export function cityDeleteSuccess(id) {
    Logger.log('debug', `[city.actions] cityDeleteSuccess(${id})`);
    return {
      type: CITY_DELETE_SUCCESS,
      id: id,
    }
  }
  
  export function cityDeleteFailure(error) {
    Logger.log('debug', `[city.actions] cityDeleteFailure(%j)`, error);
    return {
      type: CITY_DELETE_FAILURE,
      error: error
    }
  }
  
  export function cityFormDestroy(formState=null) {
    Logger.log('debug', `[city.actions] cityFormDestroy(%j)`, formState);
    return {
      type: CITY_FORM_DESTROY,
      form: formState
    }
  }

  // API THUNK ACTION CREATORS

  export function loadCities(page=1, limit=250, order=null, filter=null, cb=function(){}) {
    Logger.log('debug', `[location.actions] loadCities(${page}, ${limit}, ${order}, %j, ###)`, filter);
  
    return async function(dispatch) {
      dispatch(cityListRequest(page, limit, order, filter));
  
      // call API
      const response = await api.getCities(page, limit, order, filter);
  
      // get cities list success
      if (200 === response.get('status')) {
  
        Logger.log('info', `Get API cities list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
  
        const normalizedCities = normalize(response.getIn(['data', 'cities']), [schema.city]);
  
        const citySuccessData = {
          page: response.getIn(['data', 'page']),
          limit: response.getIn(['data', 'limit']),
          order: order,
          total: response.getIn(['data', 'total']),
          result: normalizedCities.result
        };
  
        dispatch(addEntities(normalizedCities));
        dispatch(cityListSuccess(citySuccessData));
        
      // get cities list failure
      } else {
        Logger.log('info', `Get API cities list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
        dispatch(cityListFailure(response.getIn(['data', 'error'])));
      }
  
      // callback function
      cb();
    }
  }
  
  export function loadCity(id, cb=function(){}) {
    Logger.log('debug', `[city.actions] loadCity(${id}, ###)`);
  
    return async function(dispatch) {
      dispatch(cityReadRequest(id));
  
      // call API
      const response = await api.getCity(id);
      let success = false;
  
      // get city success
      if (200 === response.get('status')) {
  
        Logger.log('info', `Get API city success. ID: ${id}.`);
  
        const normalizedEntities = normalize([response.getIn(['data', 'city'])], [schema.city]);
        const data = {
          id: response.getIn(['data', 'city', 'id']),
        };
  
        dispatch(addEntities(normalizedEntities));
        dispatch(cityReadSuccess(data));
        success = true;
        
      // get city failure
      } else {
        Logger.log('info', `Get API city failure. ID: ${id}.`);
        dispatch(cityReadFailure(response.getIn(['data', 'error'])));
      }
  
      // callback function
      cb(success);
    }
  }
  
  export function createCity(data, cb=function(){}) {
    Logger.log('debug', `[city.actions] createCity(%j, ###)`, data);
  
    return async function(dispatch) {
      dispatch(cityCreateRequest(data));
  
      // call API
      const response = await api.postCity(data);
      let success = false;
  
      // post city success
      if (201 === response.get('status')) {
  
        Logger.log('info', `POST API city success. Post: ${response.getIn(['data', 'city', 'id'])}.`);
  
        const normalizedEntities = normalize([response.getIn(['data', 'city'])], [schema.city]);
        const data = {
          id: response.getIn(['data', 'city', 'id']),
        };
        dispatch(addEntities(normalizedEntities));
        dispatch(cityCreateSuccess(data));
        success = true;
        
      // get cities failure
      } else {
        Logger.log('info', `POST API city failure.`);
        dispatch(cityCreateFailure(response.getIn(['data', 'error'])));
      }
  
      // callback function
      cb(success);
    }
  }
  
  export function updateCity(id, data, cb=function(){}) {
    Logger.log('debug', `[city.actions] updateCity(${id}, %j, ###)`, data);
  
    return async function(dispatch) {
      dispatch(cityUpdateRequest(id, data));
  
      // call API
      const response = await api.putCity(id, data);
      let success = false;
  
      // put city success
      if (200 === response.get('status')) {
  
        Logger.log('info', `PUT API city success. User: ${id}.`);
  
        const normalizedEntities = normalize([response.getIn(['data', 'city'])], [schema.city]);
        const data = {
          id: response.getIn(['data', 'city', 'id']),
        };
  
        dispatch(addEntities(normalizedEntities));
        dispatch(cityUpdateSuccess(data));
        success = true;
        
      // get city failure
      } else {
        Logger.log('info', `PUT API city failure. ID: ${id}.`);
        dispatch(cityUpdateFailure(response.getIn(['data', 'error'])));
      }
  
      // callback function
      cb(success);
    }
  }
  
  export function deleteCity(id, cb=function(){}) {
    Logger.log('debug', `[locations.actions] deleteCity(${id}, ###)`);
  
    return async function(dispatch) {
      dispatch(cityDeleteRequest(id));
  
      // call API
      const response = await api.deleteCity(id);
      let success = false;
  
      // delete city post success
      if (204 === response.get('status')) {
  
        Logger.log('info', `DELETE API city success. ID: ${id}.`);
  
        dispatch(removeEntity({entityType: 'city', id: id}));
        dispatch(cityDeleteSuccess(id));
        success = true;
        
      // get city failure
      } else {
        Logger.log('info', `DELETE API city post failure. ID: ${id}.`);
        dispatch(cityDeleteFailure(response.getIn(['data', 'error'])));
      }
  
      // callback function
      cb(success);
    }
  }