import React, {useEffect} from 'react';
import {withRouter} from "react-router";
import {Translation, getI18n} from 'react-i18next';
import {Table, Tag} from 'antd';

import ListActions from '../../../elements/components/ListActions';
import {getColumnSearchProps, getColumnDateRangeProps} from '../../../elements/components/TableColumnFilters';
import {BooleanTag, StatusTag} from '../../../elements/components/Tags';
import {pathTo} from '../../../Routes';
import Config from '../../../../../Config';
import Format from '../../../../../lib/Format';
import QueryString from '../../../../../lib/QueryString';
import Logger from '../../../../../lib/Logger';

const CalendarEventsList = ({component, page, limit, order, filter, total, load, remove, history, ...props}) => {

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters, dataIndex) => {
    clearFilters();
    delete filter[dataIndex];
    history.push(QueryString.append(props.location.pathname, {order, ...filter}));
  };
  
  const columns = [
    {
      title: getI18n().t('calendar_event_title'),
      dataIndex: 'title',
      key: 'title',
      ...getColumnSearchProps(
        'title',
        handleSearch,
        handleReset,
        getI18n().t('calendar_event_title'),
        filter
      ),
    },
    {
      title: getI18n().t('calendar_event_owner_name'),
      dataIndex: 'owner_name',
      key: 'owner_name',
      ...getColumnSearchProps(
        'owner_name',
        handleSearch,
        handleReset,
        getI18n().t('calendar_event_owner_name'),
        filter
      ),
    },
    {
      title: getI18n().t('calendar_event_care_guide_name'),
      dataIndex: 'care_guide_name',
      key: 'care_guide_name',
      ...getColumnSearchProps(
        'care_guide_name',
        handleSearch,
        handleReset,
        getI18n().t('calendar_event_care_guide_name'),
        filter
      ),
    },
    {
      title: getI18n().t('calendar_event_partnership_name'),
      dataIndex: 'partnership_name',
      key: 'partnership',
      ...getColumnSearchProps(
        'partnership',
        handleSearch,
        handleReset,
        getI18n().t('calendar_event_partnership_name'),
        filter
      ),
    },
    {
      title: getI18n().t('calendar_event_partnership_plan'),
      dataIndex: 'partnership_plan',
      key: 'plan',
      ...getColumnSearchProps(
        'plan',
        handleSearch,
        handleReset,
        getI18n().t('calendar_event_partnership_plan'),
        filter
      ),
    },
    {
      title: getI18n().t('calendar_event_profile'),
      dataIndex: 'profile_username',
      key: 'username',
      ...getColumnSearchProps('username', handleSearch, handleReset, getI18n().t('calendar_event_profile'), filter),
    },
    {
      title: getI18n().t('calendar_event_start_at'),
      dataIndex: 'start_at',
      key: 'start_at',
      render: date => Format.date(date, Config.get('DEFAULT_DATETIME_FORMAT')),
      defaultSortOrder: order === 'start_at.asc' ? 'ascend' : (order === 'start_at.desc' ? 'descend' : null),
      sorter: true,
      ...getColumnDateRangeProps('start_at', handleSearch, handleReset, filter),
    },
    {
      title: getI18n().t('calendar_event_end_at'),
      dataIndex: 'end_at',
      key: 'end_at',
      render: date => Format.date(date, Config.get('DEFAULT_DATETIME_FORMAT')),
      defaultSortOrder: order === 'end_at.asc' ? 'ascend' : (order === 'end_at.desc' ? 'descend' : null),
      sorter: true,
      ...getColumnDateRangeProps('end_at', handleSearch, handleReset, filter),
    },
    {
      title: getI18n().t('calendar_event_recurring'),
      dataIndex: 'recurring',
      key: 'recurring',
      filters: [
        {text: getI18n().t('calendar_event_recurring_type_1'), value: 1},
        {text: getI18n().t('calendar_event_recurring_type_2'), value: 2},
        {text: getI18n().t('calendar_event_recurring_type_3'), value: 3},
        {text: getI18n().t('calendar_event_recurring_type_4'), value: 4},
        {text: getI18n().t('calendar_event_recurring_type_5'), value: 5},
      ],
      filteredValue: 'recurring' in filter ? filter['recurring'].split(',') : null,
      render: val => <Tag>{getI18n().t('calendar_event_recurring_type_' + val)}</Tag>,
    },
    {
      title: getI18n().t('calendar_event_is_all_day'),
      dataIndex: 'is_all_day',
      key: 'is_all_day',
      filters: [
        {text: getI18n().t('boolean_true'), value: 1},
        {text: getI18n().t('boolean_false'), value: 0},
      ],
      filteredValue: 'is_all_day' in filter ? filter['is_all_day'].split(',') : null,
      render: val => <BooleanTag value={val} />,
    },
    {
      title: getI18n().t('table_header_status'),
      dataIndex: 'status',
      key: 'status',
      filters: [
        {text: getI18n().t('status_enabled'), value: 1},
        {text: getI18n().t('status_disabled'), value: 2},
        {text: getI18n().t('status_archived'), value: 3},
        {text: getI18n().t('status_deleted'), value: 4},
        {text: getI18n().t('status_pending'), value: 5},
      ],
      filteredValue: 'status' in filter ? filter['status'].split(',') : null,
      render: code => <StatusTag status={code} />,
    },
    {
      title: getI18n().t('table_header_updated_at'),
      dataIndex: 'updated_at',
      key: 'updated_at',
      render: date => Format.date(date),
      defaultSortOrder: order === 'updated_at.asc' ? 'ascend' : (order === 'updated_at.desc' ? 'descend' : null),
      sorter: true,
      ...getColumnDateRangeProps('updated_at', handleSearch, handleReset, filter),
    },
    {
      title: getI18n().t('table_header_created_at'),
      dataIndex: 'created_at',
      key: 'created_at',
      render: date => Format.date(date),
      defaultSortOrder: order === 'created_at.asc' ? 'ascend' : (order === 'created_at.desc' ? 'descend' : null),
      sorter: true,
      ...getColumnDateRangeProps('created_at', handleSearch, handleReset, filter),
    },
    {
      title: getI18n().t('table_header_actions'),
      dataIndex: 'id',
      key: 'actions',
      render: id =>
        <ListActions
          id={id}
          page={page}
          limit={limit}
          order={order}
          filter={filter}
          load={load.bind(this)}
          remove={remove.bind(this)}
          editScreen="CalendarEventEditScreen"
        />,
    },
  ];

  const pagination = {
    current: page,
    pageSize: limit,
    total: total,
    showSizeChanger: false,
    position: ['bottomCenter'],
  }

  const handleTableChange = (pagination, filters, sorter) => {

    let path = props.location.pathname;
    const params = {};

    // handle pagination
    if ('current' in pagination && pagination['current']) {
      path = pathTo(component, {page: pagination['current']});
    }

    // handle sorting
    if ('field' in sorter && 'order' in sorter) {
      if (sorter['field'] && sorter['order']) {
        const order = sorter['field'] + '.' + (sorter['order'] === 'ascend' ? 'asc' : 'desc');
        params['order'] = order;
      }
    }

    // handle filters
    if (filters) {
      for (const key in filters) {
        if (filters[key]) {
          params[key] = filters[key].join(',');
        }
      }
    }

    history.push(QueryString.append(path, params));
  }

  const filterString = JSON.stringify(filter);
  useEffect(() => {
    load(page, limit, order, JSON.parse(filterString));
  }, [page, limit, order, filterString, load]);

  return (
    <Translation>{(t) => 

      <Table
        dataSource={props.list}
        columns={columns}
        pagination={page === 1 && total <= limit  ? false : pagination}
        loading={props.isLoading}
        scroll={{x: true}}
        onChange={handleTableChange}
      />

    }</Translation>
  );
}

export default withRouter(CalendarEventsList);

Logger.log('silly', `CalendarEventsList loaded.`);
