import { connect } from 'react-redux';
import { denormalize } from 'normalizr';

import { schema } from '../../../../../state/schema';
import {
  loadPartnershipFormAnswer,
  deletePartnershipFormAnswer,
} from '../../../../../state/modules/partnershipFormAnswers/actions';
import PartnershipFormAnswerViewScreen from '../components/PartnershipFormAnswerViewScreen';

const mapStateToProps = (state, ownProps) => {
  const partnershipFormAnswer = ownProps.match.params.id
    ? denormalize(
        state.entities.getIn([
          'partnershipFormAnswers',
          ownProps.match.params.id,
        ]),
        schema.partnershipFormAnswer,
        state.entities.toJS()
      )
    : null;

  return {
    isLoading: state.partnershipFormAnswers.get('isLoading'),
    partnershipFormAnswer: partnershipFormAnswer,
    id: ownProps.match.params.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (id, cb) => {
      dispatch(loadPartnershipFormAnswer(id, cb));
    },
    remove: (id, cb) => {
      dispatch(deletePartnershipFormAnswer(id, cb));
    },
  };
};

const PartnershipFormAnswerViewScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnershipFormAnswerViewScreen);

export default PartnershipFormAnswerViewScreenContainer;
