import {connect} from 'react-redux';
import {denormalize} from 'normalizr';

import {schema} from '../../../../../../state/schema';
import {loadCountries, deleteCountry} from '../../../../../../state/modules/locationCountries/actions';
import {sendMessage} from '../../../../../../state/actions';
import CountriesList from '../components/CountriesList';

const mapStateToProps = (state, ownProps) => {

  const page = ownProps.page;
  const limit = ownProps.limit;
  const order = ownProps.order;
  const result = state.locationCountries.getIn(['pages', order, limit, page]);
  return {
    list: result 
      ? result.map(x => {
          return {
            key: x,
            ...denormalize(
              state.entities.getIn(['countries', x]),
              schema.country,
              state.entities.toJS()
            )
          };
        }).toArray().map(x => {

          // flatten some nested objects to make table easier to work with
          return {
            ...x
          };
        })
      : [],
    isLoading: state.locationCountries.get('areCountriesLoading'),
    total: state.locationCountries.get('total'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (page, limit, order, filter, cb) => {
      dispatch(loadCountries(page,limit, order, filter, cb));
    },
    remove: (id, cb) => {
      dispatch(deleteCountry(id, cb));
    },
    sendMessage: (level, title, body, expires) => {
      dispatch(sendMessage(level, title, body, expires));
    },
  }
}

const CountryListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CountriesList);

export default CountryListContainer;
