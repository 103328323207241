import {connect} from 'react-redux';

import CalendarEventEditScreen from '../components/CalendarEventEditScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.calendarEvents.get('isLoading'),
    success: state.calendarEvents.getIn(['form', 'success']),
  }
}

const CalendarEventEditScreenContainer = connect(
  mapStateToProps
)(CalendarEventEditScreen);

export default CalendarEventEditScreenContainer;
