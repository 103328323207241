import {connect} from 'react-redux';
import {denormalize} from 'normalizr';

import {schema} from '../../../../../state/schema';
import {loadPartnershipMedia} from '../../../../../state/modules/media/actions';
import MediaInputPartnership from '../components/MediaInputPartnership';

const mapStateToProps = (state, ownProps) => {

  const partnershipId = ownProps.partnershipId;
  const page = ownProps.page || 1;
  const limit = ownProps.limit || 50;
  const order = ownProps.order || 'created_at.desc';

  const result = state.media.getIn(['partnerships', partnershipId, order, limit, page]);
  const media = result
    ? result.map(x => {
        return {
          key: x,
          ...denormalize(
            state.entities.getIn(['media', x]),
            schema.medium,
            state.entities.toJS()
          )
        };
      }).toArray()
    : [];

  return {
    options: media.map(x => {
      return {label: x.file_name + '.' + x.file_extension, value: x.id};
    }),
    isLoading: state.media.get('isPartnershipMediaLoading'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (partnershipId, page, limit, order, filter, cb) => {
      dispatch(loadPartnershipMedia(partnershipId, page, limit, order, filter, cb));
    },
  }
}

const MediaInputPartnershipContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MediaInputPartnership);

export default MediaInputPartnershipContainer;
