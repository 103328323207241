import { normalize } from 'normalizr';

import api from '../../api';
import { schema } from '../../schema';
import { addEntities, removeEntity } from '../../actions';
import Format from '../../../lib/Format';
import Logger from '../../../lib/Logger';

// ACTION TYPES

export const CARE_GUIDE_LIST_REQUEST = 'CARE_GUIDE_LIST_REQUEST';
export const CARE_GUIDE_LIST_SUCCESS = 'CARE_GUIDE_LIST_SUCCESS';
export const CARE_GUIDE_LIST_FAILURE = 'CARE_GUIDE_LIST_FAILURE';
export const CARE_GUIDE_READ_REQUEST = 'CARE_GUIDE_READ_REQUEST';
export const CARE_GUIDE_READ_SUCCESS = 'CARE_GUIDE_READ_SUCCESS';
export const CARE_GUIDE_READ_FAILURE = 'CARE_GUIDE_READ_FAILURE';
export const CARE_GUIDE_UPDATE_REQUEST = 'CARE_GUIDE_UPDATE_REQUEST';
export const CARE_GUIDE_UPDATE_SUCCESS = 'CARE_GUIDE_UPDATE_SUCCESS';
export const CARE_GUIDE_UPDATE_FAILURE = 'CARE_GUIDE_UPDATE_FAILURE';
export const CARE_GUIDE_CREATE_REQUEST = 'CARE_GUIDE_CREATE_REQUEST';
export const CARE_GUIDE_CREATE_SUCCESS = 'CARE_GUIDE_CREATE_SUCCESS';
export const CARE_GUIDE_CREATE_FAILURE = 'CARE_GUIDE_CREATE_FAILURE';
export const CARE_GUIDE_DELETE_REQUEST = 'CARE_GUIDE_DELETE_REQUEST';
export const CARE_GUIDE_DELETE_SUCCESS = 'CARE_GUIDE_DELETE_SUCCESS';
export const CARE_GUIDE_DELETE_FAILURE = 'CARE_GUIDE_DELETE_FAILURE';
export const CARE_GUIDE_FORM_DESTROY = 'CARE_GUIDE_FORM_DESTROY';
export const CARE_GUIDE_GEOCODE_REQUEST = 'CARE_GUIDE_GEOCODE_REQUEST';
export const CARE_GUIDE_GEOCODE_SUCCESS = 'CARE_GUIDE_GEOCODE_SUCCESS';
export const CARE_GUIDE_GEOCODE_FAILURE = 'CARE_GUIDE_GEOCODE_FAILURE';
export const CARE_GUIDE_CSV_DOWNLOAD_REQUEST =
  'CARE_GUIDE_CSV_DOWNLOAD_REQUEST';
export const CARE_GUIDE_CSV_DOWNLOAD_SUCCESS =
  'CARE_GUIDE_CSV_DOWNLOAD_SUCCESS';
export const CARE_GUIDE_CSV_DOWNLOAD_FAILURE =
  'CARE_GUIDE_CSV_DOWNLOAD_FAILURE';
export const CARE_GUIDE_SEND_CSV_REQUEST = 'CARE_GUIDE_SEND_CSV_REQUEST';
export const CARE_GUIDE_SEND_CSV_SUCCESS = 'CARE_GUIDE_SEND_CSV_SUCCESS';
export const CARE_GUIDE_SEND_CSV_FAILURE = 'CARE_GUIDE_SEND_CSV_FAILURE';

// ACTION CREATORS

export function careGuideListRequest(page, limit, order, filter) {
  Logger.log(
    'debug',
    `[careGuides.actions] careGuideListRequest(${page}, ${limit}, ${order}, %j)`,
    filter
  );
  return {
    type: CARE_GUIDE_LIST_REQUEST,
    page: page,
    limit: limit,
    order: order,
    filter: filter,
  };
}

export function careGuideListSuccess(data) {
  Logger.log('debug', `[careGuides.actions] careGuideListSuccess(%j)`, data);
  return {
    type: CARE_GUIDE_LIST_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now(),
  };
}

export function careGuideListFailure(error) {
  Logger.log('debug', `[careGuides.actions] careGuideListFailure(%j)`, error);
  return {
    type: CARE_GUIDE_LIST_FAILURE,
    error: error,
  };
}

export function careGuideReadRequest(id) {
  Logger.log('debug', `[careGuides.actions] careGuideReadRequest(${id})`);
  return {
    type: CARE_GUIDE_READ_REQUEST,
    id: id,
  };
}

export function careGuideReadSuccess(data) {
  Logger.log('debug', `[careGuides.actions] careGuideReadSuccess(%j)`, data);
  return {
    type: CARE_GUIDE_READ_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function careGuideReadFailure(error) {
  Logger.log('debug', `[careGuides.actions] careGuideReadFailure(%j)`, error);
  return {
    type: CARE_GUIDE_READ_FAILURE,
    error: error,
  };
}

export function careGuideUpdateRequest(id, data) {
  Logger.log(
    'debug',
    `[careGuides.actions] careGuideUpdateRequest(${id}, %j)`,
    data
  );
  return {
    type: CARE_GUIDE_UPDATE_REQUEST,
  };
}

export function careGuideUpdateSuccess(data) {
  Logger.log('debug', `[careGuides.actions] careGuideUpdateSuccess(%j)`, data);
  return {
    type: CARE_GUIDE_UPDATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function careGuideUpdateFailure(error) {
  Logger.log('debug', `[careGuides.actions] careGuideUpdateFailure(%j)`, error);
  return {
    type: CARE_GUIDE_UPDATE_FAILURE,
    error: error,
  };
}

export function careGuideCreateRequest(data) {
  Logger.log('debug', `[careGuides.actions] careGuideCreateRequest(%j)`, data);
  return {
    type: CARE_GUIDE_CREATE_REQUEST,
  };
}

export function careGuideCreateSuccess(data) {
  Logger.log('debug', `[careGuides.actions] careGuideCreateSuccess(%j)`, data);
  return {
    type: CARE_GUIDE_CREATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function careGuideCreateFailure(error) {
  Logger.log('debug', `[careGuides.actions] careGuideCreateFailure(%j)`, error);
  return {
    type: CARE_GUIDE_CREATE_FAILURE,
    error: error,
  };
}

export function careGuideDeleteRequest(id) {
  Logger.log('debug', `[careGuides.actions] careGuideDeleteRequest(${id})`);
  return {
    type: CARE_GUIDE_DELETE_REQUEST,
    id: id,
  };
}

export function careGuideDeleteSuccess(id) {
  Logger.log('debug', `[careGuides.actions] careGuideDeleteSuccess(${id})`);
  return {
    type: CARE_GUIDE_DELETE_SUCCESS,
    id: id,
  };
}

export function careGuideDeleteFailure(error) {
  Logger.log('debug', `[careGuides.actions] careGuideDeleteFailure(%j)`, error);
  return {
    type: CARE_GUIDE_DELETE_FAILURE,
    error: error,
  };
}

export function careGuideFormDestroy(formState = null) {
  Logger.log(
    'debug',
    `[careGuides.actions] careGuideFormDestroy(%j)`,
    formState
  );
  return {
    type: CARE_GUIDE_FORM_DESTROY,
    form: formState,
  };
}

export function careGuideGeocodeRequest(location) {
  Logger.log(
    'debug',
    `[careGuides.actions] careGuideGeocodeRequest(${location})`
  );
  return {
    type: CARE_GUIDE_GEOCODE_REQUEST,
    location: location,
  };
}

export function careGuideGeocodeSuccess(data) {
  Logger.log('debug', `[careGuides.actions] careGuideGeocodeSuccess(%j)`, data);
  return {
    type: CARE_GUIDE_GEOCODE_SUCCESS,
    location: data.location,
    latitude: data.latitude,
    longitude: data.longitude,
    receivedAt: Date.now(),
  };
}

export function careGuideGeocodeFailure(error) {
  Logger.log(
    'debug',
    `[careGuides.actions] careGuideGeocodeFailure(%j)`,
    error
  );
  return {
    type: CARE_GUIDE_GEOCODE_FAILURE,
    error: error,
  };
}

export function careGuideCSVDownloadRequest(order, filter, partnershipId) {
  Logger.log(
    'debug',
    `[state.careGuides.actions] careGuideCSVDownloadRequest()`
  );
  return {
    type: CARE_GUIDE_CSV_DOWNLOAD_REQUEST,
    order: order,
    filter: filter,
    partnershipId: partnershipId,
  };
}

export function careGuideCSVDownloadSuccess() {
  Logger.log(
    'debug',
    `[state.careGuides.actions] careGuideCSVDownloadSuccess()`
  );
  return {
    type: CARE_GUIDE_CSV_DOWNLOAD_SUCCESS,
  };
}

export function careGuideCSVDownloadFailure() {
  Logger.log(
    'debug',
    `[state.careGuides.actions] careGuideCSVDownloadFailure()`
  );
  return {
    type: CARE_GUIDE_CSV_DOWNLOAD_FAILURE,
  };
}

export function sendCsvRequest() {
  Logger.log('debug', `[state.careGuides.actions] sendCsvRequest()`);
  return {
    type: CARE_GUIDE_SEND_CSV_REQUEST,
  };
}

export function sendCsvSuccess() {
  Logger.log('debug', `[state.careGuides.actions] sendCsvSuccess()`);
  return {
    type: CARE_GUIDE_SEND_CSV_SUCCESS,
  };
}

export function sendCsvFailure(error) {
  Logger.log('debug', `[state.careGuides.actions] sendCsvFailure(%j)`, error);
  return {
    type: CARE_GUIDE_SEND_CSV_FAILURE,
    error: error,
  };
}

// API THUNK ACTION CREATORS

export function loadCareGuides(
  page = 1,
  limit = 10,
  order = null,
  filter = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[careGuides.actions] loadCareGuides(${page}, ${limit}, ${order}, %j, ###)`,
    filter
  );

  return async function (dispatch) {
    dispatch(careGuideListRequest(page, limit, order, filter));

    // call API
    const response = await api.getCareGuides(page, limit, order, filter);
    let success = false;
    let result = [];

    // get care guides list success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API care guides list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );

      const normalizedEntities = normalize(
        response.getIn(['data', 'care_guides']),
        [schema.careGuide]
      );
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result,
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideListSuccess(data));
      success = true;
      result = normalizedEntities.result;
    } else if (1 === page && 204 === response.get('status')) {
      Logger.log(
        'info',
        `Get API care guides success [empty]. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: [],
      };
      dispatch(careGuideListSuccess(data));
      success = true;

      // get care guides list failure
    } else {
      Logger.log(
        'info',
        `Get API care guides list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      dispatch(careGuideListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success, result);
  };
}

export function loadCareGuide(id, cb = function () {}) {
  Logger.log('debug', `[careGuides.actions] loadCareGuide(${id}, ###)`);

  return async function (dispatch) {
    dispatch(careGuideReadRequest(id));

    // call API
    const response = await api.getCareGuide(id);
    let success = false;

    // get care guide success
    if (200 === response.get('status')) {
      Logger.log('info', `Get API care guide success. ID: ${id}.`);

      const normalizedEntities = normalize(
        [response.getIn(['data', 'care_guide'])],
        [schema.careGuide]
      );
      const data = {
        id: response.getIn(['data', 'care_guide', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideReadSuccess(data));
      success = true;

      // get care guide failure
    } else {
      Logger.log('info', `Get API care guide failure. ID: ${id}.`);
      dispatch(careGuideReadFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function updateCareGuide(id, data, cb = function () {}) {
  Logger.log(
    'debug',
    `[careGuides.actions] updateCareGuide(${id}, %j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(careGuideUpdateRequest(id, data));

    // call API
    const response = await api.putCareGuide(id, data);
    let success = false;

    // put care guide success
    if (200 === response.get('status')) {
      Logger.log('info', `PUT API care guide success. User: ${id}.`);

      const normalizedEntities = normalize(
        [response.getIn(['data', 'care_guide'])],
        [schema.careGuide]
      );
      const data = {
        id: response.getIn(['data', 'care_guide', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideUpdateSuccess(data));
      success = true;

      // get care guide failure
    } else {
      Logger.log('info', `PUT API care guide failure. ID: ${id}.`);
      dispatch(careGuideUpdateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function createCareGuide(data, cb = function () {}) {
  Logger.log('debug', `[careGuides.actions] createCareGuide(%j, ###)`, data);

  return async function (dispatch) {
    dispatch(careGuideCreateRequest(data));

    // call API
    const response = await api.postCareGuides(data);
    let success = false;

    // post care guides success
    if (201 === response.get('status')) {
      Logger.log(
        'info',
        `POST API care guides success. Post: ${response.getIn([
          'data',
          'care_guide',
          'id',
        ])}.`
      );

      const normalizedEntities = normalize(
        [response.getIn(['data', 'care_guide'])],
        [schema.careGuide]
      );
      const data = {
        id: response.getIn(['data', 'care_guide', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideCreateSuccess(data));
      success = true;

      // get care guides failure
    } else {
      Logger.log('info', `POST API care guides failure.`);
      dispatch(careGuideCreateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function deleteCareGuide(id, cb = function () {}) {
  Logger.log('debug', `[careGuides.actions] deleteCareGuide(${id}, ###)`);

  return async function (dispatch) {
    dispatch(careGuideDeleteRequest(id));

    // call API
    const response = await api.deleteCareGuide(id);
    let success = false;

    // delete care guide success
    if (204 === response.get('status')) {
      Logger.log('info', `DELETE API care guide success. ID: ${id}.`);

      dispatch(removeEntity({ entityType: 'care_guides', id: id }));
      dispatch(careGuideDeleteSuccess(id));
      success = true;

      // get care guide failure
    } else {
      Logger.log('info', `DELETE API care guide failure. ID: ${id}.`);
      dispatch(careGuideDeleteFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function getGeocode(location, cb = function () {}) {
  Logger.log('debug', `[careGuides.actions] getGeocode(${location}, ###)`);

  return async function (dispatch) {
    dispatch(careGuideGeocodeRequest(location));

    // call API
    const response = await api.getGoogleMapsAPIGeocode(location);
    let success = false;
    let data = {};

    // get geocode success
    if (200 === response.get('status')) {
      Logger.log('info', `Get API geocode success.`);

      data = {
        location: location,
        latitude: response.getIn([
          'data',
          'results',
          0,
          'geometry',
          'location',
          'lat',
        ]),
        longitude: response.getIn([
          'data',
          'results',
          0,
          'geometry',
          'location',
          'lng',
        ]),
      };

      dispatch(careGuideGeocodeSuccess(data));
      success = true;

      // get geocode failure
    } else {
      Logger.log('info', `Get API geocode failure.`);
      dispatch(careGuideGeocodeFailure(response.getIn(['error_message'])));
    }

    // callback function
    cb(success, data);
  };
}

export function downloadCareGuidesCSV(
  partnershipId = null,
  order = null,
  filter = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.careGuides.actions] downloadCareGuidesCSV(${order}, ${filter}, ###)`
  );

  return async function (dispatch) {
    dispatch(careGuideCSVDownloadRequest(order, filter));

    // call API
    const response = await api.getCareGuidesCSV(order, filter, partnershipId);
    let success = false;

    // get care guides CSV success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API care guides CSV success. Order: ${order}. Partnership ID: ${partnershipId}`
      );
      const now = new Date();

      // trigger browser download
      const url = window.URL.createObjectURL(new Blob([response.get('data')]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `cv-care-guides-${Format.date(now, 'YYYY-MM-DDTHHmm')}.csv`
      );
      document.body.appendChild(link);
      link.click();

      dispatch(careGuideCSVDownloadSuccess());
      success = true;

      // get care guides CSV failure
    } else {
      Logger.log('info', `Get API care guides CSV failure.`);
      dispatch(careGuideCSVDownloadFailure());
    }

    // callback function
    cb(success);
  };
}

export function sendCSV(file, cb = function () {}) {
  Logger.log('debug', `[state.careGuides.actions] sendCSV(###, ###)`);

  return async function (dispatch) {
    dispatch(sendCsvRequest());

    // call API
    const response = await api.patchSendCsv(file);
    let success = false;
    let message = response.get('data')['care_guides_csv'] ?? "Send Error";
    // patch send csv success
    if (200 === response.get('status')) {      
      Logger.log('info', `PATCH send CSV success.`);
      success = true;
      dispatch(sendCsvSuccess());

      // patch send csv failure
    } else {
      Logger.log('info', `PATCH send CSV failure.`);
      dispatch(sendCsvFailure(message));
    }

    // callback function
    cb(success, message);
  };
}

Logger.log('silly', `careGuides.actions loaded.`);
