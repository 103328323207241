import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import { Translation, getI18n } from 'react-i18next';
import { Table } from 'antd';
import ListActions from '../../../elements/components/ListActions';
import {
  getColumnSearchProps,
  getColumnDateRangeProps,
} from '../../../elements/components/TableColumnFilters';
import { pathTo } from '../../../Routes';
import Format from '../../../../../lib/Format';
import QueryString from '../../../../../lib/QueryString';
import Logger from '../../../../../lib/Logger';
import { TypeSectionTag } from '../../../elements/components/Tags';

const DashboardSectionsList = ({
  component,
  page,
  limit,
  order,
  filter,
  total,
  load,
  remove,
  history,
  ...props
}) => {
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters, dataIndex) => {
    clearFilters();
    delete filter[dataIndex];
    history.push(
      QueryString.append(props.location.pathname, { order, ...filter })
    );
  };

  const columns = [
    {
      title: getI18n().t('dashboard_sections_partnership_name'),
      dataIndex: 'partnership_name',
      key: 'partnership',
      ...getColumnSearchProps(
        'partnership',
        handleSearch,
        handleReset,
        getI18n().t('dashboard_sections_partnership_name'),
        filter
      ),
    },
    {
      title: getI18n().t('dashboard_sections_type'),
      dataIndex: 'type',
      key: 'type',
      filters: [
        { text: getI18n().t('dashboard_section_type_resources'), value: 1 },
        { text: getI18n().t('dashboard_section_type_daily_logs'), value: 2 },
        { text: getI18n().t('dashboard_section_type_recommended'), value: 3 },
      ],
      filteredValue: 'type' in filter ? filter['type'].split(',') : null,
      render: (type) => <TypeSectionTag type={type} />,
    },
    {
      title: getI18n().t('dashboard_sections_order'),
      dataIndex: 'order',
      key: 'order',
      defaultSortOrder:
        order === 'order.asc'
          ? 'ascend'
          : order === 'order.desc'
          ? 'descend'
          : null,
      sorter: true,
    },
    {
      title: getI18n().t('table_header_updated_at'),
      dataIndex: 'updated_at',
      key: 'updated_at',
      render: (date) => Format.date(date),
      defaultSortOrder:
        order === 'updated_at.asc'
          ? 'ascend'
          : order === 'updated_at.desc'
          ? 'descend'
          : null,
      sorter: true,
      ...getColumnDateRangeProps(
        'updated_at',
        handleSearch,
        handleReset,
        filter
      ),
    },
    {
      title: getI18n().t('table_header_created_at'),
      dataIndex: 'created_at',
      key: 'created_at',
      render: (date) => Format.date(date),
      defaultSortOrder:
        order === 'created_at.asc'
          ? 'ascend'
          : order === 'created_at.desc'
          ? 'descend'
          : null,
      sorter: true,
      ...getColumnDateRangeProps(
        'created_at',
        handleSearch,
        handleReset,
        filter
      ),
    },
    {
      title: getI18n().t('table_header_actions'),
      key: 'actions',
      render: (item) => (
        <ListActions
          id={item.id}
          page={page}
          limit={limit}
          order={order}
          filter={filter}
          load={load.bind(this)}
          remove={remove.bind(this)}
          editScreen="DashboardSectionEditScreen"
        />
      ),
    },
  ];

  const pagination = {
    current: page,
    pageSize: limit,
    total: total,
    showSizeChanger: false,
    position: ['bottomCenter'],
  };

  const handleTableChange = (pagination, filters, sorter) => {
    let path = props.location.pathname;
    const params = {};

    // handle pagination
    if ('current' in pagination && pagination['current']) {
      path = pathTo(component, { page: pagination['current'] });
    }

    // handle sorting
    if ('field' in sorter && 'order' in sorter) {
      if (sorter['field'] && sorter['order']) {
        const order =
          sorter['field'] +
          '.' +
          (sorter['order'] === 'ascend' ? 'asc' : 'desc');
        params['order'] = order;
      }
    }

    // handle filters
    if (filters) {
      for (const key in filters) {
        if (filters[key]) {
          params[key] = filters[key].join(',');
        }
      }
    }

    history.push(QueryString.append(path, params));
  };

  const filterString = JSON.stringify(filter);
  useEffect(() => {
    load(page, limit, order, JSON.parse(filterString));
  }, [page, limit, order, filterString, load]);

  return (
    <Translation>
      {(t) => (
        <Table
          dataSource={props.list}
          columns={columns}
          pagination={page === 1 && total <= limit ? false : pagination}
          loading={props.isLoading}
          scroll={{ x: true }}
          onChange={handleTableChange}
        />
      )}
    </Translation>
  );
};

export default withRouter(DashboardSectionsList);

Logger.log('silly', `DashboardSectionsList loaded.`);
