import {connect} from 'react-redux';

import MessagesScreen from '../components/MessagesScreen';

const mapStateToProps = state => {
  return {
    total: state.messages.get('total'),
    isLoading: state.messages.get('isLoading')
  }
}

const MessagesScreenContainer = connect(
  mapStateToProps
)(MessagesScreen);

export default MessagesScreenContainer;
