import { connect } from 'react-redux';
import { denormalize } from 'normalizr';

import { schema } from '../../../../../state/schema';
import { loadAlert } from '../../../../../state/modules/alerts/actions';
import AlertViewScreen from '../components/AlertViewScreen';

const mapStateToProps = (state, ownProps) => {

  const id = ownProps.match.params.id;
  const alert = id
    ? denormalize(
        state.entities.getIn(['alerts', id]),
        schema.alert,
        state.entities.toJS()
      )
    : null;
    
  return {
    isLoading: state.alerts.get('isLoading'),
    alert: alert,
    id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (id, cb) => {
      dispatch(loadAlert(id, cb));
    },
  };
};

const AlertViewScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AlertViewScreen);

export default AlertViewScreenContainer;
