import {connect} from 'react-redux';

import PlanAddScreen from '../components/PlanAddScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.plans.get('isLoading'),
    success: state.plans.getIn(['form', 'success']),
  }
}

const PlanAddScreenContainer = connect(
  mapStateToProps
)(PlanAddScreen);

export default PlanAddScreenContainer;
