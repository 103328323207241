import { connect } from 'react-redux';

import CalendarEventExceptionsCSVButton from '../components/CalendarEventExceptionsCSVButton';

const mapStateToProps = (state) => {
  return {};
};

const CalendarEventExceptionsCSVButtonContainer = connect(mapStateToProps)(
  CalendarEventExceptionsCSVButton
);

export default CalendarEventExceptionsCSVButtonContainer;
