import {connect} from 'react-redux';
import {denormalize} from 'normalizr';

import {schema} from '../../../../../../state/schema';
import {loadSubRegions, deleteSubRegion} from '../../../../../../state/modules/locationSubRegions/actions';
import {sendMessage} from '../../../../../../state/actions';
import SubRegionsList from '../components/SubRegionsList';

const mapStateToProps = (state, ownProps) => {

  const page = ownProps.page;
  const limit = ownProps.limit;
  const order = ownProps.order;
  const result = state.locationSubRegions.getIn(['pages', order, limit, page]);
  return {
    list: result 
      ? result.map(x => {
          return {
            key: x,
            ...denormalize(
              state.entities.getIn(['subregions', x]),
              schema.subregion,
              state.entities.toJS()
            )
          };
        }).toArray().map(x => {

          // flatten some nested objects to make table easier to work with
          return {
            region_name: x.region ? x.region.name: '',
            ...x
          };
        })
      : [],
    isLoading: state.locationSubRegions.get('areSubRegionsLoading'),
    total: state.locationSubRegions.get('total'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (page, limit, order, filter, cb) => {
      dispatch(loadSubRegions(page,limit, order, filter, cb));
    },
    remove: (id, cb) => {
      dispatch(deleteSubRegion(id, cb));
    },
    sendMessage: (level, title, body, expires) => {
      dispatch(sendMessage(level, title, body, expires));
    },
  }
}

const SubRegionListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SubRegionsList);

export default SubRegionListContainer;
