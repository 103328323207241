import { normalize } from 'normalizr';

import api from '../../api';
import { schema } from '../../schema';
import { addEntities, removeEntity } from '../../actions';
import Format from '../../../lib/Format';
import Logger from '../../../lib/Logger';

// ACTION TYPES

export const CARE_GUIDE_NOTE_LIST_REQUEST = 'CARE_GUIDE_NOTE_LIST_REQUEST';
export const CARE_GUIDE_NOTE_LIST_SUCCESS = 'CARE_GUIDE_NOTE_LIST_SUCCESS';
export const CARE_GUIDE_NOTE_LIST_FAILURE = 'CARE_GUIDE_NOTE_LIST_FAILURE';
export const CARE_GUIDE_NOTE_READ_REQUEST = 'CARE_GUIDE_NOTE_READ_REQUEST';
export const CARE_GUIDE_NOTE_READ_SUCCESS = 'CARE_GUIDE_NOTE_READ_SUCCESS';
export const CARE_GUIDE_NOTE_READ_FAILURE = 'CARE_GUIDE_NOTE_READ_FAILURE';
export const CARE_GUIDE_NOTE_UPDATE_REQUEST = 'CARE_GUIDE_NOTE_UPDATE_REQUEST';
export const CARE_GUIDE_NOTE_UPDATE_SUCCESS = 'CARE_GUIDE_NOTE_UPDATE_SUCCESS';
export const CARE_GUIDE_NOTE_UPDATE_FAILURE = 'CARE_GUIDE_NOTE_UPDATE_FAILURE';
export const CARE_GUIDE_NOTE_CREATE_REQUEST = 'CARE_GUIDE_NOTE_CREATE_REQUEST';
export const CARE_GUIDE_NOTE_CREATE_SUCCESS = 'CARE_GUIDE_NOTE_CREATE_SUCCESS';
export const CARE_GUIDE_NOTE_CREATE_FAILURE = 'CARE_GUIDE_NOTE_CREATE_FAILURE';
export const CARE_GUIDE_NOTE_DELETE_REQUEST = 'CARE_GUIDE_NOTE_DELETE_REQUEST';
export const CARE_GUIDE_NOTE_DELETE_SUCCESS = 'CARE_GUIDE_NOTE_DELETE_SUCCESS';
export const CARE_GUIDE_NOTE_DELETE_FAILURE = 'CARE_GUIDE_NOTE_DELETE_FAILURE';
export const CARE_GUIDE_NOTE_FORM_DESTROY = 'CARE_GUIDE_NOTE_FORM_DESTROY';
export const CARE_GUIDE_NOTE_CSV_DOWNLOAD_REQUEST =
  'CARE_GUIDE_NOTE_CSV_DOWNLOAD_REQUEST';
export const CARE_GUIDE_NOTE_CSV_DOWNLOAD_SUCCESS =
  'CARE_GUIDE_NOTE_CSV_DOWNLOAD_SUCCESS';
export const CARE_GUIDE_NOTE_CSV_DOWNLOAD_FAILURE =
  'CARE_GUIDE_NOTE_CSV_DOWNLOAD_FAILURE';

// ACTION CREATORS

export function careGuideNoteListRequest(page, limit, order, filter) {
  Logger.log(
    'debug',
    `[careGuideNotes.actions] careGuideNoteListRequest(${page}, ${limit}, ${order}, %j)`,
    filter
  );
  return {
    type: CARE_GUIDE_NOTE_LIST_REQUEST,
    page: page,
    limit: limit,
    order: order,
    filter: filter,
  };
}

export function careGuideNoteListSuccess(data) {
  Logger.log(
    'debug',
    `[careGuideNotes.actions] careGuideNoteListSuccess(%j)`,
    data
  );
  return {
    type: CARE_GUIDE_NOTE_LIST_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now(),
  };
}

export function careGuideNoteListFailure(error) {
  Logger.log(
    'debug',
    `[careGuideNotes.actions] careGuideNoteListFailure(%j)`,
    error
  );
  return {
    type: CARE_GUIDE_NOTE_LIST_FAILURE,
    error: error,
  };
}

export function careGuideNoteReadRequest(id) {
  Logger.log(
    'debug',
    `[careGuideNotes.actions] careGuideNoteReadRequest(${id})`
  );
  return {
    type: CARE_GUIDE_NOTE_READ_REQUEST,
    id: id,
  };
}

export function careGuideNoteReadSuccess(data) {
  Logger.log(
    'debug',
    `[careGuideNotes.actions] careGuideNoteReadSuccess(%j)`,
    data
  );
  return {
    type: CARE_GUIDE_NOTE_READ_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function careGuideNoteReadFailure(error) {
  Logger.log(
    'debug',
    `[careGuideNotes.actions] careGuideNoteReadFailure(%j)`,
    error
  );
  return {
    type: CARE_GUIDE_NOTE_READ_FAILURE,
    error: error,
  };
}

export function careGuideNoteUpdateRequest(id, data) {
  Logger.log(
    'debug',
    `[careGuideNotes.actions] careGuideNoteUpdateRequest(${id}, %j)`,
    data
  );
  return {
    type: CARE_GUIDE_NOTE_UPDATE_REQUEST,
  };
}

export function careGuideNoteUpdateSuccess(data) {
  Logger.log(
    'debug',
    `[careGuideNotes.actions] careGuideNoteUpdateSuccess(%j)`,
    data
  );
  return {
    type: CARE_GUIDE_NOTE_UPDATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function careGuideNoteUpdateFailure(error) {
  Logger.log(
    'debug',
    `[careGuideNotes.actions] careGuideNoteUpdateFailure(%j)`,
    error
  );
  return {
    type: CARE_GUIDE_NOTE_UPDATE_FAILURE,
    error: error,
  };
}

export function careGuideNoteCreateRequest(data) {
  Logger.log(
    'debug',
    `[careGuideNotes.actions] careGuideNoteCreateRequest(%j)`,
    data
  );
  return {
    type: CARE_GUIDE_NOTE_CREATE_REQUEST,
  };
}

export function careGuideNoteCreateSuccess(data) {
  Logger.log(
    'debug',
    `[careGuideNotes.actions] careGuideNoteCreateSuccess(%j)`,
    data
  );
  return {
    type: CARE_GUIDE_NOTE_CREATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function careGuideNoteCreateFailure(error) {
  Logger.log(
    'debug',
    `[careGuideNotes.actions] careGuideNoteCreateFailure(%j)`,
    error
  );
  return {
    type: CARE_GUIDE_NOTE_CREATE_FAILURE,
    error: error,
  };
}

export function careGuideNoteDeleteRequest(id) {
  Logger.log(
    'debug',
    `[careGuideNotes.actions] careGuideNoteDeleteRequest(${id})`
  );
  return {
    type: CARE_GUIDE_NOTE_DELETE_REQUEST,
    id: id,
  };
}

export function careGuideNoteDeleteSuccess(id) {
  Logger.log(
    'debug',
    `[careGuideNotes.actions] careGuideNoteDeleteSuccess(${id})`
  );
  return {
    type: CARE_GUIDE_NOTE_DELETE_SUCCESS,
    id: id,
  };
}

export function careGuideNoteDeleteFailure(error) {
  Logger.log(
    'debug',
    `[careGuideNotes.actions] careGuideNoteDeleteFailure(%j)`,
    error
  );
  return {
    type: CARE_GUIDE_NOTE_DELETE_FAILURE,
    error: error,
  };
}

export function careGuideNoteFormDestroy(formState = null) {
  Logger.log(
    'debug',
    `[careGuideNotes.actions] careGuideNoteFormDestroy(%j)`,
    formState
  );
  return {
    type: CARE_GUIDE_NOTE_FORM_DESTROY,
    form: formState,
  };
}

export function careGuideNoteCSVDownloadRequest(order, filter) {
  Logger.log(
    'debug',
    `[state.careGuideNotes.actions] careGuideNoteCSVDownloadRequest()`
  );
  return {
    type: CARE_GUIDE_NOTE_CSV_DOWNLOAD_REQUEST,
    order: order,
    filter: filter,
  };
}

export function careGuideNoteCSVDownloadSuccess() {
  Logger.log(
    'debug',
    `[state.careGuideNotes.actions] careGuideNoteCSVDownloadSuccess()`
  );
  return {
    type: CARE_GUIDE_NOTE_CSV_DOWNLOAD_SUCCESS,
  };
}

export function careGuideNoteCSVDownloadFailure() {
  Logger.log(
    'debug',
    `[state.careGuideNotes.actions] careGuideNoteCSVDownloadFailure()`
  );
  return {
    type: CARE_GUIDE_NOTE_CSV_DOWNLOAD_FAILURE,
  };
}

// API THUNK ACTION CREATORS

export function loadCareGuideNotes(
  page = 1,
  limit = 10,
  order = null,
  filter = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[careGuideNotes.actions] loadCareGuideNotes(${page}, ${limit}, ${order}, %j, ###)`,
    filter
  );

  return async function (dispatch) {
    dispatch(careGuideNoteListRequest(page, limit, order, filter));

    // call API
    const response = await api.getCareGuideNotes(page, limit, order, filter);
    let success = false;

    // get care guide notes list success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API care guide notes list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );

      const normalizedEntities = normalize(
        response.getIn(['data', 'care_guide_notes']),
        [schema.careGuideNote]
      );
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result,
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideNoteListSuccess(data));
      success = true;
    } else if (1 === page && 204 === response.get('status')) {
      Logger.log(
        'info',
        `Get API care guide notes success [empty]. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: [],
      };
      dispatch(careGuideNoteListSuccess(data));
      success = true;

      // get care guide notes list failure
    } else {
      Logger.log(
        'info',
        `Get API care guide notes list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      dispatch(careGuideNoteListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function loadCareGuideNote(id, cb = function () {}) {
  Logger.log('debug', `[careGuideNotes.actions] loadCareGuideNote(${id}, ###)`);

  return async function (dispatch) {
    dispatch(careGuideNoteReadRequest(id));

    // call API
    const response = await api.getCareGuideNote(id);
    let success = false;

    // get care guide note success
    if (200 === response.get('status')) {
      Logger.log('info', `Get API care guide note success. ID: ${id}.`);

      const normalizedEntities = normalize(
        [response.getIn(['data', 'care_guide_note'])],
        [schema.careGuideNote]
      );
      const data = {
        id: response.getIn(['data', 'care_guide_note', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideNoteReadSuccess(data));
      success = true;

      // get care guide note failure
    } else {
      Logger.log('info', `Get API care guide note failure. ID: ${id}.`);
      dispatch(careGuideNoteReadFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function updateCareGuideNote(id, data, cb = function () {}) {
  Logger.log(
    'debug',
    `[careGuideNotes.actions] updateCareGuideNote(${id}, %j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(careGuideNoteUpdateRequest(id, data));

    // call API
    const response = await api.putCareGuideNote(id, data);
    let success = false;

    // put care guide note success
    if (200 === response.get('status')) {
      Logger.log('info', `PUT API care guide note success. User: ${id}.`);

      const normalizedEntities = normalize(
        [response.getIn(['data', 'care_guide_note'])],
        [schema.careGuideNote]
      );
      const data = {
        id: response.getIn(['data', 'care_guide_note', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideNoteUpdateSuccess(data));
      success = true;

      // get care guide note failure
    } else {
      Logger.log('info', `PUT API care guide note failure. ID: ${id}.`);
      dispatch(careGuideNoteUpdateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function createCareGuideNote(data, cb = function () {}) {
  Logger.log(
    'debug',
    `[careGuideNotes.actions] createCareGuideNote(%j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(careGuideNoteCreateRequest(data));

    // call API
    const response = await api.postCareGuideNotes(data);
    let success = false;

    // post care guide notes success
    if (201 === response.get('status')) {
      Logger.log(
        'info',
        `POST API care guide notes success. Post: ${response.getIn([
          'data',
          'care_guide_note',
          'id',
        ])}.`
      );

      const normalizedEntities = normalize(
        [response.getIn(['data', 'care_guide_note'])],
        [schema.careGuideNote]
      );
      const data = {
        id: response.getIn(['data', 'care_guide_note', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideNoteCreateSuccess(data));
      success = true;

      // get care guide notes failure
    } else {
      Logger.log('info', `POST API care guide notes failure.`);
      dispatch(careGuideNoteCreateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function deleteCareGuideNote(id, cb = function () {}) {
  Logger.log(
    'debug',
    `[careGuideNotes.actions] deleteCareGuideNote(${id}, ###)`
  );

  return async function (dispatch) {
    dispatch(careGuideNoteDeleteRequest(id));

    // call API
    const response = await api.deleteCareGuideNote(id);
    let success = false;

    // delete care guide note success
    if (204 === response.get('status')) {
      Logger.log('info', `DELETE API care guide note success. ID: ${id}.`);

      dispatch(removeEntity({ entityType: 'careGuideNotes', id: id }));
      dispatch(careGuideNoteDeleteSuccess(id));
      success = true;

      // get care guide note failure
    } else {
      Logger.log('info', `DELETE API care guide note failure. ID: ${id}.`);
      dispatch(careGuideNoteDeleteFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function downloadCareGuideNotesCSV(
  order = null,
  filter = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.careGuideNotes.actions] downloadCareGuideNotesCSV(${order}, ${filter}, ###)`
  );

  return async function (dispatch) {
    dispatch(careGuideNoteCSVDownloadRequest(order, filter));

    // call API
    const response = await api.getCareGuideNotesCSV(order, filter);
    let success = false;
    // get care guide notes CSV success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API care guide notes CSV success. Order: ${order}`
      );
      const now = new Date();

      // trigger browser download
      const url = window.URL.createObjectURL(new Blob([response.get('data')]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `cv-care-guide-notes-${Format.date(now, 'YYYY-MM-DDTHHmm')}.csv`
      );
      document.body.appendChild(link);
      link.click();

      dispatch(careGuideNoteCSVDownloadSuccess());
      success = true;
      // get care guide notes CSV failure
    } else {
      Logger.log('info', `Get API care guide notes CSV failure.`);
      dispatch(careGuideNoteCSVDownloadFailure());
    }

    // callback function
    cb(success);
  };
}

Logger.log('silly', `careGuideNotes.actions loaded.`);
