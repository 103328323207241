import React, {useState, useEffect} from 'react';
import {Redirect} from 'react-router-dom'
import {Translation, getI18n} from 'react-i18next';
import {Affix, Button, Card, Col, Form, Input, Row, Space, Spin} from 'antd';
import {DragOutlined, LoadingOutlined, MinusCircleOutlined, PlusOutlined} from '@ant-design/icons';
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";

import CareGuideSectionInput from '../../careGuideSections/containers/CareGuideSectionInputContainer';
import message from '../../../elements/lib/MessageWrapper';
import FormMetadata from '../../../elements/components/FormMetadata';
import {pathTo} from '../../../Routes';
import Logger from '../../../../../lib/Logger';


const SummaryInputRow = ({field, index, remove, ...props}) => {
  return (
    <Draggable key={field.key} draggableId={field.key.toString()} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <Translation>{(t) => 
            <Space key={field.key} style={{display: 'flex'}} align="start">

              <div className="drag-handle" {...provided.dragHandleProps}>
                <DragOutlined />
              </div>

              <div className="form-group">
                <Form.Item
                  {...field}
                  name={[field.name, 'field']}
                  label={t('care_guide_template_summary_field')}
                  fieldKey={[field.fieldKey, 'field']}
                  rules={[
                    {required: true, message: t('feedback_validation_required')},
                    {type: 'string', min: 2, max: 60, message: t('feedback_validation_char_range', {min: 2, max: 60})},
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>

              <MinusCircleOutlined
                onClick={() => {
                  remove(field.name);
                }}
              />
            </Space>
          }</Translation>
        </div>
      )}
    </Draggable>
  )
}

const SectionInputRow = ({field, index, remove, ...props}) => {
  return (
    <Draggable key={field.key} draggableId={field.key.toString()} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <Translation>{(t) => 
            <Space key={field.key} style={{display: 'flex'}} align="start">

              <div className="drag-handle" {...provided.dragHandleProps}>
                <DragOutlined />
              </div>

              <CareGuideSectionInput
                {...field}
                name={[field.name, 'section_id']}
                label={null}
                fieldKey={[field.fieldKey, 'section_id']}
                loadOnMount={false}
                style={{width: '300px'}}
              />

              <MinusCircleOutlined
                onClick={() => {
                  remove(field.name);
                }}
              />
            </Space>
          }</Translation>
        </div>
      )}
    </Draggable>
  )
}


const CareGuideTemplateForm = ({id, data, errors, load, loadSections, destroyForm, isLoading, isSubmitting, created_id, ...props}) => {

  const [redirectTo, setRedirectTo] = useState(null);
  const [form] = Form.useForm();

  // form column settings
  const layout = {
    main: {
      labelCol: {span: 5},
      wrapperCol: {span: 19},
    },
  }

  // load record data from API
  useEffect(() => {
    if (id) {
      load(id);
    }
  }, [id, load]);

  // load sections on mount
  useEffect(() => {
    loadSections(1, 100);
  }, [loadSections]);

  // update input values when new data is available
  useEffect(() => {
    if (id && !isSubmitting) {
      form.setFieldsValue(data);
    }
  }, [form, data, isSubmitting, id]);

  // handle errors reported by API
  useEffect(() => {
    let firstFieldName = '';
    for (const field in errors) {
      form.setFields([{name: field, errors: errors[field]}]);
      if (firstFieldName === '') {
        firstFieldName = field;
      }
    }
    form.scrollToField(firstFieldName);
  }, [form, errors]);

  // redirect add form to edit form on successful create action
  useEffect(() => {
    if (created_id) {
      setRedirectTo(pathTo('CareGuideTemplateEditScreen', {id: created_id}));
    }
    return () => {
      destroyForm();
    }
  }, [created_id, setRedirectTo, destroyForm]);

  // move section row into new position after drag and drop reorder
  const onDragEnd = (result, move) => {
    if (result.destination) {
      move(result.source.index, result.destination.index);
    }
  }

  // submit data handler
  const submitData = async (values) => {
    Logger.log('debug', `CareGuideTemplateForm.submitData(###)`);

    // API POST/PUT payload
    let payload = {};
    for (const input of Object.keys(data)) {
      
      // add ordering to sections
      if (input === 'sections' && Array.isArray(values[input])) {
        payload[input] = values[input].map((x, i) => {
          return {
            id: x.id,
            section_id: x.section_id,
            order: i+1
          }
        });
      }

      // add all other inputs to payload
      else if (input in values) {
        payload[input] = values[input];
      }
    }

    if (id) { // update
      props.update(id, payload, (success) => {
        if (success) {
          message.success(getI18n().t('feedback_form_success'));
        } else {
          message.error(getI18n().t('feedback_form_error'));
        }
      });      
    } else { // create
      props.create(payload, (success) => {
        if (success) {
          message.success(getI18n().t('feedback_form_success'));
        } else {
          message.error(getI18n().t('feedback_form_error'));
        }
      });      
    }
  }

  // form submit handler
  const handleFinish = async (values) => {
    Logger.log('debug', `CareGuideTemplateForm.handleFinish(###)`);
    if (!props.isSubmitting) {
      await submitData(values);
    }
  }

  // form error handler
  const handleFinishFailed = ({values, errorFields, outOfDate}) => {
    Logger.log('debug', `CareGuideTemplateForm.handleFinishFailed(###)`);
    message.error(getI18n().t('feedback_form_error'));
    if (errorFields && errorFields.length > 0) {
      form.scrollToField(errorFields[0].name);
    }
  }

  // remove error message when input value changes
  const handleValuesChange = (changedValues, allValues) => {
    for (const key of Object.keys(changedValues)) {
      form.setFields([{name: key, errors: []}]);
    }
  }

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <Translation>{(t) => 
      <>
        <div className="care-guide-template-form">
          <Form
            name="care_guide_template_form"
            form={form}
            initialValues={data}
            onFinish={handleFinish}
            onFinishFailed={handleFinishFailed}
            onValuesChange={handleValuesChange}
            validateTrigger="onSubmit"
            {...layout.main}
          >
            <Row gutter={16}>

              <Col xs={24} lg={18}>
                <Card
                  title={id ? t('care_guide_template_edit_title') : t('care_guide_template_add_title')}
                  extra={isLoading
                    ? <Spin indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} />
                    : null}
                >

                  <div className="form-group">
                    <Form.Item
                      name="name"
                      label={t('care_guide_template_name')}
                      rules={[
                        {required: true, message: t('feedback_validation_required')},
                        {type: 'string', min: 3, max: 60, message: t('feedback_validation_char_range', {min: 3, max: 60})},
                      ]}
                    >
                      <Input autoFocus disabled={isLoading || isSubmitting} />
                    </Form.Item>
                  </div>

                </Card>

                <Card
                  title={t('care_guide_template_summary_title')}
                  extra={isLoading
                    ? <Spin indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} />
                    : null}
                >

                  <Form.List name="summary">
                  
                    {(fields, {add, remove, move}) => {
                      return(
                        <>
                          <DragDropContext onDragEnd={(result) => onDragEnd(result, move)}>
                            <Droppable droppableId="droppable">
                              {(provided, snapshot) => (
                                <div
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                >
                                  {fields.map((field, i) => (
                                    <SummaryInputRow field={field} remove={remove} index={i} key={i} />
                                  ))}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </DragDropContext>

                          <Form.Item>
                            <Button
                              type="dashed"
                              onClick={() => {
                                add();
                              }}
                              block
                            >
                              <PlusOutlined /> Add field
                            </Button>
                          </Form.Item>
                        </>
                      );
                    }}
                  </Form.List>

                </Card>

                <Card
                  title={t('care_guide_template_sections_title')}
                  extra={isLoading
                    ? <Spin indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} />
                    : null}
                >

                  <Form.List name="sections">
                  
                    {(fields, {add, remove, move}) => {
                      return(
                        <>
                          <DragDropContext onDragEnd={(result) => onDragEnd(result, move)}>
                            <Droppable droppableId="droppable">
                              {(provided, snapshot) => (
                                <div
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                >
                                  {fields.map((field, i) => (
                                    <SectionInputRow field={field} remove={remove} index={i} key={i} />
                                  ))}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </DragDropContext>

                          <Form.Item>
                            <Button
                              type="dashed"
                              onClick={() => {
                                add();
                              }}
                              block
                            >
                              <PlusOutlined /> Add field
                            </Button>
                          </Form.Item>
                        </>
                      );
                    }}
                  </Form.List>

                </Card>
              </Col>

              <Col xs={24} lg={6}>
                <Affix offsetTop={10}>
                  <Card title={t('form_metadata_header')}>
                    <FormMetadata
                      id={id}
                      isSubmitting={isSubmitting}
                      delete={props.delete.bind(this)}
                      deleteRedirectTo="CareGuideTemplatesScreen"
                      createdAt={props.createdAt}
                      updatedAt={props.updatedAt}
                    />
                  </Card>
                </Affix>
              </Col>

            </Row>
          </Form>
        </div>
      </>
    }</Translation>
  );
}

export default CareGuideTemplateForm;

Logger.log('silly', `CareGuideTemplateForm loaded.`);
