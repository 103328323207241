import { getI18n } from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import ImportTasksScreen from './containers/ImportTasksScreenContainer';
import ImportTaskViewScreen from './containers/ImportTaskViewScreenContainer';

const register = () => {
  Logger.log('debug', `importTasks.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'importTasks', 'routes'])) {
    Events.dispatch('ADD_MAIN_ROUTES', {
      ImportTasksScreen: [
        'PrivateRoute',
        '/import-tasks/:page(\\d+)?',
        true,
        ImportTasksScreen,
        getI18n().t('import_tasks_route_list'),
      ],
      ImportTaskViewScreen: [
        'PrivateRoute',
        '/import-tasks/view/:id([\\w\\-]+)',
        true,
        ImportTaskViewScreen,
        getI18n().t('import_tasks_route_view'),
      ],
    });
  }
};

export default register;

Logger.log('silly', `importTasks.register() loaded.`);
