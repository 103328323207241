import { Map, List } from 'immutable';

import {
  CALENDAR_EVENT_LIST_REQUEST,
  CALENDAR_EVENT_LIST_SUCCESS,
  CALENDAR_EVENT_LIST_FAILURE,
  CALENDAR_EVENT_READ_REQUEST,
  CALENDAR_EVENT_READ_SUCCESS,
  CALENDAR_EVENT_READ_FAILURE,
  CALENDAR_EVENT_UPDATE_REQUEST,
  CALENDAR_EVENT_UPDATE_SUCCESS,
  CALENDAR_EVENT_UPDATE_FAILURE,
  CALENDAR_EVENT_CREATE_REQUEST,
  CALENDAR_EVENT_CREATE_SUCCESS,
  CALENDAR_EVENT_CREATE_FAILURE,
  CALENDAR_EVENT_DELETE_REQUEST,
  CALENDAR_EVENT_DELETE_SUCCESS,
  CALENDAR_EVENT_DELETE_FAILURE,
  CALENDAR_EVENT_FORM_DESTROY,
  CALENDAR_EVENT_CSV_DOWNLOAD_REQUEST,
  CALENDAR_EVENT_CSV_DOWNLOAD_SUCCESS,
  CALENDAR_EVENT_CSV_DOWNLOAD_FAILURE,
  CALENDAR_EVENT_EXCEPTION_LIST_REQUEST,
  CALENDAR_EVENT_EXCEPTION_LIST_SUCCESS,
  CALENDAR_EVENT_EXCEPTION_LIST_FAILURE,
  CALENDAR_EVENT_EXCEPTION_READ_REQUEST,
  CALENDAR_EVENT_EXCEPTION_READ_SUCCESS,
  CALENDAR_EVENT_EXCEPTION_READ_FAILURE,
  CALENDAR_EVENT_EXCEPTION_UPDATE_REQUEST,
  CALENDAR_EVENT_EXCEPTION_UPDATE_SUCCESS,
  CALENDAR_EVENT_EXCEPTION_UPDATE_FAILURE,
  CALENDAR_EVENT_EXCEPTION_CREATE_REQUEST,
  CALENDAR_EVENT_EXCEPTION_CREATE_SUCCESS,
  CALENDAR_EVENT_EXCEPTION_CREATE_FAILURE,
  CALENDAR_EVENT_EXCEPTION_DELETE_REQUEST,
  CALENDAR_EVENT_EXCEPTION_DELETE_SUCCESS,
  CALENDAR_EVENT_EXCEPTION_DELETE_FAILURE,
  CALENDAR_EVENT_EXCEPTION_FORM_DESTROY,
  CALENDAR_EVENT_EXCEPTION_CSV_DOWNLOAD_REQUEST,
  CALENDAR_EVENT_EXCEPTION_CSV_DOWNLOAD_SUCCESS,
  CALENDAR_EVENT_EXCEPTION_CSV_DOWNLOAD_FAILURE,
  CALENDAR_EVENT_SHOW_EVENT_PARTICIPANT_FORM,
  CALENDAR_EVENT_HIDE_EVENT_PARTICIPANT_FORM,
  CALENDAR_EVENT_LIST_PARTICIPANTS_REQUEST,
  CALENDAR_EVENT_LIST_PARTICIPANTS_SUCCESS,
  CALENDAR_EVENT_LIST_PARTICIPANTS_FAILURE,
  CALENDAR_EVENT_DELETE_PARTICIPANT_REQUEST,
  CALENDAR_EVENT_DELETE_PARTICIPANT_SUCCESS,
  CALENDAR_EVENT_DELETE_PARTICIPANT_FAILURE,
  CALENDAR_EVENT_UPDATE_PARTICIPANT_REQUEST,
  CALENDAR_EVENT_UPDATE_PARTICIPANT_SUCCESS,
  CALENDAR_EVENT_UPDATE_PARTICIPANT_FAILURE,
  CALENDAR_EVENT_CREATE_PARTICIPANT_REQUEST,
  CALENDAR_EVENT_CREATE_PARTICIPANT_SUCCESS,
  CALENDAR_EVENT_CREATE_PARTICIPANT_FAILURE,
  CALENDAR_EVENT_PARTICIPANT_SET_PAGE,
  CALENDAR_EVENT_EVENT_PARTICIPANT_FORM_DESTROY,
} from './actions';
import Logger from '../../../lib/Logger';

export default function calendarEvents(
  state = Map({
    isLoading: false,
    areExceptionsLoading: false,
    isCSVDownloading: false,
    isExceptionCSVDownloading: false,
    pages: {},
    exceptionPages: {},
    total: 0,
    exceptionsTotal: 0,
    form: null,
    exceptionForm: null,
    pageParticipants: 1,
    areParticipantEventsLoading: false,
    eventParticipantFormIsVisible: false,
    eventParticipantFormParticipantId: null,
    eventForm: {},
  }),
  action
) {
  Logger.log(
    'debug',
    `[calendarEvents.reducers] calendarEvents(%j, %j)`,
    state,
    action
  );

  switch (action.type) {
    case CALENDAR_EVENT_LIST_REQUEST:
      return state.mergeDeep({
        isLoading: true,
      });

    case CALENDAR_EVENT_LIST_SUCCESS:
      return state
        .mergeDeep({
          isLoading: false,
          pages: {
            [action.order]: {
              [action.limit]: {
                [action.page]: null,
              },
            },
          },
          total: action.total,
          lastUpdated: action.receivedAt,
        })
        .setIn(
          ['pages', action.order, action.limit, action.page],
          List(action.result)
        );

    case CALENDAR_EVENT_LIST_FAILURE:
      return state.mergeDeep({
        isLoading: false,
      });

    case CALENDAR_EVENT_READ_REQUEST:
      return state.mergeDeep({
        isLoading: true,
      });

    case CALENDAR_EVENT_READ_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        lastUpdated: action.receivedAt,
      });

    case CALENDAR_EVENT_READ_FAILURE:
      return state.mergeDeep({
        isLoading: false,
      });

    case CALENDAR_EVENT_UPDATE_REQUEST:
      return state.mergeDeep({
        isLoading: true,
        form: {
          isSubmitting: true,
          success: null,
          errors: null,
        },
      });

    case CALENDAR_EVENT_UPDATE_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        form: {
          isSubmitting: false,
          success: true,
          errors: null,
        },
        lastUpdated: action.receivedAt,
      });

    case CALENDAR_EVENT_UPDATE_FAILURE:
      return state
        .mergeDeep({
          isLoading: false,
          form: {
            isSubmitting: false,
            success: false,
            errors: null,
          },
        })
        .setIn(['form', 'errors'], action.error);

    case CALENDAR_EVENT_CREATE_REQUEST:
      return state.mergeDeep({
        isLoading: true,
        form: {
          isSubmitting: true,
          success: null,
          errors: null,
        },
      });

    case CALENDAR_EVENT_CREATE_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        form: {
          isSubmitting: false,
          success: true,
          created_id: action.id,
          errors: null,
        },
        lastUpdated: action.receivedAt,
      });

    case CALENDAR_EVENT_CREATE_FAILURE:
      return state
        .mergeDeep({
          isLoading: false,
          form: {
            isSubmitting: false,
            success: false,
            errors: null,
          },
        })
        .setIn(['form', 'errors'], action.error);

    case CALENDAR_EVENT_DELETE_REQUEST:
      return state.mergeDeep({
        isLoading: true,
      });

    case CALENDAR_EVENT_DELETE_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        lastUpdated: action.receivedAt,
      });

    case CALENDAR_EVENT_DELETE_FAILURE:
      return state.mergeDeep({
        isLoading: false,
      });

    case CALENDAR_EVENT_FORM_DESTROY:
      return state
        .mergeDeep({
          form: null,
        })
        .set('form', action.form);

    case CALENDAR_EVENT_CSV_DOWNLOAD_REQUEST:
      return state.mergeDeep({
        isCSVDownloading: true,
      });

    case CALENDAR_EVENT_CSV_DOWNLOAD_SUCCESS:
      return state.mergeDeep({
        isCSVDownloading: false,
      });

    case CALENDAR_EVENT_CSV_DOWNLOAD_FAILURE:
      return state.mergeDeep({
        isCSVDownloading: false,
      });

    case CALENDAR_EVENT_EXCEPTION_LIST_REQUEST:
      return state.mergeDeep({
        areExceptionsLoading: true,
      });

    case CALENDAR_EVENT_EXCEPTION_LIST_SUCCESS:
      return state
        .mergeDeep({
          areExceptionsLoading: false,
          exceptionPages: {
            [action.order]: {
              [action.limit]: {
                [action.page]: null,
              },
            },
          },
          exceptionsTotal: action.total,
          lastUpdated: action.receivedAt,
        })
        .setIn(
          ['exceptionPages', action.order, action.limit, action.page],
          List(action.result)
        );

    case CALENDAR_EVENT_EXCEPTION_LIST_FAILURE:
      return state.mergeDeep({
        areExceptionsLoading: false,
      });

    case CALENDAR_EVENT_EXCEPTION_READ_REQUEST:
      return state.mergeDeep({
        areExceptionsLoading: true,
      });

    case CALENDAR_EVENT_EXCEPTION_READ_SUCCESS:
      return state.mergeDeep({
        areExceptionsLoading: false,
        lastUpdated: action.receivedAt,
      });

    case CALENDAR_EVENT_EXCEPTION_READ_FAILURE:
      return state.mergeDeep({
        areExceptionsLoading: false,
      });

    case CALENDAR_EVENT_EXCEPTION_UPDATE_REQUEST:
      return state.mergeDeep({
        areExceptionsLoading: true,
        exceptionForm: {
          isSubmitting: true,
          success: null,
          errors: null,
        },
      });

    case CALENDAR_EVENT_EXCEPTION_UPDATE_SUCCESS:
      return state.mergeDeep({
        areExceptionsLoading: false,
        exceptionForm: {
          isSubmitting: false,
          success: true,
          errors: null,
        },
        lastUpdated: action.receivedAt,
      });

    case CALENDAR_EVENT_EXCEPTION_UPDATE_FAILURE:
      return state
        .mergeDeep({
          areExceptionsLoading: false,
          exceptionForm: {
            isSubmitting: false,
            success: false,
            errors: null,
          },
        })
        .setIn(['exceptionForm', 'errors'], action.error);

    case CALENDAR_EVENT_EXCEPTION_CREATE_REQUEST:
      return state.mergeDeep({
        areExceptionsLoading: true,
        exceptionForm: {
          isSubmitting: true,
          success: null,
          errors: null,
        },
      });

    case CALENDAR_EVENT_EXCEPTION_CREATE_SUCCESS:
      return state.mergeDeep({
        areExceptionsLoading: false,
        exceptionForm: {
          isSubmitting: false,
          success: true,
          created_id: action.id,
          errors: null,
        },
        lastUpdated: action.receivedAt,
      });

    case CALENDAR_EVENT_EXCEPTION_CREATE_FAILURE:
      return state
        .mergeDeep({
          areExceptionsLoading: false,
          exceptionForm: {
            isSubmitting: false,
            success: false,
            errors: null,
          },
        })
        .setIn(['exceptionForm', 'errors'], action.error);

    case CALENDAR_EVENT_EXCEPTION_DELETE_REQUEST:
      return state.mergeDeep({
        areExceptionsLoading: true,
      });

    case CALENDAR_EVENT_EXCEPTION_DELETE_SUCCESS:
      return state.mergeDeep({
        areExceptionsLoading: false,
        lastUpdated: action.receivedAt,
      });

    case CALENDAR_EVENT_EXCEPTION_DELETE_FAILURE:
      return state.mergeDeep({
        areExceptionsLoading: false,
      });

    case CALENDAR_EVENT_EXCEPTION_FORM_DESTROY:
      return state
        .mergeDeep({
          exceptionForm: null,
        })
        .set('exceptionForm', action.form);

    case CALENDAR_EVENT_EXCEPTION_CSV_DOWNLOAD_REQUEST:
      return state.mergeDeep({
        isExceptionCSVDownloading: true,
      });

    case CALENDAR_EVENT_EXCEPTION_CSV_DOWNLOAD_SUCCESS:
      return state.mergeDeep({
        isExceptionCSVDownloading: false,
      });

    case CALENDAR_EVENT_EXCEPTION_CSV_DOWNLOAD_FAILURE:
      return state.mergeDeep({
        isExceptionCSVDownloading: false,
      });

    case CALENDAR_EVENT_SHOW_EVENT_PARTICIPANT_FORM:
      return state.mergeDeep({
        eventParticipantFormIsVisible: true,
        eventParticipantFormParticipantId: action.participantId,
      });

    case CALENDAR_EVENT_HIDE_EVENT_PARTICIPANT_FORM:
      return state.mergeDeep({
        eventParticipantFormIsVisible: false,
        eventParticipantFormParticipantId: null,
      });

    case CALENDAR_EVENT_EVENT_PARTICIPANT_FORM_DESTROY:
      return state
        .mergeDeep({
          eventForm: null,
        })
        .set('eventForm', action.form);

    case CALENDAR_EVENT_LIST_PARTICIPANTS_REQUEST:
      return state.mergeDeep({
        areParticipantEventsLoading: true,
      });

    case CALENDAR_EVENT_LIST_PARTICIPANTS_SUCCESS:
      return state
        .mergeDeep({
          areParticipantEventsLoading: false,
          calendarEventParticipants: {
            [action.calendarEventId]: {
              total: action.total,
              [action.page]: {
                [action.limit]: null,
              },
            },
          },
          lastUpdated: action.receivedAt,
        })
        .setIn(
          [
            'calendarEventParticipants',
            action.calendarEventId,
            action.page,
            action.limit,
          ],
          List(action.result)
        );

    case CALENDAR_EVENT_LIST_PARTICIPANTS_FAILURE:
      return state.mergeDeep({
        areParticipantEventsLoading: false,
      });

    case CALENDAR_EVENT_DELETE_PARTICIPANT_REQUEST:
      return state.mergeDeep({
        areParticipantEventsLoading: true,
      });

    case CALENDAR_EVENT_DELETE_PARTICIPANT_SUCCESS:
      return state.mergeDeep({
        areParticipantEventsLoading: false,
        lastUpdated: action.receivedAt,
      });

    case CALENDAR_EVENT_DELETE_PARTICIPANT_FAILURE:
      return state.mergeDeep({
        areParticipantEventsLoading: false,
      });

    case CALENDAR_EVENT_CREATE_PARTICIPANT_REQUEST:
      return state.mergeDeep({
        eventForm: {
          isSubmitting: true,
          success: null,
          errors: null,
        },
      });

    case CALENDAR_EVENT_CREATE_PARTICIPANT_SUCCESS:
      return state.mergeDeep({
        eventForm: {
          isSubmitting: false,
          success: true,
          errors: null,
        },
        lastUpdated: action.receivedAt,
      });

    case CALENDAR_EVENT_CREATE_PARTICIPANT_FAILURE:
      return state
        .mergeDeep({
          eventForm: {
            isSubmitting: false,
            success: false,
            errors: null,
          },
        })
        .setIn(['eventForm', 'errors'], action.error);

    case CALENDAR_EVENT_UPDATE_PARTICIPANT_REQUEST:
      return state.mergeDeep({
        eventForm: {
          isSubmitting: true,
          success: null,
          errors: null,
        },
      });

    case CALENDAR_EVENT_UPDATE_PARTICIPANT_SUCCESS:
      return state.mergeDeep({
        eventForm: {
          isSubmitting: false,
          success: true,
          errors: null,
        },
        lastUpdated: action.receivedAt,
      });

    case CALENDAR_EVENT_UPDATE_PARTICIPANT_FAILURE:
      return state
        .mergeDeep({
          eventForm: {
            isSubmitting: false,
            success: false,
            errors: null,
          },
        })
        .setIn(['eventForm', 'errors'], action.error);

    case CALENDAR_EVENT_PARTICIPANT_SET_PAGE:
      return state.mergeDeep({
        pageParticipants: action.page,
      });

    default:
      return state;
  }
}

Logger.log('silly', `calendarEvents.reducers loaded.`);
