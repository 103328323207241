import {connect} from 'react-redux';

import JournalTagAddScreen from '../components/JournalTagAddScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.journalTags.get('isLoading'),
    success: state.journalTags.getIn(['form', 'success']),
  }
}

const JournalTagAddScreenContainer = connect(
  mapStateToProps
)(JournalTagAddScreen);

export default JournalTagAddScreenContainer;
