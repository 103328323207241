import {connect} from 'react-redux';

import {downloadCareGuideHelperTagsCSV} from '../../../../../state/modules/careGuideHelperTags/actions';
import CareGuideHelperTagsCSVButton from '../components/CareGuideHelperTagsCSVButton';

const mapStateToProps = state => {
  return {
    isDownloading: state.careGuideHelperTags.get('isCSVDownloading'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    download: (order, filter, cb) => {
      dispatch(downloadCareGuideHelperTagsCSV(order, filter, cb));
    }
  }
}

const CareGuideHelperTagsCSVButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CareGuideHelperTagsCSVButton);

export default CareGuideHelperTagsCSVButtonContainer;
