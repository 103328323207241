import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Translation, getI18n } from 'react-i18next';
import {
  Affix,
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Spin,
  Table,
  Space,
} from 'antd';
import {
  LoadingOutlined,
  PlusOutlined,
  EditOutlined,
  MinusCircleOutlined,
} from '@ant-design/icons';
import moment from 'moment';

import TimeInput from './TimeInput';
import BodyInput from './BodyInput';
import RecurringInput from './RecurringInput';
import ParticipantInputRow from './ParticipantInputRow';
import EventParticipantFormModal from '../containers/EventParticipantFormModalContainer';
import message from '../../../elements/lib/MessageWrapper';
import FormMetadata from '../../../elements/components/FormMetadata';
import { pathTo } from '../../../Routes';
import Format from '../../../../../lib/Format';
import Logger from '../../../../../lib/Logger';
import Config from '../../../../../Config';
import CareGuidePartnershipInput from '../../careGuides/containers/CareGuidePartnershipInputContainer';
import CareGuideInput from '../../careGuides/containers/CareGuideInputContainer';
import UserCareGuidesInput from '../../users/containers/UserCareGuidesInputContainer';
import UserPartnerPartnershipInput from '../../users/containers/UserPartnerPartnershipInputContainer';

import PartnershipInput from '../../partnerships/containers/PartnershipInputContainer';

const CalendarEventForm = ({
  id,
  data,
  errors,
  load,
  destroyForm,
  isLoading,
  isSubmitting,
  created_id,
  pageParticipants,
  loadParticipants,
  areParticipantEventsLoading,
  list,
  total,
  setPage,
  deleteParticipant,
  showParticipantForm,
  ...props
}) => {
  const [redirectTo, setRedirectTo] = useState(null);
  const [careGuideIds, setCareGuideIds] = useState(data['care_guides']);
  const [partnershipId, setPartnershipId] = useState(data['partnership']);
  const [isEventPartner, setEventPartner] = useState(false);
  const [isMultipleCareGuide, setIsMultipleCareGuide] = useState(false);
  const [isMembersIndividual, setIsMembersIndividual] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [isChangeData, setIsChangeData] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);

  const [isAllDay, setIsAllDay] = useState(false);
  const [isRecurring, setIsRecurring] = useState(false);
  const [form] = Form.useForm();

  const apiDatetimeFormat = Config.get('API_DATETIME_FORMAT');

  // form column settings
  const layout = {
    main: {
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
    },
  };

  const formItemCheckBox = {
    labelCol: {
      sm: {
        span: 8,
      },
    },
    wrapperCol: {
      sm: {
        span: 10,
        offset: 5,
      },
    },
  };

  const pagination = id
    ? {
        onChange: (newPage) => {
          setPage(newPage);
        },
        position: ['topLeft'],
        current: pageParticipants,
        total: total,
        showSizeChanger: false,
      }
    : null;

  const onChangeIsMultipleCareGuide = (evt) => {
    form.setFieldsValue({
      participants: [],
      profile: '',
      care_guides: [],
    });

    setIsChangeData(true);
    setIsMultipleCareGuide(evt.target.checked);
  };

  const onChangeIsEventPartner = (evt) => {
    form.setFieldsValue({
      participants: [],
      profile: '',
    });
    setEventPartner(evt.target.checked);
    setIsChangeData(true);
  };

  const onChangeIsMembersIndividual = (evt) => {
    if (!id) {
      form.setFieldsValue({
        participants: [],
      });
    }
    setIsMembersIndividual(evt.target.checked);
  };

  useEffect(() => {
    if (id) {
      loadParticipants(id, pageParticipants);
    }
  }, [id, loadParticipants, pageParticipants]);

  // load record data from API
  useEffect(() => {
    if (id) {
      load(id);
    }
  }, [id, load]);

  // update input values when new data is available
  const dataString = JSON.stringify(data);
  useEffect(() => {
    if (id && !isSubmitting) {
      const dataObj = JSON.parse(dataString);

      dataObj['start_at_date'] = dataObj['start_at_date']
        ? moment(dataObj['start_at_date'], apiDatetimeFormat)
        : null;

      dataObj['recurring_end_at'] = dataObj['recurring_end_at']
        ? moment(dataObj['recurring_end_at'], apiDatetimeFormat)
        : null;
      setIsAllDay(dataObj['is_all_day']);
      setIsRecurring([2, 3, 4, 5].includes(dataObj['recurring']));
      form.setFieldsValue(dataObj);
      setCareGuideIds(dataObj['care_guides']);
      setPartnershipId(dataObj['partnership']);
      setStartDate(dataObj['start_at_date']);
      onRecurringChangeHandler(data['recurring']);

      if (dataObj['partnership']) {
        setEventPartner(true);
        setIsMembersIndividual(true);
        if (dataObj['care_guides'].length > 0) {
          setIsMultipleCareGuide(true);
        }
      }
      setPage(1);
      loadParticipants(id, pageParticipants);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, dataString, isSubmitting, id, apiDatetimeFormat]);

  // handle errors reported by API
  useEffect(() => {
    let firstFieldName = '';
    for (const field in errors) {
      form.setFields([{ name: field, errors: errors[field] }]);
      if (firstFieldName === '') {
        firstFieldName = field;
      }
    }
    form.scrollToField(firstFieldName);
  }, [form, errors]);

  // redirect add form to edit form on successful create action
  useEffect(() => {
    if (created_id) {
      setRedirectTo(pathTo('CalendarEventEditScreen', { id: created_id }));
    }
    return () => {
      destroyForm();
    };
  }, [created_id, setRedirectTo, destroyForm]);

  // submit data handler
  const submitData = async (values) => {
    Logger.log('debug', `CalendarEventForm.submitData(###)`);

    // API POST/PUT payload
    let payload = {};
    for (const input of Object.keys(data)) {
      if (input in values) {
        // transform profile refs to UUIDs
        if (['profile'].includes(input)) {
          payload['user_profile_id'] = values[input];
        } else if (['care_guide'].includes(input)) {
          payload['care_guide_ids'] = careGuideIds;
        } else if (['care_guides'].includes(input)) {
          payload['care_guide_ids'] = careGuideIds;
        } else if (['parent_event', 'partnership'].includes(input)) {
          if (values[input]) {
            payload[input + '_id'] = values[input];
          }
        }
        // transform boolean values
        else if (['is_all_day'].includes(input)) {
          payload[input] = values[input] ? true : false;
          if (values[input]) {
            payload['start_at'] = Format.date(
              values['start_at_date'].format(),
              apiDatetimeFormat
            );
            payload['recurring_end_at'] = values['recurring_end_at']
              ? Format.date(
                  values['recurring_end_at'].format(),
                  apiDatetimeFormat
                )
              : null;
          } else {
            // prep moment date objects so we can manipulate them
            const endDateObj = values['end_at_date']
              ? moment(values['end_at_date'], 'MM/DD/YYYY')
              : null;

            const endTimeObj = values['end_at_time']
              ? moment(values['end_at_time'], ['h:mm A'])
              : null;
            const startTimeObj = moment(values['start_at_time'], ['h:mm A']);

            // if end time is less than start time then increment end date by one day
            if (endTimeObj && endTimeObj < startTimeObj) {
              endDateObj.add(1, 'day');
            }

            const startDate2 = moment(
              values['start_at_date'].format('YYYY-MM-DD') +
                ' ' +
                startTimeObj.format('HH:mm')
            );
            const endDate =
              endDateObj && endTimeObj
                ? moment(
                    endDateObj.format('YYYY-MM-DD') +
                      ' ' +
                      endTimeObj.format('HH:mm')
                  )
                : null;

            const recurringEndDate =
              values['recurring_end_at'] !== null
                ? moment(
                    values['recurring_end_at'].format('YYYY-MM-DD') +
                      ' ' +
                      endTimeObj.format('HH:mm')
                  )
                : null;
            payload['start_at'] = Format.date(
              startDate2.format(),
              apiDatetimeFormat
            );
            payload['end_at'] = endDate
              ? Format.date(endDate.format(), apiDatetimeFormat)
              : null;
            payload['recurring_end_at'] = recurringEndDate
              ? Format.date(recurringEndDate.format(), apiDatetimeFormat)
              : null;
          }
        } else if (
          [
            'start_at_date',
            'start_at_time',
            'end_at_date',
            'end_at_time',
            'recurring_end_at',
          ].includes(input)
        ) {
          // do nothing
        }
        // handle participants
        else if (input === 'participants') {
          if (Array.isArray(values[input])) {
            payload[input] = values[input].map((x, i) => {
              const { id, key, profile, ...participant } = x; // remove unused properties
              return { user_profile_id: profile.id, ...participant };
            });
          } else {
            payload[input] = [];
          }
        } else if (['url_event_partner', 'url_label'].includes(input)) {
          if (values[input]) {
            if (values[input].length > 0) {
              payload[input] = values[input];
            }
          }
        } else {
          payload[input] = values[input];
        }
      } else if (input === 'participants') {
        if (!isMembersIndividual) {
          payload['is_members_individual'] = isMembersIndividual;
        }
      }
    }

    if (id) {
      // update
      props.update(id, payload, (success) => {
        if (success) {
          setIsChangeData(false);
          message.success(getI18n().t('feedback_form_success'));
        } else {
          message.error(getI18n().t('feedback_form_error'));
        }
      });
    } else {
      // create
      props.create(payload, (success) => {
        if (success) {
          setIsChangeData(false);
          message.success(getI18n().t('feedback_form_success'));
        } else {
          message.error(getI18n().t('feedback_form_error'));
        }
      });
    }
  };

  // form submit handler
  const handleFinish = async (values) => {
    Logger.log('debug', `CalendarEventForm.handleFinish(###)`);
    if (!props.isSubmitting) {
      await submitData(values);
    }
  };

  // form error handler
  const handleFinishFailed = ({ values, errorFields, outOfDate }) => {
    Logger.log('debug', `CalendarEventForm.handleFinishFailed(###)`);
    message.error(getI18n().t('feedback_form_error'));
    if (errorFields && errorFields.length > 0) {
      form.scrollToField(errorFields[0].name);
    }
  };
  const onStartTimeChangeHandler = (value, option) => {
    // get end time value (as string)
    const endTimeValue = form.getFieldValue('end_at_time');

    // get date values (as moment.js objects)
    const startDate2 = form.getFieldValue('start_at_date');
    const endDate = form.getFieldValue('end_at_date');

    // turn time values into moment.js objects
    const endTime = endTimeValue ? moment(endTimeValue, ['h:mm A']) : null;
    const startTime = moment(value, ['h:mm A']);

    // if `end_at_time` has no value, set time to `start_at_time` (value) + 1 hour
    if (!endTimeValue) {
      form.setFieldsValue({
        end_at_time: startTime.add(1, 'hour').format('h:mm A'),
      });

      // if `start_at_date` and `end_at_date` are set to same day and `start_at_time` is later than `end_at_time`,
      // set time to `start_at_time` (value) + 1 hour
      // } else if (startDate2 && endDate && startDate2.format("YYYY-MM-DD") === endDate.format("YYYY-MM-DD") && startTime && endTime && startTime > endTime) {
    } else if (
      startDate2 &&
      endDate &&
      startDate2.format('MM/DD/YYYY') === endDate &&
      startTime &&
      endTime &&
      startTime >= endTime
    ) {
      form.setFieldsValue({
        end_at_time: startTime.add(1, 'hour').format('h:mm A'),
      });
    }
  };

  const onEndTimeChangeHandler = (value, option) => {
    // get start time value (as string)
    const startTimeValue = form.getFieldValue('start_at_time');

    // get date values (as moment.js objects)
    const startDate2 = form.getFieldValue('start_at_date');
    const endDate = form.getFieldValue('end_at_date');

    // turn time values into moment.js objects
    const startTime = startTimeValue
      ? moment(startTimeValue, ['h:mm A'])
      : null;
    const endTime = moment(value, ['h:mm A']);

    // if `start_at_date` and `end_at_date` are set to same day and `start_at_time` is later than `end_at_time`,
    // set time to `start_at_time` (value) + 1 hour
    // if (startDate2 && endDate && startDate2.format("YYYY-MM-DD") === endDate.format("YYYY-MM-DD") && startTime && endTime && startTime > endTime) {
    if (
      startDate2 &&
      endDate &&
      startDate2.format('MM/DD/YYYY') === endDate &&
      startTime &&
      endTime &&
      startTime > endTime
    ) {
      form.setFieldsValue({
        end_at_time: startTime.add(1, 'hour').format('h:mm A'),
      });
    }
  };

  const onChangeStartDate = (date, dateString) => {
    setStartDate(date);
    form.setFieldsValue({ end_at_date: Format.date(date, 'MM/DD/YYYY') });
    const lastDate = form.getFieldValue('recurring_end_at');
    const recurring = form.getFieldValue('recurring');
    if ([2, 3, 4, 5].includes(recurring) && (!lastDate || lastDate < date)) {
      form.setFieldsValue({ recurring_end_at: date });
    }
  };

  const disableLastDateHandler = (current) => {
    return startDate && current && current < startDate.startOf('day');
  };

  const onRecurringChangeHandler = (val) => {
    setIsRecurring([2, 3, 4, 5].includes(val));
    const lastDate = form.getFieldValue('recurring_end_at');
    const recurring = form.getFieldValue('recurring');
    if ([2, 3, 4, 5].includes(recurring)) {
      if (lastDate !== null && lastDate < startDate) {
        form.setFieldsValue({ recurring_end_at: startDate });
      }
    } else {
      form.setFieldsValue({ recurring_end_at: null });
    }
  };

  const handleChange = (value) => {
    if (form) {
      form.setFieldsValue({
        participants: [],
      });
    }
    setCareGuideIds(value);
    setIsChangeData(true);
    return value;
  };

  const handleChangePartnership = (value) => {
    if (form) {
      form.setFieldsValue({
        participants: [],
        care_guides: [],
      });
    }
    setPartnershipId(value);
    setIsChangeData(true);
    return value;
  };

  const participantColumns = [
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 100,
    },
    {
      title: 'Actions',
      dataIndex: 'edit',
      key: 'edit',
      width: 100,
    },
  ];

  const onChangeCareGuide = (e) => {
    setCareGuideIds([e]);
    form.setFieldsValue({ profile: null });
  };

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <Translation>
      {(t) => (
        <>
          <div className="calendar-event-form">
            <Form
              name="calendar_event_form"
              form={form}
              initialValues={data}
              onFinish={handleFinish}
              onFinishFailed={handleFinishFailed}
              validateTrigger="onSubmit"
              {...layout.main}
            >
              <Row gutter={16}>
                <Col xs={24} lg={18}>
                  <Card
                    title={
                      id
                        ? t('calendar_event_edit_title')
                        : t('calendar_event_add_title')
                    }
                    extra={
                      isLoading ? (
                        <Spin
                          indicator={
                            <LoadingOutlined style={{ fontSize: 20 }} spin />
                          }
                        />
                      ) : null
                    }
                  >
                    <div className="form-group">
                      <Form.Item name="is_event_partner" {...formItemCheckBox}>
                        <Checkbox
                          checked={isEventPartner}
                          onChange={(evt) => onChangeIsEventPartner(evt)}
                          disabled={isLoading || isSubmitting}
                        >
                          {t('calendar_event_is_event_partner')}
                        </Checkbox>
                      </Form.Item>
                    </div>

                    {isEventPartner && (
                      <div className="form-group">
                        <Form.Item
                          name="is_one_care_guide"
                          {...formItemCheckBox}
                        >
                          <Checkbox
                            checked={isMultipleCareGuide}
                            onChange={(evt) => onChangeIsMultipleCareGuide(evt)}
                            disabled={isLoading || isSubmitting}
                          >
                            {t('calendar_event_is_one_care_guide')}
                          </Checkbox>
                        </Form.Item>
                      </div>
                    )}
                    {isEventPartner ? (
                      <>
                        <PartnershipInput
                          form={form}
                          name="partnership"
                          label={t('calendar_event_partnership')}
                          disabled={isLoading || isSubmitting}
                          onChange={handleChangePartnership}
                          rules={[
                            {
                              required: true,
                              message: t('feedback_validation_required'),
                            },
                          ]}
                        />
                        <div className="form-group">
                          <Form.Item
                            name="url_event_partner"
                            label={t('calendar_event_url_event_partner')}
                          >
                            <Input
                              autoFocus
                              disabled={isLoading || isSubmitting}
                            />
                          </Form.Item>
                        </div>
                        <div className="form-group">
                          <Form.Item
                            name="url_label"
                            label={t('calendar_event_url_label')}
                            rules={[
                              {
                                type: 'string',
                                min: 1,
                                max: 20,
                                message: t('feedback_validation_length', {
                                  min: 1,
                                  max: 20,
                                }),
                              },
                            ]}
                          >
                            <Input
                              autoFocus
                              disabled={isLoading || isSubmitting}
                            />
                          </Form.Item>
                        </div>
                        {isMultipleCareGuide && (
                          <>
                            <CareGuidePartnershipInput
                              form={form}
                              multiple={true}
                              name="care_guides"
                              label={t('calendar_event_care_guides')}
                              partnershipId={partnershipId}
                              onChange={handleChange}
                              rules={[
                                {
                                  required: true,
                                  message: t('feedback_validation_required'),
                                },
                              ]}
                            />
                            <UserPartnerPartnershipInput
                              partnershipId={partnershipId}
                              name="profile"
                              label={t('calendar_event_participant_user')}
                              rules={[
                                {
                                  required: true,
                                  message: t('feedback_validation_required'),
                                },
                              ]}
                              form={form}
                              disabled={isLoading || isSubmitting}
                            />
                          </>
                        )}

                        {!isMultipleCareGuide && (
                          <UserPartnerPartnershipInput
                            partnershipId={partnershipId}
                            name="profile"
                            label={t('calendar_event_participant_user')}
                            rules={[
                              {
                                required: true,
                                message: t('feedback_validation_required'),
                              },
                            ]}
                            form={form}
                            disabled={isLoading || isSubmitting}
                          />
                        )}
                      </>
                    ) : (
                      <>
                        <CareGuideInput
                          form={form}
                          name="care_guide"
                          label={t('calendar_event_care_guide')}
                          disabled={isLoading || isSubmitting}
                          onChange={onChangeCareGuide}
                          rules={[
                            {
                              required: true,
                              message: t('feedback_validation_required'),
                            },
                          ]}
                        />
                        <UserCareGuidesInput
                          careGuideIds={careGuideIds}
                          name="profile"
                          label={t('calendar_event_profile')}
                          rules={[
                            {
                              required: true,
                              message: t('feedback_validation_required'),
                            },
                          ]}
                          form={form}
                          disabled={isLoading || isSubmitting}
                        />
                      </>
                    )}

                    <div className="form-group">
                      <Form.Item
                        name="title"
                        label={t('calendar_event_title')}
                        rules={[
                          {
                            required: true,
                            message: t('feedback_validation_required'),
                          },
                          {
                            type: 'string',
                            min: 0,
                            max: 250,
                            message: t('feedback_validation_char_range', {
                              min: 0,
                              max: 250,
                            }),
                          },
                        ]}
                      >
                        <Input disabled={isLoading || isSubmitting} />
                      </Form.Item>
                    </div>

                    <div className="form-group">
                      <Form.Item
                        name="is_all_day"
                        valuePropName="checked"
                        {...formItemCheckBox}
                      >
                        <Checkbox
                          onChange={(evt) => setIsAllDay(evt.target.checked)}
                          disabled={isLoading || isSubmitting}
                        >
                          {t('calendar_event_is_all_day')}
                        </Checkbox>
                      </Form.Item>
                    </div>

                    <div className="form-group">
                      <Form.Item
                        name="start_at_date"
                        style={{ marginBottom: 0 }}
                        label={t('calendar_event_start_date')}
                        rules={[
                          {
                            required: true,
                            message: t('feedback_validation_required'),
                          },
                        ]}
                      >
                        <Row gutter={16}>
                          <Col span={8}>
                            <div className="form-group">
                              <Form.Item name="start_at_date">
                                <DatePicker
                                  placeholder={t('calendar_event_start_date')}
                                  format="MM/DD/YYYY"
                                  onChange={onChangeStartDate}
                                  disabled={isLoading || isSubmitting}
                                />
                              </Form.Item>
                            </div>
                          </Col>

                          <Col span={8}>
                            {!isAllDay ? (
                              <TimeInput
                                name="start_at_time"
                                placeholder={t(
                                  'calendar_event_form_input_start_at_time'
                                )}
                                label={t(
                                  'calendar_event_form_input_start_at_time'
                                )}
                                rules={[
                                  {
                                    required: true,
                                    message: t('feedback_validation_required'),
                                  },
                                ]}
                                disabled={isLoading || isSubmitting}
                                onChange={onStartTimeChangeHandler}
                              />
                            ) : null}
                          </Col>

                          {!isAllDay ? (
                            <Col span={8}>
                              <TimeInput
                                placeholder={t(
                                  'calendar_event_form_input_end_at_time'
                                )}
                                name="end_at_time"
                                label={t(
                                  'calendar_event_form_input_end_at_time'
                                )}
                                rules={[
                                  {
                                    required: true,
                                    message: t('feedback_validation_required'),
                                  },
                                ]}
                                disabled={
                                  !startDate || isLoading || isSubmitting
                                }
                                onChange={onEndTimeChangeHandler}
                              />
                            </Col>
                          ) : null}

                          <Form.Item name="end_at_date">
                            <Input type="hidden" />
                          </Form.Item>
                        </Row>
                      </Form.Item>
                    </div>

                    <div className="form-group">
                      <Form.Item
                        name="recurring"
                        style={{ marginBottom: 0 }}
                        label={t('calendar_event_recurring')}
                        rules={[
                          {
                            required: true,
                            message: t('feedback_validation_required'),
                          },
                        ]}
                      >
                        <Row gutter={16}>
                          <Col span={8}>
                            <RecurringInput
                              name="recurring"
                              disabled={isLoading || isSubmitting}
                              onChange={onRecurringChangeHandler}
                            />
                          </Col>

                          <Col span={8}>
                            <div className="form-group">
                              <Form.Item
                                name="recurring_end_at"
                                label={t('calendar_event_last_date')}
                              >
                                <DatePicker
                                  placeholder={
                                    isRecurring
                                      ? t(
                                          'calendar_event_form_placeholder_recurring_end_at_date'
                                        )
                                      : null
                                  }
                                  format="MM/DD/YYYY"
                                  allowClear={true}
                                  disabledDate={disableLastDateHandler}
                                  disabled={
                                    !isRecurring || isLoading || isSubmitting
                                  }
                                />
                              </Form.Item>
                            </div>
                          </Col>
                        </Row>
                      </Form.Item>
                    </div>

                    <div className="form-group">
                      <Form.Item
                        name="parent_event"
                        label={t('calendar_event_parent_event_id')}
                        rules={[]}
                      >
                        <Input disabled={isLoading || isSubmitting} />
                      </Form.Item>
                    </div>

                    <BodyInput
                      name="description"
                      label={t('calendar_event_description')}
                      form={form}
                      defaultValue={form.getFieldValue(['description'])}
                      disabled={isLoading || isSubmitting}
                    />

                    {isEventPartner && (
                      <div className="form-group">
                        <Form.Item
                          name="is_members_individual"
                          {...formItemCheckBox}
                        >
                          <Checkbox
                            checked={isMembersIndividual}
                            onChange={(evt) => onChangeIsMembersIndividual(evt)}
                            disabled={isLoading || isSubmitting}
                          >
                            {t('calendar_event_is_participants_individually')}
                          </Checkbox>
                        </Form.Item>
                      </div>
                    )}

                    {(isMembersIndividual || !isEventPartner) && (
                      <>
                        {id ? (
                          <Form.Item label={t('calendar_event_participants')}>
                            <div className="calendar-participants">
                              <Table
                                className="calendar-event-table-participants"
                                pagination={
                                  pageParticipants === 1 && total <= 10
                                    ? false
                                    : !isChangeData
                                    ? pagination
                                    : false
                                }
                                columns={participantColumns}
                                loading={areParticipantEventsLoading}
                                dataSource={
                                  !isChangeData
                                    ? list
                                      ? list.map((x) => {
                                          return {
                                            key: x.id,
                                            value: (
                                              <>
                                                {x?.profile?.first_name} {x?.profile?.last_name}
                                              </>
                                            ),
                                            status: t(
                                              'calendar_event_detail_public_status_' +
                                                x.public_status
                                            ),
                                            edit: (
                                              <>
                                                <Space>
                                                  <Button
                                                    icon={<EditOutlined />}
                                                    onClick={() =>
                                                      showParticipantForm(x.id)
                                                    }
                                                  />
                                                  <Button
                                                    onClick={() =>
                                                      deleteParticipant(
                                                        x.id,
                                                        (success) => {
                                                          if (success) {
                                                            loadParticipants(
                                                              id,
                                                              pageParticipants
                                                            );
                                                            message.success(
                                                              getI18n().t(
                                                                'feedback_delete_success'
                                                              )
                                                            );
                                                          } else {
                                                            message.error(
                                                              getI18n().t(
                                                                'feedback_delete_error'
                                                              )
                                                            );
                                                          }
                                                        }
                                                      )
                                                    }
                                                    icon={
                                                      <MinusCircleOutlined />
                                                    }
                                                  />
                                                </Space>
                                              </>
                                            ),
                                          };
                                        })
                                      : []
                                    : []
                                }
                                size="small"
                                showHeader={false}
                              />
                            </div>
                            <Form.Item style={{ marginBottom: 0 }}>
                              <Button
                                type="dashed"
                                onClick={() => showParticipantForm(null)}
                                block
                              >
                                <PlusOutlined /> Add participant
                              </Button>
                            </Form.Item>
                          </Form.Item>
                        ) : (
                          <Form.Item label={t('calendar_event_participants')}>
                            <Form.List name="participants">
                              {(participants, { add, remove, move }) => {
                                return (
                                  <>
                                    <div className="scroll-participants">
                                      {participants.map((participant, i) => (
                                        <ParticipantInputRow
                                          isEventPartner={isEventPartner}
                                          isMultipleCareGuide={
                                            isMultipleCareGuide
                                          }
                                          firstLoad={firstLoad}
                                          setFirstLoad={setFirstLoad}
                                          partnershipId={partnershipId}
                                          participant={participant}
                                          careGuideIds={careGuideIds}
                                          disabled={isLoading || isSubmitting}
                                          remove={remove}
                                          index={i}
                                          key={i}
                                          form={form}
                                        />
                                      ))}
                                    </div>
                                    <Form.Item style={{ marginBottom: 0 }}>
                                      <Button
                                        type="dashed"
                                        onClick={() => {
                                          add();
                                        }}
                                        block
                                      >
                                        <PlusOutlined /> Add participant
                                      </Button>
                                    </Form.Item>
                                  </>
                                );
                              }}
                            </Form.List>
                          </Form.Item>
                        )}
                      </>
                    )}
                  </Card>
                </Col>

                <Col xs={24} lg={6}>
                  <Affix offsetTop={10}>
                    <Card title={t('form_metadata_header')}>
                      <FormMetadata
                        id={id}
                        isSubmitting={isSubmitting}
                        delete={props.delete.bind(this)}
                        deleteRedirectTo="CalendarEventsScreen"
                        createdAt={props.createdAt}
                        updatedAt={props.updatedAt}
                      />
                    </Card>
                  </Affix>
                </Col>
              </Row>
            </Form>
            <EventParticipantFormModal
              eventId={id}
              isEventPartner={isEventPartner}
              isMultipleCareGuide={isMultipleCareGuide}
              partnershipId={partnershipId}
              careGuideIds={careGuideIds}
            />
          </div>
        </>
      )}
    </Translation>
  );
};

export default CalendarEventForm;

Logger.log('silly', `CalendarEventForm loaded.`);
