import {getI18n} from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import MessagesScreen from './containers/MessagesScreenContainer';
import MessageAddScreen from './containers/MessageAddScreenContainer';
import MessageEditScreen from './containers/MessageEditScreenContainer';

const register = () => {
  Logger.log('debug', `messages.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'messages', 'routes'])) {
    Events.dispatch('ADD_MAIN_ROUTES', 
      {
        'MessageAddScreen': ['PrivateRoute', '/messages/add', true, MessageAddScreen, getI18n().t('message_route_add')],
        'MessageEditScreen': ['PrivateRoute', '/messages/edit/:id([\\w\\-]+)', true, MessageEditScreen, getI18n().t('message_route_edit')],
        'MessagesScreen': ['PrivateRoute', '/messages/:page(\\d+)?', true, MessagesScreen, getI18n().t('messages_route_list')],
      }
    );
  }
}

export default register;

Logger.log('silly', `messages.register() loaded.`);
