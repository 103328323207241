import {connect} from 'react-redux';

import TwoFactorAuthScreen from '../components/TwoFactorAuthScreen';
import Auth from '../../../../../lib/Auth';

const mapStateToProps = (state, ownProps) => {
  return {
    isAuthenticated: Auth.isAuthTokenValid(state.session.get('authToken'), state.session.get('authExpires')),
    twoFactorsecret: state.session.get('twoFactorsecret'),
    provisioningURI: state.session.get('provisioningURI'),
  }
}

const TwoFactorAuthScreenContainer = connect(
  mapStateToProps
)(TwoFactorAuthScreen);

export default TwoFactorAuthScreenContainer;
