import {connect} from 'react-redux';

import {downloadPlansCSV} from '../../../../../state/modules/plans/actions';
import PlansCSVButton from '../components/PlansCSVButton';

const mapStateToProps = state => {
  return {
    isDownloading: state.plans.get('isCSVDownloading'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    download: (order, filter, cb) => {
      dispatch(downloadPlansCSV(order, filter, cb));
    }
  }
}

const PlansCSVButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PlansCSVButton);

export default PlansCSVButtonContainer;
