import {normalize} from 'normalizr';

import api from '../../api';
import {schema} from '../../schema';
import {addEntities, removeEntity} from '../../actions';
import Format from '../../../lib/Format';
import Logger from '../../../lib/Logger';

// ACTION TYPES

export const ACCOUNT_LIST_REQUEST = 'ACCOUNT_LIST_REQUEST';
export const ACCOUNT_LIST_SUCCESS = 'ACCOUNT_LIST_SUCCESS';
export const ACCOUNT_LIST_FAILURE = 'ACCOUNT_LIST_FAILURE';
export const ACCOUNT_READ_REQUEST = 'ACCOUNT_READ_REQUEST';
export const ACCOUNT_READ_SUCCESS = 'ACCOUNT_READ_SUCCESS';
export const ACCOUNT_READ_FAILURE = 'ACCOUNT_READ_FAILURE';
export const ACCOUNT_UPDATE_REQUEST = 'ACCOUNT_UPDATE_REQUEST';
export const ACCOUNT_UPDATE_SUCCESS = 'ACCOUNT_UPDATE_SUCCESS';
export const ACCOUNT_UPDATE_FAILURE = 'ACCOUNT_UPDATE_FAILURE';
export const ACCOUNT_CREATE_REQUEST = 'ACCOUNT_CREATE_REQUEST';
export const ACCOUNT_CREATE_SUCCESS = 'ACCOUNT_CREATE_SUCCESS';
export const ACCOUNT_CREATE_FAILURE = 'ACCOUNT_CREATE_FAILURE';
export const ACCOUNT_DELETE_REQUEST = 'ACCOUNT_DELETE_REQUEST';
export const ACCOUNT_DELETE_SUCCESS = 'ACCOUNT_DELETE_SUCCESS';
export const ACCOUNT_DELETE_FAILURE = 'ACCOUNT_DELETE_FAILURE';
export const ACCOUNT_FORM_DESTROY = 'ACCOUNT_FORM_DESTROY';
export const ACCOUNT_CSV_DOWNLOAD_REQUEST = 'ACCOUNT_CSV_DOWNLOAD_REQUEST';
export const ACCOUNT_CSV_DOWNLOAD_SUCCESS = 'ACCOUNT_CSV_DOWNLOAD_SUCCESS';
export const ACCOUNT_CSV_DOWNLOAD_FAILURE = 'ACCOUNT_CSV_DOWNLOAD_FAILURE';

// ACTION CREATORS

export function accountListRequest(page, limit, order, filter) {
  Logger.log('debug', `[accounts.actions] accountListRequest(${page}, ${limit}, ${order}, %j)`, filter);
  return {
    type: ACCOUNT_LIST_REQUEST,
    page: page,
    limit: limit,
    order: order,
    filter: filter
  }
}

export function accountListSuccess(data) {
  Logger.log('debug', `[accounts.actions] accountListSuccess(%j)`, data);
  return {
    type: ACCOUNT_LIST_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now()
  }
}

export function accountListFailure(error) {
  Logger.log('debug', `[accounts.actions] accountListFailure(%j)`, error);
  return {
    type: ACCOUNT_LIST_FAILURE,
    error: error
  }
}

export function accountReadRequest(id) {
  Logger.log('debug', `[accounts.actions] accountReadRequest(${id})`);
  return {
    type: ACCOUNT_READ_REQUEST,
    id: id
  }
}

export function accountReadSuccess(data) {
  Logger.log('debug', `[accounts.actions] accountReadSuccess(%j)`, data);
  return {
    type: ACCOUNT_READ_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function accountReadFailure(error) {
  Logger.log('debug', `[accounts.actions] accountReadFailure(%j)`, error);
  return {
    type: ACCOUNT_READ_FAILURE,
    error: error
  }
}

export function accountUpdateRequest(id, data) {
  Logger.log('debug', `[accounts.actions] accountUpdateRequest(${id}, %j)`, data);
  return {
    type: ACCOUNT_UPDATE_REQUEST,
  }
}

export function accountUpdateSuccess(data) {
  Logger.log('debug', `[accounts.actions] accountUpdateSuccess(%j)`, data);
  return {
    type: ACCOUNT_UPDATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function accountUpdateFailure(error) {
  Logger.log('debug', `[accounts.actions] accountUpdateFailure(%j)`, error);
  return {
    type: ACCOUNT_UPDATE_FAILURE,
    error: error
  }
}

export function accountCreateRequest(data) {
  Logger.log('debug', `[accounts.actions] accountCreateRequest(%j)`, data);
  return {
    type: ACCOUNT_CREATE_REQUEST,
  }
}

export function accountCreateSuccess(data) {
  Logger.log('debug', `[accounts.actions] accountCreateSuccess(%j)`, data);
  return {
    type: ACCOUNT_CREATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function accountCreateFailure(error) {
  Logger.log('debug', `[accounts.actions] accountCreateFailure(%j)`, error);
  return {
    type: ACCOUNT_CREATE_FAILURE,
    error: error
  }
}

export function accountDeleteRequest(id) {
  Logger.log('debug', `[accounts.actions] accountDeleteRequest(${id})`);
  return {
    type: ACCOUNT_DELETE_REQUEST,
    id: id
  }
}

export function accountDeleteSuccess(id) {
  Logger.log('debug', `[accounts.actions] accountDeleteSuccess(${id})`);
  return {
    type: ACCOUNT_DELETE_SUCCESS,
    id: id,
  }
}

export function accountDeleteFailure(error) {
  Logger.log('debug', `[accounts.actions] accountDeleteFailure(%j)`, error);
  return {
    type: ACCOUNT_DELETE_FAILURE,
    error: error
  }
}

export function accountFormDestroy(formState=null) {
  Logger.log('debug', `[accounts.actions] accountFormDestroy(%j)`, formState);
  return {
    type: ACCOUNT_FORM_DESTROY,
    form: formState
  }
}

export function accountCSVDownloadRequest(order, filter) {
  Logger.log('debug', `[state.accounts.actions] accountCSVDownloadRequest()`);
  return {
    type: ACCOUNT_CSV_DOWNLOAD_REQUEST,
    order: order,
    filter: filter,
  }
}

export function accountCSVDownloadSuccess() {
  Logger.log('debug', `[state.accounts.actions] accountCSVDownloadSuccess()`);
  return {
    type: ACCOUNT_CSV_DOWNLOAD_SUCCESS,
  }
}

export function accountCSVDownloadFailure() {
  Logger.log('debug', `[state.accounts.actions] accountCSVDownloadFailure()`);
  return {
    type: ACCOUNT_CSV_DOWNLOAD_FAILURE,
  }
}


// API THUNK ACTION CREATORS

export function loadAccounts(page=1, limit=10, order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[accounts.actions] loadAccounts(${page}, ${limit}, ${order}, %j, ###)`, filter);

  return async function(dispatch) {
    dispatch(accountListRequest(page, limit, order, filter));

    // call API
    const response = await api.getAccounts(page, limit, order, filter);
    let success = false;
    let result = [];
    // get accounts list success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API accounts list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`);

      const normalizedEntities = normalize(response.getIn(['data', 'accounts']), [schema.account]);
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(accountListSuccess(data));
      success = true;
      result = normalizedEntities.result;

    } else if (1 === page && 204 === response.get('status')) {

      Logger.log('info', `Get API accounts success [empty]. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: []
      };
      dispatch(accountListSuccess(data));
      success = true;
      
    // get accounts list failure
    } else {
      Logger.log('info', `Get API accounts list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      dispatch(accountListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success, result);
  }
}

export function loadAccount(id, cb=function(){}) {
  Logger.log('debug', `[accounts.actions] loadAccount(${id}, ###)`);

  return async function(dispatch) {
    dispatch(accountReadRequest(id));

    // call API
    const response = await api.getAccount(id);
    let success = false;

    // get account success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API account success. ID: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'account'])], [schema.account]);
      const data = {
        id: response.getIn(['data', 'account', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(accountReadSuccess(data));
      success = true;
      
    // get account failure
    } else {
      Logger.log('info', `Get API account failure. ID: ${id}.`);
      dispatch(accountReadFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function updateAccount(id, data, cb=function(){}) {
  Logger.log('debug', `[accounts.actions] updateAccount(${id}, %j, ###)`, data);

  return async function(dispatch) {
    dispatch(accountUpdateRequest(id, data));

    // call API
    const response = await api.putAccount(id, data);
    let success = false;

    // put account success
    if (200 === response.get('status')) {

      Logger.log('info', `PUT API account success. User: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'account'])], [schema.account]);
      const data = {
        id: response.getIn(['data', 'account', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(accountUpdateSuccess(data));
      success = true;
      
    // get account failure
    } else {
      Logger.log('info', `PUT API account failure. ID: ${id}.`);
      dispatch(accountUpdateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function createAccount(data, cb=function(){}) {
  Logger.log('debug', `[accounts.actions] createAccount(%j, ###)`, data);

  return async function(dispatch) {
    dispatch(accountCreateRequest(data));

    // call API
    const response = await api.postAccounts(data);
    let success = false;

    // post accounts success
    if (201 === response.get('status')) {

      Logger.log('info', `POST API accounts success. Post: ${response.getIn(['data', 'account', 'id'])}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'account'])], [schema.account]);
      const data = {
        id: response.getIn(['data', 'account', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(accountCreateSuccess(data));
      success = true;
      
    // get accounts failure
    } else {
      Logger.log('info', `POST API accounts failure.`);
      dispatch(accountCreateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function deleteAccount(id, cb=function(){}) {
  Logger.log('debug', `[accounts.actions] deleteAccount(${id}, ###)`);

  return async function(dispatch) {
    dispatch(accountDeleteRequest(id));

    // call API
    const response = await api.deleteAccount(id);
    let success = false;

    // delete account success
    if (204 === response.get('status')) {

      Logger.log('info', `DELETE API account success. ID: ${id}.`);

      dispatch(removeEntity({entityType: 'accounts', id: id}));
      dispatch(accountDeleteSuccess(id));
      success = true;
      
    // get account failure
    } else {
      Logger.log('info', `DELETE API account failure. ID: ${id}.`);
      dispatch(accountDeleteFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function downloadAccountsCSV(order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[state.accounts.actions] downloadAccountsCSV(${order}, ${filter}, ###)`);

  return async function(dispatch) {
    dispatch(accountCSVDownloadRequest(order, filter));

    // call API
    const response = await api.getAccountsCSV(order, filter);

    // get accounts CSV success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API accounts CSV success. Order: ${order}`);
      const now = new Date();

      // trigger browser download
      const url = window.URL.createObjectURL(new Blob([response.get('data')]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `cv-accounts-${Format.date(now, 'YYYY-MM-DDTHHmm')}.csv`);
      document.body.appendChild(link);
      link.click();

      dispatch(accountCSVDownloadSuccess());
      
    // get accounts CSV failure
    } else {
      Logger.log('info', `Get API accounts CSV failure.`);
      dispatch(accountCSVDownloadFailure());
    }

    // callback function
    cb();
  }
}

Logger.log('silly', `accounts.actions loaded.`);
