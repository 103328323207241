import {connect} from 'react-redux';

import {downloadAdminstratorsCSV} from '../../../../../state/modules/administrators/actions';
import AdministratorsCSVButton from '../components/AdministratorsCSVButton';

const mapStateToProps = state => {
  return {
    isDownloading: state.administrators.get('isCSVDownloading'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    download: (order, filter, cb) => {
      dispatch(downloadAdminstratorsCSV(order, filter, cb));
    }
  }
}

const AdministratorsCSVButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdministratorsCSVButton);

export default AdministratorsCSVButtonContainer;
