import {connect} from 'react-redux';

import RelationshipsScreen from '../components/RelationshipsScreen';

const mapStateToProps = state => {
  return {
    total: state.relationships.get('total'),
    isLoading: state.relationships.get('isLoading')
  }
}

const RelationshipsScreenContainer = connect(
  mapStateToProps
)(RelationshipsScreen);

export default RelationshipsScreenContainer;
