import {normalize} from 'normalizr';

import api from '../../api';
import {schema} from '../../schema';
import {addEntities, removeEntity} from '../../actions';
import Format from '../../../lib/Format';
import Logger from '../../../lib/Logger';

// ACTION TYPES

export const MEDIA_LIST_REQUEST = 'MEDIA_LIST_REQUEST';
export const MEDIA_LIST_SUCCESS = 'MEDIA_LIST_SUCCESS';
export const MEDIA_LIST_FAILURE = 'MEDIA_LIST_FAILURE';
export const MEDIA_READ_REQUEST = 'MEDIA_READ_REQUEST';
export const MEDIA_READ_SUCCESS = 'MEDIA_READ_SUCCESS';
export const MEDIA_READ_FAILURE = 'MEDIA_READ_FAILURE';
export const MEDIA_UPDATE_REQUEST = 'MEDIA_UPDATE_REQUEST';
export const MEDIA_UPDATE_SUCCESS = 'MEDIA_UPDATE_SUCCESS';
export const MEDIA_UPDATE_FAILURE = 'MEDIA_UPDATE_FAILURE';
export const MEDIA_CREATE_REQUEST = 'MEDIA_CREATE_REQUEST';
export const MEDIA_CREATE_SUCCESS = 'MEDIA_CREATE_SUCCESS';
export const MEDIA_CREATE_FAILURE = 'MEDIA_CREATE_FAILURE';
export const MEDIA_DELETE_REQUEST = 'MEDIA_DELETE_REQUEST';
export const MEDIA_DELETE_SUCCESS = 'MEDIA_DELETE_SUCCESS';
export const MEDIA_DELETE_FAILURE = 'MEDIA_DELETE_FAILURE';
export const MEDIA_CARE_GUIDE_LIST_REQUEST = 'MEDIA_CARE_GUIDE_LIST_REQUEST';
export const MEDIA_CARE_GUIDE_LIST_SUCCESS = 'MEDIA_CARE_GUIDE_LIST_SUCCESS';
export const MEDIA_CARE_GUIDE_LIST_FAILURE = 'MEDIA_CARE_GUIDE_LIST_FAILURE';
export const MEDIA_PARTNERSHIP_LIST_REQUEST = 'MEDIA_PARTNERSHIP_LIST_REQUEST';
export const MEDIA_PARTNERSHIP_LIST_SUCCESS = 'MEDIA_PARTNERSHIP_LIST_SUCCESS';
export const MEDIA_PARTNERSHIP_LIST_FAILURE = 'MEDIA_PARTNERSHIP_LIST_FAILURE';
export const MEDIA_FORM_DESTROY = 'MEDIA_FORM_DESTROY';
export const MEDIA_CSV_DOWNLOAD_REQUEST = 'MEDIA_CSV_DOWNLOAD_REQUEST';
export const MEDIA_CSV_DOWNLOAD_SUCCESS = 'MEDIA_CSV_DOWNLOAD_SUCCESS';
export const MEDIA_CSV_DOWNLOAD_FAILURE = 'MEDIA_CSV_DOWNLOAD_FAILURE';

// ACTION CREATORS

export function mediaListRequest(page, limit, order, filter) {
  Logger.log('debug', `[media.actions] mediaListRequest(${page}, ${limit}, ${order}, %j)`, filter);
  return {
    type: MEDIA_LIST_REQUEST,
    page: page,
    limit: limit,
    order: order,
    filter: filter
  }
}

export function mediaListSuccess(data) {
  Logger.log('debug', `[media.actions] mediaListSuccess(%j)`, data);
  return {
    type: MEDIA_LIST_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now()
  }
}

export function mediaListFailure(error) {
  Logger.log('debug', `[media.actions] mediaListFailure(%j)`, error);
  return {
    type: MEDIA_LIST_FAILURE,
    error: error
  }
}

export function mediaReadRequest(id) {
  Logger.log('debug', `[media.actions] mediaReadRequest(${id})`);
  return {
    type: MEDIA_READ_REQUEST,
    id: id
  }
}

export function mediaReadSuccess(data) {
  Logger.log('debug', `[media.actions] mediaReadSuccess(%j)`, data);
  return {
    type: MEDIA_READ_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function mediaReadFailure(error) {
  Logger.log('debug', `[media.actions] mediaReadFailure(%j)`, error);
  return {
    type: MEDIA_READ_FAILURE,
    error: error
  }
}

export function mediaUpdateRequest(id, data) {
  Logger.log('debug', `[media.actions] mediaUpdateRequest(${id}, %j)`, data);
  return {
    type: MEDIA_UPDATE_REQUEST,
  }
}

export function mediaUpdateSuccess(data) {
  Logger.log('debug', `[media.actions] mediaUpdateSuccess(%j)`, data);
  return {
    type: MEDIA_UPDATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function mediaUpdateFailure(error) {
  Logger.log('debug', `[media.actions] mediaUpdateFailure(%j)`, error);
  return {
    type: MEDIA_UPDATE_FAILURE,
    error: error
  }
}

export function mediaCreateRequest(data) {
  Logger.log('debug', `[media.actions] mediaCreateRequest(%j)`, data);
  return {
    type: MEDIA_CREATE_REQUEST,
  }
}

export function mediaCreateSuccess(data) {
  Logger.log('debug', `[media.actions] mediaCreateSuccess(%j)`, data);
  return {
    type: MEDIA_CREATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function mediaCreateFailure(error) {
  Logger.log('debug', `[media.actions] mediaCreateFailure(%j)`, error);
  return {
    type: MEDIA_CREATE_FAILURE,
    error: error
  }
}

export function mediaDeleteRequest(id) {
  Logger.log('debug', `[media.actions] mediaDeleteRequest(${id})`);
  return {
    type: MEDIA_DELETE_REQUEST,
    id: id
  }
}

export function mediaDeleteSuccess(id) {
  Logger.log('debug', `[media.actions] mediaDeleteSuccess(${id})`);
  return {
    type: MEDIA_DELETE_SUCCESS,
    id: id,
  }
}

export function mediaDeleteFailure(error) {
  Logger.log('debug', `[media.actions] mediaDeleteFailure(%j)`, error);
  return {
    type: MEDIA_DELETE_FAILURE,
    error: error
  }
}

export function mediaCareGuideListRequest(careGuideId, page, limit, order, filter) {
  Logger.log('debug', `[media.actions] mediaCareGuideListRequest(${careGuideId}, ${page}, ${limit}, ${order}, %j)`, filter);
  return {
    type: MEDIA_CARE_GUIDE_LIST_REQUEST,
    careGuideId: careGuideId,
    page: page,
    limit: limit,
    order: order,
    filter: filter
  }
}

export function mediaCareGuideListSuccess(data) {
  Logger.log('debug', `[media.actions] mediaCareGuideListSuccess(%j)`, data);
  return {
    type: MEDIA_CARE_GUIDE_LIST_SUCCESS,
    careGuideId: data.careGuideId,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now()
  }
}

export function mediaCareGuideListFailure(error) {
  Logger.log('debug', `[media.actions] mediaCareGuideListFailure(%j)`, error);
  return {
    type: MEDIA_CARE_GUIDE_LIST_FAILURE,
    error: error
  }
}

export function mediaPartnershipListRequest(partnershipId, page, limit, order, filter) {
  Logger.log('debug', `[media.actions] mediaPartnershipListRequest(${partnershipId}, ${page}, ${limit}, ${order}, %j)`, filter);
  return {
    type: MEDIA_PARTNERSHIP_LIST_REQUEST,
    partnershipId: partnershipId,
    page: page,
    limit: limit,
    order: order,
    filter: filter
  }
}

export function mediaPartnershipListSuccess(data) {
  Logger.log('debug', `[media.actions] mediaPartnershipListSuccess(%j)`, data);
  return {
    type: MEDIA_PARTNERSHIP_LIST_SUCCESS,
    partnershipId: data.partnershipId,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now()
  }
}

export function mediaPartnershipListFailure(error) {
  Logger.log('debug', `[media.actions] mediaPartnershipListFailure(%j)`, error);
  return {
    type: MEDIA_PARTNERSHIP_LIST_FAILURE,
    error: error
  }
}

export function mediaFormDestroy(formState=null) {
  Logger.log('debug', `[media.actions] mediaFormDestroy(%j)`, formState);
  return {
    type: MEDIA_FORM_DESTROY,
    form: formState
  }
}

export function mediaCSVDownloadRequest(order, filter) {
  Logger.log('debug', `[state.media.actions] mediaCSVDownloadRequest()`);
  return {
    type: MEDIA_CSV_DOWNLOAD_REQUEST,
    order: order,
    filter: filter,
  }
}

export function mediaCSVDownloadSuccess() {
  Logger.log('debug', `[state.media.actions] mediaCSVDownloadSuccess()`);
  return {
    type: MEDIA_CSV_DOWNLOAD_SUCCESS,
  }
}

export function mediaCSVDownloadFailure() {
  Logger.log('debug', `[state.media.actions] mediaCSVDownloadFailure()`);
  return {
    type: MEDIA_CSV_DOWNLOAD_FAILURE,
  }
}


// API THUNK ACTION CREATORS

export function loadMedia(page=1, limit=10, order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[media.actions] loadMedia(${page}, ${limit}, ${order}, %j, ###)`, filter);

  return async function(dispatch) {
    dispatch(mediaListRequest(page, limit, order, filter));

    // call API
    const response = await api.getMedia(page, limit, order, filter);
    let success = false;

    // get media list success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API media list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`);

      const normalizedEntities = normalize(response.getIn(['data', 'media']), [schema.medium]);
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(mediaListSuccess(data));
      success = true;

    } else if (1 === page && 204 === response.get('status')) {

      Logger.log('info', `Get API media success [empty]. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: []
      };
      dispatch(mediaListSuccess(data));
      success = true;
      
    // get media list failure
    } else {
      Logger.log('info', `Get API media list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      dispatch(mediaListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}


export function loadMedium(id, cb=function(){}) {
  Logger.log('debug', `[media.actions] loadMedium(${id}, ###)`);

  return async function(dispatch) {
    dispatch(mediaReadRequest(id));

    // call API
    const response = await api.getMedium(id);
    let success = false;

    // get medium success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API medium success. ID: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'medium'])], [schema.medium]);
      const data = {
        id: response.getIn(['data', 'medium', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(mediaReadSuccess(data));
      success = true;
      
    // get medium failure
    } else {
      Logger.log('info', `Get API medium failure. ID: ${id}.`);
      dispatch(mediaReadFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function updateMedium(id, data, cb=function(){}) {
  Logger.log('debug', `[media.actions] updateMedium(${id}, %j, ###)`, data);

  return async function(dispatch) {
    dispatch(mediaUpdateRequest(id, data));

    // call API
    const response = await api.putMedium(id, data);
    let success = false;

    // put medium success
    if (200 === response.get('status')) {

      Logger.log('info', `PUT API medium success. User: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'medium'])], [schema.medium]);
      const data = {
        id: response.getIn(['data', 'medium', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(mediaUpdateSuccess(data));
      success = true;
      
    // get medium failure
    } else {
      Logger.log('info', `PUT API medium failure. ID: ${id}.`);
      dispatch(mediaUpdateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function createMedia(data, cb=function(){}) {
  Logger.log('debug', `[media.actions] createMedia(%j, ###)`, data);

  return async function(dispatch) {
    dispatch(mediaCreateRequest(data));

    // call API
    const response = await api.postMedia(data);
    let success = false;

    // post media success
    if (201 === response.get('status')) {

      Logger.log('info', `POST API media success. Post: ${response.getIn(['data', 'medium', 'id'])}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'medium'])], [schema.medium]);
      const data = {
        id: response.getIn(['data', 'medium', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(mediaCreateSuccess(data));
      success = true;
      
    // get media failure
    } else {
      Logger.log('info', `POST API media failure.`);
      dispatch(mediaCreateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function deleteMedium(id, cb=function(){}) {
  Logger.log('debug', `[media.actions] deleteMedium(${id}, ###)`);

  return async function(dispatch) {
    dispatch(mediaDeleteRequest(id));

    // call API
    const response = await api.deleteMedium(id);
    let success = false;

    // delete medium success
    if (204 === response.get('status')) {

      Logger.log('info', `DELETE API medium success. ID: ${id}.`);

      dispatch(removeEntity({entityType: 'media', id: id}));
      dispatch(mediaDeleteSuccess(id));
      success = true;
      
    // get medium failure
    } else {
      Logger.log('info', `DELETE API medium failure. ID: ${id}.`);
      dispatch(mediaDeleteFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function loadCareGuideMedia(careGuideId, page=1, limit=10, order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[media.actions] loadCareGuideMedia(${careGuideId}, ${page}, ${limit}, ${order}, %j, ###)`, filter);

  return async function(dispatch) {
    dispatch(mediaCareGuideListRequest(careGuideId, page, limit, order, filter));

    // call API
    const response = await api.getCareGuideMedia(careGuideId, page, limit, order, filter);
    let success = false;

    // get care guide media list success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API care guide media list success. Care Guide: ${careGuideId}, Page: ${page}, Limit: ${limit}, Order: ${order}.`);

      const normalizedEntities = normalize(response.getIn(['data', 'media']), [schema.medium]);
      const data = {
        careGuideId: careGuideId,
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(mediaCareGuideListSuccess(data));
      success = true;

    } else if (1 === page && 204 === response.get('status')) {

      Logger.log('info', `Get API media success [empty]. Care Guide: ${careGuideId}, Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      const data = {
        careGuideId: careGuideId,
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: []
      };
      dispatch(mediaCareGuideListSuccess(data));
      success = true;
      
    // get care guide media list failure
    } else {
      Logger.log('info', `Get API care guide media list failure. Care Guide: ${careGuideId}, Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      dispatch(mediaCareGuideListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}


export function loadPartnershipMedia(partnershipId, page=1, limit=10, order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[media.actions] loadPartnershipMedia(${partnershipId}, ${page}, ${limit}, ${order}, %j, ###)`, filter);

  return async function(dispatch) {
    dispatch(mediaPartnershipListRequest(partnershipId, page, limit, order, filter));

    // call API
    const response = await api.getPartnershipMedia(partnershipId, page, limit, order, filter);
    let success = false;

    // get partnership media list success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API partnership media list success. Partnership: ${partnershipId}, Page: ${page}, Limit: ${limit}, Order: ${order}.`);

      const normalizedEntities = normalize(response.getIn(['data', 'media']), [schema.medium]);
      const data = {
        partnershipId: partnershipId,
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(mediaPartnershipListSuccess(data));
      success = true;

    } else if (1 === page && 204 === response.get('status')) {

      Logger.log('info', `Get API media success [empty]. Partnership: ${partnershipId}, Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      const data = {
        partnershipId: partnershipId,
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: []
      };
      dispatch(mediaPartnershipListSuccess(data));
      success = true;
      
    // get partnership media list failure
    } else {
      Logger.log('info', `Get API partnership media list failure. Partnership: ${partnershipId}, Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      dispatch(mediaPartnershipListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function downloadMediaCSV(order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[state.media.actions] downloadMediaCSV(${order}, ${filter}, ###)`);

  return async function(dispatch) {
    dispatch(mediaCSVDownloadRequest(order, filter));

    // call API
    const response = await api.getMediaCSV(order, filter);

    // get media CSV success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API media CSV success. Order: ${order}`);
      const now = new Date();

      // trigger browser download
      const url = window.URL.createObjectURL(new Blob([response.get('data')]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `cv-media-${Format.date(now, 'YYYY-MM-DDTHHmm')}.csv`);
      document.body.appendChild(link);
      link.click();

      dispatch(mediaCSVDownloadSuccess());
      
    // get media CSV failure
    } else {
      Logger.log('info', `Get API media CSV failure.`);
      dispatch(mediaCSVDownloadFailure());
    }

    // callback function
    cb();
  }
}

Logger.log('silly', `media.actions loaded.`);
