import {getI18n} from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import PlansScreen from './containers/PlansScreenContainer';
import PlanEditScreen from './containers/PlanEditScreenContainer';
import PlanAddScreen from './containers/PlanAddScreenContainer';

const register = () => {
  Logger.log('debug', `plans.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'plans', 'routes'])) {
    Events.dispatch('ADD_MAIN_ROUTES', 
      {
        'PlanAddScreen': ['PrivateRoute', '/plans/add', true, PlanAddScreen, getI18n().t('plans_route_add')],
        'PlanEditScreen': ['PrivateRoute', '/plans/edit/:id(\\d+)', true, PlanEditScreen, getI18n().t('plans_route_edit')],
        'PlansScreen': ['PrivateRoute', '/plans/:page(\\d+)?', true, PlansScreen, getI18n().t('plans_route_list')],
      }
    );
  }
}

export default register;

Logger.log('silly', `plans.register() loaded.`);
