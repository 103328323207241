import { connect } from 'react-redux';

import CareGuideDocumentAddScreen from '../components/CareGuideDocumentAddScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.careGuideDocuments.get('isLoading'),
    success: state.careGuideDocuments.getIn(['form', 'success']),
  }
}

const CareGuideDocumentAddScreenContainer = connect(
  mapStateToProps
)(CareGuideDocumentAddScreen);

export default CareGuideDocumentAddScreenContainer;
