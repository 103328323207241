import {connect} from 'react-redux';

import {downloadJournalTagsCSV} from '../../../../../state/modules/journalTags/actions';
import JournalTagsCSVButton from '../components/JournalTagsCSVButton';

const mapStateToProps = state => {
  return {
    isDownloading: state.journalTags.get('isCSVDownloading'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    download: (order, filter, cb) => {
      dispatch(downloadJournalTagsCSV(order, filter, cb));
    }
  }
}

const JournalTagsCSVButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(JournalTagsCSVButton);

export default JournalTagsCSVButtonContainer;
