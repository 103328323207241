import {getI18n} from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import CareGuidePartnerTagsScreen from './containers/CareGuidePartnerTagsScreenContainer';
import CareGuidePartnerTagAddScreen from './containers/CareGuidePartnerTagAddScreenContainer';
import CareGuidePartnerTagEditScreen from './containers/CareGuidePartnerTagEditScreenContainer';

const register = () => {
  Logger.log('debug', `careGuidePartnerTags.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'careGuidePartnerTags', 'routes'])) {
    Events.dispatch('ADD_MAIN_ROUTES', 
      {
        'CareGuidePartnerTagAddScreen': ['PrivateRoute', '/care-guide-partner-tags/add', true, CareGuidePartnerTagAddScreen, getI18n().t('care_guide_partner_tag_route_add')],
        'CareGuidePartnerTagEditScreen': ['PrivateRoute', '/care-guide-partner-tags/edit/:id([\\w\\-]+)', true, CareGuidePartnerTagEditScreen, getI18n().t('care_guide_partner_tag_route_edit')],
        'CareGuidePartnerTagsScreen': ['PrivateRoute', '/care-guide-partner-tags/:page(\\d+)?', true, CareGuidePartnerTagsScreen, getI18n().t('care_guide_partner_tags_route_list')],
      }
    );
  }
}

export default register;

Logger.log('silly', `careGuidePartnerTags.register() loaded.`);
