import { connect } from 'react-redux';

import { downloadCareGuidePartnerTagsCSV } from '../../../../../state/modules/careGuidePartnerTags/actions';
import CareGuidePartnerTagsScreen from '../components/CareGuidePartnerTagsScreen';

const mapStateToProps = (state) => {
  return {
    total: state.careGuidePartnerTags.get('total'),
    isLoading: state.careGuidePartnerTags.get('isLoading'),
    isDownloading: state.careGuidePartnerTags.get('isCSVDownloading'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    download: (order, filter, cb) => {
      dispatch(downloadCareGuidePartnerTagsCSV(order, filter, cb));
    },
  };
};

const CareGuidePartnerTagsScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CareGuidePartnerTagsScreen);

export default CareGuidePartnerTagsScreenContainer;
