import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Translation, getI18n } from 'react-i18next';
import {
  Affix,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Spin,
  Checkbox,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';

import message from '../../../elements/lib/MessageWrapper';
import FormMetadata from '../../../elements/components/FormMetadata';
import { pathTo } from '../../../Routes';
import Format from '../../../../../lib/Format';
import Logger from '../../../../../lib/Logger';
import Config from '../../../../../Config';
import CareGuideInput from '../../careGuides/containers/CareGuideInputContainer';
import UserCareGuidePartnershipInput from '../../users/containers/UserCareGuidePartnershipInputContainer';

const CareGuideDocumentForm = ({
  id,
  data,
  errors,
  load,
  destroyForm,
  isLoading,
  isSubmitting,
  created_id,
  ...props
}) => {
  const [redirectTo, setRedirectTo] = useState(null);
  const [isPinned, setIsPinned] = useState(false);
  const [careGuideId, setCareGuideId] = useState(data['care_guide']);

  const [form] = Form.useForm();

  // form column settings
  const layout = {
    main: {
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
    },
  };

  // load record data from API
  useEffect(() => {
    if (id) {
      load(id);
    }
  }, [id, load]);

  // update input values when new data is available
  const dataString = JSON.stringify(data);
  useEffect(() => {
    if (id && !isSubmitting) {
      const dataObj = JSON.parse(dataString);
      setIsPinned(dataObj['is_pinned']);
      dataObj['pinned_at'] = dataObj['pinned_at']
        ? moment(dataObj['pinned_at'], Config.get('API_DATETIME_FORMAT'))
        : null;
      setCareGuideId(dataObj['care_guide']);
      dataObj['uploaded_at'] = dataObj['uploaded_at']
        ? moment(dataObj['uploaded_at'], Config.get('API_DATETIME_FORMAT'))
        : moment();
      form.setFieldsValue(dataObj);
    }
  }, [form, dataString, isSubmitting, id]);

  // handle errors reported by API
  useEffect(() => {
    let firstFieldName = '';
    for (const field in errors) {
      form.setFields([{ name: field, errors: errors[field] }]);
      if (firstFieldName === '') {
        firstFieldName = field;
      }
    }
    form.scrollToField(firstFieldName);
  }, [form, errors]);

  // redirect add form to edit form on successful create action
  useEffect(() => {
    if (created_id) {
      setRedirectTo(pathTo('CareGuideDocumentEditScreen', { id: created_id }));
    }
    return () => {
      destroyForm();
    };
  }, [created_id, setRedirectTo, destroyForm]);

  // submit data handler
  const submitData = async (values) => {
    Logger.log('debug', `CareGuideDocumentForm.submitData(###)`);

    // API POST/PUT payload
    let payload = {};
    for (const input of Object.keys(data)) {
      if (input in values) {
        // transform profile refs to UUIDs
        if (['profile'].includes(input)) {
          payload['user_profile_id'] = values[input];
        } else if (['care_guide'].includes(input)) {
          payload[input + '_id'] = values[input];
        } else if (['uploaded_at', 'pinned_at'].includes(input)) {
          payload[input] = Format.date(
            values[input],
            Config.get('API_DATETIME_FORMAT')
          );
        } else if (['is_pinned'].includes(input)) {
          payload[input] = values[input] ? true : false;
        } else {
          payload[input] = values[input];
        }
      }
    }

    if (id) {
      // update
      props.update(id, payload, (success) => {
        if (success) {
          message.success(getI18n().t('feedback_form_success'));
        } else {
          message.error(getI18n().t('feedback_form_error'));
        }
      });
    } else {
      // create
      props.create(payload, (success) => {
        if (success) {
          message.success(getI18n().t('feedback_form_success'));
        } else {
          message.error(getI18n().t('feedback_form_error'));
        }
      });
    }
  };

  // form submit handler
  const handleFinish = async (values) => {
    Logger.log('debug', `CareGuideDocumentForm.handleFinish(###)`);
    if (!props.isSubmitting) {
      await submitData(values);
    }
  };

  // form error handler
  const handleFinishFailed = ({ values, errorFields, outOfDate }) => {
    Logger.log('debug', `CareGuideDocumentForm.handleFinishFailed(###)`);
    message.error(getI18n().t('feedback_form_error'));
    if (errorFields && errorFields.length > 0) {
      form.scrollToField(errorFields[0].name);
    }
  };

  const onChangeIsPinnedHandler = (e) => {
    setIsPinned(e.target.checked);
  };

  const onChangeCareGuide = (e) => {
    setCareGuideId(e);
    form.setFieldsValue({ profile: null });
  };
  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <Translation>
      {(t) => (
        <>
          <div className="medium-form">
            <Form
              name="medium_form"
              form={form}
              initialValues={data}
              onFinish={handleFinish}
              onFinishFailed={handleFinishFailed}
              validateTrigger="onSubmit"
              {...layout.main}
            >
              <Row gutter={16}>
                <Col xs={24} lg={18}>
                  <Card
                    title={
                      id
                        ? t('care_guide_documents_edit_title')
                        : t('care_guide_documents_add_title')
                    }
                    extra={
                      isLoading ? (
                        <Spin
                          indicator={
                            <LoadingOutlined style={{ fontSize: 20 }} spin />
                          }
                        />
                      ) : null
                    }
                  >
                    <div className="form-group">
                      <Form.Item
                        name="is_pinned"
                        label={t('care_guide_documents_is_pinned')}
                        valuePropName="checked"
                      >
                        <Checkbox
                          value={isPinned}
                          onChange={onChangeIsPinnedHandler}
                          disabled={isLoading || isSubmitting}
                        />
                      </Form.Item>
                    </div>

                    <div className="form-group">
                      <Form.Item
                        name="pinned_at"
                        label={t('care_guide_documents_pinned_at')}
                      >
                        <DatePicker
                          showTime
                          disabled={isLoading || isSubmitting}
                          format={Config.get('DEFAULT_DATETIME_FORMAT')}
                        />
                      </Form.Item>
                    </div>
                    
                    <CareGuideInput
                      form={form}
                      name="care_guide"
                      label={t('care_guide_documents_care_guide')}
                      disabled={isLoading || isSubmitting}
                      onChange={onChangeCareGuide}
                      rules={[
                        {
                          required: true,
                          message: t('feedback_validation_required'),
                        },
                      ]}
                      hasPartnership={true}
                    />

                    <UserCareGuidePartnershipInput
                      careGuideId={careGuideId}
                      name="profile"
                      label={t('care_guide_documents_profile')}
                      rules={[
                        {
                          required: true,
                          message: t('feedback_validation_required'),
                        },
                      ]}
                      form={form}
                      disabled={isLoading || isSubmitting}
                    />

                    <div className="form-group">
                      <Form.Item
                        name="file_name"
                        label={t('care_guide_documents_file_name')}
                        rules={[
                          {
                            required: true,
                            message: t('feedback_validation_required'),
                          },
                          {
                            type: 'string',
                            min: 1,
                            max: 120,
                            message: t('feedback_validation_char_range', {
                              min: 1,
                              max: 120,
                            }),
                          },
                        ]}
                      >
                        <Input disabled={isLoading || isSubmitting} />
                      </Form.Item>
                    </div>

                    <div className="form-group">
                      <Form.Item
                        name="user_file_name"
                        label={t('care_guide_documents_user_file_name')}
                        rules={[
                          {
                            required: true,
                            message: t('feedback_validation_required'),
                          },
                          {
                            type: 'string',
                            min: 1,
                            max: 120,
                            message: t('feedback_validation_char_range', {
                              min: 1,
                              max: 120,
                            }),
                          },
                        ]}
                      >
                        <Input disabled={isLoading || isSubmitting} />
                      </Form.Item>
                    </div>

                    <div className="form-group">
                      <Form.Item
                        name="file_extension"
                        label={t('care_guide_documents_file_extension')}
                        rules={[
                          {
                            type: 'string',
                            min: 0,
                            max: 20,
                            message: t('feedback_validation_char_range', {
                              min: 0,
                              max: 20,
                            }),
                          },
                        ]}
                      >
                        <Input disabled={isLoading || isSubmitting} />
                      </Form.Item>
                    </div>

                    <div className="form-group">
                      <Form.Item
                        name="file_directory"
                        label={t('care_guide_documents_file_directory')}
                        rules={[
                          {
                            type: 'string',
                            min: 0,
                            max: 120,
                            message: t('feedback_validation_char_range', {
                              min: 0,
                              max: 120,
                            }),
                          },
                        ]}
                      >
                        <Input disabled={isLoading || isSubmitting} />
                      </Form.Item>
                    </div>

                    <div className="form-group">
                      <Form.Item
                        name="file_bucket"
                        label={t('care_guide_documents_file_bucket')}
                        rules={[
                          {
                            type: 'string',
                            min: 0,
                            max: 64,
                            message: t('feedback_validation_char_range', {
                              min: 0,
                              max: 64,
                            }),
                          },
                        ]}
                      >
                        <Input disabled={isLoading || isSubmitting} />
                      </Form.Item>
                    </div>

                    <div className="form-group">
                      <Form.Item
                        name="size_bytes"
                        label={t('care_guide_documents_size_bytes')}
                        rules={[
                          {
                            required: true,
                            message: t('feedback_validation_required'),
                          },
                          {
                            type: 'number',
                            message: t('feedback_validation_number'),
                          },
                        ]}
                      >
                        <InputNumber
                          min={0}
                          formatter={(value) =>
                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                          }
                          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                          disabled={isLoading || isSubmitting}
                          style={{ width: 150 }}
                        />
                      </Form.Item>
                    </div>

                    <div className="form-group">
                      <Form.Item
                        name="uploaded_at"
                        label={t('care_guide_documents_uploaded_at')}
                        rules={[
                          {
                            required: true,
                            message: t('feedback_validation_required'),
                          },
                        ]}
                      >
                        <DatePicker
                          showTime
                          disabled={isLoading || isSubmitting}
                          format={Config.get('DEFAULT_DATETIME_FORMAT')}
                        />
                      </Form.Item>
                    </div>

                    <div className="form-group">
                      <Form.Item
                        name="description"
                        label={t('care_guide_documents_description')}
                        rules={[
                          {
                            type: 'string',
                            min: 0,
                            max: 500,
                            message: t('feedback_validation_char_range', {
                              min: 0,
                              max: 500,
                            }),
                          },
                        ]}
                      >
                        <Input.TextArea disabled={isLoading || isSubmitting} />
                      </Form.Item>
                    </div>
                  </Card>
                </Col>

                <Col xs={24} lg={6}>
                  <Affix offsetTop={10}>
                    <Card title={t('form_metadata_header')}>
                      <FormMetadata
                        id={id}
                        isSubmitting={isSubmitting}
                        delete={props.delete.bind(this)}
                        deleteRedirectTo="CareGuideDocumentsScreen"
                        createdAt={props.createdAt}
                        updatedAt={props.updatedAt}
                      />
                    </Card>
                  </Affix>
                </Col>
              </Row>
            </Form>
          </div>
        </>
      )}
    </Translation>
  );
};

export default CareGuideDocumentForm;

Logger.log('silly', `CareGuideDocumentForm loaded.`);
