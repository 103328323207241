import {connect} from 'react-redux';

import JournalReplyEditScreen from '../components/JournalReplyEditScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.journalReplies.get('isLoading'),
    success: state.journalReplies.getIn(['form', 'success']),
  }
}

const JournalReplyEditScreenContainer = connect(
  mapStateToProps
)(JournalReplyEditScreen);

export default JournalReplyEditScreenContainer;
