import { connect } from 'react-redux';
import { downloadCareGuideDocumentsCSV } from '../../../../../state/modules/careGuideDocuments/actions';

import CareGuideDocumentsScreen from '../components/CareGuideDocumentsScreen';

const mapStateToProps = (state) => {
  return {
    total: state.careGuideDocuments.get('total'),
    isLoading: state.careGuideDocuments.get('isLoading'),
    isDownloading: state.careGuideDocuments.get('isCSVDownloading'),
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    download: (order, filter, cb) => {
      dispatch(downloadCareGuideDocumentsCSV(order, filter, cb));
    },
  };
};
const CareGuideDocumentsScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CareGuideDocumentsScreen);

export default CareGuideDocumentsScreenContainer;
