import {connect} from 'react-redux';
import {List} from 'immutable';
import moment from 'moment';
import {denormalize} from 'normalizr';

import {schema} from '../../../../../state/schema';
import {
  loadCareGuide,
  updateCareGuide,
  createCareGuide,
  deleteCareGuide,
  careGuideFormDestroy,
  getGeocode
} from '../../../../../state/modules/careGuides/actions';
import {sendMessage} from '../../../../../state/actions';
import CareGuideForm from '../components/CareGuideForm';
import Config from '../../../../../Config';

const inputs = List([
  'account_id',
  'first_name',
  'last_name',
  'owner',
  'postal_code_input',
  'relationship',
  'segments',
  'started_at',
  'geo',
  'latitude',
  'longitude',
  'summary',
  'status',
  'partnership',
  'care_partner_tags',
]);

const mapStateToProps = (state, ownProps) => {

  const careGuideId = ownProps.id || ownProps.copyId;

  const data = {}, errors = {};
  for (const val of inputs.values()) {
    data[val] = state.entities.getIn(['careGuides', careGuideId, val], '');
    if (['owner', 'relationship'].includes(val)) {
      errors[val] = state.careGuides.getIn(['form', 'errors', val + '_id']);
    }
    else {
      errors[val] = state.careGuides.getIn(['form', 'errors', val]);
    }
  }
  Object.keys(data).forEach(x => {

    // change select number values to strings to work with ant design select inputs
    if (['status', 'relationship'].includes(x)) {
      data[x] = data[x].toString();
    }

    // get lat/lng from geo point
    if (['geo'].includes(x) && data[x]) {
      data['latitude'] = data[x]['latitude'] ? parseFloat(data[x]['latitude']) : null;
      data['longitude'] = data[x]['longitude'] ? parseFloat(data[x]['longitude']) : null;
    }

    if(['partnership'].includes(x)){
      if (data[x] === null){
        data[x] = "N/A";
      }else{
        data[x] = data[x].id;
      }
    }

  });

  data['started_at'] = data['started_at']
    ? moment(data['started_at'], Config.get('API_DATETIME_FORMAT'))
    : moment();

  if (data['care_partner_tags'] === '') {
    data['care_partner_tags'] = [];
  }

  // hydrate segments for dynamic inputs
  if ('segments' in data && Array.isArray(data['segments'])) {
    data['segments'] = data['segments'].map(x => {
      return {
        key: x,
        ...denormalize(
          state.entities.getIn(['careGuideSegments', x]),
          schema.careGuideSegment,
          state.entities.toJS()
        )
      };
    });
  }

  return {
    isLoading: state.careGuides.get('isLoading'),
    isGeocodeLoading: state.resources.get('isGeocodeLoading'),
    isSubmitting: state.careGuides.getIn(['form', 'isSubmitting']),
    success: state.careGuides.getIn(['form', 'success']),
    created_id: state.careGuides.getIn(['form', 'created_id']),
    data: data,
    errors: errors,
    createdAt: state.entities.getIn(['careGuides', ownProps.id, 'created_at'], null),
    updatedAt: state.entities.getIn(['careGuides', ownProps.id, 'updated_at'], null),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (id, cb) => {
      dispatch(loadCareGuide(id, cb));
    },
    update: (id, data, cb) => {
      dispatch(updateCareGuide(id, data, cb));
    },
    create: (data, cb) => {
      dispatch(createCareGuide(data, cb));
    },
    delete: (id, cb) => {
      dispatch(deleteCareGuide(id, cb))
    },
    destroyForm: (formState) => {
      dispatch(careGuideFormDestroy(formState));
    },
    sendMessage: (level, title, body, expires) => {
      dispatch(sendMessage(level, title, body, expires));
    },
    getGeocode: (location, cb) => {
      dispatch(getGeocode(location, cb));
    },
  }
}

const CareGuideFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CareGuideForm);

export default CareGuideFormContainer;
