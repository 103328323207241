import {connect} from 'react-redux';

import {downloadUsersCSV} from '../../../../../state/modules/users/actions';
import UsersCSVButton from '../components/UsersCSVButton';

const mapStateToProps = state => {
  return {
    isDownloading: state.users.get('isCSVDownloading'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    download: (order, filter, cb) => {
      dispatch(downloadUsersCSV(order, filter, cb));
    }
  }
}

const UsersCSVButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersCSVButton);

export default UsersCSVButtonContainer;
