import React from 'react';
import {Translation, getI18n} from 'react-i18next';
import {Button, Upload} from 'antd';
import {CloudUploadOutlined, LoadingOutlined} from '@ant-design/icons';

import message from '../../../elements/lib/MessageWrapper';

const UploadCSVButton = ({isUploading, getUploadURL, sendCSVMessage, ...props}) => {

  // upload criteria
  const fileTypes = {
    'text/csv': 'csv',
    'application/vnd.ms-excel': 'csv',
    'application/csv': 'csv',
    'text/x-csv': 'csv',
    'application/x-csv': 'csv',
    'text/comma-separated-values': 'csv',
    'text/x-comma-separated-values': 'csv',
    'text/plain': 'csv',
  };
  const fileSizeLimit = 1024 * 1024 * 10;

  // const reFileKey = /(?:(.*?)\/)?(.*?)\.(\w+)/;

  const beforeUpload = file => {

    const isSupportedFileType = Object.keys(fileTypes).includes(file.type);
    if (!isSupportedFileType) {
      message.error(getI18n().t('imports_csv_form_feedback_file_types'));
    }

    const isUnderFileSizeLimit = file.size < fileSizeLimit;
    if (!isUnderFileSizeLimit) {
      message.error(getI18n().t('imports_csv_form_feedback_file_size_limit'));
    }

    return isSupportedFileType && isUnderFileSizeLimit;
  }

  const errorMessage = () => {
    message.error(getI18n().t('imports_csv_form_feedback_error'));
  }

  const submit = async (data) => {

    // get upload URL for S3 bucket
    getUploadURL(async (success, res) => {
      if (success) {

        console.log('UPLOAD URL', res);

        // upload file
        props.upload(res.upload_url, data.file, async (uploadSuccess) => {
          if (uploadSuccess) {

            console.log('UPLOAD SUCCESS');

            const messageData = {
              resource: 1,
              upload_bucket: res.bucket,
              directory: res.directory,
              file_id: res.file_id,
              file_extension: res.file_ext,
            };

            console.log('messageData', messageData);

            // send message
            sendCSVMessage(messageData, (messageSuccess) => {
              if (messageSuccess) {
                message.success(getI18n().t('imports_csv_form_feedback_success'));
              } else {
                errorMessage();    
              }
            })
          } else {
            errorMessage();
          }
        });
      } else {
        errorMessage();
      }
    });
  }

  return (
    <Translation>{(t) => 
      <>
        <Upload
          name="import"
          showUploadList={false}
          customRequest={submit}
          beforeUpload={beforeUpload}
        >
          <Button
            size="small"
            icon={isUploading ? <LoadingOutlined /> : <CloudUploadOutlined />}
          >
            {t('action_import')}
          </Button>
        </Upload>
      </>
    }</Translation>
  );
}

export default UploadCSVButton;
