import { connect } from 'react-redux';
import { denormalize } from 'normalizr';

import { schema } from '../../../../../state/schema';
import { loadCareGuideDocument } from '../../../../../state/modules/careGuideDocuments/actions';
import CareGuideDocumentViewScreen from '../components/CareGuideDocumentViewScreen';

const mapStateToProps = (state, ownProps) => {

    const careGuideDocument = ownProps.match.params.id
        ? denormalize(
            state.entities.getIn(['careGuideDocuments', ownProps.match.params.id]),
            schema.careGuideDocument,
            state.entities.toJS()
        )
        : null;;

    return {
        isLoading: state.careGuideDocuments.get('isLoading'),
        careGuideDocument: careGuideDocument,
        id: ownProps.match.params.id,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        load: (id, cb) => {
            dispatch(loadCareGuideDocument(id, cb));
        },
    }
}

const CareGuideDocumentViewScreenContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CareGuideDocumentViewScreen);

export default CareGuideDocumentViewScreenContainer;
