import {connect} from 'react-redux';

import CalendarEventAddScreen from '../components/CalendarEventAddScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.calendarEvents.get('isLoading'),
    success: state.calendarEvents.getIn(['form', 'success']),
  }
}

const CalendarEventAddScreenContainer = connect(
  mapStateToProps
)(CalendarEventAddScreen);

export default CalendarEventAddScreenContainer;
