import React, {useState} from 'react';
import {Translation, getI18n} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import {Button, Col, Form, Input, Row, Switch, Typography} from 'antd';
import QRCode from 'qrcode.react';

import {pathTo} from '../../../Routes';
import Config from '../../../../../Config';
import Logger from '../../../../../lib/Logger';
import message from '../../../elements/lib/MessageWrapper';

const TwoFactorSettingsForm = props => {

  const [isEnrolledChecked, setIsEnrolledChecked] = useState(props.isEnrolledOTP)
  const [form] = Form.useForm();

  // submit handler
  const submit = async (values) => {
    Logger.log('debug', `TwoFactorSettingsForm.submit()`);

    const payload = {
      is_enrolled_otp: true,
      otp: values.otp,
    };

    // udpate setting
    props.update(payload, (success) => {
      if (success) {
        message.success(getI18n().t('user_account_2fa_form_message_success'));
        // logout user
        props.destroySession(() => props.history.push(pathTo(Config.get('DEFAULT_LOGIN_SCREEN'))));
      } else {
        message.error(getI18n().t('user_account_2fa_form_message_failure'));
      }
    });
  }

  const handleValuesChange = (changedValues, allVallues) => {
    if ('is_enrolled_otp' in changedValues) {
      setIsEnrolledChecked(changedValues.is_enrolled_otp);
      if (changedValues.is_enrolled_otp === true) {

        // get secret key
        props.update({generate_code: true}, (success) => {
          if (!success) {
            message.error(getI18n().t('user_account_2fa_form_message_failure'));
          }
        });
      } else {

        // turn off 2FA
        props.update({is_enrolled_otp: false}, (success) => {
          if (!success) {
            message.error(getI18n().t('user_account_2fa_form_message_failure'));
          }
        });
      }
    }
  }

  // form submit handler
  const handleFinish = async (values) => {
    Logger.log('debug', `TwoFactorSettingsForm.handleFinish(###)`);
    if (!props.isSubmitting) {
      await submit(values);
    }
  }

  // form error handler
  const handleFinishFailed = ({values, errorFields, outOfDate}) => {
    Logger.log('debug', `TwoFactorSettingsForm.handleFinishFailed(###)`);
    message.error(getI18n().t('user_account_2fa_form_message_failure'));
  }

  const {Paragraph, Text} = Typography;

  return(
      <Translation>{(t) => 
        <div>
          <Form
            hideRequiredMark
            name="user_account_2fa_form"
            onFinish={handleFinish}
            onFinishFailed={handleFinishFailed}
            onValuesChange={handleValuesChange}
            form={form}
            initialValues={{
              is_enrolled_otp: props.isEnrolledOTP,
            }}
          >

            <div className="form-group">
              <Form.Item>
                <Form.Item
                  name="is_enrolled_otp"
                  label={null}
                  valuePropName="checked"
                  noStyle
                >
                  <Switch disabled={props.isSubmitting} />
                </Form.Item>
                {' '}<span className="ant-form-text">{t('user_account_2fa_input_is_enrolled_otp')}</span>
              </Form.Item>
            </div>

            {isEnrolledChecked && props.OTPSecret && props.provisioningURI
              ? <>

                  <Row gutter={16} justify="center">
                    <Col sm={18}>
                      <h3>Instructions:</h3>
                      <ul>
                        <li>Download <Text strong>Google Authenticator</Text> to your mobile device.</li>
                        <li>Create a new profile by either scanning the QR code or by manually entering the secret key.</li>
                        <li>Submit the generated one-time password in the form to complete setup.</li>
                        <li>After successfully submiting the one-time password <Text underline>you will be automatically logged out</Text>. Please log back in to regenerate your access token.</li>
                      </ul>
                    </Col>

                    <Col sm={6}>
                      <QRCode value={props.provisioningURI} />
                    </Col>
                  </Row>

                  <Row gutter={16} style={{marginBottom: 24}}>
                    <Col>
                      <Paragraph copyable={{ text: props.OTPSecret }}>Secret: <Text keyboard>{props.OTPSecret}</Text></Paragraph>
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col>
                      <h3>Complete Enrollment</h3>
                      <Paragraph>Enter the one-time password from <Text strong>Google Authenticator</Text>:</Paragraph>

                      <div className="form-group">
                        <Form.Item
                          name="otp"
                          label={null}
                        >
                          <Input disabled={props.isSubmitting} />
                        </Form.Item>
                      </div>

                    </Col>
                  </Row>

                  <div className="form-actions">
                    <Form.Item>
                      <Button
                        type="primary"
                        className="login-button"
                        htmlType="submit"
                        loading={props.isSubmitting}
                      >
                        {t('session_login_2fa_form_button_submit')}
                      </Button>
                    </Form.Item>
                  </div>
                </>
              : null}

          </Form>
        </div>
      }</Translation>
  );
}

export default withRouter(TwoFactorSettingsForm);

Logger.log('silly', `TwoFactorSettingsForm loaded.`);
