import { connect } from 'react-redux';
import { List } from 'immutable';
import moment from 'moment';
import { denormalize } from 'normalizr';

import { schema } from '../../../../../state/schema';
import {
  loadCalendarEvent,
  updateCalendarEvent,
  createCalendarEvent,
  deleteCalendarEvent,
  loadCalendarEventParticipants,
  calendarEventParticipantSetPage,
  deleteCalendarEventParticipant,
  calendarEventShowEventParticipantForm,
  calendarEventFormDestroy,
} from '../../../../../state/modules/calendarEvents/actions';
import { sendMessage } from '../../../../../state/actions';
import CalendarEventForm from '../components/CalendarEventForm';
import Config from '../../../../../Config';

const inputs = List([
  'care_guide',
  'care_guides',
  'profile',
  'title',
  'description',
  'start_at',
  'end_at',
  'start_at_date',
  'start_at_time',
  'end_at_date',
  'end_at_time',
  'is_all_day',
  'participants',
  'parent_event',
  'recurring',
  'rrule',
  'recurring_end_at',
  'status',
  'partnership',
  'url_event_partner',
  'url_label',
]);

const mapStateToProps = (state, ownProps) => {
  const data = {},
    errors = {};
  for (const val of inputs.values()) {
    data[val] = state.entities.getIn(['calendarEvents', ownProps.id, val], '');
    if (['profile'].includes(val)) {
      errors[val] = state.calendarEvents.getIn([
        'form',
        'errors',
        'user_profile_id',
      ]);
    } else if (['care_guide', 'parent_event', 'partnership'].includes(val)) {
      errors[val] = state.calendarEvents.getIn(['form', 'errors', val + '_id']);
    } else {
      errors[val] = state.calendarEvents.getIn(['form', 'errors', val]);
    }
  }

  // change select number values to strings to work with ant design select inputs
  Object.keys(data).forEach((x) => {
    if (['status'].includes(x)) {
      data[x] = data[x].toString();
    }
  });

  data['start_at_date'] = data['start_at']
    ? moment(data['start_at'], Config.get('API_DATETIME_FORMAT'))
    : null;

  data['start_at_time'] =
    data && data['start_at']
      ? moment(data['start_at'], Config.get('API_DATETIME_FORMAT')).format(
          'h:mm A'
        )
      : '8:00 AM';

  data['end_at_date'] =
    data && data['end_at']
      ? moment(data['end_at'], Config.get('API_DATETIME_FORMAT')).format(
          'MM/DD/YYYY'
        )
      : data['start_at_date']
      ? moment(data['start_at']).format('MM/DD/YYYY')
      : null;

  data['end_at_time'] =
    data && data['end_at']
      ? moment(data['end_at'], Config.get('API_DATETIME_FORMAT')).format(
          'h:mm A'
        )
      : '9:00 AM';

  data['recurring_end_at'] = data['recurring_end_at']
    ? moment(data['recurring_end_at'], Config.get('API_DATETIME_FORMAT'))
    : null;

  const care_guide = data['care_guide'] ?? null;

  if (Array.isArray(data['care_guides'])) {
    if (data['care_guides'].length < 1) {
      data['care_guides'] = care_guide ? [care_guide] : [];
    }
  } else {
    data['care_guides'] = care_guide ? [care_guide] : [];
  }

  // hydrate participants for dynamic inputs
  if ('participants' in data && Array.isArray(data['participants'])) {
    data['participants'] = data['participants'].map((x) => {
      return {
        key: x,
        ...denormalize(
          state.entities.getIn(['calendarEventParticipants', x]),
          schema.calendarEventParticipant,
          state.entities.toJS()
        ),
      };
    });
  }

  const total = state.calendarEvents.getIn([
    'calendarEventParticipants',
    ownProps.id,
    'total',
  ]);
  const pageParticipants = state.calendarEvents.get('pageParticipants');
  const areParticipantEventsLoading = state.calendarEvents.get(
    'areParticipantEventsLoading'
  );

  const result = state.calendarEvents.getIn([
    'calendarEventParticipants',
    ownProps.id,
    pageParticipants,
    10,
  ]);
  let list = [];

  list = result
    ? result
        .map((x) => {
          return {
            key: x,
            ...denormalize(
              state.entities.getIn(['calendarEventParticipants', x]),
              schema.calendarEventParticipant,
              state.entities.toJS()
            ),
          };
        })
        .toArray()
    : [];

  return {

    pageParticipants: pageParticipants,
    list: list,
    total: total,
    areParticipantEventsLoading: areParticipantEventsLoading,
    isLoading: state.calendarEvents.get('isLoading'),
    isSubmitting: state.calendarEvents.getIn(['form', 'isSubmitting']),
    success: state.calendarEvents.getIn(['form', 'success']),
    created_id: state.calendarEvents.getIn(['form', 'created_id']),
    data: data,
    errors: errors,
    createdAt: state.entities.getIn(
      ['calendarEvents', ownProps.id, 'created_at'],
      null
    ),
    updatedAt: state.entities.getIn(
      ['calendarEvents', ownProps.id, 'updated_at'],
      null
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (id, cb) => {
      dispatch(loadCalendarEvent(id, cb));
    },
    update: (id, data, cb) => {
      dispatch(updateCalendarEvent(id, data, cb));
    },
    create: (data, cb) => {
      dispatch(createCalendarEvent(data, cb));
    },
    delete: (id, cb) => {
      dispatch(deleteCalendarEvent(id, cb));
    },
    destroyForm: (formState) => {
      dispatch(calendarEventFormDestroy(formState));
    },
    sendMessage: (level, title, body, expires) => {
      dispatch(sendMessage(level, title, body, expires));
    },
    deleteParticipant: (calendarEventParticipantId, cb) => {
      dispatch(deleteCalendarEventParticipant(calendarEventParticipantId, cb));
    },
    loadParticipants: (calendarEventId, page, limit, cb) => {
      dispatch(loadCalendarEventParticipants(calendarEventId, page, limit, cb));
    },
    setPage: (page) => {
      dispatch(calendarEventParticipantSetPage(page));
    },
    showParticipantForm: (participantId) => {
      dispatch(calendarEventShowEventParticipantForm(participantId));
    },
  };
};

const CalendarEventFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CalendarEventForm);

export default CalendarEventFormContainer;
