import {connect} from 'react-redux';
import {List} from 'immutable';
import moment from 'moment';

import {
  loadCalendarEventException,
  updateCalendarEventException,
  createCalendarEventException,
  deleteCalendarEventException,
  calendarEventExceptionFormDestroy
} from '../../../../../state/modules/calendarEvents/actions';
import {sendMessage} from '../../../../../state/actions';
import CalendarEventExceptionForm from '../components/CalendarEventExceptionForm';
import Config from '../../../../../Config';

const inputs = List([
  'event',
  'exception_at',
  'status'
]);

const mapStateToProps = (state, ownProps) => {

  const data = {}, errors = {};
  for (const val of inputs.values()) {
    data[val] = state.entities.getIn(['calendarEventExceptions', ownProps.id, val], '');
    if (['event'].includes(val)) {
      errors[val] = state.calendarEvents.getIn(['exceptionForm', 'errors', val + '_id']);
    }
    else {
      errors[val] = state.calendarEvents.getIn(['exceptionForm', 'errors', val]);
    }
  }

  // change select number values to strings to work with ant design select inputs
  Object.keys(data).forEach(x => {
    if (['status'].includes(x)) {
      data[x] = data[x].toString();
    }
  });

  data['exception_at'] = data['exception_at']
    ? moment(data['exception_at'], Config.get('API_DATETIME_FORMAT'))
    : null;

  return {
    isLoading: state.calendarEvents.get('areExceptionsLoading'),
    isSubmitting: state.calendarEvents.getIn(['exceptionForm', 'isSubmitting']),
    success: state.calendarEvents.getIn(['exceptionForm', 'success']),
    created_id: state.calendarEvents.getIn(['exceptionForm', 'created_id']),
    data: data,
    errors: errors,
    createdAt: state.entities.getIn(['calendarEventExceptions', ownProps.id, 'created_at'], null),
    updatedAt: state.entities.getIn(['calendarEventExceptions', ownProps.id, 'updated_at'], null),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (id, cb) => {
      dispatch(loadCalendarEventException(id, cb));
    },
    update: (id, data, cb) => {
      dispatch(updateCalendarEventException(id, data, cb));
    },
    create: (data, cb) => {
      dispatch(createCalendarEventException(data, cb));
    },
    delete: (id, cb) => {
      dispatch(deleteCalendarEventException(id, cb))
    },
    destroyForm: (formState) => {
      dispatch(calendarEventExceptionFormDestroy(formState));
    },
    sendMessage: (level, title, body, expires) => {
      dispatch(sendMessage(level, title, body, expires));
    },
  }
}

const CalendarEventExceptionFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CalendarEventExceptionForm);

export default CalendarEventExceptionFormContainer;
