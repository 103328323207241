import { connect } from 'react-redux';

import { downloadCareGuideNotesCSV } from '../../../../../state/modules/careGuideNotes/actions';
import CareGuideNotesScreen from '../components/CareGuideNotesScreen';

const mapStateToProps = state => {
  return {
    total: state.careGuideNotes.get('total'),
    isLoading: state.careGuideNotes.get('isLoading'),
    isDownloading: state.careGuideNotes.get('isCSVDownloading'),
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    download: (order, filter, cb) => {
      dispatch(downloadCareGuideNotesCSV(order, filter, cb));
    },
  };
};

const CareGuideNotesScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CareGuideNotesScreen);

export default CareGuideNotesScreenContainer;