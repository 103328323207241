import {getI18n} from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import JournalTagsScreen from './containers/JournalTagsScreenContainer';
import JournalTagAddScreen from './containers/JournalTagAddScreenContainer';
import JournalTagEditScreen from './containers/JournalTagEditScreenContainer';

const register = () => {
  Logger.log('debug', `journalTags.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'journalTags', 'routes'])) {
    Events.dispatch('ADD_MAIN_ROUTES', 
      {
        'JournalTagAddScreen': ['PrivateRoute', '/journal-tags/add', true, JournalTagAddScreen, getI18n().t('journal_tag_route_add')],
        'JournalTagEditScreen': ['PrivateRoute', '/journal-tags/edit/:id([\\w\\-]+)', true, JournalTagEditScreen, getI18n().t('journal_tag_route_edit')],
        'JournalTagsScreen': ['PrivateRoute', '/journal-tags/:page(\\d+)?', true, JournalTagsScreen, getI18n().t('journal_tags_route_list')],
      }
    );
  }
}

export default register;

Logger.log('silly', `journalTags.register() loaded.`);
