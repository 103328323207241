import {getI18n} from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import CareGuideTemplatesScreen from './containers/CareGuideTemplatesScreenContainer';
import CareGuideTemplateAddScreen from './containers/CareGuideTemplateAddScreenContainer';
import CareGuideTemplateEditScreen from './containers/CareGuideTemplateEditScreenContainer';

const register = () => {
  Logger.log('debug', `careGuideTemplates.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'careGuideTemplates', 'routes'])) {
    Events.dispatch('ADD_MAIN_ROUTES', 
      {
        'CareGuideTemplateAddScreen': ['PrivateRoute', '/care-guide-templates/add', true, CareGuideTemplateAddScreen, getI18n().t('care_guide_template_route_add')],
        'CareGuideTemplateEditScreen': ['PrivateRoute', '/care-guide-templates/edit/:id([\\w\\-]+)', true, CareGuideTemplateEditScreen, getI18n().t('care_guide_template_route_edit')],
        'CareGuideTemplatesScreen': ['PrivateRoute', '/care-guide-templates/:page(\\d+)?', true, CareGuideTemplatesScreen, getI18n().t('care_guide_templates_route_list')],
      }
    );
  }
}

export default register;

Logger.log('silly', `careGuideTemplates.register() loaded.`);
