import { connect } from 'react-redux';

import RegistrationsScreen from '../components/RegistrationsScreen';

const mapStateToProps = (state) => {
  return {
    total: state.registration.get('total'),
    isLoading: state.registration.get('isLoading'),
  };
};

const RegistrationsScreenContainer =
  connect(mapStateToProps)(RegistrationsScreen);

export default RegistrationsScreenContainer;
