import {normalize} from 'normalizr';

import api from '../../api';
import {schema} from '../../schema';
import {addEntities, removeEntity, removeEntitys} from '../../actions';
import Format from '../../../lib/Format';
import Logger from '../../../lib/Logger';

// ACTION TYPES

export const USER_PROFILES_PARTNERSHIP_LIST_REQUEST = 'USER_PROFILES_PARTNERSHIP_LIST_REQUEST';
export const USER_PROFILES_PARTNERSHIP_LIST_SUCCESS = 'USER_PROFILES_PARTNERSHIP_LIST_SUCCESS';
export const USER_PROFILES_PARTNERSHIP_LIST_FAILURE = 'USER_PROFILES_PARTNERSHIP_LIST_FAILURE';
export const USER_PROFILES_PARTNERSHIP_READ_REQUEST = 'USER_PROFILES_PARTNERSHIP_READ_REQUEST';
export const USER_PROFILES_PARTNERSHIP_READ_SUCCESS = 'USER_PROFILES_PARTNERSHIP_READ_SUCCESS';
export const USER_PROFILES_PARTNERSHIP_READ_FAILURE = 'USER_PROFILES_PARTNERSHIP_READ_FAILURE';
export const USER_PROFILES_PARTNERSHIP_UPDATE_REQUEST = 'USER_PROFILES_PARTNERSHIP_UPDATE_REQUEST';
export const USER_PROFILES_PARTNERSHIP_UPDATE_SUCCESS = 'USER_PROFILES_PARTNERSHIP_UPDATE_SUCCESS';
export const USER_PROFILES_PARTNERSHIP_UPDATE_FAILURE = 'USER_PROFILES_PARTNERSHIP_UPDATE_FAILURE';
export const USER_PROFILES_PARTNERSHIP_CREATE_REQUEST = 'USER_PROFILES_PARTNERSHIP_CREATE_REQUEST';
export const USER_PROFILES_PARTNERSHIP_CREATE_SUCCESS = 'USER_PROFILES_PARTNERSHIP_CREATE_SUCCESS';
export const USER_PROFILES_PARTNERSHIP_CREATE_FAILURE = 'USER_PROFILES_PARTNERSHIP_CREATE_FAILURE';
export const USER_PROFILES_PARTNERSHIP_DELETE_REQUEST = 'USER_PROFILES_PARTNERSHIP_DELETE_REQUEST';
export const USER_PROFILES_PARTNERSHIP_DELETE_SUCCESS = 'USER_PROFILES_PARTNERSHIP_DELETE_SUCCESS';
export const USER_PROFILES_PARTNERSHIP_DELETE_FAILURE = 'USER_PROFILES_PARTNERSHIP_DELETE_FAILURE';
export const USER_PROFILES_PARTNERSHIP_FORM_DESTROY = 'USER_PROFILES_PARTNERSHIP_FORM_DESTROY';
export const USER_PROFILES_PARTNERSHIP_CSV_DOWNLOAD_REQUEST = 'USER_PROFILES_PARTNERSHIP_CSV_DOWNLOAD_REQUEST';
export const USER_PROFILES_PARTNERSHIP_CSV_DOWNLOAD_SUCCESS = 'USER_PROFILES_PARTNERSHIP_CSV_DOWNLOAD_SUCCESS';
export const USER_PROFILES_PARTNERSHIP_CSV_DOWNLOAD_FAILURE = 'USER_PROFILES_PARTNERSHIP_CSV_DOWNLOAD_FAILURE';

// ACTION CREATORS

export function userProfilesPartnershipListRequest(page, limit, order, filter) {
  Logger.log('debug', `[userProfilesPartnerships.actions] userProfilesPartnershipListRequest(${page}, ${limit}, ${order}, %j)`, filter);
  return {
    type: USER_PROFILES_PARTNERSHIP_LIST_REQUEST,
    page: page,
    limit: limit,
    order: order,
    filter: filter
  }
}

export function userProfilesPartnershipListSuccess(data) {
  Logger.log('debug', `[userProfilesPartnerships.actions] userProfilesPartnershipListSuccess(%j)`, data);
  return {
    type: USER_PROFILES_PARTNERSHIP_LIST_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now()
  }
}

export function userProfilesPartnershipListFailure(error) {
  Logger.log('debug', `[userProfilesPartnerships.actions] userProfilesPartnershipListFailure(%j)`, error);
  return {
    type: USER_PROFILES_PARTNERSHIP_LIST_FAILURE,
    error: error
  }
}

export function userProfilesPartnershipReadRequest(id) {
  Logger.log('debug', `[userProfilesPartnerships.actions] userProfilesPartnershipReadRequest(${id})`);
  return {
    type: USER_PROFILES_PARTNERSHIP_READ_REQUEST,
    id: id
  }
}

export function userProfilesPartnershipReadSuccess(data) {
  Logger.log('debug', `[userProfilesPartnerships.actions] userProfilesPartnershipReadSuccess(%j)`, data);
  return {
    type: USER_PROFILES_PARTNERSHIP_READ_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function userProfilesPartnershipReadFailure(error) {
  Logger.log('debug', `[userProfilesPartnerships.actions] userProfilesPartnershipReadFailure(%j)`, error);
  return {
    type: USER_PROFILES_PARTNERSHIP_READ_FAILURE,
    error: error
  }
}

export function userProfilesPartnershipUpdateRequest(id, data) {
  Logger.log('debug', `[userProfilesPartnerships.actions] userProfilesPartnershipUpdateRequest(${id}, %j)`, data);
  return {
    type: USER_PROFILES_PARTNERSHIP_UPDATE_REQUEST,
  }
}

export function userProfilesPartnershipUpdateSuccess(data) {
  Logger.log('debug', `[userProfilesPartnerships.actions] userProfilesPartnershipUpdateSuccess(%j)`, data);
  return {
    type: USER_PROFILES_PARTNERSHIP_UPDATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function userProfilesPartnershipUpdateFailure(error) {
  Logger.log('debug', `[userProfilesPartnerships.actions] userProfilesPartnershipUpdateFailure(%j)`, error);
  return {
    type: USER_PROFILES_PARTNERSHIP_UPDATE_FAILURE,
    error: error
  }
}

export function userProfilesPartnershipCreateRequest(data) {
  Logger.log('debug', `[userProfilesPartnerships.actions] userProfilesPartnershipCreateRequest(%j)`, data);
  return {
    type: USER_PROFILES_PARTNERSHIP_CREATE_REQUEST,
  }
}

export function userProfilesPartnershipCreateSuccess(data) {
  Logger.log('debug', `[userProfilesPartnerships.actions] userProfilesPartnershipCreateSuccess(%j)`, data);
  return {
    type: USER_PROFILES_PARTNERSHIP_CREATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function userProfilesPartnershipCreateFailure(error) {
  Logger.log('debug', `[userProfilesPartnerships.actions] userProfilesPartnershipCreateFailure(%j)`, error);
  return {
    type: USER_PROFILES_PARTNERSHIP_CREATE_FAILURE,
    error: error
  }
}

export function userProfilesPartnershipDeleteRequest(id) {
  Logger.log('debug', `[userProfilesPartnerships.actions] userProfilesPartnershipDeleteRequest(${id})`);
  return {
    type: USER_PROFILES_PARTNERSHIP_DELETE_REQUEST,
    id: id
  }
}

export function userProfilesPartnershipDeleteSuccess(id) {
  Logger.log('debug', `[userProfilesPartnerships.actions] userProfilesPartnershipDeleteSuccess(${id})`);
  return {
    type: USER_PROFILES_PARTNERSHIP_DELETE_SUCCESS,
    id: id,
  }
}

export function userProfilesPartnershipDeleteFailure(error) {
  Logger.log('debug', `[userProfilesPartnerships.actions] userProfilesPartnershipDeleteFailure(%j)`, error);
  return {
    type: USER_PROFILES_PARTNERSHIP_DELETE_FAILURE,
    error: error
  }
}

export function userProfilesPartnershipFormDestroy(formState=null) {
  Logger.log('debug', `[userProfilesPartnerships.actions] userProfilesPartnershipFormDestroy(%j)`, formState);
  return {
    type: USER_PROFILES_PARTNERSHIP_FORM_DESTROY,
    form: formState
  }
}

export function userProfilesPartnershipCSVDownloadRequest(order, filter) {
  Logger.log('debug', `[state.userProfilesPartnerships.actions] userProfilesPartnershipCSVDownloadRequest()`);
  return {
    type: USER_PROFILES_PARTNERSHIP_CSV_DOWNLOAD_REQUEST,
    order: order,
    filter: filter,
  }
}

export function userProfilesPartnershipCSVDownloadSuccess() {
  Logger.log('debug', `[state.userProfilesPartnerships.actions] userProfilesPartnershipCSVDownloadSuccess()`);
  return {
    type: USER_PROFILES_PARTNERSHIP_CSV_DOWNLOAD_SUCCESS,
  }
}

export function userProfilesPartnershipCSVDownloadFailure() {
  Logger.log('debug', `[state.userProfilesPartnerships.actions] userProfilesPartnershipCSVDownloadFailure()`);
  return {
    type: USER_PROFILES_PARTNERSHIP_CSV_DOWNLOAD_FAILURE,
  }
}


// API THUNK ACTION CREATORS

export function loadUserProfilesPartnerships(page=1, limit=10, order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[userProfilesPartnership.actions] loadUserProfilesPartnerships(${page}, ${limit}, ${order}, %j, ###)`, filter);

  return async function(dispatch) {
    dispatch(userProfilesPartnershipListRequest(page, limit, order, filter));

    // call API
    const response = await api.getUserProfilesPartnerships(page, limit, order, filter);
    let success = false;

    // get user profiles partnerships list success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API user profiles partnerships list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`);

      const normalizedEntities = normalize(response.getIn(['data', 'user_profiles_partnerships']), [schema.userProfilesPartnership]);
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result
      };

      dispatch(removeEntitys({entityType: 'userProfiles'}))
      dispatch(addEntities(normalizedEntities));
      dispatch(userProfilesPartnershipListSuccess(data));
      success = true;

    } else if (1 === page && 204 === response.get('status')) {

      Logger.log('info', `Get API user profiles partnerships success [empty]. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: []
      };
      dispatch(userProfilesPartnershipListSuccess(data));
      success = true;
      
    // get user profiles partnerships list failure
    } else {
      Logger.log('info', `Get API user profiles partnerships list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      dispatch(userProfilesPartnershipListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function loadUserProfilesPartnership(id, cb=function(){}) {
  Logger.log('debug', `[userProfilespartnerships.actions] loadUserProfilesPartnership(${id}, ###)`);

  return async function(dispatch) {
    dispatch(userProfilesPartnershipReadRequest(id));

    // call API
    const response = await api.getUserProfilesPartnership(id);
    let success = false;

    // get user profiles partnership success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API user profiles partnership success. ID: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'user_profiles_partnerships'])], [schema.userProfilesPartnership]);
      const data = {
        id: response.getIn(['data', 'user_profiles_partnership', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(userProfilesPartnershipReadSuccess(data));
      success = true;
      
    // get user profiles partnership failure
    } else {
      Logger.log('info', `Get API user profiles partnership failure. ID: ${id}.`);
      dispatch(userProfilesPartnershipReadFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function updateUserProfilesPartnership(id, data, cb=function(){}) {
  Logger.log('debug', `[userProfilesPartnerships.actions] updateUserProfilesPartnership(${id}, %j, ###)`, data);
  return async function(dispatch) {
    dispatch(userProfilesPartnershipUpdateRequest(id, data));

    // call API
    const response = await api.putUserProfilesPartnership(id, data);
    let success = false;

    // put user profiles partnership success
    if (200 === response.get('status')) {

      Logger.log('info', `PUT API user profiles partnership success. User: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'user_profiles_partnership'])], [schema.userProfilesPartnership]);
      const data = {
        id: response.getIn(['data', 'user_profiles_partnership', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(userProfilesPartnershipUpdateSuccess(data));
      success = true;
      
    // get user profiles partnership failure
    } else {
      Logger.log('info', `PUT API user profiles partnership failure. ID: ${id}.`);
      dispatch(userProfilesPartnershipUpdateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function createUserProfilesPartnership(data, cb=function(){}) {
  Logger.log('debug', `[userProfilesPartnerships.actions] createUserProfilesPartnership(%j, ###)`, data);

  return async function(dispatch) {
    dispatch(userProfilesPartnershipCreateRequest(data));

    // call API
    const response = await api.postUserProfilesPartnerships(data);
    let success = false;

    // post user profiles partnership success
    if (201 === response.get('status')) {

      Logger.log('info', `POST API user profiles partnership success. Post: ${response.getIn(['data', 'user_profiles_partnership', 'id'])}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'user_profiles_partnership'])], [schema.userProfilesPartnership]);
      const data = {
        id: response.getIn(['data', 'user_profiles_partnership', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(userProfilesPartnershipCreateSuccess(data));
      success = true;
      
    // get user profiles partnerships failure
    } else {
      Logger.log('info', `POST API user profiles partnerships failure.`);
      dispatch(userProfilesPartnershipCreateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function deleteUserProfilesPartnership(id, cb=function(){}) {
  Logger.log('debug', `[userProfilesPartnerships.actions] deleteUserProfilesPartnership(${id}, ###)`);

  return async function(dispatch) {
    dispatch(userProfilesPartnershipDeleteRequest(id));

    // call API
    const response = await api.deleteUserProfilesPartnership(id);
    let success = false;

    // delete user profiles partnership success
    if (204 === response.get('status')) {

      Logger.log('info', `DELETE API user profiles partnership success. ID: ${id}.`);

      dispatch(removeEntity({entityType: 'userProfilesPartnerships', id: id}));
      dispatch(userProfilesPartnershipDeleteSuccess(id));
      success = true;
      
    // get user profiles partnership failure
    } else {
      Logger.log('info', `DELETE API user profiles partnership failure. ID: ${id}.`);
      dispatch(userProfilesPartnershipDeleteFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function downloadUserProfilesPartnershipsCSV(order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[state.userProfilesPartnerships.actions] downloadUserProfilesPartnershipsCSV(${order}, ${filter}, ###)`);

  return async function(dispatch) {
    dispatch(userProfilesPartnershipCSVDownloadRequest(order, filter));

    // call API
    const response = await api.getUserProfilesPartnershipsCSV(order, filter);
    let success = false;

    // get user profiles partnership CSV success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API user profiles partnership CSV success. Order: ${order}`);
      const now = new Date();

      // trigger browser download
      const url = window.URL.createObjectURL(new Blob([response.get('data')]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `cv-user-profiles-partnerships-${Format.date(now, 'YYYY-MM-DDTHHmm')}.csv`);
      document.body.appendChild(link);
      link.click();

      dispatch(userProfilesPartnershipCSVDownloadSuccess());
      success = true;
    // get user profiles partnership CSV failure
    } else {
      Logger.log('info', `Get API user profiles partnership CSV failure.`);
      dispatch(userProfilesPartnershipCSVDownloadFailure());
    }

    // callback function
    cb(success);
  }
}

Logger.log('silly', `userProfilesPartnerships.actions loaded.`);
