import {getI18n} from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import JobsScreen from './containers/JobsScreenContainer';
import JobViewScreen from './containers/JobViewScreenContainer';

const register = () => {
  Logger.log('debug', `imports.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'imports', 'routes'])) {
    Events.dispatch('ADD_MAIN_ROUTES', 
      {
        'JobViewScreen': ['PrivateRoute', '/imports/jobs/view/:id([\\w\\-]+)', true, JobViewScreen, getI18n().t('imports_jobs_route_view')],
        'JobsScreen': ['PrivateRoute', '/imports/jobs/:page(\\d+)?', true, JobsScreen, getI18n().t('imports_jobs_route_list')],
      }
    );
  }
}

export default register;

Logger.log('silly', `imports.register() loaded.`);
