import {connect} from 'react-redux';
import {List} from 'immutable';
import moment from 'moment';

import {
  loadCareGuideInvitation,
  updateCareGuideInvitation,
  createCareGuideInvitation,
  deleteCareGuideInvitation,
  careGuideInvitationFormDestroy
} from '../../../../../state/modules/careGuideInvitations/actions';
import {sendMessage} from '../../../../../state/actions';
import CareGuideInvitationForm from '../components/CareGuideInvitationForm';
import Config from '../../../../../Config';

const inputs = List([
  'care_guide',
  'profile',
  'expires_at',
  'invited_at',
  'inviter',
  'email',
  'code',
  'public_status',
  'status'
]);

const mapStateToProps = (state, ownProps) => {

  const data = {}, errors = {};
  for (const val of inputs.values()) {
    data[val] = state.entities.getIn(['careGuideInvitations', ownProps.id, val], '');
    if (['profile'].includes(val)) {
      errors[val] = state.careGuideInvitations.getIn(['form', 'errors', 'user_profile_id']);
    }
    else if (['care_guide'].includes(val)) {
      errors[val] = state.careGuideInvitations.getIn(['form', 'errors', val + '_id']);
    }
    else if (['inviter'].includes(val)) {
      errors[val] = state.careGuideInvitations.getIn(['form', 'errors', 'invited_by']);
    }
    else {
      errors[val] = state.careGuideInvitations.getIn(['form', 'errors', val]);
    }
  }

  // change select number values to strings to work with ant design select inputs
  Object.keys(data).forEach(x => {
    if (['status'].includes(x)) {
      data[x] = data[x].toString();
    }
  });

  data['expires_at'] = data['expires_at']
    ? moment(data['expires_at'], Config.get('API_DATETIME_FORMAT'))
    : moment();

  data['invited_at'] = data['invited_at']
    ? moment(data['invited_at'], Config.get('API_DATETIME_FORMAT'))
    : moment();

  return {
    isLoading: state.careGuideInvitations.get('isLoading'),
    isSubmitting: state.careGuideInvitations.getIn(['form', 'isSubmitting']),
    success: state.careGuideInvitations.getIn(['form', 'success']),
    created_id: state.careGuideInvitations.getIn(['form', 'created_id']),
    data: data,
    errors: errors,
    createdAt: state.entities.getIn(['careGuideInvitations', ownProps.id, 'created_at'], null),
    updatedAt: state.entities.getIn(['careGuideInvitations', ownProps.id, 'updated_at'], null),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (id, cb) => {
      dispatch(loadCareGuideInvitation(id, cb));
    },
    update: (id, data, cb) => {
      dispatch(updateCareGuideInvitation(id, data, cb));
    },
    create: (data, cb) => {
      dispatch(createCareGuideInvitation(data, cb));
    },
    delete: (id, cb) => {
      dispatch(deleteCareGuideInvitation(id, cb))
    },
    destroyForm: (formState) => {
      dispatch(careGuideInvitationFormDestroy(formState));
    },
    sendMessage: (level, title, body, expires) => {
      dispatch(sendMessage(level, title, body, expires));
    },
  }
}

const CareGuideInvitationFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CareGuideInvitationForm);

export default CareGuideInvitationFormContainer;
