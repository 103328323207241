import {connect} from 'react-redux';

import ResourceTagEditScreen from '../components/ResourceTagEditScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.resourceTags.get('isLoading'),
    success: state.resourceTags.getIn(['form', 'success']),
  }
}

const ResourceTagEditScreenContainer = connect(
  mapStateToProps
)(ResourceTagEditScreen);

export default ResourceTagEditScreenContainer;
