import { normalize } from 'normalizr';

import api from '../../api';
import { schema } from '../../schema';
import { addEntities, removeEntity } from '../../actions';
import Format from '../../../lib/Format';
import Logger from '../../../lib/Logger';

// ACTION TYPES

export const PLAN_LIST_REQUEST = 'PLAN_LIST_REQUEST';
export const PLAN_LIST_SUCCESS = 'PLAN_LIST_SUCCESS';
export const PLAN_LIST_FAILURE = 'PLAN_LIST_FAILURE';
export const PLAN_READ_REQUEST = 'PLAN_READ_REQUEST';
export const PLAN_READ_SUCCESS = 'PLAN_READ_SUCCESS';
export const PLAN_READ_FAILURE = 'PLAN_READ_FAILURE';
export const PLAN_UPDATE_REQUEST = 'PLAN_UPDATE_REQUEST';
export const PLAN_UPDATE_SUCCESS = 'PLAN_UPDATE_SUCCESS';
export const PLAN_UPDATE_FAILURE = 'PLAN_UPDATE_FAILURE';
export const PLAN_CREATE_REQUEST = 'PLAN_CREATE_REQUEST';
export const PLAN_CREATE_SUCCESS = 'PLAN_CREATE_SUCCESS';
export const PLAN_CREATE_FAILURE = 'PLAN_CREATE_FAILURE';
export const PLAN_DELETE_REQUEST = 'PLAN_DELETE_REQUEST';
export const PLAN_DELETE_SUCCESS = 'PLAN_DELETE_SUCCESS';
export const PLAN_DELETE_FAILURE = 'PLAN_DELETE_FAILURE';
export const PLAN_FORM_DESTROY = 'PLAN_FORM_DESTROY';
export const PLAN_CSV_DOWNLOAD_REQUEST = 'PLAN_CSV_DOWNLOAD_REQUEST';
export const PLAN_CSV_DOWNLOAD_SUCCESS = 'PLAN_CSV_DOWNLOAD_SUCCESS';
export const PLAN_CSV_DOWNLOAD_FAILURE = 'PLAN_CSV_DOWNLOAD_FAILURE';

// ACTION CREATORS

export function planListRequest(page, limit, order, filter) {
  Logger.log(
    'debug',
    `[plans.actions] planListRequest(${page}, ${limit}, ${order}, %j)`,
    filter
  );
  return {
    type: PLAN_LIST_REQUEST,
    page: page,
    limit: limit,
    order: order,
    filter: filter,
  };
}

export function planListSuccess(data) {
  Logger.log('debug', `[plans.actions] planListSuccess(%j)`, data);
  return {
    type: PLAN_LIST_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now(),
  };
}

export function planListFailure(error) {
  Logger.log('debug', `[plans.actions] planListFailure(%j)`, error);
  return {
    type: PLAN_LIST_FAILURE,
    error: error,
  };
}

export function planReadRequest(id) {
  Logger.log('debug', `[plans.actions] planReadRequest(${id})`);
  return {
    type: PLAN_READ_REQUEST,
    id: id,
  };
}

export function planReadSuccess(data) {
  Logger.log('debug', `[plans.actions] planReadSuccess(%j)`, data);
  return {
    type: PLAN_READ_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function planReadFailure(error) {
  Logger.log('debug', `[plans.actions] planReadFailure(%j)`, error);
  return {
    type: PLAN_READ_FAILURE,
    error: error,
  };
}

export function planUpdateRequest(id, data) {
  Logger.log('debug', `[plans.actions] planUpdateRequest(${id}, %j)`, data);
  return {
    type: PLAN_UPDATE_REQUEST,
  };
}

export function planUpdateSuccess(data) {
  Logger.log('debug', `[plans.actions] planUpdateSuccess(%j)`, data);
  return {
    type: PLAN_UPDATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function planUpdateFailure(error) {
  Logger.log('debug', `[plans.actions] planUpdateFailure(%j)`, error);
  return {
    type: PLAN_UPDATE_FAILURE,
    error: error,
  };
}

export function planCreateRequest(data) {
  Logger.log('debug', `[plans.actions] planCreateRequest(%j)`, data);
  return {
    type: PLAN_CREATE_REQUEST,
  };
}

export function planCreateSuccess(data) {
  Logger.log('debug', `[plans.actions] planCreateSuccess(%j)`, data);
  return {
    type: PLAN_CREATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function planCreateFailure(error) {
  Logger.log('debug', `[plans.actions] planCreateFailure(%j)`, error);
  return {
    type: PLAN_CREATE_FAILURE,
    error: error,
  };
}

export function planDeleteRequest(id) {
  Logger.log('debug', `[plans.actions] planDeleteRequest(${id})`);
  return {
    type: PLAN_DELETE_REQUEST,
    id: id,
  };
}

export function planDeleteSuccess(id) {
  Logger.log('debug', `[plans.actions] planDeleteSuccess(${id})`);
  return {
    type: PLAN_DELETE_SUCCESS,
    id: id,
  };
}

export function planDeleteFailure(error) {
  Logger.log('debug', `[plans.actions] planDeleteFailure(%j)`, error);
  return {
    type: PLAN_DELETE_FAILURE,
    error: error,
  };
}

export function planFormDestroy(formState = null) {
  Logger.log('debug', `[plans.actions] planFormDestroy(%j)`, formState);
  return {
    type: PLAN_FORM_DESTROY,
    form: formState,
  };
}

export function planCSVDownloadRequest(order, filter) {
  Logger.log('debug', `[state.plans.actions] planCSVDownloadRequest()`);
  return {
    type: PLAN_CSV_DOWNLOAD_REQUEST,
    order: order,
    filter: filter,
  };
}

export function planCSVDownloadSuccess() {
  Logger.log('debug', `[state.plans.actions] planCSVDownloadSuccess()`);
  return {
    type: PLAN_CSV_DOWNLOAD_SUCCESS,
  };
}

export function planCSVDownloadFailure() {
  Logger.log('debug', `[state.plans.actions] planCSVDownloadFailure()`);
  return {
    type: PLAN_CSV_DOWNLOAD_FAILURE,
  };
}

// API THUNK ACTION CREATORS

export function loadPlans(
  page = 1,
  limit = 10,
  order = null,
  filter = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[plans.actions] loadPlans(${page}, ${limit}, ${order}, %j, ###)`,
    filter
  );

  return async function (dispatch) {
    dispatch(planListRequest(page, limit, order, filter));

    // call API
    const response = await api.getPlans(page, limit, order, filter);
    let success = false;
    let result = [];

    // get plans list success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API plans list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );

      const normalizedEntities = normalize(response.getIn(['data', 'plans']), [
        schema.plan,
      ]);
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result,
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(planListSuccess(data));
      result = normalizedEntities.result;
      success = true;
    } else if (1 === page && 204 === response.get('status')) {
      Logger.log(
        'info',
        `Get API plans success [empty]. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: [],
      };
      dispatch(planListSuccess(data));
      success = true;

      // get plans list failure
    } else {
      Logger.log(
        'info',
        `Get API plans list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      dispatch(planListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success, result);
  };
}

export function loadPlan(id, cb = function () {}) {
  Logger.log('debug', `[plans.actions] loadPlan(${id}, ###)`);

  return async function (dispatch) {
    dispatch(planReadRequest(id));

    // call API
    const response = await api.getPlan(id);
    let success = false;

    // get plan success
    if (200 === response.get('status')) {
      Logger.log('info', `Get API plan success. ID: ${id}.`);

      const normalizedEntities = normalize(
        [response.getIn(['data', 'plan'])],
        [schema.plan]
      );
      const data = {
        id: response.getIn(['data', 'plan', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(planReadSuccess(data));
      success = true;

      // get plan failure
    } else {
      Logger.log('info', `Get API plan failure. ID: ${id}.`);
      dispatch(planReadFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function updatePlan(id, data, cb = function () {}) {
  Logger.log('debug', `[plans.actions] updatePlan(${id}, %j, ###)`, data);

  return async function (dispatch) {
    dispatch(planUpdateRequest(id, data));

    // call API
    const response = await api.putPlan(id, data);
    let success = false;

    // put plan success
    if (200 === response.get('status')) {
      Logger.log('info', `PUT API plan success. User: ${id}.`);

      const normalizedEntities = normalize(
        [response.getIn(['data', 'plan'])],
        [schema.plan]
      );
      const data = {
        id: response.getIn(['data', 'plan', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(planUpdateSuccess(data));
      success = true;

      // get plan failure
    } else {
      Logger.log('info', `PUT API plan failure. ID: ${id}.`);
      dispatch(planUpdateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function createPlan(data, cb = function () {}) {
  Logger.log('debug', `[plans.actions] createPlan(%j, ###)`, data);

  return async function (dispatch) {
    dispatch(planCreateRequest(data));

    // call API
    const response = await api.postPlans(data);
    let success = false;

    // post plans success
    if (201 === response.get('status')) {
      Logger.log(
        'info',
        `POST API plans success. Post: ${response.getIn([
          'data',
          'plan',
          'id',
        ])}.`
      );

      const normalizedEntities = normalize(
        [response.getIn(['data', 'plan'])],
        [schema.plan]
      );
      const data = {
        id: response.getIn(['data', 'plan', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(planCreateSuccess(data));
      success = true;

      // get plans failure
    } else {
      Logger.log('info', `POST API plans failure.`);
      dispatch(planCreateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function deletePlan(id, cb = function () {}) {
  Logger.log('debug', `[plans.actions] deletePlan(${id}, ###)`);

  return async function (dispatch) {
    dispatch(planDeleteRequest(id));

    // call API
    const response = await api.deletePlan(id);
    let success = false;

    // delete plan success
    if (204 === response.get('status')) {
      Logger.log('info', `DELETE API plan success. ID: ${id}.`);

      dispatch(removeEntity({ entityType: 'plans', id: id }));
      dispatch(planDeleteSuccess(id));
      success = true;

      // get plan failure
    } else {
      Logger.log('info', `DELETE API plan failure. ID: ${id}.`);
      dispatch(planDeleteFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function downloadPlansCSV(
  order = null,
  filter = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.plans.actions] downloadPlansCSV(${order}, ${filter}, ###)`
  );

  return async function (dispatch) {
    dispatch(planCSVDownloadRequest(order, filter));

    // call API
    const response = await api.getPlansCSV(order, filter);

    // get plans CSV success
    if (200 === response.get('status')) {
      Logger.log('info', `Get API plans CSV success. Order: ${order}`);
      const now = new Date();

      // trigger browser download
      const url = window.URL.createObjectURL(new Blob([response.get('data')]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `cv-plans-${Format.date(now, 'YYYY-MM-DDTHHmm')}.csv`
      );
      document.body.appendChild(link);
      link.click();

      dispatch(planCSVDownloadSuccess());

      // get plans CSV failure
    } else {
      Logger.log('info', `Get API plans CSV failure.`);
      dispatch(planCSVDownloadFailure());
    }

    // callback function
    cb();
  };
}

Logger.log('silly', `plans.actions loaded.`);
