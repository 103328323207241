import {Map, List} from 'immutable';

import {
    SUBREGION_LIST_REQUEST,
    SUBREGION_LIST_SUCCESS,
    SUBREGION_LIST_FAILURE,
    SUBREGION_READ_REQUEST,
    SUBREGION_READ_SUCCESS,
    SUBREGION_READ_FAILURE,
    SUBREGION_UPDATE_REQUEST,
    SUBREGION_UPDATE_SUCCESS,
    SUBREGION_UPDATE_FAILURE,
    SUBREGION_CREATE_REQUEST,
    SUBREGION_CREATE_SUCCESS,
    SUBREGION_CREATE_FAILURE,
    SUBREGION_DELETE_REQUEST,
    SUBREGION_DELETE_SUCCESS,
    SUBREGION_DELETE_FAILURE,
    SUBREGION_FORM_DESTROY
  } from './actions';
  import Logger from '../../../lib/Logger';
  
export default function subregions(
    state=Map({
      areSubRegionsLoading: false
    }),
    action
  ) {
    Logger.log('debug', `[subregion.reducers] subregions(%j, %j)`, state, action);
  
    switch(action.type) {
  
      case SUBREGION_LIST_REQUEST:
        return state.mergeDeep({
          areSubRegionsLoading: true
        });
  
      case SUBREGION_LIST_SUCCESS:
        return state.mergeDeep({
          areSubRegionsLoading: false,
          pages: {
            [action.order]: {
              [action.limit]: {
                [action.page]: null
              }
            }
          },
          total: action.total,
          lastUpdated: action.receivedAt
        }).setIn(['pages', action.order, action.limit, action.page], List(action.result));
  
      case SUBREGION_LIST_FAILURE:
        return state.mergeDeep({
          areSubRegionsLoading: false
        });
      case SUBREGION_READ_REQUEST:
        return state.mergeDeep({
          areSubRegionsLoading: true
        });
  
      case SUBREGION_READ_SUCCESS:
        return state.mergeDeep({
          areSubRegionsLoading: false,
          lastUpdated: action.receivedAt
        });
  
      case SUBREGION_READ_FAILURE:
        return state.mergeDeep({
          areSubRegionsLoading: false
        });
        case SUBREGION_UPDATE_REQUEST:
          return state.mergeDeep({
            areSubRegionsLoading: true,
            form: {
              isSubmitting: true,
              success: null,
              errors: null
            }
          });
    
        case SUBREGION_UPDATE_SUCCESS:
          return state.mergeDeep({
            areSubRegionsLoading: false,
            form: {
              isSubmitting: false,
              success: true,
              errors: null
            },
            lastUpdated: action.receivedAt
          });
    
        case SUBREGION_UPDATE_FAILURE:
          return state.mergeDeep({
            areSubRegionsLoading: false,
            form: {
              isSubmitting: false,
              success: false,
              errors: null
            }
          }).setIn(['form', 'errors'], action.error);
    
        case SUBREGION_CREATE_REQUEST:
          return state.mergeDeep({
            areSubRegionsLoading: true,
            form: {
              isSubmitting: true,
              success: null,
              errors: null
            }
          });
    
        case SUBREGION_CREATE_SUCCESS:
          return state.mergeDeep({
            areSubRegionsLoading: false,
            form: {
              isSubmitting: false,
              success: true,
              created_id: action.id,
              errors: null
            },
            lastUpdated: action.receivedAt
          });
    
        case SUBREGION_CREATE_FAILURE:
          return state.mergeDeep({
            areSubRegionsLoading: false,
            form: {
              isSubmitting: false,
              success: false,
              errors: null
            }
          }).setIn(['form', 'errors'], action.error);
    
        case SUBREGION_DELETE_REQUEST:
          return state.mergeDeep({
            areSubRegionsLoading: true
          });
    
        case SUBREGION_DELETE_SUCCESS:
          return state.mergeDeep({
            areSubRegionsLoading: false,
            lastUpdated: action.receivedAt
          });
    
        case SUBREGION_DELETE_FAILURE:
          return state.mergeDeep({
            areSubRegionsLoading: false
          });
    
        case SUBREGION_FORM_DESTROY:
          return state.mergeDeep({
            form: null
          }).set('form', action.form);
          
      default:
        return state;
    }
  }
  
  Logger.log('silly', `subregion.reducers loaded.`);