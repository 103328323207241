import {normalize} from 'normalizr';

import api from '../../api';
import {schema} from '../../schema';
import {addEntities, removeEntity} from '../../actions';
import Format from '../../../lib/Format';
import Logger from '../../../lib/Logger';

// ACTION TYPES

export const CARE_GUIDE_OWNER_LIST_REQUEST = 'CARE_GUIDE_OWNER_LIST_REQUEST';
export const CARE_GUIDE_OWNER_LIST_SUCCESS = 'CARE_GUIDE_OWNER_LIST_SUCCESS';
export const CARE_GUIDE_OWNER_LIST_FAILURE = 'CARE_GUIDE_OWNER_LIST_FAILURE';
export const CARE_GUIDE_OWNER_READ_REQUEST = 'CARE_GUIDE_OWNER_READ_REQUEST';
export const CARE_GUIDE_OWNER_READ_SUCCESS = 'CARE_GUIDE_OWNER_READ_SUCCESS';
export const CARE_GUIDE_OWNER_READ_FAILURE = 'CARE_GUIDE_OWNER_READ_FAILURE';
export const CARE_GUIDE_OWNER_UPDATE_REQUEST = 'CARE_GUIDE_OWNER_UPDATE_REQUEST';
export const CARE_GUIDE_OWNER_UPDATE_SUCCESS = 'CARE_GUIDE_OWNER_UPDATE_SUCCESS';
export const CARE_GUIDE_OWNER_UPDATE_FAILURE = 'CARE_GUIDE_OWNER_UPDATE_FAILURE';
export const CARE_GUIDE_OWNER_CREATE_REQUEST = 'CARE_GUIDE_OWNER_CREATE_REQUEST';
export const CARE_GUIDE_OWNER_CREATE_SUCCESS = 'CARE_GUIDE_OWNER_CREATE_SUCCESS';
export const CARE_GUIDE_OWNER_CREATE_FAILURE = 'CARE_GUIDE_OWNER_CREATE_FAILURE';
export const CARE_GUIDE_OWNER_DELETE_REQUEST = 'CARE_GUIDE_OWNER_DELETE_REQUEST';
export const CARE_GUIDE_OWNER_DELETE_SUCCESS = 'CARE_GUIDE_OWNER_DELETE_SUCCESS';
export const CARE_GUIDE_OWNER_DELETE_FAILURE = 'CARE_GUIDE_OWNER_DELETE_FAILURE';
export const CARE_GUIDE_OWNER_FORM_DESTROY = 'CARE_GUIDE_OWNER_FORM_DESTROY';
export const CARE_GUIDE_OWNER_CSV_DOWNLOAD_REQUEST = 'CARE_GUIDE_OWNER_CSV_DOWNLOAD_REQUEST';
export const CARE_GUIDE_OWNER_CSV_DOWNLOAD_SUCCESS = 'CARE_GUIDE_OWNER_CSV_DOWNLOAD_SUCCESS';
export const CARE_GUIDE_OWNER_CSV_DOWNLOAD_FAILURE = 'CARE_GUIDE_OWNER_CSV_DOWNLOAD_FAILURE';

// ACTION CREATORS

export function careGuideOwnerListRequest(page, limit, order, filter) {
  Logger.log('debug', `[careGuideOwners.actions] careGuideOwnerListRequest(${page}, ${limit}, ${order}, %j)`, filter);
  return {
    type: CARE_GUIDE_OWNER_LIST_REQUEST,
    page: page,
    limit: limit,
    order: order,
    filter: filter
  }
}

export function careGuideOwnerListSuccess(data) {
  Logger.log('debug', `[careGuideOwners.actions] careGuideOwnerListSuccess(%j)`, data);
  return {
    type: CARE_GUIDE_OWNER_LIST_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now()
  }
}

export function careGuideOwnerListFailure(error) {
  Logger.log('debug', `[careGuideOwners.actions] careGuideOwnerListFailure(%j)`, error);
  return {
    type: CARE_GUIDE_OWNER_LIST_FAILURE,
    error: error
  }
}

export function careGuideOwnerReadRequest(id) {
  Logger.log('debug', `[careGuideOwners.actions] careGuideOwnerReadRequest(${id})`);
  return {
    type: CARE_GUIDE_OWNER_READ_REQUEST,
    id: id
  }
}

export function careGuideOwnerReadSuccess(data) {
  Logger.log('debug', `[careGuideOwners.actions] careGuideOwnerReadSuccess(%j)`, data);
  return {
    type: CARE_GUIDE_OWNER_READ_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function careGuideOwnerReadFailure(error) {
  Logger.log('debug', `[careGuideOwners.actions] careGuideOwnerReadFailure(%j)`, error);
  return {
    type: CARE_GUIDE_OWNER_READ_FAILURE,
    error: error
  }
}

export function careGuideOwnerUpdateRequest(id, data) {
  Logger.log('debug', `[careGuideOwners.actions] careGuideOwnerUpdateRequest(${id}, %j)`, data);
  return {
    type: CARE_GUIDE_OWNER_UPDATE_REQUEST,
  }
}

export function careGuideOwnerUpdateSuccess(data) {
  Logger.log('debug', `[careGuideOwners.actions] careGuideOwnerUpdateSuccess(%j)`, data);
  return {
    type: CARE_GUIDE_OWNER_UPDATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function careGuideOwnerUpdateFailure(error) {
  Logger.log('debug', `[careGuideOwners.actions] careGuideOwnerUpdateFailure(%j)`, error);
  return {
    type: CARE_GUIDE_OWNER_UPDATE_FAILURE,
    error: error
  }
}

export function careGuideOwnerCreateRequest(data) {
  Logger.log('debug', `[careGuideOwners.actions] careGuideOwnerCreateRequest(%j)`, data);
  return {
    type: CARE_GUIDE_OWNER_CREATE_REQUEST,
  }
}

export function careGuideOwnerCreateSuccess(data) {
  Logger.log('debug', `[careGuideOwners.actions] careGuideOwnerCreateSuccess(%j)`, data);
  return {
    type: CARE_GUIDE_OWNER_CREATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function careGuideOwnerCreateFailure(error) {
  Logger.log('debug', `[careGuideOwners.actions] careGuideOwnerCreateFailure(%j)`, error);
  return {
    type: CARE_GUIDE_OWNER_CREATE_FAILURE,
    error: error
  }
}

export function careGuideOwnerDeleteRequest(id) {
  Logger.log('debug', `[careGuideOwners.actions] careGuideOwnerDeleteRequest(${id})`);
  return {
    type: CARE_GUIDE_OWNER_DELETE_REQUEST,
    id: id
  }
}

export function careGuideOwnerDeleteSuccess(id) {
  Logger.log('debug', `[careGuideOwners.actions] careGuideOwnerDeleteSuccess(${id})`);
  return {
    type: CARE_GUIDE_OWNER_DELETE_SUCCESS,
    id: id,
  }
}

export function careGuideOwnerDeleteFailure(error) {
  Logger.log('debug', `[careGuideOwners.actions] careGuideOwnerDeleteFailure(%j)`, error);
  return {
    type: CARE_GUIDE_OWNER_DELETE_FAILURE,
    error: error
  }
}

export function careGuideOwnerFormDestroy(formState=null) {
  Logger.log('debug', `[careGuideOwners.actions] careGuideOwnerFormDestroy(%j)`, formState);
  return {
    type: CARE_GUIDE_OWNER_FORM_DESTROY,
    form: formState
  }
}

export function careGuideOwnerCSVDownloadRequest(order, filter) {
  Logger.log('debug', `[state.careGuideOwners.actions] careGuideOwnerCSVDownloadRequest()`);
  return {
    type: CARE_GUIDE_OWNER_CSV_DOWNLOAD_REQUEST,
    order: order,
    filter: filter,
  }
}

export function careGuideOwnerCSVDownloadSuccess() {
  Logger.log('debug', `[state.careGuideOwners.actions] careGuideOwnerCSVDownloadSuccess()`);
  return {
    type: CARE_GUIDE_OWNER_CSV_DOWNLOAD_SUCCESS,
  }
}

export function careGuideOwnerCSVDownloadFailure() {
  Logger.log('debug', `[state.careGuideOwners.actions] careGuideOwnerCSVDownloadFailure()`);
  return {
    type: CARE_GUIDE_OWNER_CSV_DOWNLOAD_FAILURE,
  }
}


// API THUNK ACTION CREATORS

export function loadCareGuideOwners(page=1, limit=10, order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[careGuideOwners.actions] loadCareGuideOwners(${page}, ${limit}, ${order}, %j, ###)`, filter);

  return async function(dispatch) {
    dispatch(careGuideOwnerListRequest(page, limit, order, filter));

    // call API
    const response = await api.getCareGuideOwners(page, limit, order, filter);
    let success = false;
    let result = [];

    // get care guide owners list success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API care guide owners list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`);

      const normalizedEntities = normalize(response.getIn(['data', 'care_guide_owners']), [schema.careGuideOwner]);
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideOwnerListSuccess(data));
      success = true;
      result = normalizedEntities.result;
    } else if (1 === page && 204 === response.get('status')) {

      Logger.log('info', `Get API care guide owners success [empty]. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: []
      };
      dispatch(careGuideOwnerListSuccess(data));
      success = true;
      
    // get care guide owners list failure
    } else {
      Logger.log('info', `Get API care guide owners list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      dispatch(careGuideOwnerListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success, result);
  }
}

export function loadCareGuideOwner(id, cb=function(){}) {
  Logger.log('debug', `[careGuideOwners.actions] loadCareGuideOwner(${id}, ###)`);

  return async function(dispatch) {
    dispatch(careGuideOwnerReadRequest(id));

    // call API
    const response = await api.getCareGuideOwner(id);
    let success = false;

    // get care guide owner success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API care guide owner success. ID: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'care_guide_owner'])], [schema.careGuideOwner]);
      const data = {
        id: response.getIn(['data', 'care_guide_owner', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideOwnerReadSuccess(data));
      success = true;
      
    // get care guide owner failure
    } else {
      Logger.log('info', `Get API care guide owner failure. ID: ${id}.`);
      dispatch(careGuideOwnerReadFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function updateCareGuideOwner(id, data, cb=function(){}) {
  Logger.log('debug', `[careGuideOwners.actions] updateCareGuideOwner(${id}, %j, ###)`, data);

  return async function(dispatch) {
    dispatch(careGuideOwnerUpdateRequest(id, data));

    // call API
    const response = await api.putCareGuideOwner(id, data);
    let success = false;

    // put care guide owner success
    if (200 === response.get('status')) {

      Logger.log('info', `PUT API care guide owner success. User: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'care_guide_owner'])], [schema.careGuideOwner]);
      const data = {
        id: response.getIn(['data', 'care_guide_owner', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideOwnerUpdateSuccess(data));
      success = true;
      
    // get care guide owner failure
    } else {
      Logger.log('info', `PUT API care guide owner failure. ID: ${id}.`);
      dispatch(careGuideOwnerUpdateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function createCareGuideOwner(data, cb=function(){}) {
  Logger.log('debug', `[careGuideOwners.actions] createCareGuideOwner(%j, ###)`, data);

  return async function(dispatch) {
    dispatch(careGuideOwnerCreateRequest(data));

    // call API
    const response = await api.postCareGuideOwners(data);
    let success = false;

    // post care guide owners success
    if (201 === response.get('status')) {

      Logger.log('info', `POST API care guide owners success. Post: ${response.getIn(['data', 'care_guide_owner', 'id'])}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'care_guide_owner'])], [schema.careGuideOwner]);
      const data = {
        id: response.getIn(['data', 'care_guide_owner', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideOwnerCreateSuccess(data));
      success = true;
      
    // get care guide owners failure
    } else {
      Logger.log('info', `POST API care guide owners failure.`);
      dispatch(careGuideOwnerCreateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function deleteCareGuideOwner(id, cb=function(){}) {
  Logger.log('debug', `[careGuideOwners.actions] deleteCareGuideOwner(${id}, ###)`);

  return async function(dispatch) {
    dispatch(careGuideOwnerDeleteRequest(id));

    // call API
    const response = await api.deleteCareGuideOwner(id);
    let success = false;

    // delete care guide owner success
    if (204 === response.get('status')) {

      Logger.log('info', `DELETE API care guide owner success. ID: ${id}.`);

      dispatch(removeEntity({entityType: 'care_guide_owners', id: id}));
      dispatch(careGuideOwnerDeleteSuccess(id));
      success = true;
      
    // get care guide owner failure
    } else {
      Logger.log('info', `DELETE API care guide owner failure. ID: ${id}.`);
      dispatch(careGuideOwnerDeleteFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function downloadCareGuideOwndersCSV(order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[state.careGuideOwners.actions] downloadCareGuideOwndersCSV(${order}, ${filter}, ###)`);

  return async function(dispatch) {
    dispatch(careGuideOwnerCSVDownloadRequest(order, filter));

    // call API
    const response = await api.getCareGuideOwnersCSV(order, filter);

    // get care guide owners CSV success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API care guide owners CSV success. Order: ${order}`);
      const now = new Date();

      // trigger browser download
      const url = window.URL.createObjectURL(new Blob([response.get('data')]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `cv-care-guide-owners-${Format.date(now, 'YYYY-MM-DDTHHmm')}.csv`);
      document.body.appendChild(link);
      link.click();

      dispatch(careGuideOwnerCSVDownloadSuccess());
      
    // get care guide owners CSV failure
    } else {
      Logger.log('info', `Get API care guide owners CSV failure.`);
      dispatch(careGuideOwnerCSVDownloadFailure());
    }

    // callback function
    cb();
  }
}

Logger.log('silly', `careGuideOwners.actions loaded.`);
