import { connect } from 'react-redux';
import { denormalize } from 'normalizr';

import { schema } from '../../../../../state/schema';
import {
  loadRecommendedUtilities,
  deleteRecommendedUtility,
} from '../../../../../state/modules/recommendedUtilities/actions';
import RecommendedUtilitiesList from '../components/RecommendedUtilitiesList';

const mapStateToProps = (state, ownProps) => {
  const page = ownProps.page;
  const limit = ownProps.limit;
  const order = ownProps.order;
  const result = state.recommendedUtilities.getIn([
    'pages',
    order,
    limit,
    page,
  ]);

  return {
    list: result
      ? result
          .map((x) => {
            return {
              key: x,
              ...denormalize(
                state.entities.getIn(['recommendedUtilities', x]),
                schema.recommendedUtility,
                state.entities.toJS()
              ),
            };
          })
          .toArray()
          .map((x) => {
            // flatten some nested objects to make table easier to work with
            return {
              partnership_name: x?.partnership?.name ?? '',
              partnership_plan: x?.partnership?.plan?.name ?? '',
              profile_username: x.profile?.user?.username || '',
              ...x,
            };
          })
      : [],
    isLoading: state.recommendedUtilities.get('isLoading'),
    total: state.recommendedUtilities.get('total'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (page, limit, order, filter, cb) => {
      dispatch(loadRecommendedUtilities(page, limit, order, filter, cb));
    },
    remove: (id, cb) => {
      dispatch(deleteRecommendedUtility(id, cb));
    },
  };
};

const RecommendedUtilitiesListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RecommendedUtilitiesList);

export default RecommendedUtilitiesListContainer;
