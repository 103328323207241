import {connect} from 'react-redux';

import CareGuideOwnerEditScreen from '../components/CareGuideOwnerEditScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.careGuideOwners.get('isLoading'),
    success: state.careGuideOwners.getIn(['form', 'success']),
  }
}

const CareGuideOwnerEditScreenContainer = connect(
  mapStateToProps
)(CareGuideOwnerEditScreen);

export default CareGuideOwnerEditScreenContainer;
