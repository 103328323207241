import {connect} from 'react-redux';

import {downloadAccountsCSV} from '../../../../../state/modules/accounts/actions';
import AccountsCSVButton from '../components/AccountsCSVButton';

const mapStateToProps = state => {
  return {
    isDownloading: state.accounts.get('isCSVDownloading'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    download: (order, filter, cb) => {
      dispatch(downloadAccountsCSV(order, filter, cb));
    }
  }
}

const AccountsCSVButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountsCSVButton);

export default AccountsCSVButtonContainer;
