import { Map, List } from 'immutable';

import {
  DASHBOARD_SECTION_LIST_REQUEST,
  DASHBOARD_SECTION_LIST_SUCCESS,
  DASHBOARD_SECTION_LIST_FAILURE,
  DASHBOARD_SECTION_READ_REQUEST,
  DASHBOARD_SECTION_READ_SUCCESS,
  DASHBOARD_SECTION_READ_FAILURE,
  DASHBOARD_SECTION_UPDATE_REQUEST,
  DASHBOARD_SECTION_UPDATE_SUCCESS,
  DASHBOARD_SECTION_UPDATE_FAILURE,
  DASHBOARD_SECTION_CREATE_REQUEST,
  DASHBOARD_SECTION_CREATE_SUCCESS,
  DASHBOARD_SECTION_CREATE_FAILURE,
  DASHBOARD_SECTION_DELETE_REQUEST,
  DASHBOARD_SECTION_DELETE_SUCCESS,
  DASHBOARD_SECTION_DELETE_FAILURE,
  DASHBOARD_SECTION_FORM_DESTROY,
} from './actions';
import Logger from '../../../lib/Logger';

export default function dashboardSections(
  state = Map({
    isLoading: false,
    pages: {},
  }),
  action
) {
  Logger.log(
    'debug',
    `[dashboardSections.reducers] dashboardSections(%j, %j)`,
    state,
    action
  );

  switch (action.type) {
    case DASHBOARD_SECTION_LIST_REQUEST:
      return state.mergeDeep({
        isLoading: true,
      });

    case DASHBOARD_SECTION_LIST_SUCCESS:
      return state
        .mergeDeep({
          isLoading: false,
          pages: {
            [action.order]: {
              [action.limit]: {
                [action.page]: null,
              },
            },
          },
          total: action.total,
          lastUpdated: action.receivedAt,
        })
        .setIn(
          ['pages', action.order, action.limit, action.page],
          List(action.result)
        );

    case DASHBOARD_SECTION_LIST_FAILURE:
      return state.mergeDeep({
        isLoading: false,
      });

    case DASHBOARD_SECTION_READ_REQUEST:
      return state.mergeDeep({
        isLoading: true,
      });

    case DASHBOARD_SECTION_READ_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        lastUpdated: action.receivedAt,
      });

    case DASHBOARD_SECTION_READ_FAILURE:
      return state.mergeDeep({
        isLoading: false,
      });

    case DASHBOARD_SECTION_UPDATE_REQUEST:
      return state.mergeDeep({
        isLoading: true,
        form: {
          isSubmitting: true,
          success: null,
          errors: null,
        },
      });

    case DASHBOARD_SECTION_UPDATE_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        form: {
          isSubmitting: false,
          success: true,
          errors: null,
        },
        lastUpdated: action.receivedAt,
      });

    case DASHBOARD_SECTION_UPDATE_FAILURE:
      return state
        .mergeDeep({
          isLoading: false,
          form: {
            isSubmitting: false,
            success: false,
            errors: null,
          },
        })
        .setIn(['form', 'errors'], action.error);

    case DASHBOARD_SECTION_CREATE_REQUEST:
      return state.mergeDeep({
        isLoading: true,
        form: {
          isSubmitting: true,
          success: null,
          errors: null,
        },
      });

    case DASHBOARD_SECTION_CREATE_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        form: {
          isSubmitting: false,
          success: true,
          created_id: action.id,
          errors: null,
        },
        lastUpdated: action.receivedAt,
      });

    case DASHBOARD_SECTION_CREATE_FAILURE:
      return state
        .mergeDeep({
          isLoading: false,
          form: {
            isSubmitting: false,
            success: false,
            errors: null,
          },
        })
        .setIn(['form', 'errors'], action.error);

    case DASHBOARD_SECTION_DELETE_REQUEST:
      return state.mergeDeep({
        isLoading: true,
      });

    case DASHBOARD_SECTION_DELETE_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        lastUpdated: action.receivedAt,
      });

    case DASHBOARD_SECTION_DELETE_FAILURE:
      return state.mergeDeep({
        isLoading: false,
      });

    case DASHBOARD_SECTION_FORM_DESTROY:
      return state
        .mergeDeep({
          form: null,
        })
        .set('form', action.form);

    default:
      return state;
  }
}

Logger.log('silly', `dashboardSections.reducers loaded.`);
