import React from 'react';
import { withRouter } from 'react-router';
import { Translation, getI18n } from 'react-i18next';
import { Button, Popconfirm, Space } from 'antd';
import { EditOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons';

import message from '../lib/MessageWrapper';
import { pathTo } from '../../Routes';

const ListActions = ({
  id,
  page,
  limit,
  order,
  filter,
  load,
  remove,
  editScreen,
  history,
  viewScreen,
  actionDelete = true,
  actionEdit = true,
  actionView = false,
  ...props
}) => {
  const confirm = (id) => {
    remove(id, (success) => {
      if (success) {
        load(page, limit, order, filter);
        message.success(getI18n().t('feedback_delete_success'));
      } else {
        message.error(getI18n().t('feedback_delete_error'));
      }
    });
  };

  return (
    <Translation>
      {(t) => (
        <>
          <Space>
            {actionView && (
              <Button
                icon={<EyeOutlined />}
                onClick={(e) => history.push(pathTo(viewScreen, { id: id }))}
              />
            )}

            {actionEdit && (
              <Button
                icon={<EditOutlined />}
                onClick={(e) => history.push(pathTo(editScreen, { id: id }))}
              />
            )}
            {actionDelete && (
              <Popconfirm
                placement="topRight"
                title={t('delete_confirm_body')}
                onConfirm={(e) => confirm(id)}
                okText={t('confirm_yes')}
                cancelText={t('confirm_cancel')}
              >
                <Button danger icon={<DeleteOutlined />} />
              </Popconfirm>
            )}
          </Space>
        </>
      )}
    </Translation>
  );
};

export default withRouter(ListActions);
