import React, { useEffect } from 'react';
import { Translation } from 'react-i18next';
import { Form, Select } from 'antd';

const MembersInput = ({
  careGuideIds,
  name,
  label,
  options,
  isLoading,
  isSubmitting,
  load,
  multiple = false,
  emptyOption = false,
  rules = [],
  ...props
}) => {
  // load members on mount
  useEffect(() => {
    if (careGuideIds.length > 0) {
      load({ care_guide_ids: careGuideIds });
    }
  }, [load, careGuideIds]);

  return (
    <Translation>
      {(t) => (
        <div className="form-group">
          <Form.Item
            name={name}
            label={label ? label : t('care_guide_helper_members')}
            rules={rules}
          >
            <Select
              mode={multiple ? 'multiple' : null}
              showSearch
              optionFilterProp="children"
              disabled={isLoading || isSubmitting}
              loading={isLoading}
            >
              {emptyOption ? (
                <Select.Option key="empty-option" value={null}>
                  {t('form_input_options_none')}
                </Select.Option>
              ) : null}
              {options.map((x) => (
                <Select.Option key={x.value} value={x.value}>
                  {x.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      )}
    </Translation>
  );
};

export default MembersInput;
