import {connect} from 'react-redux';

import CareGuideTemplatesScreen from '../components/CareGuideTemplatesScreen';

const mapStateToProps = state => {
  return {
    total: state.careGuideTemplates.get('total'),
    isLoading: state.careGuideTemplates.get('isLoading')
  }
}

const CareGuideTemplatesScreenContainer = connect(
  mapStateToProps
)(CareGuideTemplatesScreen);

export default CareGuideTemplatesScreenContainer;
