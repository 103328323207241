import {normalize} from 'normalizr';

import api from '../../api';
import {schema} from '../../schema';
import {addEntities, removeEntity} from '../../actions';
import Format from '../../../lib/Format';
import Logger from '../../../lib/Logger';

// ACTION TYPES

export const CARE_GUIDE_SECTION_LIST_REQUEST = 'CARE_GUIDE_SECTION_LIST_REQUEST';
export const CARE_GUIDE_SECTION_LIST_SUCCESS = 'CARE_GUIDE_SECTION_LIST_SUCCESS';
export const CARE_GUIDE_SECTION_LIST_FAILURE = 'CARE_GUIDE_SECTION_LIST_FAILURE';
export const CARE_GUIDE_SECTION_READ_REQUEST = 'CARE_GUIDE_SECTION_READ_REQUEST';
export const CARE_GUIDE_SECTION_READ_SUCCESS = 'CARE_GUIDE_SECTION_READ_SUCCESS';
export const CARE_GUIDE_SECTION_READ_FAILURE = 'CARE_GUIDE_SECTION_READ_FAILURE';
export const CARE_GUIDE_SECTION_UPDATE_REQUEST = 'CARE_GUIDE_SECTION_UPDATE_REQUEST';
export const CARE_GUIDE_SECTION_UPDATE_SUCCESS = 'CARE_GUIDE_SECTION_UPDATE_SUCCESS';
export const CARE_GUIDE_SECTION_UPDATE_FAILURE = 'CARE_GUIDE_SECTION_UPDATE_FAILURE';
export const CARE_GUIDE_SECTION_CREATE_REQUEST = 'CARE_GUIDE_SECTION_CREATE_REQUEST';
export const CARE_GUIDE_SECTION_CREATE_SUCCESS = 'CARE_GUIDE_SECTION_CREATE_SUCCESS';
export const CARE_GUIDE_SECTION_CREATE_FAILURE = 'CARE_GUIDE_SECTION_CREATE_FAILURE';
export const CARE_GUIDE_SECTION_DELETE_REQUEST = 'CARE_GUIDE_SECTION_DELETE_REQUEST';
export const CARE_GUIDE_SECTION_DELETE_SUCCESS = 'CARE_GUIDE_SECTION_DELETE_SUCCESS';
export const CARE_GUIDE_SECTION_DELETE_FAILURE = 'CARE_GUIDE_SECTION_DELETE_FAILURE';
export const CARE_GUIDE_SECTION_FORM_DESTROY = 'CARE_GUIDE_SECTION_FORM_DESTROY';
export const CARE_GUIDE_SECTION_CSV_DOWNLOAD_REQUEST = 'CARE_GUIDE_SECTION_CSV_DOWNLOAD_REQUEST';
export const CARE_GUIDE_SECTION_CSV_DOWNLOAD_SUCCESS = 'CARE_GUIDE_SECTION_CSV_DOWNLOAD_SUCCESS';
export const CARE_GUIDE_SECTION_CSV_DOWNLOAD_FAILURE = 'CARE_GUIDE_SECTION_CSV_DOWNLOAD_FAILURE';

// ACTION CREATORS

export function careGuideSectionListRequest(page, limit, order, filter) {
  Logger.log('debug', `[careGuideSections.actions] careGuideSectionListRequest(${page}, ${limit}, ${order}, %j)`, filter);
  return {
    type: CARE_GUIDE_SECTION_LIST_REQUEST,
    page: page,
    limit: limit,
    order: order,
    filter: filter
  }
}

export function careGuideSectionListSuccess(data) {
  Logger.log('debug', `[careGuideSections.actions] careGuideSectionListSuccess(%j)`, data);
  return {
    type: CARE_GUIDE_SECTION_LIST_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now()
  }
}

export function careGuideSectionListFailure(error) {
  Logger.log('debug', `[careGuideSections.actions] careGuideSectionListFailure(%j)`, error);
  return {
    type: CARE_GUIDE_SECTION_LIST_FAILURE,
    error: error
  }
}

export function careGuideSectionReadRequest(id) {
  Logger.log('debug', `[careGuideSections.actions] careGuideSectionReadRequest(${id})`);
  return {
    type: CARE_GUIDE_SECTION_READ_REQUEST,
    id: id
  }
}

export function careGuideSectionReadSuccess(data) {
  Logger.log('debug', `[careGuideSections.actions] careGuideSectionReadSuccess(%j)`, data);
  return {
    type: CARE_GUIDE_SECTION_READ_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function careGuideSectionReadFailure(error) {
  Logger.log('debug', `[careGuideSections.actions] careGuideSectionReadFailure(%j)`, error);
  return {
    type: CARE_GUIDE_SECTION_READ_FAILURE,
    error: error
  }
}

export function careGuideSectionUpdateRequest(id, data) {
  Logger.log('debug', `[careGuideSections.actions] careGuideSectionUpdateRequest(${id}, %j)`, data);
  return {
    type: CARE_GUIDE_SECTION_UPDATE_REQUEST,
  }
}

export function careGuideSectionUpdateSuccess(data) {
  Logger.log('debug', `[careGuideSections.actions] careGuideSectionUpdateSuccess(%j)`, data);
  return {
    type: CARE_GUIDE_SECTION_UPDATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function careGuideSectionUpdateFailure(error) {
  Logger.log('debug', `[careGuideSections.actions] careGuideSectionUpdateFailure(%j)`, error);
  return {
    type: CARE_GUIDE_SECTION_UPDATE_FAILURE,
    error: error
  }
}

export function careGuideSectionCreateRequest(data) {
  Logger.log('debug', `[careGuideSections.actions] careGuideSectionCreateRequest(%j)`, data);
  return {
    type: CARE_GUIDE_SECTION_CREATE_REQUEST,
  }
}

export function careGuideSectionCreateSuccess(data) {
  Logger.log('debug', `[careGuideSections.actions] careGuideSectionCreateSuccess(%j)`, data);
  return {
    type: CARE_GUIDE_SECTION_CREATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function careGuideSectionCreateFailure(error) {
  Logger.log('debug', `[careGuideSections.actions] careGuideSectionCreateFailure(%j)`, error);
  return {
    type: CARE_GUIDE_SECTION_CREATE_FAILURE,
    error: error
  }
}

export function careGuideSectionDeleteRequest(id) {
  Logger.log('debug', `[careGuideSections.actions] careGuideSectionDeleteRequest(${id})`);
  return {
    type: CARE_GUIDE_SECTION_DELETE_REQUEST,
    id: id
  }
}

export function careGuideSectionDeleteSuccess(id) {
  Logger.log('debug', `[careGuideSections.actions] careGuideSectionDeleteSuccess(${id})`);
  return {
    type: CARE_GUIDE_SECTION_DELETE_SUCCESS,
    id: id,
  }
}

export function careGuideSectionDeleteFailure(error) {
  Logger.log('debug', `[careGuideSections.actions] careGuideSectionDeleteFailure(%j)`, error);
  return {
    type: CARE_GUIDE_SECTION_DELETE_FAILURE,
    error: error
  }
}

export function careGuideSectionFormDestroy(formState=null) {
  Logger.log('debug', `[careGuideSections.actions] careGuideSectionFormDestroy(%j)`, formState);
  return {
    type: CARE_GUIDE_SECTION_FORM_DESTROY,
    form: formState
  }
}

export function careGuideSectionCSVDownloadRequest(order, filter) {
  Logger.log('debug', `[state.careGuideSections.actions] careGuideSectionCSVDownloadRequest()`);
  return {
    type: CARE_GUIDE_SECTION_CSV_DOWNLOAD_REQUEST,
    order: order,
    filter: filter,
  }
}

export function careGuideSectionCSVDownloadSuccess() {
  Logger.log('debug', `[state.careGuideSections.actions] careGuideSectionCSVDownloadSuccess()`);
  return {
    type: CARE_GUIDE_SECTION_CSV_DOWNLOAD_SUCCESS,
  }
}

export function careGuideSectionCSVDownloadFailure() {
  Logger.log('debug', `[state.careGuideSections.actions] careGuideSectionCSVDownloadFailure()`);
  return {
    type: CARE_GUIDE_SECTION_CSV_DOWNLOAD_FAILURE,
  }
}


// API THUNK ACTION CREATORS

export function loadCareGuideSections(page=1, limit=10, order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[careGuideSections.actions] loadCareGuideSections(${page}, ${limit}, ${order}, %j, ###)`, filter);

  return async function(dispatch) {
    dispatch(careGuideSectionListRequest(page, limit, order, filter));

    // call API
    const response = await api.getCareGuideSections(page, limit, order, filter);
    let success = false;

    // get care guide sections list success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API care guide sections list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`);

      const normalizedEntities = normalize(response.getIn(['data', 'care_guide_sections']), [schema.careGuideSection]);
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideSectionListSuccess(data));
      success = true;

    } else if (1 === page && 204 === response.get('status')) {

      Logger.log('info', `Get API care guide sections success [empty]. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: []
      };
      dispatch(careGuideSectionListSuccess(data));
      success = true;
      
    // get care guide sections list failure
    } else {
      Logger.log('info', `Get API care guide sections list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      dispatch(careGuideSectionListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function loadCareGuideSection(id, cb=function(){}) {
  Logger.log('debug', `[careGuideSections.actions] loadCareGuideSection(${id}, ###)`);

  return async function(dispatch) {
    dispatch(careGuideSectionReadRequest(id));

    // call API
    const response = await api.getCareGuideSection(id);
    let success = false;

    // get care guide section success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API care guide section success. ID: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'care_guide_section'])], [schema.careGuideSection]);
      const data = {
        id: response.getIn(['data', 'care_guide_section', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideSectionReadSuccess(data));
      success = true;
      
    // get care guide section failure
    } else {
      Logger.log('info', `Get API care guide section failure. ID: ${id}.`);
      dispatch(careGuideSectionReadFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function updateCareGuideSection(id, data, cb=function(){}) {
  Logger.log('debug', `[careGuideSections.actions] updateCareGuideSection(${id}, %j, ###)`, data);

  return async function(dispatch) {
    dispatch(careGuideSectionUpdateRequest(id, data));

    // call API
    const response = await api.putCareGuideSection(id, data);
    let success = false;

    // put care guide section success
    if (200 === response.get('status')) {

      Logger.log('info', `PUT API care guide section success. User: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'care_guide_section'])], [schema.careGuideSection]);
      const data = {
        id: response.getIn(['data', 'care_guide_section', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideSectionUpdateSuccess(data));
      success = true;
      
    // get care guide section failure
    } else {
      Logger.log('info', `PUT API care guide section failure. ID: ${id}.`);
      dispatch(careGuideSectionUpdateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function createCareGuideSection(data, cb=function(){}) {
  Logger.log('debug', `[careGuideSections.actions] createCareGuideSection(%j, ###)`, data);

  return async function(dispatch) {
    dispatch(careGuideSectionCreateRequest(data));

    // call API
    const response = await api.postCareGuideSections(data);
    let success = false;

    // post care guide sections success
    if (201 === response.get('status')) {

      Logger.log('info', `POST API care guide sections success. Post: ${response.getIn(['data', 'care_guide_section', 'id'])}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'care_guide_section'])], [schema.careGuideSection]);
      const data = {
        id: response.getIn(['data', 'care_guide_section', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideSectionCreateSuccess(data));
      success = true;
      
    // get care guide sections failure
    } else {
      Logger.log('info', `POST API care guide sections failure.`);
      dispatch(careGuideSectionCreateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function deleteCareGuideSection(id, cb=function(){}) {
  Logger.log('debug', `[careGuideSections.actions] deleteCareGuideSection(${id}, ###)`);

  return async function(dispatch) {
    dispatch(careGuideSectionDeleteRequest(id));

    // call API
    const response = await api.deleteCareGuideSection(id);
    let success = false;

    // delete care guide section success
    if (204 === response.get('status')) {

      Logger.log('info', `DELETE API care guide section success. ID: ${id}.`);

      dispatch(removeEntity({entityType: 'care_guide_sections', id: id}));
      dispatch(careGuideSectionDeleteSuccess(id));
      success = true;
      
    // get care guide section failure
    } else {
      Logger.log('info', `DELETE API care guide section failure. ID: ${id}.`);
      dispatch(careGuideSectionDeleteFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function downloadCareGuideSectionsCSV(order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[state.careGuideSections.actions] downloadCareGuideSectionsCSV(${order}, ${filter}, ###)`);

  return async function(dispatch) {
    dispatch(careGuideSectionCSVDownloadRequest(order, filter));

    // call API
    const response = await api.getCareGuideSectionsCSV(order, filter);

    // get care guide sections CSV success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API care guide sections CSV success. Order: ${order}`);
      const now = new Date();

      // trigger browser download
      const url = window.URL.createObjectURL(new Blob([response.get('data')]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `cv-care-guide-sections-${Format.date(now, 'YYYY-MM-DDTHHmm')}.csv`);
      document.body.appendChild(link);
      link.click();

      dispatch(careGuideSectionCSVDownloadSuccess());
      
    // get care guide sections CSV failure
    } else {
      Logger.log('info', `Get API care guide sectionss CSV failure.`);
      dispatch(careGuideSectionCSVDownloadFailure());
    }

    // callback function
    cb();
  }
}

Logger.log('silly', `careGuideSections.actions loaded.`);
