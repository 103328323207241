import {connect} from 'react-redux';

import JournalPostAddScreen from '../components/JournalPostAddScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.journalPosts.get('isLoading'),
    success: state.journalPosts.getIn(['form', 'success']),
  }
}

const JournalPostAddScreenContainer = connect(
  mapStateToProps
)(JournalPostAddScreen);

export default JournalPostAddScreenContainer;
