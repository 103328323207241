import {normalize} from 'normalizr';

import api from '../../api';
import {schema} from '../../schema';
import {addEntities, removeEntity} from '../../actions';
import Format from '../../../lib/Format';
import Logger from '../../../lib/Logger';

// ACTION TYPES

export const EMAIL_LIST_REQUEST = 'EMAIL_LIST_REQUEST';
export const EMAIL_LIST_SUCCESS = 'EMAIL_LIST_SUCCESS';
export const EMAIL_LIST_FAILURE = 'EMAIL_LIST_FAILURE';
export const EMAIL_READ_REQUEST = 'EMAIL_READ_REQUEST';
export const EMAIL_READ_SUCCESS = 'EMAIL_READ_SUCCESS';
export const EMAIL_READ_FAILURE = 'EMAIL_READ_FAILURE';
export const EMAIL_UPDATE_REQUEST = 'EMAIL_UPDATE_REQUEST';
export const EMAIL_UPDATE_SUCCESS = 'EMAIL_UPDATE_SUCCESS';
export const EMAIL_UPDATE_FAILURE = 'EMAIL_UPDATE_FAILURE';
export const EMAIL_DELETE_REQUEST = 'EMAIL_DELETE_REQUEST';
export const EMAIL_DELETE_SUCCESS = 'EMAIL_DELETE_SUCCESS';
export const EMAIL_DELETE_FAILURE = 'EMAIL_DELETE_FAILURE';
export const EMAIL_FORM_DESTROY = 'EMAIL_FORM_DESTROY';
export const EMAIL_CSV_DOWNLOAD_REQUEST = 'EMAIL_CSV_DOWNLOAD_REQUEST';
export const EMAIL_CSV_DOWNLOAD_SUCCESS = 'EMAIL_CSV_DOWNLOAD_SUCCESS';
export const EMAIL_CSV_DOWNLOAD_FAILURE = 'EMAIL_CSV_DOWNLOAD_FAILURE';

// ACTION CREATORS

export function emailListRequest(page, limit, order, filter) {
  Logger.log('debug', `[emails.actions] emailListRequest(${page}, ${limit}, ${order}, %j)`, filter);
  return {
    type: EMAIL_LIST_REQUEST,
    page: page,
    limit: limit,
    order: order,
    filter: filter
  }
}

export function emailListSuccess(data) {
  Logger.log('debug', `[emails.actions] emailListSuccess(%j)`, data);
  return {
    type: EMAIL_LIST_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now()
  }
}

export function emailListFailure(error) {
  Logger.log('debug', `[emails.actions] emailListFailure(%j)`, error);
  return {
    type: EMAIL_LIST_FAILURE,
    error: error
  }
}

export function emailReadRequest(id) {
  Logger.log('debug', `[emails.actions] emailReadRequest(${id})`);
  return {
    type: EMAIL_READ_REQUEST,
    id: id
  }
}

export function emailReadSuccess(data) {
  Logger.log('debug', `[emails.actions] emailReadSuccess(%j)`, data);
  return {
    type: EMAIL_READ_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function emailReadFailure(error) {
  Logger.log('debug', `[emails.actions] emailReadFailure(%j)`, error);
  return {
    type: EMAIL_READ_FAILURE,
    error: error
  }
}

export function emailUpdateRequest(id, data) {
  Logger.log('debug', `[emails.actions] emailUpdateRequest(${id}, %j)`, data);
  return {
    type: EMAIL_UPDATE_REQUEST,
  }
}

export function emailUpdateSuccess(data) {
  Logger.log('debug', `[emails.actions] emailUpdateSuccess(%j)`, data);
  return {
    type: EMAIL_UPDATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function emailUpdateFailure(error) {
  Logger.log('debug', `[emails.actions] emailUpdateFailure(%j)`, error);
  return {
    type: EMAIL_UPDATE_FAILURE,
    error: error
  }
}

export function emailDeleteRequest(id) {
  Logger.log('debug', `[emails.actions] emailDeleteRequest(${id})`);
  return {
    type: EMAIL_DELETE_REQUEST,
    id: id
  }
}

export function emailDeleteSuccess(id) {
  Logger.log('debug', `[emails.actions] emailDeleteSuccess(${id})`);
  return {
    type: EMAIL_DELETE_SUCCESS,
    id: id,
  }
}

export function emailDeleteFailure(error) {
  Logger.log('debug', `[emails.actions] emailDeleteFailure(%j)`, error);
  return {
    type: EMAIL_DELETE_FAILURE,
    error: error
  }
}

export function emailFormDestroy(formState=null) {
  Logger.log('debug', `[emails.actions] emailFormDestroy(%j)`, formState);
  return {
    type: EMAIL_FORM_DESTROY,
    form: formState
  }
}

export function emailCSVDownloadRequest(order, filter) {
  Logger.log('debug', `[state.emails.actions] emailCSVDownloadRequest()`);
  return {
    type: EMAIL_CSV_DOWNLOAD_REQUEST,
    order: order,
    filter: filter,
  }
}

export function emailCSVDownloadSuccess() {
  Logger.log('debug', `[state.emails.actions] emailCSVDownloadSuccess()`);
  return {
    type: EMAIL_CSV_DOWNLOAD_SUCCESS,
  }
}

export function emailCSVDownloadFailure() {
  Logger.log('debug', `[state.emails.actions] emailCSVDownloadFailure()`);
  return {
    type: EMAIL_CSV_DOWNLOAD_FAILURE,
  }
}


// API THUNK ACTION CREATORS

export function loadEmails(page=1, limit=10, order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[emails.actions] loadEmails(${page}, ${limit}, ${order}, %j, ###)`, filter);

  return async function(dispatch) {
    dispatch(emailListRequest(page, limit, order, filter));

    // call API
    const response = await api.getEmails(page, limit, order, filter);
    let success = false;

    // get emails list success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API emails list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`);

      const normalizedEntities = normalize(response.getIn(['data', 'emails']), [schema.email]);
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(emailListSuccess(data));
      success = true;

    } else if (1 === page && 204 === response.get('status')) {

      Logger.log('info', `Get API emails success [empty]. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: []
      };
      dispatch(emailListSuccess(data));
      success = true;
      
    // get emails list failure
    } else {
      Logger.log('info', `Get API emails list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      dispatch(emailListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function loadEmail(id, cb=function(){}) {
  Logger.log('debug', `[emails.actions] loadEmail(${id}, ###)`);

  return async function(dispatch) {
    dispatch(emailReadRequest(id));

    // call API
    const response = await api.getEmail(id);
    let success = false;

    // get email success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API email success. ID: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'email'])], [schema.email]);
      const data = {
        id: response.getIn(['data', 'email', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(emailReadSuccess(data));
      success = true;
      
    // get email failure
    } else {
      Logger.log('info', `Get API email failure. ID: ${id}.`);
      dispatch(emailReadFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function updateEmail(id, data, cb=function(){}) {
  Logger.log('debug', `[emails.actions] updateEmail(${id}, %j, ###)`, data);

  return async function(dispatch) {
    dispatch(emailUpdateRequest(id, data));

    // call API
    const response = await api.putEmail(id, data);
    let success = false;

    // put email success
    if (200 === response.get('status')) {

      Logger.log('info', `PUT API email success. User: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'email'])], [schema.email]);
      const data = {
        id: response.getIn(['data', 'email', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(emailUpdateSuccess(data));
      success = true;
      
    // get email failure
    } else {
      Logger.log('info', `PUT API email failure. ID: ${id}.`);
      dispatch(emailUpdateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function deleteEmail(id, cb=function(){}) {
  Logger.log('debug', `[emails.actions] deleteEmail(${id}, ###)`);

  return async function(dispatch) {
    dispatch(emailDeleteRequest(id));

    // call API
    const response = await api.deleteEmail(id);
    let success = false;

    // delete email success
    if (204 === response.get('status')) {

      Logger.log('info', `DELETE API email success. ID: ${id}.`);

      dispatch(removeEntity({entityType: 'emails', id: id}));
      dispatch(emailDeleteSuccess(id));
      success = true;
      
    // get email failure
    } else {
      Logger.log('info', `DELETE API email failure. ID: ${id}.`);
      dispatch(emailDeleteFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function downloadEmailsCSV(order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[state.emails.actions] downloadEmailsCSV(${order}, ${filter}, ###)`);

  return async function(dispatch) {
    dispatch(emailCSVDownloadRequest(order, filter));

    // call API
    const response = await api.getEmailsCSV(order, filter);

    // get emails CSV success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API emails CSV success. Order: ${order}`);
      const now = new Date();

      // trigger browser download
      const url = window.URL.createObjectURL(new Blob([response.get('data')]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `cv-emails-${Format.date(now, 'YYYY-MM-DDTHHmm')}.csv`);
      document.body.appendChild(link);
      link.click();

      dispatch(emailCSVDownloadSuccess());
      
    // get emails CSV failure
    } else {
      Logger.log('info', `Get API emails CSV failure.`);
      dispatch(emailCSVDownloadFailure());
    }

    // callback function
    cb();
  }
}

Logger.log('silly', `emails.actions loaded.`);
