import { connect } from 'react-redux';
import { denormalize } from 'normalizr';

import { schema } from '../../../../../state/schema';
import { loadImportTask } from '../../../../../state/modules/importTasks/actions';
import ImportTaskViewScreen from '../components/ImportTaskViewScreen';

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  const importTask = id
    ? denormalize(
        state.entities.getIn(['importTasks', id]),
        schema.importTask,
        state.entities.toJS()
      )
    : null;
    
  return {
    isLoading: state.importTasks.get('isLoading'),
    importTask: importTask,
    id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (id, cb) => {
      dispatch(loadImportTask(id, cb));
    },
  };
};

const ImportTaskViewScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ImportTaskViewScreen);

export default ImportTaskViewScreenContainer;
