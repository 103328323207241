import { connect } from 'react-redux';

import { loadPlans } from '../../../../../state/modules/plans/actions';
import PlanInput from '../components/PlanInput';

const mapStateToProps = (state) => {
  return {
    total: state.plans.get('total'),
    isLoading: state.plans.get('isLoading'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (page, limit, order, filter, cb) => {
      dispatch(loadPlans(page, limit, order, filter, cb));
    },
  };
};

const PlanInputContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PlanInput);

export default PlanInputContainer;
