import { connect } from 'react-redux';
import { denormalize } from 'normalizr';

import { schema } from '../../../../../state/schema';
import {
  loadJournalPartnerTags,
  deleteJournalPartnerTag,
} from '../../../../../state/modules/journalPartnerTags/actions';
import { sendMessage } from '../../../../../state/actions';
import JournalPartnerTagsList from '../components/JournalPartnerTagsList';

const mapStateToProps = (state, ownProps) => {
  const page = ownProps.page;
  const limit = ownProps.limit;
  const order = ownProps.order;
  const result = state.journalPartnerTags.getIn(['pages', order, limit, page]);

  return {
    list: result
      ? result
          .map((x) => {
            return {
              key: x,
              ...denormalize(
                state.entities.getIn(['journalPartnerTags', x]),
                schema.journalPartnerTag,
                state.entities.toJS()
              ),
            };
          })
          .toArray()
          .map((x) => {
            // flatten some nested objects to make table easier to work with
            return {
              partnership_name: x?.partnership?.name ?? '',
              partnership_plan: x?.partnership?.plan?.name ?? '',
              ...x,
            };
          })
      : [],
    isLoading: state.journalPartnerTags.get('isLoading'),
    total: state.journalPartnerTags.get('total'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (page, limit, order, filter, cb) => {
      dispatch(loadJournalPartnerTags(page, limit, order, filter, cb));
    },
    remove: (id, cb) => {
      dispatch(deleteJournalPartnerTag(id, cb));
    },
    sendMessage: (level, title, body, expires) => {
      dispatch(sendMessage(level, title, body, expires));
    },
  };
};

const JournalPartnerTagsListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(JournalPartnerTagsList);

export default JournalPartnerTagsListContainer;
