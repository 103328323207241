import {Map, List} from 'immutable';

import {
  EMAIL_LIST_REQUEST,
  EMAIL_LIST_SUCCESS,
  EMAIL_LIST_FAILURE,
  EMAIL_READ_REQUEST,
  EMAIL_READ_SUCCESS,
  EMAIL_READ_FAILURE,
  EMAIL_UPDATE_REQUEST,
  EMAIL_UPDATE_SUCCESS,
  EMAIL_UPDATE_FAILURE,
  EMAIL_DELETE_REQUEST,
  EMAIL_DELETE_SUCCESS,
  EMAIL_DELETE_FAILURE,
  EMAIL_FORM_DESTROY,
  EMAIL_CSV_DOWNLOAD_REQUEST,
  EMAIL_CSV_DOWNLOAD_SUCCESS,
  EMAIL_CSV_DOWNLOAD_FAILURE
} from './actions'
import Logger from '../../../lib/Logger';

export default function emails(
  state=Map({
    isLoading: false,
    isCSVDownloading: false,
    pages: {}
  }),
  action
) {
  Logger.log('debug', `[emails.reducers] emails(%j, %j)`, state, action);

  switch(action.type) {

    case EMAIL_LIST_REQUEST:
      return state.mergeDeep({
        isLoading: true
      });

    case EMAIL_LIST_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        pages: {
          [action.order]: {
            [action.limit]: {
              [action.page]: null
            }
          }
        },
        total: action.total,
        lastUpdated: action.receivedAt
      }).setIn(['pages', action.order, action.limit, action.page], List(action.result));

    case EMAIL_LIST_FAILURE:
      return state.mergeDeep({
        isLoading: false
      });

    case EMAIL_READ_REQUEST:
      return state.mergeDeep({
        isLoading: true
      });

    case EMAIL_READ_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        lastUpdated: action.receivedAt
      });

    case EMAIL_READ_FAILURE:
      return state.mergeDeep({
        isLoading: false
      });

    case EMAIL_UPDATE_REQUEST:
      return state.mergeDeep({
        isLoading: true,
        form: {
          isSubmitting: true,
          success: null,
          errors: null
        }
      });

    case EMAIL_UPDATE_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        form: {
          isSubmitting: false,
          success: true,
          errors: null
        },
        lastUpdated: action.receivedAt
      });

    case EMAIL_UPDATE_FAILURE:
      return state.mergeDeep({
        isLoading: false,
        form: {
          isSubmitting: false,
          success: false,
          errors: null
        }
      }).setIn(['form', 'errors'], action.error);

    case EMAIL_DELETE_REQUEST:
      return state.mergeDeep({
        isLoading: true
      });

    case EMAIL_DELETE_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        lastUpdated: action.receivedAt
      });

    case EMAIL_DELETE_FAILURE:
      return state.mergeDeep({
        isLoading: false
      });

    case EMAIL_FORM_DESTROY:
      return state.mergeDeep({
        form: null
      }).set('form', action.form);

    case EMAIL_CSV_DOWNLOAD_REQUEST:
      return state.mergeDeep({
        isCSVDownloading: true
      });

    case EMAIL_CSV_DOWNLOAD_SUCCESS:
      return state.mergeDeep({
        isCSVDownloading: false,
      });

    case EMAIL_CSV_DOWNLOAD_FAILURE:
      return state.mergeDeep({
        isCSVDownloading: false,
      });

    default:
      return state;
  }
}

Logger.log('silly', `emails.reducers loaded.`);
