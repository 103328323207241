import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { Card, Col, Row, Space, Spin, Button } from 'antd';
import { LoadingOutlined, PlusCircleOutlined } from '@ant-design/icons';

import { pathTo } from '../../../Routes';
import DashboardSectionsList from '../containers/DashboardSectionsListContainer';
import DocumentHead from '../../../elements/components/DocumentHead';
import Config from '../../../../../Config';
import QueryString from '../../../../../lib/QueryString';
import Logger from '../../../../../lib/Logger';

class DashboardSectionsScreen extends Component {
  static defaultProps = {
    limit: Config.get('DEFAULT_LIST_LENGTH', 10),
    isLoading: true,
  };

  scrollToTop = () => {
    Logger.log('debug', `DashboardSectionsScreen.scrollToTop()`);
    if (typeof window !== 'undefined') {
      setTimeout(() => window.scrollTo(0, 0), 100);
    }
  };

  render() {
    const loadingIcon = (
      <Spin indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} />
    );
    const page = parseInt(
      this.props.match.params.page ? this.props.match.params.page : 1
    );
    const { order, ...filter } = QueryString.parse(this.props.location.search);

    return (
      <Translation>
        {(t) => (
          <div>
            <DocumentHead title={t('dashboard_sections_route_list')} />

            <Row>
              <Col span={24}>
                <Card
                  title={
                    <Space size={10}>
                      {t('dashboard_sections_list_title')}
                      <Button
                        size="small"
                        icon={<PlusCircleOutlined />}
                        onClick={(e) =>
                          this.props.history.push(
                            pathTo('DashboardSectionAddScreen')
                          )
                        }
                      >
                        {t('action_create')}
                      </Button>
                    </Space>
                  }
                  extra={this.props.isLoading ? loadingIcon : null}
                >
                  <DashboardSectionsList
                    key={page}
                    component="DashboardSectionsScreen"
                    page={page}
                    limit={this.props.limit}
                    order={order || 'order.asc'}
                    filter={filter}
                  />
                </Card>
              </Col>
            </Row>
          </div>
        )}
      </Translation>
    );
  }

  /* LIFECYCLE EVENTS */

  componentDidMount() {
    this.scrollToTop();
  }
}

export default DashboardSectionsScreen;

Logger.log('silly', `DashboardSectionsScreen loaded.`);
