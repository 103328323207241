import {connect} from 'react-redux';

import CareGuideInvitationAddScreen from '../components/CareGuideInvitationAddScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.careGuideInvitations.get('isLoading'),
    success: state.careGuideInvitations.getIn(['form', 'success']),
  }
}

const CareGuideInvitationAddScreenContainer = connect(
  mapStateToProps
)(CareGuideInvitationAddScreen);

export default CareGuideInvitationAddScreenContainer;
