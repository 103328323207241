import { normalize } from 'normalizr';

import api from '../../api';
import { schema } from '../../schema';
import { addEntities, removeEntity } from '../../actions';
import Format from '../../../lib/Format';
import Logger from '../../../lib/Logger';

// ACTION TYPES

export const PARTNERSHIP_LIST_REQUEST = 'PARTNERSHIP_LIST_REQUEST';
export const PARTNERSHIP_LIST_SUCCESS = 'PARTNERSHIP_LIST_SUCCESS';
export const PARTNERSHIP_LIST_FAILURE = 'PARTNERSHIP_LIST_FAILURE';
export const PARTNERSHIP_CREATE_REQUEST = 'PARTNERSHIP_CREATE_REQUEST';
export const PARTNERSHIP_CREATE_SUCCESS = 'PARTNERSHIP_CREATE_SUCCESS';
export const PARTNERSHIP_CREATE_FAILURE = 'PARTNERSHIP_CREATE_FAILURE';
export const PARTNERSHIP_READ_REQUEST = 'PARTNERSHIP_READ_REQUEST';
export const PARTNERSHIP_READ_SUCCESS = 'PARTNERSHIP_READ_SUCCESS';
export const PARTNERSHIP_READ_FAILURE = 'PARTNERSHIP_READ_FAILURE';
export const PARTNERSHIP_UPDATE_REQUEST = 'PARTNERSHIP_UPDATE_REQUEST';
export const PARTNERSHIP_UPDATE_SUCCESS = 'PARTNERSHIP_UPDATE_SUCCESS';
export const PARTNERSHIP_UPDATE_FAILURE = 'PARTNERSHIP_UPDATE_FAILURE';
export const PARTNERSHIP_DELETE_REQUEST = 'PARTNERSHIP_DELETE_REQUEST';
export const PARTNERSHIP_DELETE_SUCCESS = 'PARTNERSHIP_DELETE_SUCCESS';
export const PARTNERSHIP_DELETE_FAILURE = 'PARTNERSHIP_DELETE_FAILURE';
export const PARTNERSHIP_FORM_DESTROY = 'PARTNERSHIP_FORM_DESTROY';
export const PARTNERSHIP_CSV_DOWNLOAD_REQUEST =
  'PARTNERSHIP_CSV_DOWNLOAD_REQUEST';
export const PARTNERSHIP_CSV_DOWNLOAD_SUCCESS =
  'PARTNERSHIP_CSV_DOWNLOAD_SUCCESS';
export const PARTNERSHIP_CSV_DOWNLOAD_FAILURE =
  'PARTNERSHIP_CSV_DOWNLOAD_FAILURE';

// ACTION CREATORS

export function partnershipListRequest(page, limit, order, filter) {
  Logger.log(
    'debug',
    `[partnerships.actions] partnershipListRequest(${page}, ${limit}, ${order}, %j)`,
    filter
  );
  return {
    type: PARTNERSHIP_LIST_REQUEST,
    page: page,
    limit: limit,
    order: order,
    filter: filter,
  };
}

export function partnershipListSuccess(data) {
  Logger.log(
    'debug',
    `[partnerships.actions] partnershipListSuccess(%j)`,
    data
  );
  return {
    type: PARTNERSHIP_LIST_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now(),
  };
}

export function partnershipListFailure(error) {
  Logger.log(
    'debug',
    `[partnerships.actions] partnershipListFailure(%j)`,
    error
  );
  return {
    type: PARTNERSHIP_LIST_FAILURE,
    error: error,
  };
}

export function partnershipReadRequest(id) {
  Logger.log('debug', `[partnerships.actions] partnershipReadRequest(${id})`);
  return {
    type: PARTNERSHIP_READ_REQUEST,
    id: id,
  };
}

export function partnershipReadSuccess(data) {
  Logger.log(
    'debug',
    `[partnerships.actions] partnershipReadSuccess(%j)`,
    data
  );
  return {
    type: PARTNERSHIP_READ_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function partnershipReadFailure(error) {
  Logger.log(
    'debug',
    `[partnerships.actions] partnershipReadFailure(%j)`,
    error
  );
  return {
    type: PARTNERSHIP_READ_FAILURE,
    error: error,
  };
}

export function partnershipUpdateRequest(id, data) {
  Logger.log(
    'debug',
    `[partnerships.actions] partnershipUpdateRequest(${id}, %j)`,
    data
  );
  return {
    type: PARTNERSHIP_UPDATE_REQUEST,
  };
}

export function partnershipUpdateSuccess(data) {
  Logger.log(
    'debug',
    `[partnerships.actions] partnershipUpdateSuccess(%j)`,
    data
  );
  return {
    type: PARTNERSHIP_UPDATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function partnershipUpdateFailure(error) {
  Logger.log(
    'debug',
    `[partnerships.actions] partnershipUpdateFailure(%j)`,
    error
  );
  return {
    type: PARTNERSHIP_UPDATE_FAILURE,
    error: error,
  };
}

export function partnershipDeleteRequest(id) {
  Logger.log('debug', `[partnerships.actions] partnershipDeleteRequest(${id})`);
  return {
    type: PARTNERSHIP_DELETE_REQUEST,
    id: id,
  };
}

export function partnershipDeleteSuccess(id) {
  Logger.log('debug', `[partnerships.actions] partnershipDeleteSuccess(${id})`);
  return {
    type: PARTNERSHIP_DELETE_SUCCESS,
    id: id,
  };
}

export function partnershipDeleteFailure(error) {
  Logger.log(
    'debug',
    `[partnerships.actions] partnershipDeleteFailure(%j)`,
    error
  );
  return {
    type: PARTNERSHIP_DELETE_FAILURE,
    error: error,
  };
}

export function partnershipFormDestroy(formState = null) {
  Logger.log(
    'debug',
    `[partnerships.actions] partnershipFormDestroy(%j)`,
    formState
  );
  return {
    type: PARTNERSHIP_FORM_DESTROY,
    form: formState,
  };
}

export function partnershipCSVDownloadRequest(order, filter) {
  Logger.log(
    'debug',
    `[state.partnerships.actions] partnershipCSVDownloadRequest()`
  );
  return {
    type: PARTNERSHIP_CSV_DOWNLOAD_REQUEST,
    order: order,
    filter: filter,
  };
}

export function partnershipCSVDownloadSuccess() {
  Logger.log(
    'debug',
    `[state.partnerships.actions] partnershipCSVDownloadSuccess()`
  );
  return {
    type: PARTNERSHIP_CSV_DOWNLOAD_SUCCESS,
  };
}

export function partnershipCSVDownloadFailure() {
  Logger.log(
    'debug',
    `[state.partnerships.actions] partnershipCSVDownloadFailure()`
  );
  return {
    type: PARTNERSHIP_CSV_DOWNLOAD_FAILURE,
  };
}

export function partnershipCreateRequest(data) {
  Logger.log(
    'debug',
    `[partnerships.actions] partnershipCreateRequest(%j)`,
    data
  );
  return {
    type: PARTNERSHIP_CREATE_REQUEST,
  };
}

export function partnershipCreateSuccess(data) {
  Logger.log(
    'debug',
    `[partnerships.actions] partnershipCreateSuccess(%j)`,
    data
  );
  return {
    type: PARTNERSHIP_CREATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function partnershipCreateFailure(error) {
  Logger.log(
    'debug',
    `[partnerships.actions] partnershipCreateFailure(%j)`,
    error
  );
  return {
    type: PARTNERSHIP_CREATE_FAILURE,
    error: error,
  };
}

// API THUNK ACTION CREATORS

export function loadPartnerships(
  page = 1,
  limit = 10,
  order = null,
  filter = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[partnership.actions] loadPartnerships(${page}, ${limit}, ${order}, %j, ###)`,
    filter
  );

  return async function (dispatch) {
    dispatch(partnershipListRequest(page, limit, order, filter));

    // call API
    const response = await api.getPartnerships(page, limit, order, filter);
    let success = false;
    let result = [];
    // get partnerships list success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API partnerships list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );

      const normalizedEntities = normalize(
        response.getIn(['data', 'partnerships']),
        [schema.partnership]
      );
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result,
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(partnershipListSuccess(data));
      success = true;
      result = normalizedEntities.result;
    } else if (1 === page && 204 === response.get('status')) {
      Logger.log(
        'info',
        `Get API partnerships success [empty]. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: [],
      };
      dispatch(partnershipListSuccess(data));
      success = true;

      // get partnerships list failure
    } else {
      Logger.log(
        'info',
        `Get API partnerships list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      dispatch(partnershipListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success, result);
  };
}

export function loadPartnership(id, cb = function () {}) {
  Logger.log('debug', `[partnership.actions] loadPartnership(${id}, ###)`);

  return async function (dispatch) {
    dispatch(partnershipReadRequest(id));

    // call API
    const response = await api.getPartnership(id);
    let success = false;

    // get partnership success
    if (200 === response.get('status')) {
      Logger.log('info', `Get API partnership success. ID: ${id}.`);

      const normalizedEntities = normalize(
        [response.getIn(['data', 'partnership'])],
        [schema.partnership]
      );
      const data = {
        id: response.getIn(['data', 'partnership', 'id']),
      };
      dispatch(addEntities(normalizedEntities));
      dispatch(partnershipReadSuccess(data));
      success = true;

      // get partnership failure
    } else {
      Logger.log('info', `Get API partnership failure. ID: ${id}.`);
      dispatch(partnershipReadFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function createPartnership(data, cb = function () {}) {
  Logger.log(
    'debug',
    `[partnerships.actions] createPartnership(%j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(partnershipCreateRequest(data));

    // call API
    const response = await api.postPartnership(data);
    let success = false;

    // post partnership success
    if (201 === response.get('status')) {
      Logger.log(
        'info',
        `POST API partnership success. Post: ${response.getIn([
          'data',
          'partnership',
          'id',
        ])}.`
      );

      const normalizedEntities = normalize(
        [response.getIn(['data', 'partnership'])],
        [schema.partnership]
      );
      const data = {
        id: response.getIn(['data', 'partnership', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(partnershipCreateSuccess(data));
      success = true;

      // get partnership failure
    } else {
      Logger.log('info', `POST API partnership failure.`);
      dispatch(partnershipCreateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function updatePartnership(id, data, cb = function () {}) {
  Logger.log(
    'debug',
    `[partnerships.actions] updatePartnership(${id}, %j, ###)`,
    data
  );
  return async function (dispatch) {
    dispatch(partnershipUpdateRequest(id, data));

    // call API
    const response = await api.putPartnership(id, data);
    let success = false;

    // put partnership success
    if (200 === response.get('status')) {
      Logger.log('info', `PUT API partnership success. User: ${id}.`);

      const normalizedEntities = normalize(
        [response.getIn(['data', 'partnership'])],
        [schema.partnership]
      );
      const data = {
        id: response.getIn(['data', 'partnership', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(partnershipUpdateSuccess(data));
      success = true;

      // get partnership failure
    } else {
      Logger.log('info', `PUT API partnership failure. ID: ${id}.`);
      dispatch(partnershipUpdateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function deletePartnership(id, cb = function () {}) {
  Logger.log('debug', `[partnerships.actions] deletePartnership(${id}, ###)`);

  return async function (dispatch) {
    dispatch(partnershipDeleteRequest(id));

    // call API
    const response = await api.deletePartnership(id);
    let success = false;

    // delete partnership success
    if (204 === response.get('status')) {
      Logger.log('info', `DELETE API partnership success. ID: ${id}.`);

      dispatch(removeEntity({ entityType: 'partnerships', id: id }));
      dispatch(partnershipDeleteSuccess(id));
      success = true;

      // get partnership failure
    } else {
      Logger.log('info', `DELETE API partnership failure. ID: ${id}.`);
      dispatch(partnershipDeleteFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function downloadPartnershipsCSV(
  order = null,
  filter = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.partnerships.actions] downloadPartnershipsCSV(${order}, ${filter}, ###)`
  );

  return async function (dispatch) {
    dispatch(partnershipCSVDownloadRequest(order, filter));

    // call API
    const response = await api.getPartnershipsCSV(order, filter);

    // get partnership CSV success
    if (200 === response.get('status')) {
      Logger.log('info', `Get API partnership CSV success. Order: ${order}`);
      const now = new Date();

      // trigger browser download
      const url = window.URL.createObjectURL(new Blob([response.get('data')]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `cv-partnerships-${Format.date(now, 'YYYY-MM-DDTHHmm')}.csv`
      );
      document.body.appendChild(link);
      link.click();

      dispatch(partnershipCSVDownloadSuccess());

      // get partnership CSV failure
    } else {
      Logger.log('info', `Get API partnership CSV failure.`);
      dispatch(partnershipCSVDownloadFailure());
    }

    // callback function
    cb();
  };
}

Logger.log('silly', `partnerships.actions loaded.`);
