import {getI18n} from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import CalendarEventsScreen from './containers/CalendarEventsScreenContainer';
import CalendarEventAddScreen from './containers/CalendarEventAddScreenContainer';
import CalendarEventEditScreen from './containers/CalendarEventEditScreenContainer';

import CalendarEventExceptionsScreen from './containers/CalendarEventExceptionsScreenContainer';
import CalendarEventExceptionAddScreen from './containers/CalendarEventExceptionAddScreenContainer';
import CalendarEventExceptionEditScreen from './containers/CalendarEventExceptionEditScreenContainer';

const register = () => {
  Logger.log('debug', `calendarEvents.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'calendarEvents', 'routes'])) {
    Events.dispatch('ADD_MAIN_ROUTES', 
      {
        'CalendarEventAddScreen': ['PrivateRoute', '/calendar-events/add', true, CalendarEventAddScreen, getI18n().t('calendar_event_route_add')],
        'CalendarEventEditScreen': ['PrivateRoute', '/calendar-events/edit/:id([\\w\\-]+)', true, CalendarEventEditScreen, getI18n().t('calendar_event_route_edit')],
        'CalendarEventsScreen': ['PrivateRoute', '/calendar-events/:page(\\d+)?', true, CalendarEventsScreen, getI18n().t('calendar_events_route_list')],
        'CalendarEventExceptionAddScreen': ['PrivateRoute', '/calendar-event-exceptions/add', true, CalendarEventExceptionAddScreen, getI18n().t('calendar_event_exception_route_add')],
        'CalendarEventExceptionEditScreen': ['PrivateRoute', '/calendar-event-exceptions/edit/:id([\\w\\-]+)', true, CalendarEventExceptionEditScreen, getI18n().t('calendar_event_exception_route_edit')],
        'CalendarEventExceptionsScreen': ['PrivateRoute', '/calendar-event-exceptions/:page(\\d+)?', true, CalendarEventExceptionsScreen, getI18n().t('calendar_event_exceptions_route_list')],
      }
    );
  }
}

export default register;

Logger.log('silly', `calendarEvents.register() loaded.`);
