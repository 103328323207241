import { connect } from 'react-redux';

import PasswordResetsScreen from '../components/PasswordResetsScreen';

const mapStateToProps = (state) => {
  return {
    total: state.passwordResets.get('total'),
    isLoading: state.passwordResets.get('isLoading'),
  };
};

const PasswordResetsScreenContainer =
  connect(mapStateToProps)(PasswordResetsScreen);

export default PasswordResetsScreenContainer;
