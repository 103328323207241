import { connect } from 'react-redux';
import { denormalize } from 'normalizr';

import { schema } from '../../../../../state/schema';
import { loadPasswordResets } from '../../../../../state/modules/passwordResets/actions';
import PasswordResetsList from '../components/PasswordResetsList';

const mapStateToProps = (state, ownProps) => {
  const page = ownProps.page;
  const limit = ownProps.limit;
  const order = ownProps.order;
  const result = state.passwordResets.getIn(['pages', order, limit, page]);

  return {
    list: result
      ? result
          .map((x) => {
            return {
              key: x,
              ...denormalize(
                state.entities.getIn(['passwordResets', x]),
                schema.passwordReset,
                state.entities.toJS()
              ),
            };
          })
          .toArray()
          .map((x) => {
            // flatten some nested objects to make table easier to work with
            return {
              user_id: x.user ? x.user.id : '',
              username: x.user ? x.user.username : '',
              ...x,
            };
          })
      : [],
    isLoading: state.passwordResets.get('isLoading'),
    total: state.passwordResets.get('total'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (page, limit, order, filter, cb) => {
      dispatch(loadPasswordResets(page, limit, order, filter, cb));
    },
  };
};

const PasswordResetsListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordResetsList);

export default PasswordResetsListContainer;
