import {connect} from 'react-redux';
import {List} from 'immutable';
import moment from 'moment';

import {
  loadCountry,
  updateCountry,
  createCountry,
  deleteCountry,
  countryFormDestroy
} from '../../../../../../state/modules/locationCountries/actions';
import {sendMessage} from '../../../../../../state/actions';
import CountryForm from '../components/CountryForm';
import Config from '../../../../../../Config';

const inputs = List([
  'name',
  'code_2',
  'code_3',
  'status'
]);

const mapStateToProps = (state, ownProps) => {

  const data = {}, errors = {};
  for (const val of inputs.values()) {
    data[val] = state.entities.getIn(['countries', ownProps.id, val], '');
    // if (['profile'].includes(val)) {
    //   errors[val] = state.Country.getIn(['form', 'errors', 'user_profile_id']);
    // }
    // else if (['care_guide'].includes(val)) {
    //   errors[val] = state.Country.getIn(['form', 'errors', val + '_id']);
    // }
    // else {
    errors[val] = state.locationCountries.getIn(['form', 'errors', val]);
    // }
  }

  // change select number values to strings to work with ant design select inputs
  Object.keys(data).forEach(x => {
    if (['status', 'rating'].includes(x)) {
      data[x] = data[x].toString();
    }
  });

  data['published_at'] = data['published_at']
    ? moment(data['published_at'], Config.get('API_DATETIME_FORMAT'))
    : moment();

  data['edited_at'] = data['edited_at']
    ? moment(data['edited_at'], Config.get('API_DATETIME_FORMAT'))
    : null;

  if (data['tags'] === '') {
    data['tags'] = [];
  }

  if (data['media'] === '') {
    data['media'] = [];
  }

  return {
    isLoading: state.locationCountries.get('areCountriesLoading'),
    isSubmitting: state.locationCountries.getIn(['form', 'isSubmitting']),
    success: state.locationCountries.getIn(['form', 'success']),
    created_id: state.locationCountries.getIn(['form', 'created_id']),
    data: data,
    errors: errors,
    createdAt: state.entities.getIn(['countries', ownProps.id, 'created_at'], null),
    updatedAt: state.entities.getIn(['countries', ownProps.id, 'updated_at'], null),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (id, cb) => {
      dispatch(loadCountry(id, cb));
    },
    update: (id, data, cb) => {
      dispatch(updateCountry(id, data, cb));
    },
    create: (data, cb) => {
      dispatch(createCountry(data, cb));
    },
    delete: (id, cb) => {
      dispatch(deleteCountry(id, cb))
    },
    destroyForm: (formState) => {
      dispatch(countryFormDestroy(formState));
    },
    sendMessage: (level, title, body, expires) => {
      dispatch(sendMessage(level, title, body, expires));
    },
  }
}

const CountryFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CountryForm);

export default CountryFormContainer;
