import { normalize } from 'normalizr';

import api from '../../api';
import { schema } from '../../schema';
import { addEntities } from '../../actions';
import Logger from '../../../lib/Logger';

// ACTION TYPES

export const IMPORT_TASK_LIST_REQUEST = 'IMPORT_TASK_LIST_REQUEST';
export const IMPORT_TASK_LIST_SUCCESS = 'IMPORT_TASK_LIST_SUCCESS';
export const IMPORT_TASK_LIST_FAILURE = 'IMPORT_TASK_LIST_FAILURE';
export const IMPORT_TASK_READ_REQUEST = 'IMPORT_TASK_READ_REQUEST';
export const IMPORT_TASK_READ_SUCCESS = 'IMPORT_TASK_READ_SUCCESS';
export const IMPORT_TASK_READ_FAILURE = 'IMPORT_TASK_READ_FAILURE';

// ACTION CREATORS

export function importTaskListRequest(page, limit, order, filter) {
  Logger.log(
    'debug',
    `[importTasks.actions] importTaskListRequest(${page}, ${limit}, ${order}, %j)`,
    filter
  );
  return {
    type: IMPORT_TASK_LIST_REQUEST,
    page: page,
    limit: limit,
    order: order,
    filter: filter,
  };
}

export function importTaskListSuccess(data) {
  Logger.log('debug', `[importTasks.actions] importTaskListSuccess(%j)`, data);
  return {
    type: IMPORT_TASK_LIST_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now(),
  };
}

export function importTaskListFailure(error) {
  Logger.log('debug', `[importTasks.actions] importTaskListFailure(%j)`, error);
  return {
    type: IMPORT_TASK_LIST_FAILURE,
    error: error,
  };
}

export function importTaskReadRequest(id) {
  Logger.log('debug', `[importTasks.actions] importTaskReadRequest(${id})`);
  return {
    type: IMPORT_TASK_READ_REQUEST,
    id: id,
  };
}

export function importTaskReadSuccess(data) {
  Logger.log('debug', `[importTasks.actions] importTaskReadSuccess(%j)`, data);
  return {
    type: IMPORT_TASK_READ_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function importTaskReadFailure(error) {
  Logger.log('debug', `[importTasks.actions] importTaskReadFailure(%j)`, error);
  return {
    type: IMPORT_TASK_READ_FAILURE,
    error: error,
  };
}

// API THUNK ACTION CREATORS

export function loadImportTasks(
  page = 1,
  limit = 10,
  order = null,
  filter = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[importTasks.actions] loadImportTasks(${page}, ${limit}, ${order}, %j, ###)`,
    filter
  );

  return async function (dispatch) {
    dispatch(importTaskListRequest(page, limit, order, filter));

    // call API
    const response = await api.getImportTasks(page, limit, order, filter);
    let success = false;

    // get import tasks list success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API import tasks list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );

      const normalizedEntities = normalize(
        response.getIn(['data', 'import_tasks']),
        [schema.importTask]
      );
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result,
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(importTaskListSuccess(data));
      success = true;
    } else if (1 === page && 204 === response.get('status')) {
      Logger.log(
        'info',
        `Get API import tasks success [empty]. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: [],
      };
      dispatch(importTaskListSuccess(data));
      success = true;

      // get import tasks list failure
    } else {
      Logger.log(
        'info',
        `Get API import tasks list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      dispatch(importTaskListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function loadImportTask(id, cb = function () {}) {
  Logger.log('debug', `[importTasks.actions] loadImportTask(${id}, ###)`);

  return async function (dispatch) {
    dispatch(importTaskReadRequest(id));

    // call API
    const response = await api.getImportTask(id);
    let success = false;

    // get import task success
    if (200 === response.get('status')) {
      Logger.log('info', `Get API import task success. ID: ${id}.`);

      const normalizedEntities = normalize(
        [response.getIn(['data', 'import_task'])],
        [schema.importTask]
      );
      const data = {
        id: response.getIn(['data', 'import_task', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(importTaskReadSuccess(data));
      success = true;

      // get import task failure
    } else {
      Logger.log('info', `Get API import task failure. ID: ${id}.`);
      dispatch(importTaskReadFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

Logger.log('silly', `importTasks.actions loaded.`);
