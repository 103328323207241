import {normalize} from 'normalizr';

import api from '../../api';
import {schema} from '../../schema';
import {addEntities, removeEntity} from '../../actions';
import Format from '../../../lib/Format';
import Logger from '../../../lib/Logger';

// ACTION TYPES

export const CARE_GUIDE_TEMPLATE_LIST_REQUEST = 'CARE_GUIDE_TEMPLATE_LIST_REQUEST';
export const CARE_GUIDE_TEMPLATE_LIST_SUCCESS = 'CARE_GUIDE_TEMPLATE_LIST_SUCCESS';
export const CARE_GUIDE_TEMPLATE_LIST_FAILURE = 'CARE_GUIDE_TEMPLATE_LIST_FAILURE';
export const CARE_GUIDE_TEMPLATE_READ_REQUEST = 'CARE_GUIDE_TEMPLATE_READ_REQUEST';
export const CARE_GUIDE_TEMPLATE_READ_SUCCESS = 'CARE_GUIDE_TEMPLATE_READ_SUCCESS';
export const CARE_GUIDE_TEMPLATE_READ_FAILURE = 'CARE_GUIDE_TEMPLATE_READ_FAILURE';
export const CARE_GUIDE_TEMPLATE_UPDATE_REQUEST = 'CARE_GUIDE_TEMPLATE_UPDATE_REQUEST';
export const CARE_GUIDE_TEMPLATE_UPDATE_SUCCESS = 'CARE_GUIDE_TEMPLATE_UPDATE_SUCCESS';
export const CARE_GUIDE_TEMPLATE_UPDATE_FAILURE = 'CARE_GUIDE_TEMPLATE_UPDATE_FAILURE';
export const CARE_GUIDE_TEMPLATE_CREATE_REQUEST = 'CARE_GUIDE_TEMPLATE_CREATE_REQUEST';
export const CARE_GUIDE_TEMPLATE_CREATE_SUCCESS = 'CARE_GUIDE_TEMPLATE_CREATE_SUCCESS';
export const CARE_GUIDE_TEMPLATE_CREATE_FAILURE = 'CARE_GUIDE_TEMPLATE_CREATE_FAILURE';
export const CARE_GUIDE_TEMPLATE_DELETE_REQUEST = 'CARE_GUIDE_TEMPLATE_DELETE_REQUEST';
export const CARE_GUIDE_TEMPLATE_DELETE_SUCCESS = 'CARE_GUIDE_TEMPLATE_DELETE_SUCCESS';
export const CARE_GUIDE_TEMPLATE_DELETE_FAILURE = 'CARE_GUIDE_TEMPLATE_DELETE_FAILURE';
export const CARE_GUIDE_TEMPLATE_FORM_DESTROY = 'CARE_GUIDE_TEMPLATE_FORM_DESTROY';
export const CARE_GUIDE_TEMPLATE_CSV_DOWNLOAD_REQUEST = 'CARE_GUIDE_TEMPLATE_CSV_DOWNLOAD_REQUEST';
export const CARE_GUIDE_TEMPLATE_CSV_DOWNLOAD_SUCCESS = 'CARE_GUIDE_TEMPLATE_CSV_DOWNLOAD_SUCCESS';
export const CARE_GUIDE_TEMPLATE_CSV_DOWNLOAD_FAILURE = 'CARE_GUIDE_TEMPLATE_CSV_DOWNLOAD_FAILURE';

// ACTION CREATORS

export function careGuideTemplateListRequest(page, limit, order, filter) {
  Logger.log('debug', `[careGuideTemplates.actions] careGuideTemplateListRequest(${page}, ${limit}, ${order}, %j)`, filter);
  return {
    type: CARE_GUIDE_TEMPLATE_LIST_REQUEST,
    page: page,
    limit: limit,
    order: order,
    filter: filter
  }
}

export function careGuideTemplateListSuccess(data) {
  Logger.log('debug', `[careGuideTemplates.actions] careGuideTemplateListSuccess(%j)`, data);
  return {
    type: CARE_GUIDE_TEMPLATE_LIST_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now()
  }
}

export function careGuideTemplateListFailure(error) {
  Logger.log('debug', `[careGuideTemplates.actions] careGuideTemplateListFailure(%j)`, error);
  return {
    type: CARE_GUIDE_TEMPLATE_LIST_FAILURE,
    error: error
  }
}

export function careGuideTemplateReadRequest(id) {
  Logger.log('debug', `[careGuideTemplates.actions] careGuideTemplateReadRequest(${id})`);
  return {
    type: CARE_GUIDE_TEMPLATE_READ_REQUEST,
    id: id
  }
}

export function careGuideTemplateReadSuccess(data) {
  Logger.log('debug', `[careGuideTemplates.actions] careGuideTemplateReadSuccess(%j)`, data);
  return {
    type: CARE_GUIDE_TEMPLATE_READ_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function careGuideTemplateReadFailure(error) {
  Logger.log('debug', `[careGuideTemplates.actions] careGuideTemplateReadFailure(%j)`, error);
  return {
    type: CARE_GUIDE_TEMPLATE_READ_FAILURE,
    error: error
  }
}

export function careGuideTemplateUpdateRequest(id, data) {
  Logger.log('debug', `[careGuideTemplates.actions] careGuideTemplateUpdateRequest(${id}, %j)`, data);
  return {
    type: CARE_GUIDE_TEMPLATE_UPDATE_REQUEST,
  }
}

export function careGuideTemplateUpdateSuccess(data) {
  Logger.log('debug', `[careGuideTemplates.actions] careGuideTemplateUpdateSuccess(%j)`, data);
  return {
    type: CARE_GUIDE_TEMPLATE_UPDATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function careGuideTemplateUpdateFailure(error) {
  Logger.log('debug', `[careGuideTemplates.actions] careGuideTemplateUpdateFailure(%j)`, error);
  return {
    type: CARE_GUIDE_TEMPLATE_UPDATE_FAILURE,
    error: error
  }
}

export function careGuideTemplateCreateRequest(data) {
  Logger.log('debug', `[careGuideTemplates.actions] careGuideTemplateCreateRequest(%j)`, data);
  return {
    type: CARE_GUIDE_TEMPLATE_CREATE_REQUEST,
  }
}

export function careGuideTemplateCreateSuccess(data) {
  Logger.log('debug', `[careGuideTemplates.actions] careGuideTemplateCreateSuccess(%j)`, data);
  return {
    type: CARE_GUIDE_TEMPLATE_CREATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function careGuideTemplateCreateFailure(error) {
  Logger.log('debug', `[careGuideTemplates.actions] careGuideTemplateCreateFailure(%j)`, error);
  return {
    type: CARE_GUIDE_TEMPLATE_CREATE_FAILURE,
    error: error
  }
}

export function careGuideTemplateDeleteRequest(id) {
  Logger.log('debug', `[careGuideTemplates.actions] careGuideTemplateDeleteRequest(${id})`);
  return {
    type: CARE_GUIDE_TEMPLATE_DELETE_REQUEST,
    id: id
  }
}

export function careGuideTemplateDeleteSuccess(id) {
  Logger.log('debug', `[careGuideTemplates.actions] careGuideTemplateDeleteSuccess(${id})`);
  return {
    type: CARE_GUIDE_TEMPLATE_DELETE_SUCCESS,
    id: id,
  }
}

export function careGuideTemplateDeleteFailure(error) {
  Logger.log('debug', `[careGuideTemplates.actions] careGuideTemplateDeleteFailure(%j)`, error);
  return {
    type: CARE_GUIDE_TEMPLATE_DELETE_FAILURE,
    error: error
  }
}

export function careGuideTemplateFormDestroy(formState=null) {
  Logger.log('debug', `[careGuideTemplates.actions] careGuideTemplateFormDestroy(%j)`, formState);
  return {
    type: CARE_GUIDE_TEMPLATE_FORM_DESTROY,
    form: formState
  }
}

export function careGuideTemplateCSVDownloadRequest(order, filter) {
  Logger.log('debug', `[state.careGuideTemplates.actions] careGuideTemplateCSVDownloadRequest()`);
  return {
    type: CARE_GUIDE_TEMPLATE_CSV_DOWNLOAD_REQUEST,
    order: order,
    filter: filter,
  }
}

export function careGuideTemplateCSVDownloadSuccess() {
  Logger.log('debug', `[state.careGuideTemplates.actions] careGuideTemplateCSVDownloadSuccess()`);
  return {
    type: CARE_GUIDE_TEMPLATE_CSV_DOWNLOAD_SUCCESS,
  }
}

export function careGuideTemplateCSVDownloadFailure() {
  Logger.log('debug', `[state.careGuideTemplates.actions] careGuideTemplateCSVDownloadFailure()`);
  return {
    type: CARE_GUIDE_TEMPLATE_CSV_DOWNLOAD_FAILURE,
  }
}


// API THUNK ACTION CREATORS

export function loadCareGuideTemplates(page=1, limit=10, order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[careGuideTemplates.actions] loadCareGuideTemplates(${page}, ${limit}, ${order}, %j, ###)`, filter);

  return async function(dispatch) {
    dispatch(careGuideTemplateListRequest(page, limit, order, filter));

    // call API
    const response = await api.getCareGuideTemplates(page, limit, order, filter);
    let success = false;

    // get care guide templates list success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API care guide templates list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`);

      const normalizedEntities = normalize(response.getIn(['data', 'care_guide_templates']), [schema.careGuideTemplate]);
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideTemplateListSuccess(data));
      success = true;

    } else if (1 === page && 204 === response.get('status')) {

      Logger.log('info', `Get API care guide templates success [empty]. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: []
      };
      dispatch(careGuideTemplateListSuccess(data));
      success = true;
      
    // get care guide templates list failure
    } else {
      Logger.log('info', `Get API care guide templates list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      dispatch(careGuideTemplateListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function loadCareGuideTemplate(id, cb=function(){}) {
  Logger.log('debug', `[careGuideTemplates.actions] loadCareGuideTemplate(${id}, ###)`);

  return async function(dispatch) {
    dispatch(careGuideTemplateReadRequest(id));

    // call API
    const response = await api.getCareGuideTemplate(id);
    let success = false;

    // get care guide template success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API care guide template success. ID: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'care_guide_template'])], [schema.careGuideTemplate]);
      const data = {
        id: response.getIn(['data', 'care_guide_template', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideTemplateReadSuccess(data));
      success = true;
      
    // get care guide template failure
    } else {
      Logger.log('info', `Get API care guide template failure. ID: ${id}.`);
      dispatch(careGuideTemplateReadFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function updateCareGuideTemplate(id, data, cb=function(){}) {
  Logger.log('debug', `[careGuideTemplates.actions] updateCareGuideTemplate(${id}, %j, ###)`, data);

  return async function(dispatch) {
    dispatch(careGuideTemplateUpdateRequest(id, data));

    // call API
    const response = await api.putCareGuideTemplate(id, data);
    let success = false;

    // put care guide template success
    if (200 === response.get('status')) {

      Logger.log('info', `PUT API care guide template success. User: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'care_guide_template'])], [schema.careGuideTemplate]);
      const data = {
        id: response.getIn(['data', 'care_guide_template', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideTemplateUpdateSuccess(data));
      success = true;
      
    // get care guide template failure
    } else {
      Logger.log('info', `PUT API care guide template failure. ID: ${id}.`);
      dispatch(careGuideTemplateUpdateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function createCareGuideTemplate(data, cb=function(){}) {
  Logger.log('debug', `[careGuideTemplates.actions] createCareGuideTemplate(%j, ###)`, data);

  return async function(dispatch) {
    dispatch(careGuideTemplateCreateRequest(data));

    // call API
    const response = await api.postCareGuideTemplates(data);
    let success = false;

    // post care guide templates success
    if (201 === response.get('status')) {

      Logger.log('info', `POST API care guide templates success. Post: ${response.getIn(['data', 'care_guide_template', 'id'])}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'care_guide_template'])], [schema.careGuideTemplate]);
      const data = {
        id: response.getIn(['data', 'care_guide_template', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideTemplateCreateSuccess(data));
      success = true;
      
    // get care guide templates failure
    } else {
      Logger.log('info', `POST API care guide templates failure.`);
      dispatch(careGuideTemplateCreateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function deleteCareGuideTemplate(id, cb=function(){}) {
  Logger.log('debug', `[careGuideTemplates.actions] deleteCareGuideTemplate(${id}, ###)`);

  return async function(dispatch) {
    dispatch(careGuideTemplateDeleteRequest(id));

    // call API
    const response = await api.deleteCareGuideTemplate(id);
    let success = false;

    // delete care guide template success
    if (204 === response.get('status')) {

      Logger.log('info', `DELETE API care guide template success. ID: ${id}.`);

      dispatch(removeEntity({entityType: 'care_guide_templates', id: id}));
      dispatch(careGuideTemplateDeleteSuccess(id));
      success = true;
      
    // get care guide template failure
    } else {
      Logger.log('info', `DELETE API care guide template failure. ID: ${id}.`);
      dispatch(careGuideTemplateDeleteFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function downloadCareGuideTemplatesCSV(order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[state.careGuideTemplates.actions] downloadCareGuideTemplatesCSV(${order}, ${filter}, ###)`);

  return async function(dispatch) {
    dispatch(careGuideTemplateCSVDownloadRequest(order, filter));

    // call API
    const response = await api.getCareGuideTemplatesCSV(order, filter);

    // get care guide templates CSV success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API care guide templates CSV success. Order: ${order}`);
      const now = new Date();

      // trigger browser download
      const url = window.URL.createObjectURL(new Blob([response.get('data')]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `cv-care-guide-templates-${Format.date(now, 'YYYY-MM-DDTHHmm')}.csv`);
      document.body.appendChild(link);
      link.click();

      dispatch(careGuideTemplateCSVDownloadSuccess());
      
    // get care guide templates CSV failure
    } else {
      Logger.log('info', `Get API care guide templates CSV failure.`);
      dispatch(careGuideTemplateCSVDownloadFailure());
    }

    // callback function
    cb();
  }
}

Logger.log('silly', `careGuideTemplates.actions loaded.`);
