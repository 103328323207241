import {connect} from 'react-redux';
import {denormalize} from 'normalizr';

import {schema} from '../../../../../state/schema';
import {loadPartnerships, deletePartnership} from '../../../../../state/modules/partnerships/actions';
import {sendMessage} from '../../../../../state/actions';
import PartnershipsList from '../components/PartnershipsList';

const mapStateToProps = (state, ownProps) => {

  const page = ownProps.page;
  const limit = ownProps.limit;
  const order = ownProps.order;
  const result = state.partnerships.getIn(['pages', order, limit, page]);

  return {
    list: result 
      ? result.map(x => {
          return {
            key: x,
            ...denormalize(
              state.entities.getIn(['partnerships', x]),
              schema.partnership,
              state.entities.toJS()
            )
          };
        }).toArray().map(x => {
          // flatten some nested objects to make table easier to work with
          return {
            plan_id: x.plan ? x.plan.id : '',
            plan_name: x.plan ? x.plan.name : '',
            ...x,
          };
        })
      : [],
    isLoading: state.partnerships.get('isLoading'),
    total: state.partnerships.get('total'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (page, limit, order, filter, cb) => {
      dispatch(loadPartnerships(page, limit, order, filter, cb));
    },
    remove: (id, cb) => {
      dispatch(deletePartnership(id, cb));
    },
    sendMessage: (level, title, body, expires) => {
      dispatch(sendMessage(level, title, body, expires));
    },
  }
}

const PartnershipsListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnershipsList);

export default PartnershipsListContainer;
