import React, {useState} from 'react';
import {Translation} from 'react-i18next';
import {Button, Checkbox, Form, Input, Space} from 'antd';
import {MinusOutlined, PlusOutlined} from '@ant-design/icons';

import TableColumnTypeInput from './TableColumnTypeInput';

const FieldTypeTableEditor = ({form, content, contentsPath, ...props}) => {

  const columns = form.getFieldValue([...contentsPath, `columns`]);

  const [columnCount, setColumnCount] = useState(columns ? columns.length : 0);
  const [columnTypes, setColumnTypes] = useState([...Array(columnCount).keys()].map(i => form.getFieldValue([...contentsPath, `columns`, i, `type`])).join());

  // input column settings
  const layout = {
    main: {
      wrapperCol: {span: 24},
    },
  }

  const updateColumnTypes = () => {
    // create a string of comma separated column types
    const values = [...Array(columnCount).keys()].map(i => form.getFieldValue([...contentsPath, `columns`, i, `type`]));
    setColumnTypes(values.join())
  }

  return (
    <Translation>{(t) => 
      <>

        <div className="field-type-table-column-list">
          <Form.List name={[content.name, 'columns']}>

            {(columns, {add, remove, move}) => {
              return(
                <>
                  {columns.map((column, i) => (
                    <Space key={i} size={2}>
                      <Space direction="vertical">

                        <TableColumnTypeInput
                          {...column}
                          name={[column.name, 'type']}
                          fieldKey={[column.fieldKey, 'type']}
                          onChange={updateColumnTypes}
                          {...layout.main}
                        />

                        <Form.Item
                          {...column}
                          name={[column.name, 'label']}
                          fieldKey={[column.fieldKey, 'label']}
                          rules={[
                            {required: true, message: t('feedback_validation_required')},
                          ]}
                          {...layout.main}
                        >
                          <Input placeholder="Header" />
                        </Form.Item>

                      </Space>

                      <Button
                        danger
                        shape="circle"
                        size="small"
                        icon={<MinusOutlined />}
                        onClick={() => {
                          setColumnCount(columnCount - 1);
                          remove(column.name);
                          updateColumnTypes();
                        }}
                      />
                    </Space>
                  ))}

                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => {
                        setColumnCount(columnCount + 1);
                        add();
                      }}
                      block
                    >
                      <PlusOutlined /> Add column
                    </Button>
                  </Form.Item>
                </>
              );
            }}

          </Form.List>
        </div>

        <div className="field-type-table-row-list">
          <Form.List name={[content.name, 'rows']}>

            {(rows, {add, remove, move}) => {
              return(
                <>
                  {rows.map((row, j) => (
                    <div key={j}>
                      <Space direction="horizontal">

                        {[...Array(columnCount).keys()].map(i => (
                          <div key={i}>
                            {
                              [
                                // text
                                <Form.Item
                                  {...row}
                                  name={[row.name, 'content', i]}
                                  fieldKey={[row.fieldKey, 'content', i]}
                                  {...layout.main}
                                >
                                  <Input placeholder="Content..." />
                                </Form.Item>,

                                // textarea
                                <Form.Item
                                  {...row}
                                  name={[row.name, 'content', i]}
                                  fieldKey={[row.fieldKey, 'content', i]}
                                  {...layout.main}
                                >
                                  <Input.TextArea rows={3} placeholder="Content..." />
                                </Form.Item>,

                                // check mark
                                <Form.Item
                                {...row}
                                name={[row.name, 'content', i]}
                                fieldKey={[row.fieldKey, 'content', i]}
                                valuePropName="checked"
                                {...layout.main}
                                >
                                  <Checkbox />
                                </Form.Item>,

                                // text w/check mark
                                <Form.Item
                                  {...row}
                                  name={[row.name, 'content', i]}
                                  fieldKey={[row.fieldKey, 'content', i]}
                                  {...layout.main}
                                >
                                  <Input placeholder="Content..." />
                                </Form.Item>,

                                // cross mark
                                <Form.Item
                                  {...row}
                                  name={[row.name, 'content', i]}
                                  fieldKey={[row.fieldKey, 'content', i]}
                                  valuePropName="checked"
                                  {...layout.main}
                                >
                                  <Checkbox />
                                </Form.Item>,

                                // text w/cross
                                <Form.Item
                                  {...row}
                                  name={[row.name, 'content', i]}
                                  fieldKey={[row.fieldKey, 'content', i]}
                                  {...layout.main}
                                >
                                  <Input placeholder="Content..." />
                                </Form.Item>,

                                // phone number
                                <Form.Item
                                  {...row}
                                  name={[row.name, 'content', i]}
                                  fieldKey={[row.fieldKey, 'content', i]}
                                  {...layout.main}
                                >
                                  <Input placeholder="Phone Number..." />
                                </Form.Item>,

                                // URL
                                <Form.Item
                                  {...row}
                                  name={[row.name, 'content', i]}
                                  fieldKey={[row.fieldKey, 'content', i]}
                                  {...layout.main}
                                >
                                  <Input placeholder="URL..." />
                                </Form.Item>,

                              ][parseInt(columnTypes.split(',').filter((type, it) => it === i) - 1)]
                            }
                          </div>
                        ))}

                        <Button
                          danger
                          shape="circle"
                          size="small"
                          icon={<MinusOutlined />}
                          onClick={() => remove(row.name)}
                        />

                      </Space>
                    </div>
                  ))}

                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => {
                        add();
                      }}
                      block
                    >
                      <PlusOutlined /> Add row
                    </Button>
                  </Form.Item>
                </>
              );
            }}

          </Form.List>
        </div>

      </>
    }</Translation>
  )
}

export default FieldTypeTableEditor;
