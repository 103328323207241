import {connect} from 'react-redux';

import CareGuidePartnerTagAddScreen from '../components/CareGuidePartnerTagAddScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.careGuidePartnerTags.get('isLoading'),
    success: state.careGuidePartnerTags.getIn(['form', 'success']),
  }
}

const CareGuidePartnerTagAddScreenContainer = connect(
  mapStateToProps
)(CareGuidePartnerTagAddScreen);

export default CareGuidePartnerTagAddScreenContainer;