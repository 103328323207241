import React, {useEffect} from 'react';
import {Translation} from 'react-i18next';
import {Form, Select} from 'antd';

const TagInput = ({careGuideId, name, label, options, isLoading, isSubmitting, load, ...props}) => {

  // load journal partner tags care guide on mount
  useEffect(() => {
    if (careGuideId) {
      load(careGuideId, 1, 150, 'created_at.desc');
    }
  }, [load, careGuideId]);

  return (
    <Translation>{(t) => 
      <div className="form-group">
        <Form.Item
          name={name}
          label={label ? label : t('journal_post_partner_tags')}
        >
          <Select
            mode="tags"
            disabled={isLoading || isSubmitting}
            loading={isLoading}
          >
            {options.map(x => <Select.Option key={x.value} value={x.value}>{x.label}</Select.Option>)}
          </Select>
        </Form.Item>
      </div>
    }</Translation>
  );
}

export default TagInput;