import {connect} from 'react-redux';

import {downloadCareGuideSectionsCSV} from '../../../../../state/modules/careGuideSections/actions';
import CareGuideSectionsCSVButton from '../components/CareGuideSectionsCSVButton';

const mapStateToProps = state => {
  return {
    isDownloading: state.careGuideSections.get('isCSVDownloading'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    download: (order, filter, cb) => {
      dispatch(downloadCareGuideSectionsCSV(order, filter, cb));
    }
  }
}

const CareGuideSectionsCSVButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CareGuideSectionsCSVButton);

export default CareGuideSectionsCSVButtonContainer;
