import React, { useEffect } from 'react';
import { Translation } from 'react-i18next';
import { Affix, Card, Col, Input, Row, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import DocumentHead from '../../../elements/components/DocumentHead';
import Config from '../../../../../Config';
import Format from '../../../../../lib/Format';
import Logger from '../../../../../lib/Logger';
import { ImportTaskStatusTag } from '../../../elements/components/Tags';


const getResults = (obj={}) => {

  let resultMessages = [];

  const getResultsRecursive = (obj) => {
    for (const key in obj) {
      if (typeof obj[key] === 'object') {
        getResultsRecursive(obj[key]);
      } else {
        resultMessages.push(obj[key]);
      }
    }
  };

  getResultsRecursive(obj);
  return resultMessages;
};

const DisplayInput = ({ label, value, ...props }) => (
  <div className="form-group">
    <div className="ant-row ant-form-item" style={{ marginBottom: 0 }}>
      <div className="ant-col ant-col-5 ant-form-item-label">
        <label
          htmlFor="import_task_form_application"
          className=""
          title="Application"
        >
          {label}
        </label>
      </div>
      <div className="ant-col ant-col-19 ant-form-item-control">
        <Input value={value} bordered={false} />
      </div>
    </div>
  </div>
);

const DisplayComponent = ({ label, value, ...props }) => (
  <div className="form-group">
    <div className="ant-row ant-form-item" style={{ marginBottom: 0 }}>
      <div className="ant-col ant-col-5  ant-form-item-label">
        <label
          htmlFor="import_task_form_application"
          className=""
          title="Application"
        >
          {label}
        </label>
      </div>
      <div className="ant-col ant-col-19 ant-form-item-control">
        <div className="ant-form-item-control-input">
          <div className="ant-form-item-control-input-content">{value}</div>
        </div>
      </div>
    </div>
  </div>
);

const DisplayInputText = ({ label, value, ...props }) => (
  <div className="form-group">
    <div className="ant-row ant-form-item" style={{ marginBottom: 0 }}>
      <div className="ant-col ant-col-5 ant-form-item-label">
        <label
          htmlFor="import_task_form_application"
          className=""
          title="Application"
        >
          {label}
        </label>
      </div>
      <div className="ant-col ant-col-19 ant-form-item-control">
        <Input.TextArea style={{color: "black"}} disabled rows={3} value={value} bordered={false} />
      </div>
    </div>
  </div>
);

const ImportTaskViewScreen = ({
  importTask,
  isLoading,
  load,
  id,
  ...props
}) => {
  const scrollToTop = () => {
    Logger.log('debug', `ImportTaskViewScreen.scrollToTop()`);
    if (typeof window !== 'undefined') {
      setTimeout(() => window.scrollTo(0, 0), 100);
    }
  };

  useEffect(() => {
    scrollToTop();
    load(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Translation>
      {(t) => (
        <div>
          <DocumentHead title={t('import_tasks_route_view')} />

          <div className="import-task-view">
            <Row gutter={16}>
              <Col xs={24} lg={18}>
                <Card
                  title={t('import_tasks_view_title')}
                  extra={
                    isLoading ? (
                      <Spin
                        indicator={
                          <LoadingOutlined style={{ fontSize: 20 }} spin />
                        }
                      />
                    ) : null
                  }
                >
                  <DisplayInput
                    label={t('import_task_administrator_username')}
                    value={importTask?.administrator?.username ?? null}
                  />
                  <DisplayInput
                    label={t('import_task_owner_username')}
                    value={
                      importTask?.care_guide?.owner?.profile?.user?.username ??
                      null
                    }
                  />
                  <DisplayInput
                    label={t('import_task_care_guide_name')}
                    value={
                      importTask?.care_guide?.first_name &&
                      importTask?.care_guide?.last_name
                        ? `${importTask?.care_guide?.first_name} ${importTask?.care_guide?.last_name}`
                        : null
                    }
                  />
                  <DisplayComponent
                    label={t('import_task_status')}
                    value={
                      importTask?.status ? (
                        <ImportTaskStatusTag status={importTask?.status} />
                      ) : null
                    }
                  />
                  <DisplayInputText
                    label={t('import_task_results')}
                    value={importTask?.results ? getResults(importTask?.results ?? {}).join('\n') : null}
                  />
                </Card>
              </Col>
              <Col xs={24} lg={6}>
                <Affix offsetTop={10}>
                  <Card title={t('form_metadata_header')}>
                    {importTask && importTask.created_at ? (
                      <p>
                        {t('form_metadata_created_at')}{' '}
                        <small>
                          {Format.date(
                            importTask.created_at,
                            Config.get('DEFAULT_DATETIME_FORMAT')
                          )}
                        </small>
                      </p>
                    ) : (
                      ''
                    )}
                    {importTask &&
                    importTask.updated_at &&
                    importTask.updated_at !== importTask.created_at ? (
                      <p>
                        {t('form_metadata_updated_ad')}{' '}
                        <small>
                          {Format.date(
                            importTask.updated_at,
                            Config.get('DEFAULT_DATETIME_FORMAT')
                          )}
                        </small>
                      </p>
                    ) : (
                      ''
                    )}
                  </Card>
                </Affix>
              </Col>
            </Row>
          </div>
        </div>
      )}
    </Translation>
  );
};

export default ImportTaskViewScreen;

Logger.log('silly', `ImportTaskViewScreen loaded.`);
