import React from 'react';
import {Translation} from 'react-i18next';
import {Tag} from 'antd';


const OneTimePasswordTag = ({status, ...props}) => {

  const labels = {
    1: "roles_input_one_time_password_off",
    2: "roles_input_one_time_password_optional",
    3: "roles_input_one_time_password_on",
  };

  const colors = {
    1: "error",
    2: "processing",
    3: "success",
  };

  return (
    <Translation>{(t) =>
      <Tag color={colors[status]}>{t(labels[status])}</Tag>
    }</Translation>
  )
}

export default OneTimePasswordTag;
