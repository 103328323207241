import { connect } from 'react-redux';

import CareGuideDocumentEditScreen from '../components/CareGuideDocumentEditScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.careGuideDocuments.get('isLoading'),
    success: state.careGuideDocuments.getIn(['form', 'success']),
  }
}

const CareGuideDocumentEditScreenContainer = connect(
  mapStateToProps
)(CareGuideDocumentEditScreen);

export default CareGuideDocumentEditScreenContainer;
