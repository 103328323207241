import {connect} from 'react-redux';

import {downloadMessagesCSV} from '../../../../../state/modules/messages/actions';
import MessagesCSVButton from '../components/MessagesCSVButton';

const mapStateToProps = state => {
  return {
    isDownloading: state.messages.get('isCSVDownloading'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    download: (order, filter, cb) => {
      dispatch(downloadMessagesCSV(order, filter, cb));
    }
  }
}

const MessagesCSVButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MessagesCSVButton);

export default MessagesCSVButtonContainer;
