import { connect } from 'react-redux';

import JournalRepliesScreen from '../components/JournalRepliesScreen';
import { journalRepliesCSV } from '../../../../../state/modules/journalReplies/actions';

const mapStateToProps = (state) => {
  return {
    total: state.journalReplies.get('total'),
    isLoading: state.journalReplies.get('isLoading'),
    isDownloading: state.journalReplies.get('isCSVDownloading'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    download: (order, filter, cb) => {
      dispatch(journalRepliesCSV(order, filter, cb));
    },
  };
};

const JournalRepliesScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(JournalRepliesScreen);

export default JournalRepliesScreenContainer;
