import {connect} from 'react-redux';

import RegionAddScreen from '../components/RegionAddScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.locationRegions.get('areRegionsLoading'),
    success: state.locationRegions.getIn(['form', 'success']),
  }
}

const RegionAddScreenContainer = connect(
  mapStateToProps
)(RegionAddScreen);

export default RegionAddScreenContainer;
