import {getI18n} from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import EmailsScreen from './containers/EmailsScreenContainer';
import EmailEditScreen from './containers/EmailEditScreenContainer';

const register = () => {
  Logger.log('debug', `emails.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'emails', 'routes'])) {
    Events.dispatch('ADD_MAIN_ROUTES', 
      {
        'EmailEditScreen': ['PrivateRoute', '/emails/edit/:id([\\w\\-]+)', true, EmailEditScreen, getI18n().t('email_route_edit')],
        'EmailsScreen': ['PrivateRoute', '/emails/:page(\\d+)?', true, EmailsScreen, getI18n().t('emails_route_list')],
      }
    );
  }
}

export default register;

Logger.log('silly', `emails.register() loaded.`);
