import { normalize } from 'normalizr';

import api from '../../api';
import { schema } from '../../schema';
import { addEntities } from '../../actions';
import Logger from '../../../lib/Logger';
import Format from '../../../lib/Format';

// ACTION TYPES

export const ALERT_LIST_REQUEST = 'ALERT_LIST_REQUEST';
export const ALERT_LIST_SUCCESS = 'ALERT_LIST_SUCCESS';
export const ALERT_LIST_FAILURE = 'ALERT_LIST_FAILURE';
export const ALERT_READ_REQUEST = 'ALERT_READ_REQUEST';
export const ALERT_READ_SUCCESS = 'ALERT_READ_SUCCESS';
export const ALERT_READ_FAILURE = 'ALERT_READ_FAILURE';
export const ALERT_CSV_DOWNLOAD_REQUEST = 'ALERT_CSV_DOWNLOAD_REQUEST';
export const ALERT_CSV_DOWNLOAD_SUCCESS = 'ALERT_CSV_DOWNLOAD_SUCCESS';
export const ALERT_CSV_DOWNLOAD_FAILURE = 'ALERT_CSV_DOWNLOAD_FAILURE';

// ACTION CREATORS

export function alertListRequest(page, limit, order, filter) {
  Logger.log(
    'debug',
    `[alert.actions] alertListRequest(${page}, ${limit}, ${order}, %j)`,
    filter
  );
  return {
    type: ALERT_LIST_REQUEST,
    page: page,
    limit: limit,
    order: order,
    filter: filter,
  };
}

export function alertListSuccess(data) {
  Logger.log('debug', `[alert.actions] alertListSuccess(%j)`, data);
  return {
    type: ALERT_LIST_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now(),
  };
}

export function alertListFailure(error) {
  Logger.log('debug', `[alert.actions] alertListFailure(%j)`, error);
  return {
    type: ALERT_LIST_FAILURE,
    error: error,
  };
}

export function alertReadRequest(id) {
  Logger.log('debug', `[alert.actions] alertReadRequest(${id})`);
  return {
    type: ALERT_READ_REQUEST,
    id: id,
  };
}

export function alertReadSuccess(data) {
  Logger.log('debug', `[alert.actions] alertReadSuccess(%j)`, data);
  return {
    type: ALERT_READ_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function alertReadFailure(error) {
  Logger.log('debug', `[alert.actions] alertReadFailure(%j)`, error);
  return {
    type: ALERT_READ_FAILURE,
    error: error,
  };
}

export function alertCSVDownloadRequest(order, filter) {
  Logger.log('debug', `[state.alerts.actions] alertCSVDownloadRequest()`);
  return {
    type: ALERT_CSV_DOWNLOAD_REQUEST,
    order: order,
    filter: filter,
  }
}

export function alertCSVDownloadSuccess() {
  Logger.log('debug', `[state.alerts.actions] alertCSVDownloadSuccess()`);
  return {
    type: ALERT_CSV_DOWNLOAD_SUCCESS,
  }
}

export function alertCSVDownloadFailure() {
  Logger.log('debug', `[state.alerts.actions] alertCSVDownloadFailure()`);
  return {
    type: ALERT_CSV_DOWNLOAD_FAILURE,
  }
}


// API THUNK ACTION CREATORS

export function loadAlerts(
  page = 1,
  limit = 10,
  order = null,
  filter = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[alert.actions] loadAlerts(${page}, ${limit}, ${order}, ###)`
  );

  return async function (dispatch) {
    dispatch(alertListRequest(page, limit, order, filter));

    // call API
    const response = await api.getAlerts(page, limit, order, filter);
    let success = false;

    // get alerts list success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API alerts list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );

      const normalizedEntities = normalize(response.getIn(['data', 'alerts']), [
        schema.alert,
      ]);
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result,
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(alertListSuccess(data));
      success = true;
    } else if (1 === page && 204 === response.get('status')) {
      Logger.log(
        'info',
        `Get API alerts list success [empty]. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: [],
      };
      dispatch(alertListSuccess(data));

      // get alerts list failure
    } else {
      Logger.log(
        'info',
        `Get API alerts list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      dispatch(alertListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function loadAlert(id, cb = function () {}) {
  Logger.log('debug', `[alert.actions] loadAlert(${id}, ###)`);

  return async function (dispatch) {
    dispatch(alertReadRequest(id));

    // call API
    const response = await api.getAlert(id);
    let success = false;

    // get alert success
    if (200 === response.get('status')) {
      Logger.log('info', `Get API alert success. ID: ${id}.`);

      const normalizedEntities = normalize(
        [response.getIn(['data', 'alert'])],
        [schema.alert]
      );
      const data = {
        id: response.getIn(['data', 'alert', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(alertReadSuccess(data));
      success = true;

      // get alert failure
    } else {
      Logger.log('info', `Get API alert failure. ID: ${id}.`);
      dispatch(alertReadFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function downloadAlertsCSV(order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[state.alerts.actions] downloadAlertsCSV(${order}, ${filter}, ###)`);

  return async function(dispatch) {
    dispatch(alertCSVDownloadRequest(order, filter));

    // call API
    const response = await api.getAlertsCSV(order, filter);

    // get alerts CSV success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API alerts CSV success. Order: ${order}`);
      const now = new Date();

      // trigger browser download
      const url = window.URL.createObjectURL(new Blob([response.get('data')]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `cv-alerts-${Format.date(now, 'YYYY-MM-DDTHHmm')}.csv`);
      document.body.appendChild(link);
      link.click();

      dispatch(alertCSVDownloadSuccess());
      
    // get alerts CSV failure
    } else {
      Logger.log('info', `Get API alerts CSV failure.`);
      dispatch(alertCSVDownloadFailure());
    }

    // callback function
    cb();
  }
}

Logger.log('silly', `alerts.actions loaded.`);
