import {connect} from 'react-redux';

import RegionEditScreen from '../components/RegionEditScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.locationRegions.get('areRegionsLoading'),
    success: state.locationRegions.getIn(['form', 'success']),
  }
}

const RegionEditScreenContainer = connect(
  mapStateToProps
)(RegionEditScreen);

export default RegionEditScreenContainer;
