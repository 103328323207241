import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Translation, getI18n } from 'react-i18next';
import {
  Affix,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Spin,
  Space,
  Radio,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';

import message from '../../../elements/lib/MessageWrapper';
import FormMetadata from '../../../elements/components/FormMetadata';
import { pathTo } from '../../../Routes';
import Format from '../../../../../lib/Format';
import Logger from '../../../../../lib/Logger';
import Config from '../../../../../Config';
import PartnershipInput from '../../partnerships/containers/PartnershipInputContainer';
import UserPartnerPartnershipInput from '../../users/containers/UserPartnerPartnershipInputContainer';

const RecommendedUtilityForm = ({
  id,
  data,
  errors,
  load,
  destroyForm,
  isLoading,
  isSubmitting,
  created_id,
  ...props
}) => {
  const [redirectTo, setRedirectTo] = useState(null);
  const [optionType, setOptionType] = useState(1);
  const [partnershipId, setPartnershipId] = useState(null);

  const [form] = Form.useForm();

  const onChangePartnershipId = (value) => {
    setPartnershipId(value);
    return value;
  };

  // form column settings
  const layout = {
    main: {
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
    },
  };

  // load record data from API
  useEffect(() => {
    if (id) {
      load(id);
    }
  }, [id, load]);

  // update input values when new data is available
  const dataString = JSON.stringify(data);
  useEffect(() => {
    if (id && !isSubmitting) {
      const dataObj = JSON.parse(dataString);

      setOptionType(
        dataObj['file_bucket'] &&
          dataObj['file_directory'] &&
          dataObj['file_extension'] &&
          dataObj['file_name']
          ? 2
          : 1
      );
      setPartnershipId(dataObj['partnership']);
      dataObj['uploaded_at'] = dataObj['uploaded_at']
        ? moment(dataObj['uploaded_at'], Config.get('API_DATETIME_FORMAT'))
        : moment();
      form.setFieldsValue(dataObj);
    }
  }, [form, dataString, isSubmitting, id]);

  // handle errors reported by API
  useEffect(() => {
    let firstFieldName = '';
    for (const field in errors) {
      form.setFields([{ name: field, errors: errors[field] }]);
      if (firstFieldName === '') {
        firstFieldName = field;
      }
    }
    form.scrollToField(firstFieldName);
  }, [form, errors]);

  // redirect add form to edit form on successful create action
  useEffect(() => {
    if (created_id) {
      setRedirectTo(pathTo('RecommendedUtilityEditScreen', { id: created_id }));
    }
    return () => {
      destroyForm();
    };
  }, [created_id, setRedirectTo, destroyForm]);

  // submit data handler
  const submitData = async (values) => {
    Logger.log('debug', `RecommendedUtilityForm.submitData(###)`);

    // API POST/PUT payload
    let payload = {};
    for (const input of Object.keys(data)) {
      if (input in values) {
        // transform profile refs to UUIDs
        if (['profile'].includes(input)) {
          payload['user_profile_id'] = values[input];
        } else if (['partnership'].includes(input)) {
          payload[input + '_id'] = values[input];
        } else if (['uploaded_at'].includes(input)) {
          payload[input] = Format.date(
            values[input],
            Config.get('API_DATETIME_FORMAT')
          );
        } else {
          payload[input] = values[input];
        }
      }
    }

    if (id) {
      // update
      props.update(id, payload, (success) => {
        if (success) {
          message.success(getI18n().t('feedback_form_success'));
        } else {
          message.error(getI18n().t('feedback_form_error'));
        }
      });
    } else {
      // create
      props.create(payload, (success) => {
        if (success) {
          message.success(getI18n().t('feedback_form_success'));
        } else {
          message.error(getI18n().t('feedback_form_error'));
        }
      });
    }
  };

  // form submit handler
  const handleFinish = async (values) => {
    Logger.log('debug', `RecommendedUtilityForm.handleFinish(###)`);
    if (!props.isSubmitting) {
      await submitData(values);
    }
  };

  // form error handler
  const handleFinishFailed = ({ values, errorFields, outOfDate }) => {
    Logger.log('debug', `RecommendedUtilityForm.handleFinishFailed(###)`);
    message.error(getI18n().t('feedback_form_error'));
    if (errorFields && errorFields.length > 0) {
      form.scrollToField(errorFields[0].name);
    }
  };

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <Translation>
      {(t) => (
        <>
          <div className="recommended-utility-form">
            <Form
              name="recommended_utility_form"
              form={form}
              initialValues={data}
              onFinish={handleFinish}
              onFinishFailed={handleFinishFailed}
              validateTrigger="onSubmit"
              {...layout.main}
            >
              <Row gutter={16}>
                <Col xs={24} lg={18}>
                  <Card
                    title={
                      id
                        ? t('recommended_utilities_edit_title')
                        : t('recommended_utilities_add_title')
                    }
                    extra={
                      isLoading ? (
                        <Spin
                          indicator={
                            <LoadingOutlined style={{ fontSize: 20 }} spin />
                          }
                        />
                      ) : null
                    }
                  >
                    <div className="form-group">
                      <Form.Item
                        name="title"
                        label={t('recommended_utilities_title')}
                        rules={[
                          {
                            required: true,
                            message: t('feedback_validation_required'),
                          },
                          {
                            type: 'string',
                            min: 0,
                            max: 120,
                            message: t('feedback_validation_char_range', {
                              min: 0,
                              max: 120,
                            }),
                          },
                        ]}
                      >
                        <Input disabled={isLoading || isSubmitting} />
                      </Form.Item>
                    </div>

                    <PartnershipInput
                      form={form}
                      name="partnership"
                      label={t('recommended_utilities_partnership')}
                      disabled={isLoading || isSubmitting}
                      onChange={onChangePartnershipId}
                      rules={[
                        {
                          required: true,
                          message: t('feedback_validation_required'),
                        },
                      ]}
                    />
                    <UserPartnerPartnershipInput
                      partnershipId={partnershipId}
                      name="profile"
                      label={t('calendar_event_participant_user')}
                      rules={[
                        {
                          required: true,
                          message: t('feedback_validation_required'),
                        },
                      ]}
                      form={form}
                      disabled={isLoading || isSubmitting}
                    />
                    <div className="form-group">
                      <Form.Item label={t('recommended_utilities_option_type')}>
                        <Radio.Group
                          size={'large'}
                          onChange={(e) => setOptionType(e.target.value)}
                          value={optionType}
                        >
                          <Space direction="vertical">
                            <Radio value={1}>
                              {t('recommended_utilities_website')}
                            </Radio>
                            <Radio value={2}>
                              {t('recommended_utilities_document')}
                            </Radio>
                          </Space>
                        </Radio.Group>
                      </Form.Item>
                    </div>

                    {optionType === 1 && (
                      <div className="form-group">
                        <Form.Item
                          name="website"
                          label={t('recommended_utilities_website')}
                          rules={[
                            {
                              required: true,
                              message: t('feedback_validation_required'),
                            },
                          ]}
                        >
                          <Input disabled={isLoading || isSubmitting} />
                        </Form.Item>
                      </div>
                    )}
                    {optionType === 2 && (
                      <>
                        <div className="form-group">
                          <Form.Item
                            name="file_name"
                            label={t('recommended_utilities_file_name')}
                            rules={[
                              {
                                required: true,
                                message: t('feedback_validation_required'),
                              },
                              {
                                type: 'string',
                                min: 1,
                                max: 255,
                                message: t('feedback_validation_char_range', {
                                  min: 1,
                                  max: 255,
                                }),
                              },
                            ]}
                          >
                            <Input disabled={isLoading || isSubmitting} />
                          </Form.Item>
                        </div>
                        <div className="form-group">
                          <Form.Item
                            name="file_name"
                            label={t('recommended_utilities_file_name')}
                            rules={[
                              {
                                required: true,
                                message: t('feedback_validation_required'),
                              },
                              {
                                type: 'string',
                                min: 1,
                                max: 120,
                                message: t('feedback_validation_char_range', {
                                  min: 1,
                                  max: 120,
                                }),
                              },
                            ]}
                          >
                            <Input disabled={isLoading || isSubmitting} />
                          </Form.Item>
                        </div>
                        <div className="form-group">
                          <Form.Item
                            name="file_extension"
                            label={t('recommended_utilities_file_extension')}
                            rules={[
                              {
                                required: true,
                                message: t('feedback_validation_required'),
                              },
                              {
                                type: 'string',
                                min: 0,
                                max: 20,
                                message: t('feedback_validation_char_range', {
                                  min: 0,
                                  max: 20,
                                }),
                              },
                            ]}
                          >
                            <Input disabled={isLoading || isSubmitting} />
                          </Form.Item>
                        </div>

                        <div className="form-group">
                          <Form.Item
                            name="file_directory"
                            label={t('recommended_utilities_file_directory')}
                            rules={[
                              {
                                required: true,
                                message: t('feedback_validation_required'),
                              },
                              {
                                type: 'string',
                                min: 0,
                                max: 120,
                                message: t('feedback_validation_char_range', {
                                  min: 0,
                                  max: 120,
                                }),
                              },
                            ]}
                          >
                            <Input disabled={isLoading || isSubmitting} />
                          </Form.Item>
                        </div>

                        <div className="form-group">
                          <Form.Item
                            name="file_bucket"
                            label={t('recommended_utilities_file_bucket')}
                            rules={[
                              {
                                required: true,
                                message: t('feedback_validation_required'),
                              },
                              {
                                type: 'string',
                                min: 0,
                                max: 64,
                                message: t('feedback_validation_char_range', {
                                  min: 0,
                                  max: 64,
                                }),
                              },
                            ]}
                          >
                            <Input disabled={isLoading || isSubmitting} />
                          </Form.Item>
                        </div>

                        <div className="form-group">
                          <Form.Item
                            name="size_bytes"
                            label={t('recommended_utilities_size_bytes')}
                            rules={[
                              {
                                required: true,
                                message: t('feedback_validation_required'),
                              },
                              {
                                type: 'number',
                                message: t('feedback_validation_number'),
                              },
                            ]}
                          >
                            <InputNumber
                              min={0}
                              formatter={(value) =>
                                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                              }
                              parser={(value) =>
                                value.replace(/\$\s?|(,*)/g, '')
                              }
                              disabled={isLoading || isSubmitting}
                              style={{ width: 150 }}
                            />
                          </Form.Item>
                        </div>

                        <div className="form-group">
                          <Form.Item
                            name="uploaded_at"
                            label={t('recommended_utilities_uploaded_at')}
                            rules={[
                              {
                                required: true,
                                message: t('feedback_validation_required'),
                              },
                            ]}
                          >
                            <DatePicker
                              showTime
                              disabled={isLoading || isSubmitting}
                              format={Config.get('DEFAULT_DATETIME_FORMAT')}
                            />
                          </Form.Item>
                        </div>
                      </>
                    )}
                  </Card>
                </Col>

                <Col xs={24} lg={6}>
                  <Affix offsetTop={10}>
                    <Card title={t('form_metadata_header')}>
                      <FormMetadata
                        id={id}
                        isSubmitting={isSubmitting}
                        delete={props.delete.bind(this)}
                        deleteRedirectTo="RecommendedUtilitysScreen"
                        createdAt={props.createdAt}
                        updatedAt={props.updatedAt}
                      />
                    </Card>
                  </Affix>
                </Col>
              </Row>
            </Form>
          </div>
        </>
      )}
    </Translation>
  );
};

export default RecommendedUtilityForm;

Logger.log('silly', `RecommendedUtilityForm loaded.`);
