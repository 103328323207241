import { connect } from 'react-redux';

import { downloadRecommendedUtilitiesCSV } from '../../../../../state/modules/recommendedUtilities/actions';
import RecommendedUtilitiesCSVButton from '../components/RecommendedUtilitiesCSVButton';

const mapStateToProps = (state) => {
  return {
    isDownloading: state.recommendedUtilities.get('isCSVDownloading'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    download: (order, filter, cb) => {
      dispatch(downloadRecommendedUtilitiesCSV(order, filter, cb));
    },
  };
};

const RecommendedUtilitiesCSVButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RecommendedUtilitiesCSVButton);

export default RecommendedUtilitiesCSVButtonContainer;
