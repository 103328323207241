import {connect} from 'react-redux';

import {downloadRelationshipsCSV} from '../../../../../state/modules/relationships/actions';
import RelationshipsCSVButton from '../components/RelationshipsCSVButton';

const mapStateToProps = state => {
  return {
    isDownloading: state.relationships.get('isCSVDownloading'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    download: (order, filter, cb) => {
      dispatch(downloadRelationshipsCSV(order, filter, cb));
    }
  }
}

const RelationshipsCSVButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RelationshipsCSVButton);

export default RelationshipsCSVButtonContainer;
