import {connect} from 'react-redux';
import {List} from 'immutable';

import {
  loadResourceServiceTag,
  updateResourceServiceTag,
  createResourceServiceTag,
  deleteResourceServiceTag,
  resourceServiceTagFormDestroy
} from '../../../../../state/modules/resourceServiceTags/actions';
import {sendMessage} from '../../../../../state/actions';
import ResourceServiceTagForm from '../components/ResourceServiceTagForm';

const inputs = List([
  'label',
  'color_hex',
  'status'
]);

const mapStateToProps = (state, ownProps) => {

  const data = {}, errors = {};
  for (const val of inputs.values()) {
    data[val] = state.entities.getIn(['resourceServiceTags', ownProps.id, val], '');
    errors[val] = state.resourceServiceTags.getIn(['form', 'errors', val]);
  }

  // change select number values to strings to work with ant design select inputs
  Object.keys(data).forEach(x => {
    if (['status', 'rating'].includes(x)) {
      data[x] = data[x].toString();
    }
  });

  return {
    isLoading: state.resourceServiceTags.get('isLoading'),
    isSubmitting: state.resourceServiceTags.getIn(['form', 'isSubmitting']),
    success: state.resourceServiceTags.getIn(['form', 'success']),
    created_id: state.resourceServiceTags.getIn(['form', 'created_id']),
    data: data,
    errors: errors,
    createdAt: state.entities.getIn(['resourceServiceTags', ownProps.id, 'created_at'], null),
    updatedAt: state.entities.getIn(['resourceServiceTags', ownProps.id, 'updated_at'], null),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (id, cb) => {
      dispatch(loadResourceServiceTag(id, cb));
    },
    update: (id, data, cb) => {
      dispatch(updateResourceServiceTag(id, data, cb));
    },
    create: (data, cb) => {
      dispatch(createResourceServiceTag(data, cb));
    },
    delete: (id, cb) => {
      dispatch(deleteResourceServiceTag(id, cb))
    },
    destroyForm: (formState) => {
      dispatch(resourceServiceTagFormDestroy(formState));
    },
    sendMessage: (level, title, body, expires) => {
      dispatch(sendMessage(level, title, body, expires));
    },
  }
}

const ResourceServiceTagFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResourceServiceTagForm);

export default ResourceServiceTagFormContainer;
