import {normalize} from 'normalizr';

import api from '../../api';
import {schema} from '../../schema';
import {addEntities, removeEntity} from '../../actions';
import Format from '../../../lib/Format';
import Logger from '../../../lib/Logger';

// ACTION TYPES

export const MESSAGE_LIST_REQUEST = 'MESSAGE_LIST_REQUEST';
export const MESSAGE_LIST_SUCCESS = 'MESSAGE_LIST_SUCCESS';
export const MESSAGE_LIST_FAILURE = 'MESSAGE_LIST_FAILURE';
export const MESSAGE_READ_REQUEST = 'MESSAGE_READ_REQUEST';
export const MESSAGE_READ_SUCCESS = 'MESSAGE_READ_SUCCESS';
export const MESSAGE_READ_FAILURE = 'MESSAGE_READ_FAILURE';
export const MESSAGE_UPDATE_REQUEST = 'MESSAGE_UPDATE_REQUEST';
export const MESSAGE_UPDATE_SUCCESS = 'MESSAGE_UPDATE_SUCCESS';
export const MESSAGE_UPDATE_FAILURE = 'MESSAGE_UPDATE_FAILURE';
export const MESSAGE_CREATE_REQUEST = 'MESSAGE_CREATE_REQUEST';
export const MESSAGE_CREATE_SUCCESS = 'MESSAGE_CREATE_SUCCESS';
export const MESSAGE_CREATE_FAILURE = 'MESSAGE_CREATE_FAILURE';
export const MESSAGE_DELETE_REQUEST = 'MESSAGE_DELETE_REQUEST';
export const MESSAGE_DELETE_SUCCESS = 'MESSAGE_DELETE_SUCCESS';
export const MESSAGE_DELETE_FAILURE = 'MESSAGE_DELETE_FAILURE';
export const MESSAGE_FORM_DESTROY = 'MESSAGE_FORM_DESTROY';
export const MESSAGE_CSV_DOWNLOAD_REQUEST = 'MESSAGE_CSV_DOWNLOAD_REQUEST';
export const MESSAGE_CSV_DOWNLOAD_SUCCESS = 'MESSAGE_CSV_DOWNLOAD_SUCCESS';
export const MESSAGE_CSV_DOWNLOAD_FAILURE = 'MESSAGE_CSV_DOWNLOAD_FAILURE';

// ACTION CREATORS

export function messageListRequest(page, limit, order, filter) {
  Logger.log('debug', `[messages.actions] messageListRequest(${page}, ${limit}, ${order}, %j)`, filter);
  return {
    type: MESSAGE_LIST_REQUEST,
    page: page,
    limit: limit,
    order: order,
    filter: filter
  }
}

export function messageListSuccess(data) {
  Logger.log('debug', `[messages.actions] messageListSuccess(%j)`, data);
  return {
    type: MESSAGE_LIST_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now()
  }
}

export function messageListFailure(error) {
  Logger.log('debug', `[messages.actions] messageListFailure(%j)`, error);
  return {
    type: MESSAGE_LIST_FAILURE,
    error: error
  }
}

export function messageReadRequest(id) {
  Logger.log('debug', `[messages.actions] messageReadRequest(${id})`);
  return {
    type: MESSAGE_READ_REQUEST,
    id: id
  }
}

export function messageReadSuccess(data) {
  Logger.log('debug', `[messages.actions] messageReadSuccess(%j)`, data);
  return {
    type: MESSAGE_READ_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function messageReadFailure(error) {
  Logger.log('debug', `[messages.actions] messageReadFailure(%j)`, error);
  return {
    type: MESSAGE_READ_FAILURE,
    error: error
  }
}

export function messageUpdateRequest(id, data) {
  Logger.log('debug', `[messages.actions] messageUpdateRequest(${id}, %j)`, data);
  return {
    type: MESSAGE_UPDATE_REQUEST,
  }
}

export function messageUpdateSuccess(data) {
  Logger.log('debug', `[messages.actions] messageUpdateSuccess(%j)`, data);
  return {
    type: MESSAGE_UPDATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function messageUpdateFailure(error) {
  Logger.log('debug', `[messages.actions] messageUpdateFailure(%j)`, error);
  return {
    type: MESSAGE_UPDATE_FAILURE,
    error: error
  }
}

export function messageCreateRequest(data) {
  Logger.log('debug', `[messages.actions] messageCreateRequest(%j)`, data);
  return {
    type: MESSAGE_CREATE_REQUEST,
  }
}

export function messageCreateSuccess(data) {
  Logger.log('debug', `[messages.actions] messageCreateSuccess(%j)`, data);
  return {
    type: MESSAGE_CREATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function messageCreateFailure(error) {
  Logger.log('debug', `[messages.actions] messageCreateFailure(%j)`, error);
  return {
    type: MESSAGE_CREATE_FAILURE,
    error: error
  }
}

export function messageDeleteRequest(id) {
  Logger.log('debug', `[messages.actions] messageDeleteRequest(${id})`);
  return {
    type: MESSAGE_DELETE_REQUEST,
    id: id
  }
}

export function messageDeleteSuccess(id) {
  Logger.log('debug', `[messages.actions] messageDeleteSuccess(${id})`);
  return {
    type: MESSAGE_DELETE_SUCCESS,
    id: id,
  }
}

export function messageDeleteFailure(error) {
  Logger.log('debug', `[messages.actions] messageDeleteFailure(%j)`, error);
  return {
    type: MESSAGE_DELETE_FAILURE,
    error: error
  }
}

export function messageFormDestroy(formState=null) {
  Logger.log('debug', `[messages.actions] messageFormDestroy(%j)`, formState);
  return {
    type: MESSAGE_FORM_DESTROY,
    form: formState
  }
}

export function messageCSVDownloadRequest(order, filter) {
  Logger.log('debug', `[state.messages.actions] messageCSVDownloadRequest()`);
  return {
    type: MESSAGE_CSV_DOWNLOAD_REQUEST,
    order: order,
    filter: filter,
  }
}

export function messageCSVDownloadSuccess() {
  Logger.log('debug', `[state.messages.actions] messageCSVDownloadSuccess()`);
  return {
    type: MESSAGE_CSV_DOWNLOAD_SUCCESS,
  }
}

export function messageCSVDownloadFailure() {
  Logger.log('debug', `[state.messages.actions] messageCSVDownloadFailure()`);
  return {
    type: MESSAGE_CSV_DOWNLOAD_FAILURE,
  }
}


// API THUNK ACTION CREATORS

export function loadMessages(page=1, limit=10, order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[messages.actions] loadMessages(${page}, ${limit}, ${order}, %j, ###)`, filter);

  return async function(dispatch) {
    dispatch(messageListRequest(page, limit, order, filter));

    // call API
    const response = await api.getMessages(page, limit, order, filter);
    let success = false;

    // get messages list success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API messages list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`);

      const normalizedEntities = normalize(response.getIn(['data', 'messages']), [schema.message]);
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(messageListSuccess(data));
      success = true;

    } else if (1 === page && 204 === response.get('status')) {

      Logger.log('info', `Get API messages success [empty]. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: []
      };
      dispatch(messageListSuccess(data));
      success = true;
      
    // get messages list failure
    } else {
      Logger.log('info', `Get API messages list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      dispatch(messageListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function loadMessage(id, cb=function(){}) {
  Logger.log('debug', `[messages.actions] loadMessage(${id}, ###)`);

  return async function(dispatch) {
    dispatch(messageReadRequest(id));

    // call API
    const response = await api.getMessage(id);
    let success = false;

    // get message success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API message success. ID: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'message'])], [schema.message]);
      const data = {
        id: response.getIn(['data', 'message', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(messageReadSuccess(data));
      success = true;
      
    // get message failure
    } else {
      Logger.log('info', `Get API message failure. ID: ${id}.`);
      dispatch(messageReadFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function updateMessage(id, data, cb=function(){}) {
  Logger.log('debug', `[messages.actions] updateMessage(${id}, %j, ###)`, data);

  return async function(dispatch) {
    dispatch(messageUpdateRequest(id, data));

    // call API
    const response = await api.putMessage(id, data);
    let success = false;

    // put message success
    if (200 === response.get('status')) {

      Logger.log('info', `PUT API message success. User: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'message'])], [schema.message]);
      const data = {
        id: response.getIn(['data', 'message', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(messageUpdateSuccess(data));
      success = true;
      
    // get message failure
    } else {
      Logger.log('info', `PUT API message failure. ID: ${id}.`);
      dispatch(messageUpdateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function createMessage(data, cb=function(){}) {
  Logger.log('debug', `[messages.actions] createMessage(%j, ###)`, data);

  return async function(dispatch) {
    dispatch(messageCreateRequest(data));

    // call API
    const response = await api.postMessages(data);
    let success = false;

    // post messages success
    if (201 === response.get('status')) {

      Logger.log('info', `POST API messages success. Post: ${response.getIn(['data', 'message', 'id'])}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'message'])], [schema.message]);
      const data = {
        id: response.getIn(['data', 'message', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(messageCreateSuccess(data));
      success = true;
      
    // get messages failure
    } else {
      Logger.log('info', `POST API messages failure.`);
      dispatch(messageCreateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function deleteMessage(id, cb=function(){}) {
  Logger.log('debug', `[messages.actions] deleteMessage(${id}, ###)`);

  return async function(dispatch) {
    dispatch(messageDeleteRequest(id));

    // call API
    const response = await api.deleteMessage(id);
    let success = false;

    // delete message success
    if (204 === response.get('status')) {

      Logger.log('info', `DELETE API message success. ID: ${id}.`);

      dispatch(removeEntity({entityType: 'messages', id: id}));
      dispatch(messageDeleteSuccess(id));
      success = true;
      
    // get message failure
    } else {
      Logger.log('info', `DELETE API message failure. ID: ${id}.`);
      dispatch(messageDeleteFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function downloadMessagesCSV(order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[state.messages.actions] downloadMessagesCSV(${order}, ${filter}, ###)`);

  return async function(dispatch) {
    dispatch(messageCSVDownloadRequest(order, filter));

    // call API
    const response = await api.getMessagesCSV(order, filter);

    // get messages CSV success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API messages CSV success. Order: ${order}`);
      const now = new Date();

      // trigger browser download
      const url = window.URL.createObjectURL(new Blob([response.get('data')]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `cv-messages-${Format.date(now, 'YYYY-MM-DDTHHmm')}.csv`);
      document.body.appendChild(link);
      link.click();

      dispatch(messageCSVDownloadSuccess());
      
    // get messages CSV failure
    } else {
      Logger.log('info', `Get API messages CSV failure.`);
      dispatch(messageCSVDownloadFailure());
    }

    // callback function
    cb();
  }
}

Logger.log('silly', `messages.actions loaded.`);
