import {connect} from 'react-redux';

import RelationshipAddScreen from '../components/RelationshipAddScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.relationships.get('isLoading'),
    success: state.relationships.getIn(['form', 'success']),
  }
}

const RelationshipAddScreenContainer = connect(
  mapStateToProps
)(RelationshipAddScreen);

export default RelationshipAddScreenContainer;
