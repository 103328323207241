import {connect} from 'react-redux';
import {List} from 'immutable';
import {denormalize} from 'normalizr';

import {schema} from '../../../../../state/schema';
import {
  loadAccount,
  updateAccount,
  createAccount,
  deleteAccount,
  accountFormDestroy
} from '../../../../../state/modules/accounts/actions';
import {sendMessage} from '../../../../../state/actions';
import AccountForm from '../components/AccountForm';

const inputs = List([
  'default_care_guide_limit',
  'helper_limit',
  'upload_gb_limit',
  'owner',
  'plan',
  'template',
  'subscription_cycle',
  'subscription_id',
  'subscription_status',
  'status'
]);

const mapStateToProps = (state, ownProps) => {

  const data = {}, errors = {};
  for (const val of inputs.values()) {
    data[val] = state.entities.getIn(['accounts', ownProps.id, val], '');
    if (['plan'].includes(val)) {
      errors[val] = state.accounts.getIn(['form', 'errors', val + '_id']);
    }
    else {
      errors[val] = state.accounts.getIn(['form', 'errors', val]);
    }
  }

  // change select number values to strings to work with ant design select inputs
  Object.keys(data).forEach(x => {
    if (['status'].includes(x)) {
      data[x] = data[x].toString();
    }
  });

  return {
    isLoading: state.accounts.get('isLoading'),
    isSubmitting: state.accounts.getIn(['form', 'isSubmitting']),
    success: state.accounts.getIn(['form', 'success']),
    created_id: state.accounts.getIn(['form', 'created_id']),
    data: data,
    account: ownProps.id
      ? denormalize(
          state.entities.getIn(['accounts', ownProps.id]),
          schema.account,
          state.entities.toJS()
        )
      : null,
    errors: errors,
    createdAt: state.entities.getIn(['accounts', ownProps.id, 'created_at'], null),
    updatedAt: state.entities.getIn(['accounts', ownProps.id, 'updated_at'], null),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (id, cb) => {
      dispatch(loadAccount(id, cb));
    },
    update: (id, data, cb) => {
      dispatch(updateAccount(id, data, cb));
    },
    create: (data, cb) => {
      dispatch(createAccount(data, cb));
    },
    delete: (id, cb) => {
      dispatch(deleteAccount(id, cb))
    },
    destroyForm: (formState) => {
      dispatch(accountFormDestroy(formState));
    },
    sendMessage: (level, title, body, expires) => {
      dispatch(sendMessage(level, title, body, expires));
    },
  }
}

const AccountFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountForm);

export default AccountFormContainer;
