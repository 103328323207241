import { connect } from 'react-redux';

import CareGuideNoteEditScreen from '../components/CareGuideNoteEditScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.careGuideNotes.get('isLoading'),
    success: state.careGuideNotes.getIn(['form', 'success']),
  }
}

const CareGuideNoteEditScreenContainer = connect(
  mapStateToProps
)(CareGuideNoteEditScreen);

export default CareGuideNoteEditScreenContainer;