import {normalize} from 'normalizr';

import api from '../../api';
import {schema} from '../../schema';
import {addEntities, removeEntity} from '../../actions';
import Logger from '../../../lib/Logger';

export const REGION_LIST_REQUEST = 'REGION_LIST_REQUEST';
export const REGION_LIST_SUCCESS = 'REGION_LIST_SUCCESS';
export const REGION_LIST_FAILURE = 'REGION_LIST_FAILURE';
export const REGION_READ_REQUEST = 'REGION_READ_REQUEST';
export const REGION_READ_SUCCESS = 'REGION_READ_SUCCESS';
export const REGION_READ_FAILURE = 'REGION_READ_FAILURE';
export const REGION_CREATE_FAILURE = 'REGION_CREATE_FAILURE';
export const REGION_CREATE_REQUEST = 'REGION_CREATE_REQUEST';
export const REGION_CREATE_SUCCESS = 'REGION_CREATE_SUCCESS';
export const REGION_DELETE_REQUEST = 'REGION_DELETE_REQUEST';
export const REGION_DELETE_SUCCESS = 'REGION_DELETE_SUCCESS';
export const REGION_DELETE_FAILURE = 'REGION_DELETE_FAILURE';
export const REGION_UPDATE_REQUEST = 'REGION_UPDATE_REQUEST';
export const REGION_UPDATE_SUCCESS = 'REGION_UPDATE_SUCCESS';
export const REGION_UPDATE_FAILURE = 'REGION_UPDATE_FAILURE';
export const REGION_FORM_DESTROY = 'REGION_FORM_DESTROY';

export function regionListRequest(page, limit, order, filter) {
    Logger.log('debug', `[location.actions] regionListRequest(${page}, ${limit})`);
    return {
      type: REGION_LIST_REQUEST,
      page: page,
      limit: limit,
      order: order,
      filter: filter
    }
  }
  
  export function regionListSuccess(data) {
    Logger.log('debug', `[location.actions] regionListSuccess(%j)`, data);
    return {
      type: REGION_LIST_SUCCESS,
      page: data.page,
      limit: data.limit,
      order: data.order,
      result: data.result,
      total: data.total,
      receivedAt: Date.now()
    }
  }
  
  export function regionListFailure(error) {
    Logger.log('debug', `[location.actions] regionListFailure(%j)`, error);
    return {
      type: REGION_LIST_FAILURE,
      error: error
    }
  }
  
  export function regionCreateRequest(data) {
    Logger.log('debug', `[region.actions] regionCreateRequest(%j)`, data);
    return {
      type: REGION_CREATE_REQUEST,
    }
  }
  
  export function regionCreateSuccess(data) {
    Logger.log('debug', `[region.actions] regionCreateSuccess(%j)`, data);
    return {
      type: REGION_CREATE_SUCCESS,
      id: data.id,
      receivedAt: Date.now()
    }
  }
  
  export function regionCreateFailure(error) {
    Logger.log('debug', `[region.actions] regionCreateFailure(%j)`, error);
    return {
      type: REGION_CREATE_FAILURE,
      error: error
    }
  }
  
  export function regionReadRequest(id) {
    Logger.log('debug', `[region.actions] regionReadRequest(${id})`);
    return {
      type: REGION_READ_REQUEST,
      id: id
    }
  }
  
  export function regionReadSuccess(data) {
    Logger.log('debug', `[region.actions] regionReadSuccess(%j)`, data);
    return {
      type: REGION_READ_SUCCESS,
      id: data.id,
      receivedAt: Date.now()
    }
  }
  
  export function regionReadFailure(error) {
    Logger.log('debug', `[region.actions] regionReadFailure(%j)`, error);
    return {
      type: REGION_READ_FAILURE,
      error: error
    }
  }
  
  export function regionUpdateRequest(id, data) {
    Logger.log('debug', `[region.actions] regionUpdateRequest(${id}, %j)`, data);
    return {
      type: REGION_UPDATE_REQUEST,
    }
  }
  
  export function regionUpdateSuccess(data) {
    Logger.log('debug', `[region.actions] regionUpdateSuccess(%j)`, data);
    return {
      type: REGION_UPDATE_SUCCESS,
      id: data.id,
      receivedAt: Date.now()
    }
  }
  
  export function regionUpdateFailure(error) {
    Logger.log('debug', `[region.actions] regionUpdateFailure(%j)`, error);
    return {
      type: REGION_UPDATE_FAILURE,
      error: error
    }
  }
  
  export function  regionDeleteRequest(id) {
    Logger.log('debug', `[ region.actions] regionDeleteRequest(${id})`);
    return {
      type: REGION_DELETE_REQUEST,
      id: id
    }
  }
  
  export function regionDeleteSuccess(id) {
    Logger.log('debug', `[region.actions] regionDeleteSuccess(${id})`);
    return {
      type: REGION_DELETE_SUCCESS,
      id: id,
    }
  }
  
  export function regionDeleteFailure(error) {
    Logger.log('debug', `[region.actions] regionDeleteFailure(%j)`, error);
    return {
      type: REGION_DELETE_FAILURE,
      error: error
    }
  }
  
  export function regionFormDestroy(formState=null) {
    Logger.log('debug', `[region.actions] regionFormDestroy(%j)`, formState);
    return {
      type: REGION_FORM_DESTROY,
      form: formState
    }
  }

  // API THUNK ACTION CREATORS

export function loadRegions(page=1, limit=250,order=null, filter=null, cb=function(){}) {
    Logger.log('debug', `[location.actions] loadRegions(${page}, ${limit}, ###)`);
  
    return async function(dispatch) {
      dispatch(regionListRequest(page, limit, order, filter));
  
      // call API
      const response = await api.getRegions(page, limit, order, filter);
  
      // get regions list success
      if (200 === response.get('status')) {
  
        Logger.log('info', `Get API regions list success. Page: ${page}, Limit: ${limit}.  Order: ${order}.`);
  
        const normalizedRegions = normalize(response.getIn(['data', 'regions']), [schema.region]);
        const regionSuccessData = {
          page: response.getIn(['data', 'page']),
          limit: response.getIn(['data', 'limit']),
          order: order,
          total: response.getIn(['data', 'total']),
          result: normalizedRegions.result
        };
        dispatch(addEntities(normalizedRegions));
        dispatch(regionListSuccess(regionSuccessData));
        
      // get regions list failure
      } else {
        Logger.log('info', `Get API regions list failure. Page: ${page}, Limit: ${limit}.`);
        dispatch(regionListFailure(response.getIn(['data', 'error'])));
      }
  
      // callback function
      cb();
    }
  }
  
  export function loadRegion(id, cb=function(){}) {
    Logger.log('debug', `[region.actions] loadRegion(${id}, ###)`);
  
    return async function(dispatch) {
      dispatch(regionReadRequest(id));
  
      // call API
      const response = await api.getRegion(id);
      let success = false;
  
      // get region success
      if (200 === response.get('status')) {
  
        Logger.log('info', `Get API region success. ID: ${id}.`);
  
        const normalizedEntities = normalize([response.getIn(['data', 'region'])], [schema.region]);
        const data = {
          id: response.getIn(['data', 'region', 'id']),
        };
  
        dispatch(addEntities(normalizedEntities));
        dispatch(regionReadSuccess(data));
        success = true;
        
      // get region failure
      } else {
        Logger.log('info', `Get API region failure. ID: ${id}.`);
        dispatch(regionReadFailure(response.getIn(['data', 'error'])));
      }
  
      // callback function
      cb(success);
    }
  }
  
  export function createRegion(data, cb=function(){}) {
    Logger.log('debug', `[region.actions] createRegion(%j, ###)`, data);
  
    return async function(dispatch) {
      dispatch(regionCreateRequest(data));
  
      // call API
      const response = await api.postRegion(data);
      let success = false;
  
      // post region success
      if (201 === response.get('status')) {
  
        Logger.log('info', `POST API region success. Post: ${response.getIn(['data', 'region', 'id'])}.`);
  
        const normalizedEntities = normalize([response.getIn(['data', 'region'])], [schema.region]);
        const data = {
          id: response.getIn(['data', 'region', 'id']),
        };
  
        dispatch(addEntities(normalizedEntities));
        dispatch(regionCreateSuccess(data));
        success = true;
        
      // get regions failure
      } else {
        Logger.log('info', `POST API region failure.`);
        dispatch(regionCreateFailure(response.getIn(['data', 'error'])));
      }
  
      // callback function
      cb(success);
    }
  }
  
  export function updateRegion(id, data, cb=function(){}) {
    Logger.log('debug', `[region.actions] updateRegion(${id}, %j, ###)`, data);
  
    return async function(dispatch) {
      dispatch(regionUpdateRequest(id, data));
  
      // call API
      const response = await api.putRegion(id, data);
      let success = false;
  
      // put region success
      if (200 === response.get('status')) {
  
        Logger.log('info', `PUT API region success. User: ${id}.`);
  
        const normalizedEntities = normalize([response.getIn(['data', 'region'])], [schema.region]);
        const data = {
          id: response.getIn(['data', 'region', 'id']),
        };
  
        dispatch(addEntities(normalizedEntities));
        dispatch(regionUpdateSuccess(data));
        success = true;
        
      // get region failure
      } else {
        Logger.log('info', `PUT API region failure. ID: ${id}.`);
        dispatch(regionUpdateFailure(response.getIn(['data', 'error'])));
      }
  
      // callback function
      cb(success);
    }
  }
  
  export function deleteRegion(id, cb=function(){}) {
    Logger.log('debug', `[locations.actions] deleteRegion(${id}, ###)`);
  
    return async function(dispatch) {
      dispatch(regionDeleteRequest(id));
  
      // call API
      const response = await api.deleteRegion(id);
      let success = false;
  
      // delete region post success
      if (204 === response.get('status')) {
  
        Logger.log('info', `DELETE API region success. ID: ${id}.`);
  
        dispatch(removeEntity({entityType: 'region', id: id}));
        dispatch(regionDeleteSuccess(id));
        success = true;
        
      // get region failure
      } else {
        Logger.log('info', `DELETE API region post failure. ID: ${id}.`);
        dispatch(regionDeleteFailure(response.getIn(['data', 'error'])));
      }
  
      // callback function
      cb(success);
    }
  }