import { connect } from 'react-redux';
import { denormalize } from 'normalizr';

import { schema } from '../../../../../state/schema';
import { loadCareGuideMembers } from '../../../../../state/modules/careGuideHelpers/actions';
import MembersInput from '../components/MembersInput';

const mapStateToProps = (state, ownProps) => {

  const result = state.careGuideHelpers.getIn(['members', 'pages']);

  const members = result
    ? result
        .map((x) => {
          return {
            key: x,
            ...denormalize(
              state.entities.getIn(['careGuideMembers', x]),
              schema.careGuideMember,
              state.entities.toJS()
            ),
          };
        })
        .toArray()
    : [];

  return {
    options: members.map((x) => {
      return {
        label: x.profile ?  `${x.profile.first_name} - ${x.profile.last_name}`  : '',
        value: x.profile ? x.profile.id : '',
      };
    }),
    isLoading: state.journalTags.get('areMembersLoading'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (filter, cb) => {
      dispatch(
        loadCareGuideMembers(filter, cb)
      );
    },
  };
};

const MembersInputContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MembersInput);

export default MembersInputContainer;
