import { connect } from 'react-redux';
import { denormalize } from 'normalizr';

import { schema } from '../../../../../state/schema';
import {
  loadMedia,
  deleteMedium,
} from '../../../../../state/modules/media/actions';
import { sendMessage } from '../../../../../state/actions';
import MediaList from '../components/MediaList';

const mapStateToProps = (state, ownProps) => {
  const page = ownProps.page;
  const limit = ownProps.limit;
  const order = ownProps.order;
  const result = state.media.getIn(['pages', order, limit, page]);

  return {
    list: result
      ? result
          .map((x) => {
            return {
              key: x,
              ...denormalize(
                state.entities.getIn(['media', x]),
                schema.medium,
                state.entities.toJS()
              ),
            };
          })
          .toArray()
          .map((x) => {
            // flatten some nested objects to make table easier to work with
            return {
              care_guide_name:
                x?.care_guide?.first_name && x?.care_guide?.last_name
                  ? `${x?.care_guide?.first_name} ${x?.care_guide?.last_name}`
                  : '',
              care_guide_id: x.care_guide ? x.care_guide.id : '',
              partnership_id: x.partnership ? x.partnership.id : '',
              owner_id: x.owner ? x.owner.id : '',
              owner_username:
                x.owner && x.owner.user ? x.owner.user.username : '',
              ...x,
            };
          })
      : [],
    isLoading: state.media.get('isLoading'),
    total: state.media.get('total'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (page, limit, order, filter, cb) => {
      dispatch(loadMedia(page, limit, order, filter, cb));
    },
    remove: (id, cb) => {
      dispatch(deleteMedium(id, cb));
    },
    sendMessage: (level, title, body, expires) => {
      dispatch(sendMessage(level, title, body, expires));
    },
  };
};

const MediaListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MediaList);

export default MediaListContainer;
