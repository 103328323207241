import {connect} from 'react-redux';

import {loadCareGuideSections} from '../../../../../state/modules/careGuideSections/actions';
import CareGuideSectionInput from '../components/CareGuideSectionInput';

const mapStateToProps = (state) => {
  const careGuideSections = state.entities.get('careGuideSections', {});
  return {
    options: Object.keys(careGuideSections).map(id => {return {label: careGuideSections[id].name, value: id}}),
    isLoading: state.careGuideSections.get('isLoading'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (page, limit, order, filter, cb) => {
      dispatch(loadCareGuideSections(page, limit, order, filter, cb));
    },
  }
}

const CareGuideSectionInputContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CareGuideSectionInput);

export default CareGuideSectionInputContainer;
