import React, { useState } from 'react';
import message from '../../../elements/lib/MessageWrapper';
import Logger from '../../../../../lib/Logger';
import { Translation, getI18n } from 'react-i18next';
import { Space, Button, Form, Radio } from 'antd';
import { Modal, Typography } from 'antd';
import PartnershipInput from '../containers/PartnershipInputContainer';

const { Text } = Typography;

const layout = {
  main: {
    labelCol: { span: 5 },
    wrapperCol: { span: 19 },
  },
};

const PartnershipModalExport = ({
  openModalExport,
  setOpenModalExport,
  isDownloading,
  total,
  optionExport,
  setOptionExport,
  submitData,
  options,
  labels,
  ...props
}) => {
  const [partnershipId, setPartnershipId] = useState(null);
  const [form] = Form.useForm();

  const onClickOK = () => {
    form.submit();
  };

  // form submit handler
  const handleFinish = async (values) => {
    Logger.log('debug', `PartnershipModalExport.handleFinish(###)`);
    if (!isDownloading) {
      await submitData(values, partnershipId, clearForm);
    }
  };

  // form error handler
  const handleFinishFailed = () => {
    Logger.log('debug', `PartnershipModalExport.handleFinishFailed(###)`);
    message.error(getI18n().t('partnership_message_export_failure'));
  };

  const clearForm = () => {
    setOpenModalExport(false);
    form.setFieldsValue({
      option_export: 1,
      partnership: null,
    });
    setOptionExport(1);
    setPartnershipId(null);
  };

  return (
    <Translation>
      {(t) => (
        <>
          <Modal
            title={t(labels?.title)}
            className="modal-export"
            centered
            visible={openModalExport}
            onCancel={clearForm}
            footer={[
              <Button
                key="back"
                onClick={clearForm}
                disabled={
                  total < 1 ||
                  (!partnershipId && optionExport === 2) || isDownloading
                }
              >
                {t(labels?.title_btn_cancel)}
              </Button>,
              <Button
                key="submit"
                type="primary"
                loading={isDownloading}
                disabled={
                  total < 1 ||
                  (!partnershipId && optionExport === 2)
                }
                onClick={onClickOK}
              >
                {t(labels?.title_btn_export)}
              </Button>,
            ]}
          >
            <Form
              name="validate_other"
              {...layout.main}
              initialValues={{
                option_export: 1,
              }}
              form={form}
              onFinish={handleFinish}
              onFinishFailed={handleFinishFailed}
              validateTrigger="onSubmit"
              requiredMark={false}
              layout="vertical"
            >
              <div className="ant-col ant-col-24 ant-form-item-label">
                <label
                  className="ant-form-item-required export-care-guides-options-title"
                  title="options-title"
                >
                  {t(labels?.title_list_export)}
                </label>
                <Text disabled>({t('required')})</Text>
              </div>

              <Form.Item name="option_export">
                <Radio.Group
                  size={'large'}
                  onChange={(e) => setOptionExport(e.target.value)}
                >
                  <Space direction="vertical">
                    {options.map(option => (
                      <Radio value={option.value} key={option.value} disabled={option.total}>
                        {t(option.label)}
                       </Radio>
                    ))}
                  </Space>
                </Radio.Group>
              </Form.Item>
              {optionExport === 2 && (
                <PartnershipInput
                  form={form}
                  name="partnership"
                  label={t(labels?.title_partnership_input)}
                  disabled={isDownloading}
                  allowNone={false}
                  onChange={(e) => setPartnershipId(e)}
                />
              )}
              <div style={{ display: 'none' }}>
                <Button htmlType="submit"></Button>
              </div>
            </Form>
          </Modal>
        </>
      )}
    </Translation>
  );
};

export default PartnershipModalExport;
