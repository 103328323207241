import {connect} from 'react-redux';

import ResourceServiceTagAddScreen from '../components/ResourceServiceTagAddScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.resourceServiceTags.get('isLoading'),
    success: state.resourceServiceTags.getIn(['form', 'success']),
  }
}

const ResourceServiceTagAddScreenContainer = connect(
  mapStateToProps
)(ResourceServiceTagAddScreen);

export default ResourceServiceTagAddScreenContainer;
