import {connect} from 'react-redux';

import ResourceNoteAddScreen from '../components/ResourceNoteAddScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.resourceNotes.get('isLoading'),
    success: state.resourceNotes.getIn(['form', 'success']),
  }
}

const ResourceNoteAddScreenContainer = connect(
  mapStateToProps
)(ResourceNoteAddScreen);

export default ResourceNoteAddScreenContainer;
