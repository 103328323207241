import {connect} from 'react-redux';

import JournalReplyAddScreen from '../components/JournalReplyAddScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.journalReplies.get('isLoading'),
    success: state.journalReplies.getIn(['form', 'success']),
  }
}

const JournalReplyAddScreenContainer = connect(
  mapStateToProps
)(JournalReplyAddScreen);

export default JournalReplyAddScreenContainer;
