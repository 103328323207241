import {connect} from 'react-redux';

import AccountsScreen from '../components/AccountsScreen';

const mapStateToProps = state => {
  return {
    total: state.accounts.get('total'),
    isLoading: state.accounts.get('isLoading')
  }
}

const AccountsScreenContainer = connect(
  mapStateToProps
)(AccountsScreen);

export default AccountsScreenContainer;
