import { connect } from 'react-redux';

import CareGuideDocumentsCSVButton from '../components/CareGuideDocumentsCSVButton';

const mapStateToProps = (state) => {
  return {};
};

const CareGuideDocumentsCSVButtonContainer = connect(mapStateToProps)(
  CareGuideDocumentsCSVButton
);

export default CareGuideDocumentsCSVButtonContainer;
