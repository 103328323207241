import {connect} from 'react-redux';

import ResourceAddScreen from '../components/ResourceAddScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.resources.get('isLoading'),
    success: state.resources.getIn(['form', 'success']),
  }
}

const ResourceAddScreenContainer = connect(
  mapStateToProps
)(ResourceAddScreen);

export default ResourceAddScreenContainer;
