import { normalize } from 'normalizr';

import api from '../../api';
import { schema } from '../../schema';
import { addEntities, removeEntity } from '../../actions';
import Format from '../../../lib/Format';
import Logger from '../../../lib/Logger';

// ACTION TYPES

export const CARE_GUIDE_INVITATION_LIST_REQUEST =
  'CARE_GUIDE_INVITATION_LIST_REQUEST';
export const CARE_GUIDE_INVITATION_LIST_SUCCESS =
  'CARE_GUIDE_INVITATION_LIST_SUCCESS';
export const CARE_GUIDE_INVITATION_LIST_FAILURE =
  'CARE_GUIDE_INVITATION_LIST_FAILURE';
export const CARE_GUIDE_INVITATION_READ_REQUEST =
  'CARE_GUIDE_INVITATION_READ_REQUEST';
export const CARE_GUIDE_INVITATION_READ_SUCCESS =
  'CARE_GUIDE_INVITATION_READ_SUCCESS';
export const CARE_GUIDE_INVITATION_READ_FAILURE =
  'CARE_GUIDE_INVITATION_READ_FAILURE';
export const CARE_GUIDE_INVITATION_UPDATE_REQUEST =
  'CARE_GUIDE_INVITATION_UPDATE_REQUEST';
export const CARE_GUIDE_INVITATION_UPDATE_SUCCESS =
  'CARE_GUIDE_INVITATION_UPDATE_SUCCESS';
export const CARE_GUIDE_INVITATION_UPDATE_FAILURE =
  'CARE_GUIDE_INVITATION_UPDATE_FAILURE';
export const CARE_GUIDE_INVITATION_CREATE_REQUEST =
  'CARE_GUIDE_INVITATION_CREATE_REQUEST';
export const CARE_GUIDE_INVITATION_CREATE_SUCCESS =
  'CARE_GUIDE_INVITATION_CREATE_SUCCESS';
export const CARE_GUIDE_INVITATION_CREATE_FAILURE =
  'CARE_GUIDE_INVITATION_CREATE_FAILURE';
export const CARE_GUIDE_INVITATION_DELETE_REQUEST =
  'CARE_GUIDE_INVITATION_DELETE_REQUEST';
export const CARE_GUIDE_INVITATION_DELETE_SUCCESS =
  'CARE_GUIDE_INVITATION_DELETE_SUCCESS';
export const CARE_GUIDE_INVITATION_DELETE_FAILURE =
  'CARE_GUIDE_INVITATION_DELETE_FAILURE';
export const CARE_GUIDE_INVITATION_FORM_DESTROY =
  'CARE_GUIDE_INVITATION_FORM_DESTROY';
export const CARE_GUIDE_INVITATION_CSV_DOWNLOAD_REQUEST =
  'CARE_GUIDE_INVITATION_CSV_DOWNLOAD_REQUEST';
export const CARE_GUIDE_INVITATION_CSV_DOWNLOAD_SUCCESS =
  'CARE_GUIDE_INVITATION_CSV_DOWNLOAD_SUCCESS';
export const CARE_GUIDE_INVITATION_CSV_DOWNLOAD_FAILURE =
  'CARE_GUIDE_INVITATION_CSV_DOWNLOAD_FAILURE';

// ACTION CREATORS

export function careGuideInvitationListRequest(page, limit, order, filter) {
  Logger.log(
    'debug',
    `[careGuideInvitations.actions] careGuideInvitationListRequest(${page}, ${limit}, ${order}, %j)`,
    filter
  );
  return {
    type: CARE_GUIDE_INVITATION_LIST_REQUEST,
    page: page,
    limit: limit,
    order: order,
    filter: filter,
  };
}

export function careGuideInvitationListSuccess(data) {
  Logger.log(
    'debug',
    `[careGuideInvitations.actions] careGuideInvitationListSuccess(%j)`,
    data
  );
  return {
    type: CARE_GUIDE_INVITATION_LIST_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now(),
  };
}

export function careGuideInvitationListFailure(error) {
  Logger.log(
    'debug',
    `[careGuideInvitations.actions] careGuideInvitationListFailure(%j)`,
    error
  );
  return {
    type: CARE_GUIDE_INVITATION_LIST_FAILURE,
    error: error,
  };
}

export function careGuideInvitationReadRequest(id) {
  Logger.log(
    'debug',
    `[careGuideInvitations.actions] careGuideInvitationReadRequest(${id})`
  );
  return {
    type: CARE_GUIDE_INVITATION_READ_REQUEST,
    id: id,
  };
}

export function careGuideInvitationReadSuccess(data) {
  Logger.log(
    'debug',
    `[careGuideInvitations.actions] careGuideInvitationReadSuccess(%j)`,
    data
  );
  return {
    type: CARE_GUIDE_INVITATION_READ_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function careGuideInvitationReadFailure(error) {
  Logger.log(
    'debug',
    `[careGuideInvitations.actions] careGuideInvitationReadFailure(%j)`,
    error
  );
  return {
    type: CARE_GUIDE_INVITATION_READ_FAILURE,
    error: error,
  };
}

export function careGuideInvitationUpdateRequest(id, data) {
  Logger.log(
    'debug',
    `[careGuideInvitations.actions] careGuideInvitationUpdateRequest(${id}, %j)`,
    data
  );
  return {
    type: CARE_GUIDE_INVITATION_UPDATE_REQUEST,
  };
}

export function careGuideInvitationUpdateSuccess(data) {
  Logger.log(
    'debug',
    `[careGuideInvitations.actions] careGuideInvitationUpdateSuccess(%j)`,
    data
  );
  return {
    type: CARE_GUIDE_INVITATION_UPDATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function careGuideInvitationUpdateFailure(error) {
  Logger.log(
    'debug',
    `[careGuideInvitations.actions] careGuideInvitationUpdateFailure(%j)`,
    error
  );
  return {
    type: CARE_GUIDE_INVITATION_UPDATE_FAILURE,
    error: error,
  };
}

export function careGuideInvitationCreateRequest(data) {
  Logger.log(
    'debug',
    `[careGuideInvitations.actions] careGuideInvitationCreateRequest(%j)`,
    data
  );
  return {
    type: CARE_GUIDE_INVITATION_CREATE_REQUEST,
  };
}

export function careGuideInvitationCreateSuccess(data) {
  Logger.log(
    'debug',
    `[careGuideInvitations.actions] careGuideInvitationCreateSuccess(%j)`,
    data
  );
  return {
    type: CARE_GUIDE_INVITATION_CREATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function careGuideInvitationCreateFailure(error) {
  Logger.log(
    'debug',
    `[careGuideInvitations.actions] careGuideInvitationCreateFailure(%j)`,
    error
  );
  return {
    type: CARE_GUIDE_INVITATION_CREATE_FAILURE,
    error: error,
  };
}

export function careGuideInvitationDeleteRequest(id) {
  Logger.log(
    'debug',
    `[careGuideInvitations.actions] careGuideInvitationDeleteRequest(${id})`
  );
  return {
    type: CARE_GUIDE_INVITATION_DELETE_REQUEST,
    id: id,
  };
}

export function careGuideInvitationDeleteSuccess(id) {
  Logger.log(
    'debug',
    `[careGuideInvitations.actions] careGuideInvitationDeleteSuccess(${id})`
  );
  return {
    type: CARE_GUIDE_INVITATION_DELETE_SUCCESS,
    id: id,
  };
}

export function careGuideInvitationDeleteFailure(error) {
  Logger.log(
    'debug',
    `[careGuideInvitations.actions] careGuideInvitationDeleteFailure(%j)`,
    error
  );
  return {
    type: CARE_GUIDE_INVITATION_DELETE_FAILURE,
    error: error,
  };
}

export function careGuideInvitationFormDestroy(formState = null) {
  Logger.log(
    'debug',
    `[careGuideInvitations.actions] careGuideInvitationFormDestroy(%j)`,
    formState
  );
  return {
    type: CARE_GUIDE_INVITATION_FORM_DESTROY,
    form: formState,
  };
}

export function careGuideInvitationCSVDownloadRequest(order, filter) {
  Logger.log(
    'debug',
    `[state.careGuideInvitations.actions] careGuideInvitationCSVDownloadRequest()`
  );
  return {
    type: CARE_GUIDE_INVITATION_CSV_DOWNLOAD_REQUEST,
    order: order,
    filter: filter,
  };
}

export function careGuideInvitationCSVDownloadSuccess() {
  Logger.log(
    'debug',
    `[state.careGuideInvitations.actions] careGuideInvitationCSVDownloadSuccess()`
  );
  return {
    type: CARE_GUIDE_INVITATION_CSV_DOWNLOAD_SUCCESS,
  };
}

export function careGuideInvitationCSVDownloadFailure() {
  Logger.log(
    'debug',
    `[state.careGuideInvitations.actions] careGuideInvitationCSVDownloadFailure()`
  );
  return {
    type: CARE_GUIDE_INVITATION_CSV_DOWNLOAD_FAILURE,
  };
}

// API THUNK ACTION CREATORS

export function loadCareGuideInvitations(
  page = 1,
  limit = 10,
  order = null,
  filter = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[careGuideInvitations.actions] loadCareGuideInvitations(${page}, ${limit}, ${order}, %j, ###)`,
    filter
  );

  return async function (dispatch) {
    dispatch(careGuideInvitationListRequest(page, limit, order, filter));

    // call API
    const response = await api.getCareGuideInvitations(
      page,
      limit,
      order,
      filter
    );
    let success = false;

    // get care guide invitations list success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API care guide invitations list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );

      const normalizedEntities = normalize(
        response.getIn(['data', 'care_guide_invitations']),
        [schema.careGuideInvitation]
      );
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result,
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideInvitationListSuccess(data));
      success = true;
    } else if (1 === page && 204 === response.get('status')) {
      Logger.log(
        'info',
        `Get API care guide invitations success [empty]. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: [],
      };
      dispatch(careGuideInvitationListSuccess(data));
      success = true;

      // get care guide invitations list failure
    } else {
      Logger.log(
        'info',
        `Get API care guide invitations list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      dispatch(
        careGuideInvitationListFailure(response.getIn(['data', 'error']))
      );
    }

    // callback function
    cb(success);
  };
}

export function loadCareGuideInvitation(id, cb = function () {}) {
  Logger.log(
    'debug',
    `[careGuideInvitations.actions] loadCareGuideInvitation(${id}, ###)`
  );

  return async function (dispatch) {
    dispatch(careGuideInvitationReadRequest(id));

    // call API
    const response = await api.getCareGuideInvitation(id);
    let success = false;

    // get care guide invitation success
    if (200 === response.get('status')) {
      Logger.log('info', `Get API care guide invitation success. ID: ${id}.`);

      const normalizedEntities = normalize(
        [response.getIn(['data', 'care_guide_invitation'])],
        [schema.careGuideInvitation]
      );
      const data = {
        id: response.getIn(['data', 'care_guide_invitation', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideInvitationReadSuccess(data));
      success = true;

      // get care guide invitation failure
    } else {
      Logger.log('info', `Get API care guide invitation failure. ID: ${id}.`);
      dispatch(
        careGuideInvitationReadFailure(response.getIn(['data', 'error']))
      );
    }

    // callback function
    cb(success);
  };
}

export function updateCareGuideInvitation(id, data, cb = function () {}) {
  Logger.log(
    'debug',
    `[careGuideInvitations.actions] updateCareGuideInvitation(${id}, %j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(careGuideInvitationUpdateRequest(id, data));

    // call API
    const response = await api.putCareGuideInvitation(id, data);
    let success = false;

    // put care guide invitation success
    if (200 === response.get('status')) {
      Logger.log('info', `PUT API care guide invitation success. User: ${id}.`);

      const normalizedEntities = normalize(
        [response.getIn(['data', 'care_guide_invitation'])],
        [schema.careGuideInvitation]
      );
      const data = {
        id: response.getIn(['data', 'care_guide_invitation', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideInvitationUpdateSuccess(data));
      success = true;

      // get care guide invitation failure
    } else {
      Logger.log('info', `PUT API care guide invitation failure. ID: ${id}.`);
      dispatch(
        careGuideInvitationUpdateFailure(response.getIn(['data', 'error']))
      );
    }

    // callback function
    cb(success);
  };
}

export function createCareGuideInvitation(data, cb = function () {}) {
  Logger.log(
    'debug',
    `[careGuideInvitations.actions] createCareGuideInvitation(%j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(careGuideInvitationCreateRequest(data));

    // call API
    const response = await api.postCareGuideInvitations(data);
    let success = false;

    // post care guide invitations success
    if (201 === response.get('status')) {
      Logger.log(
        'info',
        `POST API care guide invitations success. Post: ${response.getIn([
          'data',
          'care_guide_invitation',
          'id',
        ])}.`
      );

      const normalizedEntities = normalize(
        [response.getIn(['data', 'care_guide_invitation'])],
        [schema.careGuideInvitation]
      );
      const data = {
        id: response.getIn(['data', 'care_guide_invitation', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideInvitationCreateSuccess(data));
      success = true;

      // get care guide invitations failure
    } else {
      Logger.log('info', `POST API care guide invitations failure.`);
      dispatch(
        careGuideInvitationCreateFailure(response.getIn(['data', 'error']))
      );
    }

    // callback function
    cb(success);
  };
}

export function deleteCareGuideInvitation(id, cb = function () {}) {
  Logger.log(
    'debug',
    `[careGuideInvitations.actions] deleteCareGuideInvitation(${id}, ###)`
  );

  return async function (dispatch) {
    dispatch(careGuideInvitationDeleteRequest(id));

    // call API
    const response = await api.deleteCareGuideInvitation(id);
    let success = false;

    // delete care guide invitation success
    if (204 === response.get('status')) {
      Logger.log(
        'info',
        `DELETE API care guide invitation success. ID: ${id}.`
      );

      dispatch(removeEntity({ entityType: 'care_guide_invitations', id: id }));
      dispatch(careGuideInvitationDeleteSuccess(id));
      success = true;

      // get care guide invitation failure
    } else {
      Logger.log(
        'info',
        `DELETE API care guide invitation failure. ID: ${id}.`
      );
      dispatch(
        careGuideInvitationDeleteFailure(response.getIn(['data', 'error']))
      );
    }

    // callback function
    cb(success);
  };
}

export function downloadCareGuideInvitationssCSV(
  order = null,
  filter = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.careGuideInvitations.actions] downloadCareGuideInvitationssCSV(${order}, ${filter}, ###)`
  );

  return async function (dispatch) {
    dispatch(careGuideInvitationCSVDownloadRequest(order, filter));

    // call API
    const response = await api.getCareGuideInvitationsCSV(order, filter);
    let success = false;

    // get care guide invitations CSV success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API care guide invitations CSV success. Order: ${order}`
      );
      const now = new Date();

      // trigger browser download
      const url = window.URL.createObjectURL(new Blob([response.get('data')]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `cv-care-guide-invitations-${Format.date(now, 'YYYY-MM-DDTHHmm')}.csv`
      );
      document.body.appendChild(link);
      link.click();

      dispatch(careGuideInvitationCSVDownloadSuccess());
      success = true;

      // get care guide invitations CSV failure
    } else {
      Logger.log('info', `Get API care guide invitations CSV failure.`);
      dispatch(careGuideInvitationCSVDownloadFailure());
    }

    // callback function
    cb(success);
  };
}

Logger.log('silly', `careGuideInvitations.actions loaded.`);
