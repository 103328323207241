import React from 'react';
import {Translation} from 'react-i18next';
import {Tag} from 'antd';


const SubscriptionStatusTag = ({status, ...props}) => {

  const labels = {
    0: "accounts_input_subscription_status_none",
    1: "accounts_input_subscription_status_active",
    2: "accounts_input_subscription_status_pending",
    3: "accounts_input_subscription_status_canceled",
    4: "accounts_input_subscription_status_failed"
  };

  const colors = {
    0: "default",
    1: "success",
    2: "processing",
    3: "warning",
    4: "error",
  };

  return (
    <Translation>{(t) =>
      <Tag color={colors[status]}>{t(labels[status])}</Tag>
    }</Translation>
  )
}

export default SubscriptionStatusTag;
