import {connect} from 'react-redux';

import {loadSubRegions} from '../../../../../../state/modules/locationSubRegions/actions';
import SubregionInput from '../components/SubregionInput';

const mapStateToProps = (state, ownProps) => {
  const regionIds = ownProps['region_id']
    ? [parseInt(ownProps['region_id'])]
    : (ownProps['region_ids'] ? ownProps['region_ids'].split(',').map(x => parseInt(x)) : []);
  const subregions = state.entities.get('subregions', {});
  return {
    options: Object.keys(subregions)
      .filter(id => subregions[id].region && regionIds.includes(subregions[id].region))
      .map(id => {return {label: subregions[id].name, value: id}}),
    isLoading: state.locationSubRegions.get('areSubRegionsLoading'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (page, limit, order, filter, cb) => {
      dispatch(loadSubRegions(page, limit, order, filter, cb));
    },
  }
}

const SubRegionInputContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SubregionInput);

export default SubRegionInputContainer;
