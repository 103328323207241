import { normalize } from 'normalizr';

import api from '../../api';
import { schema } from '../../schema';
import { addEntities, removeEntity } from '../../actions';
import Logger from '../../../lib/Logger';

// ACTION TYPES

export const DASHBOARD_SECTION_LIST_REQUEST = 'DASHBOARD_SECTION_LIST_REQUEST';
export const DASHBOARD_SECTION_LIST_SUCCESS = 'DASHBOARD_SECTION_LIST_SUCCESS';
export const DASHBOARD_SECTION_LIST_FAILURE = 'DASHBOARD_SECTION_LIST_FAILURE';
export const DASHBOARD_SECTION_READ_REQUEST = 'DASHBOARD_SECTION_READ_REQUEST';
export const DASHBOARD_SECTION_READ_SUCCESS = 'DASHBOARD_SECTION_READ_SUCCESS';
export const DASHBOARD_SECTION_READ_FAILURE = 'DASHBOARD_SECTION_READ_FAILURE';
export const DASHBOARD_SECTION_UPDATE_REQUEST =
  'DASHBOARD_SECTION_UPDATE_REQUEST';
export const DASHBOARD_SECTION_UPDATE_SUCCESS =
  'DASHBOARD_SECTION_UPDATE_SUCCESS';
export const DASHBOARD_SECTION_UPDATE_FAILURE =
  'DASHBOARD_SECTION_UPDATE_FAILURE';
export const DASHBOARD_SECTION_CREATE_REQUEST =
  'DASHBOARD_SECTION_CREATE_REQUEST';
export const DASHBOARD_SECTION_CREATE_SUCCESS =
  'DASHBOARD_SECTION_CREATE_SUCCESS';
export const DASHBOARD_SECTION_CREATE_FAILURE =
  'DASHBOARD_SECTION_CREATE_FAILURE';
export const DASHBOARD_SECTION_DELETE_REQUEST =
  'DASHBOARD_SECTION_DELETE_REQUEST';
export const DASHBOARD_SECTION_DELETE_SUCCESS =
  'DASHBOARD_SECTION_DELETE_SUCCESS';
export const DASHBOARD_SECTION_DELETE_FAILURE =
  'DASHBOARD_SECTION_DELETE_FAILURE';
export const DASHBOARD_SECTION_FORM_DESTROY = 'DASHBOARD_SECTION_FORM_DESTROY';

// ACTION CREATORS

export function dashboardSectionListRequest(page, limit, order, filter) {
  Logger.log(
    'debug',
    `[dashboardSections.actions] dashboardSectionListRequest(${page}, ${limit}, ${order}, %j)`,
    filter
  );
  return {
    type: DASHBOARD_SECTION_LIST_REQUEST,
    page: page,
    limit: limit,
    order: order,
    filter: filter,
  };
}

export function dashboardSectionListSuccess(data) {
  Logger.log(
    'debug',
    `[dashboardSections.actions] dashboardSectionListSuccess(%j)`,
    data
  );
  return {
    type: DASHBOARD_SECTION_LIST_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now(),
  };
}

export function dashboardSectionListFailure(error) {
  Logger.log(
    'debug',
    `[dashboardSections.actions] dashboardSectionListFailure(%j)`,
    error
  );
  return {
    type: DASHBOARD_SECTION_LIST_FAILURE,
    error: error,
  };
}

export function dashboardSectionReadRequest(id) {
  Logger.log(
    'debug',
    `[dashboardSections.actions] dashboardSectionReadRequest(${id})`
  );
  return {
    type: DASHBOARD_SECTION_READ_REQUEST,
    id: id,
  };
}

export function dashboardSectionReadSuccess(data) {
  Logger.log(
    'debug',
    `[dashboardSections.actions] dashboardSectionReadSuccess(%j)`,
    data
  );
  return {
    type: DASHBOARD_SECTION_READ_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function dashboardSectionReadFailure(error) {
  Logger.log(
    'debug',
    `[dashboardSections.actions] dashboardSectionReadFailure(%j)`,
    error
  );
  return {
    type: DASHBOARD_SECTION_READ_FAILURE,
    error: error,
  };
}

export function dashboardSectionUpdateRequest(id, data) {
  Logger.log(
    'debug',
    `[dashboardSections.actions] dashboardSectionUpdateRequest(${id}, %j)`,
    data
  );
  return {
    type: DASHBOARD_SECTION_UPDATE_REQUEST,
  };
}

export function dashboardSectionUpdateSuccess(data) {
  Logger.log(
    'debug',
    `[dashboardSections.actions] dashboardSectionUpdateSuccess(%j)`,
    data
  );
  return {
    type: DASHBOARD_SECTION_UPDATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function dashboardSectionUpdateFailure(error) {
  Logger.log(
    'debug',
    `[dashboardSections.actions] dashboardSectionUpdateFailure(%j)`,
    error
  );
  return {
    type: DASHBOARD_SECTION_UPDATE_FAILURE,
    error: error,
  };
}

export function dashboardSectionCreateRequest(data) {
  Logger.log(
    'debug',
    `[dashboardSections.actions] dashboardSectionCreateRequest(%j)`,
    data
  );
  return {
    type: DASHBOARD_SECTION_CREATE_REQUEST,
  };
}

export function dashboardSectionCreateSuccess(data) {
  Logger.log(
    'debug',
    `[dashboardSections.actions] dashboardSectionCreateSuccess(%j)`,
    data
  );
  return {
    type: DASHBOARD_SECTION_CREATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function dashboardSectionCreateFailure(error) {
  Logger.log(
    'debug',
    `[dashboardSections.actions] dashboardSectionCreateFailure(%j)`,
    error
  );
  return {
    type: DASHBOARD_SECTION_CREATE_FAILURE,
    error: error,
  };
}

export function dashboardSectionDeleteRequest(id) {
  Logger.log(
    'debug',
    `[dashboardSections.actions] dashboardSectionDeleteRequest(${id})`
  );
  return {
    type: DASHBOARD_SECTION_DELETE_REQUEST,
    id: id,
  };
}

export function dashboardSectionDeleteSuccess(id) {
  Logger.log(
    'debug',
    `[dashboardSections.actions] dashboardSectionDeleteSuccess(${id})`
  );
  return {
    type: DASHBOARD_SECTION_DELETE_SUCCESS,
    id: id,
  };
}

export function dashboardSectionDeleteFailure(error) {
  Logger.log(
    'debug',
    `[dashboardSections.actions] dashboardSectionDeleteFailure(%j)`,
    error
  );
  return {
    type: DASHBOARD_SECTION_DELETE_FAILURE,
    error: error,
  };
}

export function dashboardSectionsFormDestroy(formState = null) {
  Logger.log(
    'debug',
    `[dashboardSections.actions] dashboardSectionsFormDestroy(%j)`,
    formState
  );
  return {
    type: DASHBOARD_SECTION_FORM_DESTROY,
    form: formState,
  };
}

// API THUNK ACTION CREATORS

export function loadDashboardSections(
  page = 1,
  limit = 10,
  order = null,
  filter = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[dashboardSections.actions] loadDashboardSections(${page}, ${limit}, ${order}, %j, ###)`,
    filter
  );

  return async function (dispatch) {
    dispatch(dashboardSectionListRequest(page, limit, order, filter));

    // call API
    const response = await api.getDashboardSections(page, limit, order, filter);
    let success = false;

    // get dashboard sections list success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API dashboard sections list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );

      const normalizedEntities = normalize(
        response.getIn(['data', 'dashboard_sections']),
        [schema.dashboardSection]
      );
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result,
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(dashboardSectionListSuccess(data));
      success = true;
    } else if (1 === page && 204 === response.get('status')) {
      Logger.log(
        'info',
        `Get API dashboard sections success [empty]. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: [],
      };
      dispatch(dashboardSectionListSuccess(data));
      success = true;

      // get dashboard sections list failure
    } else {
      Logger.log(
        'info',
        `Get API dashboard sections list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      dispatch(dashboardSectionListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function loadDashboardSection(id, cb = function () {}) {
  Logger.log(
    'debug',
    `[dashboardSections.actions] loadDashboardSection(${id}, ###)`
  );

  return async function (dispatch) {
    dispatch(dashboardSectionReadRequest(id));

    // call API
    const response = await api.getDashboardSection(id);
    let success = false;

    // get dashboard section success
    if (200 === response.get('status')) {
      Logger.log('info', `Get API dashboard section success. ID: ${id}.`);

      const normalizedEntities = normalize(
        [response.getIn(['data', 'dashboard_section'])],
        [schema.dashboardSection]
      );
      const data = {
        id: response.getIn(['data', 'dashboard_section', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(dashboardSectionReadSuccess(data));
      success = true;

      // get dashboard section failure
    } else {
      Logger.log('info', `Get API dashboard section failure. ID: ${id}.`);
      dispatch(dashboardSectionReadFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function updateDashboardSection(id, data, cb = function () {}) {
  Logger.log(
    'debug',
    `[dashboardSections.actions] updateDashboardSection(${id}, %j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(dashboardSectionUpdateRequest(id, data));

    // call API
    const response = await api.putDashboardSection(id, data);
    let success = false;

    // put dashboard section success
    if (200 === response.get('status')) {
      Logger.log('info', `PUT API dashboard section success. User: ${id}.`);

      const normalizedEntities = normalize(
        [response.getIn(['data', 'dashboard_section'])],
        [schema.dashboardSection]
      );
      const data = {
        id: response.getIn(['data', 'dashboard_section', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(dashboardSectionUpdateSuccess(data));
      success = true;

      // get dashboard section failure
    } else {
      Logger.log('info', `PUT API dashboard section failure. ID: ${id}.`);
      dispatch(
        dashboardSectionUpdateFailure(response.getIn(['data', 'error']))
      );
    }

    // callback function
    cb(success);
  };
}

export function createDashboardSection(data, cb = function () {}) {
  Logger.log(
    'debug',
    `[dashboardSections.actions] createDashboardSection(%j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(dashboardSectionCreateRequest(data));

    // call API
    const response = await api.postDashboardSections(data);
    let success = false;

    // post dashboard sections success
    if (201 === response.get('status')) {
      Logger.log(
        'info',
        `POST API dashboard sections success. Post: ${response.getIn([
          'data',
          'dashboard_section',
          'id',
        ])}.`
      );

      const normalizedEntities = normalize(
        [response.getIn(['data', 'dashboard_section'])],
        [schema.dashboardSection]
      );
      const data = {
        id: response.getIn(['data', 'dashboard_section', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(dashboardSectionCreateSuccess(data));
      success = true;

      // get dashboard sections failure
    } else {
      Logger.log('info', `POST API dashboard sections failure.`);
      dispatch(
        dashboardSectionCreateFailure(response.getIn(['data', 'error']))
      );
    }

    // callback function
    cb(success);
  };
}

export function deleteDashboardSection(id, cb = function () {}) {
  Logger.log(
    'debug',
    `[dashboardSections.actions] deleteDashboardSection(${id}, ###)`
  );

  return async function (dispatch) {
    dispatch(dashboardSectionDeleteRequest(id));

    // call API
    const response = await api.deleteDashboardSection(id);
    let success = false;

    // delete dashboard section success
    if (204 === response.get('status')) {
      Logger.log('info', `DELETE API dashboard section success. ID: ${id}.`);

      dispatch(removeEntity({ entityType: 'dashboardSections', id: id }));
      dispatch(dashboardSectionDeleteSuccess(id));
      success = true;

      // get dashboard section failure
    } else {
      Logger.log('info', `DELETE API dashboard section failure. ID: ${id}.`);
      dispatch(
        dashboardSectionDeleteFailure(response.getIn(['data', 'error']))
      );
    }

    // callback function
    cb(success);
  };
}

Logger.log('silly', `dashboardSections.actions loaded.`);
