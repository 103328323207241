import { connect } from 'react-redux';

import { downloadAdminPartnerClassesCSV } from '../../../../../state/modules/adminPartnerClasses/actions';
import AdminPartnerClassesScreen from '../components/AdminPartnerClassesScreen';

const mapStateToProps = (state) => {
  return {
    total: state.adminPartnerClasses.get('total'),
    isLoading: state.adminPartnerClasses.get('isLoading'),
    isDownloading: state.adminPartnerClasses.get('isCSVDownloading'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    download: (order, filter, cb) => {
      dispatch(downloadAdminPartnerClassesCSV(order, filter, cb));
    },
  };
};

const AdminPartnerClassesScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminPartnerClassesScreen);

export default AdminPartnerClassesScreenContainer;
