import { connect } from 'react-redux';

import ResourcesCSVButton from '../components/ResourcesCSVButton';

const mapStateToProps = (state) => {
  return {};
};

const ResourcesCSVButtonContainer =
  connect(mapStateToProps)(ResourcesCSVButton);

export default ResourcesCSVButtonContainer;
