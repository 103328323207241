import {connect} from 'react-redux';

import CountryScreen from '../components/CountryScreen';

const mapStateToProps = state => {
  return {
    total: state.locationCountries.get('total'),
    isLoading: state.locationCountries.get('areCountriesLoading')
  }
}

const CountryScreenContainer = connect(
  mapStateToProps
)(CountryScreen);

export default CountryScreenContainer;
