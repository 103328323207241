import {connect} from 'react-redux';

import CareGuideOwnersScreen from '../components/CareGuideOwnersScreen';

const mapStateToProps = state => {
  return {
    total: state.careGuideOwners.get('total'),
    isLoading: state.careGuideOwners.get('isLoading')
  }
}

const CareGuideOwnersScreenContainer = connect(
  mapStateToProps
)(CareGuideOwnersScreen);

export default CareGuideOwnersScreenContainer;
