import {connect} from 'react-redux';

import AccountAddScreen from '../components/AccountAddScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.accounts.get('isLoading'),
    success: state.accounts.getIn(['form', 'success']),
  }
}

const AccountAddScreenContainer = connect(
  mapStateToProps
)(AccountAddScreen);

export default AccountAddScreenContainer;
