import { connect } from 'react-redux';

import RegistrationCodesScreen from '../components/RegistrationCodesScreen';
import { downloadRegistrationCodesCSV } from '../../../../../state/modules/registrationCodes/actions';

const mapStateToProps = (state) => {
  return {
    total: state.registrationCodes.get('total'),
    isLoading: state.registrationCodes.get('isLoading'),
    isDownloading: state.registrationCodes.get('isCSVDownloading'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    download: (order, filter, cb) => {
      dispatch(downloadRegistrationCodesCSV(order, filter, cb));
    },
  };
};

const RegistrationCodesScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RegistrationCodesScreen);

export default RegistrationCodesScreenContainer;
