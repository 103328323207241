import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Translation, getI18n } from 'react-i18next';
import { Affix, Card, Col, DatePicker, Form, Row, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';

import RatingInput from './RatingInput';
import BodyInput from './BodyInput';
import PublicStatusInput from './PublicStatusInput';
import TagInput from '../../journalTags/containers/TagInputContainer';
import MediaInput from '../../media/containers/MediaInputContainer';
import message from '../../../elements/lib/MessageWrapper';
import FormMetadata from '../../../elements/components/FormMetadata';
import { pathTo } from '../../../Routes';
import Format from '../../../../../lib/Format';
import Logger from '../../../../../lib/Logger';
import Config from '../../../../../Config';
import TagInputPartner from '../../journalPartnerTags/containers/TagInputContainer';
import CareGuideInput from '../../careGuides/containers/CareGuideInputContainer';
import UserCareGuideInput from '../../users/containers/UserCareGuideInputContainer';

const JournalPostForm = ({
  id,
  data,
  errors,
  load,
  destroyForm,
  isLoading,
  isSubmitting,
  created_id,
  ...props
}) => {
  const [redirectTo, setRedirectTo] = useState(null);
  const [careGuideId, setCareGuideId] = useState(data['care_guide']);
  const [form] = Form.useForm();

  // form column settings
  const layout = {
    main: {
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
    },
  };

  // load record data from API
  useEffect(() => {
    if (id) {
      load(id);
    }
  }, [id, load]);

  // update input values when new data is available
  const dataString = JSON.stringify(data);
  useEffect(() => {
    if (id && !isSubmitting) {
      const dataObj = JSON.parse(dataString);
      dataObj['published_at'] = dataObj['published_at']
        ? moment(dataObj['published_at'], Config.get('API_DATETIME_FORMAT'))
        : moment();
      dataObj['edited_at'] = dataObj['edited_at']
        ? moment(dataObj['edited_at'], Config.get('API_DATETIME_FORMAT'))
        : null;
      form.setFieldsValue(dataObj);
      setCareGuideId(dataObj['care_guide']);
    }
  }, [form, dataString, isSubmitting, id]);

  // handle errors reported by API
  useEffect(() => {
    let firstFieldName = '';
    for (const field in errors) {
      form.setFields([{ name: field, errors: errors[field] }]);
      if (firstFieldName === '') {
        firstFieldName = field;
      }
    }
    form.scrollToField(firstFieldName);
  }, [form, errors]);

  // redirect add form to edit form on successful create action
  useEffect(() => {
    if (created_id) {
      setRedirectTo(pathTo('JournalPostEditScreen', { id: created_id }));
    }
    return () => {
      destroyForm();
    };
  }, [created_id, setRedirectTo, destroyForm]);

  // submit data handler
  const submitData = async (values) => {
    Logger.log('debug', `JournalPostForm.submitData(###)`);

    // API POST/PUT payload
    let payload = {};
    for (const input of Object.keys(data)) {
      if (input in values) {
        // transform profile refs to UUIDs
        if (['profile'].includes(input)) {
          payload['user_profile_id'] = values[input];
        }

        // transform entity refs to IDs
        else if (['care_guide'].includes(input)) {
          payload[input + '_id'] = values[input];
        }

        // transform media entity refs to IDs
        else if (['media'].includes(input) && Array.isArray(values[input])) {
          payload[input + '_ids'] = values[input];
        } else if (
          ['published_at', 'edited_at'].includes(input) &&
          values[input]
        ) {
          payload[input] = Format.date(
            values[input],
            Config.get('API_DATETIME_FORMAT')
          );
        } else {
          payload[input] = values[input];
        }
      }
    }

    if (id) {
      // update
      props.update(id, payload, (success) => {
        if (success) {
          message.success(getI18n().t('feedback_form_success'));
        } else {
          message.error(getI18n().t('feedback_form_error'));
        }
      });
    } else {
      // create
      props.create(payload, (success) => {
        if (success) {
          message.success(getI18n().t('feedback_form_success'));
        } else {
          message.error(getI18n().t('feedback_form_error'));
        }
      });
    }
  };

  // form submit handler
  const handleFinish = async (values) => {
    Logger.log('debug', `JournalPostForm.handleFinish(###)`);
    if (!props.isSubmitting) {
      await submitData(values);
    }
  };

  // form error handler
  const handleFinishFailed = ({ values, errorFields, outOfDate }) => {
    Logger.log('debug', `JournalPostForm.handleFinishFailed(###)`);
    message.error(getI18n().t('feedback_form_error'));
    if (errorFields && errorFields.length > 0) {
      form.scrollToField(errorFields[0].name);
    }
  };

  const onChangeCareGuide = (e) => {
    setCareGuideId(e);
    form.setFieldsValue({ profile: null });
  };

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <Translation>
      {(t) => (
        <>
          <div className="journal-post-form">
            <Form
              name="journal_post_form"
              form={form}
              initialValues={data}
              onFinish={handleFinish}
              onFinishFailed={handleFinishFailed}
              validateTrigger="onSubmit"
              {...layout.main}
            >
              <Row gutter={16}>
                <Col xs={24} lg={18}>
                  <Card
                    title={
                      id
                        ? t('journal_post_edit_title')
                        : t('journal_post_add_title')
                    }
                    extra={
                      isLoading ? (
                        <Spin
                          indicator={
                            <LoadingOutlined style={{ fontSize: 20 }} spin />
                          }
                        />
                      ) : null
                    }
                  >

                    <CareGuideInput
                      form={form}
                      name="care_guide"
                      label={t('journal_post_care_guide')}
                      disabled={isLoading || isSubmitting}
                      onChange={onChangeCareGuide}
                      rules={[
                        {
                          required: true,
                          message: t('feedback_validation_required'),
                        },
                      ]}
                    />

                  <UserCareGuideInput
                      careGuideId={careGuideId}
                      name="profile"
                      label={t('journal_post_profile')}
                      rules={[
                        {
                          required: true,
                          message: t('feedback_validation_required'),
                        },
                      ]}
                      form={form}
                      disabled={isLoading || isSubmitting}
                    />

                    <div className="form-group">
                      <Form.Item
                        name="published_at"
                        label={t('journal_post_published_at')}
                        rules={[
                          {
                            required: true,
                            message: t('feedback_validation_required'),
                          },
                        ]}
                      >
                        <DatePicker
                          showTime
                          disabled={isLoading || isSubmitting}
                          format={Config.get('DEFAULT_DATETIME_FORMAT')}
                        />
                      </Form.Item>
                    </div>

                    <div className="form-group">
                      <Form.Item
                        name="edited_at"
                        label={t('journal_post_edited_at')}
                        rules={null}
                      >
                        <DatePicker
                          showTime
                          disabled={isLoading || isSubmitting}
                          format={Config.get('DEFAULT_DATETIME_FORMAT')}
                        />
                      </Form.Item>
                    </div>

                    <RatingInput
                      name="rating"
                      label={t('journal_post_rating')}
                      disabled={isLoading || isSubmitting}
                    />

                    <BodyInput
                      name="body"
                      label={t('journal_post_body')}
                      form={form}
                      defaultValue={form.getFieldValue(['body'])}
                      disabled={isLoading || isSubmitting}
                    />

                    <TagInput
                      name="tags"
                      label={t('journal_post_tags')}
                      disabled={isLoading || isSubmitting}
                    />

                    <TagInputPartner
                      name="partner_tags"
                      careGuideId={careGuideId}
                      label={t('journal_post_partner_tags')}
                      disabled={isLoading || isSubmitting}
                    />

                    <MediaInput
                      name="media"
                      label={t('media_media')}
                      disabled={isLoading || isSubmitting}
                      careGuideId={careGuideId}
                      multiple={true}
                    />

                    <PublicStatusInput
                      name="public_status"
                      label={t('journal_post_public_status')}
                      disabled={isLoading || isSubmitting}
                    />
                  </Card>
                </Col>

                <Col xs={24} lg={6}>
                  <Affix offsetTop={10}>
                    <Card title={t('form_metadata_header')}>
                      <FormMetadata
                        id={id}
                        isSubmitting={isSubmitting}
                        delete={props.delete.bind(this)}
                        deleteRedirectTo="JournalPostsScreen"
                        createdAt={props.createdAt}
                        updatedAt={props.updatedAt}
                      />
                    </Card>
                  </Affix>
                </Col>
              </Row>
            </Form>
          </div>
        </>
      )}
    </Translation>
  );
};

export default JournalPostForm;

Logger.log('silly', `JournalPostForm loaded.`);
