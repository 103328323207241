import React, { Component } from 'react';
import { Translation } from 'react-i18next';

import DashboardSectionForm from '../containers/DashboardSectionFormContainer';
import DocumentHead from '../../../elements/components/DocumentHead';
import Logger from '../../../../../lib/Logger';

class DashboardSectionEditScreen extends Component {
  scrollToTop = () => {
    Logger.log('debug', `DashboardSectionEditScreen.scrollToTop()`);
    if (typeof window !== 'undefined') {
      setTimeout(() => window.scrollTo(0, 0), 100);
    }
  };

  render() {
    return (
      <Translation>
        {(t) => (
          <div>
            <DocumentHead title={t('dashboard_sections_route_edit')} />
            <DashboardSectionForm id={this.props.match.params.id} />
          </div>
        )}
      </Translation>
    );
  }

  /* LIFECYCLE EVENTS */

  componentDidMount() {
    this.scrollToTop();
  }
}

export default DashboardSectionEditScreen;

Logger.log('silly', `DashboardSectionEditScreen loaded.`);
