import { connect } from 'react-redux';

import JournalPartnerTagsCSVButton from '../components/JournalPartnerTagsCSVButton';

const mapStateToProps = (state) => {
  return {};
};

const JournalPartnerTagsCSVButtonContainer = connect(mapStateToProps)(
  JournalPartnerTagsCSVButton
);

export default JournalPartnerTagsCSVButtonContainer;
