import { connect } from 'react-redux';

import { loadCareGuideOwners } from '../../../../../state/modules/careGuideOwners/actions';
import CareGuideOwnerInput from '../components/CareGuideOwnerInput';

const mapStateToProps = (state) => {
  return {
    total: state.careGuideOwners.get('total'),
    isLoading: state.careGuideOwners.get('isLoading'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (page, limit, order, filter, cb) => {
      dispatch(loadCareGuideOwners(page, limit, order, filter, cb));
    },
  };
};

const CareGuideOwnerInputContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CareGuideOwnerInput);

export default CareGuideOwnerInputContainer;
